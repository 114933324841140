/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Action": [
      "ApiCallAction",
      "ApiCallGraphqlAction",
      "CalculationAction",
      "ChecklistAction",
      "ClinicalNoteAction",
      "ExtensionAction",
      "FormAction",
      "MessageAction",
      "PushToEmrAction"
    ],
    "ExtensionActionField": [
      "BooleanField",
      "DateField",
      "HtmlField",
      "JsonField",
      "NumericArrayField",
      "NumericField",
      "StringArrayField",
      "StringField",
      "TextField"
    ],
    "PaginationAndSortingPayload": [
      "ActivitiesPayload"
    ],
    "Payload": [
      "ActionPayload",
      "ActivateTriggerPayload",
      "AddActionToStepPayload",
      "AddChecklistToStepPayload",
      "AddConditionToRulePayload",
      "AddConstantPayload",
      "AddDataPointDefinitionToPathwayPayload",
      "AddDataPointMappingToExtensionActionPayload",
      "AddDerivedDataPointDefinitionPayload",
      "AddLabelToStepPayload",
      "AddLocationToPathwayPayload",
      "AddMappingToApiCallPayload",
      "AddMessageToStepPayload",
      "AddQuestionToFormPayload",
      "AddQuestionsToFormPayload",
      "AddRuleToQuestionPayload",
      "AddStepFromLibraryToTrackPayload",
      "AddStepToLibraryPayload",
      "AddStepToTrackPayload",
      "AddStickyNoteToTrackPayload",
      "AddTrackToPathwayPayload",
      "AddTransformationToDynamicVariablePayload",
      "AddTriggerToPathwayPayload",
      "AddTriggerToTrackPayload",
      "AddWebhookPayload",
      "AhpLinkPayload",
      "AhpLinkPayloads",
      "BaselineInfoPayload",
      "CalculationResultPayload",
      "CalculationsPayload",
      "CareflowComponentsPayload",
      "ClearActionIdleTimePayload",
      "ClearTimingDelayPayload",
      "ClearTimingReferencePayload",
      "ClinicalNotePayload",
      "ConfigureExtensionPayload",
      "ConnectStepsPayload",
      "ConstantPayload",
      "ConstantsPayload",
      "CreateDynamicVariablePayload",
      "CreateFormPayload",
      "CreateMessagePayload",
      "CreatePathwayCasePayload",
      "CreatePathwayPayload",
      "DataCatalogJsonPayload",
      "DataCatalogPayload",
      "DataPointDefinitionPayload",
      "DataPointDefinitionsPayload",
      "DeletePathwayCasePayload",
      "DomainEventsPayload",
      "DuplicateCareflowPayload",
      "DuplicateQuestionPayload",
      "DuplicateStepPayload",
      "DynamicVariablePayload",
      "DynamicVariablesPayload",
      "ElementsPayload",
      "EmptyPayload",
      "EmrReportPayload",
      "EndTrackAfterStepPayload",
      "EvaluatedRulePayload",
      "ExecuteTestApiCallPayload",
      "ExtensionActivityRecordPayload",
      "ExtensionConfigurationsPayload",
      "ExtensionsPayload",
      "FormPayload",
      "FormResponsePayload",
      "FormsPayload",
      "GeneratedClinicalNotePayload",
      "GeneratedEmrReportPayload",
      "GeneratedMessagePayload",
      "JsonPathPayload",
      "LocationsPayload",
      "MarkReleaseAsLivePayload",
      "MessagePayload",
      "MockObjectPayload",
      "PathwayCaseApiCallPayload",
      "PathwayCaseApiCallsPayload",
      "PathwayCasePayload",
      "PathwayCaseWebhookCallPayload",
      "PathwayCaseWebhookCallsPayload",
      "PathwayCasesPayload",
      "PathwayDiffPayload",
      "PathwayPayload",
      "PathwaySettingsPayload",
      "PathwayValidationStatusPayload",
      "PathwayVersionPayload",
      "PathwayVersionsPayload",
      "PathwaysPayload",
      "PublishPathwayPayload",
      "ReadMessagePayload",
      "RebuildViewModelsPayload",
      "RefreshExtensionPayload",
      "RemoveActionFromStepPayload",
      "RemoveClinicalNoteNarrativePayload",
      "RemoveConditionFromRulePayload",
      "RemoveConstantPayload",
      "RemoveDataPointMappingFromExtensionActionPayload",
      "RemoveLabelFromStepPayload",
      "RemoveMappingFromApiCallPayload",
      "RemoveQuestionFromFormPayload",
      "RemoveRuleFromQuestionPayload",
      "RemoveStepFromTrackPayload",
      "RemoveTrackFromPathwayPayload",
      "RemoveTransformationsFromDynamicVariablePayload",
      "RemoveTransitionPayload",
      "RemoveWebhookPayload",
      "ReorderActionsPayload",
      "ReorderQuestionsPayload",
      "RepairPathwaysPayload",
      "ResetPreviewPayload",
      "RetryWebhookCallPayload",
      "RulePayload",
      "SchemaPayload",
      "SetActionCalculationIdPayload",
      "SetActionCalculationInputPayload",
      "SetActionChecklistItemsPayload",
      "SetActionFormDisplayModePayload",
      "SetActionFormIdPayload",
      "SetActionIdleTimePayload",
      "SetActionMessageIdPayload",
      "SetActionStakeholdersPayload",
      "SetActionTitlePayload",
      "SetApiCallBodyPayload",
      "SetApiCallEndpointPayload",
      "SetApiCallHeadersPayload",
      "SetApiCallMethodPayload",
      "SetBrandingAccentColorPayload",
      "SetBrandingCustomThemePayload",
      "SetBrandingHostedPageAutoProgressPayload",
      "SetBrandingHostedPageAutosavePayload",
      "SetBrandingHostedPageTitlePayload",
      "SetBrandingLogoUrlPayload",
      "SetConditionOperandPayload",
      "SetConditionOperatorPayload",
      "SetConditionReferencePayload",
      "SetCustomActionFieldPayload",
      "SetDashboardIdsPayload",
      "SetDataPointDefinitionMetaDataFieldPayload",
      "SetDataPointDefinitionOptionalFieldPayload",
      "SetDataPointDefinitionPiiFieldPayload",
      "SetDateQuestionConfigPayload",
      "SetDynamicVariableDataPointDefinitionPayload",
      "SetDynamicVariableDataPointPropertyPayload",
      "SetDynamicVariableFallbackPayload",
      "SetDynamicVariableLabelPayload",
      "SetExtensionActionDataPointMappingDataPointPayload",
      "SetExtensionActionDataPointMappingKeyPayload",
      "SetExtensionActionDataPointMappingsPayload",
      "SetExtensionActionFieldPayload",
      "SetFormKeyPayload",
      "SetFormMetadataPayload",
      "SetFormTitlePayload",
      "SetFormTrademarkPayload",
      "SetMultipleSelectQuestionConfigPayload",
      "SetNumberQuestionConfigPayload",
      "SetPathwayCaseTitlePayload",
      "SetPathwayTitlePayload",
      "SetPhoneQuestionConfigPayload",
      "SetQuestionConfigPayload",
      "SetQuestionKeyPayload",
      "SetQuestionMetadataPayload",
      "SetQuestionOptionValueTypePayload",
      "SetQuestionsConfigPayload",
      "SetRemindersAmountPayload",
      "SetRemindersDelayPayload",
      "SetRuleBooleanOperatorPayload",
      "SetSliderQuestionConfigPayload",
      "SetStakeholderEmailsPayload",
      "SetStepCoordinatesPayload",
      "SetStepDocumentationPayload",
      "SetStepTitlePayload",
      "SetStickyNoteCoordinatesPayload",
      "SetTimingDelayPayload",
      "SetTimingReferencePayload",
      "SetTrackEndCoordinatesPayload",
      "SetTrackStartCoordinatesPayload",
      "SetTrackTitlePayload",
      "SetTransformationParamsPayload",
      "SetTransformationTypePayload",
      "SetTransitionDestinationPayload",
      "StakeholdersPayload",
      "StartPreviewPayload",
      "StartTrackFromStepPayload",
      "StartTransitionFromStepPayload",
      "StepLibraryPayload",
      "StepPayload",
      "StepsPayload",
      "StickyNotePayload",
      "StickyNotesPayload",
      "SubmitChecklistPayload",
      "SubmitFormResponsePayload",
      "TenantPayload",
      "TenantsPayload",
      "TestSourceControlPayload",
      "TimingPayload",
      "TrackPayload",
      "TracksPayload",
      "TransitionPayload",
      "TransitionsPayload",
      "TriggerActivationPayload",
      "TriggerActivationsPayload",
      "TriggerApiCallPayload",
      "TriggerPayload",
      "UpdateApiCallMappingDataPointPayload",
      "UpdateApiCallMappingFirstMatchOnlyPayload",
      "UpdateApiCallMappingKeyPayload",
      "UpdateCareFlowApiCallRetrySettingsPayload",
      "UpdateCareFlowSourceControlSettingsPayload",
      "UpdateClinicalNoteContextPayload",
      "UpdateConstantPayload",
      "UpdateDataPointDefinitionPayload",
      "UpdateDynamicVariablePayload",
      "UpdateEmailNotificationStatusPayload",
      "UpdateLabelTextPayload",
      "UpdateQuestionPayload",
      "UpdateStickyNoteBodyPayload",
      "UpdateWebhookEndpointPayload",
      "UpdateWebhookHeadersPayload",
      "UpdateWebhookNamePayload",
      "UpdateWebhookPayload",
      "UpdateWebhookStatusPayload",
      "UpdateWebhookSubscribedEventsPayload",
      "UpdateWebhookTestEndpointPayload",
      "UserPayload",
      "WebhookPayload"
    ],
    "Transformation": [
      "AddDurationTransformation",
      "AddPrefixTransformation",
      "AddSuffixTransformation",
      "AddWeekdaysTransformation",
      "FormatDateTransformation",
      "FormatPhoneNumberTransformation",
      "RoundToTransformation",
      "SubtractDurationTransformation"
    ],
    "TriggerActivationReference": [
      "AdHocActivationReference",
      "DataPointCollectedActivationReference",
      "PathwayStartedActivationReference",
      "StepCompletedActivationReference",
      "TrackCompletedActivationReference",
      "TrackStartedActivationReference"
    ],
    "TriggerSettings": [
      "AdHocTriggerSettings",
      "DataPointCollectedTriggerSettings",
      "DataPointNotCollectedTriggerSettings",
      "DesignatedTriggerTimer",
      "ExtensionWebhookTriggerSettings",
      "PathwayStartedTriggerSettings",
      "StepCompletedTriggerSettings",
      "TrackCompletedTriggerSettings"
    ]
  }
};
      export default result;
    
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Safe date scalar that can serialize string or date */
  SafeDate: any;
};

export type Action = {
  clinical_note_id?: Maybe<Scalars['ID']>;
  created: AuditTrail;
  emr_report_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  idle_time?: Maybe<Delay>;
  last_updated?: Maybe<AuditTrail>;
  locations?: Maybe<Array<Location>>;
  message_id?: Maybe<Scalars['ID']>;
  plugin_key?: Maybe<Scalars['String']>;
  stakeholders: Array<Stakeholder>;
  status: ConfigurationStatus;
  step: Step;
  title?: Maybe<Scalars['String']>;
  type: ActionType;
};

export type ActionComponent = {
  __typename?: 'ActionComponent';
  definition_id?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type ActionCustomFieldInput = {
  id: Scalars['String'];
  value: Scalars['String'];
};

export type ActionPayload = Payload & {
  __typename?: 'ActionPayload';
  /**
   * An action is a ? TBD ? at a specific date.
   * Actions are caused by a human(manual) or system(automated).
   * Something or someone causing actions is referred to as an agent (https://english.stackexchange.com/a/96105).
   * Action only exist in a context of a step.
   * By humans we mean patients or care teams.
   * By system we mean the Awell engine.
   * Typical actions done by humans are filing in forms and checklists.
   * Typical actions done by systems are calculations and sending messages.
   *
   * An action definition is the set of information needed to perform an action.
   * information can be:
   * - static
   * - dynamic
   *
   * Information is static when it is not expected to change during the time a pathway is active
   * Examples of static information:
   * - constants. e.g., specific email "foo@gmail.com" in a send message action
   * - gender of the patient
   * - name of the organisation
   * - preferences of the organisation like preferred reminder channel
   *
   * Information is dynamic when it is collected during an execution:
   * Examples of dynamic information:
   * - Form submissions
   * - step completion and activation dates
   * - Calculation results
   */
  action: Action;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export enum ActionType {
  ApiCall = 'API_CALL',
  ApiCallGraphql = 'API_CALL_GRAPHQL',
  Calculation = 'CALCULATION',
  Checklist = 'CHECKLIST',
  ClinicalNote = 'CLINICAL_NOTE',
  Form = 'FORM',
  Message = 'MESSAGE',
  Plugin = 'PLUGIN',
  PushToEmr = 'PUSH_TO_EMR'
}

export type ActivateTriggerInput = {
  trigger_activation_id: Scalars['String'];
};

export type ActivateTriggerPayload = Payload & {
  __typename?: 'ActivateTriggerPayload';
  activated: Scalars['Boolean'];
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  evaluated_rule_id?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type ActivitiesDeleted = {
  __typename?: 'ActivitiesDeleted';
  activity_ids: Array<Scalars['String']>;
};

export type ActivitiesPayload = PaginationAndSortingPayload & {
  __typename?: 'ActivitiesPayload';
  activities: Array<Activity>;
  code: Scalars['String'];
  metadata?: Maybe<ActivityMetadata>;
  pagination?: Maybe<PaginationOutput>;
  sorting?: Maybe<SortingOutput>;
  success: Scalars['Boolean'];
};

export type Activity = {
  __typename?: 'Activity';
  action: ActivityAction;
  action_component?: Maybe<ActionComponent>;
  container_name?: Maybe<Scalars['String']>;
  context?: Maybe<PathwayContext>;
  date: Scalars['String'];
  /** Url for icon, only used by extensions custom actions */
  icon_url?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  indirect_object?: Maybe<ActivityObject>;
  isUserActivity: Scalars['Boolean'];
  label?: Maybe<ActivityLabel>;
  object: ActivityObject;
  public?: Maybe<Scalars['Boolean']>;
  reference_id: Scalars['String'];
  resolution?: Maybe<ActivityResolution>;
  session_id?: Maybe<Scalars['String']>;
  stakeholders?: Maybe<Array<ActivityObject>>;
  status: ActivityStatus;
  stream_id: Scalars['String'];
  sub_activities: Array<SubActivity>;
  subject: ActivitySubject;
  track?: Maybe<ActivityTrack>;
};

export enum ActivityAction {
  Activate = 'ACTIVATE',
  Added = 'ADDED',
  Assigned = 'ASSIGNED',
  Complete = 'COMPLETE',
  Computed = 'COMPUTED',
  Delegated = 'DELEGATED',
  Deliver = 'DELIVER',
  Discarded = 'DISCARDED',
  Expired = 'EXPIRED',
  Failed = 'FAILED',
  FailedToSend = 'FAILED_TO_SEND',
  Generated = 'GENERATED',
  IsWaitingOn = 'IS_WAITING_ON',
  Postponed = 'POSTPONED',
  Processed = 'PROCESSED',
  Read = 'READ',
  Remind = 'REMIND',
  Reported = 'REPORTED',
  Scheduled = 'SCHEDULED',
  Send = 'SEND',
  Skipped = 'SKIPPED',
  Stopped = 'STOPPED',
  Submitted = 'SUBMITTED'
}

export type ActivityLabel = {
  __typename?: 'ActivityLabel';
  color: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  text: Scalars['String'];
};

export type ActivityMetadata = {
  __typename?: 'ActivityMetadata';
  stakeholders?: Maybe<Array<ActivityObject>>;
};

export type ActivityObject = {
  __typename?: 'ActivityObject';
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  preferred_language?: Maybe<Scalars['String']>;
  type: ActivityObjectType;
};

export enum ActivityObjectType {
  Action = 'ACTION',
  ApiCall = 'API_CALL',
  Calculation = 'CALCULATION',
  Checklist = 'CHECKLIST',
  ClinicalNote = 'CLINICAL_NOTE',
  EmrReport = 'EMR_REPORT',
  EmrRequest = 'EMR_REQUEST',
  EvaluatedRule = 'EVALUATED_RULE',
  Form = 'FORM',
  Message = 'MESSAGE',
  Pathway = 'PATHWAY',
  Patient = 'PATIENT',
  Plugin = 'PLUGIN',
  PluginAction = 'PLUGIN_ACTION',
  Reminder = 'REMINDER',
  Stakeholder = 'STAKEHOLDER',
  Step = 'STEP',
  Track = 'TRACK',
  User = 'USER'
}

export enum ActivityResolution {
  Expired = 'EXPIRED',
  Failure = 'FAILURE',
  Success = 'SUCCESS'
}

export enum ActivityStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Done = 'DONE',
  Expired = 'EXPIRED',
  Failed = 'FAILED'
}

export type ActivitySubject = {
  __typename?: 'ActivitySubject';
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  type: ActivitySubjectType;
};

export enum ActivitySubjectType {
  ApiCall = 'API_CALL',
  Awell = 'AWELL',
  Plugin = 'PLUGIN',
  Stakeholder = 'STAKEHOLDER',
  User = 'USER'
}

export type ActivityTrack = {
  __typename?: 'ActivityTrack';
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type AdHocActivationReference = TriggerActivationReference & {
  __typename?: 'AdHocActivationReference';
  type?: Maybe<TriggerActivationReferenceType>;
};

export type AdHocTriggerSettings = TriggerSettings & {
  __typename?: 'AdHocTriggerSettings';
  rule?: Maybe<Rule>;
  type?: Maybe<TriggerType>;
};

export type AddActionFromTemplateInput = {
  calculation_id?: Maybe<Scalars['String']>;
  extension?: Maybe<ExtensionActionInput>;
  stakeholder_id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type: ActionType;
};

export type AddActionToStepInput = {
  custom_action_id?: Maybe<Scalars['String']>;
  extension?: Maybe<ExtensionActionInput>;
  step_id: Scalars['String'];
  title: Scalars['String'];
  type: ActionType;
};

export type AddActionToStepPayload = Payload & {
  __typename?: 'AddActionToStepPayload';
  action: Action;
  api_call?: Maybe<ApiCall>;
  code: Scalars['String'];
  emr_report?: Maybe<EmrReport>;
  error?: Maybe<ErrorObject>;
  message?: Maybe<Message>;
  step: Step;
  success: Scalars['Boolean'];
};

export type AddAttachmentInput = {
  name: Scalars['String'];
  type: MessageAttachmentType;
  url: Scalars['String'];
};

export type AddChecklistToStepInput = {
  checklist: Array<Scalars['String']>;
  stakeholder_ids: Array<Scalars['String']>;
  step_id: Scalars['String'];
  /** Action title */
  title?: Maybe<Scalars['String']>;
};

export type AddChecklistToStepPayload = Payload & {
  __typename?: 'AddChecklistToStepPayload';
  action: ChecklistAction;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  step: Step;
  success: Scalars['Boolean'];
};

export type AddConditionToRuleInput = {
  condition?: Maybe<ConditionInput>;
  rule_id: Scalars['String'];
};

export type AddConditionToRulePayload = Payload & {
  __typename?: 'AddConditionToRulePayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  rule: Rule;
  success: Scalars['Boolean'];
};

export type AddConstantInput = {
  constant: NewConstantInput;
  pathway_id: Scalars['String'];
};

export type AddConstantPayload = Payload & {
  __typename?: 'AddConstantPayload';
  code: Scalars['String'];
  constant: Constant;
  error?: Maybe<ErrorObject>;
  pathway: Pathway;
  success: Scalars['Boolean'];
};

export type AddCustomActionToPathwayInput = {
  custom_action: CustomActionInput;
  pathway_id: Scalars['String'];
};

export type AddCustomFieldToPathwayInput = {
  labels: Array<LabelInput>;
  pathway_id: Scalars['String'];
};

export type AddDataPointDefinitionInput = {
  category: DataPointSourceType;
  jsonPath?: Maybe<Scalars['String']>;
  jsonSchemaSource?: Maybe<JsonSchemaSourceGraphqlTypeInput>;
  key: Scalars['String'];
  optional?: Maybe<Scalars['Boolean']>;
  possibleValues?: Maybe<Array<DataPointPossibleValueInput>>;
  range?: Maybe<RangeInput>;
  unit?: Maybe<Scalars['String']>;
  valueType: DataPointValueType;
};

export type AddDataPointDefinitionToPathwayInput = {
  data_point_definition: AddDataPointDefinitionInput;
  pathway_id: Scalars['String'];
};

export type AddDataPointDefinitionToPathwayPayload = Payload & {
  __typename?: 'AddDataPointDefinitionToPathwayPayload';
  code: Scalars['String'];
  data_point_definition: DataPointDefinition;
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type AddDataPointMappingToExtensionActionInput = {
  action_id: Scalars['String'];
};

export type AddDataPointMappingToExtensionActionPayload = Payload & {
  __typename?: 'AddDataPointMappingToExtensionActionPayload';
  action: ExtensionAction;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type AddDerivedDataPointDefinitionInput = {
  derived_data_point_definition: DerivedDataPointDefinitionInput;
  pathway_id: Scalars['String'];
};

export type AddDerivedDataPointDefinitionPayload = Payload & {
  __typename?: 'AddDerivedDataPointDefinitionPayload';
  code: Scalars['String'];
  data_point_definition: DataPointDefinition;
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type AddDurationParameters = {
  __typename?: 'AddDurationParameters';
  duration?: Maybe<DurationGraphQlObjectType>;
};

export type AddDurationTransformation = Transformation & {
  __typename?: 'AddDurationTransformation';
  id: Scalars['ID'];
  parameters?: Maybe<AddDurationParameters>;
  type: TransformationType;
};

export type AddLabelToStepInput = {
  label_id: Scalars['String'];
  step_id: Scalars['String'];
};

export type AddLabelToStepPayload = Payload & {
  __typename?: 'AddLabelToStepPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  step: Step;
  success: Scalars['Boolean'];
};

export type AddLocationToPathwayInput = {
  label: Scalars['String'];
  pathway_id: Scalars['String'];
};

export type AddLocationToPathwayPayload = Payload & {
  __typename?: 'AddLocationToPathwayPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  location: Location;
  success: Scalars['Boolean'];
};

export type AddMappingToApiCallInput = {
  api_call_id: Scalars['String'];
  data_point_definition_id?: Maybe<Scalars['String']>;
  response_key: Scalars['String'];
};

export type AddMappingToApiCallPayload = Payload & {
  __typename?: 'AddMappingToApiCallPayload';
  api_call: ApiCall;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type AddMessageAttachmentInput = {
  attachment: AddAttachmentInput;
  message_id: Scalars['String'];
};

export type AddMessageToStepInput = {
  /** Message body in either Slate or HTML format */
  body?: Maybe<MessageBodyInput>;
  stakeholder_ids: Array<Scalars['String']>;
  step_id: Scalars['String'];
  subject?: Maybe<Scalars['String']>;
  /** Action title */
  title?: Maybe<Scalars['String']>;
};

export type AddMessageToStepPayload = Payload & {
  __typename?: 'AddMessageToStepPayload';
  action: MessageAction;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  message?: Maybe<Message>;
  step: Step;
  success: Scalars['Boolean'];
};

export type AddNarrativeToClinicalNoteInput = {
  clinical_note_id: Scalars['String'];
  key: Scalars['String'];
  title: Scalars['String'];
};

export type AddPrefixParameters = {
  __typename?: 'AddPrefixParameters';
  prefix?: Maybe<Scalars['String']>;
};

export type AddPrefixTransformation = Transformation & {
  __typename?: 'AddPrefixTransformation';
  id: Scalars['ID'];
  parameters?: Maybe<AddPrefixParameters>;
  type: TransformationType;
};

export type AddQuestionToFormInput = {
  form_id: Scalars['String'];
  previous_question_id?: Maybe<Scalars['String']>;
  user_question_type: UserQuestionType;
};

export type AddQuestionToFormPayload = Payload & {
  __typename?: 'AddQuestionToFormPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  form: Form;
  question: Question;
  success: Scalars['Boolean'];
};

export type AddQuestionsToFormInput = {
  form_id: Scalars['String'];
  questions: Array<QuestionInput>;
};

export type AddQuestionsToFormPayload = Payload & {
  __typename?: 'AddQuestionsToFormPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  form: Form;
  questions: Array<Question>;
  success: Scalars['Boolean'];
};

export type AddRuleToQuestionInput = {
  form_id: Scalars['String'];
  question_id: Scalars['String'];
};

export type AddRuleToQuestionPayload = Payload & {
  __typename?: 'AddRuleToQuestionPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  question: Question;
  rule: Rule;
  success: Scalars['Boolean'];
};

export type AddStepFromLibraryToTrackInput = {
  coordinates: CoordinatesInput;
  step_id: Scalars['String'];
  track_id: Scalars['String'];
};

export type AddStepFromLibraryToTrackPayload = Payload & {
  __typename?: 'AddStepFromLibraryToTrackPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  step: Step;
  success: Scalars['Boolean'];
};

export type AddStepFromTemplateInput = {
  actions: Array<AddActionFromTemplateInput>;
  coordinates: CoordinatesInput;
  title: Scalars['String'];
  track_id: Scalars['String'];
};

export type AddStepToLibraryInput = {
  step_id: Scalars['String'];
};

export type AddStepToLibraryPayload = Payload & {
  __typename?: 'AddStepToLibraryPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  step: Step;
  success: Scalars['Boolean'];
};

export type AddStepToTrackInput = {
  coordinates: CoordinatesInput;
  track_id: Scalars['String'];
};

export type AddStepToTrackPayload = Payload & {
  __typename?: 'AddStepToTrackPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  step: Step;
  success: Scalars['Boolean'];
};

export type AddStickyNoteToTrackInput = {
  body?: Maybe<StickyNoteBodyInput>;
  coordinates: CoordinatesInput;
  track_id: Scalars['String'];
};

export type AddStickyNoteToTrackPayload = Payload & {
  __typename?: 'AddStickyNoteToTrackPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  stickyNote: StickyNote;
  success: Scalars['Boolean'];
};

export type AddSuffixParameters = {
  __typename?: 'AddSuffixParameters';
  suffix?: Maybe<Scalars['String']>;
};

export type AddSuffixTransformation = Transformation & {
  __typename?: 'AddSuffixTransformation';
  id: Scalars['ID'];
  parameters?: Maybe<AddSuffixParameters>;
  type: TransformationType;
};

export type AddTrackInput = {
  pathway_case_id: Scalars['String'];
  reason?: Maybe<Scalars['String']>;
  track_definition_id: Scalars['String'];
};

export type AddTrackToPathwayInput = {
  pathway_id: Scalars['String'];
  title: Scalars['String'];
};

export type AddTrackToPathwayPayload = Payload & {
  __typename?: 'AddTrackToPathwayPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  track: Track;
};

export type AddTransformationToDynamicVariableInput = {
  dynamic_variable_id: Scalars['String'];
  type: TransformationType;
};

export type AddTransformationToDynamicVariablePayload = Payload & {
  __typename?: 'AddTransformationToDynamicVariablePayload';
  code: Scalars['String'];
  dynamic_variable: DynamicVariable;
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type AddTriggerTimerInput = {
  trigger_id: Scalars['String'];
};

export type AddTriggerToPathwayInput = {
  pathway_id: Scalars['String'];
  webhook_id: Scalars['String'];
};

export type AddTriggerToPathwayPayload = Payload & {
  __typename?: 'AddTriggerToPathwayPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  trigger: Trigger;
};

export type AddTriggerToTrackInput = {
  track_id: Scalars['String'];
};

export type AddTriggerToTrackPayload = Payload & {
  __typename?: 'AddTriggerToTrackPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  trigger: Trigger;
};

export type AddWebhookInput = {
  pathway_id: Scalars['String'];
  webhook: NewWebhookInput;
};

export type AddWebhookPayload = Payload & {
  __typename?: 'AddWebhookPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  webhook: Webhook;
};

export type AddWeekdaysParameters = {
  __typename?: 'AddWeekdaysParameters';
  days?: Maybe<Scalars['Float']>;
};

export type AddWeekdaysTransformation = Transformation & {
  __typename?: 'AddWeekdaysTransformation';
  id: Scalars['ID'];
  parameters?: Maybe<AddWeekdaysParameters>;
  type: TransformationType;
};

export type AhpLink = {
  __typename?: 'AhpLink';
  base_url: Scalars['String'];
  cancel_url?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  language: Language;
  pathway_definition_id: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  stakeholder_definition_id: Scalars['String'];
  success_url?: Maybe<Scalars['String']>;
};

export type AhpLinkInput = {
  cancel_url?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  language: Language;
  pathway_definition_id: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  stakeholder_definition_id: Scalars['String'];
  success_url?: Maybe<Scalars['String']>;
};

export type AhpLinkPayload = Payload & {
  __typename?: 'AhpLinkPayload';
  ahp_link: AhpLink;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type AhpLinkPayloads = Payload & {
  __typename?: 'AhpLinkPayloads';
  ahp_links: Array<AhpLink>;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export enum AllowedDatesOptions {
  All = 'ALL',
  Future = 'FUTURE',
  Past = 'PAST'
}

export type Answer = {
  __typename?: 'Answer';
  question_id: Scalars['String'];
  value: Scalars['String'];
  value_type: DataPointValueType;
};

export type AnswerInput = {
  question_id: Scalars['String'];
  value: Scalars['String'];
};

export type ApiCall = {
  __typename?: 'ApiCall';
  body?: Maybe<MessageBodyTemplate>;
  endpoint?: Maybe<Scalars['String']>;
  headers: Array<ApiCallHeader>;
  id: Scalars['ID'];
  mappings: Array<ApiCallMapping>;
  method: Scalars['String'];
  title: Scalars['String'];
};

export type ApiCallAction = Action & {
  __typename?: 'ApiCallAction';
  api_call?: Maybe<ApiCall>;
  api_call_id: Scalars['ID'];
  clinical_note_id?: Maybe<Scalars['ID']>;
  created: AuditTrail;
  emr_report_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  idle_time?: Maybe<Delay>;
  last_updated?: Maybe<AuditTrail>;
  locations?: Maybe<Array<Location>>;
  message_id?: Maybe<Scalars['ID']>;
  plugin_key?: Maybe<Scalars['String']>;
  stakeholders: Array<Stakeholder>;
  status: ConfigurationStatus;
  step: Step;
  title?: Maybe<Scalars['String']>;
  type: ActionType;
};

export type ApiCallBodyInput = {
  html: Scalars['String'];
  plaintext: Scalars['String'];
  slate: Scalars['String'];
};

export type ApiCallGraphqlAction = Action & {
  __typename?: 'ApiCallGraphqlAction';
  api_call?: Maybe<ApiCall>;
  api_call_id: Scalars['ID'];
  clinical_note_id?: Maybe<Scalars['ID']>;
  created: AuditTrail;
  emr_report_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  idle_time?: Maybe<Delay>;
  last_updated?: Maybe<AuditTrail>;
  locations?: Maybe<Array<Location>>;
  message_id?: Maybe<Scalars['ID']>;
  plugin_key?: Maybe<Scalars['String']>;
  stakeholders: Array<Stakeholder>;
  status: ConfigurationStatus;
  step: Step;
  title?: Maybe<Scalars['String']>;
  type: ActionType;
};

export type ApiCallHeader = {
  __typename?: 'ApiCallHeader';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type ApiCallHeaderInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type ApiCallMapping = {
  __typename?: 'ApiCallMapping';
  data_point_definition_id?: Maybe<Scalars['String']>;
  first_match_only?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  response_key: Scalars['String'];
};

export type ApiCallRequest = {
  __typename?: 'ApiCallRequest';
  body?: Maybe<Scalars['String']>;
  endpoint: Scalars['String'];
  headers: Array<ApiCallHeader>;
  method: ApiCallRequestMethod;
};

export enum ApiCallRequestMethod {
  Delete = 'DELETE',
  Get = 'GET',
  Patch = 'PATCH',
  Post = 'POST',
  Put = 'PUT'
}

export type ApiCallResponse = {
  __typename?: 'ApiCallResponse';
  body: Scalars['String'];
  date: Scalars['String'];
  status: Scalars['Float'];
};

export type ApiCallRetrySettings = {
  __typename?: 'ApiCallRetrySettings';
  enabled?: Maybe<Scalars['Boolean']>;
  initial_delay_ms?: Maybe<Scalars['Float']>;
  max_attempts?: Maybe<Scalars['Float']>;
  max_delay_ms?: Maybe<Scalars['Float']>;
};

export type ApiCallRetrySettingsInput = {
  enabled?: Maybe<Scalars['Boolean']>;
  initial_delay_ms?: Maybe<Scalars['Float']>;
  max_attempts?: Maybe<Scalars['Float']>;
  max_delay_ms?: Maybe<Scalars['Float']>;
};

export enum ApiCallStatus {
  Failed = 'Failed',
  InProgress = 'InProgress',
  Pending = 'Pending',
  PermanentlyFailed = 'PermanentlyFailed',
  Skipped = 'Skipped',
  Success = 'Success'
}

export type ApiPathwayContext = {
  __typename?: 'ApiPathwayContext';
  id: Scalars['String'];
  pathway_definition_id: Scalars['String'];
  patient_id?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['String']>;
};

export type AuditTrail = {
  __typename?: 'AuditTrail';
  date: Scalars['SafeDate'];
  user_email?: Maybe<Scalars['String']>;
  user_id: Scalars['String'];
};

export type AvailableWebhook = {
  __typename?: 'AvailableWebhook';
  description?: Maybe<Scalars['String']>;
  key: Scalars['String'];
};

export type BaselineDataPoint = {
  __typename?: 'BaselineDataPoint';
  definition: DataPointDefinition;
  value?: Maybe<Scalars['String']>;
};

export type BaselineInfoInput = {
  data_point_definition_id: Scalars['String'];
  value: Scalars['String'];
};

export type BaselineInfoPayload = Payload & {
  __typename?: 'BaselineInfoPayload';
  baselineDataPoints: Array<BaselineDataPoint>;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type BooleanField = ExtensionActionField & {
  __typename?: 'BooleanField';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  label: Scalars['String'];
  required?: Maybe<Scalars['Boolean']>;
  type: ExtensionActionFieldType;
  /** Value is kept as string because it can contain data point definition id in handlebars template, otherwise it can be "true" or "false" */
  value?: Maybe<Scalars['String']>;
};

export enum BooleanOperator {
  And = 'AND',
  Or = 'OR'
}

export type BrandingSettings = {
  __typename?: 'BrandingSettings';
  accent_color?: Maybe<Scalars['String']>;
  custom_theme?: Maybe<FormattedJson>;
  hosted_page_auto_progress?: Maybe<Scalars['Boolean']>;
  hosted_page_autosave?: Maybe<Scalars['Boolean']>;
  hosted_page_title?: Maybe<Scalars['String']>;
  logo_url?: Maybe<Scalars['String']>;
};

/**
 * Calculations are functions that take data points as input and output new data points
 * For the definition of a data point, see DataPointsPayload.ts
 *
 * Most often these calculations are scores used to diagnose patients.
 * Examples are:
 * - Mayo score
 * - PRO2 score
 * - Oxford hip score
 * - EORTC score
 * ...
 *
 * Calculations can also be customer specific algorithms that are used for example to triage patients
 * A calculation consists of scores.
 *
 * Calculation definitions include:
 * - a list of score definitions
 * - the calculation title
 * - the calculation key. This is human readable id
 *
 * A score definition consists of a:
 * - type
 * - id
 * - title
 * - if type = integer, range
 *
 * The implementation of the calculation is **not** included in the calculation definition
 */
export type Calculation = {
  __typename?: 'Calculation';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  key: Scalars['String'];
  results: Array<CalculationResult>;
  title: Scalars['String'];
};

export type CalculationAction = Action & {
  __typename?: 'CalculationAction';
  calculation_id?: Maybe<Scalars['ID']>;
  calculation_inputs: Array<CalculationInput>;
  clinical_note_id?: Maybe<Scalars['ID']>;
  created: AuditTrail;
  emr_report_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  idle_time?: Maybe<Delay>;
  last_updated?: Maybe<AuditTrail>;
  locations?: Maybe<Array<Location>>;
  message_id?: Maybe<Scalars['ID']>;
  plugin_key?: Maybe<Scalars['String']>;
  stakeholders: Array<Stakeholder>;
  status: ConfigurationStatus;
  step: Step;
  title?: Maybe<Scalars['String']>;
  type: ActionType;
};

export type CalculationInput = {
  __typename?: 'CalculationInput';
  calculation_input_id: Scalars['String'];
  data_point_definition_id: Scalars['String'];
};

export type CalculationResult = {
  __typename?: 'CalculationResult';
  id: Scalars['ID'];
  key: Scalars['String'];
  range?: Maybe<Range>;
  title: Scalars['String'];
  valueType: DataPointValueType;
};

export type CalculationResultInput = {
  activity_id: Scalars['String'];
  pathway_case_id: Scalars['String'];
};

export type CalculationResultPayload = Payload & {
  __typename?: 'CalculationResultPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  result: Array<CalculationSingleResult>;
  success: Scalars['Boolean'];
};

export type CalculationSingleResult = {
  __typename?: 'CalculationSingleResult';
  status?: Maybe<Scalars['String']>;
  subresult_id: Scalars['String'];
  unit?: Maybe<Scalars['String']>;
  value: Scalars['String'];
  value_type?: Maybe<DataPointValueType>;
};

export type CalculationsPayload = Payload & {
  __typename?: 'CalculationsPayload';
  calculations: Array<Calculation>;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type CareflowComponentsPayload = Payload & {
  __typename?: 'CareflowComponentsPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  json: Scalars['String'];
  success: Scalars['Boolean'];
};

export type ChecklistAction = Action & {
  __typename?: 'ChecklistAction';
  /**
   * A checklist is a set of todos for a human to complete
   * A todo is an instruction
   * A checklist is part of a checklist action
   *
   * Example of checklist:
   * - Safety checklist before surgery:
   * -- review medical file of the patient
   * -- check heart-rate
   *
   *
   * Notes regarding execution domain
   * --------------------------------
   * A checklist action is done when all the todos of the checklist are marked as completed
   * In the execution domain each todo will have a complete / not complete state
   *
   * Remaining questions to answer:
   * - Are todos simple strings or will they be more complex? e.g., to be filled in by specific role? to be completed by specific date? can be skipped? can they be grouped?
   */
  checklist?: Maybe<Array<Scalars['String']>>;
  clinical_note_id?: Maybe<Scalars['ID']>;
  created: AuditTrail;
  emr_report_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  idle_time?: Maybe<Delay>;
  last_updated?: Maybe<AuditTrail>;
  locations?: Maybe<Array<Location>>;
  message_id?: Maybe<Scalars['ID']>;
  plugin_key?: Maybe<Scalars['String']>;
  stakeholders: Array<Stakeholder>;
  status: ConfigurationStatus;
  step: Step;
  title?: Maybe<Scalars['String']>;
  type: ActionType;
};

export type ChoiceRangeConfig = {
  __typename?: 'ChoiceRangeConfig';
  enabled?: Maybe<Scalars['Boolean']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
};

export type ChoiceRangeConfigInput = {
  enabled?: Maybe<Scalars['Boolean']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
};

export type ClearActionIdleTimeInput = {
  action_id: Scalars['String'];
};

export type ClearActionIdleTimePayload = Payload & {
  __typename?: 'ClearActionIdleTimePayload';
  action: Action;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type ClearTimingDelayInput = {
  timing_id: Scalars['String'];
};

export type ClearTimingDelayPayload = Payload & {
  __typename?: 'ClearTimingDelayPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  timing: Timing;
};

export type ClearTimingReferenceInput = {
  timing_id: Scalars['String'];
};

export type ClearTimingReferencePayload = Payload & {
  __typename?: 'ClearTimingReferencePayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  timing: Timing;
};

export type ClinicalNote = {
  __typename?: 'ClinicalNote';
  context: Array<ClinicalNoteContextField>;
  id: Scalars['ID'];
  narratives: Array<ClinicalNoteNarrative>;
};

export type ClinicalNoteAction = Action & {
  __typename?: 'ClinicalNoteAction';
  clinical_note_id?: Maybe<Scalars['ID']>;
  created: AuditTrail;
  emr_report_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  idle_time?: Maybe<Delay>;
  last_updated?: Maybe<AuditTrail>;
  locations?: Maybe<Array<Location>>;
  message_id?: Maybe<Scalars['ID']>;
  plugin_key?: Maybe<Scalars['String']>;
  stakeholders: Array<Stakeholder>;
  status: ConfigurationStatus;
  step: Step;
  title?: Maybe<Scalars['String']>;
  type: ActionType;
};

export type ClinicalNoteContextField = {
  __typename?: 'ClinicalNoteContextField';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type ClinicalNoteContextFieldInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type ClinicalNoteNarrative = {
  __typename?: 'ClinicalNoteNarrative';
  body?: Maybe<Scalars['String']>;
  html_body?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  key?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type ClinicalNotePayload = Payload & {
  __typename?: 'ClinicalNotePayload';
  clinical_note: ClinicalNote;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type CollectionContext = {
  __typename?: 'CollectionContext';
  action?: Maybe<CollectionContextItem>;
  stakeholder?: Maybe<Stakeholder>;
  step?: Maybe<CollectionContextItem>;
  track?: Maybe<CollectionContextItem>;
};

export type CollectionContextItem = {
  __typename?: 'CollectionContextItem';
  id: Scalars['String'];
  title: Scalars['String'];
};

export type Condition = {
  __typename?: 'Condition';
  id: Scalars['ID'];
  operand?: Maybe<Operand>;
  operator?: Maybe<ConditionOperator>;
  reference?: Maybe<Scalars['String']>;
  reference_key?: Maybe<Scalars['String']>;
  status: ConfigurationStatus;
};

export type ConditionInput = {
  operand?: Maybe<OperandInput>;
  operator: ConditionOperator;
  reference: Scalars['String'];
};

export enum ConditionOperandType {
  Boolean = 'BOOLEAN',
  DataPoint = 'DATA_POINT',
  DataSource = 'DATA_SOURCE',
  Number = 'NUMBER',
  NumbersArray = 'NUMBERS_ARRAY',
  String = 'STRING',
  StringsArray = 'STRINGS_ARRAY'
}

export enum ConditionOperator {
  Contains = 'CONTAINS',
  DoesNotContain = 'DOES_NOT_CONTAIN',
  IsAnyOf = 'IS_ANY_OF',
  IsEmpty = 'IS_EMPTY',
  IsEqualTo = 'IS_EQUAL_TO',
  IsGreaterThan = 'IS_GREATER_THAN',
  IsGreaterThanOrEqualTo = 'IS_GREATER_THAN_OR_EQUAL_TO',
  IsInRange = 'IS_IN_RANGE',
  IsLessThan = 'IS_LESS_THAN',
  IsLessThanOrEqualTo = 'IS_LESS_THAN_OR_EQUAL_TO',
  IsNoneOf = 'IS_NONE_OF',
  IsNotEmpty = 'IS_NOT_EMPTY',
  IsNotEqualTo = 'IS_NOT_EQUAL_TO',
  IsNotTrue = 'IS_NOT_TRUE',
  IsToday = 'IS_TODAY',
  IsTrue = 'IS_TRUE'
}

export type ConfigurationStatus = {
  __typename?: 'ConfigurationStatus';
  errors: Array<Scalars['String']>;
  status: Status;
};

export type ConfigureAhpLinkInput = {
  ahp_link: AhpLinkInput;
  id?: Maybe<Scalars['String']>;
};

export type ConfigureExtensionInput = {
  extension_key: Scalars['String'];
  pathway_id: Scalars['String'];
  properties: Array<ExtensionPropertyInput>;
};

export type ConfigureExtensionPayload = Payload & {
  __typename?: 'ConfigureExtensionPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  extension: ExtensionConfiguration;
  success: Scalars['Boolean'];
};

export type ConnectStepsInput = {
  destination_orientation: TransitionOrientationType;
  destination_step_id: Scalars['String'];
  origin_orientation: TransitionOrientationType;
  origin_step_id: Scalars['String'];
  track_id: Scalars['String'];
};

export type ConnectStepsPayload = Payload & {
  __typename?: 'ConnectStepsPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  origin_step: Step;
  success: Scalars['Boolean'];
  transition: Transition;
};

export type Constant = {
  __typename?: 'Constant';
  id: Scalars['ID'];
  label: Scalars['String'];
  obfuscated: Scalars['Boolean'];
  value: Scalars['String'];
};

export type ConstantInput = {
  constant_id: Scalars['String'];
  pathway_id: Scalars['String'];
};

export type ConstantPayload = Payload & {
  __typename?: 'ConstantPayload';
  code: Scalars['String'];
  constant?: Maybe<Constant>;
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type ConstantsPayload = Payload & {
  __typename?: 'ConstantsPayload';
  code: Scalars['String'];
  constants: Array<Constant>;
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type Coordinates = {
  __typename?: 'Coordinates';
  x: Scalars['Float'];
  y: Scalars['Float'];
};

export type CoordinatesInput = {
  x: Scalars['Float'];
  y: Scalars['Float'];
};

export type CreateDynamicVariableInput = {
  dynamic_variable?: Maybe<DynamicVariableInput>;
  pathway_id: Scalars['String'];
};

export type CreateDynamicVariablePayload = Payload & {
  __typename?: 'CreateDynamicVariablePayload';
  code: Scalars['String'];
  dynamic_variable: DynamicVariable;
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type CreateFormInput = {
  title?: Maybe<Scalars['String']>;
};

export type CreateFormPayload = Payload & {
  __typename?: 'CreateFormPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  form: Form;
  success: Scalars['Boolean'];
};

export type CreateMessagePayload = Payload & {
  __typename?: 'CreateMessagePayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  message: Message;
  success: Scalars['Boolean'];
};

export type CreatePathwayCaseInput = {
  pathway_id: Scalars['String'];
  title: Scalars['String'];
};

export type CreatePathwayCasePayload = Payload & {
  __typename?: 'CreatePathwayCasePayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  pathway_case: PathwayCase;
  success: Scalars['Boolean'];
};

export type CreatePathwayInput = {
  title: Scalars['String'];
};

export type CreatePathwayPayload = Payload & {
  __typename?: 'CreatePathwayPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  pathway: Pathway;
  success: Scalars['Boolean'];
};

export type CustomAction = {
  __typename?: 'CustomAction';
  category: Scalars['String'];
  created: AuditTrail;
  fields: Array<CustomActionField>;
  id: Scalars['ID'];
  last_updated?: Maybe<AuditTrail>;
  name: Scalars['String'];
  type: ActionType;
};

export type CustomActionField = {
  __typename?: 'CustomActionField';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  label: Scalars['String'];
  mandatory?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  options?: Maybe<Array<CustomActionFieldOption>>;
  readonly?: Maybe<Scalars['Boolean']>;
  type: CustomActionFieldType;
  value?: Maybe<Scalars['String']>;
};

export type CustomActionFieldInput = {
  description?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  mandatory?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  options?: Maybe<Array<CustomActionFieldOptionInput>>;
  readonly?: Maybe<Scalars['Boolean']>;
  type: CustomActionFieldType;
  value?: Maybe<Scalars['String']>;
};

export type CustomActionFieldOption = {
  __typename?: 'CustomActionFieldOption';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type CustomActionFieldOptionInput = {
  label: Scalars['String'];
  value: Scalars['String'];
};

export enum CustomActionFieldType {
  Select = 'SELECT',
  Text = 'TEXT'
}

export type CustomActionInput = {
  category: Scalars['String'];
  fields: Array<CustomActionFieldInput>;
  name: Scalars['String'];
  type: ActionType;
};

export type DataCatalogInput = {
  pathway_definition_id: Scalars['String'];
};

export type DataCatalogItem = {
  __typename?: 'DataCatalogItem';
  category: DataPointSourceType;
  collection_contexts: Array<CollectionContext>;
  /** Additonal context on data point */
  data_point_metadata?: Maybe<Array<DataPointMetaDataItem>>;
  id: Scalars['ID'];
  jsonPath?: Maybe<Scalars['String']>;
  /** For json valueType we can use this field to specify schema of json object */
  jsonSchemaSource?: Maybe<JsonSchemaSourceGraphqlType>;
  key: Scalars['String'];
  optional?: Maybe<Scalars['Boolean']>;
  /** Personally identifiable information */
  pii?: Maybe<Scalars['Boolean']>;
  possibleValues?: Maybe<Array<DataPointPossibleValue>>;
  range?: Maybe<Range>;
  source_definition_id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  valueType: DataPointValueType;
};

export type DataCatalogJsonPayload = Payload & {
  __typename?: 'DataCatalogJsonPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  json: Scalars['String'];
  success: Scalars['Boolean'];
};

export type DataCatalogPayload = Payload & {
  __typename?: 'DataCatalogPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  items: Array<DataCatalogItem>;
  success: Scalars['Boolean'];
};

export type DataPointCollectedActivationReference = TriggerActivationReference & {
  __typename?: 'DataPointCollectedActivationReference';
  data_point_definition_id?: Maybe<Scalars['ID']>;
  type?: Maybe<TriggerActivationReferenceType>;
};

export type DataPointCollectedTriggerSettings = TriggerSettings & {
  __typename?: 'DataPointCollectedTriggerSettings';
  data_point_definition_id?: Maybe<Scalars['ID']>;
  rule?: Maybe<Rule>;
  rule_id?: Maybe<Scalars['ID']>;
  type?: Maybe<TriggerType>;
};

export type DataPointDefinition = {
  __typename?: 'DataPointDefinition';
  category: DataPointSourceType;
  /** Additonal context on data point */
  data_point_metadata?: Maybe<Array<DataPointMetaDataItem>>;
  id: Scalars['ID'];
  jsonPath?: Maybe<Scalars['String']>;
  /** For json valueType we can use this field to specify schema of json object */
  jsonSchemaSource?: Maybe<JsonSchemaSourceGraphqlType>;
  key: Scalars['String'];
  optional?: Maybe<Scalars['Boolean']>;
  /** Personally identifiable information */
  pii?: Maybe<Scalars['Boolean']>;
  possibleValues?: Maybe<Array<DataPointPossibleValue>>;
  range?: Maybe<Range>;
  source_definition_id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  valueType: DataPointValueType;
};

export type DataPointDefinitionInput = {
  key: Scalars['String'];
  optional?: Maybe<Scalars['Boolean']>;
  possibleValues?: Maybe<Array<DataPointPossibleValueInput>>;
  range?: Maybe<RangeInput>;
  unit?: Maybe<Scalars['String']>;
  valueType: DataPointValueType;
};

export type DataPointDefinitionPayload = Payload & {
  __typename?: 'DataPointDefinitionPayload';
  code: Scalars['String'];
  data_point_definition: DataPointDefinition;
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type DataPointDefinitionsInput = {
  categories?: Maybe<Array<DataPointSourceType>>;
  pathway_definition_id: Scalars['String'];
  valueTypes?: Maybe<Array<DataPointValueType>>;
};

export type DataPointDefinitionsPayload = Payload & {
  __typename?: 'DataPointDefinitionsPayload';
  code: Scalars['String'];
  data_point_definitions: Array<DataPointDefinition>;
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type DataPointMetaDataInputType = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type DataPointMetaDataItem = {
  __typename?: 'DataPointMetaDataItem';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type DataPointNotCollectedTriggerSettings = TriggerSettings & {
  __typename?: 'DataPointNotCollectedTriggerSettings';
  data_point_definition_id?: Maybe<Scalars['ID']>;
  rule?: Maybe<Rule>;
  rule_id?: Maybe<Scalars['ID']>;
  type?: Maybe<TriggerType>;
};

export type DataPointPossibleValue = {
  __typename?: 'DataPointPossibleValue';
  label?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

export type DataPointPossibleValueInput = {
  label?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

export enum DataPointPropertyType {
  CollectionDate = 'COLLECTION_DATE',
  Label = 'LABEL',
  Value = 'VALUE'
}

export enum DataPointSourceType {
  ApiCall = 'API_CALL',
  ApiCallStatus = 'API_CALL_STATUS',
  Calculation = 'CALCULATION',
  DataPoint = 'DATA_POINT',
  ExtensionAction = 'EXTENSION_ACTION',
  ExtensionWebhook = 'EXTENSION_WEBHOOK',
  Form = 'FORM',
  Pathway = 'PATHWAY',
  PatientIdentifier = 'PATIENT_IDENTIFIER',
  PatientProfile = 'PATIENT_PROFILE',
  Step = 'STEP',
  Track = 'TRACK'
}

export enum DataPointValueType {
  Boolean = 'BOOLEAN',
  Date = 'DATE',
  Json = 'JSON',
  Number = 'NUMBER',
  NumbersArray = 'NUMBERS_ARRAY',
  String = 'STRING',
  StringsArray = 'STRINGS_ARRAY',
  Telephone = 'TELEPHONE'
}

export type DateConfig = {
  __typename?: 'DateConfig';
  allowed_dates?: Maybe<AllowedDatesOptions>;
  include_date_of_response?: Maybe<Scalars['Boolean']>;
};

export type DateConfigInput = {
  allowed_dates?: Maybe<AllowedDatesOptions>;
  include_date_of_response?: Maybe<Scalars['Boolean']>;
};

export type DateField = ExtensionActionField & {
  __typename?: 'DateField';
  delay?: Maybe<Delay>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  label: Scalars['String'];
  required?: Maybe<Scalars['Boolean']>;
  type: ExtensionActionFieldType;
  /** Date in ISO 8601 format */
  value?: Maybe<Scalars['String']>;
};

export type DateQuestionConfigInput = {
  date: DateConfigInput;
};

export type Delay = {
  __typename?: 'Delay';
  amount: Scalars['Int'];
  unit: DelayUnitType;
};

export type DelayInput = {
  amount: Scalars['Int'];
  unit: DelayUnitType;
};

export enum DelayUnitType {
  Day = 'DAY',
  Hour = 'HOUR',
  Minute = 'MINUTE',
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR'
}

export type DeletePathwayCaseInput = {
  pathway_case_id: Scalars['String'];
};

export type DeletePathwayCasePayload = Payload & {
  __typename?: 'DeletePathwayCasePayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type DerivedDataPointDefinitionInput = {
  jsonPath?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  source_id: Scalars['String'];
  valueType: DataPointValueType;
};

export type DesignatedTriggerTimer = TriggerSettings & {
  __typename?: 'DesignatedTriggerTimer';
  delay?: Maybe<Delay>;
  id: Scalars['ID'];
  reference?: Maybe<TriggerActivationReference>;
  rule?: Maybe<Rule>;
  type?: Maybe<TriggerType>;
};

export type DestinationInput = {
  node_id: Scalars['String'];
  orientation?: Maybe<TransitionOrientationType>;
  type: TransitionNodeType;
};

/**
 * We use event-sourcing in our app. In event-sourcing event are used to record state changes.
 * Event is a record of some state change that is a result of executing a command.
 * Events are always named with a verb in past tense, is something that already happened. (StepCreated, StepUpdated …)
 * Event is a record of state change with all the information that is necessary to replay it.
 * Events are immutable and stored into event store which is append only.
 * By being immutable and sequentially stored makes them suitable for audit log and history.
 * You must never delete an event.
 */
export type DomainEvent = {
  __typename?: 'DomainEvent';
  aggregate_class: Scalars['String'];
  aggregate_id: Scalars['String'];
  data?: Maybe<Scalars['String']>;
  event_type: Scalars['String'];
  id: Scalars['ID'];
  metadata: DomainEventMetadata;
  stream: Scalars['String'];
};

export type DomainEventMetadata = {
  __typename?: 'DomainEventMetadata';
  caused_by?: Maybe<Scalars['String']>;
  saga_id?: Maybe<Scalars['String']>;
  timestamp: Scalars['String'];
  user_id: Scalars['String'];
  version: Scalars['Float'];
};

export type DomainEventsPayload = Payload & {
  __typename?: 'DomainEventsPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  events: Array<DomainEvent>;
  success: Scalars['Boolean'];
};

export type DuplicateCareflowInput = {
  careflow_id: Scalars['String'];
};

export type DuplicateCareflowPayload = Payload & {
  __typename?: 'DuplicateCareflowPayload';
  careflow_id: Scalars['String'];
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type DuplicateQuestionInput = {
  form_id: Scalars['String'];
  question_id: Scalars['String'];
};

export type DuplicateQuestionPayload = Payload & {
  __typename?: 'DuplicateQuestionPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  form: Form;
  question: Question;
  success: Scalars['Boolean'];
};

export type DuplicateStepInput = {
  step_id: Scalars['String'];
};

export type DuplicateStepPayload = Payload & {
  __typename?: 'DuplicateStepPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  step: Step;
  success: Scalars['Boolean'];
};

export type DurationGraphQlObjectType = {
  __typename?: 'DurationGraphQlObjectType';
  days?: Maybe<Scalars['Float']>;
  hours?: Maybe<Scalars['Float']>;
  minutes?: Maybe<Scalars['Float']>;
  months?: Maybe<Scalars['Float']>;
  weeks?: Maybe<Scalars['Float']>;
  years?: Maybe<Scalars['Float']>;
};

export type DynamicVariable = {
  __typename?: 'DynamicVariable';
  data_point_definition?: Maybe<DataPointDefinition>;
  data_point_property?: Maybe<DataPointPropertyType>;
  fallback?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  status: ConfigurationStatus;
  transformations?: Maybe<Array<Transformation>>;
};

export type DynamicVariableInput = {
  data_point_definition_id: Scalars['String'];
  data_point_property?: Maybe<DataPointPropertyType>;
  fallback?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  transformations?: Maybe<Array<TransformationInput>>;
};

export type DynamicVariablePayload = Payload & {
  __typename?: 'DynamicVariablePayload';
  code: Scalars['String'];
  dynamic_variable: DynamicVariable;
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type DynamicVariablesPayload = Payload & {
  __typename?: 'DynamicVariablesPayload';
  code: Scalars['String'];
  dynamic_variables: Array<DynamicVariable>;
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type Element = {
  __typename?: 'Element';
  activity_type?: Maybe<ActionType>;
  context: PathwayContext;
  end_date?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  label?: Maybe<ActivityLabel>;
  name: Scalars['String'];
  parent_id?: Maybe<Scalars['ID']>;
  stakeholders: Array<ElementStakeholder>;
  start_date: Scalars['String'];
  status: ElementStatus;
  type: ElementType;
};

export type ElementStakeholder = {
  __typename?: 'ElementStakeholder';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export enum ElementStatus {
  Active = 'ACTIVE',
  Discarded = 'DISCARDED',
  Done = 'DONE',
  Postponed = 'POSTPONED',
  Scheduled = 'SCHEDULED',
  Stopped = 'STOPPED'
}

export enum ElementType {
  Action = 'ACTION',
  Pathway = 'PATHWAY',
  Step = 'STEP',
  Track = 'TRACK',
  Trigger = 'TRIGGER'
}

export type ElementsPayload = Payload & {
  __typename?: 'ElementsPayload';
  code: Scalars['String'];
  elements: Array<Element>;
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type EmptyPayload = Payload & {
  __typename?: 'EmptyPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type EmrBodyInput = {
  html: Scalars['String'];
  plaintext: Scalars['String'];
  slate: Scalars['String'];
};

/**
 * EMR Reports are templates that use data points to create EMR report instances.
 * EMR Reports that use 0 data points are called static EMR reports.
 *
 * A EMR report instance is created by filling a EMR report template with data point instances
 *
 * An illustrative example:
 * - EMR report:
 *   - report template: "Hello |patient_first_name|"
 *   - data points:
 *     - patient_first_name is a data point of type patient with property first name
 * If a patient has a first name Ben then the report instance would be "Hello Ben"
 *
 * Explanation of EMR report field:
 *  - body: The content that goes in the report
 */
export type EmrReport = {
  __typename?: 'EmrReport';
  body?: Maybe<Scalars['String']>;
  html_body?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type EmrReportMetadataField = {
  __typename?: 'EmrReportMetadataField';
  id: Scalars['ID'];
  label: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type EmrReportPayload = Payload & {
  __typename?: 'EmrReportPayload';
  code: Scalars['String'];
  emr_report: EmrReport;
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type EndTrackAfterStepInput = {
  destination_orientation: TransitionOrientationType;
  origin_orientation: TransitionOrientationType;
  origin_step_id: Scalars['String'];
  track_id: Scalars['String'];
};

export type EndTrackAfterStepPayload = Payload & {
  __typename?: 'EndTrackAfterStepPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  origin_step: Step;
  success: Scalars['Boolean'];
  transition: Transition;
};

export type Error = {
  __typename?: 'Error';
  message: Scalars['String'];
  stack: Scalars['String'];
};

export type ErrorObject = {
  __typename?: 'ErrorObject';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type EvaluateFormRulesInput = {
  answers: Array<AnswerInput>;
  form_id: Scalars['String'];
};

export type EvaluateFormRulesPayload = {
  __typename?: 'EvaluateFormRulesPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  results: Array<QuestionRuleResult>;
  success: Scalars['Boolean'];
};

export type EvaluatedRule = {
  __typename?: 'EvaluatedRule';
  conditions: Array<EvaluatedRuleCondition>;
  satisfied: Scalars['Boolean'];
};

export type EvaluatedRuleCondition = {
  __typename?: 'EvaluatedRuleCondition';
  id: Scalars['String'];
  operand?: Maybe<Operand>;
  operator: ConditionOperator;
  reference: EvaluatedRuleReference;
  satisfied: Scalars['Boolean'];
};

export type EvaluatedRulePayload = Payload & {
  __typename?: 'EvaluatedRulePayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  evaluatedRule: EvaluatedRule;
  success: Scalars['Boolean'];
};

export type EvaluatedRuleReference = {
  __typename?: 'EvaluatedRuleReference';
  data_point_definition_id: Scalars['String'];
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type EventsInput = {
  event_type?: Maybe<Scalars['String']>;
  saga_id?: Maybe<Scalars['String']>;
  stream?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

export type ExclusiveOptionConfig = {
  __typename?: 'ExclusiveOptionConfig';
  enabled?: Maybe<Scalars['Boolean']>;
  option_id?: Maybe<Scalars['String']>;
};

export type ExclusiveOptionConfigInput = {
  enabled?: Maybe<Scalars['Boolean']>;
};

export type ExecuteTestApiCallInput = {
  api_call_id: Scalars['String'];
  pathway_definition_id: Scalars['String'];
  variable_inputs: Array<GraphqlTestApiCallVariableDataPoint>;
};

export type ExecuteTestApiCallPayload = Payload & {
  __typename?: 'ExecuteTestApiCallPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  mappings: Array<GraphqlTestApiCallMapping>;
  metadata: GraphqlTestApiCallMetadata;
  request: GraphqlTestApiCallRequest;
  response: GraphqlTestApiCallResponse;
  success: Scalars['Boolean'];
};

export type ExistingWebhookInput = {
  enabled: Scalars['Boolean'];
  endpoint: Scalars['String'];
  endpoint_test?: Maybe<Scalars['String']>;
  headers?: Maybe<Array<WebhookHeaderInput>>;
  id: Scalars['String'];
  name: Scalars['String'];
  subscribed_events?: Maybe<Array<Scalars['String']>>;
};

export type ExportPathwayEventsCount = {
  __typename?: 'ExportPathwayEventsCount';
  event_count: Scalars['Float'];
  exported_count: Scalars['Float'];
};

export type ExportPathwayInput = {
  pathway_id: Scalars['String'];
};

export type ExportPathwayStatus = {
  __typename?: 'ExportPathwayStatus';
  content?: Maybe<Scalars['String']>;
  count?: Maybe<ExportPathwayEventsCount>;
  message: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type Extension = {
  __typename?: 'Extension';
  actions: Array<ExtensionActionBlueprint>;
  /** Key and description of the webhooks defined in the extension repository */
  available_webhooks?: Maybe<Array<AvailableWebhook>>;
  category: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  icon_url?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  settings?: Maybe<ExtensionSettings>;
  title: Scalars['String'];
  webhooks: Array<ExtensionWebhook>;
};


export type ExtensionWebhooksArgs = {
  pathway_id: Scalars['String'];
};

export type ExtensionAction = Action & {
  __typename?: 'ExtensionAction';
  clinical_note_id?: Maybe<Scalars['ID']>;
  created: AuditTrail;
  emr_report_id?: Maybe<Scalars['ID']>;
  extension_action_key?: Maybe<Scalars['ID']>;
  extension_data_point_mappings?: Maybe<Array<ExtensionDataPointMapping>>;
  extension_data_points?: Maybe<Array<ExtensionDataPointDefinition>>;
  extension_fields: Array<ExtensionActionField>;
  extension_key?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  idle_time?: Maybe<Delay>;
  last_updated?: Maybe<AuditTrail>;
  locations?: Maybe<Array<Location>>;
  message_id?: Maybe<Scalars['ID']>;
  plugin_key?: Maybe<Scalars['String']>;
  stakeholders: Array<Stakeholder>;
  stakeholders_select_settings?: Maybe<StakeholdersSelectSettings>;
  status: ConfigurationStatus;
  step: Step;
  title?: Maybe<Scalars['String']>;
  type: ActionType;
};

export type ExtensionActionBlueprint = {
  __typename?: 'ExtensionActionBlueprint';
  category: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  fields: Array<ExtensionActionField>;
  icon?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  stakeholders_select_settings?: Maybe<StakeholdersSelectSettings>;
  title: Scalars['String'];
};

export type ExtensionActionField = {
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  label: Scalars['String'];
  required?: Maybe<Scalars['Boolean']>;
  type: ExtensionActionFieldType;
};

export type ExtensionActionFieldInput = {
  id: Scalars['String'];
  options?: Maybe<ExtensionOptionsInput>;
  value: Scalars['String'];
};

export enum ExtensionActionFieldType {
  Boolean = 'BOOLEAN',
  Date = 'DATE',
  Html = 'HTML',
  Json = 'JSON',
  Numeric = 'NUMERIC',
  NumericArray = 'NUMERIC_ARRAY',
  String = 'STRING',
  StringArray = 'STRING_ARRAY',
  Text = 'TEXT'
}

export type ExtensionActionInput = {
  extension_action_key: Scalars['String'];
  extension_key: Scalars['String'];
};

export type ExtensionActivityField = {
  __typename?: 'ExtensionActivityField';
  id: Scalars['ID'];
  label: Scalars['String'];
  type: ExtensionActionFieldType;
  value: Scalars['String'];
};

export type ExtensionActivityRecord = {
  __typename?: 'ExtensionActivityRecord';
  activity_id: Scalars['String'];
  data_points: Array<ExtensionDataPoint>;
  date: Scalars['String'];
  fields: Array<ExtensionActivityField>;
  id: Scalars['ID'];
  pathway_id: Scalars['String'];
  plugin_action_key: Scalars['String'];
  plugin_key: Scalars['String'];
  settings?: Maybe<Array<PluginActionSettingsProperty>>;
};

export type ExtensionActivityRecordPayload = Payload & {
  __typename?: 'ExtensionActivityRecordPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  record: ExtensionActivityRecord;
  success: Scalars['Boolean'];
};

export type ExtensionConfiguration = {
  __typename?: 'ExtensionConfiguration';
  extension_key: Scalars['String'];
  properties?: Maybe<Array<ExtensionProperty>>;
};

export type ExtensionConfigurationsPayload = Payload & {
  __typename?: 'ExtensionConfigurationsPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  extensions?: Maybe<Array<ExtensionConfiguration>>;
  success: Scalars['Boolean'];
};

export type ExtensionConstantInput = {
  label: Scalars['String'];
  obfuscated: Scalars['Boolean'];
  value: Scalars['String'];
};

export type ExtensionDataPoint = {
  __typename?: 'ExtensionDataPoint';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type ExtensionDataPointDefinition = {
  __typename?: 'ExtensionDataPointDefinition';
  /** For data points derived from objects or arrays, we use this prop to specify the path to the scalar value. */
  jsonPath?: Maybe<Scalars['String']>;
  /** Complex data points are stored in json format but require a schema to validate them. This prop specifies the source of the schema. */
  jsonSchemaSource?: Maybe<JsonSchemaSourceGraphqlType>;
  key: Scalars['String'];
  valueType: DataPointValueType;
};

export type ExtensionDataPointMapping = {
  __typename?: 'ExtensionDataPointMapping';
  data_point_definition_id?: Maybe<Scalars['String']>;
  extension_data_point_key?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type ExtensionDateFieldDelayInput = {
  amount: Scalars['Int'];
  unit: DelayUnitType;
};

export type ExtensionOptionsInput = {
  delay?: Maybe<ExtensionDateFieldDelayInput>;
  slate?: Maybe<Scalars['String']>;
};

export type ExtensionProperty = {
  __typename?: 'ExtensionProperty';
  constant: Constant;
  key: Scalars['String'];
};

export type ExtensionPropertyInput = {
  constant: ExtensionConstantInput;
  key: Scalars['String'];
};

export type ExtensionSettings = {
  __typename?: 'ExtensionSettings';
  description: Scalars['String'];
  fields: Array<ExtensionSettingsField>;
};

export type ExtensionSettingsField = {
  __typename?: 'ExtensionSettingsField';
  description?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  label: Scalars['String'];
  obfuscated: Scalars['Boolean'];
  required?: Maybe<Scalars['Boolean']>;
};

export type ExtensionWebhook = {
  __typename?: 'ExtensionWebhook';
  data_point_definitions: Array<DataPointDefinition>;
  endpoint_url: Scalars['String'];
  id: Scalars['String'];
  webhook_key: Scalars['String'];
};

export type ExtensionWebhookTriggerSettings = TriggerSettings & {
  __typename?: 'ExtensionWebhookTriggerSettings';
  rule?: Maybe<Rule>;
  rule_id?: Maybe<Scalars['ID']>;
  type?: Maybe<TriggerType>;
  webhook_id?: Maybe<Scalars['ID']>;
};

export type ExtensionsPayload = Payload & {
  __typename?: 'ExtensionsPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  extensions: Array<Extension>;
  success: Scalars['Boolean'];
};

/**
 * Forms are a set of questions
 * Example of types of questions:
 *   - Text field (stored as string)
 *   - Multiple choice question (stored as array of answer ids)
 *   - image field (stored as string that is an URL where the image is stored)
 *   - Scale question (stored as a floating point number between 0 and 1)
 *   - ...
 * Forms are usually filled in by caregivers or patients.
 * When filled in, forms generate:
 *   - answer data points
 *   - metadata such as submission date, user id of user doing submission
 */
export type Form = {
  __typename?: 'Form';
  created?: Maybe<AuditTrail>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  key?: Maybe<Scalars['String']>;
  last_updated?: Maybe<AuditTrail>;
  metadata?: Maybe<FormattedJson>;
  pathways_used_in: Array<Pathway>;
  permissions: Permissions;
  question_ids: Array<Scalars['String']>;
  questions: Array<Question>;
  team_ids: Array<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  trademark?: Maybe<Scalars['String']>;
};

export type FormAction = Action & {
  __typename?: 'FormAction';
  clinical_note_id?: Maybe<Scalars['ID']>;
  created: AuditTrail;
  emr_report_id?: Maybe<Scalars['ID']>;
  form_display_mode?: Maybe<Scalars['String']>;
  form_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  idle_time?: Maybe<Delay>;
  last_updated?: Maybe<AuditTrail>;
  locations?: Maybe<Array<Location>>;
  message_id?: Maybe<Scalars['ID']>;
  plugin_key?: Maybe<Scalars['String']>;
  stakeholders: Array<Stakeholder>;
  status: ConfigurationStatus;
  step: Step;
  title?: Maybe<Scalars['String']>;
  type: ActionType;
};

export type FormDataPointDefinitionsInput = {
  form_definition_id: Scalars['String'];
};

export type FormPayload = Payload & {
  __typename?: 'FormPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  form: Form;
  success: Scalars['Boolean'];
};

export type FormResponse = {
  __typename?: 'FormResponse';
  answers: Array<Answer>;
};

export type FormResponseInput = {
  activity_id: Scalars['String'];
  pathway_case_id: Scalars['String'];
};

export type FormResponsePayload = Payload & {
  __typename?: 'FormResponsePayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  response: FormResponse;
  success: Scalars['Boolean'];
};

export type FormatDateParameters = {
  __typename?: 'FormatDateParameters';
  format?: Maybe<Scalars['String']>;
};

export type FormatDateTransformation = Transformation & {
  __typename?: 'FormatDateTransformation';
  id: Scalars['ID'];
  parameters?: Maybe<FormatDateParameters>;
  type: TransformationType;
};

export type FormatPhoneNumberParameters = {
  __typename?: 'FormatPhoneNumberParameters';
  format?: Maybe<Scalars['String']>;
};

export type FormatPhoneNumberTransformation = Transformation & {
  __typename?: 'FormatPhoneNumberTransformation';
  id: Scalars['ID'];
  parameters?: Maybe<FormatPhoneNumberParameters>;
  type: TransformationType;
};

export type FormattedJson = {
  __typename?: 'FormattedJson';
  plaintext: Scalars['String'];
  slate: Scalars['String'];
};

export type FormattedJsonInput = {
  plaintext: Scalars['String'];
  slate: Scalars['String'];
};

export type FormattedText = {
  __typename?: 'FormattedText';
  content: TranslatedText;
  format: Scalars['String'];
};

export type FormattedTextInput = {
  content: TranslatedTextInput;
  format: Scalars['String'];
};

export type FormsPayload = Payload & {
  __typename?: 'FormsPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  forms: Array<Form>;
  success: Scalars['Boolean'];
};

export type GeneratedClinicalNoteContextField = {
  __typename?: 'GeneratedClinicalNoteContextField';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type GeneratedClinicalNoteNarrative = {
  __typename?: 'GeneratedClinicalNoteNarrative';
  body: Scalars['String'];
  id: Scalars['ID'];
  key: Scalars['String'];
  title: Scalars['String'];
};

export type GeneratedClinicalNotePayload = Payload & {
  __typename?: 'GeneratedClinicalNotePayload';
  clinical_note: PreviewClinicalNote;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type GeneratedEmrReportPayload = Payload & {
  __typename?: 'GeneratedEmrReportPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  report: PreviewEmrReport;
  success: Scalars['Boolean'];
};

export type GeneratedMessagePayload = Payload & {
  __typename?: 'GeneratedMessagePayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  message: PreviewMessage;
  success: Scalars['Boolean'];
};

export type GraphqlTestApiCallMapping = {
  __typename?: 'GraphqlTestApiCallMapping';
  data_point_definition_id: Scalars['String'];
  response_key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
  value_type: Scalars['String'];
};

export type GraphqlTestApiCallMetadata = {
  __typename?: 'GraphqlTestApiCallMetadata';
  response_size: Scalars['Float'];
  response_time: Scalars['Float'];
};

export type GraphqlTestApiCallRequest = {
  __typename?: 'GraphqlTestApiCallRequest';
  body?: Maybe<Scalars['String']>;
  endpoint: Scalars['String'];
  headers: Array<ApiCallHeader>;
  method: Scalars['String'];
};

export type GraphqlTestApiCallResponse = {
  __typename?: 'GraphqlTestApiCallResponse';
  body: Scalars['String'];
  date: Scalars['String'];
  error?: Maybe<Scalars['String']>;
  headers: Scalars['String'];
  status: Scalars['Float'];
};

export type GraphqlTestApiCallVariableDataPoint = {
  data_point_definition_id: Scalars['String'];
  value: Scalars['String'];
  value_type: Scalars['String'];
};

export type HtmlField = ExtensionActionField & {
  __typename?: 'HtmlField';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  label: Scalars['String'];
  required?: Maybe<Scalars['Boolean']>;
  slate?: Maybe<Scalars['String']>;
  type: ExtensionActionFieldType;
  value?: Maybe<Scalars['String']>;
};

export type IdleTimeDelayInput = {
  amount: Scalars['Int'];
  unit: DelayUnitType;
};

export type ImportPathwayEventsCount = {
  __typename?: 'ImportPathwayEventsCount';
  event_count: Scalars['Float'];
  event_id: Scalars['String'];
  imported_count: Scalars['Float'];
};

export type ImportPathwayInput = {
  base64Json: Scalars['String'];
  tenant_id: Scalars['String'];
};

export type ImportPathwayStatus = {
  __typename?: 'ImportPathwayStatus';
  count?: Maybe<ImportPathwayEventsCount>;
  message: Scalars['String'];
};

export type InvalidStatusReason = {
  __typename?: 'InvalidStatusReason';
  error?: Maybe<Scalars['String']>;
  type: PathwayValidationStatusReasonType;
};

export type IsSlugUniquePayload = {
  __typename?: 'IsSlugUniquePayload';
  is_slug_unique: Scalars['Boolean'];
};

export type JsonField = ExtensionActionField & {
  __typename?: 'JsonField';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** For json fields we can use this prop to specify schema of json object */
  jsonSchemaSource?: Maybe<JsonSchemaSourceGraphqlType>;
  label: Scalars['String'];
  required?: Maybe<Scalars['Boolean']>;
  slate?: Maybe<Scalars['String']>;
  type: ExtensionActionFieldType;
  value?: Maybe<Scalars['String']>;
};

export type JsonPathPayload = Payload & {
  __typename?: 'JsonPathPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  jsonPath: Scalars['String'];
  mockObject: Scalars['String'];
  success: Scalars['Boolean'];
};

export type JsonSchemaSourceGraphqlType = {
  __typename?: 'JsonSchemaSourceGraphqlType';
  qualifiedKey: Scalars['String'];
  system: JsonSchemaSourceSystem;
};

export type JsonSchemaSourceGraphqlTypeInput = {
  qualifiedKey: Scalars['String'];
  system: JsonSchemaSourceSystem;
};

export enum JsonSchemaSourceSystem {
  Awell = 'AWELL',
  Extension = 'EXTENSION',
  FhirProxy = 'FHIR_PROXY'
}

export type Label = {
  __typename?: 'Label';
  color: Scalars['String'];
  created: AuditTrail;
  id: Scalars['ID'];
  last_updated?: Maybe<AuditTrail>;
  text: Scalars['String'];
};

export type LabelInput = {
  color: Scalars['String'];
  text: Scalars['String'];
};

export enum Language {
  Dutch = 'DUTCH',
  English = 'ENGLISH',
  Estonian = 'ESTONIAN',
  French = 'FRENCH',
  Romanian = 'ROMANIAN',
  Russian = 'RUSSIAN',
  Spanish = 'SPANISH'
}

/**
 * Some actions are performed in specific locations.
 * Pathway studio allows pathway builders to create and assign locations to each action.
 * Example:
 * - Filling an intake form during the doctor's visit
 * - Filling a follow-up questionnaire at home
 * ...
 */
export type Location = {
  __typename?: 'Location';
  id: Scalars['ID'];
  label: Scalars['String'];
};

export type LocationsPayload = Payload & {
  __typename?: 'LocationsPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  locations: Array<Location>;
  success: Scalars['Boolean'];
};

export type MappingDataPointDefinitionInput = {
  data_point_definition_id?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  new_data_point_definition?: Maybe<NewMappingDataPointDefinitionInput>;
};

export type MarkReleaseAsLiveInput = {
  definition_id: Scalars['String'];
  release_id: Scalars['String'];
};

export type MarkReleaseAsLivePayload = Payload & {
  __typename?: 'MarkReleaseAsLivePayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type MassResetTeamInput = {
  team_id: Scalars['String'];
};

/**
 * Messages are templates that use data points to create message instances.
 * Messages that use 0 data points are called static messages.
 *
 * A message instance is created by filling a message template with data point instances
 *
 * An illustrative example:
 * - Message:
 *   - message template: "Hello |patient_first_name|"
 *   - data points:
 *     - patient_first_name is a data point of type patient with property first name
 * If a patient has a first name Ben then the message instance would be "Hello Ben"
 *
 * Explanation of Message fields:
 *  - subject: Short explanation of what goes in the message
 *  - body: The content that goes in the message
 */
export type Message = {
  __typename?: 'Message';
  attachments?: Maybe<Array<MessageAttachment>>;
  body?: Maybe<Scalars['String']>;
  format: MessageFormat;
  html_body?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  subject?: Maybe<Scalars['String']>;
};

export type MessageAction = Action & {
  __typename?: 'MessageAction';
  clinical_note_id?: Maybe<Scalars['ID']>;
  created: AuditTrail;
  emr_report_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  idle_time?: Maybe<Delay>;
  last_updated?: Maybe<AuditTrail>;
  locations?: Maybe<Array<Location>>;
  message?: Maybe<Message>;
  message_id?: Maybe<Scalars['ID']>;
  plugin_key?: Maybe<Scalars['String']>;
  stakeholders: Array<Stakeholder>;
  status: ConfigurationStatus;
  step: Step;
  title?: Maybe<Scalars['String']>;
  type: ActionType;
};

export type MessageAttachment = {
  __typename?: 'MessageAttachment';
  id: Scalars['ID'];
  name: Scalars['String'];
  type: MessageAttachmentType;
  url: Scalars['String'];
};

export type MessageAttachmentInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  type: MessageAttachmentType;
  url: Scalars['String'];
};

export enum MessageAttachmentType {
  File = 'FILE',
  Link = 'LINK',
  Video = 'VIDEO'
}

export type MessageBodyInput = {
  html: Scalars['String'];
  slate: Scalars['String'];
};

export type MessageBodyTemplate = {
  __typename?: 'MessageBodyTemplate';
  html: Scalars['String'];
  plaintext: Scalars['String'];
  slate: Scalars['String'];
};

export enum MessageFormat {
  Html = 'HTML',
  Slate = 'SLATE'
}

export type MessagePayload = Payload & {
  __typename?: 'MessagePayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  message: Message;
  success: Scalars['Boolean'];
};

export type MockObjectPayload = Payload & {
  __typename?: 'MockObjectPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  mockObject: Scalars['String'];
  success: Scalars['Boolean'];
};

export type MultipleSelectConfig = {
  __typename?: 'MultipleSelectConfig';
  exclusive_option?: Maybe<ExclusiveOptionConfig>;
  range?: Maybe<ChoiceRangeConfig>;
};

export type MultipleSelectConfigInput = {
  exclusive_option?: Maybe<ExclusiveOptionConfigInput>;
  range?: Maybe<ChoiceRangeConfigInput>;
};

export type MultipleSelectQuestionConfigInput = {
  multiple_select: MultipleSelectConfigInput;
};

export type Mutation = {
  __typename?: 'Mutation';
  AddChecklistToStep: AddChecklistToStepPayload;
  AddMessageToStep: AddMessageToStepPayload;
  activateTrigger: ActivateTriggerPayload;
  addActionToStep: AddActionToStepPayload;
  addConditionToRule: AddConditionToRulePayload;
  addConstant: AddConstantPayload;
  addCustomActionToPathway: EmptyPayload;
  addCustomFieldToPathway: EmptyPayload;
  addDataPointDefinitionToPathway: AddDataPointDefinitionToPathwayPayload;
  addDataPointMappingToExtensionAction: AddDataPointMappingToExtensionActionPayload;
  addDerivedDataPointDefinition: AddDerivedDataPointDefinitionPayload;
  addLabelToStep: AddLabelToStepPayload;
  addLocationToPathway: AddLocationToPathwayPayload;
  addMappingToApiCall: AddMappingToApiCallPayload;
  addMessageAttachment: MessagePayload;
  addNarrativeToClinicalNote: ClinicalNotePayload;
  addQuestionToForm: AddQuestionToFormPayload;
  addQuestionsToForm: AddQuestionsToFormPayload;
  addRuleToQuestion: AddRuleToQuestionPayload;
  addStepFromLibraryToTrack: AddStepFromLibraryToTrackPayload;
  addStepFromTemplate: StepPayload;
  addStepToLibrary: AddStepToLibraryPayload;
  addStepToTrack: AddStepToTrackPayload;
  addStickyNoteToTrack: AddStickyNoteToTrackPayload;
  addTrack: EmptyPayload;
  addTrackToPathway: AddTrackToPathwayPayload;
  addTransformationToDynamicVariable: AddTransformationToDynamicVariablePayload;
  addTriggerTimer: TriggerPayload;
  addTriggerToPathway: AddTriggerToPathwayPayload;
  addTriggerToTrack: AddTriggerToTrackPayload;
  addWebhook: AddWebhookPayload;
  clearActionIdleTime: ClearActionIdleTimePayload;
  clearTimingDelay: ClearTimingDelayPayload;
  clearTimingReference: ClearTimingReferencePayload;
  clearViewModels: EmptyPayload;
  configureAhpLink: AhpLinkPayload;
  configureExtension: ConfigureExtensionPayload;
  connectSteps: ConnectStepsPayload;
  createDynamicVariable: CreateDynamicVariablePayload;
  createForm: CreateFormPayload;
  createMessage: CreateMessagePayload;
  createPathway: CreatePathwayPayload;
  /**
   * Creates pathway case for a pathway.
   *       Pathway Case represents a single execution course in a pathway
   *       that is determined by evaluating transition and timing conditions
   *       based on provided input by the stakeholders of a pathway (patient, doctors, etc.)
   */
  createPathwayCase: CreatePathwayCasePayload;
  /** Delete the pathway case */
  deletePathwayCase: DeletePathwayCasePayload;
  duplicateCareflow: DuplicateCareflowPayload;
  duplicateQuestion: DuplicateQuestionPayload;
  duplicateStep: DuplicateStepPayload;
  endTrackAfterStep: EndTrackAfterStepPayload;
  evaluateFormRules: EvaluateFormRulesPayload;
  executeTestApiCall: ExecuteTestApiCallPayload;
  exportPathway: EmptyPayload;
  importPathway: EmptyPayload;
  markReleaseAsLive: MarkReleaseAsLivePayload;
  massResetTeam: EmptyPayload;
  publishPathway: PublishPathwayPayload;
  readMessage: ReadMessagePayload;
  rebuildGraph: EmptyPayload;
  rebuildGraphs: RebuildViewModelsPayload;
  rebuildViewModels: RebuildViewModelsPayload;
  recomputeCalculationDataSources: EmptyPayload;
  refreshDefinitionReferences: EmptyPayload;
  refreshExtension: RefreshExtensionPayload;
  removeActionFromStep: RemoveActionFromStepPayload;
  removeClinicalNoteNarrative: RemoveClinicalNoteNarrativePayload;
  removeConditionFromRule: RemoveConditionFromRulePayload;
  removeConstant: RemoveConstantPayload;
  removeDataPointDefinitionFromPathway: EmptyPayload;
  removeDataPointMappingFromExtensionAction: RemoveDataPointMappingFromExtensionActionPayload;
  removeDynamicVariable: EmptyPayload;
  removeExtensionConfiguration: EmptyPayload;
  removeLabelFromStep: RemoveLabelFromStepPayload;
  removeMappingFromApiCall: RemoveMappingFromApiCallPayload;
  removeMessageAttachment: MessagePayload;
  removeQuestionFromForm: RemoveQuestionFromFormPayload;
  removeRuleFromQuestion: RemoveRuleFromQuestionPayload;
  removeStepFromLibrary: EmptyPayload;
  removeStepFromTrack: RemoveStepFromTrackPayload;
  removeStickyNoteFromTrack: EmptyPayload;
  removeTrackFromPathway: RemoveTrackFromPathwayPayload;
  removeTransformationsFromDynamicVariable: RemoveTransformationsFromDynamicVariablePayload;
  removeTransition: RemoveTransitionPayload;
  removeTriggerFromPathway: EmptyPayload;
  removeTriggerFromTrack: EmptyPayload;
  removeTriggerTimer: TriggerPayload;
  removeWebhook: RemoveWebhookPayload;
  reorderActions: ReorderActionsPayload;
  reorderMessageAttachments: MessagePayload;
  reorderQuestions: ReorderQuestionsPayload;
  repairPathways: RepairPathwaysPayload;
  resetPatientProfileDataPointDefinitions: EmptyPayload;
  resetPreview: ResetPreviewPayload;
  retryAllFailedWebhookCalls: EmptyPayload;
  retryAllWebhookCalls: EmptyPayload;
  retryApiCall: EmptyPayload;
  retryWebhookCall: RetryWebhookCallPayload;
  saveMessage: MessagePayload;
  setActionCalculationId: SetActionCalculationIdPayload;
  setActionCalculationInput: SetActionCalculationInputPayload;
  setActionChecklistItems: SetActionChecklistItemsPayload;
  setActionFormDisplayMode: SetActionFormDisplayModePayload;
  setActionFormId: SetActionFormIdPayload;
  setActionIdleTime: SetActionIdleTimePayload;
  setActionMessageId: SetActionMessageIdPayload;
  setActionStakeholders: SetActionStakeholdersPayload;
  setActionTitle: SetActionTitlePayload;
  setApiCallBody: SetApiCallBodyPayload;
  setApiCallEndpoint: SetApiCallEndpointPayload;
  setApiCallHeaders: SetApiCallHeadersPayload;
  setApiCallMethod: SetApiCallMethodPayload;
  setBrandingAccentColor: SetBrandingAccentColorPayload;
  setBrandingCustomTheme: SetBrandingCustomThemePayload;
  setBrandingHostedPageAutoProgress: SetBrandingHostedPageAutoProgressPayload;
  setBrandingHostedPageAutosave: SetBrandingHostedPageAutosavePayload;
  setBrandingHostedPageTitle: SetBrandingHostedPageTitlePayload;
  setBrandingLogoUrl: SetBrandingLogoUrlPayload;
  setClinicalNoteNarrativeTitle: ClinicalNotePayload;
  setConditionOperand: SetConditionOperandPayload;
  setConditionOperator: SetConditionOperatorPayload;
  setConditionReference: SetConditionReferencePayload;
  setCustomActionField: SetCustomActionFieldPayload;
  setDashboardIds: SetDashboardIdsPayload;
  setDataPointDefinitionMetaDataField: SetDataPointDefinitionMetaDataFieldPayload;
  setDataPointDefinitionOptionalField: SetDataPointDefinitionOptionalFieldPayload;
  setDataPointDefinitionPiiField: SetDataPointDefinitionPiiFieldPayload;
  setDateQuestionConfig: SetDateQuestionConfigPayload;
  setDynamicVariableDataPointDefinition: SetDynamicVariableDataPointDefinitionPayload;
  setDynamicVariableDataPointProperty: SetDynamicVariableDataPointPropertyPayload;
  setDynamicVariableFallback: SetDynamicVariableFallbackPayload;
  setDynamicVariableLabel: SetDynamicVariableLabelPayload;
  setEmrReportBody: EmrReportPayload;
  setExtensionActionDataPointMappingDataPoint: SetExtensionActionDataPointMappingDataPointPayload;
  setExtensionActionDataPointMappingKey: SetExtensionActionDataPointMappingKeyPayload;
  setExtensionActionDataPointMappings: SetExtensionActionDataPointMappingsPayload;
  setExtensionActionField: SetExtensionActionFieldPayload;
  setFormKey: SetFormKeyPayload;
  setFormMetadata: SetFormMetadataPayload;
  setFormTitle: SetFormTitlePayload;
  setFormTrademark: SetFormTrademarkPayload;
  setMessageAttachmentName: MessagePayload;
  setMessageAttachmentUrl: MessagePayload;
  setMessageBody: MessagePayload;
  setMessageSubject: MessagePayload;
  setMultipleSelectQuestionConfig: SetMultipleSelectQuestionConfigPayload;
  setNumberQuestionConfig: SetNumberQuestionConfigPayload;
  setPageTitle?: Maybe<Scalars['Boolean']>;
  setPathwayCaseTitle: SetPathwayCaseTitlePayload;
  setPathwayTitle: SetPathwayTitlePayload;
  setPhoneQuestionConfig: SetPhoneQuestionConfigPayload;
  setQuestionConfig: SetQuestionConfigPayload;
  setQuestionKey: SetQuestionKeyPayload;
  setQuestionMetadata: SetQuestionMetadataPayload;
  setQuestionOptionValueType: SetQuestionOptionValueTypePayload;
  setQuestionsConfig: SetQuestionsConfigPayload;
  setRemindersAmount: SetRemindersAmountPayload;
  setRemindersDelay: SetRemindersDelayPayload;
  setRuleBooleanOperator: SetRuleBooleanOperatorPayload;
  setSliderQuestionConfig: SetSliderQuestionConfigPayload;
  setStakeholderEmails: SetStakeholderEmailsPayload;
  setStepCoordinates: SetStepCoordinatesPayload;
  setStepDocumentation: SetStepDocumentationPayload;
  setStepTitle: SetStepTitlePayload;
  setStickyNoteCoordinates: SetStickyNoteCoordinatesPayload;
  setTimingDelay: SetTimingDelayPayload;
  setTimingReference: SetTimingReferencePayload;
  setTrackEndCoordinates: SetTrackEndCoordinatesPayload;
  setTrackStartCoordinates: SetTrackStartCoordinatesPayload;
  /** Setting track title */
  setTrackTitle: SetTrackTitlePayload;
  setTransformationParams: SetTransformationParamsPayload;
  setTransformationType: SetTransformationTypePayload;
  setTransitionDestination: SetTransitionDestinationPayload;
  setTriggerDataPointDefinition: TriggerPayload;
  setTriggerDelay: TriggerPayload;
  setTriggerStep: TriggerPayload;
  setTriggerTimerDataPointDefinition: TriggerPayload;
  setTriggerTimerStep: TriggerPayload;
  setTriggerTimerTrack: TriggerPayload;
  setTriggerTimerType: TriggerPayload;
  setTriggerTrack: TriggerPayload;
  setTriggerType: TriggerPayload;
  shareForms: EmptyPayload;
  sharePathways: EmptyPayload;
  startPreview: StartPreviewPayload;
  startTrackFromStep: StartTrackFromStepPayload;
  startTransitionFromStep: StartTransitionFromStepPayload;
  submitChecklist: SubmitChecklistPayload;
  submitFormResponse: SubmitFormResponsePayload;
  testCareFlowSourceControlSettings: TestSourceControlPayload;
  transferPathway: EmptyPayload;
  trashPathway: EmptyPayload;
  triggerApiCall: TriggerApiCallPayload;
  updateApiCallMappingDataPoint: UpdateApiCallMappingDataPointPayload;
  updateApiCallMappingFirstMatchOnly: UpdateApiCallMappingFirstMatchOnlyPayload;
  updateApiCallMappingKey: UpdateApiCallMappingKeyPayload;
  updateCareFlowApiCallRetrySettings: UpdateCareFlowApiCallRetrySettingsPayload;
  updateCareFlowSourceControlSettings: UpdateCareFlowSourceControlSettingsPayload;
  updateClinicalNoteContext: UpdateClinicalNoteContextPayload;
  updateClinicalNoteNarrativeBody: ClinicalNotePayload;
  updateConstant: UpdateConstantPayload;
  updateDataPointDefinition: UpdateDataPointDefinitionPayload;
  updateDynamicVariable: UpdateDynamicVariablePayload;
  updateEmailNotificationStatus: UpdateEmailNotificationStatusPayload;
  updateEndPanelWidth?: Maybe<Scalars['Boolean']>;
  updateLabelText: UpdateLabelTextPayload;
  updateQuestion: UpdateQuestionPayload;
  updateSessionBuilderUrl?: Maybe<Scalars['Boolean']>;
  updateSessionSimulatorUrl?: Maybe<Scalars['Boolean']>;
  updateStartPanelWidth?: Maybe<Scalars['Boolean']>;
  updateStickyNoteBody: UpdateStickyNoteBodyPayload;
  updateWebhook: UpdateWebhookPayload;
  updateWebhookEndpoint: UpdateWebhookEndpointPayload;
  updateWebhookHeaders: UpdateWebhookHeadersPayload;
  updateWebhookName: UpdateWebhookNamePayload;
  updateWebhookStatus: UpdateWebhookStatusPayload;
  updateWebhookSubscribedEvents: UpdateWebhookSubscribedEventsPayload;
  updateWebhookTestEndpoint: UpdateWebhookTestEndpointPayload;
  updateZoomLevel?: Maybe<Scalars['Boolean']>;
};


export type MutationAddChecklistToStepArgs = {
  input: AddChecklistToStepInput;
};


export type MutationAddMessageToStepArgs = {
  input: AddMessageToStepInput;
};


export type MutationActivateTriggerArgs = {
  input: ActivateTriggerInput;
};


export type MutationAddActionToStepArgs = {
  input: AddActionToStepInput;
};


export type MutationAddConditionToRuleArgs = {
  input: AddConditionToRuleInput;
};


export type MutationAddConstantArgs = {
  input: AddConstantInput;
};


export type MutationAddCustomActionToPathwayArgs = {
  input: AddCustomActionToPathwayInput;
};


export type MutationAddCustomFieldToPathwayArgs = {
  input: AddCustomFieldToPathwayInput;
};


export type MutationAddDataPointDefinitionToPathwayArgs = {
  input: AddDataPointDefinitionToPathwayInput;
};


export type MutationAddDataPointMappingToExtensionActionArgs = {
  input: AddDataPointMappingToExtensionActionInput;
};


export type MutationAddDerivedDataPointDefinitionArgs = {
  input: AddDerivedDataPointDefinitionInput;
};


export type MutationAddLabelToStepArgs = {
  input: AddLabelToStepInput;
};


export type MutationAddLocationToPathwayArgs = {
  input: AddLocationToPathwayInput;
};


export type MutationAddMappingToApiCallArgs = {
  input: AddMappingToApiCallInput;
};


export type MutationAddMessageAttachmentArgs = {
  input: AddMessageAttachmentInput;
};


export type MutationAddNarrativeToClinicalNoteArgs = {
  input: AddNarrativeToClinicalNoteInput;
};


export type MutationAddQuestionToFormArgs = {
  input: AddQuestionToFormInput;
};


export type MutationAddQuestionsToFormArgs = {
  input: AddQuestionsToFormInput;
};


export type MutationAddRuleToQuestionArgs = {
  input: AddRuleToQuestionInput;
};


export type MutationAddStepFromLibraryToTrackArgs = {
  input: AddStepFromLibraryToTrackInput;
};


export type MutationAddStepFromTemplateArgs = {
  input: AddStepFromTemplateInput;
};


export type MutationAddStepToLibraryArgs = {
  input: AddStepToLibraryInput;
};


export type MutationAddStepToTrackArgs = {
  input: AddStepToTrackInput;
};


export type MutationAddStickyNoteToTrackArgs = {
  input: AddStickyNoteToTrackInput;
};


export type MutationAddTrackArgs = {
  input: AddTrackInput;
};


export type MutationAddTrackToPathwayArgs = {
  input: AddTrackToPathwayInput;
};


export type MutationAddTransformationToDynamicVariableArgs = {
  input: AddTransformationToDynamicVariableInput;
};


export type MutationAddTriggerTimerArgs = {
  input: AddTriggerTimerInput;
};


export type MutationAddTriggerToPathwayArgs = {
  input: AddTriggerToPathwayInput;
};


export type MutationAddTriggerToTrackArgs = {
  input: AddTriggerToTrackInput;
};


export type MutationAddWebhookArgs = {
  input: AddWebhookInput;
};


export type MutationClearActionIdleTimeArgs = {
  input: ClearActionIdleTimeInput;
};


export type MutationClearTimingDelayArgs = {
  input: ClearTimingDelayInput;
};


export type MutationClearTimingReferenceArgs = {
  input: ClearTimingReferenceInput;
};


export type MutationConfigureAhpLinkArgs = {
  input: ConfigureAhpLinkInput;
};


export type MutationConfigureExtensionArgs = {
  input: ConfigureExtensionInput;
};


export type MutationConnectStepsArgs = {
  input: ConnectStepsInput;
};


export type MutationCreateDynamicVariableArgs = {
  input: CreateDynamicVariableInput;
};


export type MutationCreateFormArgs = {
  input: CreateFormInput;
};


export type MutationCreatePathwayArgs = {
  input: CreatePathwayInput;
};


export type MutationCreatePathwayCaseArgs = {
  input: CreatePathwayCaseInput;
};


export type MutationDeletePathwayCaseArgs = {
  input: DeletePathwayCaseInput;
};


export type MutationDuplicateCareflowArgs = {
  input: DuplicateCareflowInput;
};


export type MutationDuplicateQuestionArgs = {
  input: DuplicateQuestionInput;
};


export type MutationDuplicateStepArgs = {
  input: DuplicateStepInput;
};


export type MutationEndTrackAfterStepArgs = {
  input: EndTrackAfterStepInput;
};


export type MutationEvaluateFormRulesArgs = {
  input: EvaluateFormRulesInput;
};


export type MutationExecuteTestApiCallArgs = {
  input: ExecuteTestApiCallInput;
};


export type MutationExportPathwayArgs = {
  input: ExportPathwayInput;
};


export type MutationImportPathwayArgs = {
  input: ImportPathwayInput;
};


export type MutationMarkReleaseAsLiveArgs = {
  input: MarkReleaseAsLiveInput;
};


export type MutationMassResetTeamArgs = {
  input: MassResetTeamInput;
};


export type MutationPublishPathwayArgs = {
  input: PublishPathwayInput;
};


export type MutationReadMessageArgs = {
  input: ReadMessageInput;
};


export type MutationRebuildGraphArgs = {
  pathway_definition_id: Scalars['String'];
};


export type MutationRebuildGraphsArgs = {
  input: RebuildInput;
};


export type MutationRebuildViewModelsArgs = {
  input: RebuildInput;
};


export type MutationRefreshExtensionArgs = {
  input: RefreshExtensionInput;
};


export type MutationRemoveActionFromStepArgs = {
  input: RemoveActionFromStepInput;
};


export type MutationRemoveClinicalNoteNarrativeArgs = {
  input: RemoveClinicalNoteNarrativeInput;
};


export type MutationRemoveConditionFromRuleArgs = {
  input: RemoveConditionFromRuleInput;
};


export type MutationRemoveConstantArgs = {
  input: RemoveConstantInput;
};


export type MutationRemoveDataPointDefinitionFromPathwayArgs = {
  input: RemoveDataPointDefinitionFromPathwayInput;
};


export type MutationRemoveDataPointMappingFromExtensionActionArgs = {
  input: RemoveDataPointMappingFromExtensionActionInput;
};


export type MutationRemoveDynamicVariableArgs = {
  input: RemoveDynamicVariableInput;
};


export type MutationRemoveExtensionConfigurationArgs = {
  input: RemoveExtensionConfigurationInput;
};


export type MutationRemoveLabelFromStepArgs = {
  input: RemoveLabelFromStepInput;
};


export type MutationRemoveMappingFromApiCallArgs = {
  input: RemoveMappingFromApiCallInput;
};


export type MutationRemoveMessageAttachmentArgs = {
  input: RemoveMessageAttachmentInput;
};


export type MutationRemoveQuestionFromFormArgs = {
  input: RemoveQuestionFromFormInput;
};


export type MutationRemoveRuleFromQuestionArgs = {
  input: RemoveRuleFromQuestionInput;
};


export type MutationRemoveStepFromLibraryArgs = {
  input: RemoveStepFromLibraryInput;
};


export type MutationRemoveStepFromTrackArgs = {
  input: RemoveStepFromTrackInput;
};


export type MutationRemoveStickyNoteFromTrackArgs = {
  input: RemoveStickyNoteFromTrackInput;
};


export type MutationRemoveTrackFromPathwayArgs = {
  input: RemoveTrackFromPathwayInput;
};


export type MutationRemoveTransformationsFromDynamicVariableArgs = {
  input: RemoveTransformationsFromDynamicVariableInput;
};


export type MutationRemoveTransitionArgs = {
  input: RemoveTransitionInput;
};


export type MutationRemoveTriggerFromPathwayArgs = {
  input: RemoveTriggerFromPathwayInput;
};


export type MutationRemoveTriggerFromTrackArgs = {
  input: RemoveTriggerFromTrackInput;
};


export type MutationRemoveTriggerTimerArgs = {
  input: RemoveTriggerTimerInput;
};


export type MutationRemoveWebhookArgs = {
  input: RemoveWebhookInput;
};


export type MutationReorderActionsArgs = {
  input: ReorderActionsInput;
};


export type MutationReorderMessageAttachmentsArgs = {
  input: ReorderMessageAttachmentsInput;
};


export type MutationReorderQuestionsArgs = {
  input: ReorderQuestionsInput;
};


export type MutationRepairPathwaysArgs = {
  input: RepairPathwaysInput;
};


export type MutationResetPatientProfileDataPointDefinitionsArgs = {
  input: ResetPatientProfileDataPointDefinitionsInput;
};


export type MutationResetPreviewArgs = {
  input: ResetPreviewInput;
};


export type MutationRetryAllFailedWebhookCallsArgs = {
  input: RetryAllFailedWebhookCallsInput;
};


export type MutationRetryAllWebhookCallsArgs = {
  input: RetryAllWebhookCallsInput;
};


export type MutationRetryApiCallArgs = {
  input: RetryApiCallInput;
};


export type MutationRetryWebhookCallArgs = {
  input: RetryWebhookCallInput;
};


export type MutationSaveMessageArgs = {
  input: SaveMessageInput;
};


export type MutationSetActionCalculationIdArgs = {
  input: SetActionCalculationIdInput;
};


export type MutationSetActionCalculationInputArgs = {
  input: SetCalculationDataPointInput;
};


export type MutationSetActionChecklistItemsArgs = {
  input: SetActionChecklistItemsInput;
};


export type MutationSetActionFormDisplayModeArgs = {
  input: SetActionFormDisplayModeInput;
};


export type MutationSetActionFormIdArgs = {
  input: SetActionFormIdInput;
};


export type MutationSetActionIdleTimeArgs = {
  input: SetActionIdleTimeInput;
};


export type MutationSetActionMessageIdArgs = {
  input: SetActionMessageIdInput;
};


export type MutationSetActionStakeholdersArgs = {
  input: SetActionStakeholdersInput;
};


export type MutationSetActionTitleArgs = {
  input: SetActionTitleInput;
};


export type MutationSetApiCallBodyArgs = {
  input: SetApiCallBodyInput;
};


export type MutationSetApiCallEndpointArgs = {
  input: SetApiCallEndpointInput;
};


export type MutationSetApiCallHeadersArgs = {
  input: SetApiCallHeadersInput;
};


export type MutationSetApiCallMethodArgs = {
  input: SetApiCallMethodInput;
};


export type MutationSetBrandingAccentColorArgs = {
  input: SetBrandingAccentColorInput;
};


export type MutationSetBrandingCustomThemeArgs = {
  input: SetBrandingCustomThemeInput;
};


export type MutationSetBrandingHostedPageAutoProgressArgs = {
  input: SetBrandingHostedPageAutoProgressInput;
};


export type MutationSetBrandingHostedPageAutosaveArgs = {
  input: SetBrandingHostedPageAutosaveInput;
};


export type MutationSetBrandingHostedPageTitleArgs = {
  input: SetBrandingHostedPageTitleInput;
};


export type MutationSetBrandingLogoUrlArgs = {
  input: SetBrandingLogoUrlInput;
};


export type MutationSetClinicalNoteNarrativeTitleArgs = {
  input: SetClinicalNoteNarrativeTitleInput;
};


export type MutationSetConditionOperandArgs = {
  input: SetConditionOperandInput;
};


export type MutationSetConditionOperatorArgs = {
  input: SetConditionOperatorInput;
};


export type MutationSetConditionReferenceArgs = {
  input: SetConditionReferenceInput;
};


export type MutationSetCustomActionFieldArgs = {
  input: SetCustomActionFieldInput;
};


export type MutationSetDashboardIdsArgs = {
  input: SetDashboardIdsInput;
};


export type MutationSetDataPointDefinitionMetaDataFieldArgs = {
  input: SetDataPointDefinitionMetaDataFieldInput;
};


export type MutationSetDataPointDefinitionOptionalFieldArgs = {
  input: SetDataPointDefinitionOptionalFieldInput;
};


export type MutationSetDataPointDefinitionPiiFieldArgs = {
  input: SetDataPointDefinitionPiiFieldInput;
};


export type MutationSetDateQuestionConfigArgs = {
  input: SetDateQuestionConfigInput;
};


export type MutationSetDynamicVariableDataPointDefinitionArgs = {
  input: SetDynamicVariableDataPointDefinitionInput;
};


export type MutationSetDynamicVariableDataPointPropertyArgs = {
  input: SetDynamicVariableDataPointPropertyInput;
};


export type MutationSetDynamicVariableFallbackArgs = {
  input: SetDynamicVariableFallbackInput;
};


export type MutationSetDynamicVariableLabelArgs = {
  input: SetDynamicVariableLabelInput;
};


export type MutationSetEmrReportBodyArgs = {
  input: SetEmrReportBodyInput;
};


export type MutationSetExtensionActionDataPointMappingDataPointArgs = {
  input: SetExtensionActionDataPointMappingDataPointInput;
};


export type MutationSetExtensionActionDataPointMappingKeyArgs = {
  input: SetExtensionActionDataPointMappingKeyInput;
};


export type MutationSetExtensionActionDataPointMappingsArgs = {
  input: SetExtensionActionDataPointMappingsInput;
};


export type MutationSetExtensionActionFieldArgs = {
  input: SetExtensionActionFieldInput;
};


export type MutationSetFormKeyArgs = {
  input: SetFormKeyInput;
};


export type MutationSetFormMetadataArgs = {
  input: SetFormMetadataInput;
};


export type MutationSetFormTitleArgs = {
  input: SetFormTitleInput;
};


export type MutationSetFormTrademarkArgs = {
  input: SetFormTrademarkInput;
};


export type MutationSetMessageAttachmentNameArgs = {
  input: SetMessageAttachmentNameInput;
};


export type MutationSetMessageAttachmentUrlArgs = {
  input: SetMessageAttachmentUrlInput;
};


export type MutationSetMessageBodyArgs = {
  input: SetMessageBodyInput;
};


export type MutationSetMessageSubjectArgs = {
  input: SetMessageSubjectInput;
};


export type MutationSetMultipleSelectQuestionConfigArgs = {
  input: SetMultipleSelectQuestionConfigInput;
};


export type MutationSetNumberQuestionConfigArgs = {
  input: SetNumberQuestionConfigInput;
};


export type MutationSetPageTitleArgs = {
  title?: Maybe<Scalars['String']>;
};


export type MutationSetPathwayCaseTitleArgs = {
  input: SetPathwayCaseTitleInput;
};


export type MutationSetPathwayTitleArgs = {
  input: SetPathwayTitleInput;
};


export type MutationSetPhoneQuestionConfigArgs = {
  input: SetPhoneQuestionConfigInput;
};


export type MutationSetQuestionConfigArgs = {
  input: SetQuestionConfigInput;
};


export type MutationSetQuestionKeyArgs = {
  input: SetQuestionKeyInput;
};


export type MutationSetQuestionMetadataArgs = {
  input: SetQuestionMetadataInput;
};


export type MutationSetQuestionOptionValueTypeArgs = {
  input: SetQuestionOptionValueTypeInput;
};


export type MutationSetQuestionsConfigArgs = {
  input: SetQuestionsConfigInput;
};


export type MutationSetRemindersAmountArgs = {
  input: SetRemindersAmountInput;
};


export type MutationSetRemindersDelayArgs = {
  input: SetRemindersDelayInput;
};


export type MutationSetRuleBooleanOperatorArgs = {
  input: SetRuleBooleanOperatorInput;
};


export type MutationSetSliderQuestionConfigArgs = {
  input: SetSliderQuestionConfigInput;
};


export type MutationSetStakeholderEmailsArgs = {
  input: SetStakeholderEmailsInput;
};


export type MutationSetStepCoordinatesArgs = {
  input: SetStepCoordinatesInput;
};


export type MutationSetStepDocumentationArgs = {
  input: SetStepDocumentationInput;
};


export type MutationSetStepTitleArgs = {
  input: SetStepTitleInput;
};


export type MutationSetStickyNoteCoordinatesArgs = {
  input: SetStickyNoteCoordinatesInput;
};


export type MutationSetTimingDelayArgs = {
  input: SetTimingDelayInput;
};


export type MutationSetTimingReferenceArgs = {
  input: SetTimingReferenceInput;
};


export type MutationSetTrackEndCoordinatesArgs = {
  input: SetTrackEndCoordinatesInput;
};


export type MutationSetTrackStartCoordinatesArgs = {
  input: SetTrackStartCoordinatesInput;
};


export type MutationSetTrackTitleArgs = {
  input: SetTrackTitleInput;
};


export type MutationSetTransformationParamsArgs = {
  input: SetTransformationParamsInput;
};


export type MutationSetTransformationTypeArgs = {
  input: SetTransformationTypeInput;
};


export type MutationSetTransitionDestinationArgs = {
  input: SetTransitionDestinationInput;
};


export type MutationSetTriggerDataPointDefinitionArgs = {
  input: SetTriggerDataPointDefinitionInput;
};


export type MutationSetTriggerDelayArgs = {
  input: SetTriggerDelayInput;
};


export type MutationSetTriggerStepArgs = {
  input: SetTriggerStepInput;
};


export type MutationSetTriggerTimerDataPointDefinitionArgs = {
  input: SetTriggerTimerDataPointDefinitionInput;
};


export type MutationSetTriggerTimerStepArgs = {
  input: SetTriggerTimerStepInput;
};


export type MutationSetTriggerTimerTrackArgs = {
  input: SetTriggerTimerTrackInput;
};


export type MutationSetTriggerTimerTypeArgs = {
  input: SetTriggerTimerTypeInput;
};


export type MutationSetTriggerTrackArgs = {
  input: SetTriggerTrackInput;
};


export type MutationSetTriggerTypeArgs = {
  input: SetTriggerTypeInput;
};


export type MutationShareFormsArgs = {
  input: ShareFormsInput;
};


export type MutationSharePathwaysArgs = {
  input: SharePathwaysInput;
};


export type MutationStartPreviewArgs = {
  input: StartPreviewInput;
};


export type MutationStartTrackFromStepArgs = {
  input: StartTrackFromStepInput;
};


export type MutationStartTransitionFromStepArgs = {
  input: StartTransitionFromStepInput;
};


export type MutationSubmitChecklistArgs = {
  input: SubmitChecklistInput;
};


export type MutationSubmitFormResponseArgs = {
  input: SubmitFormResponseInput;
};


export type MutationTestCareFlowSourceControlSettingsArgs = {
  input: TestSourceControlInput;
};


export type MutationTransferPathwayArgs = {
  input: TransferPathwayInput;
};


export type MutationTrashPathwayArgs = {
  input: TrashPathwayInput;
};


export type MutationTriggerApiCallArgs = {
  input: TriggerApiCallInput;
};


export type MutationUpdateApiCallMappingDataPointArgs = {
  input: UpdateApiCallMappingDataPointInput;
};


export type MutationUpdateApiCallMappingFirstMatchOnlyArgs = {
  input: UpdateApiCallMappingFirstMatchOnlyInput;
};


export type MutationUpdateApiCallMappingKeyArgs = {
  input: UpdateApiCallMappingKeyInput;
};


export type MutationUpdateCareFlowApiCallRetrySettingsArgs = {
  input: UpdateCareFlowApiCallRetrySettingsInput;
};


export type MutationUpdateCareFlowSourceControlSettingsArgs = {
  input: UpdateCareFlowSourceControlSettingsInput;
};


export type MutationUpdateClinicalNoteContextArgs = {
  input: UpdateClinicalNoteContextInput;
};


export type MutationUpdateClinicalNoteNarrativeBodyArgs = {
  input: UpdateClinicalNoteNarrativeBodyInput;
};


export type MutationUpdateConstantArgs = {
  input: UpdateConstantInput;
};


export type MutationUpdateDataPointDefinitionArgs = {
  input: UpdateDataPointDefinitionInput;
};


export type MutationUpdateDynamicVariableArgs = {
  input: UpdateDynamicVariableInput;
};


export type MutationUpdateEmailNotificationStatusArgs = {
  input: UpdateEmailNotificationStatusInput;
};


export type MutationUpdateEndPanelWidthArgs = {
  width?: Maybe<Scalars['Int']>;
};


export type MutationUpdateLabelTextArgs = {
  input: UpdateLabelTextInput;
};


export type MutationUpdateQuestionArgs = {
  input: UpdateQuestionInput;
};


export type MutationUpdateSessionBuilderUrlArgs = {
  url?: Maybe<Scalars['String']>;
};


export type MutationUpdateSessionSimulatorUrlArgs = {
  url?: Maybe<Scalars['String']>;
};


export type MutationUpdateStartPanelWidthArgs = {
  width?: Maybe<Scalars['Int']>;
};


export type MutationUpdateStickyNoteBodyArgs = {
  input: UpdateStickyNoteBodyInput;
};


export type MutationUpdateWebhookArgs = {
  input: UpdateWebhookInput;
};


export type MutationUpdateWebhookEndpointArgs = {
  input: UpdateWebhookEndpointInput;
};


export type MutationUpdateWebhookHeadersArgs = {
  input: UpdateWebhookHeadersInput;
};


export type MutationUpdateWebhookNameArgs = {
  input: UpdateWebhookNameInput;
};


export type MutationUpdateWebhookStatusArgs = {
  input: UpdateWebhookStatusInput;
};


export type MutationUpdateWebhookSubscribedEventsArgs = {
  input: UpdateWebhookSubscribedEventsInput;
};


export type MutationUpdateWebhookTestEndpointArgs = {
  input: UpdateWebhookTestEndpointInput;
};


export type MutationUpdateZoomLevelArgs = {
  percent?: Maybe<Scalars['Float']>;
};

export type NewConstantInput = {
  label: Scalars['String'];
  obfuscated: Scalars['Boolean'];
  value: Scalars['String'];
};

export type NewMappingDataPointDefinitionInput = {
  key: Scalars['String'];
  valueType: DataPointValueType;
};

export type NewWebhookInput = {
  enabled?: Scalars['Boolean'];
  endpoint: Scalars['String'];
  endpoint_test?: Maybe<Scalars['String']>;
  headers?: Array<WebhookHeaderInput>;
  name: Scalars['String'];
  subscribed_events?: Maybe<Array<Scalars['String']>>;
};

export type NotSupportedExtensionAction = {
  __typename?: 'NotSupportedExtensionAction';
  action: UsageContext;
  step: UsageContext;
  track: UsageContext;
};

export type NumberConfig = {
  __typename?: 'NumberConfig';
  range?: Maybe<RangeConfig>;
};

export type NumberConfigInput = {
  range?: Maybe<NumberRangeConfigInput>;
};

export type NumberQuestionConfigInput = {
  number: NumberConfigInput;
};

export type NumberRangeConfigInput = {
  enabled?: Maybe<Scalars['Boolean']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
};

export type NumericArrayField = ExtensionActionField & {
  __typename?: 'NumericArrayField';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  label: Scalars['String'];
  required?: Maybe<Scalars['Boolean']>;
  type: ExtensionActionFieldType;
  /** Value is kept as string because it can contain data point definition id in handlebars template, otherwise it is just "array of numbers" */
  value?: Maybe<Scalars['String']>;
};

export type NumericField = ExtensionActionField & {
  __typename?: 'NumericField';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  label: Scalars['String'];
  required?: Maybe<Scalars['Boolean']>;
  type: ExtensionActionFieldType;
  /** Value is kept as string because it can contain data point definition id in handlebars template, otherwise it is just "number" */
  value?: Maybe<Scalars['String']>;
};

export type Operand = {
  __typename?: 'Operand';
  type: ConditionOperandType;
  value: Scalars['String'];
};

export type OperandInput = {
  type: ConditionOperandType;
  value: Scalars['String'];
};

export type Option = {
  __typename?: 'Option';
  id: Scalars['ID'];
  label: Scalars['String'];
  value: Scalars['String'];
};

export type OptionInput = {
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type PaginationAndSortingPayload = {
  code: Scalars['String'];
  pagination?: Maybe<PaginationOutput>;
  sorting?: Maybe<SortingOutput>;
  success: Scalars['Boolean'];
};

export type PaginationOutput = {
  __typename?: 'PaginationOutput';
  count?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  total_count?: Maybe<Scalars['Int']>;
};

export type PaginationParams = {
  count: Scalars['Int'];
  offset: Scalars['Int'];
};

/**
 * A pathway is a set of tracks.
 * Transitions join tracks together (see definition of a transition at |TBD|)
 * A pathway starts with a start node and ends with an end nodeS
 *
 * A patient in a pathway generates a pathway instance
 */
export type Pathway = {
  __typename?: 'Pathway';
  created: AuditTrail;
  custom_actions?: Maybe<Array<CustomAction>>;
  id: Scalars['ID'];
  labels?: Maybe<Array<Label>>;
  last_published_version?: Maybe<PathwayVersion>;
  last_updated?: Maybe<AuditTrail>;
  locations?: Maybe<Array<Location>>;
  publishing_status: PublishingStatus;
  readonly?: Maybe<Scalars['Boolean']>;
  settings?: Maybe<PathwaySettings>;
  stakeholders: Array<Stakeholder>;
  team_ids?: Maybe<Array<Scalars['String']>>;
  title: Scalars['String'];
  track_ids?: Maybe<Array<Scalars['String']>>;
  tracks?: Maybe<Array<Track>>;
  trashed?: Maybe<Trashed>;
  triggers: Array<Trigger>;
};

export type PathwayCase = {
  __typename?: 'PathwayCase';
  activities: Array<Activity>;
  created_by: PathwayCollaborator;
  id: Scalars['ID'];
  last_modification_date?: Maybe<Scalars['SafeDate']>;
  last_modified_by?: Maybe<PathwayCollaborator>;
  pathway_id: Scalars['String'];
  start_date?: Maybe<Scalars['SafeDate']>;
  status: PathwayCaseStatus;
  title: Scalars['String'];
};

export type PathwayCaseApiCall = {
  __typename?: 'PathwayCaseApiCall';
  created_at: Scalars['String'];
  id: Scalars['ID'];
  request: ApiCallRequest;
  responses: Array<ApiCallResponse>;
  status: ApiCallStatus;
  title: Scalars['String'];
};

export type PathwayCaseApiCallPayload = Payload & {
  __typename?: 'PathwayCaseApiCallPayload';
  api_call: PathwayCaseApiCall;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type PathwayCaseApiCallsPayload = Payload & {
  __typename?: 'PathwayCaseApiCallsPayload';
  api_calls: Array<PathwayCaseApiCall>;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type PathwayCasePayload = Payload & {
  __typename?: 'PathwayCasePayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  pathway_case: PathwayCase;
  success: Scalars['Boolean'];
};

export enum PathwayCaseStatus {
  Active = 'ACTIVE',
  Blank = 'BLANK',
  Completed = 'COMPLETED',
  MissingBaselineDatapoints = 'MISSING_BASELINE_DATAPOINTS',
  WaitingForManualTrigger = 'WAITING_FOR_MANUAL_TRIGGER'
}

export type PathwayCaseStepActivitiesInput = {
  pathway_case_id: Scalars['String'];
  step_id: Scalars['String'];
};

export type PathwayCaseWebhookCall = {
  __typename?: 'PathwayCaseWebhookCall';
  created_at: Scalars['String'];
  event_type: Scalars['String'];
  id: Scalars['ID'];
  pathway?: Maybe<ApiPathwayContext>;
  request: WebhookCallRequest;
  responses: Array<WebhookCallResponse>;
  status: Scalars['String'];
  webhook_id: Scalars['String'];
  webhook_name: Scalars['String'];
};

export type PathwayCaseWebhookCallPayload = Payload & {
  __typename?: 'PathwayCaseWebhookCallPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  webhook_call: PathwayCaseWebhookCall;
};

export type PathwayCaseWebhookCallsPayload = Payload & {
  __typename?: 'PathwayCaseWebhookCallsPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  webhook_calls: Array<PathwayCaseWebhookCall>;
};

export type PathwayCasesInput = {
  pathway_id: Scalars['String'];
};

export type PathwayCasesPayload = Payload & {
  __typename?: 'PathwayCasesPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  pathway_cases: Array<PathwayCase>;
  success: Scalars['Boolean'];
};

export type PathwayCollaborator = {
  __typename?: 'PathwayCollaborator';
  user_email?: Maybe<Scalars['String']>;
  user_id: Scalars['String'];
};

export type PathwayComponentLink = {
  __typename?: 'PathwayComponentLink';
  text: Scalars['String'];
  url: Scalars['String'];
};

export type PathwayContext = {
  __typename?: 'PathwayContext';
  action_id?: Maybe<Scalars['String']>;
  instance_id: Scalars['String'];
  pathway_id: Scalars['String'];
  step_id?: Maybe<Scalars['String']>;
  track_id?: Maybe<Scalars['String']>;
};

/** Diff between two care flows. Used to help understand how care flows have changed over time. */
export type PathwayDiff = {
  __typename?: 'PathwayDiff';
  newPathway?: Maybe<Scalars['String']>;
  oldPathway?: Maybe<Scalars['String']>;
};

export type PathwayDiffPayload = Payload & {
  __typename?: 'PathwayDiffPayload';
  code: Scalars['String'];
  diff: PathwayDiff;
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type PathwayPayload = Payload & {
  __typename?: 'PathwayPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  pathway: Pathway;
  success: Scalars['Boolean'];
};

export type PathwaySettings = {
  __typename?: 'PathwaySettings';
  allow_email_notifications?: Maybe<Scalars['Boolean']>;
  api_call_retry_settings?: Maybe<ApiCallRetrySettings>;
  branding?: Maybe<BrandingSettings>;
  constants?: Maybe<Array<Constant>>;
  dashboard_ids?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  reminders?: Maybe<RemindersSettings>;
  source_control?: Maybe<SourceControlSettings>;
  stakeholder_notification_language?: Maybe<Scalars['String']>;
  stakeholders?: Maybe<Array<StakeholderEmail>>;
  webhooks?: Maybe<Array<Webhook>>;
};

export type PathwaySettingsPayload = Payload & {
  __typename?: 'PathwaySettingsPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  settings: PathwaySettings;
  success: Scalars['Boolean'];
};

export type PathwayStartedActivationReference = TriggerActivationReference & {
  __typename?: 'PathwayStartedActivationReference';
  type?: Maybe<TriggerActivationReferenceType>;
};

export type PathwayStartedTriggerSettings = TriggerSettings & {
  __typename?: 'PathwayStartedTriggerSettings';
  rule?: Maybe<Rule>;
  type?: Maybe<TriggerType>;
};

export type PathwayValidationStatus = {
  __typename?: 'PathwayValidationStatus';
  components: Array<PathwayValidationStatusComponent>;
  status: PathwayValidationStatusType;
};

export type PathwayValidationStatusComponent = {
  __typename?: 'PathwayValidationStatusComponent';
  id: Scalars['String'];
  link?: Maybe<PathwayComponentLink>;
  reason?: Maybe<InvalidStatusReason>;
  status: PathwayValidationStatusType;
  type: PathwayValidationStatusComponentType;
};

export enum PathwayValidationStatusComponentType {
  Cycle = 'CYCLE',
  DefinitionNavigationGraph = 'DEFINITION_NAVIGATION_GRAPH',
  Rule = 'RULE',
  Transition = 'TRANSITION',
  Trigger = 'TRIGGER'
}

export type PathwayValidationStatusPayload = Payload & {
  __typename?: 'PathwayValidationStatusPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  status: PathwayValidationStatus;
  success: Scalars['Boolean'];
};

export enum PathwayValidationStatusReasonType {
  CycleDetected = 'CYCLE_DETECTED',
  Duplicate = 'DUPLICATE',
  IncompleteCondition = 'INCOMPLETE_CONDITION',
  IncompleteTiming = 'INCOMPLETE_TIMING',
  InvalidTrigger = 'INVALID_TRIGGER',
  MissingActions = 'MISSING_ACTIONS',
  MissingDestination = 'MISSING_DESTINATION',
  MissingPathwayStartedTrigger = 'MISSING_PATHWAY_STARTED_TRIGGER',
  MissingSteps = 'MISSING_STEPS',
  MissingTrigger = 'MISSING_TRIGGER',
  NotFound = 'NOT_FOUND',
  Unknown = 'UNKNOWN'
}

export enum PathwayValidationStatusType {
  Error = 'ERROR',
  Valid = 'VALID',
  Warning = 'WARNING'
}

/**
 * Pathway Version stores information related to publishing.
 * It contains version/publish information of all components (Pathway/Step/Transition)
 * in a pathway.
 */
export type PathwayVersion = {
  __typename?: 'PathwayVersion';
  comments?: Maybe<FormattedText>;
  commit_link?: Maybe<Scalars['String']>;
  created_at: Scalars['SafeDate'];
  created_by: PathwayCollaborator;
  id: Scalars['ID'];
  version: Scalars['Float'];
  version_status: VersionStatus;
};

export type PathwayVersionPayload = Payload & {
  __typename?: 'PathwayVersionPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  version: PathwayVersion;
};

export type PathwayVersionsPayload = Payload & {
  __typename?: 'PathwayVersionsPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  versions: Array<PathwayVersion>;
};

export type PathwaysPayload = Payload & {
  __typename?: 'PathwaysPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  pathways: Array<Pathway>;
  success: Scalars['Boolean'];
};

export type Payload = {
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type Permissions = {
  __typename?: 'Permissions';
  write: Scalars['Boolean'];
};

export type PhoneConfig = {
  __typename?: 'PhoneConfig';
  available_countries?: Maybe<Array<Scalars['String']>>;
  default_country?: Maybe<Scalars['String']>;
};

export type PhoneConfigInput = {
  available_countries?: Maybe<Array<Scalars['String']>>;
  default_country?: Maybe<Scalars['String']>;
};

export type PhoneQuestionConfigInput = {
  phone: PhoneConfigInput;
};

export type PluginActionSettingsProperty = {
  __typename?: 'PluginActionSettingsProperty';
  key: Scalars['String'];
  label: Scalars['String'];
  value: Scalars['String'];
};

export type PreviewClinicalNote = {
  __typename?: 'PreviewClinicalNote';
  context: Array<GeneratedClinicalNoteContextField>;
  id: Scalars['ID'];
  narratives: Array<GeneratedClinicalNoteNarrative>;
};

export type PreviewEmrReport = {
  __typename?: 'PreviewEmrReport';
  id: Scalars['ID'];
  message_html: Scalars['String'];
  metadata?: Maybe<Array<EmrReportMetadataField>>;
};

export type PreviewMessage = {
  __typename?: 'PreviewMessage';
  attachments?: Maybe<Array<MessageAttachment>>;
  body: Scalars['String'];
  format: MessageFormat;
  id: Scalars['ID'];
  subject?: Maybe<Scalars['String']>;
};

export type PublishPathwayInput = {
  comments?: Maybe<FormattedTextInput>;
  pathway_id: Scalars['String'];
};

export type PublishPathwayPayload = Payload & {
  __typename?: 'PublishPathwayPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  published_version: PathwayVersion;
  success: Scalars['Boolean'];
};

export enum PublishingStatus {
  HasUnpublishedChanges = 'HAS_UNPUBLISHED_CHANGES',
  NoUnpublishedChanges = 'NO_UNPUBLISHED_CHANGES'
}

export type PushToEmrAction = Action & {
  __typename?: 'PushToEmrAction';
  clinical_note_id?: Maybe<Scalars['ID']>;
  created: AuditTrail;
  custom_fields?: Maybe<Array<CustomActionField>>;
  emr_report?: Maybe<EmrReport>;
  emr_report_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  idle_time?: Maybe<Delay>;
  last_updated?: Maybe<AuditTrail>;
  locations?: Maybe<Array<Location>>;
  message_id?: Maybe<Scalars['ID']>;
  plugin_key?: Maybe<Scalars['String']>;
  stakeholders: Array<Stakeholder>;
  status: ConfigurationStatus;
  step: Step;
  title?: Maybe<Scalars['String']>;
  type: ActionType;
};

export type Query = {
  __typename?: 'Query';
  action: ActionPayload;
  adHocTracks: TracksPayload;
  ahp_link: AhpLinkPayload;
  ahp_links: AhpLinkPayloads;
  baseline_info: BaselineInfoPayload;
  calculation_results: CalculationResultPayload;
  calculations: CalculationsPayload;
  calculationsLibrary?: Maybe<Array<Maybe<CalculationLibraryType>>>;
  careflow_components: CareflowComponentsPayload;
  clinical_note: ClinicalNotePayload;
  constant: ConstantPayload;
  constants: ConstantsPayload;
  data_catalog: DataCatalogPayload;
  /** Export data point definitions in JSON format */
  data_catalog_json: DataCatalogJsonPayload;
  data_point_definition: DataPointDefinitionPayload;
  data_point_definitions: DataPointDefinitionsPayload;
  dynamic_variable: DynamicVariablePayload;
  dynamic_variables: DynamicVariablesPayload;
  emr_report: EmrReportPayload;
  evaluatedRule: EvaluatedRulePayload;
  events: DomainEventsPayload;
  extensionActivityRecord: ExtensionActivityRecordPayload;
  extension_configurations: ExtensionConfigurationsPayload;
  extensions: ExtensionsPayload;
  form: FormPayload;
  formBuilderLayout?: Maybe<FormBuilderLayout>;
  form_data_point_definitions: DataPointDefinitionsPayload;
  form_response: FormResponsePayload;
  forms: FormsPayload;
  generated_clinical_note: GeneratedClinicalNotePayload;
  generated_emr_report: GeneratedEmrReportPayload;
  generated_message: GeneratedMessagePayload;
  isPathwayReadyForPreview: PathwayValidationStatusPayload;
  is_slug_unique: IsSlugUniquePayload;
  jsonPathFromMockObject: JsonPathPayload;
  jsonSchema: SchemaPayload;
  layout?: Maybe<Layout>;
  listForms: FormsPayload;
  listPathways: PathwaysPayload;
  locations: LocationsPayload;
  message: MessagePayload;
  mockObjectFromJsonSchema: MockObjectPayload;
  pageTitle?: Maybe<Scalars['String']>;
  pathway: PathwayPayload;
  pathwayCaseActivities: ActivitiesPayload;
  pathwayCaseApiCall: PathwayCaseApiCallPayload;
  pathwayCaseApiCalls: PathwayCaseApiCallsPayload;
  pathwayCaseElements: ElementsPayload;
  pathwayCaseStepActivities: ActivitiesPayload;
  pathwayCaseTriggerActivation: TriggerActivationPayload;
  pathwayCaseTriggerActivations: TriggerActivationsPayload;
  pathwayCaseWebhookCall: PathwayCaseWebhookCallPayload;
  pathwayCaseWebhookCalls: PathwayCaseWebhookCallsPayload;
  pathwayDiffTree: PathwayDiffPayload;
  pathwayDiffs: PathwayDiffPayload;
  pathwayExtensions: ExtensionsPayload;
  pathwaySettings: PathwaySettingsPayload;
  pathwaySteps: StepsPayload;
  pathway_case: PathwayCasePayload;
  pathway_cases: PathwayCasesPayload;
  pathway_version: PathwayVersionPayload;
  pathway_versions: PathwayVersionsPayload;
  pathways: PathwaysPayload;
  rule: RulePayload;
  sessionUrls?: Maybe<SessionUrls>;
  simulatorLayout?: Maybe<SimulatorLayout>;
  stakeholders: StakeholdersPayload;
  step: StepPayload;
  stepLibrary: StepLibraryPayload;
  stepLibraryForPathway: StepLibraryPayload;
  steps: StepsPayload;
  stickyNote: StickyNotePayload;
  stickyNotes: StickyNotesPayload;
  tenant: TenantPayload;
  tenants: TenantsPayload;
  timing: TimingPayload;
  track: TrackPayload;
  trackCanvasSettings?: Maybe<Array<Maybe<TrackCanvasSettings>>>;
  transition: TransitionPayload;
  transitions: TransitionsPayload;
  user: UserPayload;
  webhook: WebhookPayload;
};


export type QueryActionArgs = {
  id: Scalars['String'];
};


export type QueryAdHocTracksArgs = {
  pathway_definition_id: Scalars['String'];
};


export type QueryAhp_LinkArgs = {
  id: Scalars['String'];
};


export type QueryAhp_LinksArgs = {
  careflow_id: Scalars['String'];
};


export type QueryBaseline_InfoArgs = {
  pathway_case_id: Scalars['String'];
};


export type QueryCalculation_ResultsArgs = {
  input: CalculationResultInput;
};


export type QueryCareflow_ComponentsArgs = {
  id: Scalars['String'];
};


export type QueryClinical_NoteArgs = {
  id: Scalars['String'];
};


export type QueryConstantArgs = {
  input: ConstantInput;
};


export type QueryConstantsArgs = {
  pathway_id: Scalars['String'];
};


export type QueryData_CatalogArgs = {
  input: DataCatalogInput;
};


export type QueryData_Catalog_JsonArgs = {
  input: DataCatalogInput;
};


export type QueryData_Point_DefinitionArgs = {
  id: Scalars['String'];
};


export type QueryData_Point_DefinitionsArgs = {
  input: DataPointDefinitionsInput;
};


export type QueryDynamic_VariableArgs = {
  id: Scalars['String'];
};


export type QueryDynamic_VariablesArgs = {
  pathway_id: Scalars['String'];
};


export type QueryEmr_ReportArgs = {
  id: Scalars['String'];
};


export type QueryEvaluatedRuleArgs = {
  id: Scalars['String'];
};


export type QueryEventsArgs = {
  input: EventsInput;
};


export type QueryExtensionActivityRecordArgs = {
  id: Scalars['String'];
};


export type QueryExtension_ConfigurationsArgs = {
  pathway_id: Scalars['String'];
};


export type QueryFormArgs = {
  id: Scalars['String'];
};


export type QueryForm_Data_Point_DefinitionsArgs = {
  input: FormDataPointDefinitionsInput;
};


export type QueryForm_ResponseArgs = {
  input: FormResponseInput;
};


export type QueryGenerated_Clinical_NoteArgs = {
  id: Scalars['String'];
};


export type QueryGenerated_Emr_ReportArgs = {
  id: Scalars['String'];
};


export type QueryGenerated_MessageArgs = {
  id: Scalars['String'];
};


export type QueryIsPathwayReadyForPreviewArgs = {
  pathway_definition_id: Scalars['String'];
};


export type QueryIs_Slug_UniqueArgs = {
  id?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
};


export type QueryJsonPathFromMockObjectArgs = {
  jsonPath: Scalars['String'];
  mockObject: Scalars['String'];
};


export type QueryJsonSchemaArgs = {
  source: JsonSchemaSourceGraphqlTypeInput;
};


export type QueryLocationsArgs = {
  pathway_id: Scalars['String'];
};


export type QueryMessageArgs = {
  id: Scalars['String'];
};


export type QueryMockObjectFromJsonSchemaArgs = {
  jsonSchema: Scalars['String'];
};


export type QueryPathwayArgs = {
  id: Scalars['String'];
};


export type QueryPathwayCaseActivitiesArgs = {
  pagination?: Maybe<PaginationParams>;
  pathway_case_id: Scalars['String'];
  sorting?: Maybe<SortingParams>;
};


export type QueryPathwayCaseApiCallArgs = {
  id: Scalars['String'];
};


export type QueryPathwayCaseApiCallsArgs = {
  pathway_case_id: Scalars['String'];
};


export type QueryPathwayCaseElementsArgs = {
  pathway_case_id: Scalars['String'];
};


export type QueryPathwayCaseStepActivitiesArgs = {
  input: PathwayCaseStepActivitiesInput;
};


export type QueryPathwayCaseTriggerActivationArgs = {
  id: Scalars['String'];
};


export type QueryPathwayCaseTriggerActivationsArgs = {
  pathway_case_id: Scalars['String'];
};


export type QueryPathwayCaseWebhookCallArgs = {
  webhook_call_id: Scalars['String'];
};


export type QueryPathwayCaseWebhookCallsArgs = {
  pathway_case_id: Scalars['String'];
};


export type QueryPathwayDiffTreeArgs = {
  pathway_id: Scalars['String'];
};


export type QueryPathwayDiffsArgs = {
  pathway_id: Scalars['String'];
};


export type QueryPathwayExtensionsArgs = {
  pathway_id: Scalars['String'];
};


export type QueryPathwaySettingsArgs = {
  input: Scalars['String'];
};


export type QueryPathwayStepsArgs = {
  pathway_id: Scalars['String'];
};


export type QueryPathway_CaseArgs = {
  id: Scalars['String'];
  pagination?: Maybe<PaginationParams>;
  sorting?: Maybe<SortingParams>;
};


export type QueryPathway_CasesArgs = {
  input: PathwayCasesInput;
};


export type QueryPathway_VersionArgs = {
  id: Scalars['String'];
};


export type QueryPathway_VersionsArgs = {
  pathway_id: Scalars['String'];
};


export type QueryRuleArgs = {
  id: Scalars['String'];
};


export type QueryStepArgs = {
  id: Scalars['String'];
};


export type QueryStepLibraryForPathwayArgs = {
  pathway_id: Scalars['String'];
};


export type QueryStepsArgs = {
  track_id: Scalars['String'];
};


export type QueryStickyNoteArgs = {
  id: Scalars['String'];
};


export type QueryStickyNotesArgs = {
  input: StickyNotesInput;
};


export type QueryTimingArgs = {
  id: Scalars['String'];
};


export type QueryTrackArgs = {
  id: Scalars['String'];
};


export type QueryTransitionArgs = {
  id: Scalars['String'];
};


export type QueryTransitionsArgs = {
  input: TransitionsInput;
};


export type QueryUserArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryWebhookArgs = {
  input: WebhookInput;
};

export type Question = {
  __typename?: 'Question';
  dataPointValueType?: Maybe<DataPointValueType>;
  form_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  key?: Maybe<Scalars['String']>;
  metadata?: Maybe<FormattedJson>;
  options?: Maybe<Array<Option>>;
  questionConfig?: Maybe<QuestionConfig>;
  questionType?: Maybe<QuestionType>;
  rule_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  userQuestionType?: Maybe<UserQuestionType>;
};

export type QuestionConfig = {
  __typename?: 'QuestionConfig';
  date?: Maybe<DateConfig>;
  mandatory: Scalars['Boolean'];
  multiple_select?: Maybe<MultipleSelectConfig>;
  number?: Maybe<NumberConfig>;
  phone?: Maybe<PhoneConfig>;
  recode_enabled?: Maybe<Scalars['Boolean']>;
  slider?: Maybe<SliderConfig>;
  use_select?: Maybe<Scalars['Boolean']>;
};

export type QuestionConfigInput = {
  mandatory?: Maybe<Scalars['Boolean']>;
  use_select?: Maybe<Scalars['Boolean']>;
};

export type QuestionInput = {
  key: Scalars['String'];
  option_value_type?: Maybe<Scalars['String']>;
  options?: Maybe<Array<OptionInput>>;
  title: Scalars['String'];
  user_question_type: UserQuestionType;
};

export type QuestionResponseInput = {
  question_id: Scalars['String'];
  value: Scalars['String'];
};

export type QuestionRuleResult = {
  __typename?: 'QuestionRuleResult';
  question_id: Scalars['String'];
  rule_id: Scalars['String'];
  satisfied: Scalars['Boolean'];
};

export enum QuestionType {
  Input = 'INPUT',
  MultipleChoice = 'MULTIPLE_CHOICE',
  NoInput = 'NO_INPUT'
}

export type Range = {
  __typename?: 'Range';
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
};

export type RangeConfig = {
  __typename?: 'RangeConfig';
  enabled?: Maybe<Scalars['Boolean']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
};

export type RangeInput = {
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
};

export type ReadMessageInput = {
  activity_id: Scalars['String'];
  pathway_case_id: Scalars['String'];
  subject: SubjectInput;
};

export type ReadMessagePayload = Payload & {
  __typename?: 'ReadMessagePayload';
  activity: Activity;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type RebuildGraphStatus = {
  __typename?: 'RebuildGraphStatus';
  event_count: Scalars['Float'];
  pathway_definition_id: Scalars['String'];
  replayed_count: Scalars['Float'];
};

export type RebuildInput = {
  pathway_id?: Maybe<Scalars['String']>;
};

export type RebuildViewModelError = {
  __typename?: 'RebuildViewModelError';
  error: Error;
  event: Scalars['String'];
  id: Scalars['String'];
  projection_name: Scalars['String'];
};

export type RebuildViewModelStatus = {
  __typename?: 'RebuildViewModelStatus';
  event_count: Scalars['Float'];
  event_id: Scalars['String'];
  replayed_count: Scalars['Float'];
};

export type RebuildViewModelsPayload = Payload & {
  __typename?: 'RebuildViewModelsPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  event_count: Scalars['Float'];
  success: Scalars['Boolean'];
};

export type RefreshExtensionInput = {
  extension_key: Scalars['String'];
  pathway_id: Scalars['ID'];
};

export type RefreshExtensionPayload = Payload & {
  __typename?: 'RefreshExtensionPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  not_supported_actions?: Maybe<Array<NotSupportedExtensionAction>>;
  success: Scalars['Boolean'];
};

export type ReminderDelayInput = {
  amount: Scalars['Float'];
  unit: DelayUnitType;
};

export type RemindersSettings = {
  __typename?: 'RemindersSettings';
  amount: Scalars['Float'];
  delay: Delay;
};

export type RemoveActionFromStepInput = {
  action_id: Scalars['String'];
  step_id: Scalars['String'];
};

export type RemoveActionFromStepPayload = Payload & {
  __typename?: 'RemoveActionFromStepPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  step: Step;
  success: Scalars['Boolean'];
};

export type RemoveClinicalNoteNarrativeInput = {
  clinical_note_id: Scalars['String'];
  narrative_id: Scalars['String'];
};

export type RemoveClinicalNoteNarrativePayload = Payload & {
  __typename?: 'RemoveClinicalNoteNarrativePayload';
  clinical_note: ClinicalNote;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type RemoveConditionFromRuleInput = {
  condition_id: Scalars['String'];
  rule_id: Scalars['String'];
};

export type RemoveConditionFromRulePayload = Payload & {
  __typename?: 'RemoveConditionFromRulePayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  rule: Rule;
  success: Scalars['Boolean'];
};

export type RemoveConstantInput = {
  constant_id: Scalars['String'];
  pathway_id: Scalars['String'];
};

export type RemoveConstantPayload = Payload & {
  __typename?: 'RemoveConstantPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  pathway: Pathway;
  success: Scalars['Boolean'];
};

export type RemoveDataPointDefinitionFromPathwayInput = {
  data_point_definition_id: Scalars['String'];
  pathway_id: Scalars['String'];
};

export type RemoveDataPointMappingFromExtensionActionInput = {
  action_id: Scalars['String'];
  mapping_id: Scalars['String'];
};

export type RemoveDataPointMappingFromExtensionActionPayload = Payload & {
  __typename?: 'RemoveDataPointMappingFromExtensionActionPayload';
  action: ExtensionAction;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type RemoveDynamicVariableInput = {
  dynamic_variable_id: Scalars['String'];
};

export type RemoveExtensionConfigurationInput = {
  extension_key: Scalars['String'];
  pathway_id: Scalars['String'];
};

export type RemoveLabelFromStepInput = {
  label_id: Scalars['String'];
  step_id: Scalars['String'];
};

export type RemoveLabelFromStepPayload = Payload & {
  __typename?: 'RemoveLabelFromStepPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  step: Step;
  success: Scalars['Boolean'];
};

export type RemoveMappingFromApiCallInput = {
  api_call_id: Scalars['String'];
  mapping_id: Scalars['String'];
};

export type RemoveMappingFromApiCallPayload = Payload & {
  __typename?: 'RemoveMappingFromApiCallPayload';
  api_call: ApiCall;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type RemoveMessageAttachmentInput = {
  attachment_id: Scalars['String'];
  message_id: Scalars['String'];
};

export type RemoveQuestionFromFormInput = {
  form_id: Scalars['String'];
  question_id: Scalars['String'];
};

export type RemoveQuestionFromFormPayload = Payload & {
  __typename?: 'RemoveQuestionFromFormPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  form: Form;
  success: Scalars['Boolean'];
};

export type RemoveRuleFromQuestionInput = {
  form_id: Scalars['String'];
  question_id: Scalars['String'];
};

export type RemoveRuleFromQuestionPayload = Payload & {
  __typename?: 'RemoveRuleFromQuestionPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  question: Question;
  success: Scalars['Boolean'];
};

export type RemoveStepFromLibraryInput = {
  step_id: Scalars['String'];
};

export type RemoveStepFromTrackInput = {
  step_id: Scalars['String'];
};

export type RemoveStepFromTrackPayload = Payload & {
  __typename?: 'RemoveStepFromTrackPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  track: Track;
};

export type RemoveStickyNoteFromTrackInput = {
  sticky_note_id: Scalars['String'];
};

export type RemoveTrackFromPathwayInput = {
  pathway_id: Scalars['String'];
  track_id: Scalars['String'];
};

export type RemoveTrackFromPathwayPayload = Payload & {
  __typename?: 'RemoveTrackFromPathwayPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  pathway: Pathway;
  success: Scalars['Boolean'];
};

export type RemoveTransformationsFromDynamicVariableInput = {
  dynamic_variable_id: Scalars['String'];
  transformation_ids: Array<Scalars['String']>;
};

export type RemoveTransformationsFromDynamicVariablePayload = Payload & {
  __typename?: 'RemoveTransformationsFromDynamicVariablePayload';
  code: Scalars['String'];
  dynamic_variable: DynamicVariable;
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type RemoveTransitionInput = {
  track_id: Scalars['String'];
  transition_id: Scalars['String'];
};

export type RemoveTransitionPayload = Payload & {
  __typename?: 'RemoveTransitionPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type RemoveTriggerFromPathwayInput = {
  trigger_id: Scalars['String'];
};

export type RemoveTriggerFromTrackInput = {
  trigger_id: Scalars['String'];
};

export type RemoveTriggerTimerInput = {
  timer_id: Scalars['String'];
  trigger_id: Scalars['String'];
};

export type RemoveWebhookInput = {
  pathway_id: Scalars['String'];
  webhook_id: Scalars['String'];
};

export type RemoveWebhookPayload = Payload & {
  __typename?: 'RemoveWebhookPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  pathway: Pathway;
  success: Scalars['Boolean'];
};

export type ReorderActionsInput = {
  action_ids: Array<Scalars['String']>;
  step_id: Scalars['String'];
};

export type ReorderActionsPayload = Payload & {
  __typename?: 'ReorderActionsPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  step: Step;
  success: Scalars['Boolean'];
};

export type ReorderMessageAttachmentsInput = {
  attachments: Array<MessageAttachmentInput>;
  message_id: Scalars['String'];
};

export type ReorderQuestionsInput = {
  form_id: Scalars['String'];
  previous_question_id?: Maybe<Scalars['String']>;
  question_id: Scalars['String'];
};

export type ReorderQuestionsPayload = Payload & {
  __typename?: 'ReorderQuestionsPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  form: Form;
  success: Scalars['Boolean'];
};

export type RepairPathwaysInput = {
  pathway_id?: Maybe<Scalars['String']>;
};

export type RepairPathwaysPayload = Payload & {
  __typename?: 'RepairPathwaysPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  pathway_count: Scalars['Float'];
  success: Scalars['Boolean'];
};

export type RepairPathwaysStatus = {
  __typename?: 'RepairPathwaysStatus';
  pathway_count: Scalars['Float'];
  repaired_count: Scalars['Float'];
};

export type ResetPatientProfileDataPointDefinitionsInput = {
  pathway_id?: Maybe<Scalars['String']>;
};

export type ResetPreviewInput = {
  pathway_case_id: Scalars['String'];
};

export type ResetPreviewPayload = Payload & {
  __typename?: 'ResetPreviewPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  pathway_case: PathwayCase;
  success: Scalars['Boolean'];
};

export type RetryAllFailedWebhookCallsInput = {
  pathway_id: Scalars['String'];
};

export type RetryAllWebhookCallsInput = {
  pathway_id: Scalars['String'];
};

export type RetryApiCallInput = {
  api_call_id: Scalars['String'];
};

export type RetryWebhookCallInput = {
  webhook_call_id: Scalars['String'];
};

export type RetryWebhookCallPayload = Payload & {
  __typename?: 'RetryWebhookCallPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  webhook_call: WebhookCall;
};

export type RoundToParameters = {
  __typename?: 'RoundToParameters';
  nbr_decimals?: Maybe<Scalars['Float']>;
};

export type RoundToTransformation = Transformation & {
  __typename?: 'RoundToTransformation';
  id: Scalars['ID'];
  parameters?: Maybe<RoundToParameters>;
  type: TransformationType;
};

/**
 * A rule is mathematical expression that determines will the transition be executed.
 * A rule contains a list of conditions and a boolean operator(AND or OR).
 * Rule will be satisfied if any of the following:
 *  - when there are no conditions
 *  - when boolean operator AND is used and all conditions are satisfied
 *  - when boolean operator OR is used and at least 1 condition is satisfied
 */
export type Rule = {
  __typename?: 'Rule';
  boolean_operator: BooleanOperator;
  conditions: Array<Condition>;
  id: Scalars['ID'];
  status: ConfigurationStatus;
};

export type RulePayload = Payload & {
  __typename?: 'RulePayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  rule: Rule;
  success: Scalars['Boolean'];
};


export type SaveMessageInput = {
  body?: Maybe<Scalars['String']>;
  message_id: Scalars['String'];
  subject?: Maybe<Scalars['String']>;
};

export type SchemaPayload = Payload & {
  __typename?: 'SchemaPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  schema: Scalars['String'];
  success: Scalars['Boolean'];
};

export type SetActionCalculationIdInput = {
  action_id: Scalars['String'];
  calculation_id: Scalars['String'];
};

export type SetActionCalculationIdPayload = Payload & {
  __typename?: 'SetActionCalculationIdPayload';
  action: CalculationAction;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetActionCalculationInputPayload = Payload & {
  __typename?: 'SetActionCalculationInputPayload';
  action: CalculationAction;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetActionChecklistItemsInput = {
  action_id: Scalars['String'];
  checklist: Array<Scalars['String']>;
};

export type SetActionChecklistItemsPayload = Payload & {
  __typename?: 'SetActionChecklistItemsPayload';
  action: ChecklistAction;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetActionFormDisplayModeInput = {
  action_id: Scalars['String'];
  form_display_mode: Scalars['String'];
};

export type SetActionFormDisplayModePayload = Payload & {
  __typename?: 'SetActionFormDisplayModePayload';
  action: FormAction;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetActionFormIdInput = {
  action_id: Scalars['String'];
  form_id: Scalars['String'];
};

export type SetActionFormIdPayload = Payload & {
  __typename?: 'SetActionFormIdPayload';
  action: FormAction;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetActionIdleTimeInput = {
  action_id: Scalars['String'];
  idle_time?: Maybe<IdleTimeDelayInput>;
};

export type SetActionIdleTimePayload = Payload & {
  __typename?: 'SetActionIdleTimePayload';
  action: Action;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetActionMessageIdInput = {
  action_id: Scalars['String'];
  message_id: Scalars['String'];
};

export type SetActionMessageIdPayload = Payload & {
  __typename?: 'SetActionMessageIdPayload';
  action: MessageAction;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetActionStakeholdersInput = {
  action_id: Scalars['String'];
  stakeholder_ids: Array<Scalars['String']>;
};

export type SetActionStakeholdersPayload = Payload & {
  __typename?: 'SetActionStakeholdersPayload';
  action: Action;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetActionTitleInput = {
  action_id: Scalars['String'];
  title: Scalars['String'];
};

export type SetActionTitlePayload = Payload & {
  __typename?: 'SetActionTitlePayload';
  action: Action;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetApiCallBodyInput = {
  api_call_id: Scalars['String'];
  body: ApiCallBodyInput;
};

export type SetApiCallBodyPayload = Payload & {
  __typename?: 'SetApiCallBodyPayload';
  api_call: ApiCall;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetApiCallEndpointInput = {
  api_call_id: Scalars['String'];
  endpoint: Scalars['String'];
};

export type SetApiCallEndpointPayload = Payload & {
  __typename?: 'SetApiCallEndpointPayload';
  api_call: ApiCall;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetApiCallHeadersInput = {
  api_call_id: Scalars['String'];
  headers: Array<ApiCallHeaderInput>;
};

export type SetApiCallHeadersPayload = Payload & {
  __typename?: 'SetApiCallHeadersPayload';
  api_call: ApiCall;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetApiCallMethodInput = {
  api_call_id: Scalars['String'];
  method: Scalars['String'];
};

export type SetApiCallMethodPayload = Payload & {
  __typename?: 'SetApiCallMethodPayload';
  api_call: ApiCall;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetBrandingAccentColorInput = {
  accent_color: Scalars['String'];
  pathway_id: Scalars['String'];
};

export type SetBrandingAccentColorPayload = Payload & {
  __typename?: 'SetBrandingAccentColorPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  settings: PathwaySettings;
  success: Scalars['Boolean'];
};

export type SetBrandingCustomThemeInput = {
  custom_theme: FormattedJsonInput;
  pathway_id: Scalars['String'];
};

export type SetBrandingCustomThemePayload = Payload & {
  __typename?: 'SetBrandingCustomThemePayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  settings: PathwaySettings;
  success: Scalars['Boolean'];
};

export type SetBrandingHostedPageAutoProgressInput = {
  hosted_page_auto_progress: Scalars['Boolean'];
  pathway_id: Scalars['String'];
};

export type SetBrandingHostedPageAutoProgressPayload = Payload & {
  __typename?: 'SetBrandingHostedPageAutoProgressPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  settings: PathwaySettings;
  success: Scalars['Boolean'];
};

export type SetBrandingHostedPageAutosaveInput = {
  hosted_page_autosave: Scalars['Boolean'];
  pathway_id: Scalars['String'];
};

export type SetBrandingHostedPageAutosavePayload = Payload & {
  __typename?: 'SetBrandingHostedPageAutosavePayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  settings: PathwaySettings;
  success: Scalars['Boolean'];
};

export type SetBrandingHostedPageTitleInput = {
  hosted_page_title: Scalars['String'];
  pathway_id: Scalars['String'];
};

export type SetBrandingHostedPageTitlePayload = Payload & {
  __typename?: 'SetBrandingHostedPageTitlePayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  settings: PathwaySettings;
  success: Scalars['Boolean'];
};

export type SetBrandingLogoUrlInput = {
  logo_url: Scalars['String'];
  pathway_id: Scalars['String'];
};

export type SetBrandingLogoUrlPayload = Payload & {
  __typename?: 'SetBrandingLogoUrlPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  settings: PathwaySettings;
  success: Scalars['Boolean'];
};

export type SetCalculationDataPointInput = {
  action_id: Scalars['String'];
  calculation_input_id: Scalars['String'];
  data_point_definition_id: Scalars['String'];
};

export type SetClinicalNoteNarrativeTitleInput = {
  clinical_note_id: Scalars['String'];
  narrative_id: Scalars['String'];
  title: Scalars['String'];
};

export type SetConditionOperandInput = {
  condition_id: Scalars['String'];
  operand: OperandInput;
  rule_id: Scalars['String'];
};

export type SetConditionOperandPayload = Payload & {
  __typename?: 'SetConditionOperandPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  rule: Rule;
  success: Scalars['Boolean'];
};

export type SetConditionOperatorInput = {
  condition_id: Scalars['String'];
  operator: ConditionOperator;
  rule_id: Scalars['String'];
};

export type SetConditionOperatorPayload = Payload & {
  __typename?: 'SetConditionOperatorPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  rule: Rule;
  success: Scalars['Boolean'];
};

export type SetConditionReferenceInput = {
  condition_id: Scalars['String'];
  reference: Scalars['String'];
  rule_id: Scalars['String'];
};

export type SetConditionReferencePayload = Payload & {
  __typename?: 'SetConditionReferencePayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  rule: Rule;
  success: Scalars['Boolean'];
};

export type SetCustomActionFieldInput = {
  action_id: Scalars['String'];
  custom_field: ActionCustomFieldInput;
};

export type SetCustomActionFieldPayload = Payload & {
  __typename?: 'SetCustomActionFieldPayload';
  action: Action;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetDashboardIdsInput = {
  dashboard_ids: Array<Scalars['String']>;
  pathway_id: Scalars['String'];
};

export type SetDashboardIdsPayload = Payload & {
  __typename?: 'SetDashboardIdsPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  settings: PathwaySettings;
  success: Scalars['Boolean'];
};

export type SetDataPointDefinitionMetaDataFieldInput = {
  data_point_definition_id: Scalars['String'];
  data_point_metadata: Array<DataPointMetaDataInputType>;
  pathway_id: Scalars['String'];
};

export type SetDataPointDefinitionMetaDataFieldPayload = Payload & {
  __typename?: 'SetDataPointDefinitionMetaDataFieldPayload';
  code: Scalars['String'];
  data_point_definition: DataPointDefinition;
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetDataPointDefinitionOptionalFieldInput = {
  data_point_definition_id: Scalars['String'];
  optional: Scalars['Boolean'];
  pathway_id: Scalars['String'];
};

export type SetDataPointDefinitionOptionalFieldPayload = Payload & {
  __typename?: 'SetDataPointDefinitionOptionalFieldPayload';
  code: Scalars['String'];
  data_point_definition: DataPointDefinition;
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetDataPointDefinitionPiiFieldInput = {
  data_point_definition_id: Scalars['String'];
  pathway_id: Scalars['String'];
  /** Personally identifiable information */
  pii: Scalars['Boolean'];
};

export type SetDataPointDefinitionPiiFieldPayload = Payload & {
  __typename?: 'SetDataPointDefinitionPiiFieldPayload';
  code: Scalars['String'];
  data_point_definition: DataPointDefinition;
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetDateQuestionConfigInput = {
  form_id: Scalars['String'];
  question_config: DateQuestionConfigInput;
  question_id: Scalars['String'];
};

export type SetDateQuestionConfigPayload = Payload & {
  __typename?: 'SetDateQuestionConfigPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  question: Question;
  success: Scalars['Boolean'];
};

export type SetDynamicVariableDataPointDefinitionInput = {
  data_point_definition_id: Scalars['String'];
  dynamic_variable_id: Scalars['String'];
};

export type SetDynamicVariableDataPointDefinitionPayload = Payload & {
  __typename?: 'SetDynamicVariableDataPointDefinitionPayload';
  code: Scalars['String'];
  dynamic_variable: DynamicVariable;
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetDynamicVariableDataPointPropertyInput = {
  data_point_property: DataPointPropertyType;
  dynamic_variable_id: Scalars['String'];
};

export type SetDynamicVariableDataPointPropertyPayload = Payload & {
  __typename?: 'SetDynamicVariableDataPointPropertyPayload';
  code: Scalars['String'];
  dynamic_variable: DynamicVariable;
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetDynamicVariableFallbackInput = {
  dynamic_variable_id: Scalars['String'];
  fallback: Scalars['String'];
};

export type SetDynamicVariableFallbackPayload = Payload & {
  __typename?: 'SetDynamicVariableFallbackPayload';
  code: Scalars['String'];
  dynamic_variable: DynamicVariable;
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetDynamicVariableLabelInput = {
  dynamic_variable_id: Scalars['String'];
  label: Scalars['String'];
};

export type SetDynamicVariableLabelPayload = Payload & {
  __typename?: 'SetDynamicVariableLabelPayload';
  code: Scalars['String'];
  dynamic_variable: DynamicVariable;
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetEmrReportBodyInput = {
  body: EmrBodyInput;
  emr_report_id: Scalars['String'];
};

export type SetExtensionActionDataPointMappingDataPointInput = {
  action_id: Scalars['String'];
  data_point_definition_id: Scalars['String'];
  mapping_id: Scalars['String'];
};

export type SetExtensionActionDataPointMappingDataPointPayload = Payload & {
  __typename?: 'SetExtensionActionDataPointMappingDataPointPayload';
  action: ExtensionAction;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetExtensionActionDataPointMappingKeyInput = {
  action_id: Scalars['String'];
  key: Scalars['String'];
  mapping_id: Scalars['String'];
};

export type SetExtensionActionDataPointMappingKeyPayload = Payload & {
  __typename?: 'SetExtensionActionDataPointMappingKeyPayload';
  action: ExtensionAction;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetExtensionActionDataPointMappingsInput = {
  action_id: Scalars['String'];
  mappings: Array<MappingDataPointDefinitionInput>;
};

export type SetExtensionActionDataPointMappingsPayload = Payload & {
  __typename?: 'SetExtensionActionDataPointMappingsPayload';
  action: ExtensionAction;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetExtensionActionFieldInput = {
  action_id: Scalars['String'];
  field: ExtensionActionFieldInput;
};

export type SetExtensionActionFieldPayload = Payload & {
  __typename?: 'SetExtensionActionFieldPayload';
  action: ExtensionAction;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetFormKeyInput = {
  form_id: Scalars['String'];
  key: Scalars['String'];
};

export type SetFormKeyPayload = Payload & {
  __typename?: 'SetFormKeyPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  form: Form;
  success: Scalars['Boolean'];
};

export type SetFormMetadataInput = {
  form_id: Scalars['String'];
  metadata: FormattedJsonInput;
};

export type SetFormMetadataPayload = Payload & {
  __typename?: 'SetFormMetadataPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  form: Form;
  success: Scalars['Boolean'];
};

export type SetFormTitleInput = {
  form_id: Scalars['String'];
  title: Scalars['String'];
};

export type SetFormTitlePayload = Payload & {
  __typename?: 'SetFormTitlePayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  form: Form;
  success: Scalars['Boolean'];
};

export type SetFormTrademarkInput = {
  form_id: Scalars['String'];
  trademark: Scalars['String'];
};

export type SetFormTrademarkPayload = Payload & {
  __typename?: 'SetFormTrademarkPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  form: Form;
  success: Scalars['Boolean'];
};

export type SetMessageAttachmentNameInput = {
  attachment_id: Scalars['String'];
  message_id: Scalars['String'];
  name: Scalars['String'];
};

export type SetMessageAttachmentUrlInput = {
  attachment_id: Scalars['String'];
  message_id: Scalars['String'];
  url: Scalars['String'];
};

export type SetMessageBodyInput = {
  body: MessageBodyInput;
  message_id: Scalars['String'];
};

export type SetMessageSubjectInput = {
  message_id: Scalars['String'];
  subject?: Maybe<Scalars['String']>;
};

export type SetMultipleSelectQuestionConfigInput = {
  form_id: Scalars['String'];
  question_config: MultipleSelectQuestionConfigInput;
  question_id: Scalars['String'];
};

export type SetMultipleSelectQuestionConfigPayload = Payload & {
  __typename?: 'SetMultipleSelectQuestionConfigPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  question: Question;
  success: Scalars['Boolean'];
};

export type SetNumberQuestionConfigInput = {
  form_id: Scalars['String'];
  question_config: NumberQuestionConfigInput;
  question_id: Scalars['String'];
};

export type SetNumberQuestionConfigPayload = Payload & {
  __typename?: 'SetNumberQuestionConfigPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  question: Question;
  success: Scalars['Boolean'];
};

export type SetPathwayCaseTitleInput = {
  pathway_case_id: Scalars['String'];
  title: Scalars['String'];
};

export type SetPathwayCaseTitlePayload = Payload & {
  __typename?: 'SetPathwayCaseTitlePayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  pathway_case: PathwayCase;
  success: Scalars['Boolean'];
};

export type SetPathwayTitleInput = {
  pathway_id: Scalars['String'];
  title: Scalars['String'];
};

export type SetPathwayTitlePayload = Payload & {
  __typename?: 'SetPathwayTitlePayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  pathway: Pathway;
  success: Scalars['Boolean'];
};

export type SetPhoneQuestionConfigInput = {
  form_id: Scalars['String'];
  question_config: PhoneQuestionConfigInput;
  question_id: Scalars['String'];
};

export type SetPhoneQuestionConfigPayload = Payload & {
  __typename?: 'SetPhoneQuestionConfigPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  question: Question;
  success: Scalars['Boolean'];
};

export type SetQuestionConfigInput = {
  form_id: Scalars['String'];
  question_config: QuestionConfigInput;
  question_id: Scalars['String'];
};

export type SetQuestionConfigPayload = Payload & {
  __typename?: 'SetQuestionConfigPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  question: Question;
  success: Scalars['Boolean'];
};

export type SetQuestionKeyInput = {
  form_id: Scalars['String'];
  key: Scalars['String'];
  question_id: Scalars['String'];
};

export type SetQuestionKeyPayload = Payload & {
  __typename?: 'SetQuestionKeyPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  question: Question;
  success: Scalars['Boolean'];
};

export type SetQuestionMetadataInput = {
  form_id: Scalars['String'];
  metadata: FormattedJsonInput;
  question_id: Scalars['String'];
};

export type SetQuestionMetadataPayload = Payload & {
  __typename?: 'SetQuestionMetadataPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  question: Question;
  success: Scalars['Boolean'];
};

export type SetQuestionOptionValueTypeInput = {
  dataPointValueType: DataPointValueType;
  form_id: Scalars['String'];
  question_id: Scalars['String'];
};

export type SetQuestionOptionValueTypePayload = Payload & {
  __typename?: 'SetQuestionOptionValueTypePayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  question: Question;
  success: Scalars['Boolean'];
};

export type SetQuestionsConfigInput = {
  form_id: Scalars['String'];
  question_config: QuestionConfigInput;
  question_ids: Array<Scalars['String']>;
};

export type SetQuestionsConfigPayload = Payload & {
  __typename?: 'SetQuestionsConfigPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  questions: Array<Question>;
  success: Scalars['Boolean'];
};

export type SetRemindersAmountInput = {
  amount: Scalars['Float'];
  pathway_id: Scalars['String'];
};

export type SetRemindersAmountPayload = Payload & {
  __typename?: 'SetRemindersAmountPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  settings: PathwaySettings;
  success: Scalars['Boolean'];
};

export type SetRemindersDelayInput = {
  delay: ReminderDelayInput;
  pathway_id: Scalars['String'];
};

export type SetRemindersDelayPayload = Payload & {
  __typename?: 'SetRemindersDelayPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  settings: PathwaySettings;
  success: Scalars['Boolean'];
};

export type SetRuleBooleanOperatorInput = {
  boolean_operator: BooleanOperator;
  rule_id: Scalars['String'];
};

export type SetRuleBooleanOperatorPayload = Payload & {
  __typename?: 'SetRuleBooleanOperatorPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  rule: Rule;
  success: Scalars['Boolean'];
};

export type SetSliderQuestionConfigInput = {
  form_id: Scalars['String'];
  question_config: SliderQuestionConfigInput;
  question_id: Scalars['String'];
};

export type SetSliderQuestionConfigPayload = Payload & {
  __typename?: 'SetSliderQuestionConfigPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  question: Question;
  success: Scalars['Boolean'];
};

export type SetStakeholderEmailsInput = {
  language: Scalars['String'];
  pathway_id: Scalars['String'];
  stakeholders: Array<StakeholderEmailInput>;
};

export type SetStakeholderEmailsPayload = Payload & {
  __typename?: 'SetStakeholderEmailsPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  settings: PathwaySettings;
  success: Scalars['Boolean'];
};

export type SetStepCoordinatesInput = {
  coordinates: CoordinatesInput;
  step_id: Scalars['String'];
};

export type SetStepCoordinatesPayload = Payload & {
  __typename?: 'SetStepCoordinatesPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  step: Step;
  success: Scalars['Boolean'];
};

export type SetStepDocumentationInput = {
  content: Scalars['String'];
  format: Scalars['String'];
  language: Language;
  step_id: Scalars['String'];
};

export type SetStepDocumentationPayload = Payload & {
  __typename?: 'SetStepDocumentationPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  step: Step;
  success: Scalars['Boolean'];
};

export type SetStepTitleInput = {
  step_id: Scalars['String'];
  title: Scalars['String'];
};

export type SetStepTitlePayload = Payload & {
  __typename?: 'SetStepTitlePayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  step: Step;
  success: Scalars['Boolean'];
};

export type SetStickyNoteCoordinatesInput = {
  coordinates: CoordinatesInput;
  sticky_note_id: Scalars['String'];
};

export type SetStickyNoteCoordinatesPayload = Payload & {
  __typename?: 'SetStickyNoteCoordinatesPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  stickyNote: StickyNote;
  success: Scalars['Boolean'];
};

export type SetTimingDelayInput = {
  delay: DelayInput;
  timing_id: Scalars['String'];
};

export type SetTimingDelayPayload = Payload & {
  __typename?: 'SetTimingDelayPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  timing: Timing;
};

export type SetTimingReferenceInput = {
  reference: Scalars['String'];
  timing_id: Scalars['String'];
};

export type SetTimingReferencePayload = Payload & {
  __typename?: 'SetTimingReferencePayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  timing: Timing;
};

export type SetTrackEndCoordinatesInput = {
  coordinates: CoordinatesInput;
  track_id: Scalars['String'];
};

export type SetTrackEndCoordinatesPayload = Payload & {
  __typename?: 'SetTrackEndCoordinatesPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  track: Track;
};

export type SetTrackStartCoordinatesInput = {
  coordinates: CoordinatesInput;
  track_id: Scalars['String'];
};

export type SetTrackStartCoordinatesPayload = Payload & {
  __typename?: 'SetTrackStartCoordinatesPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  track: Track;
};

export type SetTrackTitleInput = {
  title: Scalars['String'];
  track_id: Scalars['String'];
};

export type SetTrackTitlePayload = Payload & {
  __typename?: 'SetTrackTitlePayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  track: Track;
};

export type SetTransformationParamsInput = {
  dynamic_variable_id: Scalars['String'];
  transformation_id: Scalars['String'];
  value: Scalars['String'];
};

export type SetTransformationParamsPayload = Payload & {
  __typename?: 'SetTransformationParamsPayload';
  code: Scalars['String'];
  dynamic_variable: DynamicVariable;
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetTransformationTypeInput = {
  dynamic_variable_id: Scalars['String'];
  transformation_id: Scalars['String'];
  type: TransformationType;
};

export type SetTransformationTypePayload = Payload & {
  __typename?: 'SetTransformationTypePayload';
  code: Scalars['String'];
  dynamic_variable: DynamicVariable;
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetTransitionDestinationInput = {
  destination: DestinationInput;
  track_id: Scalars['String'];
  transition_id: Scalars['String'];
};

export type SetTransitionDestinationPayload = Payload & {
  __typename?: 'SetTransitionDestinationPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  transition: Transition;
};

export type SetTriggerDataPointDefinitionInput = {
  data_point_definition_id: Scalars['String'];
  trigger_id: Scalars['String'];
};

export type SetTriggerDelayInput = {
  delay: TriggerDelayInput;
  timer_id: Scalars['String'];
  trigger_id: Scalars['String'];
};

export type SetTriggerStepInput = {
  step_id: Scalars['String'];
  trigger_id: Scalars['String'];
};

export type SetTriggerTimerDataPointDefinitionInput = {
  data_point_definition_id: Scalars['String'];
  timer_id: Scalars['String'];
  trigger_id: Scalars['String'];
};

export type SetTriggerTimerStepInput = {
  step_id: Scalars['String'];
  timer_id: Scalars['String'];
  trigger_id: Scalars['String'];
};

export type SetTriggerTimerTrackInput = {
  timer_id: Scalars['String'];
  track_id: Scalars['String'];
  trigger_id: Scalars['String'];
};

export type SetTriggerTimerTypeInput = {
  timer_id: Scalars['String'];
  trigger_id: Scalars['String'];
  type: TriggerActivationReferenceType;
};

export type SetTriggerTrackInput = {
  track_id: Scalars['String'];
  trigger_id: Scalars['String'];
};

export type SetTriggerTypeInput = {
  trigger_id: Scalars['String'];
  type: TriggerType;
};

export type ShareFormsInput = {
  form_ids: Array<Scalars['String']>;
  team_id: Scalars['String'];
};

export type SharePathwaysInput = {
  pathway_ids: Array<Scalars['String']>;
  team_id: Scalars['String'];
};

export type SliderConfig = {
  __typename?: 'SliderConfig';
  display_marks: Scalars['Boolean'];
  is_value_tooltip_on: Scalars['Boolean'];
  max: Scalars['Float'];
  max_label: Scalars['String'];
  min: Scalars['Float'];
  min_label: Scalars['String'];
  show_min_max_values: Scalars['Boolean'];
  step_value: Scalars['Float'];
};

export type SliderConfigInput = {
  display_marks?: Maybe<Scalars['Boolean']>;
  is_value_tooltip_on?: Maybe<Scalars['Boolean']>;
  max?: Maybe<Scalars['Float']>;
  max_label?: Maybe<Scalars['String']>;
  min?: Maybe<Scalars['Float']>;
  min_label?: Maybe<Scalars['String']>;
  show_min_max_values?: Maybe<Scalars['Boolean']>;
  step_value?: Maybe<Scalars['Float']>;
};

export type SliderQuestionConfigInput = {
  slider: SliderConfigInput;
};

export type SortingOutput = {
  __typename?: 'SortingOutput';
  direction: Scalars['String'];
  field: Scalars['String'];
};

export type SortingParams = {
  direction: Scalars['String'];
  field: Scalars['String'];
};

export type SourceControlInput = {
  access_token: Scalars['String'];
  branch_name: Scalars['String'];
  repo_url: Scalars['String'];
};

export type SourceControlSettings = {
  __typename?: 'SourceControlSettings';
  access_token: Scalars['String'];
  branch_name: Scalars['String'];
  repo_url: Scalars['String'];
};

/**
 * Stakeholders are humans that are in any way involved in the pathway of the patient
 * Examples of stakeholders:
 *   - caregivers
 *   - doctors
 *   - parents
 *   - friends
 *   - guardians
 *   - paramedics (e.g., revalidation coach)
 *   - ...
 */
export type Stakeholder = {
  __typename?: 'Stakeholder';
  clinical_app_role: StakeholderClinicalAppRole;
  id: Scalars['ID'];
  label: StakeholderLabel;
};

export enum StakeholderClinicalAppRole {
  Caregiver = 'CAREGIVER',
  Patient = 'PATIENT',
  Physician = 'PHYSICIAN'
}

export type StakeholderEmail = {
  __typename?: 'StakeholderEmail';
  definition_id: Scalars['String'];
  email: Scalars['String'];
};

export type StakeholderEmailInput = {
  definition_id: Scalars['String'];
  email: Scalars['String'];
};

export type StakeholderLabel = {
  __typename?: 'StakeholderLabel';
  en: Scalars['String'];
};

export enum StakeholdersMode {
  Multiple = 'multiple',
  Single = 'single',
  Toggle = 'toggle'
}

export type StakeholdersPayload = Payload & {
  __typename?: 'StakeholdersPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  stakeholders: Array<Stakeholder>;
  success: Scalars['Boolean'];
};

export type StakeholdersSelectSettings = {
  __typename?: 'StakeholdersSelectSettings';
  description?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  mode: StakeholdersMode;
};

export type StartPreviewInput = {
  baseline_info?: Maybe<Array<BaselineInfoInput>>;
  pathway_case_id: Scalars['String'];
  pathway_id: Scalars['String'];
};

export type StartPreviewPayload = Payload & {
  __typename?: 'StartPreviewPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  pathway_case: PathwayCase;
  success: Scalars['Boolean'];
};

export type StartTrackFromStepInput = {
  destination_orientation: TransitionOrientationType;
  destination_step_id: Scalars['String'];
  origin_orientation: TransitionOrientationType;
  track_id: Scalars['String'];
};

export type StartTrackFromStepPayload = Payload & {
  __typename?: 'StartTrackFromStepPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  transition: Transition;
};

export type StartTransitionFromStepInput = {
  origin_step_id: Scalars['String'];
  track_id: Scalars['String'];
};

export type StartTransitionFromStepPayload = Payload & {
  __typename?: 'StartTransitionFromStepPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  step: Step;
  success: Scalars['Boolean'];
  transition: Transition;
};

export enum Status {
  Invalid = 'INVALID',
  Valid = 'VALID'
}

/**
 * Step is a sequence of actions.
 * Step exist only within an track.
 * Step represents a single procedure within an track.
 *
 * Examples of steps:
 *
 * 1. Post operation patient follow-up with following actions
 *    - send an email to patient
 *    - patient fills in form
 *    - calculation is done
 *    - alert generated if calculation result is bad
 *
 * 2. Baseline intake with following actions
 *    - sending an email to a patient that he/she is included in the pathway
 *    - sending an email to the clinical team that new patient is included in the pathway
 *    - filling in baseline forms by the patient
 */
export type Step = {
  __typename?: 'Step';
  actions?: Maybe<Array<Action>>;
  coordinates: Coordinates;
  created: AuditTrail;
  description?: Maybe<Scalars['String']>;
  documentation?: Maybe<FormattedText>;
  id: Scalars['ID'];
  label?: Maybe<Label>;
  last_updated?: Maybe<AuditTrail>;
  title?: Maybe<Scalars['String']>;
  track_id?: Maybe<Scalars['ID']>;
  transitions: Array<Transition>;
  type: StepType;
};

export type StepCompletedActivationReference = TriggerActivationReference & {
  __typename?: 'StepCompletedActivationReference';
  step_id?: Maybe<Scalars['ID']>;
  track_id?: Maybe<Scalars['ID']>;
  type?: Maybe<TriggerActivationReferenceType>;
};

export type StepCompletedTriggerSettings = TriggerSettings & {
  __typename?: 'StepCompletedTriggerSettings';
  rule?: Maybe<Rule>;
  rule_id?: Maybe<Scalars['ID']>;
  step_id?: Maybe<Scalars['ID']>;
  track_id?: Maybe<Scalars['ID']>;
  type?: Maybe<TriggerType>;
};

export type StepLibraryPayload = Payload & {
  __typename?: 'StepLibraryPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  steps: Array<Step>;
  success: Scalars['Boolean'];
};

export type StepPayload = Payload & {
  __typename?: 'StepPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  step: Step;
  success: Scalars['Boolean'];
};

export enum StepType {
  Step = 'STEP',
  Template = 'TEMPLATE'
}

export type StepsPayload = Payload & {
  __typename?: 'StepsPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  steps: Array<Step>;
  success: Scalars['Boolean'];
};

/**
 * Note represents text for remembrance when building pathway. It can be placed anywhere
 * in a track.
 * Notes exist only within a track for now.
 * Note represents a single post-it note within a track.
 */
export type StickyNote = {
  __typename?: 'StickyNote';
  body?: Maybe<StickyNoteBody>;
  coordinates: Coordinates;
  id: Scalars['ID'];
};

export type StickyNoteBody = {
  __typename?: 'StickyNoteBody';
  format: Scalars['String'];
  text?: Maybe<Scalars['String']>;
};

export type StickyNoteBodyInput = {
  format: Scalars['String'];
  text: Scalars['String'];
};

export type StickyNotePayload = Payload & {
  __typename?: 'StickyNotePayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  stickyNote: StickyNote;
  success: Scalars['Boolean'];
};

export type StickyNotesInput = {
  pathway_id: Scalars['String'];
  track_id: Scalars['String'];
};

export type StickyNotesPayload = Payload & {
  __typename?: 'StickyNotesPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  stickyNotes: Array<StickyNote>;
  success: Scalars['Boolean'];
};

export type StringArrayField = ExtensionActionField & {
  __typename?: 'StringArrayField';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  label: Scalars['String'];
  required?: Maybe<Scalars['Boolean']>;
  type: ExtensionActionFieldType;
  /** Value is kept as string because it can contain data point definition id in handlebars template, otherwise it is just "array of strings" */
  value?: Maybe<Scalars['String']>;
};

export type StringField = ExtensionActionField & {
  __typename?: 'StringField';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  label: Scalars['String'];
  required?: Maybe<Scalars['Boolean']>;
  stringType?: Maybe<StringType>;
  type: ExtensionActionFieldType;
  value?: Maybe<Scalars['String']>;
};

export enum StringType {
  Email = 'EMAIL',
  Phone = 'PHONE',
  Text = 'TEXT',
  Url = 'URL'
}

export type SubActivity = {
  __typename?: 'SubActivity';
  action: ActivityAction;
  date: Scalars['String'];
  error?: Maybe<Scalars['String']>;
  error_category?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  object?: Maybe<ActivityObject>;
  subject: ActivitySubject;
  text?: Maybe<TranslatedText>;
};

export type SubjectInput = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export type SubmitChecklistInput = {
  activity_id: Scalars['String'];
  pathway_case_id: Scalars['String'];
  subject: SubjectInput;
};

export type SubmitChecklistPayload = Payload & {
  __typename?: 'SubmitChecklistPayload';
  activity: Activity;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SubmitFormResponseInput = {
  activity_id: Scalars['String'];
  pathway_case_id: Scalars['String'];
  response: Array<QuestionResponseInput>;
  subject: SubjectInput;
};

export type SubmitFormResponsePayload = Payload & {
  __typename?: 'SubmitFormResponsePayload';
  activity: Activity;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type Subscription = {
  __typename?: 'Subscription';
  activitiesDeleted: ActivitiesDeleted;
  activityCompleted: Activity;
  activityCreated: Activity;
  activityUpdated: Activity;
  apiCallCreated: PathwayCaseApiCall;
  apiCallUpdated: PathwayCaseApiCall;
  elementCompleted: Element;
  elementCreated: Element;
  elementDeleted: Element;
  elementUpdated: Element;
  eventReplayed: RebuildViewModelStatus;
  onExportPathwayStatusUpdate: ExportPathwayStatus;
  onImportPathwayStatusUpdate: ImportPathwayStatus;
  pathwayRepaired: RepairPathwaysStatus;
  previewUpdated: PathwayCase;
  projectionError: RebuildViewModelError;
  rebuildGraphStatusUpdated: RebuildGraphStatus;
  triggerActivationCreated: TriggerActivation;
  triggerActivationUpdated: TriggerActivation;
  webhookCallCreated: PathwayCaseWebhookCall;
  webhookCallUpdated: PathwayCaseWebhookCall;
  webhookCallsDeleted: WebhookCallsDeleted;
};


export type SubscriptionActivitiesDeletedArgs = {
  pathway_case_id: Scalars['String'];
};


export type SubscriptionActivityCompletedArgs = {
  pathway_case_id: Scalars['String'];
};


export type SubscriptionActivityCreatedArgs = {
  pathway_case_id: Scalars['String'];
};


export type SubscriptionActivityUpdatedArgs = {
  pathway_case_id: Scalars['String'];
};


export type SubscriptionApiCallCreatedArgs = {
  pathway_case_id: Scalars['String'];
};


export type SubscriptionApiCallUpdatedArgs = {
  pathway_case_id: Scalars['String'];
};


export type SubscriptionElementCompletedArgs = {
  pathway_case_id: Scalars['String'];
};


export type SubscriptionElementCreatedArgs = {
  pathway_case_id: Scalars['String'];
};


export type SubscriptionElementDeletedArgs = {
  pathway_case_id: Scalars['String'];
};


export type SubscriptionElementUpdatedArgs = {
  pathway_case_id: Scalars['String'];
};


export type SubscriptionPreviewUpdatedArgs = {
  pathway_case_id: Scalars['ID'];
};


export type SubscriptionRebuildGraphStatusUpdatedArgs = {
  pathway_definition_id: Scalars['String'];
};


export type SubscriptionTriggerActivationCreatedArgs = {
  pathway_case_id: Scalars['String'];
};


export type SubscriptionTriggerActivationUpdatedArgs = {
  pathway_case_id: Scalars['String'];
};


export type SubscriptionWebhookCallCreatedArgs = {
  pathway_case_id: Scalars['String'];
};


export type SubscriptionWebhookCallUpdatedArgs = {
  pathway_case_id: Scalars['String'];
};


export type SubscriptionWebhookCallsDeletedArgs = {
  pathway_case_id: Scalars['String'];
};

export type SubtractDurationParameters = {
  __typename?: 'SubtractDurationParameters';
  duration?: Maybe<DurationGraphQlObjectType>;
};

export type SubtractDurationTransformation = Transformation & {
  __typename?: 'SubtractDurationTransformation';
  id: Scalars['ID'];
  parameters?: Maybe<SubtractDurationParameters>;
  type: TransformationType;
};

export type Team = {
  __typename?: 'Team';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Tenant = {
  __typename?: 'Tenant';
  accent_color?: Maybe<Scalars['String']>;
  api_call_retry_settings?: Maybe<ApiCallRetrySettings>;
  hosted_page_title?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  is_default: Scalars['Boolean'];
  logo_path?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  public?: Maybe<Scalars['Boolean']>;
};

export type TenantPayload = Payload & {
  __typename?: 'TenantPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  tenant: Tenant;
};

export type TenantsPayload = Payload & {
  __typename?: 'TenantsPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  tenants: Array<Tenant>;
};

export type TestSourceControlInput = {
  pathway_id: Scalars['String'];
};

export type TestSourceControlPayload = Payload & {
  __typename?: 'TestSourceControlPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type TextField = ExtensionActionField & {
  __typename?: 'TextField';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  label: Scalars['String'];
  required?: Maybe<Scalars['Boolean']>;
  slate?: Maybe<Scalars['String']>;
  type: ExtensionActionFieldType;
  value?: Maybe<Scalars['String']>;
};

/**
 * Timings exist in the context of a transition.
 * It allows you to add time delays before going from one step to another.
 * Delays can be:
 *   - static
 *   - dynamic
 * Static delays are always the same across pathway instances.
 * Dynamic delays vary based on data point instances.
 */
export type Timing = {
  __typename?: 'Timing';
  delay?: Maybe<Delay>;
  id: Scalars['ID'];
  reference?: Maybe<Scalars['String']>;
  reference_key?: Maybe<Scalars['String']>;
};

export type TimingPayload = Payload & {
  __typename?: 'TimingPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  timing: Timing;
};

/**
 * A box to put steps in, just a set of steps.
 * It is a way to group steps together, to make pathway more understandable.
 * Pathways are made out of tracks. (e.g. Surgery, Hormonal therapy ...)
 * Steps in a track are not executed sequentially.
 * Order of execution of steps within an track is defined by transitions.
 *
 * You can transition for Track A to Track B by linking transition from any step in Track A to track's End node
 * and configuring that transition to go to Track B.
 */
export type Track = {
  __typename?: 'Track';
  created: AuditTrail;
  end_coordinates: Coordinates;
  id: Scalars['ID'];
  last_updated?: Maybe<AuditTrail>;
  start_coordinates: Coordinates;
  steps: Array<Step>;
  sticky_notes: Array<StickyNote>;
  title: Scalars['String'];
  transitions: Array<Transition>;
  triggers: Array<Trigger>;
};

export type TrackCompletedActivationReference = TriggerActivationReference & {
  __typename?: 'TrackCompletedActivationReference';
  track_id?: Maybe<Scalars['ID']>;
  type?: Maybe<TriggerActivationReferenceType>;
};

export type TrackCompletedTriggerSettings = TriggerSettings & {
  __typename?: 'TrackCompletedTriggerSettings';
  rule?: Maybe<Rule>;
  rule_id?: Maybe<Scalars['ID']>;
  track_id?: Maybe<Scalars['ID']>;
  type?: Maybe<TriggerType>;
};

export type TrackPayload = Payload & {
  __typename?: 'TrackPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  /**
   * A box to put steps in, just a set of steps.
   * It is a way to group steps together, to make pathway more understandable.
   * Pathways are made out of tracks. (e.g. Surgery, Hormonal therapy ...)
   * Steps in a track are not executed sequentially.
   * Order of execution of steps within an track is defined by transitions.
   *
   * You can transition for Track A to Track B by linking transition from any step in Track A to track's End node
   * and configuring that transition to go to Track B.
   */
  track: Track;
};

export type TrackStartedActivationReference = TriggerActivationReference & {
  __typename?: 'TrackStartedActivationReference';
  track_id?: Maybe<Scalars['ID']>;
  type?: Maybe<TriggerActivationReferenceType>;
};

export type TracksPayload = Payload & {
  __typename?: 'TracksPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  tracks: Array<Track>;
};

export type TransferPathwayInput = {
  new_user_id: Scalars['String'];
  pathway_id: Scalars['String'];
};

export type Transformation = {
  id: Scalars['ID'];
  type: TransformationType;
};

export type TransformationInput = {
  type: TransformationType;
  value: Scalars['String'];
};

export enum TransformationType {
  AddDuration = 'ADD_DURATION',
  AddPrefix = 'ADD_PREFIX',
  AddSuffix = 'ADD_SUFFIX',
  AddWeekdays = 'ADD_WEEKDAYS',
  FormatDate = 'FORMAT_DATE',
  FormatPhoneNumber = 'FORMAT_PHONE_NUMBER',
  RoundTo = 'ROUND_TO',
  SubtractDuration = 'SUBTRACT_DURATION'
}

export type Transition = {
  __typename?: 'Transition';
  default: Scalars['Boolean'];
  destination?: Maybe<TransitionNode>;
  id: Scalars['ID'];
  origin: TransitionNode;
  rule?: Maybe<Rule>;
  status: ConfigurationStatus;
  timing?: Maybe<Timing>;
  transitionOrientation: TransitionOrientation;
};

export type TransitionNode = {
  __typename?: 'TransitionNode';
  node_id: Scalars['ID'];
  node_title: Scalars['String'];
  type: TransitionNodeType;
};

export enum TransitionNodeType {
  PathwayEnd = 'PATHWAY_END',
  PathwayStart = 'PATHWAY_START',
  Step = 'STEP',
  Track = 'TRACK',
  TrackEnd = 'TRACK_END',
  TrackStart = 'TRACK_START'
}

export type TransitionOrientation = {
  __typename?: 'TransitionOrientation';
  destination_orientation?: Maybe<TransitionOrientationType>;
  id: Scalars['ID'];
  origin_orientation?: Maybe<TransitionOrientationType>;
};

export enum TransitionOrientationType {
  Bottom = 'BOTTOM',
  Left = 'LEFT',
  Right = 'RIGHT',
  Top = 'TOP'
}

export type TransitionPayload = Payload & {
  __typename?: 'TransitionPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  transition: Transition;
};

export type TransitionsInput = {
  pathway_id: Scalars['String'];
  track_id: Scalars['String'];
};

export type TransitionsPayload = Payload & {
  __typename?: 'TransitionsPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  transitions: Array<Transition>;
};

export type TranslatedText = {
  __typename?: 'TranslatedText';
  en?: Maybe<Scalars['String']>;
};

export type TranslatedTextInput = {
  en?: Maybe<Scalars['String']>;
};

export type TrashPathwayInput = {
  pathway_id: Scalars['String'];
};

/**
 * A pathway can be trashed.
 * When a pathway is trashed it is not shown in the pathways list.
 * A trashed pathway is still editable (TEMPORARY: likely to change).
 * When a pathway is in the trash it can be:
 * - removed from the trash. It becomes available again
 * - permanently deleted
 * NOTE: A pathway can be permanently deleted only if it is in the trash.
 *
 * In summary the state transitions of a pathway:
 * active <-> in trash -> deleted
 */
export type Trashed = {
  __typename?: 'Trashed';
  is_trashed: Scalars['Boolean'];
  trashed_at: Scalars['SafeDate'];
  trashed_by: PathwayCollaborator;
};

export type Trigger = {
  __typename?: 'Trigger';
  id: Scalars['ID'];
  settings?: Maybe<TriggerSettings>;
  target_type?: Maybe<TriggerTargetType>;
  timers?: Maybe<Array<DesignatedTriggerTimer>>;
};

export type TriggerActivation = {
  __typename?: 'TriggerActivation';
  id: Scalars['ID'];
  status: TriggerActivationStatus;
  track: Track;
  trigger: Trigger;
  type: TriggerActivationType;
};

export type TriggerActivationPayload = Payload & {
  __typename?: 'TriggerActivationPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  trigger_activation: TriggerActivation;
};

export type TriggerActivationReference = {
  type?: Maybe<TriggerActivationReferenceType>;
};

export enum TriggerActivationReferenceType {
  AdHoc = 'AD_HOC',
  DataPointCollected = 'DATA_POINT_COLLECTED',
  PathwayStarted = 'PATHWAY_STARTED',
  StepCompleted = 'STEP_COMPLETED',
  TrackCompleted = 'TRACK_COMPLETED',
  TrackStarted = 'TRACK_STARTED'
}

export enum TriggerActivationStatus {
  Activated = 'ACTIVATED',
  Canceled = 'CANCELED',
  Discarded = 'DISCARDED',
  Pending = 'PENDING'
}

export enum TriggerActivationType {
  Automatic = 'AUTOMATIC',
  Manual = 'MANUAL'
}

export type TriggerActivationsPayload = Payload & {
  __typename?: 'TriggerActivationsPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  trigger_activations: Array<TriggerActivation>;
};

export type TriggerApiCallInput = {
  activity_id: Scalars['String'];
  mock_response_body?: Maybe<Scalars['String']>;
  mock_response_status?: Maybe<Scalars['Float']>;
};

export type TriggerApiCallPayload = Payload & {
  __typename?: 'TriggerApiCallPayload';
  api_call: PathwayCaseApiCall;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type TriggerDelayInput = {
  amount: Scalars['Int'];
  unit: DelayUnitType;
};

export type TriggerPayload = Payload & {
  __typename?: 'TriggerPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  trigger: Trigger;
};

export type TriggerSettings = {
  rule?: Maybe<Rule>;
  type?: Maybe<TriggerType>;
};

export enum TriggerTargetType {
  Pathway = 'PATHWAY',
  Track = 'TRACK'
}

export enum TriggerType {
  AdHoc = 'AD_HOC',
  DataPointCollected = 'DATA_POINT_COLLECTED',
  DataPointNotCollected = 'DATA_POINT_NOT_COLLECTED',
  ExtensionWebhook = 'EXTENSION_WEBHOOK',
  PathwayStarted = 'PATHWAY_STARTED',
  StepCompleted = 'STEP_COMPLETED',
  TrackCompleted = 'TRACK_COMPLETED'
}

export type UpdateApiCallMappingDataPointInput = {
  api_call_id: Scalars['String'];
  data_point_definition_id?: Maybe<Scalars['String']>;
  mapping_id: Scalars['String'];
};

export type UpdateApiCallMappingDataPointPayload = Payload & {
  __typename?: 'UpdateApiCallMappingDataPointPayload';
  api_call: ApiCall;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type UpdateApiCallMappingFirstMatchOnlyInput = {
  api_call_id: Scalars['String'];
  first_match_only: Scalars['Boolean'];
  mapping_id: Scalars['String'];
};

export type UpdateApiCallMappingFirstMatchOnlyPayload = Payload & {
  __typename?: 'UpdateApiCallMappingFirstMatchOnlyPayload';
  api_call: ApiCall;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type UpdateApiCallMappingKeyInput = {
  api_call_id: Scalars['String'];
  mapping_id: Scalars['String'];
  response_key: Scalars['String'];
};

export type UpdateApiCallMappingKeyPayload = Payload & {
  __typename?: 'UpdateApiCallMappingKeyPayload';
  api_call: ApiCall;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type UpdateCareFlowApiCallRetrySettingsInput = {
  api_call_retry_settings: ApiCallRetrySettingsInput;
  pathway_id: Scalars['String'];
};

export type UpdateCareFlowApiCallRetrySettingsPayload = Payload & {
  __typename?: 'UpdateCareFlowApiCallRetrySettingsPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  settings: PathwaySettings;
  success: Scalars['Boolean'];
};

export type UpdateCareFlowSourceControlSettingsInput = {
  pathway_id: Scalars['String'];
  source_control: SourceControlInput;
};

export type UpdateCareFlowSourceControlSettingsPayload = Payload & {
  __typename?: 'UpdateCareFlowSourceControlSettingsPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  settings: PathwaySettings;
  success: Scalars['Boolean'];
};

export type UpdateClinicalNoteContextInput = {
  clinical_note_id: Scalars['String'];
  context: Array<ClinicalNoteContextFieldInput>;
};

export type UpdateClinicalNoteContextPayload = Payload & {
  __typename?: 'UpdateClinicalNoteContextPayload';
  clinical_note: ClinicalNote;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type UpdateClinicalNoteNarrativeBodyInput = {
  body: Scalars['String'];
  clinical_note_id: Scalars['String'];
  html_body: Scalars['String'];
  narrative_id: Scalars['ID'];
};

export type UpdateConstantInput = {
  constant_id: Scalars['String'];
  label: Scalars['String'];
  obfuscated: Scalars['Boolean'];
  pathway_id: Scalars['String'];
  value: Scalars['String'];
};

export type UpdateConstantPayload = Payload & {
  __typename?: 'UpdateConstantPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  pathway: Pathway;
  success: Scalars['Boolean'];
};

export type UpdateDataPointDefinitionInput = {
  data_point_definition_id: Scalars['String'];
  fields: DataPointDefinitionInput;
  pathway_id: Scalars['String'];
};

export type UpdateDataPointDefinitionPayload = Payload & {
  __typename?: 'UpdateDataPointDefinitionPayload';
  code: Scalars['String'];
  data_point_definition: DataPointDefinition;
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type UpdateDynamicVariableInput = {
  data_point_definition_id: Scalars['String'];
  data_point_property: DataPointPropertyType;
  fallback: Scalars['String'];
  id: Scalars['String'];
  label: Scalars['String'];
  transformations: Array<TransformationInput>;
};

export type UpdateDynamicVariablePayload = Payload & {
  __typename?: 'UpdateDynamicVariablePayload';
  code: Scalars['String'];
  dynamic_variable: DynamicVariable;
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type UpdateEmailNotificationStatusInput = {
  allow_email_notifications: Scalars['Boolean'];
  pathway_id: Scalars['String'];
};

export type UpdateEmailNotificationStatusPayload = Payload & {
  __typename?: 'UpdateEmailNotificationStatusPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  settings: PathwaySettings;
  success: Scalars['Boolean'];
};

export type UpdateLabelTextInput = {
  label_id: Scalars['String'];
  pathway_id: Scalars['String'];
  text: Scalars['String'];
};

export type UpdateLabelTextPayload = Payload & {
  __typename?: 'UpdateLabelTextPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  label: Label;
  success: Scalars['Boolean'];
};

export type UpdateQuestionInput = {
  form_id: Scalars['String'];
  options?: Maybe<Array<OptionInput>>;
  question_id: Scalars['String'];
  title: Scalars['String'];
  title_html?: Maybe<Scalars['String']>;
};

export type UpdateQuestionPayload = Payload & {
  __typename?: 'UpdateQuestionPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  question: Question;
  success: Scalars['Boolean'];
};

export type UpdateStickyNoteBodyInput = {
  body: StickyNoteBodyInput;
  sticky_note_id: Scalars['String'];
};

export type UpdateStickyNoteBodyPayload = Payload & {
  __typename?: 'UpdateStickyNoteBodyPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  stickyNote: StickyNote;
  success: Scalars['Boolean'];
};

export type UpdateWebhookEndpointInput = {
  endpoint: Scalars['String'];
  pathway_id: Scalars['String'];
  webhook_id: Scalars['String'];
};

export type UpdateWebhookEndpointPayload = Payload & {
  __typename?: 'UpdateWebhookEndpointPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  pathway: Pathway;
  success: Scalars['Boolean'];
};

export type UpdateWebhookHeadersInput = {
  headers?: Array<WebhookHeaderInput>;
  pathway_id: Scalars['String'];
  webhook_id: Scalars['String'];
};

export type UpdateWebhookHeadersPayload = Payload & {
  __typename?: 'UpdateWebhookHeadersPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  pathway: Pathway;
  success: Scalars['Boolean'];
};

export type UpdateWebhookInput = {
  pathway_id: Scalars['String'];
  webhook: ExistingWebhookInput;
};

export type UpdateWebhookNameInput = {
  name: Scalars['String'];
  pathway_id: Scalars['String'];
  webhook_id: Scalars['String'];
};

export type UpdateWebhookNamePayload = Payload & {
  __typename?: 'UpdateWebhookNamePayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  pathway: Pathway;
  success: Scalars['Boolean'];
};

export type UpdateWebhookPayload = Payload & {
  __typename?: 'UpdateWebhookPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  pathway: Pathway;
  success: Scalars['Boolean'];
};

export type UpdateWebhookStatusInput = {
  enabled: Scalars['Boolean'];
  pathway_id: Scalars['String'];
  webhook_id: Scalars['String'];
};

export type UpdateWebhookStatusPayload = Payload & {
  __typename?: 'UpdateWebhookStatusPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  pathway: Pathway;
  success: Scalars['Boolean'];
};

export type UpdateWebhookSubscribedEventsInput = {
  pathway_id: Scalars['String'];
  subscribed_events?: Maybe<Array<Scalars['String']>>;
  webhook_id: Scalars['String'];
};

export type UpdateWebhookSubscribedEventsPayload = Payload & {
  __typename?: 'UpdateWebhookSubscribedEventsPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  pathway: Pathway;
  success: Scalars['Boolean'];
};

export type UpdateWebhookTestEndpointInput = {
  endpoint_test: Scalars['String'];
  pathway_id: Scalars['String'];
  webhook_id: Scalars['String'];
};

export type UpdateWebhookTestEndpointPayload = Payload & {
  __typename?: 'UpdateWebhookTestEndpointPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  pathway: Pathway;
  success: Scalars['Boolean'];
};

export type UsageContext = {
  __typename?: 'UsageContext';
  id: Scalars['ID'];
  title: Scalars['String'];
};

/**
 * A user is a person who is going to login and interact with pathway studio.
 * A user can belong to multiple teams.
 *
 * A team is a:
 * - Group of users, and
 * - Group of resources i.e. Pathways and Forms
 *
 * Inside a team:
 * - Each user has a role or list of roles.
 * - Each user has access to the group of resources.
 *
 * A role is a group of permissions which define how users interact (i.e. create, read, update, delete) with each resource
 *
 * Example permission: "read:pathways", "create:forms"
 * Example role: "editor", "owner", "viewer"
 *
 * The model definitions look like this.
 *
 * Team: {
 *   id: string
 *   name: string
 * }
 *
 * TeamRelations {
 *   team_id: stirng
 *   team_name: string
 *   roles: [string]
 * }
 *
 * User: {
 *   id: string
 *   name: string
 *   ....
 *   teams: [TeamRelations]
 * }
 *
 * Pathway: {
 *   id: string
 *   ....
 *   teams: [{
 *     team_id: string
 *   }]
 * }
 */
export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  id: Scalars['ID'];
  intercom_hash: Scalars['String'];
  name: Scalars['String'];
  pending_invitation_id?: Maybe<Scalars['String']>;
  team?: Maybe<Team>;
  tenant?: Maybe<Tenant>;
};

export type UserPayload = Payload & {
  __typename?: 'UserPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  user: User;
};

export enum UserQuestionType {
  Date = 'DATE',
  Description = 'DESCRIPTION',
  LongText = 'LONG_TEXT',
  MultipleChoice = 'MULTIPLE_CHOICE',
  MultipleChoiceGrid = 'MULTIPLE_CHOICE_GRID',
  MultipleSelect = 'MULTIPLE_SELECT',
  Number = 'NUMBER',
  ShortText = 'SHORT_TEXT',
  Signature = 'SIGNATURE',
  Slider = 'SLIDER',
  Telephone = 'TELEPHONE',
  YesNo = 'YES_NO'
}

export enum VersionStatus {
  Archived = 'Archived',
  Live = 'Live'
}

export type Webhook = {
  __typename?: 'Webhook';
  created_at: Scalars['SafeDate'];
  enabled: Scalars['Boolean'];
  endpoint: Scalars['String'];
  endpoint_test?: Maybe<Scalars['String']>;
  headers?: Maybe<Array<WebhookHeader>>;
  id: Scalars['String'];
  name: Scalars['String'];
  signing_key?: Maybe<Scalars['String']>;
  subscribed_events?: Maybe<Array<Scalars['String']>>;
};

export type WebhookCall = {
  __typename?: 'WebhookCall';
  created_at: Scalars['String'];
  event_type: Scalars['String'];
  id: Scalars['ID'];
  pathway?: Maybe<ApiPathwayContext>;
  request: WebhookCallRequest;
  responses: Array<WebhookCallResponse>;
  status: Scalars['String'];
  webhook_id: Scalars['String'];
  webhook_name: Scalars['String'];
};

export type WebhookCallHeader = {
  __typename?: 'WebhookCallHeader';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type WebhookCallRequest = {
  __typename?: 'WebhookCallRequest';
  body: Scalars['String'];
  endpoint: Scalars['String'];
  headers: Array<WebhookCallHeader>;
  method: Scalars['String'];
};

export type WebhookCallResponse = {
  __typename?: 'WebhookCallResponse';
  body: Scalars['String'];
  date: Scalars['String'];
  status: Scalars['Float'];
};

export type WebhookCallsDeleted = {
  __typename?: 'WebhookCallsDeleted';
  webhook_call_ids: Array<Scalars['String']>;
};

export type WebhookHeader = {
  __typename?: 'WebhookHeader';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type WebhookHeaderInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type WebhookInput = {
  pathway_id: Scalars['String'];
  webhook_id?: Maybe<Scalars['String']>;
};

export type WebhookPayload = Payload & {
  __typename?: 'WebhookPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  webhook?: Maybe<Webhook>;
};

export type Layout = {
  __typename?: 'Layout';
  startPanelWidth: Scalars['Int'];
  endPanelWidth: Scalars['Int'];
  startPanelCollapsed: Scalars['Boolean'];
};

export type SimulatorLayout = {
  __typename?: 'SimulatorLayout';
  activityFeedWidth: Scalars['Float'];
};

export type FormBuilderLayout = {
  __typename?: 'FormBuilderLayout';
  formPreviewWidth: Scalars['Float'];
};

export type CanvasOffset = {
  __typename?: 'CanvasOffset';
  x: Scalars['Float'];
  y: Scalars['Float'];
};

export type TrackCanvasSettings = {
  __typename?: 'TrackCanvasSettings';
  trackId?: Maybe<Scalars['String']>;
  zoomLevel: Scalars['Float'];
  offset: CanvasOffset;
};

export type SessionUrls = {
  __typename?: 'SessionUrls';
  builder?: Maybe<Scalars['String']>;
  preview?: Maybe<Scalars['String']>;
};

export type LabelType = {
  __typename?: 'LabelType';
  en?: Maybe<Scalars['String']>;
  nl?: Maybe<Scalars['String']>;
  fr?: Maybe<Scalars['String']>;
  de?: Maybe<Scalars['String']>;
};

export type CalculationBlueprintInputType = {
  __typename?: 'CalculationBlueprintInputType';
  type?: Maybe<Scalars['String']>;
};

export type CalculationBlueprintInputDefinitionType = {
  __typename?: 'CalculationBlueprintInputDefinitionType';
  id?: Maybe<Scalars['String']>;
  input_type?: Maybe<CalculationBlueprintInputType>;
};

export type CalculationBlueprintOutputType = {
  __typename?: 'CalculationBlueprintOutputType';
  type?: Maybe<Scalars['String']>;
};

export type CalculationBlueprintOutputDefinitionType = {
  __typename?: 'CalculationBlueprintOutputDefinitionType';
  subresult_id?: Maybe<Scalars['String']>;
  type?: Maybe<CalculationBlueprintOutputType>;
};

export type CalculationBlueprintType = {
  __typename?: 'CalculationBlueprintType';
  input_definition?: Maybe<Array<Maybe<CalculationBlueprintInputDefinitionType>>>;
  output_definition?: Maybe<Array<Maybe<CalculationBlueprintOutputDefinitionType>>>;
};

export type CalculationLibraryType = {
  __typename?: 'CalculationLibraryType';
  calculation_id?: Maybe<Scalars['String']>;
  calculation_name?: Maybe<LabelType>;
  calculation_description?: Maybe<LabelType>;
  calculation_blueprint?: Maybe<CalculationBlueprintType>;
};

export type AddActionToStepMutationVariables = Exact<{
  input: AddActionToStepInput;
}>;


export type AddActionToStepMutation = (
  { __typename?: 'Mutation' }
  & { addActionToStep: (
    { __typename?: 'AddActionToStepPayload' }
    & { step: (
      { __typename?: 'Step' }
      & Pick<Step, 'id'>
      & { actions?: Maybe<Array<(
        { __typename?: 'ApiCallAction' }
        & Pick<ApiCallAction, 'id' | 'title' | 'type'>
      ) | (
        { __typename?: 'ApiCallGraphqlAction' }
        & Pick<ApiCallGraphqlAction, 'id' | 'title' | 'type'>
      ) | (
        { __typename?: 'CalculationAction' }
        & Pick<CalculationAction, 'id' | 'title' | 'type'>
      ) | (
        { __typename?: 'ChecklistAction' }
        & Pick<ChecklistAction, 'id' | 'title' | 'type'>
      ) | (
        { __typename?: 'ClinicalNoteAction' }
        & Pick<ClinicalNoteAction, 'clinical_note_id' | 'id' | 'title' | 'type'>
      ) | (
        { __typename?: 'ExtensionAction' }
        & Pick<ExtensionAction, 'extension_key' | 'id' | 'title' | 'type'>
      ) | (
        { __typename?: 'FormAction' }
        & Pick<FormAction, 'id' | 'title' | 'type'>
      ) | (
        { __typename?: 'MessageAction' }
        & Pick<MessageAction, 'message_id' | 'id' | 'title' | 'type'>
      ) | (
        { __typename?: 'PushToEmrAction' }
        & Pick<PushToEmrAction, 'emr_report_id' | 'id' | 'title' | 'type'>
      )>> }
    ), action: (
      { __typename?: 'ApiCallAction' }
      & Pick<ApiCallAction, 'id' | 'type'>
    ) | (
      { __typename?: 'ApiCallGraphqlAction' }
      & Pick<ApiCallGraphqlAction, 'id' | 'type'>
    ) | (
      { __typename?: 'CalculationAction' }
      & Pick<CalculationAction, 'id' | 'type'>
    ) | (
      { __typename?: 'ChecklistAction' }
      & Pick<ChecklistAction, 'id' | 'type'>
    ) | (
      { __typename?: 'ClinicalNoteAction' }
      & Pick<ClinicalNoteAction, 'clinical_note_id' | 'id' | 'type'>
    ) | (
      { __typename?: 'ExtensionAction' }
      & Pick<ExtensionAction, 'extension_key' | 'id' | 'type'>
    ) | (
      { __typename?: 'FormAction' }
      & Pick<FormAction, 'id' | 'type'>
    ) | (
      { __typename?: 'MessageAction' }
      & Pick<MessageAction, 'message_id' | 'id' | 'type'>
    ) | (
      { __typename?: 'PushToEmrAction' }
      & Pick<PushToEmrAction, 'emr_report_id' | 'id' | 'type'>
    ) }
  ) }
);

export type RemoveActionFromStepMutationVariables = Exact<{
  input: RemoveActionFromStepInput;
}>;


export type RemoveActionFromStepMutation = (
  { __typename?: 'Mutation' }
  & { removeActionFromStep: (
    { __typename?: 'RemoveActionFromStepPayload' }
    & { step: (
      { __typename?: 'Step' }
      & Pick<Step, 'id'>
      & { actions?: Maybe<Array<(
        { __typename?: 'ApiCallAction' }
        & Pick<ApiCallAction, 'id' | 'title' | 'type'>
      ) | (
        { __typename?: 'ApiCallGraphqlAction' }
        & Pick<ApiCallGraphqlAction, 'id' | 'title' | 'type'>
      ) | (
        { __typename?: 'CalculationAction' }
        & Pick<CalculationAction, 'id' | 'title' | 'type'>
      ) | (
        { __typename?: 'ChecklistAction' }
        & Pick<ChecklistAction, 'id' | 'title' | 'type'>
      ) | (
        { __typename?: 'ClinicalNoteAction' }
        & Pick<ClinicalNoteAction, 'id' | 'title' | 'type'>
      ) | (
        { __typename?: 'ExtensionAction' }
        & Pick<ExtensionAction, 'id' | 'title' | 'type'>
      ) | (
        { __typename?: 'FormAction' }
        & Pick<FormAction, 'id' | 'title' | 'type'>
      ) | (
        { __typename?: 'MessageAction' }
        & Pick<MessageAction, 'id' | 'title' | 'type'>
      ) | (
        { __typename?: 'PushToEmrAction' }
        & Pick<PushToEmrAction, 'id' | 'title' | 'type'>
      )>> }
    ) }
  ) }
);

export type ReorderActionsMutationVariables = Exact<{
  input: ReorderActionsInput;
}>;


export type ReorderActionsMutation = (
  { __typename?: 'Mutation' }
  & { reorderActions: (
    { __typename?: 'ReorderActionsPayload' }
    & Pick<ReorderActionsPayload, 'code' | 'success'>
    & { step: (
      { __typename?: 'Step' }
      & Pick<Step, 'id'>
      & { actions?: Maybe<Array<(
        { __typename?: 'ApiCallAction' }
        & Pick<ApiCallAction, 'id' | 'title'>
      ) | (
        { __typename?: 'ApiCallGraphqlAction' }
        & Pick<ApiCallGraphqlAction, 'id' | 'title'>
      ) | (
        { __typename?: 'CalculationAction' }
        & Pick<CalculationAction, 'id' | 'title'>
      ) | (
        { __typename?: 'ChecklistAction' }
        & Pick<ChecklistAction, 'id' | 'title'>
      ) | (
        { __typename?: 'ClinicalNoteAction' }
        & Pick<ClinicalNoteAction, 'id' | 'title'>
      ) | (
        { __typename?: 'ExtensionAction' }
        & Pick<ExtensionAction, 'id' | 'title'>
      ) | (
        { __typename?: 'FormAction' }
        & Pick<FormAction, 'id' | 'title'>
      ) | (
        { __typename?: 'MessageAction' }
        & Pick<MessageAction, 'id' | 'title'>
      ) | (
        { __typename?: 'PushToEmrAction' }
        & Pick<PushToEmrAction, 'id' | 'title'>
      )>> }
    ) }
  ) }
);

export type StepActionsQueryVariables = Exact<{
  step_id: Scalars['String'];
}>;


export type StepActionsQuery = (
  { __typename?: 'Query' }
  & { step: (
    { __typename?: 'StepPayload' }
    & { step: (
      { __typename?: 'Step' }
      & Pick<Step, 'id'>
      & { actions?: Maybe<Array<(
        { __typename?: 'ApiCallAction' }
        & Pick<ApiCallAction, 'api_call_id' | 'id' | 'title' | 'type'>
      ) | (
        { __typename?: 'ApiCallGraphqlAction' }
        & Pick<ApiCallGraphqlAction, 'id' | 'title' | 'type'>
      ) | (
        { __typename?: 'CalculationAction' }
        & Pick<CalculationAction, 'id' | 'title' | 'type'>
      ) | (
        { __typename?: 'ChecklistAction' }
        & Pick<ChecklistAction, 'id' | 'title' | 'type'>
      ) | (
        { __typename?: 'ClinicalNoteAction' }
        & Pick<ClinicalNoteAction, 'clinical_note_id' | 'id' | 'title' | 'type'>
      ) | (
        { __typename?: 'ExtensionAction' }
        & Pick<ExtensionAction, 'extension_key' | 'id' | 'title' | 'type'>
      ) | (
        { __typename?: 'FormAction' }
        & Pick<FormAction, 'id' | 'title' | 'type'>
      ) | (
        { __typename?: 'MessageAction' }
        & Pick<MessageAction, 'message_id' | 'id' | 'title' | 'type'>
      ) | (
        { __typename?: 'PushToEmrAction' }
        & Pick<PushToEmrAction, 'emr_report_id' | 'id' | 'title' | 'type'>
      )>> }
    ) }
  ) }
);

export type SetActionStakeholdersMutationVariables = Exact<{
  input: SetActionStakeholdersInput;
}>;


export type SetActionStakeholdersMutation = (
  { __typename?: 'Mutation' }
  & { setActionStakeholders: (
    { __typename?: 'SetActionStakeholdersPayload' }
    & { action: (
      { __typename?: 'ApiCallAction' }
      & Pick<ApiCallAction, 'id' | 'title'>
      & { stakeholders: Array<(
        { __typename?: 'Stakeholder' }
        & Pick<Stakeholder, 'id' | 'clinical_app_role'>
        & { label: (
          { __typename?: 'StakeholderLabel' }
          & Pick<StakeholderLabel, 'en'>
        ) }
      )>, status: (
        { __typename?: 'ConfigurationStatus' }
        & ConfigurationStatusFragment
      ) }
    ) | (
      { __typename?: 'ApiCallGraphqlAction' }
      & Pick<ApiCallGraphqlAction, 'id' | 'title'>
      & { stakeholders: Array<(
        { __typename?: 'Stakeholder' }
        & Pick<Stakeholder, 'id' | 'clinical_app_role'>
        & { label: (
          { __typename?: 'StakeholderLabel' }
          & Pick<StakeholderLabel, 'en'>
        ) }
      )>, status: (
        { __typename?: 'ConfigurationStatus' }
        & ConfigurationStatusFragment
      ) }
    ) | (
      { __typename?: 'CalculationAction' }
      & Pick<CalculationAction, 'id' | 'title'>
      & { stakeholders: Array<(
        { __typename?: 'Stakeholder' }
        & Pick<Stakeholder, 'id' | 'clinical_app_role'>
        & { label: (
          { __typename?: 'StakeholderLabel' }
          & Pick<StakeholderLabel, 'en'>
        ) }
      )>, status: (
        { __typename?: 'ConfigurationStatus' }
        & ConfigurationStatusFragment
      ) }
    ) | (
      { __typename?: 'ChecklistAction' }
      & Pick<ChecklistAction, 'id' | 'title'>
      & { stakeholders: Array<(
        { __typename?: 'Stakeholder' }
        & Pick<Stakeholder, 'id' | 'clinical_app_role'>
        & { label: (
          { __typename?: 'StakeholderLabel' }
          & Pick<StakeholderLabel, 'en'>
        ) }
      )>, status: (
        { __typename?: 'ConfigurationStatus' }
        & ConfigurationStatusFragment
      ) }
    ) | (
      { __typename?: 'ClinicalNoteAction' }
      & Pick<ClinicalNoteAction, 'id' | 'title'>
      & { stakeholders: Array<(
        { __typename?: 'Stakeholder' }
        & Pick<Stakeholder, 'id' | 'clinical_app_role'>
        & { label: (
          { __typename?: 'StakeholderLabel' }
          & Pick<StakeholderLabel, 'en'>
        ) }
      )>, status: (
        { __typename?: 'ConfigurationStatus' }
        & ConfigurationStatusFragment
      ) }
    ) | (
      { __typename?: 'ExtensionAction' }
      & Pick<ExtensionAction, 'id' | 'title'>
      & { stakeholders: Array<(
        { __typename?: 'Stakeholder' }
        & Pick<Stakeholder, 'id' | 'clinical_app_role'>
        & { label: (
          { __typename?: 'StakeholderLabel' }
          & Pick<StakeholderLabel, 'en'>
        ) }
      )>, status: (
        { __typename?: 'ConfigurationStatus' }
        & ConfigurationStatusFragment
      ) }
    ) | (
      { __typename?: 'FormAction' }
      & Pick<FormAction, 'id' | 'title'>
      & { stakeholders: Array<(
        { __typename?: 'Stakeholder' }
        & Pick<Stakeholder, 'id' | 'clinical_app_role'>
        & { label: (
          { __typename?: 'StakeholderLabel' }
          & Pick<StakeholderLabel, 'en'>
        ) }
      )>, status: (
        { __typename?: 'ConfigurationStatus' }
        & ConfigurationStatusFragment
      ) }
    ) | (
      { __typename?: 'MessageAction' }
      & Pick<MessageAction, 'id' | 'title'>
      & { stakeholders: Array<(
        { __typename?: 'Stakeholder' }
        & Pick<Stakeholder, 'id' | 'clinical_app_role'>
        & { label: (
          { __typename?: 'StakeholderLabel' }
          & Pick<StakeholderLabel, 'en'>
        ) }
      )>, status: (
        { __typename?: 'ConfigurationStatus' }
        & ConfigurationStatusFragment
      ) }
    ) | (
      { __typename?: 'PushToEmrAction' }
      & Pick<PushToEmrAction, 'id' | 'title'>
      & { stakeholders: Array<(
        { __typename?: 'Stakeholder' }
        & Pick<Stakeholder, 'id' | 'clinical_app_role'>
        & { label: (
          { __typename?: 'StakeholderLabel' }
          & Pick<StakeholderLabel, 'en'>
        ) }
      )>, status: (
        { __typename?: 'ConfigurationStatus' }
        & ConfigurationStatusFragment
      ) }
    ) }
  ) }
);

export type GetActionTitleQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetActionTitleQuery = (
  { __typename?: 'Query' }
  & { action: (
    { __typename?: 'ActionPayload' }
    & { action: (
      { __typename?: 'ApiCallAction' }
      & Pick<ApiCallAction, 'id' | 'title'>
    ) | (
      { __typename?: 'ApiCallGraphqlAction' }
      & Pick<ApiCallGraphqlAction, 'id' | 'title'>
    ) | (
      { __typename?: 'CalculationAction' }
      & Pick<CalculationAction, 'id' | 'title'>
    ) | (
      { __typename?: 'ChecklistAction' }
      & Pick<ChecklistAction, 'id' | 'title'>
    ) | (
      { __typename?: 'ClinicalNoteAction' }
      & Pick<ClinicalNoteAction, 'id' | 'title'>
    ) | (
      { __typename?: 'ExtensionAction' }
      & Pick<ExtensionAction, 'id' | 'title'>
    ) | (
      { __typename?: 'FormAction' }
      & Pick<FormAction, 'id' | 'title'>
    ) | (
      { __typename?: 'MessageAction' }
      & Pick<MessageAction, 'id' | 'title'>
    ) | (
      { __typename?: 'PushToEmrAction' }
      & Pick<PushToEmrAction, 'id' | 'title'>
    ) }
  ) }
);

export type SetActionTitleMutationVariables = Exact<{
  input: SetActionTitleInput;
}>;


export type SetActionTitleMutation = (
  { __typename?: 'Mutation' }
  & { setActionTitle: (
    { __typename?: 'SetActionTitlePayload' }
    & { action: (
      { __typename?: 'ApiCallAction' }
      & Pick<ApiCallAction, 'id' | 'title'>
    ) | (
      { __typename?: 'ApiCallGraphqlAction' }
      & Pick<ApiCallGraphqlAction, 'id' | 'title'>
    ) | (
      { __typename?: 'CalculationAction' }
      & Pick<CalculationAction, 'id' | 'title'>
    ) | (
      { __typename?: 'ChecklistAction' }
      & Pick<ChecklistAction, 'id' | 'title'>
    ) | (
      { __typename?: 'ClinicalNoteAction' }
      & Pick<ClinicalNoteAction, 'id' | 'title'>
    ) | (
      { __typename?: 'ExtensionAction' }
      & Pick<ExtensionAction, 'id' | 'title'>
    ) | (
      { __typename?: 'FormAction' }
      & Pick<FormAction, 'id' | 'title'>
    ) | (
      { __typename?: 'MessageAction' }
      & Pick<MessageAction, 'id' | 'title'>
    ) | (
      { __typename?: 'PushToEmrAction' }
      & Pick<PushToEmrAction, 'id' | 'title'>
    ) }
  ) }
);

export type CalculationActionFragmentFragment = (
  { __typename?: 'CalculationAction' }
  & Pick<CalculationAction, 'calculation_id'>
  & { calculation_inputs: Array<(
    { __typename?: 'CalculationInput' }
    & Pick<CalculationInput, 'calculation_input_id' | 'data_point_definition_id'>
  )> }
);

export type GetCalculationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCalculationsQuery = (
  { __typename?: 'Query' }
  & { calculations: (
    { __typename?: 'CalculationsPayload' }
    & { calculations: Array<(
      { __typename?: 'Calculation' }
      & Pick<Calculation, 'id' | 'title' | 'description'>
    )> }
  ) }
);

export type SetActionCalculationIdMutationVariables = Exact<{
  input: SetActionCalculationIdInput;
}>;


export type SetActionCalculationIdMutation = (
  { __typename?: 'Mutation' }
  & { setActionCalculationId: (
    { __typename?: 'SetActionCalculationIdPayload' }
    & { action: (
      { __typename?: 'CalculationAction' }
      & Pick<CalculationAction, 'id' | 'title'>
      & { status: (
        { __typename?: 'ConfigurationStatus' }
        & ConfigurationStatusFragment
      ) }
      & CalculationActionFragmentFragment
    ) }
  ) }
);

export type SetActionCalculationInputMutationVariables = Exact<{
  input: SetCalculationDataPointInput;
}>;


export type SetActionCalculationInputMutation = (
  { __typename?: 'Mutation' }
  & { setActionCalculationInput: (
    { __typename?: 'SetActionCalculationInputPayload' }
    & { action: (
      { __typename?: 'CalculationAction' }
      & Pick<CalculationAction, 'id' | 'title'>
      & { status: (
        { __typename?: 'ConfigurationStatus' }
        & ConfigurationStatusFragment
      ) }
      & CalculationActionFragmentFragment
    ) }
  ) }
);

export type CheckListFragmentFragment = (
  { __typename?: 'ChecklistAction' }
  & Pick<ChecklistAction, 'checklist'>
);

export type SetActionChecklistItemsMutationVariables = Exact<{
  input: SetActionChecklistItemsInput;
}>;


export type SetActionChecklistItemsMutation = (
  { __typename?: 'Mutation' }
  & { setActionChecklistItems: (
    { __typename?: 'SetActionChecklistItemsPayload' }
    & { action: (
      { __typename?: 'ChecklistAction' }
      & Pick<ChecklistAction, 'id' | 'title'>
      & CheckListFragmentFragment
    ) }
  ) }
);

export type CreatePathwayMutationVariables = Exact<{
  input: CreatePathwayInput;
}>;


export type CreatePathwayMutation = (
  { __typename?: 'Mutation' }
  & { createPathway: (
    { __typename?: 'CreatePathwayPayload' }
    & { pathway: (
      { __typename?: 'Pathway' }
      & PathwayFragment
    ) }
  ) }
);

export type AddDerivedDataPointDefinitionMutationVariables = Exact<{
  input: AddDerivedDataPointDefinitionInput;
}>;


export type AddDerivedDataPointDefinitionMutation = (
  { __typename?: 'Mutation' }
  & { addDerivedDataPointDefinition: (
    { __typename?: 'AddDerivedDataPointDefinitionPayload' }
    & Pick<AddDerivedDataPointDefinitionPayload, 'code' | 'success'>
    & { data_point_definition: (
      { __typename?: 'DataPointDefinition' }
      & Pick<DataPointDefinition, 'key' | 'source_definition_id' | 'id' | 'category' | 'valueType' | 'unit' | 'optional' | 'title' | 'pii' | 'jsonPath'>
    ) }
  ) }
);

export type CreateFormMutationVariables = Exact<{
  input: CreateFormInput;
}>;


export type CreateFormMutation = (
  { __typename?: 'Mutation' }
  & { createForm: (
    { __typename?: 'CreateFormPayload' }
    & Pick<CreateFormPayload, 'success'>
    & { form: (
      { __typename?: 'Form' }
      & Pick<Form, 'id'>
    ) }
  ) }
);

export type FormActionFragmentFragment = (
  { __typename?: 'FormAction' }
  & Pick<FormAction, 'form_id' | 'form_display_mode'>
);

export type GetFormsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFormsQuery = (
  { __typename?: 'Query' }
  & { forms: (
    { __typename?: 'FormsPayload' }
    & { forms: Array<(
      { __typename?: 'Form' }
      & Pick<Form, 'id' | 'title' | 'description'>
      & { permissions: (
        { __typename?: 'Permissions' }
        & Pick<Permissions, 'write'>
      ) }
    )> }
  ) }
);

export type SetActionFormDisplayModeMutationVariables = Exact<{
  input: SetActionFormDisplayModeInput;
}>;


export type SetActionFormDisplayModeMutation = (
  { __typename?: 'Mutation' }
  & { setActionFormDisplayMode: (
    { __typename?: 'SetActionFormDisplayModePayload' }
    & { action: (
      { __typename?: 'FormAction' }
      & Pick<FormAction, 'id' | 'title'>
      & FormActionFragmentFragment
    ) }
  ) }
);

export type SetActionFormIdMutationVariables = Exact<{
  input: SetActionFormIdInput;
}>;


export type SetActionFormIdMutation = (
  { __typename?: 'Mutation' }
  & { setActionFormId: (
    { __typename?: 'SetActionFormIdPayload' }
    & { action: (
      { __typename?: 'FormAction' }
      & Pick<FormAction, 'id' | 'title'>
      & { status: (
        { __typename?: 'ConfigurationStatus' }
        & ConfigurationStatusFragment
      ) }
      & FormActionFragmentFragment
    ) }
  ) }
);

export type AddQuestionToFormMutationVariables = Exact<{
  input: AddQuestionToFormInput;
}>;


export type AddQuestionToFormMutation = (
  { __typename?: 'Mutation' }
  & { addQuestionToForm: (
    { __typename?: 'AddQuestionToFormPayload' }
    & { form: (
      { __typename?: 'Form' }
      & FormFragment
    ), question: (
      { __typename?: 'Question' }
      & QuestionFragment
    ) }
  ) }
);

export type AddQuestionsToFormMutationVariables = Exact<{
  input: AddQuestionsToFormInput;
}>;


export type AddQuestionsToFormMutation = (
  { __typename?: 'Mutation' }
  & { addQuestionsToForm: (
    { __typename?: 'AddQuestionsToFormPayload' }
    & { form: (
      { __typename?: 'Form' }
      & FormFragment
    ), questions: Array<(
      { __typename?: 'Question' }
      & QuestionFragment
    )> }
  ) }
);

export type DuplicateQuestionMutationVariables = Exact<{
  input: DuplicateQuestionInput;
}>;


export type DuplicateQuestionMutation = (
  { __typename?: 'Mutation' }
  & { duplicateQuestion: (
    { __typename?: 'DuplicateQuestionPayload' }
    & { form: (
      { __typename?: 'Form' }
      & Pick<Form, 'id'>
      & { questions: Array<(
        { __typename?: 'Question' }
        & QuestionFragment
      )> }
    ), question: (
      { __typename?: 'Question' }
      & QuestionFragment
    ) }
  ) }
);

export type EvaluateFormRulesMutationVariables = Exact<{
  input: EvaluateFormRulesInput;
}>;


export type EvaluateFormRulesMutation = (
  { __typename?: 'Mutation' }
  & { evaluateFormRules: (
    { __typename?: 'EvaluateFormRulesPayload' }
    & { results: Array<(
      { __typename?: 'QuestionRuleResult' }
      & Pick<QuestionRuleResult, 'question_id' | 'rule_id' | 'satisfied'>
    )> }
  ) }
);

export type RemoveQuestionFromFormMutationVariables = Exact<{
  input: RemoveQuestionFromFormInput;
}>;


export type RemoveQuestionFromFormMutation = (
  { __typename?: 'Mutation' }
  & { removeQuestionFromForm: (
    { __typename?: 'RemoveQuestionFromFormPayload' }
    & { form: (
      { __typename?: 'Form' }
      & FormFragment
    ) }
  ) }
);

export type ReorderQuestionsMutationVariables = Exact<{
  input: ReorderQuestionsInput;
}>;


export type ReorderQuestionsMutation = (
  { __typename?: 'Mutation' }
  & { reorderQuestions: (
    { __typename?: 'ReorderQuestionsPayload' }
    & { form: (
      { __typename?: 'Form' }
      & FormFragment
    ) }
  ) }
);

export type SetFormKeyMutationVariables = Exact<{
  input: SetFormKeyInput;
}>;


export type SetFormKeyMutation = (
  { __typename?: 'Mutation' }
  & { setFormKey: (
    { __typename?: 'SetFormKeyPayload' }
    & { form: (
      { __typename?: 'Form' }
      & FormFragment
    ) }
  ) }
);

export type SetFormMetadataMutationVariables = Exact<{
  input: SetFormMetadataInput;
}>;


export type SetFormMetadataMutation = (
  { __typename?: 'Mutation' }
  & { setFormMetadata: (
    { __typename?: 'SetFormMetadataPayload' }
    & { form: (
      { __typename?: 'Form' }
      & FormFragment
    ) }
  ) }
);

export type SetFormTitleMutationVariables = Exact<{
  input: SetFormTitleInput;
}>;


export type SetFormTitleMutation = (
  { __typename?: 'Mutation' }
  & { setFormTitle: (
    { __typename?: 'SetFormTitlePayload' }
    & { form: (
      { __typename?: 'Form' }
      & FormFragment
    ) }
  ) }
);

export type SetFormTrademarkMutationVariables = Exact<{
  input: SetFormTrademarkInput;
}>;


export type SetFormTrademarkMutation = (
  { __typename?: 'Mutation' }
  & { setFormTrademark: (
    { __typename?: 'SetFormTrademarkPayload' }
    & { form: (
      { __typename?: 'Form' }
      & Pick<Form, 'id' | 'trademark'>
    ) }
  ) }
);

export type GetActionMessageQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetActionMessageQuery = (
  { __typename?: 'Query' }
  & { action: (
    { __typename?: 'ActionPayload' }
    & { action: (
      { __typename?: 'ApiCallAction' }
      & Pick<ApiCallAction, 'id'>
      & { stakeholders: Array<(
        { __typename?: 'Stakeholder' }
        & Pick<Stakeholder, 'id'>
        & { label: (
          { __typename?: 'StakeholderLabel' }
          & Pick<StakeholderLabel, 'en'>
        ) }
      )>, status: (
        { __typename?: 'ConfigurationStatus' }
        & ConfigurationStatusFragment
      ) }
    ) | (
      { __typename?: 'ApiCallGraphqlAction' }
      & Pick<ApiCallGraphqlAction, 'id'>
      & { stakeholders: Array<(
        { __typename?: 'Stakeholder' }
        & Pick<Stakeholder, 'id'>
        & { label: (
          { __typename?: 'StakeholderLabel' }
          & Pick<StakeholderLabel, 'en'>
        ) }
      )>, status: (
        { __typename?: 'ConfigurationStatus' }
        & ConfigurationStatusFragment
      ) }
    ) | (
      { __typename?: 'CalculationAction' }
      & Pick<CalculationAction, 'id'>
      & { stakeholders: Array<(
        { __typename?: 'Stakeholder' }
        & Pick<Stakeholder, 'id'>
        & { label: (
          { __typename?: 'StakeholderLabel' }
          & Pick<StakeholderLabel, 'en'>
        ) }
      )>, status: (
        { __typename?: 'ConfigurationStatus' }
        & ConfigurationStatusFragment
      ) }
    ) | (
      { __typename?: 'ChecklistAction' }
      & Pick<ChecklistAction, 'id'>
      & { stakeholders: Array<(
        { __typename?: 'Stakeholder' }
        & Pick<Stakeholder, 'id'>
        & { label: (
          { __typename?: 'StakeholderLabel' }
          & Pick<StakeholderLabel, 'en'>
        ) }
      )>, status: (
        { __typename?: 'ConfigurationStatus' }
        & ConfigurationStatusFragment
      ) }
    ) | (
      { __typename?: 'ClinicalNoteAction' }
      & Pick<ClinicalNoteAction, 'id'>
      & { stakeholders: Array<(
        { __typename?: 'Stakeholder' }
        & Pick<Stakeholder, 'id'>
        & { label: (
          { __typename?: 'StakeholderLabel' }
          & Pick<StakeholderLabel, 'en'>
        ) }
      )>, status: (
        { __typename?: 'ConfigurationStatus' }
        & ConfigurationStatusFragment
      ) }
    ) | (
      { __typename?: 'ExtensionAction' }
      & Pick<ExtensionAction, 'id'>
      & { stakeholders: Array<(
        { __typename?: 'Stakeholder' }
        & Pick<Stakeholder, 'id'>
        & { label: (
          { __typename?: 'StakeholderLabel' }
          & Pick<StakeholderLabel, 'en'>
        ) }
      )>, status: (
        { __typename?: 'ConfigurationStatus' }
        & ConfigurationStatusFragment
      ) }
    ) | (
      { __typename?: 'FormAction' }
      & Pick<FormAction, 'id'>
      & { stakeholders: Array<(
        { __typename?: 'Stakeholder' }
        & Pick<Stakeholder, 'id'>
        & { label: (
          { __typename?: 'StakeholderLabel' }
          & Pick<StakeholderLabel, 'en'>
        ) }
      )>, status: (
        { __typename?: 'ConfigurationStatus' }
        & ConfigurationStatusFragment
      ) }
    ) | (
      { __typename?: 'MessageAction' }
      & Pick<MessageAction, 'id'>
      & { message?: Maybe<(
        { __typename?: 'Message' }
        & Pick<Message, 'id' | 'subject' | 'body'>
        & { attachments?: Maybe<Array<(
          { __typename?: 'MessageAttachment' }
          & Pick<MessageAttachment, 'id' | 'name' | 'url' | 'type'>
        )>> }
      )>, stakeholders: Array<(
        { __typename?: 'Stakeholder' }
        & Pick<Stakeholder, 'id'>
        & { label: (
          { __typename?: 'StakeholderLabel' }
          & Pick<StakeholderLabel, 'en'>
        ) }
      )>, status: (
        { __typename?: 'ConfigurationStatus' }
        & ConfigurationStatusFragment
      ) }
    ) | (
      { __typename?: 'PushToEmrAction' }
      & Pick<PushToEmrAction, 'id'>
      & { stakeholders: Array<(
        { __typename?: 'Stakeholder' }
        & Pick<Stakeholder, 'id'>
        & { label: (
          { __typename?: 'StakeholderLabel' }
          & Pick<StakeholderLabel, 'en'>
        ) }
      )>, status: (
        { __typename?: 'ConfigurationStatus' }
        & ConfigurationStatusFragment
      ) }
    ) }
  ) }
);

export type MessageActionFragmentFragment = (
  { __typename?: 'MessageAction' }
  & Pick<MessageAction, 'message_id'>
);

export type SetActionMessageIdMutationVariables = Exact<{
  input: SetActionMessageIdInput;
}>;


export type SetActionMessageIdMutation = (
  { __typename?: 'Mutation' }
  & { setActionMessageId: (
    { __typename?: 'SetActionMessageIdPayload' }
    & { action: (
      { __typename?: 'MessageAction' }
      & Pick<MessageAction, 'id' | 'title'>
      & MessageActionFragmentFragment
    ) }
  ) }
);

export type DuplicateCareflowMutationVariables = Exact<{
  input: DuplicateCareflowInput;
}>;


export type DuplicateCareflowMutation = (
  { __typename?: 'Mutation' }
  & { duplicateCareflow: (
    { __typename?: 'DuplicateCareflowPayload' }
    & Pick<DuplicateCareflowPayload, 'success' | 'careflow_id'>
  ) }
);

export type PathwaysQueryVariables = Exact<{ [key: string]: never; }>;


export type PathwaysQuery = (
  { __typename?: 'Query' }
  & { pathways: (
    { __typename?: 'PathwaysPayload' }
    & { pathways: Array<(
      { __typename?: 'Pathway' }
      & PathwayFragment
    )> }
  ) }
);

export type TrashPathwayMutationVariables = Exact<{
  input: TrashPathwayInput;
}>;


export type TrashPathwayMutation = (
  { __typename?: 'Mutation' }
  & { trashPathway: (
    { __typename?: 'EmptyPayload' }
    & Pick<EmptyPayload, 'success'>
  ) }
);

export type SetEmrReportBodyMutationVariables = Exact<{
  input: SetEmrReportBodyInput;
}>;


export type SetEmrReportBodyMutation = (
  { __typename?: 'Mutation' }
  & { setEmrReportBody: (
    { __typename?: 'EmrReportPayload' }
    & { emr_report: (
      { __typename?: 'EmrReport' }
      & Pick<EmrReport, 'id' | 'body'>
    ) }
  ) }
);

export type UpdateQuestionMutationVariables = Exact<{
  input: UpdateQuestionInput;
}>;


export type UpdateQuestionMutation = (
  { __typename?: 'Mutation' }
  & { updateQuestion: (
    { __typename?: 'UpdateQuestionPayload' }
    & { question: (
      { __typename?: 'Question' }
      & Pick<Question, 'id' | 'title' | 'key' | 'form_id'>
      & { options?: Maybe<Array<(
        { __typename?: 'Option' }
        & Pick<Option, 'id' | 'value' | 'label'>
      )>> }
    ) }
  ) }
);

export type AddRuleToQuestionMutationVariables = Exact<{
  input: AddRuleToQuestionInput;
}>;


export type AddRuleToQuestionMutation = (
  { __typename?: 'Mutation' }
  & { addRuleToQuestion: (
    { __typename?: 'AddRuleToQuestionPayload' }
    & { question: (
      { __typename?: 'Question' }
      & QuestionFragment
    ), rule: (
      { __typename?: 'Rule' }
      & RuleFragment
    ) }
  ) }
);

export type GetFormDataPointDefinitionsQueryVariables = Exact<{
  input: FormDataPointDefinitionsInput;
}>;


export type GetFormDataPointDefinitionsQuery = (
  { __typename?: 'Query' }
  & { form_data_point_definitions: (
    { __typename?: 'DataPointDefinitionsPayload' }
    & { data_point_definitions: Array<(
      { __typename?: 'DataPointDefinition' }
      & Pick<DataPointDefinition, 'id' | 'title' | 'category' | 'key' | 'valueType' | 'unit'>
      & { possibleValues?: Maybe<Array<(
        { __typename?: 'DataPointPossibleValue' }
        & Pick<DataPointPossibleValue, 'label' | 'value'>
      )>>, range?: Maybe<(
        { __typename?: 'Range' }
        & Pick<Range, 'min' | 'max'>
      )> }
    )> }
  ) }
);

export type RemoveRuleFromQuestionMutationVariables = Exact<{
  input: RemoveRuleFromQuestionInput;
}>;


export type RemoveRuleFromQuestionMutation = (
  { __typename?: 'Mutation' }
  & { removeRuleFromQuestion: (
    { __typename?: 'RemoveRuleFromQuestionPayload' }
    & { question: (
      { __typename?: 'Question' }
      & QuestionFragment
    ) }
  ) }
);

export type AddConditionToRuleMutationVariables = Exact<{
  input: AddConditionToRuleInput;
}>;


export type AddConditionToRuleMutation = (
  { __typename?: 'Mutation' }
  & { addConditionToRule: (
    { __typename?: 'AddConditionToRulePayload' }
    & { rule: (
      { __typename?: 'Rule' }
      & RuleFragment
    ) }
  ) }
);

export type GetRuleQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetRuleQuery = (
  { __typename?: 'Query' }
  & { rule: (
    { __typename?: 'RulePayload' }
    & { rule: (
      { __typename?: 'Rule' }
      & RuleFragment
    ) }
  ) }
);

export type RemoveConditionFromRuleMutationVariables = Exact<{
  input: RemoveConditionFromRuleInput;
}>;


export type RemoveConditionFromRuleMutation = (
  { __typename?: 'Mutation' }
  & { removeConditionFromRule: (
    { __typename?: 'RemoveConditionFromRulePayload' }
    & Pick<RemoveConditionFromRulePayload, 'code' | 'success'>
    & { rule: (
      { __typename?: 'Rule' }
      & RuleFragment
    ) }
  ) }
);

export type RuleFragment = (
  { __typename?: 'Rule' }
  & Pick<Rule, 'id' | 'boolean_operator'>
  & { status: (
    { __typename?: 'ConfigurationStatus' }
    & ConfigurationStatusFragment
  ), conditions: Array<(
    { __typename?: 'Condition' }
    & Pick<Condition, 'id' | 'reference' | 'reference_key' | 'operator'>
    & { operand?: Maybe<(
      { __typename?: 'Operand' }
      & Pick<Operand, 'type' | 'value'>
    )>, status: (
      { __typename?: 'ConfigurationStatus' }
      & ConfigurationStatusFragment
    ) }
  )> }
);

export type SetConditionOperandMutationVariables = Exact<{
  input: SetConditionOperandInput;
}>;


export type SetConditionOperandMutation = (
  { __typename?: 'Mutation' }
  & { setConditionOperand: (
    { __typename?: 'SetConditionOperandPayload' }
    & { rule: (
      { __typename?: 'Rule' }
      & RuleFragment
    ) }
  ) }
);

export type SetConditionOperatorMutationVariables = Exact<{
  input: SetConditionOperatorInput;
}>;


export type SetConditionOperatorMutation = (
  { __typename?: 'Mutation' }
  & { setConditionOperator: (
    { __typename?: 'SetConditionOperatorPayload' }
    & { rule: (
      { __typename?: 'Rule' }
      & RuleFragment
    ) }
  ) }
);

export type SetConditionReferenceMutationVariables = Exact<{
  input: SetConditionReferenceInput;
}>;


export type SetConditionReferenceMutation = (
  { __typename?: 'Mutation' }
  & { setConditionReference: (
    { __typename?: 'SetConditionReferencePayload' }
    & { rule: (
      { __typename?: 'Rule' }
      & RuleFragment
    ) }
  ) }
);

export type SetRuleBooleanOperatorMutationVariables = Exact<{
  input: SetRuleBooleanOperatorInput;
}>;


export type SetRuleBooleanOperatorMutation = (
  { __typename?: 'Mutation' }
  & { setRuleBooleanOperator: (
    { __typename?: 'SetRuleBooleanOperatorPayload' }
    & { rule: (
      { __typename?: 'Rule' }
      & RuleFragment
    ) }
  ) }
);

export type StepQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type StepQuery = (
  { __typename?: 'Query' }
  & { step: (
    { __typename?: 'StepPayload' }
    & Pick<StepPayload, 'success' | 'code'>
    & { step: (
      { __typename?: 'Step' }
      & Pick<Step, 'id' | 'title'>
      & { actions?: Maybe<Array<(
        { __typename?: 'ApiCallAction' }
        & Pick<ApiCallAction, 'id' | 'type'>
      ) | (
        { __typename?: 'ApiCallGraphqlAction' }
        & Pick<ApiCallGraphqlAction, 'id' | 'type'>
      ) | (
        { __typename?: 'CalculationAction' }
        & Pick<CalculationAction, 'id' | 'type'>
      ) | (
        { __typename?: 'ChecklistAction' }
        & Pick<ChecklistAction, 'id' | 'type'>
      ) | (
        { __typename?: 'ClinicalNoteAction' }
        & Pick<ClinicalNoteAction, 'id' | 'type'>
      ) | (
        { __typename?: 'ExtensionAction' }
        & Pick<ExtensionAction, 'id' | 'type'>
      ) | (
        { __typename?: 'FormAction' }
        & Pick<FormAction, 'id' | 'type'>
      ) | (
        { __typename?: 'MessageAction' }
        & Pick<MessageAction, 'id' | 'type'>
      ) | (
        { __typename?: 'PushToEmrAction' }
        & Pick<PushToEmrAction, 'id' | 'type'>
      )>>, documentation?: Maybe<(
        { __typename?: 'FormattedText' }
        & Pick<FormattedText, 'format'>
        & { content: (
          { __typename?: 'TranslatedText' }
          & Pick<TranslatedText, 'en'>
        ) }
      )>, transitions: Array<(
        { __typename?: 'Transition' }
        & Pick<Transition, 'id'>
        & { status: (
          { __typename?: 'ConfigurationStatus' }
          & Pick<ConfigurationStatus, 'status'>
        ) }
      )> }
    ) }
  ) }
);

export type SetStepDocumentationMutationVariables = Exact<{
  input: SetStepDocumentationInput;
}>;


export type SetStepDocumentationMutation = (
  { __typename?: 'Mutation' }
  & { setStepDocumentation: (
    { __typename?: 'SetStepDocumentationPayload' }
    & Pick<SetStepDocumentationPayload, 'success' | 'code'>
    & { step: (
      { __typename?: 'Step' }
      & Pick<Step, 'id' | 'title' | 'description'>
      & { documentation?: Maybe<(
        { __typename?: 'FormattedText' }
        & Pick<FormattedText, 'format'>
        & { content: (
          { __typename?: 'TranslatedText' }
          & Pick<TranslatedText, 'en'>
        ) }
      )> }
    ) }
  ) }
);

export type AddLabelToStepMutationVariables = Exact<{
  input: AddLabelToStepInput;
}>;


export type AddLabelToStepMutation = (
  { __typename?: 'Mutation' }
  & { addLabelToStep: (
    { __typename?: 'AddLabelToStepPayload' }
    & { step: (
      { __typename?: 'Step' }
      & Pick<Step, 'id'>
      & { label?: Maybe<(
        { __typename?: 'Label' }
        & Pick<Label, 'id' | 'color' | 'text'>
      )> }
    ) }
  ) }
);

export type GetPathwayLabelsQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetPathwayLabelsQuery = (
  { __typename?: 'Query' }
  & { pathway: (
    { __typename?: 'PathwayPayload' }
    & { pathway: (
      { __typename?: 'Pathway' }
      & Pick<Pathway, 'id'>
      & { labels?: Maybe<Array<(
        { __typename?: 'Label' }
        & Pick<Label, 'id' | 'color' | 'text'>
      )>> }
    ) }
  ) }
);

export type RemoveLabelFromStepMutationVariables = Exact<{
  input: RemoveLabelFromStepInput;
}>;


export type RemoveLabelFromStepMutation = (
  { __typename?: 'Mutation' }
  & { removeLabelFromStep: (
    { __typename?: 'RemoveLabelFromStepPayload' }
    & { step: (
      { __typename?: 'Step' }
      & Pick<Step, 'id'>
      & { label?: Maybe<(
        { __typename?: 'Label' }
        & Pick<Label, 'id' | 'color' | 'text'>
      )> }
    ) }
  ) }
);

export type StepLabelQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type StepLabelQuery = (
  { __typename?: 'Query' }
  & { step: (
    { __typename?: 'StepPayload' }
    & Pick<StepPayload, 'success' | 'code'>
    & { step: (
      { __typename?: 'Step' }
      & Pick<Step, 'id'>
      & { label?: Maybe<(
        { __typename?: 'Label' }
        & Pick<Label, 'id' | 'color' | 'text'>
      )> }
    ) }
  ) }
);

export type UpdateLabelTextMutationVariables = Exact<{
  input: UpdateLabelTextInput;
}>;


export type UpdateLabelTextMutation = (
  { __typename?: 'Mutation' }
  & { updateLabelText: (
    { __typename?: 'UpdateLabelTextPayload' }
    & Pick<UpdateLabelTextPayload, 'success'>
    & { label: (
      { __typename?: 'Label' }
      & Pick<Label, 'id' | 'text'>
    ) }
  ) }
);

export type StepTemplateQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type StepTemplateQuery = (
  { __typename?: 'Query' }
  & { step: (
    { __typename?: 'StepPayload' }
    & Pick<StepPayload, 'success' | 'code'>
    & { step: (
      { __typename?: 'Step' }
      & Pick<Step, 'id' | 'title'>
      & { actions?: Maybe<Array<(
        { __typename?: 'ApiCallAction' }
        & Pick<ApiCallAction, 'id'>
      ) | (
        { __typename?: 'ApiCallGraphqlAction' }
        & Pick<ApiCallGraphqlAction, 'id'>
      ) | (
        { __typename?: 'CalculationAction' }
        & Pick<CalculationAction, 'id'>
      ) | (
        { __typename?: 'ChecklistAction' }
        & Pick<ChecklistAction, 'id'>
      ) | (
        { __typename?: 'ClinicalNoteAction' }
        & Pick<ClinicalNoteAction, 'id'>
      ) | (
        { __typename?: 'ExtensionAction' }
        & Pick<ExtensionAction, 'id'>
      ) | (
        { __typename?: 'FormAction' }
        & Pick<FormAction, 'id'>
      ) | (
        { __typename?: 'MessageAction' }
        & Pick<MessageAction, 'id'>
      ) | (
        { __typename?: 'PushToEmrAction' }
        & Pick<PushToEmrAction, 'id'>
      )>>, documentation?: Maybe<(
        { __typename?: 'FormattedText' }
        & Pick<FormattedText, 'format'>
        & { content: (
          { __typename?: 'TranslatedText' }
          & Pick<TranslatedText, 'en'>
        ) }
      )> }
    ) }
  ) }
);

export type GetStepTitleQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetStepTitleQuery = (
  { __typename?: 'Query' }
  & { step: (
    { __typename?: 'StepPayload' }
    & { step: (
      { __typename?: 'Step' }
      & Pick<Step, 'id' | 'title'>
    ) }
  ) }
);

export type ClearTimingDelayMutationVariables = Exact<{
  input: ClearTimingDelayInput;
}>;


export type ClearTimingDelayMutation = (
  { __typename?: 'Mutation' }
  & { clearTimingDelay: (
    { __typename?: 'ClearTimingDelayPayload' }
    & { timing: (
      { __typename?: 'Timing' }
      & TimingFragment
    ) }
  ) }
);

export type ClearTimingReferenceMutationVariables = Exact<{
  input: ClearTimingReferenceInput;
}>;


export type ClearTimingReferenceMutation = (
  { __typename?: 'Mutation' }
  & { clearTimingReference: (
    { __typename?: 'ClearTimingReferencePayload' }
    & { timing: (
      { __typename?: 'Timing' }
      & TimingFragment
    ) }
  ) }
);

export type GetTimingQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetTimingQuery = (
  { __typename?: 'Query' }
  & { timing: (
    { __typename?: 'TimingPayload' }
    & { timing: (
      { __typename?: 'Timing' }
      & TimingFragment
    ) }
  ) }
);

export type SetTimingDelayMutationVariables = Exact<{
  input: SetTimingDelayInput;
}>;


export type SetTimingDelayMutation = (
  { __typename?: 'Mutation' }
  & { setTimingDelay: (
    { __typename?: 'SetTimingDelayPayload' }
    & { timing: (
      { __typename?: 'Timing' }
      & TimingFragment
    ) }
  ) }
);

export type SetTimingReferenceMutationVariables = Exact<{
  input: SetTimingReferenceInput;
}>;


export type SetTimingReferenceMutation = (
  { __typename?: 'Mutation' }
  & { setTimingReference: (
    { __typename?: 'SetTimingReferencePayload' }
    & { timing: (
      { __typename?: 'Timing' }
      & TimingFragment
    ) }
  ) }
);

export type TimingFragment = (
  { __typename?: 'Timing' }
  & Pick<Timing, 'id' | 'reference'>
  & { delay?: Maybe<(
    { __typename?: 'Delay' }
    & Pick<Delay, 'unit' | 'amount'>
  )> }
);

export type StartTransitionFromStepMutationVariables = Exact<{
  input: StartTransitionFromStepInput;
}>;


export type StartTransitionFromStepMutation = (
  { __typename?: 'Mutation' }
  & { startTransitionFromStep: (
    { __typename?: 'StartTransitionFromStepPayload' }
    & { step: (
      { __typename?: 'Step' }
      & Pick<Step, 'id'>
      & { transitions: Array<(
        { __typename?: 'Transition' }
        & TransitionPartsFragment
      )> }
    ), transition: (
      { __typename?: 'Transition' }
      & TransitionPartsFragment
    ) }
  ) }
);

export type StepTransitionsQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type StepTransitionsQuery = (
  { __typename?: 'Query' }
  & { step: (
    { __typename?: 'StepPayload' }
    & Pick<StepPayload, 'success' | 'code'>
    & { step: (
      { __typename?: 'Step' }
      & Pick<Step, 'id'>
      & { transitions: Array<(
        { __typename?: 'Transition' }
        & TransitionPartsFragment
      )> }
    ) }
  ) }
);

export type TransitionPartsFragment = (
  { __typename?: 'Transition' }
  & Pick<Transition, 'id' | 'default'>
  & { origin: (
    { __typename?: 'TransitionNode' }
    & Pick<TransitionNode, 'type' | 'node_id' | 'node_title'>
  ), destination?: Maybe<(
    { __typename?: 'TransitionNode' }
    & Pick<TransitionNode, 'type' | 'node_id' | 'node_title'>
  )>, status: (
    { __typename?: 'ConfigurationStatus' }
    & ConfigurationStatusFragment
  ), transitionOrientation: (
    { __typename?: 'TransitionOrientation' }
    & Pick<TransitionOrientation, 'origin_orientation' | 'destination_orientation'>
  ), timing?: Maybe<(
    { __typename?: 'Timing' }
    & Pick<Timing, 'id' | 'reference'>
    & { delay?: Maybe<(
      { __typename?: 'Delay' }
      & Pick<Delay, 'amount' | 'unit'>
    )> }
  )>, rule?: Maybe<(
    { __typename?: 'Rule' }
    & Pick<Rule, 'id' | 'boolean_operator'>
    & { conditions: Array<(
      { __typename?: 'Condition' }
      & Pick<Condition, 'id' | 'reference' | 'operator'>
      & { operand?: Maybe<(
        { __typename?: 'Operand' }
        & Pick<Operand, 'type' | 'value'>
      )>, status: (
        { __typename?: 'ConfigurationStatus' }
        & ConfigurationStatusFragment
      ) }
    )>, status: (
      { __typename?: 'ConfigurationStatus' }
      & ConfigurationStatusFragment
    ) }
  )> }
);

export type TransitionQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type TransitionQuery = (
  { __typename?: 'Query' }
  & { transition: (
    { __typename?: 'TransitionPayload' }
    & { transition: (
      { __typename?: 'Transition' }
      & Pick<Transition, 'id'>
      & { origin: (
        { __typename?: 'TransitionNode' }
        & Pick<TransitionNode, 'type' | 'node_id' | 'node_title'>
      ), status: (
        { __typename?: 'ConfigurationStatus' }
        & ConfigurationStatusFragment
      ), rule?: Maybe<(
        { __typename?: 'Rule' }
        & Pick<Rule, 'id'>
        & { status: (
          { __typename?: 'ConfigurationStatus' }
          & ConfigurationStatusFragment
        ), conditions: Array<(
          { __typename?: 'Condition' }
          & { status: (
            { __typename?: 'ConfigurationStatus' }
            & ConfigurationStatusFragment
          ) }
        )> }
      )> }
    ) }
  ) }
);

export type ConfigurationStatusFragment = (
  { __typename?: 'ConfigurationStatus' }
  & Pick<ConfigurationStatus, 'status' | 'errors'>
);

export type GetTransitionQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetTransitionQuery = (
  { __typename?: 'Query' }
  & { transition: (
    { __typename?: 'TransitionPayload' }
    & { transition: (
      { __typename?: 'Transition' }
      & TransitionFragment
    ) }
  ) }
);

export type TransitionFragment = (
  { __typename?: 'Transition' }
  & Pick<Transition, 'id' | 'default'>
  & { origin: (
    { __typename?: 'TransitionNode' }
    & Pick<TransitionNode, 'type' | 'node_id'>
  ), destination?: Maybe<(
    { __typename?: 'TransitionNode' }
    & Pick<TransitionNode, 'type' | 'node_id'>
  )>, transitionOrientation: (
    { __typename?: 'TransitionOrientation' }
    & Pick<TransitionOrientation, 'origin_orientation' | 'destination_orientation'>
  ), rule?: Maybe<(
    { __typename?: 'Rule' }
    & Pick<Rule, 'id'>
  )>, timing?: Maybe<(
    { __typename?: 'Timing' }
    & Pick<Timing, 'id'>
  )>, status: (
    { __typename?: 'ConfigurationStatus' }
    & ConfigurationStatusFragment
  ) }
);

export type ClearActionIdleTimeMutationVariables = Exact<{
  input: ClearActionIdleTimeInput;
}>;


export type ClearActionIdleTimeMutation = (
  { __typename?: 'Mutation' }
  & { clearActionIdleTime: (
    { __typename?: 'ClearActionIdleTimePayload' }
    & { action: (
      { __typename?: 'ApiCallAction' }
      & Pick<ApiCallAction, 'id'>
      & { idle_time?: Maybe<(
        { __typename?: 'Delay' }
        & Pick<Delay, 'unit' | 'amount'>
      )> }
    ) | (
      { __typename?: 'ApiCallGraphqlAction' }
      & Pick<ApiCallGraphqlAction, 'id'>
      & { idle_time?: Maybe<(
        { __typename?: 'Delay' }
        & Pick<Delay, 'unit' | 'amount'>
      )> }
    ) | (
      { __typename?: 'CalculationAction' }
      & Pick<CalculationAction, 'id'>
      & { idle_time?: Maybe<(
        { __typename?: 'Delay' }
        & Pick<Delay, 'unit' | 'amount'>
      )> }
    ) | (
      { __typename?: 'ChecklistAction' }
      & Pick<ChecklistAction, 'id'>
      & { idle_time?: Maybe<(
        { __typename?: 'Delay' }
        & Pick<Delay, 'unit' | 'amount'>
      )> }
    ) | (
      { __typename?: 'ClinicalNoteAction' }
      & Pick<ClinicalNoteAction, 'id'>
      & { idle_time?: Maybe<(
        { __typename?: 'Delay' }
        & Pick<Delay, 'unit' | 'amount'>
      )> }
    ) | (
      { __typename?: 'ExtensionAction' }
      & Pick<ExtensionAction, 'id'>
      & { idle_time?: Maybe<(
        { __typename?: 'Delay' }
        & Pick<Delay, 'unit' | 'amount'>
      )> }
    ) | (
      { __typename?: 'FormAction' }
      & Pick<FormAction, 'id'>
      & { idle_time?: Maybe<(
        { __typename?: 'Delay' }
        & Pick<Delay, 'unit' | 'amount'>
      )> }
    ) | (
      { __typename?: 'MessageAction' }
      & Pick<MessageAction, 'id'>
      & { idle_time?: Maybe<(
        { __typename?: 'Delay' }
        & Pick<Delay, 'unit' | 'amount'>
      )> }
    ) | (
      { __typename?: 'PushToEmrAction' }
      & Pick<PushToEmrAction, 'id'>
      & { idle_time?: Maybe<(
        { __typename?: 'Delay' }
        & Pick<Delay, 'unit' | 'amount'>
      )> }
    ) }
  ) }
);

export type SetActionIdleTimeMutationVariables = Exact<{
  input: SetActionIdleTimeInput;
}>;


export type SetActionIdleTimeMutation = (
  { __typename?: 'Mutation' }
  & { setActionIdleTime: (
    { __typename?: 'SetActionIdleTimePayload' }
    & { action: (
      { __typename?: 'ApiCallAction' }
      & Pick<ApiCallAction, 'id'>
      & { idle_time?: Maybe<(
        { __typename?: 'Delay' }
        & Pick<Delay, 'unit' | 'amount'>
      )> }
    ) | (
      { __typename?: 'ApiCallGraphqlAction' }
      & Pick<ApiCallGraphqlAction, 'id'>
      & { idle_time?: Maybe<(
        { __typename?: 'Delay' }
        & Pick<Delay, 'unit' | 'amount'>
      )> }
    ) | (
      { __typename?: 'CalculationAction' }
      & Pick<CalculationAction, 'id'>
      & { idle_time?: Maybe<(
        { __typename?: 'Delay' }
        & Pick<Delay, 'unit' | 'amount'>
      )> }
    ) | (
      { __typename?: 'ChecklistAction' }
      & Pick<ChecklistAction, 'id'>
      & { idle_time?: Maybe<(
        { __typename?: 'Delay' }
        & Pick<Delay, 'unit' | 'amount'>
      )> }
    ) | (
      { __typename?: 'ClinicalNoteAction' }
      & Pick<ClinicalNoteAction, 'id'>
      & { idle_time?: Maybe<(
        { __typename?: 'Delay' }
        & Pick<Delay, 'unit' | 'amount'>
      )> }
    ) | (
      { __typename?: 'ExtensionAction' }
      & Pick<ExtensionAction, 'id'>
      & { idle_time?: Maybe<(
        { __typename?: 'Delay' }
        & Pick<Delay, 'unit' | 'amount'>
      )> }
    ) | (
      { __typename?: 'FormAction' }
      & Pick<FormAction, 'id'>
      & { idle_time?: Maybe<(
        { __typename?: 'Delay' }
        & Pick<Delay, 'unit' | 'amount'>
      )> }
    ) | (
      { __typename?: 'MessageAction' }
      & Pick<MessageAction, 'id'>
      & { idle_time?: Maybe<(
        { __typename?: 'Delay' }
        & Pick<Delay, 'unit' | 'amount'>
      )> }
    ) | (
      { __typename?: 'PushToEmrAction' }
      & Pick<PushToEmrAction, 'id'>
      & { idle_time?: Maybe<(
        { __typename?: 'Delay' }
        & Pick<Delay, 'unit' | 'amount'>
      )> }
    ) }
  ) }
);

export type ActivateTriggerMutationVariables = Exact<{
  input: ActivateTriggerInput;
}>;


export type ActivateTriggerMutation = (
  { __typename?: 'Mutation' }
  & { activateTrigger: (
    { __typename?: 'ActivateTriggerPayload' }
    & Pick<ActivateTriggerPayload, 'evaluated_rule_id' | 'activated' | 'success' | 'message'>
  ) }
);

export type AddDataPointDefinitionToPathwayMutationVariables = Exact<{
  input: AddDataPointDefinitionToPathwayInput;
}>;


export type AddDataPointDefinitionToPathwayMutation = (
  { __typename?: 'Mutation' }
  & { addDataPointDefinitionToPathway: (
    { __typename?: 'AddDataPointDefinitionToPathwayPayload' }
    & Pick<AddDataPointDefinitionToPathwayPayload, 'success'>
    & { data_point_definition: (
      { __typename?: 'DataPointDefinition' }
      & DataPointDefinitionFragment
    ) }
  ) }
);

export type AddStepToLibraryMutationVariables = Exact<{
  input: AddStepToLibraryInput;
}>;


export type AddStepToLibraryMutation = (
  { __typename?: 'Mutation' }
  & { addStepToLibrary: (
    { __typename?: 'AddStepToLibraryPayload' }
    & Pick<AddStepToLibraryPayload, 'success'>
    & { step: (
      { __typename?: 'Step' }
      & Pick<Step, 'id' | 'title'>
    ) }
  ) }
);

export type AddWebhookMutationVariables = Exact<{
  input: AddWebhookInput;
}>;


export type AddWebhookMutation = (
  { __typename?: 'Mutation' }
  & { addWebhook: (
    { __typename?: 'AddWebhookPayload' }
    & { webhook: (
      { __typename?: 'Webhook' }
      & Pick<Webhook, 'id'>
    ) }
  ) }
);

export type AddApiCallMappingMutationVariables = Exact<{
  input: AddMappingToApiCallInput;
}>;


export type AddApiCallMappingMutation = (
  { __typename?: 'Mutation' }
  & { addMappingToApiCall: (
    { __typename?: 'AddMappingToApiCallPayload' }
    & { api_call: (
      { __typename?: 'ApiCall' }
      & Pick<ApiCall, 'id'>
      & { mappings: Array<(
        { __typename?: 'ApiCallMapping' }
        & Pick<ApiCallMapping, 'data_point_definition_id' | 'response_key' | 'first_match_only' | 'id'>
      )> }
    ) }
  ) }
);

export type ApiCallFragment = (
  { __typename?: 'ApiCall' }
  & Pick<ApiCall, 'id' | 'endpoint' | 'method'>
  & { headers: Array<(
    { __typename?: 'ApiCallHeader' }
    & Pick<ApiCallHeader, 'key' | 'value'>
  )>, body?: Maybe<(
    { __typename?: 'MessageBodyTemplate' }
    & Pick<MessageBodyTemplate, 'slate' | 'plaintext' | 'html'>
  )>, mappings: Array<(
    { __typename?: 'ApiCallMapping' }
    & Pick<ApiCallMapping, 'data_point_definition_id' | 'response_key' | 'first_match_only' | 'id'>
  )> }
);

export type RemoveMappingFromApiCallMutationVariables = Exact<{
  input: RemoveMappingFromApiCallInput;
}>;


export type RemoveMappingFromApiCallMutation = (
  { __typename?: 'Mutation' }
  & { removeMappingFromApiCall: (
    { __typename?: 'RemoveMappingFromApiCallPayload' }
    & { api_call: (
      { __typename?: 'ApiCall' }
      & Pick<ApiCall, 'id' | 'method'>
      & { mappings: Array<(
        { __typename?: 'ApiCallMapping' }
        & Pick<ApiCallMapping, 'data_point_definition_id' | 'response_key' | 'first_match_only' | 'id'>
      )> }
    ) }
  ) }
);

export type SetApiCallBodyMutationVariables = Exact<{
  input: SetApiCallBodyInput;
}>;


export type SetApiCallBodyMutation = (
  { __typename?: 'Mutation' }
  & { setApiCallBody: (
    { __typename?: 'SetApiCallBodyPayload' }
    & { api_call: (
      { __typename?: 'ApiCall' }
      & ApiCallFragment
    ) }
  ) }
);

export type SetApiCallEndpointMutationVariables = Exact<{
  input: SetApiCallEndpointInput;
}>;


export type SetApiCallEndpointMutation = (
  { __typename?: 'Mutation' }
  & { setApiCallEndpoint: (
    { __typename?: 'SetApiCallEndpointPayload' }
    & { api_call: (
      { __typename?: 'ApiCall' }
      & ApiCallFragment
    ) }
  ) }
);

export type SetApiCallHeadersMutationVariables = Exact<{
  input: SetApiCallHeadersInput;
}>;


export type SetApiCallHeadersMutation = (
  { __typename?: 'Mutation' }
  & { setApiCallHeaders: (
    { __typename?: 'SetApiCallHeadersPayload' }
    & { api_call: (
      { __typename?: 'ApiCall' }
      & ApiCallFragment
    ) }
  ) }
);

export type SetApiCallMethodMutationVariables = Exact<{
  input: SetApiCallMethodInput;
}>;


export type SetApiCallMethodMutation = (
  { __typename?: 'Mutation' }
  & { setApiCallMethod: (
    { __typename?: 'SetApiCallMethodPayload' }
    & { api_call: (
      { __typename?: 'ApiCall' }
      & Pick<ApiCall, 'id' | 'method'>
    ) }
  ) }
);

export type UpdateApiCallMappingDataPointMutationVariables = Exact<{
  input: UpdateApiCallMappingDataPointInput;
}>;


export type UpdateApiCallMappingDataPointMutation = (
  { __typename?: 'Mutation' }
  & { updateApiCallMappingDataPoint: (
    { __typename?: 'UpdateApiCallMappingDataPointPayload' }
    & { api_call: (
      { __typename?: 'ApiCall' }
      & Pick<ApiCall, 'id' | 'method'>
      & { mappings: Array<(
        { __typename?: 'ApiCallMapping' }
        & Pick<ApiCallMapping, 'data_point_definition_id' | 'response_key' | 'first_match_only' | 'id'>
      )> }
    ) }
  ) }
);

export type UpdateApiCallMappingFirstMatchOnlyMutationVariables = Exact<{
  input: UpdateApiCallMappingFirstMatchOnlyInput;
}>;


export type UpdateApiCallMappingFirstMatchOnlyMutation = (
  { __typename?: 'Mutation' }
  & { updateApiCallMappingFirstMatchOnly: (
    { __typename?: 'UpdateApiCallMappingFirstMatchOnlyPayload' }
    & { api_call: (
      { __typename?: 'ApiCall' }
      & Pick<ApiCall, 'id' | 'method'>
      & { mappings: Array<(
        { __typename?: 'ApiCallMapping' }
        & Pick<ApiCallMapping, 'data_point_definition_id' | 'response_key' | 'first_match_only' | 'id'>
      )> }
    ) }
  ) }
);

export type UpdateApiCallMappingKeyMutationVariables = Exact<{
  input: UpdateApiCallMappingKeyInput;
}>;


export type UpdateApiCallMappingKeyMutation = (
  { __typename?: 'Mutation' }
  & { updateApiCallMappingKey: (
    { __typename?: 'UpdateApiCallMappingKeyPayload' }
    & { api_call: (
      { __typename?: 'ApiCall' }
      & Pick<ApiCall, 'id' | 'method'>
      & { mappings: Array<(
        { __typename?: 'ApiCallMapping' }
        & Pick<ApiCallMapping, 'data_point_definition_id' | 'response_key' | 'first_match_only' | 'id'>
      )> }
    ) }
  ) }
);

export type DataPointDefinitionFragment = (
  { __typename?: 'DataPointDefinition' }
  & Pick<DataPointDefinition, 'id' | 'key' | 'optional' | 'valueType' | 'unit' | 'title' | 'category' | 'pii' | 'source_definition_id' | 'jsonPath'>
  & { possibleValues?: Maybe<Array<(
    { __typename?: 'DataPointPossibleValue' }
    & Pick<DataPointPossibleValue, 'label' | 'value'>
  )>>, range?: Maybe<(
    { __typename?: 'Range' }
    & Pick<Range, 'max' | 'min'>
  )>, data_point_metadata?: Maybe<Array<(
    { __typename?: 'DataPointMetaDataItem' }
    & Pick<DataPointMetaDataItem, 'key' | 'value'>
  )>>, jsonSchemaSource?: Maybe<(
    { __typename?: 'JsonSchemaSourceGraphqlType' }
    & Pick<JsonSchemaSourceGraphqlType, 'system' | 'qualifiedKey'>
  )> }
);

export type GetDataPointDefinitionQueryVariables = Exact<{
  dataPointDefinitionId: Scalars['String'];
}>;


export type GetDataPointDefinitionQuery = (
  { __typename?: 'Query' }
  & { data_point_definition: (
    { __typename?: 'DataPointDefinitionPayload' }
    & Pick<DataPointDefinitionPayload, 'success'>
    & { data_point_definition: (
      { __typename?: 'DataPointDefinition' }
      & DataPointDefinitionFragment
    ) }
  ) }
);

export type BaselineInfoQueryVariables = Exact<{
  pathway_case_id: Scalars['String'];
}>;


export type BaselineInfoQuery = (
  { __typename?: 'Query' }
  & { baseline_info: (
    { __typename?: 'BaselineInfoPayload' }
    & { baselineDataPoints: Array<(
      { __typename?: 'BaselineDataPoint' }
      & Pick<BaselineDataPoint, 'value'>
      & { definition: (
        { __typename?: 'DataPointDefinition' }
        & Pick<DataPointDefinition, 'id' | 'title' | 'category' | 'key' | 'valueType' | 'unit' | 'jsonPath'>
        & { possibleValues?: Maybe<Array<(
          { __typename?: 'DataPointPossibleValue' }
          & Pick<DataPointPossibleValue, 'label' | 'value'>
        )>>, jsonSchemaSource?: Maybe<(
          { __typename?: 'JsonSchemaSourceGraphqlType' }
          & Pick<JsonSchemaSourceGraphqlType, 'system' | 'qualifiedKey'>
        )>, range?: Maybe<(
          { __typename?: 'Range' }
          & Pick<Range, 'min' | 'max'>
        )> }
      ) }
    )> }
  ) }
);

export type GetActionCalculationQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetActionCalculationQuery = (
  { __typename?: 'Query' }
  & { action: (
    { __typename?: 'ActionPayload' }
    & { action: (
      { __typename?: 'ApiCallAction' }
      & Action_ApiCallAction_Fragment
    ) | (
      { __typename?: 'ApiCallGraphqlAction' }
      & Action_ApiCallGraphqlAction_Fragment
    ) | (
      { __typename?: 'CalculationAction' }
      & Action_CalculationAction_Fragment
    ) | (
      { __typename?: 'ChecklistAction' }
      & Action_ChecklistAction_Fragment
    ) | (
      { __typename?: 'ClinicalNoteAction' }
      & Action_ClinicalNoteAction_Fragment
    ) | (
      { __typename?: 'ExtensionAction' }
      & Action_ExtensionAction_Fragment
    ) | (
      { __typename?: 'FormAction' }
      & Action_FormAction_Fragment
    ) | (
      { __typename?: 'MessageAction' }
      & Action_MessageAction_Fragment
    ) | (
      { __typename?: 'PushToEmrAction' }
      & Action_PushToEmrAction_Fragment
    ) }
  ) }
);

export type GetCalculationResultsQueryVariables = Exact<{
  input: CalculationResultInput;
}>;


export type GetCalculationResultsQuery = (
  { __typename?: 'Query' }
  & { calculation_results: (
    { __typename?: 'CalculationResultPayload' }
    & { result: Array<(
      { __typename?: 'CalculationSingleResult' }
      & Pick<CalculationSingleResult, 'subresult_id' | 'value' | 'value_type' | 'unit' | 'status'>
    )> }
  ) }
);

export type GetCareflowComponentsQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetCareflowComponentsQuery = (
  { __typename?: 'Query' }
  & { careflow_components: (
    { __typename?: 'CareflowComponentsPayload' }
    & Pick<CareflowComponentsPayload, 'json'>
  ) }
);

export type SubmitChecklistMutationVariables = Exact<{
  input: SubmitChecklistInput;
}>;


export type SubmitChecklistMutation = (
  { __typename?: 'Mutation' }
  & { submitChecklist: (
    { __typename?: 'SubmitChecklistPayload' }
    & { activity: (
      { __typename?: 'Activity' }
      & ActivityFragment
    ) }
  ) }
);

export type AddNarrativeToClinicalNoteMutationVariables = Exact<{
  input: AddNarrativeToClinicalNoteInput;
}>;


export type AddNarrativeToClinicalNoteMutation = (
  { __typename?: 'Mutation' }
  & { addNarrativeToClinicalNote: (
    { __typename?: 'ClinicalNotePayload' }
    & { clinical_note: (
      { __typename?: 'ClinicalNote' }
      & ClinicalNoteFragment
    ) }
  ) }
);

export type ClinicalNoteFragment = (
  { __typename?: 'ClinicalNote' }
  & Pick<ClinicalNote, 'id'>
  & { narratives: Array<(
    { __typename?: 'ClinicalNoteNarrative' }
    & Pick<ClinicalNoteNarrative, 'id' | 'key' | 'title' | 'body' | 'html_body'>
  )>, context: Array<(
    { __typename?: 'ClinicalNoteContextField' }
    & Pick<ClinicalNoteContextField, 'key' | 'value'>
  )> }
);

export type GeneratedClinicalNoteFragment = (
  { __typename?: 'PreviewClinicalNote' }
  & Pick<PreviewClinicalNote, 'id'>
  & { narratives: Array<(
    { __typename?: 'GeneratedClinicalNoteNarrative' }
    & Pick<GeneratedClinicalNoteNarrative, 'id' | 'key' | 'title' | 'body'>
  )>, context: Array<(
    { __typename?: 'GeneratedClinicalNoteContextField' }
    & Pick<GeneratedClinicalNoteContextField, 'key' | 'value'>
  )> }
);

export type GetClinicalNoteQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetClinicalNoteQuery = (
  { __typename?: 'Query' }
  & { clinical_note: (
    { __typename?: 'ClinicalNotePayload' }
    & { clinical_note: (
      { __typename?: 'ClinicalNote' }
      & ClinicalNoteFragment
    ) }
  ) }
);

export type GetGeneratedClinicalNoteQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetGeneratedClinicalNoteQuery = (
  { __typename?: 'Query' }
  & { generated_clinical_note: (
    { __typename?: 'GeneratedClinicalNotePayload' }
    & { clinical_note: (
      { __typename?: 'PreviewClinicalNote' }
      & GeneratedClinicalNoteFragment
    ) }
  ) }
);

export type RemoveClinicalNoteNarrativeMutationVariables = Exact<{
  input: RemoveClinicalNoteNarrativeInput;
}>;


export type RemoveClinicalNoteNarrativeMutation = (
  { __typename?: 'Mutation' }
  & { removeClinicalNoteNarrative: (
    { __typename?: 'RemoveClinicalNoteNarrativePayload' }
    & { clinical_note: (
      { __typename?: 'ClinicalNote' }
      & ClinicalNoteFragment
    ) }
  ) }
);

export type SetClinicalNoteNarrativeTitleMutationVariables = Exact<{
  input: SetClinicalNoteNarrativeTitleInput;
}>;


export type SetClinicalNoteNarrativeTitleMutation = (
  { __typename?: 'Mutation' }
  & { setClinicalNoteNarrativeTitle: (
    { __typename?: 'ClinicalNotePayload' }
    & { clinical_note: (
      { __typename?: 'ClinicalNote' }
      & ClinicalNoteFragment
    ) }
  ) }
);

export type UpdateClinicalNoteContextMutationVariables = Exact<{
  input: UpdateClinicalNoteContextInput;
}>;


export type UpdateClinicalNoteContextMutation = (
  { __typename?: 'Mutation' }
  & { updateClinicalNoteContext: (
    { __typename?: 'UpdateClinicalNoteContextPayload' }
    & { clinical_note: (
      { __typename?: 'ClinicalNote' }
      & ClinicalNoteFragment
    ) }
  ) }
);

export type UpdateClinicalNoteNarrativeBodyMutationVariables = Exact<{
  input: UpdateClinicalNoteNarrativeBodyInput;
}>;


export type UpdateClinicalNoteNarrativeBodyMutation = (
  { __typename?: 'Mutation' }
  & { updateClinicalNoteNarrativeBody: (
    { __typename?: 'ClinicalNotePayload' }
    & { clinical_note: (
      { __typename?: 'ClinicalNote' }
      & ClinicalNoteFragment
    ) }
  ) }
);

export type CodeComparisonQueryVariables = Exact<{
  pathwayId: Scalars['String'];
}>;


export type CodeComparisonQuery = (
  { __typename?: 'Query' }
  & { pathwayDiffs: (
    { __typename?: 'PathwayDiffPayload' }
    & { diff: (
      { __typename?: 'PathwayDiff' }
      & Pick<PathwayDiff, 'newPathway' | 'oldPathway'>
    ) }
  ) }
);

export type CompareVersionsQueryVariables = Exact<{
  pathwayId: Scalars['String'];
}>;


export type CompareVersionsQuery = (
  { __typename?: 'Query' }
  & { pathwayDiffTree: (
    { __typename?: 'PathwayDiffPayload' }
    & { diff: (
      { __typename?: 'PathwayDiff' }
      & Pick<PathwayDiff, 'newPathway' | 'oldPathway'>
    ) }
  ) }
);

export type AddStepFromTemplateMutationVariables = Exact<{
  input: AddStepFromTemplateInput;
}>;


export type AddStepFromTemplateMutation = (
  { __typename?: 'Mutation' }
  & { addStepFromTemplate: (
    { __typename?: 'StepPayload' }
    & Pick<StepPayload, 'success' | 'code'>
    & { step: (
      { __typename?: 'Step' }
      & Pick<Step, 'id' | 'title'>
      & { coordinates: (
        { __typename?: 'Coordinates' }
        & Pick<Coordinates, 'x' | 'y'>
      ), actions?: Maybe<Array<(
        { __typename?: 'ApiCallAction' }
        & Pick<ApiCallAction, 'id' | 'title' | 'type'>
      ) | (
        { __typename?: 'ApiCallGraphqlAction' }
        & Pick<ApiCallGraphqlAction, 'id' | 'title' | 'type'>
      ) | (
        { __typename?: 'CalculationAction' }
        & Pick<CalculationAction, 'id' | 'title' | 'type'>
      ) | (
        { __typename?: 'ChecklistAction' }
        & Pick<ChecklistAction, 'id' | 'title' | 'type'>
      ) | (
        { __typename?: 'ClinicalNoteAction' }
        & Pick<ClinicalNoteAction, 'id' | 'title' | 'type'>
      ) | (
        { __typename?: 'ExtensionAction' }
        & Pick<ExtensionAction, 'id' | 'title' | 'type'>
      ) | (
        { __typename?: 'FormAction' }
        & Pick<FormAction, 'id' | 'title' | 'type'>
      ) | (
        { __typename?: 'MessageAction' }
        & Pick<MessageAction, 'id' | 'title' | 'type'>
      ) | (
        { __typename?: 'PushToEmrAction' }
        & Pick<PushToEmrAction, 'id' | 'title' | 'type'>
      )>> }
    ) }
  ) }
);

export type ConstantFragment = (
  { __typename?: 'Constant' }
  & Pick<Constant, 'id' | 'label' | 'value' | 'obfuscated'>
);

export type GetConstantQueryVariables = Exact<{
  input: ConstantInput;
}>;


export type GetConstantQuery = (
  { __typename?: 'Query' }
  & { constant: (
    { __typename?: 'ConstantPayload' }
    & { constant?: Maybe<(
      { __typename?: 'Constant' }
      & ConstantFragment
    )> }
  ) }
);

export type GetConstantsQueryVariables = Exact<{
  pathway_id: Scalars['String'];
}>;


export type GetConstantsQuery = (
  { __typename?: 'Query' }
  & { pathway: (
    { __typename?: 'PathwayPayload' }
    & { pathway: (
      { __typename?: 'Pathway' }
      & Pick<Pathway, 'id'>
      & { settings?: Maybe<(
        { __typename?: 'PathwaySettings' }
        & { constants?: Maybe<Array<(
          { __typename?: 'Constant' }
          & ConstantFragment
        )>> }
      )> }
    ) }
  ) }
);

export type CreateConstantMutationVariables = Exact<{
  input: AddConstantInput;
}>;


export type CreateConstantMutation = (
  { __typename?: 'Mutation' }
  & { addConstant: (
    { __typename?: 'AddConstantPayload' }
    & Pick<AddConstantPayload, 'success'>
    & { pathway: (
      { __typename?: 'Pathway' }
      & Pick<Pathway, 'id'>
      & { settings?: Maybe<(
        { __typename?: 'PathwaySettings' }
        & { constants?: Maybe<Array<(
          { __typename?: 'Constant' }
          & ConstantFragment
        )>> }
      )> }
    ) }
  ) }
);

export type CreateDynamicVariableMutationVariables = Exact<{
  input: CreateDynamicVariableInput;
}>;


export type CreateDynamicVariableMutation = (
  { __typename?: 'Mutation' }
  & { createDynamicVariable: (
    { __typename?: 'CreateDynamicVariablePayload' }
    & Pick<CreateDynamicVariablePayload, 'success'>
    & { dynamic_variable: (
      { __typename?: 'DynamicVariable' }
      & Pick<DynamicVariable, 'data_point_property' | 'fallback' | 'id' | 'label'>
      & { data_point_definition?: Maybe<(
        { __typename?: 'DataPointDefinition' }
        & Pick<DataPointDefinition, 'valueType'>
      )>, status: (
        { __typename?: 'ConfigurationStatus' }
        & Pick<ConfigurationStatus, 'status' | 'errors'>
      ), transformations?: Maybe<Array<(
        { __typename?: 'AddDurationTransformation' }
        & Pick<AddDurationTransformation, 'type'>
      ) | (
        { __typename?: 'AddPrefixTransformation' }
        & Pick<AddPrefixTransformation, 'type'>
      ) | (
        { __typename?: 'AddSuffixTransformation' }
        & Pick<AddSuffixTransformation, 'type'>
      ) | (
        { __typename?: 'AddWeekdaysTransformation' }
        & Pick<AddWeekdaysTransformation, 'type'>
      ) | (
        { __typename?: 'FormatDateTransformation' }
        & Pick<FormatDateTransformation, 'type'>
      ) | (
        { __typename?: 'FormatPhoneNumberTransformation' }
        & Pick<FormatPhoneNumberTransformation, 'type'>
      ) | (
        { __typename?: 'RoundToTransformation' }
        & Pick<RoundToTransformation, 'type'>
      ) | (
        { __typename?: 'SubtractDurationTransformation' }
        & Pick<SubtractDurationTransformation, 'type'>
      )>> }
    ) }
  ) }
);

export type GetCustomActionsQueryVariables = Exact<{
  pathwayId: Scalars['String'];
}>;


export type GetCustomActionsQuery = (
  { __typename?: 'Query' }
  & { pathway: (
    { __typename?: 'PathwayPayload' }
    & { pathway: (
      { __typename?: 'Pathway' }
      & Pick<Pathway, 'id'>
      & { custom_actions?: Maybe<Array<(
        { __typename?: 'CustomAction' }
        & Pick<CustomAction, 'id' | 'name' | 'type' | 'category'>
        & { fields: Array<(
          { __typename?: 'CustomActionField' }
          & Pick<CustomActionField, 'id' | 'name' | 'type' | 'label' | 'description' | 'value' | 'readonly' | 'mandatory'>
          & { options?: Maybe<Array<(
            { __typename?: 'CustomActionFieldOption' }
            & Pick<CustomActionFieldOption, 'label' | 'value'>
          )>> }
        )> }
      )>> }
    ) }
  ) }
);

export type DataCatalogQueryVariables = Exact<{
  input: DataCatalogInput;
}>;


export type DataCatalogQuery = (
  { __typename?: 'Query' }
  & { data_catalog: (
    { __typename?: 'DataCatalogPayload' }
    & { items: Array<(
      { __typename?: 'DataCatalogItem' }
      & Pick<DataCatalogItem, 'id' | 'pii' | 'title' | 'category' | 'key' | 'valueType' | 'unit' | 'jsonPath'>
      & { data_point_metadata?: Maybe<Array<(
        { __typename?: 'DataPointMetaDataItem' }
        & Pick<DataPointMetaDataItem, 'key' | 'value'>
      )>>, possibleValues?: Maybe<Array<(
        { __typename?: 'DataPointPossibleValue' }
        & Pick<DataPointPossibleValue, 'label' | 'value'>
      )>>, range?: Maybe<(
        { __typename?: 'Range' }
        & Pick<Range, 'min' | 'max'>
      )>, jsonSchemaSource?: Maybe<(
        { __typename?: 'JsonSchemaSourceGraphqlType' }
        & Pick<JsonSchemaSourceGraphqlType, 'system' | 'qualifiedKey'>
      )>, collection_contexts: Array<(
        { __typename?: 'CollectionContext' }
        & { track?: Maybe<(
          { __typename?: 'CollectionContextItem' }
          & Pick<CollectionContextItem, 'id' | 'title'>
        )>, step?: Maybe<(
          { __typename?: 'CollectionContextItem' }
          & Pick<CollectionContextItem, 'id' | 'title'>
        )>, action?: Maybe<(
          { __typename?: 'CollectionContextItem' }
          & Pick<CollectionContextItem, 'id' | 'title'>
        )>, stakeholder?: Maybe<(
          { __typename?: 'Stakeholder' }
          & Pick<Stakeholder, 'id'>
          & { label: (
            { __typename?: 'StakeholderLabel' }
            & Pick<StakeholderLabel, 'en'>
          ) }
        )> }
      )> }
    )> }
  ) }
);

export type DataCatalogJsonQueryVariables = Exact<{
  input: DataCatalogInput;
}>;


export type DataCatalogJsonQuery = (
  { __typename?: 'Query' }
  & { data_catalog_json: (
    { __typename?: 'DataCatalogJsonPayload' }
    & Pick<DataCatalogJsonPayload, 'json'>
  ) }
);

export type GetPathwayTitlesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPathwayTitlesQuery = (
  { __typename?: 'Query' }
  & { pathways: (
    { __typename?: 'PathwaysPayload' }
    & { pathways: Array<(
      { __typename?: 'Pathway' }
      & Pick<Pathway, 'id' | 'title'>
    )> }
  ) }
);

export type SetDataPointMetadataMutationVariables = Exact<{
  input: SetDataPointDefinitionMetaDataFieldInput;
}>;


export type SetDataPointMetadataMutation = (
  { __typename?: 'Mutation' }
  & { setDataPointDefinitionMetaDataField: (
    { __typename?: 'SetDataPointDefinitionMetaDataFieldPayload' }
    & Pick<SetDataPointDefinitionMetaDataFieldPayload, 'success'>
    & { data_point_definition: (
      { __typename?: 'DataPointDefinition' }
      & Pick<DataPointDefinition, 'id'>
      & { data_point_metadata?: Maybe<Array<(
        { __typename?: 'DataPointMetaDataItem' }
        & Pick<DataPointMetaDataItem, 'key' | 'value'>
      )>> }
    ) }
  ) }
);

export type SetDataPointPiiMutationVariables = Exact<{
  input: SetDataPointDefinitionPiiFieldInput;
}>;


export type SetDataPointPiiMutation = (
  { __typename?: 'Mutation' }
  & { setDataPointDefinitionPiiField: (
    { __typename?: 'SetDataPointDefinitionPiiFieldPayload' }
    & Pick<SetDataPointDefinitionPiiFieldPayload, 'success'>
    & { data_point_definition: (
      { __typename?: 'DataPointDefinition' }
      & DataPointDefinitionFragment
    ) }
  ) }
);

export type RemoveStepFromTrackMutationVariables = Exact<{
  input: RemoveStepFromTrackInput;
}>;


export type RemoveStepFromTrackMutation = (
  { __typename?: 'Mutation' }
  & { removeStepFromTrack: (
    { __typename?: 'RemoveStepFromTrackPayload' }
    & Pick<RemoveStepFromTrackPayload, 'success'>
    & { track: (
      { __typename?: 'Track' }
      & Pick<Track, 'id'>
      & { steps: Array<(
        { __typename?: 'Step' }
        & Pick<Step, 'id'>
      )> }
    ) }
  ) }
);

export type RemoveStickyNoteFromTrackMutationVariables = Exact<{
  input: RemoveStickyNoteFromTrackInput;
}>;


export type RemoveStickyNoteFromTrackMutation = (
  { __typename?: 'Mutation' }
  & { removeStickyNoteFromTrack: (
    { __typename?: 'EmptyPayload' }
    & Pick<EmptyPayload, 'success'>
  ) }
);

export type RemoveTransitionMutationVariables = Exact<{
  input: RemoveTransitionInput;
}>;


export type RemoveTransitionMutation = (
  { __typename?: 'Mutation' }
  & { removeTransition: (
    { __typename?: 'RemoveTransitionPayload' }
    & Pick<RemoveTransitionPayload, 'success'>
  ) }
);

export type DuplicateStepMutationVariables = Exact<{
  input: DuplicateStepInput;
}>;


export type DuplicateStepMutation = (
  { __typename?: 'Mutation' }
  & { duplicateStep: (
    { __typename?: 'DuplicateStepPayload' }
    & { step: (
      { __typename?: 'Step' }
      & CanvasStepFragment
    ) }
  ) }
);

export type AddTransformationToDynamicVariableMutationVariables = Exact<{
  input: AddTransformationToDynamicVariableInput;
}>;


export type AddTransformationToDynamicVariableMutation = (
  { __typename?: 'Mutation' }
  & { addTransformationToDynamicVariable: (
    { __typename?: 'AddTransformationToDynamicVariablePayload' }
    & { dynamic_variable: (
      { __typename?: 'DynamicVariable' }
      & Pick<DynamicVariable, 'id'>
      & { transformations?: Maybe<Array<(
        { __typename?: 'AddDurationTransformation' }
        & Transformation_AddDurationTransformation_Fragment
      ) | (
        { __typename?: 'AddPrefixTransformation' }
        & Transformation_AddPrefixTransformation_Fragment
      ) | (
        { __typename?: 'AddSuffixTransformation' }
        & Transformation_AddSuffixTransformation_Fragment
      ) | (
        { __typename?: 'AddWeekdaysTransformation' }
        & Transformation_AddWeekdaysTransformation_Fragment
      ) | (
        { __typename?: 'FormatDateTransformation' }
        & Transformation_FormatDateTransformation_Fragment
      ) | (
        { __typename?: 'FormatPhoneNumberTransformation' }
        & Transformation_FormatPhoneNumberTransformation_Fragment
      ) | (
        { __typename?: 'RoundToTransformation' }
        & Transformation_RoundToTransformation_Fragment
      ) | (
        { __typename?: 'SubtractDurationTransformation' }
        & Transformation_SubtractDurationTransformation_Fragment
      )>> }
    ) }
  ) }
);

export type DynamicVariableFragment = (
  { __typename?: 'DynamicVariable' }
  & Pick<DynamicVariable, 'id' | 'fallback' | 'data_point_property' | 'label'>
  & { status: (
    { __typename?: 'ConfigurationStatus' }
    & Pick<ConfigurationStatus, 'errors' | 'status'>
  ), data_point_definition?: Maybe<(
    { __typename?: 'DataPointDefinition' }
    & Pick<DataPointDefinition, 'id' | 'category' | 'key' | 'title' | 'valueType'>
    & { possibleValues?: Maybe<Array<(
      { __typename?: 'DataPointPossibleValue' }
      & Pick<DataPointPossibleValue, 'label' | 'value'>
    )>> }
  )>, transformations?: Maybe<Array<(
    { __typename?: 'AddDurationTransformation' }
    & Transformation_AddDurationTransformation_Fragment
  ) | (
    { __typename?: 'AddPrefixTransformation' }
    & Transformation_AddPrefixTransformation_Fragment
  ) | (
    { __typename?: 'AddSuffixTransformation' }
    & Transformation_AddSuffixTransformation_Fragment
  ) | (
    { __typename?: 'AddWeekdaysTransformation' }
    & Transformation_AddWeekdaysTransformation_Fragment
  ) | (
    { __typename?: 'FormatDateTransformation' }
    & Transformation_FormatDateTransformation_Fragment
  ) | (
    { __typename?: 'FormatPhoneNumberTransformation' }
    & Transformation_FormatPhoneNumberTransformation_Fragment
  ) | (
    { __typename?: 'RoundToTransformation' }
    & Transformation_RoundToTransformation_Fragment
  ) | (
    { __typename?: 'SubtractDurationTransformation' }
    & Transformation_SubtractDurationTransformation_Fragment
  )>> }
);

export type GetDynamicVariableQueryVariables = Exact<{
  dynamicVariableId: Scalars['String'];
}>;


export type GetDynamicVariableQuery = (
  { __typename?: 'Query' }
  & { dynamic_variable: (
    { __typename?: 'DynamicVariablePayload' }
    & { dynamic_variable: (
      { __typename?: 'DynamicVariable' }
      & DynamicVariableFragment
    ) }
  ) }
);

export type GetDynamicVariablesQueryVariables = Exact<{
  pathwayId: Scalars['String'];
}>;


export type GetDynamicVariablesQuery = (
  { __typename?: 'Query' }
  & { dynamic_variables: (
    { __typename?: 'DynamicVariablesPayload' }
    & { dynamic_variables: Array<(
      { __typename?: 'DynamicVariable' }
      & DynamicVariableFragment
    )> }
  ) }
);

export type RemoveTransformationsFromDynamicVariableMutationVariables = Exact<{
  input: RemoveTransformationsFromDynamicVariableInput;
}>;


export type RemoveTransformationsFromDynamicVariableMutation = (
  { __typename?: 'Mutation' }
  & { removeTransformationsFromDynamicVariable: (
    { __typename?: 'RemoveTransformationsFromDynamicVariablePayload' }
    & { dynamic_variable: (
      { __typename?: 'DynamicVariable' }
      & Pick<DynamicVariable, 'id'>
      & { transformations?: Maybe<Array<(
        { __typename?: 'AddDurationTransformation' }
        & Transformation_AddDurationTransformation_Fragment
      ) | (
        { __typename?: 'AddPrefixTransformation' }
        & Transformation_AddPrefixTransformation_Fragment
      ) | (
        { __typename?: 'AddSuffixTransformation' }
        & Transformation_AddSuffixTransformation_Fragment
      ) | (
        { __typename?: 'AddWeekdaysTransformation' }
        & Transformation_AddWeekdaysTransformation_Fragment
      ) | (
        { __typename?: 'FormatDateTransformation' }
        & Transformation_FormatDateTransformation_Fragment
      ) | (
        { __typename?: 'FormatPhoneNumberTransformation' }
        & Transformation_FormatPhoneNumberTransformation_Fragment
      ) | (
        { __typename?: 'RoundToTransformation' }
        & Transformation_RoundToTransformation_Fragment
      ) | (
        { __typename?: 'SubtractDurationTransformation' }
        & Transformation_SubtractDurationTransformation_Fragment
      )>> }
    ) }
  ) }
);

export type SetDynamicVariableDataPointDefinitionMutationVariables = Exact<{
  input: SetDynamicVariableDataPointDefinitionInput;
}>;


export type SetDynamicVariableDataPointDefinitionMutation = (
  { __typename?: 'Mutation' }
  & { setDynamicVariableDataPointDefinition: (
    { __typename?: 'SetDynamicVariableDataPointDefinitionPayload' }
    & { dynamic_variable: (
      { __typename?: 'DynamicVariable' }
      & DynamicVariableFragment
    ) }
  ) }
);

export type SetDynamicVariableDataPointPropertyMutationVariables = Exact<{
  input: SetDynamicVariableDataPointPropertyInput;
}>;


export type SetDynamicVariableDataPointPropertyMutation = (
  { __typename?: 'Mutation' }
  & { setDynamicVariableDataPointProperty: (
    { __typename?: 'SetDynamicVariableDataPointPropertyPayload' }
    & { dynamic_variable: (
      { __typename?: 'DynamicVariable' }
      & DynamicVariableFragment
    ) }
  ) }
);

export type SetDynamicVariableFallbackMutationVariables = Exact<{
  input: SetDynamicVariableFallbackInput;
}>;


export type SetDynamicVariableFallbackMutation = (
  { __typename?: 'Mutation' }
  & { setDynamicVariableFallback: (
    { __typename?: 'SetDynamicVariableFallbackPayload' }
    & { dynamic_variable: (
      { __typename?: 'DynamicVariable' }
      & DynamicVariableFragment
    ) }
  ) }
);

export type SetDynamicVariableLabelMutationVariables = Exact<{
  input: SetDynamicVariableLabelInput;
}>;


export type SetDynamicVariableLabelMutation = (
  { __typename?: 'Mutation' }
  & { setDynamicVariableLabel: (
    { __typename?: 'SetDynamicVariableLabelPayload' }
    & { dynamic_variable: (
      { __typename?: 'DynamicVariable' }
      & DynamicVariableFragment
    ) }
  ) }
);

export type SetTransformationParamsMutationVariables = Exact<{
  input: SetTransformationParamsInput;
}>;


export type SetTransformationParamsMutation = (
  { __typename?: 'Mutation' }
  & { setTransformationParams: (
    { __typename?: 'SetTransformationParamsPayload' }
    & { dynamic_variable: (
      { __typename?: 'DynamicVariable' }
      & Pick<DynamicVariable, 'id'>
      & { transformations?: Maybe<Array<(
        { __typename?: 'AddDurationTransformation' }
        & Transformation_AddDurationTransformation_Fragment
      ) | (
        { __typename?: 'AddPrefixTransformation' }
        & Transformation_AddPrefixTransformation_Fragment
      ) | (
        { __typename?: 'AddSuffixTransformation' }
        & Transformation_AddSuffixTransformation_Fragment
      ) | (
        { __typename?: 'AddWeekdaysTransformation' }
        & Transformation_AddWeekdaysTransformation_Fragment
      ) | (
        { __typename?: 'FormatDateTransformation' }
        & Transformation_FormatDateTransformation_Fragment
      ) | (
        { __typename?: 'FormatPhoneNumberTransformation' }
        & Transformation_FormatPhoneNumberTransformation_Fragment
      ) | (
        { __typename?: 'RoundToTransformation' }
        & Transformation_RoundToTransformation_Fragment
      ) | (
        { __typename?: 'SubtractDurationTransformation' }
        & Transformation_SubtractDurationTransformation_Fragment
      )>> }
    ) }
  ) }
);

export type SetTransformationTypeMutationVariables = Exact<{
  input: SetTransformationTypeInput;
}>;


export type SetTransformationTypeMutation = (
  { __typename?: 'Mutation' }
  & { setTransformationType: (
    { __typename?: 'SetTransformationTypePayload' }
    & { dynamic_variable: (
      { __typename?: 'DynamicVariable' }
      & Pick<DynamicVariable, 'id'>
      & { transformations?: Maybe<Array<(
        { __typename?: 'AddDurationTransformation' }
        & Transformation_AddDurationTransformation_Fragment
      ) | (
        { __typename?: 'AddPrefixTransformation' }
        & Transformation_AddPrefixTransformation_Fragment
      ) | (
        { __typename?: 'AddSuffixTransformation' }
        & Transformation_AddSuffixTransformation_Fragment
      ) | (
        { __typename?: 'AddWeekdaysTransformation' }
        & Transformation_AddWeekdaysTransformation_Fragment
      ) | (
        { __typename?: 'FormatDateTransformation' }
        & Transformation_FormatDateTransformation_Fragment
      ) | (
        { __typename?: 'FormatPhoneNumberTransformation' }
        & Transformation_FormatPhoneNumberTransformation_Fragment
      ) | (
        { __typename?: 'RoundToTransformation' }
        & Transformation_RoundToTransformation_Fragment
      ) | (
        { __typename?: 'SubtractDurationTransformation' }
        & Transformation_SubtractDurationTransformation_Fragment
      )>> }
    ) }
  ) }
);

type Transformation_AddDurationTransformation_Fragment = (
  { __typename?: 'AddDurationTransformation' }
  & Pick<AddDurationTransformation, 'id' | 'type'>
  & { parameters?: Maybe<(
    { __typename?: 'AddDurationParameters' }
    & { duration?: Maybe<(
      { __typename?: 'DurationGraphQlObjectType' }
      & Pick<DurationGraphQlObjectType, 'years' | 'months' | 'weeks' | 'days' | 'hours' | 'minutes'>
    )> }
  )> }
);

type Transformation_AddPrefixTransformation_Fragment = (
  { __typename?: 'AddPrefixTransformation' }
  & Pick<AddPrefixTransformation, 'id' | 'type'>
  & { parameters?: Maybe<(
    { __typename?: 'AddPrefixParameters' }
    & Pick<AddPrefixParameters, 'prefix'>
  )> }
);

type Transformation_AddSuffixTransformation_Fragment = (
  { __typename?: 'AddSuffixTransformation' }
  & Pick<AddSuffixTransformation, 'id' | 'type'>
  & { parameters?: Maybe<(
    { __typename?: 'AddSuffixParameters' }
    & Pick<AddSuffixParameters, 'suffix'>
  )> }
);

type Transformation_AddWeekdaysTransformation_Fragment = (
  { __typename?: 'AddWeekdaysTransformation' }
  & Pick<AddWeekdaysTransformation, 'id' | 'type'>
  & { parameters?: Maybe<(
    { __typename?: 'AddWeekdaysParameters' }
    & Pick<AddWeekdaysParameters, 'days'>
  )> }
);

type Transformation_FormatDateTransformation_Fragment = (
  { __typename?: 'FormatDateTransformation' }
  & Pick<FormatDateTransformation, 'id' | 'type'>
  & { parameters?: Maybe<(
    { __typename?: 'FormatDateParameters' }
    & Pick<FormatDateParameters, 'format'>
  )> }
);

type Transformation_FormatPhoneNumberTransformation_Fragment = (
  { __typename?: 'FormatPhoneNumberTransformation' }
  & Pick<FormatPhoneNumberTransformation, 'id' | 'type'>
  & { parameters?: Maybe<(
    { __typename?: 'FormatPhoneNumberParameters' }
    & Pick<FormatPhoneNumberParameters, 'format'>
  )> }
);

type Transformation_RoundToTransformation_Fragment = (
  { __typename?: 'RoundToTransformation' }
  & Pick<RoundToTransformation, 'id' | 'type'>
  & { parameters?: Maybe<(
    { __typename?: 'RoundToParameters' }
    & Pick<RoundToParameters, 'nbr_decimals'>
  )> }
);

type Transformation_SubtractDurationTransformation_Fragment = (
  { __typename?: 'SubtractDurationTransformation' }
  & Pick<SubtractDurationTransformation, 'id' | 'type'>
  & { parameters?: Maybe<(
    { __typename?: 'SubtractDurationParameters' }
    & { duration?: Maybe<(
      { __typename?: 'DurationGraphQlObjectType' }
      & Pick<DurationGraphQlObjectType, 'years' | 'months' | 'weeks' | 'days' | 'hours' | 'minutes'>
    )> }
  )> }
);

export type TransformationFragment = Transformation_AddDurationTransformation_Fragment | Transformation_AddPrefixTransformation_Fragment | Transformation_AddSuffixTransformation_Fragment | Transformation_AddWeekdaysTransformation_Fragment | Transformation_FormatDateTransformation_Fragment | Transformation_FormatPhoneNumberTransformation_Fragment | Transformation_RoundToTransformation_Fragment | Transformation_SubtractDurationTransformation_Fragment;

export type GetGeneratedEmrReportQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetGeneratedEmrReportQuery = (
  { __typename?: 'Query' }
  & { generated_emr_report: (
    { __typename?: 'GeneratedEmrReportPayload' }
    & { report: (
      { __typename?: 'PreviewEmrReport' }
      & Pick<PreviewEmrReport, 'id' | 'message_html'>
      & { metadata?: Maybe<Array<(
        { __typename?: 'EmrReportMetadataField' }
        & Pick<EmrReportMetadataField, 'id' | 'label' | 'value'>
      )>> }
    ) }
  ) }
);

export type GetEvaluatedRuleQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetEvaluatedRuleQuery = (
  { __typename?: 'Query' }
  & { evaluatedRule: (
    { __typename?: 'EvaluatedRulePayload' }
    & { evaluatedRule: (
      { __typename?: 'EvaluatedRule' }
      & Pick<EvaluatedRule, 'satisfied'>
      & { conditions: Array<(
        { __typename?: 'EvaluatedRuleCondition' }
        & Pick<EvaluatedRuleCondition, 'id' | 'satisfied' | 'operator'>
        & { reference: (
          { __typename?: 'EvaluatedRuleReference' }
          & Pick<EvaluatedRuleReference, 'key' | 'data_point_definition_id' | 'value'>
        ), operand?: Maybe<(
          { __typename?: 'Operand' }
          & Pick<Operand, 'type' | 'value'>
        )> }
      )> }
    ) }
  ) }
);

export type GetExtensionActivityRecordQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetExtensionActivityRecordQuery = (
  { __typename?: 'Query' }
  & { extensionActivityRecord: (
    { __typename?: 'ExtensionActivityRecordPayload' }
    & { record: (
      { __typename?: 'ExtensionActivityRecord' }
      & Pick<ExtensionActivityRecord, 'id' | 'activity_id' | 'pathway_id' | 'plugin_key' | 'plugin_action_key' | 'date'>
      & { fields: Array<(
        { __typename?: 'ExtensionActivityField' }
        & Pick<ExtensionActivityField, 'id' | 'type' | 'label' | 'value'>
      )>, settings?: Maybe<Array<(
        { __typename?: 'PluginActionSettingsProperty' }
        & Pick<PluginActionSettingsProperty, 'value' | 'label' | 'key'>
      )>>, data_points: Array<(
        { __typename?: 'ExtensionDataPoint' }
        & Pick<ExtensionDataPoint, 'label' | 'value'>
      )> }
    ) }
  ) }
);

export type ConfigureExtensionMutationVariables = Exact<{
  input: ConfigureExtensionInput;
}>;


export type ConfigureExtensionMutation = (
  { __typename?: 'Mutation' }
  & { configureExtension: (
    { __typename?: 'ConfigureExtensionPayload' }
    & { extension: (
      { __typename?: 'ExtensionConfiguration' }
      & ExtensionConfigurationFragment
    ) }
  ) }
);

export type ExtensionConfigurationFragment = (
  { __typename?: 'ExtensionConfiguration' }
  & Pick<ExtensionConfiguration, 'extension_key'>
  & { properties?: Maybe<Array<(
    { __typename?: 'ExtensionProperty' }
    & Pick<ExtensionProperty, 'key'>
    & { constant: (
      { __typename?: 'Constant' }
      & Pick<Constant, 'id' | 'obfuscated' | 'value' | 'label'>
    ) }
  )>> }
);

export type GetExtensionConfigurationsQueryVariables = Exact<{
  pathway_id: Scalars['String'];
}>;


export type GetExtensionConfigurationsQuery = (
  { __typename?: 'Query' }
  & { extension_configurations: (
    { __typename?: 'ExtensionConfigurationsPayload' }
    & { extensions?: Maybe<Array<(
      { __typename?: 'ExtensionConfiguration' }
      & ExtensionConfigurationFragment
    )>> }
  ) }
);

export type RemoveExtensionConfigurationMutationVariables = Exact<{
  input: RemoveExtensionConfigurationInput;
}>;


export type RemoveExtensionConfigurationMutation = (
  { __typename?: 'Mutation' }
  & { removeExtensionConfiguration: (
    { __typename?: 'EmptyPayload' }
    & Pick<EmptyPayload, 'success'>
  ) }
);

export type AddDataPointMappingToExtensionActionMutationVariables = Exact<{
  input: AddDataPointMappingToExtensionActionInput;
}>;


export type AddDataPointMappingToExtensionActionMutation = (
  { __typename?: 'Mutation' }
  & { addDataPointMappingToExtensionAction: (
    { __typename?: 'AddDataPointMappingToExtensionActionPayload' }
    & { action: (
      { __typename?: 'ExtensionAction' }
      & Pick<ExtensionAction, 'id'>
      & { extension_data_point_mappings?: Maybe<Array<(
        { __typename?: 'ExtensionDataPointMapping' }
        & Pick<ExtensionDataPointMapping, 'id' | 'data_point_definition_id' | 'extension_data_point_key'>
      )>> }
    ) }
  ) }
);

export type RemoveDataPointMappingFromExtensionActionMutationVariables = Exact<{
  input: RemoveDataPointMappingFromExtensionActionInput;
}>;


export type RemoveDataPointMappingFromExtensionActionMutation = (
  { __typename?: 'Mutation' }
  & { removeDataPointMappingFromExtensionAction: (
    { __typename?: 'RemoveDataPointMappingFromExtensionActionPayload' }
    & { action: (
      { __typename?: 'ExtensionAction' }
      & Pick<ExtensionAction, 'id'>
      & { extension_data_point_mappings?: Maybe<Array<(
        { __typename?: 'ExtensionDataPointMapping' }
        & Pick<ExtensionDataPointMapping, 'id' | 'data_point_definition_id' | 'extension_data_point_key'>
      )>> }
    ) }
  ) }
);

export type SetExtensionActionDataPointMappingDataPointMutationVariables = Exact<{
  input: SetExtensionActionDataPointMappingDataPointInput;
}>;


export type SetExtensionActionDataPointMappingDataPointMutation = (
  { __typename?: 'Mutation' }
  & { setExtensionActionDataPointMappingDataPoint: (
    { __typename?: 'SetExtensionActionDataPointMappingDataPointPayload' }
    & { action: (
      { __typename?: 'ExtensionAction' }
      & Pick<ExtensionAction, 'id'>
      & { extension_data_point_mappings?: Maybe<Array<(
        { __typename?: 'ExtensionDataPointMapping' }
        & Pick<ExtensionDataPointMapping, 'id' | 'data_point_definition_id' | 'extension_data_point_key'>
      )>> }
    ) }
  ) }
);

export type SetExtensionActionDataPointMappingKeyMutationVariables = Exact<{
  input: SetExtensionActionDataPointMappingKeyInput;
}>;


export type SetExtensionActionDataPointMappingKeyMutation = (
  { __typename?: 'Mutation' }
  & { setExtensionActionDataPointMappingKey: (
    { __typename?: 'SetExtensionActionDataPointMappingKeyPayload' }
    & { action: (
      { __typename?: 'ExtensionAction' }
      & Pick<ExtensionAction, 'id'>
      & { extension_data_point_mappings?: Maybe<Array<(
        { __typename?: 'ExtensionDataPointMapping' }
        & Pick<ExtensionDataPointMapping, 'id' | 'data_point_definition_id' | 'extension_data_point_key'>
      )>> }
    ) }
  ) }
);

export type SetExtensionActionDataPointMappingsMutationVariables = Exact<{
  input: SetExtensionActionDataPointMappingsInput;
}>;


export type SetExtensionActionDataPointMappingsMutation = (
  { __typename?: 'Mutation' }
  & { setExtensionActionDataPointMappings: (
    { __typename?: 'SetExtensionActionDataPointMappingsPayload' }
    & { action: (
      { __typename?: 'ExtensionAction' }
      & Pick<ExtensionAction, 'id'>
      & { extension_data_point_mappings?: Maybe<Array<(
        { __typename?: 'ExtensionDataPointMapping' }
        & Pick<ExtensionDataPointMapping, 'id' | 'data_point_definition_id' | 'extension_data_point_key'>
      )>> }
    ) }
  ) }
);

export type GetExtensionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetExtensionsQuery = (
  { __typename?: 'Query' }
  & { extensions: (
    { __typename?: 'ExtensionsPayload' }
    & { extensions: Array<(
      { __typename?: 'Extension' }
      & Pick<Extension, 'key' | 'title' | 'icon_url'>
      & { settings?: Maybe<(
        { __typename?: 'ExtensionSettings' }
        & Pick<ExtensionSettings, 'description'>
        & { fields: Array<(
          { __typename?: 'ExtensionSettingsField' }
          & Pick<ExtensionSettingsField, 'key' | 'label' | 'obfuscated' | 'required' | 'description'>
        )> }
      )>, actions: Array<(
        { __typename?: 'ExtensionActionBlueprint' }
        & Pick<ExtensionActionBlueprint, 'key' | 'title' | 'icon' | 'category'>
      )>, available_webhooks?: Maybe<Array<(
        { __typename?: 'AvailableWebhook' }
        & Pick<AvailableWebhook, 'key' | 'description'>
      )>> }
    )> }
  ) }
);

export type GetFormResponseQueryVariables = Exact<{
  input: FormResponseInput;
}>;


export type GetFormResponseQuery = (
  { __typename?: 'Query' }
  & { form_response: (
    { __typename?: 'FormResponsePayload' }
    & { response: (
      { __typename?: 'FormResponse' }
      & { answers: Array<(
        { __typename?: 'Answer' }
        & Pick<Answer, 'question_id' | 'value' | 'value_type'>
      )> }
    ) }
  ) }
);

export type SubmitFormResponseMutationVariables = Exact<{
  input: SubmitFormResponseInput;
}>;


export type SubmitFormResponseMutation = (
  { __typename?: 'Mutation' }
  & { submitFormResponse: (
    { __typename?: 'SubmitFormResponsePayload' }
    & { activity: (
      { __typename?: 'Activity' }
      & ActivityFragment
    ) }
  ) }
);

export type FormFragment = (
  { __typename?: 'Form' }
  & Pick<Form, 'id' | 'title' | 'key' | 'trademark'>
  & { metadata?: Maybe<(
    { __typename?: 'FormattedJson' }
    & Pick<FormattedJson, 'plaintext' | 'slate'>
  )>, questions: Array<(
    { __typename?: 'Question' }
    & QuestionFragment
  )> }
);

export type GetFormQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetFormQuery = (
  { __typename?: 'Query' }
  & { form: (
    { __typename?: 'FormPayload' }
    & { form: (
      { __typename?: 'Form' }
      & FormFragment
    ) }
  ) }
);

export type QuestionFragment = (
  { __typename?: 'Question' }
  & Pick<Question, 'id' | 'title' | 'form_id' | 'key' | 'dataPointValueType' | 'questionType' | 'userQuestionType' | 'rule_id'>
  & { options?: Maybe<Array<(
    { __typename?: 'Option' }
    & Pick<Option, 'id' | 'value' | 'label'>
  )>>, questionConfig?: Maybe<(
    { __typename?: 'QuestionConfig' }
    & Pick<QuestionConfig, 'recode_enabled' | 'use_select' | 'mandatory'>
    & { slider?: Maybe<(
      { __typename?: 'SliderConfig' }
      & Pick<SliderConfig, 'min' | 'max' | 'step_value' | 'display_marks' | 'min_label' | 'max_label' | 'is_value_tooltip_on' | 'show_min_max_values'>
    )>, phone?: Maybe<(
      { __typename?: 'PhoneConfig' }
      & Pick<PhoneConfig, 'default_country' | 'available_countries'>
    )>, number?: Maybe<(
      { __typename?: 'NumberConfig' }
      & { range?: Maybe<(
        { __typename?: 'RangeConfig' }
        & Pick<RangeConfig, 'min' | 'max' | 'enabled'>
      )> }
    )>, multiple_select?: Maybe<(
      { __typename?: 'MultipleSelectConfig' }
      & { range?: Maybe<(
        { __typename?: 'ChoiceRangeConfig' }
        & Pick<ChoiceRangeConfig, 'min' | 'max' | 'enabled'>
      )>, exclusive_option?: Maybe<(
        { __typename?: 'ExclusiveOptionConfig' }
        & Pick<ExclusiveOptionConfig, 'option_id' | 'enabled'>
      )> }
    )>, date?: Maybe<(
      { __typename?: 'DateConfig' }
      & Pick<DateConfig, 'allowed_dates' | 'include_date_of_response'>
    )> }
  )>, metadata?: Maybe<(
    { __typename?: 'FormattedJson' }
    & Pick<FormattedJson, 'plaintext' | 'slate'>
  )> }
);

export type GetFormLibraryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFormLibraryQuery = (
  { __typename?: 'Query' }
  & { forms: (
    { __typename?: 'FormsPayload' }
    & { forms: Array<(
      { __typename?: 'Form' }
      & Pick<Form, 'id' | 'title' | 'key' | 'question_ids'>
      & { created?: Maybe<(
        { __typename?: 'AuditTrail' }
        & Pick<AuditTrail, 'user_email' | 'user_id' | 'date'>
      )>, last_updated?: Maybe<(
        { __typename?: 'AuditTrail' }
        & Pick<AuditTrail, 'user_email' | 'user_id' | 'date'>
      )>, pathways_used_in: Array<(
        { __typename?: 'Pathway' }
        & Pick<Pathway, 'id' | 'title'>
      )> }
    )> }
  ) }
);

type Action_ApiCallAction_Fragment = (
  { __typename?: 'ApiCallAction' }
  & Pick<ApiCallAction, 'id' | 'type' | 'title'>
  & { api_call?: Maybe<(
    { __typename?: 'ApiCall' }
    & ApiCallFragment
  )>, idle_time?: Maybe<(
    { __typename?: 'Delay' }
    & Pick<Delay, 'unit' | 'amount'>
  )>, step: (
    { __typename?: 'Step' }
    & Pick<Step, 'id'>
  ), stakeholders: Array<(
    { __typename?: 'Stakeholder' }
    & Pick<Stakeholder, 'id' | 'clinical_app_role'>
    & { label: (
      { __typename?: 'StakeholderLabel' }
      & Pick<StakeholderLabel, 'en'>
    ) }
  )>, locations?: Maybe<Array<(
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'label'>
  )>>, status: (
    { __typename?: 'ConfigurationStatus' }
    & ConfigurationStatusFragment
  ) }
);

type Action_ApiCallGraphqlAction_Fragment = (
  { __typename?: 'ApiCallGraphqlAction' }
  & Pick<ApiCallGraphqlAction, 'id' | 'type' | 'title'>
  & { api_call?: Maybe<(
    { __typename?: 'ApiCall' }
    & ApiCallFragment
  )>, idle_time?: Maybe<(
    { __typename?: 'Delay' }
    & Pick<Delay, 'unit' | 'amount'>
  )>, step: (
    { __typename?: 'Step' }
    & Pick<Step, 'id'>
  ), stakeholders: Array<(
    { __typename?: 'Stakeholder' }
    & Pick<Stakeholder, 'id' | 'clinical_app_role'>
    & { label: (
      { __typename?: 'StakeholderLabel' }
      & Pick<StakeholderLabel, 'en'>
    ) }
  )>, locations?: Maybe<Array<(
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'label'>
  )>>, status: (
    { __typename?: 'ConfigurationStatus' }
    & ConfigurationStatusFragment
  ) }
);

type Action_CalculationAction_Fragment = (
  { __typename?: 'CalculationAction' }
  & Pick<CalculationAction, 'calculation_id' | 'id' | 'type' | 'title'>
  & { calculation_inputs: Array<(
    { __typename?: 'CalculationInput' }
    & Pick<CalculationInput, 'calculation_input_id' | 'data_point_definition_id'>
  )>, idle_time?: Maybe<(
    { __typename?: 'Delay' }
    & Pick<Delay, 'unit' | 'amount'>
  )>, step: (
    { __typename?: 'Step' }
    & Pick<Step, 'id'>
  ), stakeholders: Array<(
    { __typename?: 'Stakeholder' }
    & Pick<Stakeholder, 'id' | 'clinical_app_role'>
    & { label: (
      { __typename?: 'StakeholderLabel' }
      & Pick<StakeholderLabel, 'en'>
    ) }
  )>, locations?: Maybe<Array<(
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'label'>
  )>>, status: (
    { __typename?: 'ConfigurationStatus' }
    & ConfigurationStatusFragment
  ) }
);

type Action_ChecklistAction_Fragment = (
  { __typename?: 'ChecklistAction' }
  & Pick<ChecklistAction, 'checklist' | 'id' | 'type' | 'title'>
  & { idle_time?: Maybe<(
    { __typename?: 'Delay' }
    & Pick<Delay, 'unit' | 'amount'>
  )>, step: (
    { __typename?: 'Step' }
    & Pick<Step, 'id'>
  ), stakeholders: Array<(
    { __typename?: 'Stakeholder' }
    & Pick<Stakeholder, 'id' | 'clinical_app_role'>
    & { label: (
      { __typename?: 'StakeholderLabel' }
      & Pick<StakeholderLabel, 'en'>
    ) }
  )>, locations?: Maybe<Array<(
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'label'>
  )>>, status: (
    { __typename?: 'ConfigurationStatus' }
    & ConfigurationStatusFragment
  ) }
);

type Action_ClinicalNoteAction_Fragment = (
  { __typename?: 'ClinicalNoteAction' }
  & Pick<ClinicalNoteAction, 'id' | 'type' | 'title'>
  & { idle_time?: Maybe<(
    { __typename?: 'Delay' }
    & Pick<Delay, 'unit' | 'amount'>
  )>, step: (
    { __typename?: 'Step' }
    & Pick<Step, 'id'>
  ), stakeholders: Array<(
    { __typename?: 'Stakeholder' }
    & Pick<Stakeholder, 'id' | 'clinical_app_role'>
    & { label: (
      { __typename?: 'StakeholderLabel' }
      & Pick<StakeholderLabel, 'en'>
    ) }
  )>, locations?: Maybe<Array<(
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'label'>
  )>>, status: (
    { __typename?: 'ConfigurationStatus' }
    & ConfigurationStatusFragment
  ) }
);

type Action_ExtensionAction_Fragment = (
  { __typename?: 'ExtensionAction' }
  & Pick<ExtensionAction, 'extension_key' | 'extension_action_key' | 'id' | 'type' | 'title'>
  & { stakeholders_select_settings?: Maybe<(
    { __typename?: 'StakeholdersSelectSettings' }
    & Pick<StakeholdersSelectSettings, 'label' | 'mode' | 'description'>
  )>, extension_fields: Array<(
    { __typename?: 'BooleanField' }
    & Pick<BooleanField, 'value' | 'id' | 'type' | 'label' | 'description' | 'required'>
  ) | (
    { __typename?: 'DateField' }
    & Pick<DateField, 'value' | 'id' | 'type' | 'label' | 'description' | 'required'>
    & { delay?: Maybe<(
      { __typename?: 'Delay' }
      & Pick<Delay, 'amount' | 'unit'>
    )> }
  ) | (
    { __typename?: 'HtmlField' }
    & Pick<HtmlField, 'value' | 'slate' | 'id' | 'type' | 'label' | 'description' | 'required'>
  ) | (
    { __typename?: 'JsonField' }
    & Pick<JsonField, 'value' | 'slate' | 'id' | 'type' | 'label' | 'description' | 'required'>
    & { jsonSchemaSource?: Maybe<(
      { __typename?: 'JsonSchemaSourceGraphqlType' }
      & Pick<JsonSchemaSourceGraphqlType, 'system' | 'qualifiedKey'>
    )> }
  ) | (
    { __typename?: 'NumericArrayField' }
    & Pick<NumericArrayField, 'value' | 'id' | 'type' | 'label' | 'description' | 'required'>
  ) | (
    { __typename?: 'NumericField' }
    & Pick<NumericField, 'value' | 'id' | 'type' | 'label' | 'description' | 'required'>
  ) | (
    { __typename?: 'StringArrayField' }
    & Pick<StringArrayField, 'value' | 'id' | 'type' | 'label' | 'description' | 'required'>
  ) | (
    { __typename?: 'StringField' }
    & Pick<StringField, 'value' | 'stringType' | 'id' | 'type' | 'label' | 'description' | 'required'>
  ) | (
    { __typename?: 'TextField' }
    & Pick<TextField, 'value' | 'slate' | 'id' | 'type' | 'label' | 'description' | 'required'>
  )>, extension_data_points?: Maybe<Array<(
    { __typename?: 'ExtensionDataPointDefinition' }
    & Pick<ExtensionDataPointDefinition, 'key' | 'valueType' | 'jsonPath'>
    & { jsonSchemaSource?: Maybe<(
      { __typename?: 'JsonSchemaSourceGraphqlType' }
      & Pick<JsonSchemaSourceGraphqlType, 'system' | 'qualifiedKey'>
    )> }
  )>>, extension_data_point_mappings?: Maybe<Array<(
    { __typename?: 'ExtensionDataPointMapping' }
    & Pick<ExtensionDataPointMapping, 'id' | 'extension_data_point_key' | 'data_point_definition_id'>
  )>>, idle_time?: Maybe<(
    { __typename?: 'Delay' }
    & Pick<Delay, 'unit' | 'amount'>
  )>, step: (
    { __typename?: 'Step' }
    & Pick<Step, 'id'>
  ), stakeholders: Array<(
    { __typename?: 'Stakeholder' }
    & Pick<Stakeholder, 'id' | 'clinical_app_role'>
    & { label: (
      { __typename?: 'StakeholderLabel' }
      & Pick<StakeholderLabel, 'en'>
    ) }
  )>, locations?: Maybe<Array<(
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'label'>
  )>>, status: (
    { __typename?: 'ConfigurationStatus' }
    & ConfigurationStatusFragment
  ) }
);

type Action_FormAction_Fragment = (
  { __typename?: 'FormAction' }
  & Pick<FormAction, 'form_id' | 'form_display_mode' | 'id' | 'type' | 'title'>
  & { idle_time?: Maybe<(
    { __typename?: 'Delay' }
    & Pick<Delay, 'amount' | 'unit'>
  )>, step: (
    { __typename?: 'Step' }
    & Pick<Step, 'id'>
  ), stakeholders: Array<(
    { __typename?: 'Stakeholder' }
    & Pick<Stakeholder, 'id' | 'clinical_app_role'>
    & { label: (
      { __typename?: 'StakeholderLabel' }
      & Pick<StakeholderLabel, 'en'>
    ) }
  )>, locations?: Maybe<Array<(
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'label'>
  )>>, status: (
    { __typename?: 'ConfigurationStatus' }
    & ConfigurationStatusFragment
  ) }
);

type Action_MessageAction_Fragment = (
  { __typename?: 'MessageAction' }
  & Pick<MessageAction, 'message_id' | 'id' | 'type' | 'title'>
  & { idle_time?: Maybe<(
    { __typename?: 'Delay' }
    & Pick<Delay, 'unit' | 'amount'>
  )>, step: (
    { __typename?: 'Step' }
    & Pick<Step, 'id'>
  ), stakeholders: Array<(
    { __typename?: 'Stakeholder' }
    & Pick<Stakeholder, 'id' | 'clinical_app_role'>
    & { label: (
      { __typename?: 'StakeholderLabel' }
      & Pick<StakeholderLabel, 'en'>
    ) }
  )>, locations?: Maybe<Array<(
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'label'>
  )>>, status: (
    { __typename?: 'ConfigurationStatus' }
    & ConfigurationStatusFragment
  ) }
);

type Action_PushToEmrAction_Fragment = (
  { __typename?: 'PushToEmrAction' }
  & Pick<PushToEmrAction, 'emr_report_id' | 'id' | 'type' | 'title'>
  & { emr_report?: Maybe<(
    { __typename?: 'EmrReport' }
    & Pick<EmrReport, 'id' | 'body'>
  )>, custom_fields?: Maybe<Array<(
    { __typename?: 'CustomActionField' }
    & Pick<CustomActionField, 'id' | 'name' | 'type' | 'label' | 'description' | 'value' | 'readonly' | 'mandatory'>
    & { options?: Maybe<Array<(
      { __typename?: 'CustomActionFieldOption' }
      & Pick<CustomActionFieldOption, 'label' | 'value'>
    )>> }
  )>>, idle_time?: Maybe<(
    { __typename?: 'Delay' }
    & Pick<Delay, 'unit' | 'amount'>
  )>, step: (
    { __typename?: 'Step' }
    & Pick<Step, 'id'>
  ), stakeholders: Array<(
    { __typename?: 'Stakeholder' }
    & Pick<Stakeholder, 'id' | 'clinical_app_role'>
    & { label: (
      { __typename?: 'StakeholderLabel' }
      & Pick<StakeholderLabel, 'en'>
    ) }
  )>, locations?: Maybe<Array<(
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'label'>
  )>>, status: (
    { __typename?: 'ConfigurationStatus' }
    & ConfigurationStatusFragment
  ) }
);

export type ActionFragment = Action_ApiCallAction_Fragment | Action_ApiCallGraphqlAction_Fragment | Action_CalculationAction_Fragment | Action_ChecklistAction_Fragment | Action_ClinicalNoteAction_Fragment | Action_ExtensionAction_Fragment | Action_FormAction_Fragment | Action_MessageAction_Fragment | Action_PushToEmrAction_Fragment;

export type GetActionQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetActionQuery = (
  { __typename?: 'Query' }
  & { action: (
    { __typename?: 'ActionPayload' }
    & { action: (
      { __typename?: 'ApiCallAction' }
      & Action_ApiCallAction_Fragment
    ) | (
      { __typename?: 'ApiCallGraphqlAction' }
      & Action_ApiCallGraphqlAction_Fragment
    ) | (
      { __typename?: 'CalculationAction' }
      & Action_CalculationAction_Fragment
    ) | (
      { __typename?: 'ChecklistAction' }
      & Action_ChecklistAction_Fragment
    ) | (
      { __typename?: 'ClinicalNoteAction' }
      & Action_ClinicalNoteAction_Fragment
    ) | (
      { __typename?: 'ExtensionAction' }
      & Action_ExtensionAction_Fragment
    ) | (
      { __typename?: 'FormAction' }
      & Action_FormAction_Fragment
    ) | (
      { __typename?: 'MessageAction' }
      & Action_MessageAction_Fragment
    ) | (
      { __typename?: 'PushToEmrAction' }
      & Action_PushToEmrAction_Fragment
    ) }
  ) }
);

export type GetPathwayCaseApiCallQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetPathwayCaseApiCallQuery = (
  { __typename?: 'Query' }
  & { pathwayCaseApiCall: (
    { __typename?: 'PathwayCaseApiCallPayload' }
    & Pick<PathwayCaseApiCallPayload, 'code' | 'success'>
    & { api_call: (
      { __typename?: 'PathwayCaseApiCall' }
      & Pick<PathwayCaseApiCall, 'created_at' | 'title' | 'status' | 'id'>
      & { request: (
        { __typename?: 'ApiCallRequest' }
        & Pick<ApiCallRequest, 'body' | 'method' | 'endpoint'>
        & { headers: Array<(
          { __typename?: 'ApiCallHeader' }
          & Pick<ApiCallHeader, 'value' | 'key'>
        )> }
      ), responses: Array<(
        { __typename?: 'ApiCallResponse' }
        & Pick<ApiCallResponse, 'status' | 'date' | 'body'>
      )> }
    ) }
  ) }
);

export type GetPathwayCaseApiCallsQueryVariables = Exact<{
  pathway_case_id: Scalars['String'];
}>;


export type GetPathwayCaseApiCallsQuery = (
  { __typename?: 'Query' }
  & { pathwayCaseApiCalls: (
    { __typename?: 'PathwayCaseApiCallsPayload' }
    & Pick<PathwayCaseApiCallsPayload, 'code' | 'success'>
    & { api_calls: Array<(
      { __typename?: 'PathwayCaseApiCall' }
      & Pick<PathwayCaseApiCall, 'title' | 'created_at' | 'status' | 'id'>
      & { request: (
        { __typename?: 'ApiCallRequest' }
        & Pick<ApiCallRequest, 'body' | 'method' | 'endpoint'>
        & { headers: Array<(
          { __typename?: 'ApiCallHeader' }
          & Pick<ApiCallHeader, 'value' | 'key'>
        )> }
      ), responses: Array<(
        { __typename?: 'ApiCallResponse' }
        & Pick<ApiCallResponse, 'status' | 'date' | 'body'>
      )> }
    )> }
  ) }
);

export type OnApiCallCreatedSubscriptionVariables = Exact<{
  pathway_case_id: Scalars['String'];
}>;


export type OnApiCallCreatedSubscription = (
  { __typename?: 'Subscription' }
  & { apiCallCreated: (
    { __typename?: 'PathwayCaseApiCall' }
    & Pick<PathwayCaseApiCall, 'status' | 'created_at' | 'id' | 'title'>
    & { request: (
      { __typename?: 'ApiCallRequest' }
      & Pick<ApiCallRequest, 'body' | 'method' | 'endpoint'>
      & { headers: Array<(
        { __typename?: 'ApiCallHeader' }
        & Pick<ApiCallHeader, 'value' | 'key'>
      )> }
    ), responses: Array<(
      { __typename?: 'ApiCallResponse' }
      & Pick<ApiCallResponse, 'status' | 'date' | 'body'>
    )> }
  ) }
);

export type OnApiCallUpdatedSubscriptionVariables = Exact<{
  pathway_case_id: Scalars['String'];
}>;


export type OnApiCallUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { apiCallUpdated: (
    { __typename?: 'PathwayCaseApiCall' }
    & Pick<PathwayCaseApiCall, 'status' | 'created_at' | 'id' | 'title'>
    & { request: (
      { __typename?: 'ApiCallRequest' }
      & Pick<ApiCallRequest, 'body' | 'method' | 'endpoint'>
      & { headers: Array<(
        { __typename?: 'ApiCallHeader' }
        & Pick<ApiCallHeader, 'value' | 'key'>
      )> }
    ), responses: Array<(
      { __typename?: 'ApiCallResponse' }
      & Pick<ApiCallResponse, 'status' | 'date' | 'body'>
    )> }
  ) }
);

export type GetPathwayCaseWebhookCallQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetPathwayCaseWebhookCallQuery = (
  { __typename?: 'Query' }
  & { pathwayCaseWebhookCall: (
    { __typename?: 'PathwayCaseWebhookCallPayload' }
    & { webhook_call: (
      { __typename?: 'PathwayCaseWebhookCall' }
      & PathwayCaseWebhookCallFragment
    ) }
  ) }
);

export type GetPathwayCaseWebhookCallsQueryVariables = Exact<{
  pathway_case_id: Scalars['String'];
}>;


export type GetPathwayCaseWebhookCallsQuery = (
  { __typename?: 'Query' }
  & { pathwayCaseWebhookCalls: (
    { __typename?: 'PathwayCaseWebhookCallsPayload' }
    & { webhook_calls: Array<(
      { __typename?: 'PathwayCaseWebhookCall' }
      & PathwayCaseWebhookCallFragment
    )> }
  ) }
);

export type OnWebhookCallCreatedSubscriptionVariables = Exact<{
  pathway_case_id: Scalars['String'];
}>;


export type OnWebhookCallCreatedSubscription = (
  { __typename?: 'Subscription' }
  & { webhookCallCreated: (
    { __typename?: 'PathwayCaseWebhookCall' }
    & PathwayCaseWebhookCallFragment
  ) }
);

export type OnWebhookCallUpdatedSubscriptionVariables = Exact<{
  pathway_case_id: Scalars['String'];
}>;


export type OnWebhookCallUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { webhookCallUpdated: (
    { __typename?: 'PathwayCaseWebhookCall' }
    & PathwayCaseWebhookCallFragment
  ) }
);

export type OnWebhookCallsDeletedSubscriptionVariables = Exact<{
  pathway_case_id: Scalars['String'];
}>;


export type OnWebhookCallsDeletedSubscription = (
  { __typename?: 'Subscription' }
  & { webhookCallsDeleted: (
    { __typename?: 'WebhookCallsDeleted' }
    & Pick<WebhookCallsDeleted, 'webhook_call_ids'>
  ) }
);

export type PathwayCaseWebhookCallFragment = (
  { __typename?: 'PathwayCaseWebhookCall' }
  & Pick<PathwayCaseWebhookCall, 'id' | 'webhook_name' | 'webhook_id' | 'status' | 'event_type' | 'created_at'>
  & { request: (
    { __typename?: 'WebhookCallRequest' }
    & Pick<WebhookCallRequest, 'method' | 'endpoint' | 'body'>
    & { headers: Array<(
      { __typename?: 'WebhookCallHeader' }
      & Pick<WebhookCallHeader, 'key' | 'value'>
    )> }
  ), responses: Array<(
    { __typename?: 'WebhookCallResponse' }
    & Pick<WebhookCallResponse, 'status' | 'body' | 'date'>
  )>, pathway?: Maybe<(
    { __typename?: 'ApiPathwayContext' }
    & Pick<ApiPathwayContext, 'id' | 'pathway_definition_id' | 'start_date'>
  )> }
);

export type StickyNoteQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type StickyNoteQuery = (
  { __typename?: 'Query' }
  & { stickyNote: (
    { __typename?: 'StickyNotePayload' }
    & Pick<StickyNotePayload, 'success' | 'code'>
    & { stickyNote: (
      { __typename?: 'StickyNote' }
      & Pick<StickyNote, 'id'>
      & { body?: Maybe<(
        { __typename?: 'StickyNoteBody' }
        & Pick<StickyNoteBody, 'text' | 'format'>
      )> }
    ) }
  ) }
);

export type GetWebhookQueryVariables = Exact<{
  input: WebhookInput;
}>;


export type GetWebhookQuery = (
  { __typename?: 'Query' }
  & { webhook: (
    { __typename?: 'WebhookPayload' }
    & Pick<WebhookPayload, 'success'>
    & { webhook?: Maybe<(
      { __typename?: 'Webhook' }
      & Pick<Webhook, 'endpoint' | 'endpoint_test' | 'enabled' | 'name' | 'subscribed_events' | 'signing_key'>
      & { headers?: Maybe<Array<(
        { __typename?: 'WebhookHeader' }
        & Pick<WebhookHeader, 'key' | 'value'>
      )>> }
    )> }
  ) }
);

export type GetWebhooksQueryVariables = Exact<{
  pathway_id: Scalars['String'];
}>;


export type GetWebhooksQuery = (
  { __typename?: 'Query' }
  & { pathway: (
    { __typename?: 'PathwayPayload' }
    & { pathway: (
      { __typename?: 'Pathway' }
      & { settings?: Maybe<(
        { __typename?: 'PathwaySettings' }
        & { webhooks?: Maybe<Array<(
          { __typename?: 'Webhook' }
          & Pick<Webhook, 'id' | 'endpoint' | 'endpoint_test' | 'enabled' | 'name' | 'created_at' | 'subscribed_events' | 'signing_key'>
          & { headers?: Maybe<Array<(
            { __typename?: 'WebhookHeader' }
            & Pick<WebhookHeader, 'key' | 'value'>
          )>> }
        )>> }
      )> }
    ) }
  ) }
);

export type ConfigureHostedPagesLinkMutationVariables = Exact<{
  input: ConfigureAhpLinkInput;
}>;


export type ConfigureHostedPagesLinkMutation = (
  { __typename?: 'Mutation' }
  & { configureAhpLink: (
    { __typename?: 'AhpLinkPayload' }
    & { ahp_link: (
      { __typename?: 'AhpLink' }
      & HostedPagesLinkFragment
    ) }
  ) }
);

export type GetHostedPagesLinksQueryVariables = Exact<{
  careflow_id: Scalars['String'];
}>;


export type GetHostedPagesLinksQuery = (
  { __typename?: 'Query' }
  & { ahp_links: (
    { __typename?: 'AhpLinkPayloads' }
    & { ahp_links: Array<(
      { __typename?: 'AhpLink' }
      & HostedPagesLinkFragment
    )> }
  ) }
);

export type HostedPagesLinkFragment = (
  { __typename?: 'AhpLink' }
  & Pick<AhpLink, 'id' | 'stakeholder_definition_id' | 'enabled' | 'language' | 'success_url' | 'cancel_url' | 'slug' | 'pathway_definition_id' | 'base_url'>
);

export type IsSlugUniqueQueryVariables = Exact<{
  slug: Scalars['String'];
  id?: Maybe<Scalars['String']>;
}>;


export type IsSlugUniqueQuery = (
  { __typename?: 'Query' }
  & { is_slug_unique: (
    { __typename?: 'IsSlugUniquePayload' }
    & Pick<IsSlugUniquePayload, 'is_slug_unique'>
  ) }
);

export type MarkReleaseAsLiveMutationVariables = Exact<{
  input: MarkReleaseAsLiveInput;
}>;


export type MarkReleaseAsLiveMutation = (
  { __typename?: 'Mutation' }
  & { markReleaseAsLive: (
    { __typename?: 'MarkReleaseAsLivePayload' }
    & Pick<MarkReleaseAsLivePayload, 'success'>
  ) }
);

export type AddMessageAttachmentMutationVariables = Exact<{
  input: AddMessageAttachmentInput;
}>;


export type AddMessageAttachmentMutation = (
  { __typename?: 'Mutation' }
  & { addMessageAttachment: (
    { __typename?: 'MessagePayload' }
    & Pick<MessagePayload, 'success'>
    & { message: (
      { __typename?: 'Message' }
      & Pick<Message, 'id' | 'subject' | 'body'>
      & { attachments?: Maybe<Array<(
        { __typename?: 'MessageAttachment' }
        & Pick<MessageAttachment, 'id' | 'name' | 'url' | 'type'>
      )>> }
    ) }
  ) }
);

export type GetMessageQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetMessageQuery = (
  { __typename?: 'Query' }
  & { message: (
    { __typename?: 'MessagePayload' }
    & { message: (
      { __typename?: 'Message' }
      & Pick<Message, 'id' | 'body' | 'subject'>
      & { attachments?: Maybe<Array<(
        { __typename?: 'MessageAttachment' }
        & Pick<MessageAttachment, 'id' | 'name' | 'type' | 'url'>
      )>> }
    ) }
  ) }
);

export type RemoveMessageAttachmentMutationVariables = Exact<{
  input: RemoveMessageAttachmentInput;
}>;


export type RemoveMessageAttachmentMutation = (
  { __typename?: 'Mutation' }
  & { removeMessageAttachment: (
    { __typename?: 'MessagePayload' }
    & Pick<MessagePayload, 'success'>
    & { message: (
      { __typename?: 'Message' }
      & Pick<Message, 'id' | 'subject' | 'body'>
      & { attachments?: Maybe<Array<(
        { __typename?: 'MessageAttachment' }
        & Pick<MessageAttachment, 'id' | 'name' | 'url' | 'type'>
      )>> }
    ) }
  ) }
);

export type ReorderMessageAttachmentsMutationVariables = Exact<{
  input: ReorderMessageAttachmentsInput;
}>;


export type ReorderMessageAttachmentsMutation = (
  { __typename?: 'Mutation' }
  & { reorderMessageAttachments: (
    { __typename?: 'MessagePayload' }
    & Pick<MessagePayload, 'success'>
    & { message: (
      { __typename?: 'Message' }
      & Pick<Message, 'id' | 'subject' | 'body'>
      & { attachments?: Maybe<Array<(
        { __typename?: 'MessageAttachment' }
        & Pick<MessageAttachment, 'id' | 'name' | 'url' | 'type'>
      )>> }
    ) }
  ) }
);

export type SetMessageAttachmentNameMutationVariables = Exact<{
  input: SetMessageAttachmentNameInput;
}>;


export type SetMessageAttachmentNameMutation = (
  { __typename?: 'Mutation' }
  & { setMessageAttachmentName: (
    { __typename?: 'MessagePayload' }
    & Pick<MessagePayload, 'code' | 'success'>
    & { message: (
      { __typename?: 'Message' }
      & Pick<Message, 'id'>
      & { attachments?: Maybe<Array<(
        { __typename?: 'MessageAttachment' }
        & Pick<MessageAttachment, 'id' | 'name' | 'url'>
      )>> }
    ) }
  ) }
);

export type SetMessageAttachmentUrlMutationVariables = Exact<{
  input: SetMessageAttachmentUrlInput;
}>;


export type SetMessageAttachmentUrlMutation = (
  { __typename?: 'Mutation' }
  & { setMessageAttachmentUrl: (
    { __typename?: 'MessagePayload' }
    & Pick<MessagePayload, 'code' | 'success'>
    & { message: (
      { __typename?: 'Message' }
      & Pick<Message, 'id'>
      & { attachments?: Maybe<Array<(
        { __typename?: 'MessageAttachment' }
        & Pick<MessageAttachment, 'id' | 'name' | 'url'>
      )>> }
    ) }
  ) }
);

export type SetMessageBodyMutationVariables = Exact<{
  input: SetMessageBodyInput;
}>;


export type SetMessageBodyMutation = (
  { __typename?: 'Mutation' }
  & { setMessageBody: (
    { __typename?: 'MessagePayload' }
    & Pick<MessagePayload, 'success'>
    & { message: (
      { __typename?: 'Message' }
      & Pick<Message, 'id' | 'body'>
    ) }
  ) }
);

export type SetMessageSubjectMutationVariables = Exact<{
  input: SetMessageSubjectInput;
}>;


export type SetMessageSubjectMutation = (
  { __typename?: 'Mutation' }
  & { setMessageSubject: (
    { __typename?: 'MessagePayload' }
    & Pick<MessagePayload, 'success'>
    & { message: (
      { __typename?: 'Message' }
      & Pick<Message, 'id' | 'subject'>
    ) }
  ) }
);

export type GetGeneratedMessageQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetGeneratedMessageQuery = (
  { __typename?: 'Query' }
  & { generated_message: (
    { __typename?: 'GeneratedMessagePayload' }
    & { message: (
      { __typename?: 'PreviewMessage' }
      & Pick<PreviewMessage, 'id' | 'body' | 'format' | 'subject'>
      & { attachments?: Maybe<Array<(
        { __typename?: 'MessageAttachment' }
        & Pick<MessageAttachment, 'id' | 'name' | 'type' | 'url'>
      )>> }
    ) }
  ) }
);

export type ReadMessageMutationVariables = Exact<{
  input: ReadMessageInput;
}>;


export type ReadMessageMutation = (
  { __typename?: 'Mutation' }
  & { readMessage: (
    { __typename?: 'ReadMessagePayload' }
    & { activity: (
      { __typename?: 'Activity' }
      & ActivityFragment
    ) }
  ) }
);

export type GetPathwayQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetPathwayQuery = (
  { __typename?: 'Query' }
  & { pathway: (
    { __typename?: 'PathwayPayload' }
    & { pathway: (
      { __typename?: 'Pathway' }
      & PathwayFragment
    ) }
  ) }
);

export type PathwayFragment = (
  { __typename?: 'Pathway' }
  & Pick<Pathway, 'id' | 'title' | 'readonly'>
  & { tracks?: Maybe<Array<(
    { __typename?: 'Track' }
    & Pick<Track, 'id' | 'title'>
  )>>, created: (
    { __typename?: 'AuditTrail' }
    & Pick<AuditTrail, 'user_id' | 'user_email' | 'date'>
  ), last_updated?: Maybe<(
    { __typename?: 'AuditTrail' }
    & Pick<AuditTrail, 'user_id' | 'user_email' | 'date'>
  )>, labels?: Maybe<Array<(
    { __typename?: 'Label' }
    & Pick<Label, 'id' | 'color' | 'text'>
  )>>, last_published_version?: Maybe<(
    { __typename?: 'PathwayVersion' }
    & Pick<PathwayVersion, 'version' | 'version_status'>
  )> }
);

export type SetBrandingAccentColorMutationVariables = Exact<{
  input: SetBrandingAccentColorInput;
}>;


export type SetBrandingAccentColorMutation = (
  { __typename?: 'Mutation' }
  & { setBrandingAccentColor: (
    { __typename?: 'SetBrandingAccentColorPayload' }
    & { settings: (
      { __typename?: 'PathwaySettings' }
      & PathwaySettingsFragment
    ) }
  ) }
);

export type SetBrandingCustomThemeMutationVariables = Exact<{
  input: SetBrandingCustomThemeInput;
}>;


export type SetBrandingCustomThemeMutation = (
  { __typename?: 'Mutation' }
  & { setBrandingCustomTheme: (
    { __typename?: 'SetBrandingCustomThemePayload' }
    & { settings: (
      { __typename?: 'PathwaySettings' }
      & PathwaySettingsFragment
    ) }
  ) }
);

export type SetBrandingHostedPageAutoProgressMutationVariables = Exact<{
  input: SetBrandingHostedPageAutoProgressInput;
}>;


export type SetBrandingHostedPageAutoProgressMutation = (
  { __typename?: 'Mutation' }
  & { setBrandingHostedPageAutoProgress: (
    { __typename?: 'SetBrandingHostedPageAutoProgressPayload' }
    & { settings: (
      { __typename?: 'PathwaySettings' }
      & PathwaySettingsFragment
    ) }
  ) }
);

export type SetBrandingHostedPageAutosaveMutationVariables = Exact<{
  input: SetBrandingHostedPageAutosaveInput;
}>;


export type SetBrandingHostedPageAutosaveMutation = (
  { __typename?: 'Mutation' }
  & { setBrandingHostedPageAutosave: (
    { __typename?: 'SetBrandingHostedPageAutosavePayload' }
    & { settings: (
      { __typename?: 'PathwaySettings' }
      & PathwaySettingsFragment
    ) }
  ) }
);

export type SetBrandingHostedPageTitleMutationVariables = Exact<{
  input: SetBrandingHostedPageTitleInput;
}>;


export type SetBrandingHostedPageTitleMutation = (
  { __typename?: 'Mutation' }
  & { setBrandingHostedPageTitle: (
    { __typename?: 'SetBrandingHostedPageTitlePayload' }
    & { settings: (
      { __typename?: 'PathwaySettings' }
      & PathwaySettingsFragment
    ) }
  ) }
);

export type SetBrandingLogoUrlMutationVariables = Exact<{
  input: SetBrandingLogoUrlInput;
}>;


export type SetBrandingLogoUrlMutation = (
  { __typename?: 'Mutation' }
  & { setBrandingLogoUrl: (
    { __typename?: 'SetBrandingLogoUrlPayload' }
    & { settings: (
      { __typename?: 'PathwaySettings' }
      & PathwaySettingsFragment
    ) }
  ) }
);

export type PathwayCaseQueryVariables = Exact<{
  pathwayCaseId: Scalars['String'];
}>;


export type PathwayCaseQuery = (
  { __typename?: 'Query' }
  & { pathway_case: (
    { __typename?: 'PathwayCasePayload' }
    & { pathway_case: (
      { __typename?: 'PathwayCase' }
      & Pick<PathwayCase, 'id' | 'title' | 'status'>
    ) }
  ) }
);

export type SetPathwayCaseTitleMutationVariables = Exact<{
  input: SetPathwayCaseTitleInput;
}>;


export type SetPathwayCaseTitleMutation = (
  { __typename?: 'Mutation' }
  & { setPathwayCaseTitle: (
    { __typename?: 'SetPathwayCaseTitlePayload' }
    & Pick<SetPathwayCaseTitlePayload, 'success'>
    & { pathway_case: (
      { __typename?: 'PathwayCase' }
      & Pick<PathwayCase, 'id' | 'title'>
    ) }
  ) }
);

export type ActivityFragment = (
  { __typename?: 'Activity' }
  & Pick<Activity, 'id' | 'icon_url' | 'action' | 'date' | 'status' | 'resolution' | 'reference_id' | 'container_name' | 'isUserActivity'>
  & { subject: (
    { __typename?: 'ActivitySubject' }
    & Pick<ActivitySubject, 'id' | 'type' | 'name'>
  ), object: (
    { __typename?: 'ActivityObject' }
    & Pick<ActivityObject, 'id' | 'type' | 'name'>
  ), indirect_object?: Maybe<(
    { __typename?: 'ActivityObject' }
    & Pick<ActivityObject, 'id' | 'type' | 'name'>
  )>, track?: Maybe<(
    { __typename?: 'ActivityTrack' }
    & Pick<ActivityTrack, 'id' | 'title'>
  )>, label?: Maybe<(
    { __typename?: 'ActivityLabel' }
    & Pick<ActivityLabel, 'id' | 'text' | 'color'>
  )>, sub_activities: Array<(
    { __typename?: 'SubActivity' }
    & Pick<SubActivity, 'id' | 'date' | 'action' | 'error' | 'error_category'>
    & { text?: Maybe<(
      { __typename?: 'TranslatedText' }
      & Pick<TranslatedText, 'en'>
    )>, subject: (
      { __typename?: 'ActivitySubject' }
      & Pick<ActivitySubject, 'id' | 'type' | 'name'>
    ), object?: Maybe<(
      { __typename?: 'ActivityObject' }
      & Pick<ActivityObject, 'id' | 'type' | 'name'>
    )> }
  )>, context?: Maybe<(
    { __typename?: 'PathwayContext' }
    & Pick<PathwayContext, 'instance_id' | 'pathway_id' | 'track_id' | 'step_id' | 'action_id'>
  )>, stakeholders?: Maybe<Array<(
    { __typename?: 'ActivityObject' }
    & Pick<ActivityObject, 'id' | 'type' | 'name' | 'email'>
  )>> }
);

export type OnActivitiesDeletedSubscriptionVariables = Exact<{
  pathway_case_id: Scalars['String'];
}>;


export type OnActivitiesDeletedSubscription = (
  { __typename?: 'Subscription' }
  & { activitiesDeleted: (
    { __typename?: 'ActivitiesDeleted' }
    & Pick<ActivitiesDeleted, 'activity_ids'>
  ) }
);

export type OnActivityCompletedSubscriptionVariables = Exact<{
  pathway_case_id: Scalars['String'];
}>;


export type OnActivityCompletedSubscription = (
  { __typename?: 'Subscription' }
  & { activityCompleted: (
    { __typename?: 'Activity' }
    & ActivityFragment
  ) }
);

export type OnActivityCreatedSubscriptionVariables = Exact<{
  pathway_case_id: Scalars['String'];
}>;


export type OnActivityCreatedSubscription = (
  { __typename?: 'Subscription' }
  & { activityCreated: (
    { __typename?: 'Activity' }
    & ActivityFragment
  ) }
);

export type OnActivityUpdatedSubscriptionVariables = Exact<{
  pathway_case_id: Scalars['String'];
}>;


export type OnActivityUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { activityUpdated: (
    { __typename?: 'Activity' }
    & ActivityFragment
  ) }
);

export type PathwayCaseActivitiesQueryVariables = Exact<{
  pathway_case_id: Scalars['String'];
}>;


export type PathwayCaseActivitiesQuery = (
  { __typename?: 'Query' }
  & { pathwayCaseActivities: (
    { __typename?: 'ActivitiesPayload' }
    & Pick<ActivitiesPayload, 'success'>
    & { activities: Array<(
      { __typename?: 'Activity' }
      & ActivityFragment
    )> }
  ) }
);

export type PathwayCaseApiCallQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type PathwayCaseApiCallQuery = (
  { __typename?: 'Query' }
  & { pathwayCaseApiCall: (
    { __typename?: 'PathwayCaseApiCallPayload' }
    & { api_call: (
      { __typename?: 'PathwayCaseApiCall' }
      & Pick<PathwayCaseApiCall, 'id' | 'title'>
      & { request: (
        { __typename?: 'ApiCallRequest' }
        & Pick<ApiCallRequest, 'endpoint' | 'body' | 'method'>
        & { headers: Array<(
          { __typename?: 'ApiCallHeader' }
          & Pick<ApiCallHeader, 'key' | 'value'>
        )> }
      ), responses: Array<(
        { __typename?: 'ApiCallResponse' }
        & Pick<ApiCallResponse, 'status' | 'body' | 'date'>
      )> }
    ) }
  ) }
);

export type TriggerPathwayCaseApiCallMutationVariables = Exact<{
  input: TriggerApiCallInput;
}>;


export type TriggerPathwayCaseApiCallMutation = (
  { __typename?: 'Mutation' }
  & { triggerApiCall: (
    { __typename?: 'TriggerApiCallPayload' }
    & { api_call: (
      { __typename?: 'PathwayCaseApiCall' }
      & Pick<PathwayCaseApiCall, 'id'>
      & { request: (
        { __typename?: 'ApiCallRequest' }
        & Pick<ApiCallRequest, 'endpoint' | 'body'>
        & { headers: Array<(
          { __typename?: 'ApiCallHeader' }
          & Pick<ApiCallHeader, 'key' | 'value'>
        )> }
      ), responses: Array<(
        { __typename?: 'ApiCallResponse' }
        & Pick<ApiCallResponse, 'status' | 'body' | 'date'>
      )> }
    ) }
  ) }
);

export type ElementFragment = (
  { __typename?: 'Element' }
  & Pick<Element, 'id' | 'parent_id' | 'name' | 'status' | 'type' | 'activity_type' | 'start_date' | 'end_date'>
  & { label?: Maybe<(
    { __typename?: 'ActivityLabel' }
    & Pick<ActivityLabel, 'id' | 'color' | 'text'>
  )>, stakeholders: Array<(
    { __typename?: 'ElementStakeholder' }
    & Pick<ElementStakeholder, 'id' | 'name'>
  )>, context: (
    { __typename?: 'PathwayContext' }
    & Pick<PathwayContext, 'instance_id' | 'pathway_id' | 'track_id' | 'step_id' | 'action_id'>
  ) }
);

export type OnElementCompletedSubscriptionVariables = Exact<{
  pathway_case_id: Scalars['String'];
}>;


export type OnElementCompletedSubscription = (
  { __typename?: 'Subscription' }
  & { elementCompleted: (
    { __typename?: 'Element' }
    & ElementFragment
  ) }
);

export type OnElementCreatedSubscriptionVariables = Exact<{
  pathway_case_id: Scalars['String'];
}>;


export type OnElementCreatedSubscription = (
  { __typename?: 'Subscription' }
  & { elementCreated: (
    { __typename?: 'Element' }
    & ElementFragment
  ) }
);

export type OnElementDeletedSubscriptionVariables = Exact<{
  pathway_case_id: Scalars['String'];
}>;


export type OnElementDeletedSubscription = (
  { __typename?: 'Subscription' }
  & { elementDeleted: (
    { __typename?: 'Element' }
    & ElementFragment
  ) }
);

export type OnElementUpdatedSubscriptionVariables = Exact<{
  pathway_case_id: Scalars['String'];
}>;


export type OnElementUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { elementUpdated: (
    { __typename?: 'Element' }
    & ElementFragment
  ) }
);

export type PathwayCaseElementsQueryVariables = Exact<{
  pathway_case_id: Scalars['String'];
}>;


export type PathwayCaseElementsQuery = (
  { __typename?: 'Query' }
  & { pathwayCaseElements: (
    { __typename?: 'ElementsPayload' }
    & { elements: Array<(
      { __typename?: 'Element' }
      & ElementFragment
    )> }
  ) }
);

export type PathwayCaseStepActivitiesQueryVariables = Exact<{
  input: PathwayCaseStepActivitiesInput;
}>;


export type PathwayCaseStepActivitiesQuery = (
  { __typename?: 'Query' }
  & { pathwayCaseStepActivities: (
    { __typename?: 'ActivitiesPayload' }
    & Pick<ActivitiesPayload, 'success'>
    & { activities: Array<(
      { __typename?: 'Activity' }
      & ActivityFragment
    )> }
  ) }
);

export type OnTriggerActivationCreatedSubscriptionVariables = Exact<{
  pathway_case_id: Scalars['String'];
}>;


export type OnTriggerActivationCreatedSubscription = (
  { __typename?: 'Subscription' }
  & { triggerActivationCreated: (
    { __typename?: 'TriggerActivation' }
    & Pick<TriggerActivation, 'status' | 'type' | 'id'>
    & { track: (
      { __typename?: 'Track' }
      & Pick<Track, 'id' | 'title'>
    ), trigger: (
      { __typename?: 'Trigger' }
      & TriggerFragment
    ) }
  ) }
);

export type OnTriggerActivationUpdatedSubscriptionVariables = Exact<{
  pathway_case_id: Scalars['String'];
}>;


export type OnTriggerActivationUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { triggerActivationUpdated: (
    { __typename?: 'TriggerActivation' }
    & Pick<TriggerActivation, 'status' | 'type' | 'id'>
    & { track: (
      { __typename?: 'Track' }
      & Pick<Track, 'id' | 'title'>
    ), trigger: (
      { __typename?: 'Trigger' }
      & TriggerFragment
    ) }
  ) }
);

export type PathwayCaseTriggerActivationsQueryVariables = Exact<{
  pathway_case_id: Scalars['String'];
}>;


export type PathwayCaseTriggerActivationsQuery = (
  { __typename?: 'Query' }
  & { pathwayCaseTriggerActivations: (
    { __typename?: 'TriggerActivationsPayload' }
    & Pick<TriggerActivationsPayload, 'success'>
    & { trigger_activations: Array<(
      { __typename?: 'TriggerActivation' }
      & Pick<TriggerActivation, 'id' | 'status' | 'type'>
      & { trigger: (
        { __typename?: 'Trigger' }
        & TriggerFragment
      ), track: (
        { __typename?: 'Track' }
        & Pick<Track, 'id' | 'title'>
      ) }
    )> }
  ) }
);

export type CreatePathwayCaseMutationVariables = Exact<{
  input: CreatePathwayCaseInput;
}>;


export type CreatePathwayCaseMutation = (
  { __typename?: 'Mutation' }
  & { createPathwayCase: (
    { __typename?: 'CreatePathwayCasePayload' }
    & Pick<CreatePathwayCasePayload, 'success'>
    & { pathway_case: (
      { __typename?: 'PathwayCase' }
      & PathwayCaseListItemFragment
    ) }
  ) }
);

export type DeletePathwayCaseMutationVariables = Exact<{
  input: DeletePathwayCaseInput;
}>;


export type DeletePathwayCaseMutation = (
  { __typename?: 'Mutation' }
  & { deletePathwayCase: (
    { __typename?: 'DeletePathwayCasePayload' }
    & Pick<DeletePathwayCasePayload, 'success'>
  ) }
);

export type GetPathwayCasesQueryVariables = Exact<{
  input: PathwayCasesInput;
}>;


export type GetPathwayCasesQuery = (
  { __typename?: 'Query' }
  & { pathway_cases: (
    { __typename?: 'PathwayCasesPayload' }
    & { pathway_cases: Array<(
      { __typename?: 'PathwayCase' }
      & PathwayCaseListItemFragment
    )> }
  ) }
);

export type PathwayCaseListItemFragment = (
  { __typename?: 'PathwayCase' }
  & Pick<PathwayCase, 'id' | 'title' | 'last_modification_date'>
  & { created_by: (
    { __typename?: 'PathwayCollaborator' }
    & Pick<PathwayCollaborator, 'user_email'>
  ), last_modified_by?: Maybe<(
    { __typename?: 'PathwayCollaborator' }
    & Pick<PathwayCollaborator, 'user_email'>
  )> }
);

export type PathwayCollaboratorQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
}>;


export type PathwayCollaboratorQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'UserPayload' }
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name'>
    ) }
  ) }
);

export type UpdatePathwayDashboardIdsMutationVariables = Exact<{
  input: SetDashboardIdsInput;
}>;


export type UpdatePathwayDashboardIdsMutation = (
  { __typename?: 'Mutation' }
  & { setDashboardIds: (
    { __typename?: 'SetDashboardIdsPayload' }
    & { settings: (
      { __typename?: 'PathwaySettings' }
      & PathwaySettingsFragment
    ) }
  ) }
);

export type PathwayDataPointQueryVariables = Exact<{
  input: DataPointDefinitionsInput;
}>;


export type PathwayDataPointQuery = (
  { __typename?: 'Query' }
  & { data_point_definitions: (
    { __typename?: 'DataPointDefinitionsPayload' }
    & { data_point_definitions: Array<(
      { __typename?: 'DataPointDefinition' }
      & DataPointDefinitionFragment
    )> }
  ) }
);

export type AddTriggerToPathwayMutationVariables = Exact<{
  input: AddTriggerToPathwayInput;
}>;


export type AddTriggerToPathwayMutation = (
  { __typename?: 'Mutation' }
  & { addTriggerToPathway: (
    { __typename?: 'AddTriggerToPathwayPayload' }
    & Pick<AddTriggerToPathwayPayload, 'success'>
    & { trigger: (
      { __typename?: 'Trigger' }
      & TriggerFragment
    ) }
  ) }
);

export type GetPathwayExtensionsQueryVariables = Exact<{
  pathwayId: Scalars['String'];
}>;


export type GetPathwayExtensionsQuery = (
  { __typename?: 'Query' }
  & { pathwayExtensions: (
    { __typename?: 'ExtensionsPayload' }
    & { extensions: Array<(
      { __typename?: 'Extension' }
      & Pick<Extension, 'key' | 'title' | 'description' | 'icon_url'>
      & { actions: Array<(
        { __typename?: 'ExtensionActionBlueprint' }
        & Pick<ExtensionActionBlueprint, 'key' | 'title' | 'icon' | 'category'>
      )>, webhooks: Array<(
        { __typename?: 'ExtensionWebhook' }
        & Pick<ExtensionWebhook, 'id' | 'endpoint_url' | 'webhook_key'>
        & { data_point_definitions: Array<(
          { __typename?: 'DataPointDefinition' }
          & DataPointDefinitionFragment
        )> }
      )> }
    )> }
  ), extensions: (
    { __typename?: 'ExtensionsPayload' }
    & { extensions: Array<(
      { __typename?: 'Extension' }
      & Pick<Extension, 'key' | 'title' | 'icon_url' | 'category'>
      & { settings?: Maybe<(
        { __typename?: 'ExtensionSettings' }
        & Pick<ExtensionSettings, 'description'>
        & { fields: Array<(
          { __typename?: 'ExtensionSettingsField' }
          & Pick<ExtensionSettingsField, 'key' | 'label' | 'obfuscated' | 'required' | 'description'>
        )> }
      )>, actions: Array<(
        { __typename?: 'ExtensionActionBlueprint' }
        & Pick<ExtensionActionBlueprint, 'key' | 'title' | 'icon' | 'category'>
      )>, available_webhooks?: Maybe<Array<(
        { __typename?: 'AvailableWebhook' }
        & Pick<AvailableWebhook, 'key' | 'description'>
      )>> }
    )> }
  ), pathway: (
    { __typename?: 'PathwayPayload' }
    & { pathway: (
      { __typename?: 'Pathway' }
      & { triggers: Array<(
        { __typename?: 'Trigger' }
        & TriggerFragment
      )> }
    ) }
  ) }
);

export type RemoveTriggerFromPathwayMutationVariables = Exact<{
  input: RemoveTriggerFromPathwayInput;
}>;


export type RemoveTriggerFromPathwayMutation = (
  { __typename?: 'Mutation' }
  & { removeTriggerFromPathway: (
    { __typename?: 'EmptyPayload' }
    & Pick<EmptyPayload, 'success'>
  ) }
);

export type SetPathwayTitleMutationVariables = Exact<{
  input: SetPathwayTitleInput;
}>;


export type SetPathwayTitleMutation = (
  { __typename?: 'Mutation' }
  & { setPathwayTitle: (
    { __typename?: 'SetPathwayTitlePayload' }
    & { pathway: (
      { __typename?: 'Pathway' }
      & Pick<Pathway, 'id' | 'title'>
    ) }
  ) }
);

export type GetPathwayNavigationInfoQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetPathwayNavigationInfoQuery = (
  { __typename?: 'Query' }
  & { pathway: (
    { __typename?: 'PathwayPayload' }
    & { pathway: (
      { __typename?: 'Pathway' }
      & Pick<Pathway, 'id' | 'title' | 'readonly'>
    ) }
  ) }
);

export type UpdateEmailNotificationStatusMutationVariables = Exact<{
  input: UpdateEmailNotificationStatusInput;
}>;


export type UpdateEmailNotificationStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateEmailNotificationStatus: (
    { __typename?: 'UpdateEmailNotificationStatusPayload' }
    & { settings: (
      { __typename?: 'PathwaySettings' }
      & PathwaySettingsFragment
    ) }
  ) }
);

export type UpdatePathwayReminderAmountMutationVariables = Exact<{
  input: SetRemindersAmountInput;
}>;


export type UpdatePathwayReminderAmountMutation = (
  { __typename?: 'Mutation' }
  & { setRemindersAmount: (
    { __typename?: 'SetRemindersAmountPayload' }
    & { settings: (
      { __typename?: 'PathwaySettings' }
      & PathwaySettingsFragment
    ) }
  ) }
);

export type UpdatePathwayReminderDelayMutationVariables = Exact<{
  input: SetRemindersDelayInput;
}>;


export type UpdatePathwayReminderDelayMutation = (
  { __typename?: 'Mutation' }
  & { setRemindersDelay: (
    { __typename?: 'SetRemindersDelayPayload' }
    & { settings: (
      { __typename?: 'PathwaySettings' }
      & PathwaySettingsFragment
    ) }
  ) }
);

export type PathwaySettingsQueryVariables = Exact<{
  definition_id: Scalars['String'];
}>;


export type PathwaySettingsQuery = (
  { __typename?: 'Query' }
  & { pathwaySettings: (
    { __typename?: 'PathwaySettingsPayload' }
    & { settings: (
      { __typename?: 'PathwaySettings' }
      & PathwaySettingsFragment
    ) }
  ) }
);

export type PathwaySettingsFragment = (
  { __typename?: 'PathwaySettings' }
  & Pick<PathwaySettings, 'id' | 'stakeholder_notification_language' | 'dashboard_ids' | 'allow_email_notifications'>
  & { reminders?: Maybe<(
    { __typename?: 'RemindersSettings' }
    & Pick<RemindersSettings, 'amount'>
    & { delay: (
      { __typename?: 'Delay' }
      & Pick<Delay, 'unit' | 'amount'>
    ) }
  )>, stakeholders?: Maybe<Array<(
    { __typename?: 'StakeholderEmail' }
    & Pick<StakeholderEmail, 'definition_id' | 'email'>
  )>>, branding?: Maybe<(
    { __typename?: 'BrandingSettings' }
    & Pick<BrandingSettings, 'accent_color' | 'logo_url' | 'hosted_page_title' | 'hosted_page_auto_progress' | 'hosted_page_autosave'>
    & { custom_theme?: Maybe<(
      { __typename?: 'FormattedJson' }
      & Pick<FormattedJson, 'plaintext' | 'slate'>
    )> }
  )>, api_call_retry_settings?: Maybe<(
    { __typename?: 'ApiCallRetrySettings' }
    & Pick<ApiCallRetrySettings, 'enabled' | 'initial_delay_ms' | 'max_attempts' | 'max_delay_ms'>
  )>, source_control?: Maybe<(
    { __typename?: 'SourceControlSettings' }
    & Pick<SourceControlSettings, 'repo_url' | 'access_token' | 'branch_name'>
  )> }
);

export type PathwayStakeholdersQueryVariables = Exact<{
  pathway_id: Scalars['String'];
}>;


export type PathwayStakeholdersQuery = (
  { __typename?: 'Query' }
  & { pathway: (
    { __typename?: 'PathwayPayload' }
    & { pathway: (
      { __typename?: 'Pathway' }
      & Pick<Pathway, 'id'>
      & { stakeholders: Array<(
        { __typename?: 'Stakeholder' }
        & Pick<Stakeholder, 'id' | 'clinical_app_role'>
        & { label: (
          { __typename?: 'StakeholderLabel' }
          & Pick<StakeholderLabel, 'en'>
        ) }
      )> }
    ) }
  ) }
);

export type SetStakeholderEmailsMutationVariables = Exact<{
  input: SetStakeholderEmailsInput;
}>;


export type SetStakeholderEmailsMutation = (
  { __typename?: 'Mutation' }
  & { setStakeholderEmails: (
    { __typename?: 'SetStakeholderEmailsPayload' }
    & { settings: (
      { __typename?: 'PathwaySettings' }
      & Pick<PathwaySettings, 'id' | 'stakeholder_notification_language' | 'dashboard_ids'>
      & { reminders?: Maybe<(
        { __typename?: 'RemindersSettings' }
        & Pick<RemindersSettings, 'amount'>
        & { delay: (
          { __typename?: 'Delay' }
          & Pick<Delay, 'amount' | 'unit'>
        ) }
      )>, stakeholders?: Maybe<Array<(
        { __typename?: 'StakeholderEmail' }
        & Pick<StakeholderEmail, 'definition_id' | 'email'>
      )>> }
    ) }
  ) }
);

export type AddTrackToPathwayMutationVariables = Exact<{
  input: AddTrackToPathwayInput;
}>;


export type AddTrackToPathwayMutation = (
  { __typename?: 'Mutation' }
  & { addTrackToPathway: (
    { __typename?: 'AddTrackToPathwayPayload' }
    & Pick<AddTrackToPathwayPayload, 'success'>
    & { track: (
      { __typename?: 'Track' }
      & TrackFragment
    ) }
  ) }
);

export type AddTriggerToTrackMutationVariables = Exact<{
  input: AddTriggerToTrackInput;
}>;


export type AddTriggerToTrackMutation = (
  { __typename?: 'Mutation' }
  & { addTriggerToTrack: (
    { __typename?: 'AddTriggerToTrackPayload' }
    & Pick<AddTriggerToTrackPayload, 'success'>
    & { trigger: (
      { __typename?: 'Trigger' }
      & TriggerFragment
    ) }
  ) }
);

export type GetTracksQueryVariables = Exact<{
  pathwayId: Scalars['String'];
}>;


export type GetTracksQuery = (
  { __typename?: 'Query' }
  & { pathway: (
    { __typename?: 'PathwayPayload' }
    & { pathway: (
      { __typename?: 'Pathway' }
      & Pick<Pathway, 'id'>
      & { tracks?: Maybe<Array<(
        { __typename?: 'Track' }
        & TrackFragment
      )>> }
    ) }
  ) }
);

export type RemoveTrackFromPathwayMutationVariables = Exact<{
  input: RemoveTrackFromPathwayInput;
}>;


export type RemoveTrackFromPathwayMutation = (
  { __typename?: 'Mutation' }
  & { removeTrackFromPathway: (
    { __typename?: 'RemoveTrackFromPathwayPayload' }
    & Pick<RemoveTrackFromPathwayPayload, 'success'>
    & { pathway: (
      { __typename?: 'Pathway' }
      & Pick<Pathway, 'id'>
      & { tracks?: Maybe<Array<(
        { __typename?: 'Track' }
        & TrackFragment
      )>> }
    ) }
  ) }
);

export type RemoveTriggerFromTrackMutationVariables = Exact<{
  input: RemoveTriggerFromTrackInput;
}>;


export type RemoveTriggerFromTrackMutation = (
  { __typename?: 'Mutation' }
  & { removeTriggerFromTrack: (
    { __typename?: 'EmptyPayload' }
    & Pick<EmptyPayload, 'success'>
  ) }
);

export type SetTrackTitleMutationVariables = Exact<{
  input: SetTrackTitleInput;
}>;


export type SetTrackTitleMutation = (
  { __typename?: 'Mutation' }
  & { setTrackTitle: (
    { __typename?: 'SetTrackTitlePayload' }
    & Pick<SetTrackTitlePayload, 'success'>
    & { track: (
      { __typename?: 'Track' }
      & TrackFragment
    ) }
  ) }
);

export type TrackFragment = (
  { __typename?: 'Track' }
  & Pick<Track, 'id' | 'title'>
  & { triggers: Array<(
    { __typename?: 'Trigger' }
    & TriggerFragment
  )>, steps: Array<(
    { __typename?: 'Step' }
    & Pick<Step, 'id' | 'title'>
    & { transitions: Array<(
      { __typename?: 'Transition' }
      & Pick<Transition, 'id'>
    )> }
  )> }
);

export type IsPathwayReadyForPreviewQueryVariables = Exact<{
  pathway_definition_id: Scalars['String'];
}>;


export type IsPathwayReadyForPreviewQuery = (
  { __typename?: 'Query' }
  & { isPathwayReadyForPreview: (
    { __typename?: 'PathwayValidationStatusPayload' }
    & { status: (
      { __typename?: 'PathwayValidationStatus' }
      & Pick<PathwayValidationStatus, 'status'>
      & { components: Array<(
        { __typename?: 'PathwayValidationStatusComponent' }
        & Pick<PathwayValidationStatusComponent, 'id' | 'type' | 'status'>
        & { reason?: Maybe<(
          { __typename?: 'InvalidStatusReason' }
          & Pick<InvalidStatusReason, 'type' | 'error'>
        )>, link?: Maybe<(
          { __typename?: 'PathwayComponentLink' }
          & Pick<PathwayComponentLink, 'url' | 'text'>
        )> }
      )> }
    ) }
  ) }
);

export type OnRebuildGraphStatusUpdatedSubscriptionVariables = Exact<{
  pathway_definition_id: Scalars['String'];
}>;


export type OnRebuildGraphStatusUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { rebuildGraphStatusUpdated: (
    { __typename?: 'RebuildGraphStatus' }
    & Pick<RebuildGraphStatus, 'event_count' | 'replayed_count'>
  ) }
);

export type RebuildGraphMutationVariables = Exact<{
  pathway_definition_id: Scalars['String'];
}>;


export type RebuildGraphMutation = (
  { __typename?: 'Mutation' }
  & { rebuildGraph: (
    { __typename?: 'EmptyPayload' }
    & Pick<EmptyPayload, 'success'>
  ) }
);

export type GetPreviewQueryVariables = Exact<{
  pathwayCaseId: Scalars['String'];
}>;


export type GetPreviewQuery = (
  { __typename?: 'Query' }
  & { pathway_case: (
    { __typename?: 'PathwayCasePayload' }
    & { pathway_case: (
      { __typename?: 'PathwayCase' }
      & PathwayCaseFragment
    ) }
  ) }
);

export type OnPreviewUpdatedSubscriptionVariables = Exact<{
  pathwayCaseId: Scalars['ID'];
}>;


export type OnPreviewUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { previewUpdated: (
    { __typename?: 'PathwayCase' }
    & PathwayCaseFragment
  ) }
);

export type PathwayCaseFragment = (
  { __typename?: 'PathwayCase' }
  & Pick<PathwayCase, 'id' | 'pathway_id' | 'title' | 'status'>
  & { activities: Array<(
    { __typename?: 'Activity' }
    & ActivityFragment
  )> }
);

export type ResetPreviewMutationVariables = Exact<{
  input: ResetPreviewInput;
}>;


export type ResetPreviewMutation = (
  { __typename?: 'Mutation' }
  & { resetPreview: (
    { __typename?: 'ResetPreviewPayload' }
    & Pick<ResetPreviewPayload, 'success'>
  ) }
);

export type StartPreviewMutationVariables = Exact<{
  input: StartPreviewInput;
}>;


export type StartPreviewMutation = (
  { __typename?: 'Mutation' }
  & { startPreview: (
    { __typename?: 'StartPreviewPayload' }
    & Pick<StartPreviewPayload, 'success'>
  ) }
);

export type PublishPathwayMutationVariables = Exact<{
  input: PublishPathwayInput;
}>;


export type PublishPathwayMutation = (
  { __typename?: 'Mutation' }
  & { publishPathway: (
    { __typename?: 'PublishPathwayPayload' }
    & { published_version: (
      { __typename?: 'PathwayVersion' }
      & PathwayVersionFragment
    ) }
  ) }
);

export type RefreshExtensionMutationVariables = Exact<{
  input: RefreshExtensionInput;
}>;


export type RefreshExtensionMutation = (
  { __typename?: 'Mutation' }
  & { refreshExtension: (
    { __typename?: 'RefreshExtensionPayload' }
    & { not_supported_actions?: Maybe<Array<(
      { __typename?: 'NotSupportedExtensionAction' }
      & { action: (
        { __typename?: 'UsageContext' }
        & Pick<UsageContext, 'id' | 'title'>
      ), step: (
        { __typename?: 'UsageContext' }
        & Pick<UsageContext, 'id' | 'title'>
      ), track: (
        { __typename?: 'UsageContext' }
        & Pick<UsageContext, 'id' | 'title'>
      ) }
    )>> }
  ) }
);

export type RemoveDataPointDefinitionFromPathwayMutationVariables = Exact<{
  input: RemoveDataPointDefinitionFromPathwayInput;
}>;


export type RemoveDataPointDefinitionFromPathwayMutation = (
  { __typename?: 'Mutation' }
  & { removeDataPointDefinitionFromPathway: (
    { __typename?: 'EmptyPayload' }
    & Pick<EmptyPayload, 'success'>
  ) }
);

export type RemoveConstantMutationVariables = Exact<{
  input: RemoveConstantInput;
}>;


export type RemoveConstantMutation = (
  { __typename?: 'Mutation' }
  & { removeConstant: (
    { __typename?: 'RemoveConstantPayload' }
    & Pick<RemoveConstantPayload, 'success'>
    & { pathway: (
      { __typename?: 'Pathway' }
      & Pick<Pathway, 'id'>
      & { settings?: Maybe<(
        { __typename?: 'PathwaySettings' }
        & { constants?: Maybe<Array<(
          { __typename?: 'Constant' }
          & Pick<Constant, 'id' | 'label' | 'value' | 'obfuscated'>
        )>> }
      )> }
    ) }
  ) }
);

export type RemoveDynamicVariableMutationVariables = Exact<{
  input: RemoveDynamicVariableInput;
}>;


export type RemoveDynamicVariableMutation = (
  { __typename?: 'Mutation' }
  & { removeDynamicVariable: (
    { __typename?: 'EmptyPayload' }
    & Pick<EmptyPayload, 'success'>
  ) }
);

export type RemoveStepFromLibraryMutationVariables = Exact<{
  input: RemoveStepFromLibraryInput;
}>;


export type RemoveStepFromLibraryMutation = (
  { __typename?: 'Mutation' }
  & { removeStepFromLibrary: (
    { __typename?: 'EmptyPayload' }
    & Pick<EmptyPayload, 'success'>
  ) }
);

export type RemoveWebhookMutationVariables = Exact<{
  input: RemoveWebhookInput;
}>;


export type RemoveWebhookMutation = (
  { __typename?: 'Mutation' }
  & { removeWebhook: (
    { __typename?: 'RemoveWebhookPayload' }
    & Pick<RemoveWebhookPayload, 'success'>
    & { pathway: (
      { __typename?: 'Pathway' }
      & { settings?: Maybe<(
        { __typename?: 'PathwaySettings' }
        & { webhooks?: Maybe<Array<(
          { __typename?: 'Webhook' }
          & Pick<Webhook, 'id' | 'enabled' | 'name'>
        )>> }
      )> }
    ) }
  ) }
);

export type RetryAllFailedWebhooksMutationVariables = Exact<{
  input: RetryAllFailedWebhookCallsInput;
}>;


export type RetryAllFailedWebhooksMutation = (
  { __typename?: 'Mutation' }
  & { retryAllFailedWebhookCalls: (
    { __typename?: 'EmptyPayload' }
    & Pick<EmptyPayload, 'success'>
  ) }
);

export type RetryAllWebhookCallsMutationVariables = Exact<{
  input: RetryAllWebhookCallsInput;
}>;


export type RetryAllWebhookCallsMutation = (
  { __typename?: 'Mutation' }
  & { retryAllWebhookCalls: (
    { __typename?: 'EmptyPayload' }
    & Pick<EmptyPayload, 'success'>
  ) }
);

export type RetryApiCallMutationVariables = Exact<{
  input: RetryApiCallInput;
}>;


export type RetryApiCallMutation = (
  { __typename?: 'Mutation' }
  & { retryApiCall: (
    { __typename?: 'EmptyPayload' }
    & Pick<EmptyPayload, 'success' | 'code'>
  ) }
);

export type RetryWebhookCallMutationVariables = Exact<{
  input: RetryWebhookCallInput;
}>;


export type RetryWebhookCallMutation = (
  { __typename?: 'Mutation' }
  & { retryWebhookCall: (
    { __typename?: 'RetryWebhookCallPayload' }
    & Pick<RetryWebhookCallPayload, 'success' | 'code'>
  ) }
);

export type SetCustomActionFieldMutationVariables = Exact<{
  input: SetCustomActionFieldInput;
}>;


export type SetCustomActionFieldMutation = (
  { __typename?: 'Mutation' }
  & { setCustomActionField: (
    { __typename?: 'SetCustomActionFieldPayload' }
    & { action: (
      { __typename?: 'ApiCallAction' }
      & Pick<ApiCallAction, 'id'>
    ) | (
      { __typename?: 'ApiCallGraphqlAction' }
      & Pick<ApiCallGraphqlAction, 'id'>
    ) | (
      { __typename?: 'CalculationAction' }
      & Pick<CalculationAction, 'id'>
    ) | (
      { __typename?: 'ChecklistAction' }
      & Pick<ChecklistAction, 'id'>
    ) | (
      { __typename?: 'ClinicalNoteAction' }
      & Pick<ClinicalNoteAction, 'id'>
    ) | (
      { __typename?: 'ExtensionAction' }
      & Pick<ExtensionAction, 'id'>
    ) | (
      { __typename?: 'FormAction' }
      & Pick<FormAction, 'id'>
    ) | (
      { __typename?: 'MessageAction' }
      & Pick<MessageAction, 'id'>
    ) | (
      { __typename?: 'PushToEmrAction' }
      & Pick<PushToEmrAction, 'id'>
      & { custom_fields?: Maybe<Array<(
        { __typename?: 'CustomActionField' }
        & Pick<CustomActionField, 'id' | 'value'>
      )>> }
    ) }
  ) }
);

export type SetDataPointDefinitionOptionalMutationVariables = Exact<{
  input: SetDataPointDefinitionOptionalFieldInput;
}>;


export type SetDataPointDefinitionOptionalMutation = (
  { __typename?: 'Mutation' }
  & { setDataPointDefinitionOptionalField: (
    { __typename?: 'SetDataPointDefinitionOptionalFieldPayload' }
    & Pick<SetDataPointDefinitionOptionalFieldPayload, 'success'>
    & { data_point_definition: (
      { __typename?: 'DataPointDefinition' }
      & Pick<DataPointDefinition, 'id' | 'optional'>
    ) }
  ) }
);

export type SetTransitionDestinationMutationVariables = Exact<{
  input: SetTransitionDestinationInput;
}>;


export type SetTransitionDestinationMutation = (
  { __typename?: 'Mutation' }
  & { setTransitionDestination: (
    { __typename?: 'SetTransitionDestinationPayload' }
    & { transition: (
      { __typename?: 'Transition' }
      & CanvasTransitionFragment
    ) }
  ) }
);

export type SetExtensionActionFieldMutationVariables = Exact<{
  input: SetExtensionActionFieldInput;
}>;


export type SetExtensionActionFieldMutation = (
  { __typename?: 'Mutation' }
  & { setExtensionActionField: (
    { __typename?: 'SetExtensionActionFieldPayload' }
    & { action: (
      { __typename?: 'ExtensionAction' }
      & Pick<ExtensionAction, 'id'>
      & { extension_fields: Array<(
        { __typename?: 'BooleanField' }
        & Pick<BooleanField, 'value' | 'id'>
      ) | (
        { __typename?: 'DateField' }
        & Pick<DateField, 'value' | 'id'>
        & { delay?: Maybe<(
          { __typename?: 'Delay' }
          & Pick<Delay, 'amount' | 'unit'>
        )> }
      ) | (
        { __typename?: 'HtmlField' }
        & Pick<HtmlField, 'value' | 'slate' | 'id'>
      ) | (
        { __typename?: 'JsonField' }
        & Pick<JsonField, 'value' | 'slate' | 'id'>
      ) | (
        { __typename?: 'NumericArrayField' }
        & Pick<NumericArrayField, 'value' | 'id'>
      ) | (
        { __typename?: 'NumericField' }
        & Pick<NumericField, 'value' | 'id'>
      ) | (
        { __typename?: 'StringArrayField' }
        & Pick<StringArrayField, 'value' | 'id'>
      ) | (
        { __typename?: 'StringField' }
        & Pick<StringField, 'value' | 'id'>
      ) | (
        { __typename?: 'TextField' }
        & Pick<TextField, 'value' | 'slate' | 'id'>
      )>, status: (
        { __typename?: 'ConfigurationStatus' }
        & ConfigurationStatusFragment
      ) }
    ) }
  ) }
);

export type SetStepTitleMutationVariables = Exact<{
  input: SetStepTitleInput;
}>;


export type SetStepTitleMutation = (
  { __typename?: 'Mutation' }
  & { setStepTitle: (
    { __typename?: 'SetStepTitlePayload' }
    & Pick<SetStepTitlePayload, 'success' | 'code'>
    & { step: (
      { __typename?: 'Step' }
      & Pick<Step, 'id' | 'title'>
    ) }
  ) }
);

export type StakeholdersQueryVariables = Exact<{ [key: string]: never; }>;


export type StakeholdersQuery = (
  { __typename?: 'Query' }
  & { stakeholders: (
    { __typename?: 'StakeholdersPayload' }
    & { stakeholders: Array<(
      { __typename?: 'Stakeholder' }
      & Pick<Stakeholder, 'id' | 'clinical_app_role'>
      & { label: (
        { __typename?: 'StakeholderLabel' }
        & Pick<StakeholderLabel, 'en'>
      ) }
    )> }
  ) }
);

export type AddTrackMutationVariables = Exact<{
  input: AddTrackInput;
}>;


export type AddTrackMutation = (
  { __typename?: 'Mutation' }
  & { addTrack: (
    { __typename?: 'EmptyPayload' }
    & Pick<EmptyPayload, 'success'>
  ) }
);

export type GetAdHocTracksQueryVariables = Exact<{
  pathway_definition_id: Scalars['String'];
}>;


export type GetAdHocTracksQuery = (
  { __typename?: 'Query' }
  & { adHocTracks: (
    { __typename?: 'TracksPayload' }
    & { tracks: Array<(
      { __typename?: 'Track' }
      & Pick<Track, 'id' | 'title'>
    )> }
  ) }
);

export type GetStepDocumentationQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetStepDocumentationQuery = (
  { __typename?: 'Query' }
  & { step: (
    { __typename?: 'StepPayload' }
    & Pick<StepPayload, 'success' | 'code'>
    & { step: (
      { __typename?: 'Step' }
      & Pick<Step, 'id'>
      & { documentation?: Maybe<(
        { __typename?: 'FormattedText' }
        & { content: (
          { __typename?: 'TranslatedText' }
          & Pick<TranslatedText, 'en'>
        ) }
      )> }
    ) }
  ) }
);

export type StepLibraryQueryVariables = Exact<{ [key: string]: never; }>;


export type StepLibraryQuery = (
  { __typename?: 'Query' }
  & { stepLibrary: (
    { __typename?: 'StepLibraryPayload' }
    & { steps: Array<(
      { __typename?: 'Step' }
      & Pick<Step, 'id' | 'title'>
      & { actions?: Maybe<Array<(
        { __typename?: 'ApiCallAction' }
        & Pick<ApiCallAction, 'id'>
        & { locations?: Maybe<Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'label' | 'id'>
        )>>, stakeholders: Array<(
          { __typename?: 'Stakeholder' }
          & Pick<Stakeholder, 'id'>
          & { label: (
            { __typename?: 'StakeholderLabel' }
            & Pick<StakeholderLabel, 'en'>
          ) }
        )> }
      ) | (
        { __typename?: 'ApiCallGraphqlAction' }
        & Pick<ApiCallGraphqlAction, 'id'>
        & { locations?: Maybe<Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'label' | 'id'>
        )>>, stakeholders: Array<(
          { __typename?: 'Stakeholder' }
          & Pick<Stakeholder, 'id'>
          & { label: (
            { __typename?: 'StakeholderLabel' }
            & Pick<StakeholderLabel, 'en'>
          ) }
        )> }
      ) | (
        { __typename?: 'CalculationAction' }
        & Pick<CalculationAction, 'id'>
        & { locations?: Maybe<Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'label' | 'id'>
        )>>, stakeholders: Array<(
          { __typename?: 'Stakeholder' }
          & Pick<Stakeholder, 'id'>
          & { label: (
            { __typename?: 'StakeholderLabel' }
            & Pick<StakeholderLabel, 'en'>
          ) }
        )> }
      ) | (
        { __typename?: 'ChecklistAction' }
        & Pick<ChecklistAction, 'id'>
        & { locations?: Maybe<Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'label' | 'id'>
        )>>, stakeholders: Array<(
          { __typename?: 'Stakeholder' }
          & Pick<Stakeholder, 'id'>
          & { label: (
            { __typename?: 'StakeholderLabel' }
            & Pick<StakeholderLabel, 'en'>
          ) }
        )> }
      ) | (
        { __typename?: 'ClinicalNoteAction' }
        & Pick<ClinicalNoteAction, 'id'>
        & { locations?: Maybe<Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'label' | 'id'>
        )>>, stakeholders: Array<(
          { __typename?: 'Stakeholder' }
          & Pick<Stakeholder, 'id'>
          & { label: (
            { __typename?: 'StakeholderLabel' }
            & Pick<StakeholderLabel, 'en'>
          ) }
        )> }
      ) | (
        { __typename?: 'ExtensionAction' }
        & Pick<ExtensionAction, 'id'>
        & { locations?: Maybe<Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'label' | 'id'>
        )>>, stakeholders: Array<(
          { __typename?: 'Stakeholder' }
          & Pick<Stakeholder, 'id'>
          & { label: (
            { __typename?: 'StakeholderLabel' }
            & Pick<StakeholderLabel, 'en'>
          ) }
        )> }
      ) | (
        { __typename?: 'FormAction' }
        & Pick<FormAction, 'id'>
        & { locations?: Maybe<Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'label' | 'id'>
        )>>, stakeholders: Array<(
          { __typename?: 'Stakeholder' }
          & Pick<Stakeholder, 'id'>
          & { label: (
            { __typename?: 'StakeholderLabel' }
            & Pick<StakeholderLabel, 'en'>
          ) }
        )> }
      ) | (
        { __typename?: 'MessageAction' }
        & Pick<MessageAction, 'id'>
        & { locations?: Maybe<Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'label' | 'id'>
        )>>, stakeholders: Array<(
          { __typename?: 'Stakeholder' }
          & Pick<Stakeholder, 'id'>
          & { label: (
            { __typename?: 'StakeholderLabel' }
            & Pick<StakeholderLabel, 'en'>
          ) }
        )> }
      ) | (
        { __typename?: 'PushToEmrAction' }
        & Pick<PushToEmrAction, 'id'>
        & { locations?: Maybe<Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'label' | 'id'>
        )>>, stakeholders: Array<(
          { __typename?: 'Stakeholder' }
          & Pick<Stakeholder, 'id'>
          & { label: (
            { __typename?: 'StakeholderLabel' }
            & Pick<StakeholderLabel, 'en'>
          ) }
        )> }
      )>> }
    )> }
  ) }
);

export type StepLibraryForPathwayQueryVariables = Exact<{
  pathway_id: Scalars['String'];
}>;


export type StepLibraryForPathwayQuery = (
  { __typename?: 'Query' }
  & { stepLibraryForPathway: (
    { __typename?: 'StepLibraryPayload' }
    & { steps: Array<(
      { __typename?: 'Step' }
      & Pick<Step, 'id' | 'title'>
      & { actions?: Maybe<Array<(
        { __typename?: 'ApiCallAction' }
        & Pick<ApiCallAction, 'id'>
        & { locations?: Maybe<Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'label' | 'id'>
        )>>, stakeholders: Array<(
          { __typename?: 'Stakeholder' }
          & Pick<Stakeholder, 'id'>
          & { label: (
            { __typename?: 'StakeholderLabel' }
            & Pick<StakeholderLabel, 'en'>
          ) }
        )> }
      ) | (
        { __typename?: 'ApiCallGraphqlAction' }
        & Pick<ApiCallGraphqlAction, 'id'>
        & { locations?: Maybe<Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'label' | 'id'>
        )>>, stakeholders: Array<(
          { __typename?: 'Stakeholder' }
          & Pick<Stakeholder, 'id'>
          & { label: (
            { __typename?: 'StakeholderLabel' }
            & Pick<StakeholderLabel, 'en'>
          ) }
        )> }
      ) | (
        { __typename?: 'CalculationAction' }
        & Pick<CalculationAction, 'id'>
        & { locations?: Maybe<Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'label' | 'id'>
        )>>, stakeholders: Array<(
          { __typename?: 'Stakeholder' }
          & Pick<Stakeholder, 'id'>
          & { label: (
            { __typename?: 'StakeholderLabel' }
            & Pick<StakeholderLabel, 'en'>
          ) }
        )> }
      ) | (
        { __typename?: 'ChecklistAction' }
        & Pick<ChecklistAction, 'id'>
        & { locations?: Maybe<Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'label' | 'id'>
        )>>, stakeholders: Array<(
          { __typename?: 'Stakeholder' }
          & Pick<Stakeholder, 'id'>
          & { label: (
            { __typename?: 'StakeholderLabel' }
            & Pick<StakeholderLabel, 'en'>
          ) }
        )> }
      ) | (
        { __typename?: 'ClinicalNoteAction' }
        & Pick<ClinicalNoteAction, 'id'>
        & { locations?: Maybe<Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'label' | 'id'>
        )>>, stakeholders: Array<(
          { __typename?: 'Stakeholder' }
          & Pick<Stakeholder, 'id'>
          & { label: (
            { __typename?: 'StakeholderLabel' }
            & Pick<StakeholderLabel, 'en'>
          ) }
        )> }
      ) | (
        { __typename?: 'ExtensionAction' }
        & Pick<ExtensionAction, 'id'>
        & { locations?: Maybe<Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'label' | 'id'>
        )>>, stakeholders: Array<(
          { __typename?: 'Stakeholder' }
          & Pick<Stakeholder, 'id'>
          & { label: (
            { __typename?: 'StakeholderLabel' }
            & Pick<StakeholderLabel, 'en'>
          ) }
        )> }
      ) | (
        { __typename?: 'FormAction' }
        & Pick<FormAction, 'id'>
        & { locations?: Maybe<Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'label' | 'id'>
        )>>, stakeholders: Array<(
          { __typename?: 'Stakeholder' }
          & Pick<Stakeholder, 'id'>
          & { label: (
            { __typename?: 'StakeholderLabel' }
            & Pick<StakeholderLabel, 'en'>
          ) }
        )> }
      ) | (
        { __typename?: 'MessageAction' }
        & Pick<MessageAction, 'id'>
        & { locations?: Maybe<Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'label' | 'id'>
        )>>, stakeholders: Array<(
          { __typename?: 'Stakeholder' }
          & Pick<Stakeholder, 'id'>
          & { label: (
            { __typename?: 'StakeholderLabel' }
            & Pick<StakeholderLabel, 'en'>
          ) }
        )> }
      ) | (
        { __typename?: 'PushToEmrAction' }
        & Pick<PushToEmrAction, 'id'>
        & { locations?: Maybe<Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'label' | 'id'>
        )>>, stakeholders: Array<(
          { __typename?: 'Stakeholder' }
          & Pick<Stakeholder, 'id'>
          & { label: (
            { __typename?: 'StakeholderLabel' }
            & Pick<StakeholderLabel, 'en'>
          ) }
        )> }
      )>> }
    )> }
  ) }
);

export type GetStepWithLabelQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetStepWithLabelQuery = (
  { __typename?: 'Query' }
  & { step: (
    { __typename?: 'StepPayload' }
    & { step: (
      { __typename?: 'Step' }
      & Pick<Step, 'id' | 'title'>
      & { label?: Maybe<(
        { __typename?: 'Label' }
        & Pick<Label, 'id' | 'text' | 'color'>
      )> }
    ) }
  ) }
);

export type GetStepsWithLabelQueryVariables = Exact<{
  track_id: Scalars['String'];
}>;


export type GetStepsWithLabelQuery = (
  { __typename?: 'Query' }
  & { steps: (
    { __typename?: 'StepsPayload' }
    & Pick<StepsPayload, 'success' | 'code'>
    & { steps: Array<(
      { __typename?: 'Step' }
      & Pick<Step, 'id' | 'title'>
      & { label?: Maybe<(
        { __typename?: 'Label' }
        & Pick<Label, 'id' | 'text' | 'color'>
      )> }
    )> }
  ) }
);

export type TenantQueryVariables = Exact<{ [key: string]: never; }>;


export type TenantQuery = (
  { __typename?: 'Query' }
  & { tenant: (
    { __typename?: 'TenantPayload' }
    & Pick<TenantPayload, 'code' | 'success'>
    & { tenant: (
      { __typename?: 'Tenant' }
      & Pick<Tenant, 'id' | 'name' | 'public' | 'is_default' | 'logo_path' | 'accent_color' | 'hosted_page_title'>
      & { api_call_retry_settings?: Maybe<(
        { __typename?: 'ApiCallRetrySettings' }
        & Pick<ApiCallRetrySettings, 'enabled' | 'initial_delay_ms' | 'max_delay_ms' | 'max_attempts'>
      )> }
    ) }
  ) }
);

export type ExecuteTestApiCallMutationVariables = Exact<{
  input: ExecuteTestApiCallInput;
}>;


export type ExecuteTestApiCallMutation = (
  { __typename?: 'Mutation' }
  & { executeTestApiCall: (
    { __typename?: 'ExecuteTestApiCallPayload' }
    & Pick<ExecuteTestApiCallPayload, 'success'>
    & { request: (
      { __typename?: 'GraphqlTestApiCallRequest' }
      & Pick<GraphqlTestApiCallRequest, 'endpoint' | 'body' | 'method'>
      & { headers: Array<(
        { __typename?: 'ApiCallHeader' }
        & Pick<ApiCallHeader, 'key' | 'value'>
      )> }
    ), response: (
      { __typename?: 'GraphqlTestApiCallResponse' }
      & Pick<GraphqlTestApiCallResponse, 'status' | 'body' | 'date' | 'headers' | 'error'>
    ), mappings: Array<(
      { __typename?: 'GraphqlTestApiCallMapping' }
      & Pick<GraphqlTestApiCallMapping, 'data_point_definition_id' | 'response_key' | 'value' | 'value_type'>
    )>, metadata: (
      { __typename?: 'GraphqlTestApiCallMetadata' }
      & Pick<GraphqlTestApiCallMetadata, 'response_time' | 'response_size'>
    ) }
  ) }
);

export type TestCareFlowSourceControlSettingsMutationVariables = Exact<{
  input: TestSourceControlInput;
}>;


export type TestCareFlowSourceControlSettingsMutation = (
  { __typename?: 'Mutation' }
  & { testCareFlowSourceControlSettings: (
    { __typename: 'TestSourceControlPayload' }
    & Pick<TestSourceControlPayload, 'success'>
  ) }
);

export type AddStepFromLibraryToTrackMutationVariables = Exact<{
  input: AddStepFromLibraryToTrackInput;
}>;


export type AddStepFromLibraryToTrackMutation = (
  { __typename?: 'Mutation' }
  & { addStepFromLibraryToTrack: (
    { __typename?: 'AddStepFromLibraryToTrackPayload' }
    & Pick<AddStepFromLibraryToTrackPayload, 'success'>
    & { step: (
      { __typename?: 'Step' }
      & CanvasStepFragment
    ) }
  ) }
);

export type AddStepToTrackMutationVariables = Exact<{
  input: AddStepToTrackInput;
}>;


export type AddStepToTrackMutation = (
  { __typename?: 'Mutation' }
  & { addStepToTrack: (
    { __typename?: 'AddStepToTrackPayload' }
    & Pick<AddStepToTrackPayload, 'success'>
    & { step: (
      { __typename?: 'Step' }
      & CanvasStepFragment
    ) }
  ) }
);

export type AddStickyNoteToTrackMutationVariables = Exact<{
  input: AddStickyNoteToTrackInput;
}>;


export type AddStickyNoteToTrackMutation = (
  { __typename?: 'Mutation' }
  & { addStickyNoteToTrack: (
    { __typename?: 'AddStickyNoteToTrackPayload' }
    & Pick<AddStickyNoteToTrackPayload, 'success'>
    & { stickyNote: (
      { __typename?: 'StickyNote' }
      & CanvasStickyNoteFragment
    ) }
  ) }
);

export type CanvasStepFragment = (
  { __typename?: 'Step' }
  & Pick<Step, 'id' | 'title'>
  & { coordinates: (
    { __typename?: 'Coordinates' }
    & Pick<Coordinates, 'x' | 'y'>
  ), actions?: Maybe<Array<(
    { __typename?: 'ApiCallAction' }
    & Pick<ApiCallAction, 'type' | 'title' | 'id' | 'plugin_key'>
    & { locations?: Maybe<Array<(
      { __typename?: 'Location' }
      & Pick<Location, 'label' | 'id'>
    )>>, stakeholders: Array<(
      { __typename?: 'Stakeholder' }
      & Pick<Stakeholder, 'id'>
      & { label: (
        { __typename?: 'StakeholderLabel' }
        & Pick<StakeholderLabel, 'en'>
      ) }
    )>, status: (
      { __typename?: 'ConfigurationStatus' }
      & ConfigurationStatusFragment
    ) }
  ) | (
    { __typename?: 'ApiCallGraphqlAction' }
    & Pick<ApiCallGraphqlAction, 'type' | 'title' | 'id' | 'plugin_key'>
    & { locations?: Maybe<Array<(
      { __typename?: 'Location' }
      & Pick<Location, 'label' | 'id'>
    )>>, stakeholders: Array<(
      { __typename?: 'Stakeholder' }
      & Pick<Stakeholder, 'id'>
      & { label: (
        { __typename?: 'StakeholderLabel' }
        & Pick<StakeholderLabel, 'en'>
      ) }
    )>, status: (
      { __typename?: 'ConfigurationStatus' }
      & ConfigurationStatusFragment
    ) }
  ) | (
    { __typename?: 'CalculationAction' }
    & Pick<CalculationAction, 'type' | 'title' | 'id' | 'plugin_key'>
    & { locations?: Maybe<Array<(
      { __typename?: 'Location' }
      & Pick<Location, 'label' | 'id'>
    )>>, stakeholders: Array<(
      { __typename?: 'Stakeholder' }
      & Pick<Stakeholder, 'id'>
      & { label: (
        { __typename?: 'StakeholderLabel' }
        & Pick<StakeholderLabel, 'en'>
      ) }
    )>, status: (
      { __typename?: 'ConfigurationStatus' }
      & ConfigurationStatusFragment
    ) }
  ) | (
    { __typename?: 'ChecklistAction' }
    & Pick<ChecklistAction, 'type' | 'title' | 'id' | 'plugin_key'>
    & { locations?: Maybe<Array<(
      { __typename?: 'Location' }
      & Pick<Location, 'label' | 'id'>
    )>>, stakeholders: Array<(
      { __typename?: 'Stakeholder' }
      & Pick<Stakeholder, 'id'>
      & { label: (
        { __typename?: 'StakeholderLabel' }
        & Pick<StakeholderLabel, 'en'>
      ) }
    )>, status: (
      { __typename?: 'ConfigurationStatus' }
      & ConfigurationStatusFragment
    ) }
  ) | (
    { __typename?: 'ClinicalNoteAction' }
    & Pick<ClinicalNoteAction, 'clinical_note_id' | 'type' | 'title' | 'id' | 'plugin_key'>
    & { locations?: Maybe<Array<(
      { __typename?: 'Location' }
      & Pick<Location, 'label' | 'id'>
    )>>, stakeholders: Array<(
      { __typename?: 'Stakeholder' }
      & Pick<Stakeholder, 'id'>
      & { label: (
        { __typename?: 'StakeholderLabel' }
        & Pick<StakeholderLabel, 'en'>
      ) }
    )>, status: (
      { __typename?: 'ConfigurationStatus' }
      & ConfigurationStatusFragment
    ) }
  ) | (
    { __typename?: 'ExtensionAction' }
    & Pick<ExtensionAction, 'type' | 'title' | 'id' | 'plugin_key'>
    & { locations?: Maybe<Array<(
      { __typename?: 'Location' }
      & Pick<Location, 'label' | 'id'>
    )>>, stakeholders: Array<(
      { __typename?: 'Stakeholder' }
      & Pick<Stakeholder, 'id'>
      & { label: (
        { __typename?: 'StakeholderLabel' }
        & Pick<StakeholderLabel, 'en'>
      ) }
    )>, status: (
      { __typename?: 'ConfigurationStatus' }
      & ConfigurationStatusFragment
    ) }
  ) | (
    { __typename?: 'FormAction' }
    & Pick<FormAction, 'type' | 'title' | 'id' | 'plugin_key'>
    & { locations?: Maybe<Array<(
      { __typename?: 'Location' }
      & Pick<Location, 'label' | 'id'>
    )>>, stakeholders: Array<(
      { __typename?: 'Stakeholder' }
      & Pick<Stakeholder, 'id'>
      & { label: (
        { __typename?: 'StakeholderLabel' }
        & Pick<StakeholderLabel, 'en'>
      ) }
    )>, status: (
      { __typename?: 'ConfigurationStatus' }
      & ConfigurationStatusFragment
    ) }
  ) | (
    { __typename?: 'MessageAction' }
    & Pick<MessageAction, 'message_id' | 'type' | 'title' | 'id' | 'plugin_key'>
    & { locations?: Maybe<Array<(
      { __typename?: 'Location' }
      & Pick<Location, 'label' | 'id'>
    )>>, stakeholders: Array<(
      { __typename?: 'Stakeholder' }
      & Pick<Stakeholder, 'id'>
      & { label: (
        { __typename?: 'StakeholderLabel' }
        & Pick<StakeholderLabel, 'en'>
      ) }
    )>, status: (
      { __typename?: 'ConfigurationStatus' }
      & ConfigurationStatusFragment
    ) }
  ) | (
    { __typename?: 'PushToEmrAction' }
    & Pick<PushToEmrAction, 'emr_report_id' | 'type' | 'title' | 'id' | 'plugin_key'>
    & { locations?: Maybe<Array<(
      { __typename?: 'Location' }
      & Pick<Location, 'label' | 'id'>
    )>>, stakeholders: Array<(
      { __typename?: 'Stakeholder' }
      & Pick<Stakeholder, 'id'>
      & { label: (
        { __typename?: 'StakeholderLabel' }
        & Pick<StakeholderLabel, 'en'>
      ) }
    )>, status: (
      { __typename?: 'ConfigurationStatus' }
      & ConfigurationStatusFragment
    ) }
  )>>, documentation?: Maybe<(
    { __typename?: 'FormattedText' }
    & { content: (
      { __typename?: 'TranslatedText' }
      & Pick<TranslatedText, 'en'>
    ) }
  )>, label?: Maybe<(
    { __typename?: 'Label' }
    & Pick<Label, 'id' | 'color' | 'text'>
  )> }
);

export type CanvasStickyNoteFragment = (
  { __typename?: 'StickyNote' }
  & Pick<StickyNote, 'id'>
  & { body?: Maybe<(
    { __typename?: 'StickyNoteBody' }
    & Pick<StickyNoteBody, 'format' | 'text'>
  )>, coordinates: (
    { __typename?: 'Coordinates' }
    & Pick<Coordinates, 'x' | 'y'>
  ) }
);

export type CanvasTransitionFragment = (
  { __typename?: 'Transition' }
  & Pick<Transition, 'id' | 'default'>
  & { origin: (
    { __typename?: 'TransitionNode' }
    & Pick<TransitionNode, 'type' | 'node_id'>
  ), destination?: Maybe<(
    { __typename?: 'TransitionNode' }
    & Pick<TransitionNode, 'type' | 'node_id' | 'node_title'>
  )>, transitionOrientation: (
    { __typename?: 'TransitionOrientation' }
    & Pick<TransitionOrientation, 'origin_orientation' | 'destination_orientation'>
  ), timing?: Maybe<(
    { __typename?: 'Timing' }
    & Pick<Timing, 'id' | 'reference' | 'reference_key'>
    & { delay?: Maybe<(
      { __typename?: 'Delay' }
      & Pick<Delay, 'amount' | 'unit'>
    )> }
  )>, rule?: Maybe<(
    { __typename?: 'Rule' }
    & Pick<Rule, 'id' | 'boolean_operator'>
    & { conditions: Array<(
      { __typename?: 'Condition' }
      & Pick<Condition, 'id' | 'reference' | 'operator'>
      & { operand?: Maybe<(
        { __typename?: 'Operand' }
        & Pick<Operand, 'type' | 'value'>
      )> }
    )> }
  )> }
);

export type ConnectStepsMutationVariables = Exact<{
  input: ConnectStepsInput;
}>;


export type ConnectStepsMutation = (
  { __typename?: 'Mutation' }
  & { connectSteps: (
    { __typename?: 'ConnectStepsPayload' }
    & Pick<ConnectStepsPayload, 'success' | 'code'>
    & { origin_step: (
      { __typename?: 'Step' }
      & Pick<Step, 'id'>
      & { transitions: Array<(
        { __typename?: 'Transition' }
        & Pick<Transition, 'id'>
      )> }
    ), transition: (
      { __typename?: 'Transition' }
      & CanvasTransitionFragment
    ) }
  ) }
);

export type EndTrackAfterStepMutationVariables = Exact<{
  input: EndTrackAfterStepInput;
}>;


export type EndTrackAfterStepMutation = (
  { __typename?: 'Mutation' }
  & { endTrackAfterStep: (
    { __typename?: 'EndTrackAfterStepPayload' }
    & Pick<EndTrackAfterStepPayload, 'success' | 'code'>
    & { origin_step: (
      { __typename?: 'Step' }
      & Pick<Step, 'id'>
      & { transitions: Array<(
        { __typename?: 'Transition' }
        & Pick<Transition, 'id'>
      )> }
    ), transition: (
      { __typename?: 'Transition' }
      & CanvasTransitionFragment
    ) }
  ) }
);

export type SetStepCoordinatesMutationVariables = Exact<{
  input: SetStepCoordinatesInput;
}>;


export type SetStepCoordinatesMutation = (
  { __typename?: 'Mutation' }
  & { setStepCoordinates: (
    { __typename?: 'SetStepCoordinatesPayload' }
    & Pick<SetStepCoordinatesPayload, 'success' | 'code'>
    & { step: (
      { __typename?: 'Step' }
      & CanvasStepFragment
    ) }
  ) }
);

export type SetStickyNoteCoordinatesMutationVariables = Exact<{
  input: SetStickyNoteCoordinatesInput;
}>;


export type SetStickyNoteCoordinatesMutation = (
  { __typename?: 'Mutation' }
  & { setStickyNoteCoordinates: (
    { __typename?: 'SetStickyNoteCoordinatesPayload' }
    & Pick<SetStickyNoteCoordinatesPayload, 'success' | 'code'>
    & { stickyNote: (
      { __typename?: 'StickyNote' }
      & CanvasStickyNoteFragment
    ) }
  ) }
);

export type SetTrackEndCoordinatesMutationVariables = Exact<{
  input: SetTrackEndCoordinatesInput;
}>;


export type SetTrackEndCoordinatesMutation = (
  { __typename?: 'Mutation' }
  & { setTrackEndCoordinates: (
    { __typename?: 'SetTrackEndCoordinatesPayload' }
    & Pick<SetTrackEndCoordinatesPayload, 'success' | 'code'>
    & { track: (
      { __typename?: 'Track' }
      & Pick<Track, 'id'>
      & { end_coordinates: (
        { __typename?: 'Coordinates' }
        & Pick<Coordinates, 'x' | 'y'>
      ) }
    ) }
  ) }
);

export type SetTrackStartCoordinatesMutationVariables = Exact<{
  input: SetTrackStartCoordinatesInput;
}>;


export type SetTrackStartCoordinatesMutation = (
  { __typename?: 'Mutation' }
  & { setTrackStartCoordinates: (
    { __typename?: 'SetTrackStartCoordinatesPayload' }
    & Pick<SetTrackStartCoordinatesPayload, 'success' | 'code'>
    & { track: (
      { __typename?: 'Track' }
      & Pick<Track, 'id'>
      & { start_coordinates: (
        { __typename?: 'Coordinates' }
        & Pick<Coordinates, 'x' | 'y'>
      ) }
    ) }
  ) }
);

export type StartTrackFromStepMutationVariables = Exact<{
  input: StartTrackFromStepInput;
}>;


export type StartTrackFromStepMutation = (
  { __typename?: 'Mutation' }
  & { startTrackFromStep: (
    { __typename?: 'StartTrackFromStepPayload' }
    & Pick<StartTrackFromStepPayload, 'success' | 'code'>
    & { transition: (
      { __typename?: 'Transition' }
      & CanvasTransitionFragment
    ) }
  ) }
);

export type TrackCanvasQueryVariables = Exact<{
  track_id: Scalars['String'];
}>;


export type TrackCanvasQuery = (
  { __typename?: 'Query' }
  & { track: (
    { __typename?: 'TrackPayload' }
    & { track: (
      { __typename?: 'Track' }
      & Pick<Track, 'id' | 'title'>
      & { start_coordinates: (
        { __typename?: 'Coordinates' }
        & Pick<Coordinates, 'x' | 'y'>
      ), end_coordinates: (
        { __typename?: 'Coordinates' }
        & Pick<Coordinates, 'x' | 'y'>
      ), steps: Array<(
        { __typename?: 'Step' }
        & CanvasStepFragment
      )>, sticky_notes: Array<(
        { __typename?: 'StickyNote' }
        & CanvasStickyNoteFragment
      )>, transitions: Array<(
        { __typename?: 'Transition' }
        & CanvasTransitionFragment
      )> }
    ) }
  ) }
);

export type SetTriggerDataPointDefinitionMutationVariables = Exact<{
  input: SetTriggerDataPointDefinitionInput;
}>;


export type SetTriggerDataPointDefinitionMutation = (
  { __typename?: 'Mutation' }
  & { setTriggerDataPointDefinition: (
    { __typename?: 'TriggerPayload' }
    & { trigger: (
      { __typename?: 'Trigger' }
      & TriggerFragment
    ) }
  ) }
);

export type SetTriggerStepMutationVariables = Exact<{
  input: SetTriggerStepInput;
}>;


export type SetTriggerStepMutation = (
  { __typename?: 'Mutation' }
  & { setTriggerStep: (
    { __typename?: 'TriggerPayload' }
    & { trigger: (
      { __typename?: 'Trigger' }
      & TriggerFragment
    ) }
  ) }
);

export type SetTriggerTrackMutationVariables = Exact<{
  input: SetTriggerTrackInput;
}>;


export type SetTriggerTrackMutation = (
  { __typename?: 'Mutation' }
  & { setTriggerTrack: (
    { __typename?: 'TriggerPayload' }
    & { trigger: (
      { __typename?: 'Trigger' }
      & TriggerFragment
    ) }
  ) }
);

export type SetTriggerTypeMutationVariables = Exact<{
  input: SetTriggerTypeInput;
}>;


export type SetTriggerTypeMutation = (
  { __typename?: 'Mutation' }
  & { setTriggerType: (
    { __typename?: 'TriggerPayload' }
    & { trigger: (
      { __typename?: 'Trigger' }
      & TriggerFragment
    ) }
  ) }
);

export type TriggerFragment = (
  { __typename?: 'Trigger' }
  & Pick<Trigger, 'id' | 'target_type'>
  & { settings?: Maybe<(
    { __typename?: 'AdHocTriggerSettings' }
    & Pick<AdHocTriggerSettings, 'type'>
  ) | (
    { __typename?: 'DataPointCollectedTriggerSettings' }
    & Pick<DataPointCollectedTriggerSettings, 'data_point_definition_id' | 'rule_id' | 'type'>
    & { rule?: Maybe<(
      { __typename?: 'Rule' }
      & RuleFragment
    )> }
  ) | (
    { __typename?: 'DataPointNotCollectedTriggerSettings' }
    & Pick<DataPointNotCollectedTriggerSettings, 'data_point_definition_id' | 'rule_id' | 'type'>
    & { rule?: Maybe<(
      { __typename?: 'Rule' }
      & RuleFragment
    )> }
  ) | (
    { __typename?: 'DesignatedTriggerTimer' }
    & Pick<DesignatedTriggerTimer, 'type'>
  ) | (
    { __typename?: 'ExtensionWebhookTriggerSettings' }
    & Pick<ExtensionWebhookTriggerSettings, 'webhook_id' | 'rule_id' | 'type'>
    & { rule?: Maybe<(
      { __typename?: 'Rule' }
      & RuleFragment
    )> }
  ) | (
    { __typename?: 'PathwayStartedTriggerSettings' }
    & Pick<PathwayStartedTriggerSettings, 'type'>
  ) | (
    { __typename?: 'StepCompletedTriggerSettings' }
    & Pick<StepCompletedTriggerSettings, 'track_id' | 'step_id' | 'rule_id' | 'type'>
    & { rule?: Maybe<(
      { __typename?: 'Rule' }
      & RuleFragment
    )> }
  ) | (
    { __typename?: 'TrackCompletedTriggerSettings' }
    & Pick<TrackCompletedTriggerSettings, 'track_id' | 'rule_id' | 'type'>
    & { rule?: Maybe<(
      { __typename?: 'Rule' }
      & RuleFragment
    )> }
  )>, timers?: Maybe<Array<(
    { __typename?: 'DesignatedTriggerTimer' }
    & DesignatedTriggerTimerFragment
  )>> }
);

export type AddTriggerTimerMutationVariables = Exact<{
  input: AddTriggerTimerInput;
}>;


export type AddTriggerTimerMutation = (
  { __typename?: 'Mutation' }
  & { addTriggerTimer: (
    { __typename?: 'TriggerPayload' }
    & { trigger: (
      { __typename?: 'Trigger' }
      & Pick<Trigger, 'id'>
      & { timers?: Maybe<Array<(
        { __typename?: 'DesignatedTriggerTimer' }
        & DesignatedTriggerTimerFragment
      )>> }
    ) }
  ) }
);

export type DesignatedTriggerTimerFragment = (
  { __typename?: 'DesignatedTriggerTimer' }
  & Pick<DesignatedTriggerTimer, 'id'>
  & { delay?: Maybe<(
    { __typename?: 'Delay' }
    & Pick<Delay, 'unit' | 'amount'>
  )>, reference?: Maybe<(
    { __typename?: 'AdHocActivationReference' }
    & Pick<AdHocActivationReference, 'type'>
  ) | (
    { __typename?: 'DataPointCollectedActivationReference' }
    & Pick<DataPointCollectedActivationReference, 'data_point_definition_id' | 'type'>
  ) | (
    { __typename?: 'PathwayStartedActivationReference' }
    & Pick<PathwayStartedActivationReference, 'type'>
  ) | (
    { __typename?: 'StepCompletedActivationReference' }
    & Pick<StepCompletedActivationReference, 'track_id' | 'step_id' | 'type'>
  ) | (
    { __typename?: 'TrackCompletedActivationReference' }
    & Pick<TrackCompletedActivationReference, 'track_id' | 'type'>
  ) | (
    { __typename?: 'TrackStartedActivationReference' }
    & Pick<TrackStartedActivationReference, 'track_id' | 'type'>
  )> }
);

export type RemoveTriggerTimerMutationVariables = Exact<{
  input: RemoveTriggerTimerInput;
}>;


export type RemoveTriggerTimerMutation = (
  { __typename?: 'Mutation' }
  & { removeTriggerTimer: (
    { __typename?: 'TriggerPayload' }
    & Pick<TriggerPayload, 'success'>
    & { trigger: (
      { __typename?: 'Trigger' }
      & Pick<Trigger, 'id'>
      & { timers?: Maybe<Array<(
        { __typename?: 'DesignatedTriggerTimer' }
        & DesignatedTriggerTimerFragment
      )>> }
    ) }
  ) }
);

export type SetTriggerTimerDataPointDefinitionMutationVariables = Exact<{
  input: SetTriggerTimerDataPointDefinitionInput;
}>;


export type SetTriggerTimerDataPointDefinitionMutation = (
  { __typename?: 'Mutation' }
  & { setTriggerTimerDataPointDefinition: (
    { __typename?: 'TriggerPayload' }
    & Pick<TriggerPayload, 'success'>
    & { trigger: (
      { __typename?: 'Trigger' }
      & Pick<Trigger, 'id'>
      & { timers?: Maybe<Array<(
        { __typename?: 'DesignatedTriggerTimer' }
        & DesignatedTriggerTimerFragment
      )>> }
    ) }
  ) }
);

export type SetTriggerTimerDelayMutationVariables = Exact<{
  input: SetTriggerDelayInput;
}>;


export type SetTriggerTimerDelayMutation = (
  { __typename?: 'Mutation' }
  & { setTriggerDelay: (
    { __typename?: 'TriggerPayload' }
    & Pick<TriggerPayload, 'success'>
    & { trigger: (
      { __typename?: 'Trigger' }
      & Pick<Trigger, 'id'>
      & { timers?: Maybe<Array<(
        { __typename?: 'DesignatedTriggerTimer' }
        & DesignatedTriggerTimerFragment
      )>> }
    ) }
  ) }
);

export type SetTriggerTimerStepMutationVariables = Exact<{
  input: SetTriggerTimerStepInput;
}>;


export type SetTriggerTimerStepMutation = (
  { __typename?: 'Mutation' }
  & { setTriggerTimerStep: (
    { __typename?: 'TriggerPayload' }
    & Pick<TriggerPayload, 'success'>
    & { trigger: (
      { __typename?: 'Trigger' }
      & Pick<Trigger, 'id'>
      & { timers?: Maybe<Array<(
        { __typename?: 'DesignatedTriggerTimer' }
        & DesignatedTriggerTimerFragment
      )>> }
    ) }
  ) }
);

export type SetTriggerTimerTrackMutationVariables = Exact<{
  input: SetTriggerTimerTrackInput;
}>;


export type SetTriggerTimerTrackMutation = (
  { __typename?: 'Mutation' }
  & { setTriggerTimerTrack: (
    { __typename?: 'TriggerPayload' }
    & Pick<TriggerPayload, 'success'>
    & { trigger: (
      { __typename?: 'Trigger' }
      & Pick<Trigger, 'id'>
      & { timers?: Maybe<Array<(
        { __typename?: 'DesignatedTriggerTimer' }
        & DesignatedTriggerTimerFragment
      )>> }
    ) }
  ) }
);

export type SetTriggerTimerTypeMutationVariables = Exact<{
  input: SetTriggerTimerTypeInput;
}>;


export type SetTriggerTimerTypeMutation = (
  { __typename?: 'Mutation' }
  & { setTriggerTimerType: (
    { __typename?: 'TriggerPayload' }
    & Pick<TriggerPayload, 'success'>
    & { trigger: (
      { __typename?: 'Trigger' }
      & Pick<Trigger, 'id'>
      & { timers?: Maybe<Array<(
        { __typename?: 'DesignatedTriggerTimer' }
        & DesignatedTriggerTimerFragment
      )>> }
    ) }
  ) }
);

export type UpdateDataPointDefinitionMutationVariables = Exact<{
  input: UpdateDataPointDefinitionInput;
}>;


export type UpdateDataPointDefinitionMutation = (
  { __typename?: 'Mutation' }
  & { updateDataPointDefinition: (
    { __typename?: 'UpdateDataPointDefinitionPayload' }
    & Pick<UpdateDataPointDefinitionPayload, 'success'>
    & { data_point_definition: (
      { __typename?: 'DataPointDefinition' }
      & DataPointDefinitionFragment
    ) }
  ) }
);

export type UpdateCareFlowApiCallRetrySettingsMutationVariables = Exact<{
  input: UpdateCareFlowApiCallRetrySettingsInput;
}>;


export type UpdateCareFlowApiCallRetrySettingsMutation = (
  { __typename?: 'Mutation' }
  & { updateCareFlowApiCallRetrySettings: (
    { __typename?: 'UpdateCareFlowApiCallRetrySettingsPayload' }
    & { settings: (
      { __typename?: 'PathwaySettings' }
      & PathwaySettingsFragment
    ) }
  ) }
);

export type UpdateCareFlowSourceControlSettingsMutationVariables = Exact<{
  input: UpdateCareFlowSourceControlSettingsInput;
}>;


export type UpdateCareFlowSourceControlSettingsMutation = (
  { __typename?: 'Mutation' }
  & { updateCareFlowSourceControlSettings: (
    { __typename?: 'UpdateCareFlowSourceControlSettingsPayload' }
    & { settings: (
      { __typename?: 'PathwaySettings' }
      & { source_control?: Maybe<(
        { __typename?: 'SourceControlSettings' }
        & Pick<SourceControlSettings, 'repo_url' | 'access_token' | 'branch_name'>
      )> }
    ) }
  ) }
);

export type UpdateConstantMutationVariables = Exact<{
  input: UpdateConstantInput;
}>;


export type UpdateConstantMutation = (
  { __typename?: 'Mutation' }
  & { updateConstant: (
    { __typename?: 'UpdateConstantPayload' }
    & Pick<UpdateConstantPayload, 'success'>
    & { pathway: (
      { __typename?: 'Pathway' }
      & Pick<Pathway, 'id'>
      & { settings?: Maybe<(
        { __typename?: 'PathwaySettings' }
        & { constants?: Maybe<Array<(
          { __typename?: 'Constant' }
          & ConstantFragment
        )>> }
      )> }
    ) }
  ) }
);

export type UpdateDynamicVariableMutationVariables = Exact<{
  input: UpdateDynamicVariableInput;
}>;


export type UpdateDynamicVariableMutation = (
  { __typename?: 'Mutation' }
  & { updateDynamicVariable: (
    { __typename?: 'UpdateDynamicVariablePayload' }
    & Pick<UpdateDynamicVariablePayload, 'success'>
    & { dynamic_variable: (
      { __typename?: 'DynamicVariable' }
      & DynamicVariableFragment
    ) }
  ) }
);

export type SetDateQuestionConfigMutationVariables = Exact<{
  input: SetDateQuestionConfigInput;
}>;


export type SetDateQuestionConfigMutation = (
  { __typename?: 'Mutation' }
  & { setDateQuestionConfig: (
    { __typename?: 'SetDateQuestionConfigPayload' }
    & { question: (
      { __typename?: 'Question' }
      & Pick<Question, 'id'>
      & { questionConfig?: Maybe<(
        { __typename?: 'QuestionConfig' }
        & { date?: Maybe<(
          { __typename?: 'DateConfig' }
          & Pick<DateConfig, 'allowed_dates' | 'include_date_of_response'>
        )> }
      )> }
    ) }
  ) }
);

export type SetMultipleSelectQuestionConfigMutationVariables = Exact<{
  input: SetMultipleSelectQuestionConfigInput;
}>;


export type SetMultipleSelectQuestionConfigMutation = (
  { __typename?: 'Mutation' }
  & { setMultipleSelectQuestionConfig: (
    { __typename?: 'SetMultipleSelectQuestionConfigPayload' }
    & { question: (
      { __typename?: 'Question' }
      & Pick<Question, 'id'>
      & { questionConfig?: Maybe<(
        { __typename?: 'QuestionConfig' }
        & { multiple_select?: Maybe<(
          { __typename?: 'MultipleSelectConfig' }
          & { range?: Maybe<(
            { __typename?: 'ChoiceRangeConfig' }
            & Pick<ChoiceRangeConfig, 'min' | 'max' | 'enabled'>
          )>, exclusive_option?: Maybe<(
            { __typename?: 'ExclusiveOptionConfig' }
            & Pick<ExclusiveOptionConfig, 'option_id' | 'enabled'>
          )> }
        )> }
      )> }
    ) }
  ) }
);

export type SetNumberQuestionConfigMutationVariables = Exact<{
  input: SetNumberQuestionConfigInput;
}>;


export type SetNumberQuestionConfigMutation = (
  { __typename?: 'Mutation' }
  & { setNumberQuestionConfig: (
    { __typename?: 'SetNumberQuestionConfigPayload' }
    & { question: (
      { __typename?: 'Question' }
      & Pick<Question, 'id'>
      & { questionConfig?: Maybe<(
        { __typename?: 'QuestionConfig' }
        & { number?: Maybe<(
          { __typename?: 'NumberConfig' }
          & { range?: Maybe<(
            { __typename?: 'RangeConfig' }
            & Pick<RangeConfig, 'min' | 'max' | 'enabled'>
          )> }
        )> }
      )> }
    ) }
  ) }
);

export type SetPhoneQuestionConfigMutationVariables = Exact<{
  input: SetPhoneQuestionConfigInput;
}>;


export type SetPhoneQuestionConfigMutation = (
  { __typename?: 'Mutation' }
  & { setPhoneQuestionConfig: (
    { __typename?: 'SetPhoneQuestionConfigPayload' }
    & { question: (
      { __typename?: 'Question' }
      & Pick<Question, 'id'>
      & { questionConfig?: Maybe<(
        { __typename?: 'QuestionConfig' }
        & { phone?: Maybe<(
          { __typename?: 'PhoneConfig' }
          & Pick<PhoneConfig, 'default_country' | 'available_countries'>
        )> }
      )> }
    ) }
  ) }
);

export type SetQuestionConfigMutationVariables = Exact<{
  input: SetQuestionConfigInput;
}>;


export type SetQuestionConfigMutation = (
  { __typename?: 'Mutation' }
  & { setQuestionConfig: (
    { __typename?: 'SetQuestionConfigPayload' }
    & { question: (
      { __typename?: 'Question' }
      & Pick<Question, 'id'>
      & { questionConfig?: Maybe<(
        { __typename?: 'QuestionConfig' }
        & Pick<QuestionConfig, 'mandatory' | 'use_select'>
      )> }
    ) }
  ) }
);

export type SetQuestionKeyMutationVariables = Exact<{
  input: SetQuestionKeyInput;
}>;


export type SetQuestionKeyMutation = (
  { __typename?: 'Mutation' }
  & { setQuestionKey: (
    { __typename?: 'SetQuestionKeyPayload' }
    & { question: (
      { __typename?: 'Question' }
      & Pick<Question, 'id' | 'key'>
    ) }
  ) }
);

export type SetQuestionMetadataMutationVariables = Exact<{
  input: SetQuestionMetadataInput;
}>;


export type SetQuestionMetadataMutation = (
  { __typename?: 'Mutation' }
  & { setQuestionMetadata: (
    { __typename?: 'SetQuestionMetadataPayload' }
    & { question: (
      { __typename?: 'Question' }
      & Pick<Question, 'id'>
      & { metadata?: Maybe<(
        { __typename?: 'FormattedJson' }
        & Pick<FormattedJson, 'plaintext' | 'slate'>
      )> }
    ) }
  ) }
);

export type SetQuestionOptionValueTypeMutationVariables = Exact<{
  input: SetQuestionOptionValueTypeInput;
}>;


export type SetQuestionOptionValueTypeMutation = (
  { __typename?: 'Mutation' }
  & { setQuestionOptionValueType: (
    { __typename?: 'SetQuestionOptionValueTypePayload' }
    & { question: (
      { __typename?: 'Question' }
      & Pick<Question, 'id' | 'dataPointValueType'>
      & { options?: Maybe<Array<(
        { __typename?: 'Option' }
        & Pick<Option, 'id' | 'value' | 'label'>
      )>> }
    ) }
  ) }
);

export type SetQuestionsConfigMutationVariables = Exact<{
  input: SetQuestionsConfigInput;
}>;


export type SetQuestionsConfigMutation = (
  { __typename?: 'Mutation' }
  & { setQuestionsConfig: (
    { __typename?: 'SetQuestionsConfigPayload' }
    & { questions: Array<(
      { __typename?: 'Question' }
      & Pick<Question, 'id'>
      & { questionConfig?: Maybe<(
        { __typename?: 'QuestionConfig' }
        & Pick<QuestionConfig, 'mandatory' | 'use_select'>
      )> }
    )> }
  ) }
);

export type SetSliderQuestionConfigMutationVariables = Exact<{
  input: SetSliderQuestionConfigInput;
}>;


export type SetSliderQuestionConfigMutation = (
  { __typename?: 'Mutation' }
  & { setSliderQuestionConfig: (
    { __typename?: 'SetSliderQuestionConfigPayload' }
    & { question: (
      { __typename?: 'Question' }
      & Pick<Question, 'id'>
      & { questionConfig?: Maybe<(
        { __typename?: 'QuestionConfig' }
        & { slider?: Maybe<(
          { __typename?: 'SliderConfig' }
          & Pick<SliderConfig, 'min' | 'max' | 'step_value' | 'display_marks' | 'min_label' | 'max_label' | 'is_value_tooltip_on' | 'show_min_max_values'>
        )> }
      )> }
    ) }
  ) }
);

export type UpdateStickyNoteBodyMutationVariables = Exact<{
  input: UpdateStickyNoteBodyInput;
}>;


export type UpdateStickyNoteBodyMutation = (
  { __typename?: 'Mutation' }
  & { updateStickyNoteBody: (
    { __typename?: 'UpdateStickyNoteBodyPayload' }
    & Pick<UpdateStickyNoteBodyPayload, 'success' | 'code'>
    & { stickyNote: (
      { __typename?: 'StickyNote' }
      & Pick<StickyNote, 'id'>
      & { body?: Maybe<(
        { __typename?: 'StickyNoteBody' }
        & Pick<StickyNoteBody, 'format' | 'text'>
      )> }
    ) }
  ) }
);

export type UpdateWebhookMutationVariables = Exact<{
  input: UpdateWebhookInput;
}>;


export type UpdateWebhookMutation = (
  { __typename?: 'Mutation' }
  & { updateWebhook: (
    { __typename?: 'UpdateWebhookPayload' }
    & { pathway: (
      { __typename?: 'Pathway' }
      & { settings?: Maybe<(
        { __typename?: 'PathwaySettings' }
        & { webhooks?: Maybe<Array<(
          { __typename?: 'Webhook' }
          & Pick<Webhook, 'id' | 'enabled' | 'name' | 'subscribed_events'>
        )>> }
      )> }
    ) }
  ) }
);

export type UpdateWebhookEndpointMutationVariables = Exact<{
  input: UpdateWebhookEndpointInput;
}>;


export type UpdateWebhookEndpointMutation = (
  { __typename?: 'Mutation' }
  & { updateWebhookEndpoint: (
    { __typename?: 'UpdateWebhookEndpointPayload' }
    & { pathway: (
      { __typename?: 'Pathway' }
      & { settings?: Maybe<(
        { __typename?: 'PathwaySettings' }
        & { webhooks?: Maybe<Array<(
          { __typename?: 'Webhook' }
          & Pick<Webhook, 'id' | 'endpoint' | 'endpoint_test' | 'enabled' | 'name' | 'subscribed_events'>
          & { headers?: Maybe<Array<(
            { __typename?: 'WebhookHeader' }
            & Pick<WebhookHeader, 'key' | 'value'>
          )>> }
        )>> }
      )> }
    ) }
  ) }
);

export type UpdateWebhookHeadersMutationVariables = Exact<{
  input: UpdateWebhookHeadersInput;
}>;


export type UpdateWebhookHeadersMutation = (
  { __typename?: 'Mutation' }
  & { updateWebhookHeaders: (
    { __typename?: 'UpdateWebhookHeadersPayload' }
    & { pathway: (
      { __typename?: 'Pathway' }
      & { settings?: Maybe<(
        { __typename?: 'PathwaySettings' }
        & { webhooks?: Maybe<Array<(
          { __typename?: 'Webhook' }
          & Pick<Webhook, 'id' | 'endpoint' | 'endpoint_test' | 'enabled' | 'name' | 'subscribed_events'>
          & { headers?: Maybe<Array<(
            { __typename?: 'WebhookHeader' }
            & Pick<WebhookHeader, 'key' | 'value'>
          )>> }
        )>> }
      )> }
    ) }
  ) }
);

export type UpdateWebhookNameMutationVariables = Exact<{
  input: UpdateWebhookNameInput;
}>;


export type UpdateWebhookNameMutation = (
  { __typename?: 'Mutation' }
  & { updateWebhookName: (
    { __typename?: 'UpdateWebhookNamePayload' }
    & { pathway: (
      { __typename?: 'Pathway' }
      & { settings?: Maybe<(
        { __typename?: 'PathwaySettings' }
        & { webhooks?: Maybe<Array<(
          { __typename?: 'Webhook' }
          & Pick<Webhook, 'id' | 'endpoint' | 'endpoint_test' | 'enabled' | 'name' | 'subscribed_events'>
          & { headers?: Maybe<Array<(
            { __typename?: 'WebhookHeader' }
            & Pick<WebhookHeader, 'key' | 'value'>
          )>> }
        )>> }
      )> }
    ) }
  ) }
);

export type UpdateWebhookSubscribedEventsMutationVariables = Exact<{
  input: UpdateWebhookSubscribedEventsInput;
}>;


export type UpdateWebhookSubscribedEventsMutation = (
  { __typename?: 'Mutation' }
  & { updateWebhookSubscribedEvents: (
    { __typename?: 'UpdateWebhookSubscribedEventsPayload' }
    & { pathway: (
      { __typename?: 'Pathway' }
      & { settings?: Maybe<(
        { __typename?: 'PathwaySettings' }
        & { webhooks?: Maybe<Array<(
          { __typename?: 'Webhook' }
          & Pick<Webhook, 'id' | 'endpoint' | 'endpoint_test' | 'enabled' | 'name' | 'subscribed_events'>
          & { headers?: Maybe<Array<(
            { __typename?: 'WebhookHeader' }
            & Pick<WebhookHeader, 'key' | 'value'>
          )>> }
        )>> }
      )> }
    ) }
  ) }
);

export type UpdateWebhookTestEndpointMutationVariables = Exact<{
  input: UpdateWebhookTestEndpointInput;
}>;


export type UpdateWebhookTestEndpointMutation = (
  { __typename?: 'Mutation' }
  & { updateWebhookTestEndpoint: (
    { __typename?: 'UpdateWebhookTestEndpointPayload' }
    & { pathway: (
      { __typename?: 'Pathway' }
      & { settings?: Maybe<(
        { __typename?: 'PathwaySettings' }
        & { webhooks?: Maybe<Array<(
          { __typename?: 'Webhook' }
          & Pick<Webhook, 'id' | 'endpoint' | 'endpoint_test' | 'enabled' | 'name' | 'subscribed_events'>
          & { headers?: Maybe<Array<(
            { __typename?: 'WebhookHeader' }
            & Pick<WebhookHeader, 'key' | 'value'>
          )>> }
        )>> }
      )> }
    ) }
  ) }
);

export type UpdateWebhookStatusMutationVariables = Exact<{
  input: UpdateWebhookStatusInput;
}>;


export type UpdateWebhookStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateWebhookStatus: (
    { __typename?: 'UpdateWebhookStatusPayload' }
    & { pathway: (
      { __typename?: 'Pathway' }
      & { settings?: Maybe<(
        { __typename?: 'PathwaySettings' }
        & { webhooks?: Maybe<Array<(
          { __typename?: 'Webhook' }
          & Pick<Webhook, 'id' | 'endpoint' | 'endpoint_test' | 'enabled' | 'name' | 'subscribed_events'>
          & { headers?: Maybe<Array<(
            { __typename?: 'WebhookHeader' }
            & Pick<WebhookHeader, 'key' | 'value'>
          )>> }
        )>> }
      )> }
    ) }
  ) }
);

export type UserQueryVariables = Exact<{ [key: string]: never; }>;


export type UserQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'UserPayload' }
    & Pick<UserPayload, 'code' | 'success'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'intercom_hash' | 'pending_invitation_id'>
      & { tenant?: Maybe<(
        { __typename?: 'Tenant' }
        & Pick<Tenant, 'id' | 'name' | 'public' | 'is_default' | 'logo_path' | 'accent_color' | 'hosted_page_title'>
        & { api_call_retry_settings?: Maybe<(
          { __typename?: 'ApiCallRetrySettings' }
          & Pick<ApiCallRetrySettings, 'enabled' | 'initial_delay_ms' | 'max_delay_ms' | 'max_attempts'>
        )> }
      )>, team?: Maybe<(
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'name'>
      )> }
    ) }
  ) }
);

export type SimulatorLayoutQueryVariables = Exact<{ [key: string]: never; }>;


export type SimulatorLayoutQuery = (
  { __typename?: 'Query' }
  & { simulatorLayout?: Maybe<(
    { __typename?: 'SimulatorLayout' }
    & Pick<SimulatorLayout, 'activityFeedWidth'>
  )> }
);

export type LayoutQueryVariables = Exact<{ [key: string]: never; }>;


export type LayoutQuery = (
  { __typename?: 'Query' }
  & { layout?: Maybe<(
    { __typename?: 'Layout' }
    & Pick<Layout, 'startPanelWidth' | 'startPanelCollapsed' | 'endPanelWidth'>
  )> }
);

export type TrackCanvasSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type TrackCanvasSettingsQuery = (
  { __typename?: 'Query' }
  & { trackCanvasSettings?: Maybe<Array<Maybe<(
    { __typename?: 'TrackCanvasSettings' }
    & Pick<TrackCanvasSettings, 'trackId' | 'zoomLevel'>
    & { offset: (
      { __typename?: 'CanvasOffset' }
      & Pick<CanvasOffset, 'x' | 'y'>
    ) }
  )>>> }
);

export type SessionUrlsQueryVariables = Exact<{ [key: string]: never; }>;


export type SessionUrlsQuery = (
  { __typename?: 'Query' }
  & { sessionUrls?: Maybe<(
    { __typename?: 'SessionUrls' }
    & Pick<SessionUrls, 'builder' | 'preview'>
  )> }
);

export type PageTitleQueryVariables = Exact<{ [key: string]: never; }>;


export type PageTitleQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'pageTitle'>
);

export type CalculationsLibraryQueryVariables = Exact<{ [key: string]: never; }>;


export type CalculationsLibraryQuery = (
  { __typename?: 'Query' }
  & { calculationsLibrary?: Maybe<Array<Maybe<(
    { __typename?: 'CalculationLibraryType' }
    & Pick<CalculationLibraryType, 'calculation_id'>
    & { calculation_name?: Maybe<(
      { __typename?: 'LabelType' }
      & Pick<LabelType, 'en'>
    )>, calculation_description?: Maybe<(
      { __typename?: 'LabelType' }
      & Pick<LabelType, 'en'>
    )>, calculation_blueprint?: Maybe<(
      { __typename?: 'CalculationBlueprintType' }
      & { input_definition?: Maybe<Array<Maybe<(
        { __typename?: 'CalculationBlueprintInputDefinitionType' }
        & Pick<CalculationBlueprintInputDefinitionType, 'id'>
        & { input_type?: Maybe<(
          { __typename?: 'CalculationBlueprintInputType' }
          & Pick<CalculationBlueprintInputType, 'type'>
        )> }
      )>>> }
    )> }
  )>>> }
);

export type GetFormsWithElementsInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFormsWithElementsInfoQuery = (
  { __typename?: 'Query' }
  & { forms: (
    { __typename?: 'FormsPayload' }
    & { forms: Array<(
      { __typename?: 'Form' }
      & Pick<Form, 'id' | 'title' | 'key'>
    )> }
  ) }
);

export type StepsWithActionTypesQueryVariables = Exact<{
  pathway_id: Scalars['String'];
}>;


export type StepsWithActionTypesQuery = (
  { __typename?: 'Query' }
  & { pathwaySteps: (
    { __typename?: 'StepsPayload' }
    & Pick<StepsPayload, 'success' | 'code'>
    & { steps: Array<(
      { __typename?: 'Step' }
      & Pick<Step, 'id' | 'title' | 'track_id'>
      & { actions?: Maybe<Array<(
        { __typename?: 'ApiCallAction' }
        & Pick<ApiCallAction, 'title' | 'id' | 'type'>
      ) | (
        { __typename?: 'ApiCallGraphqlAction' }
        & Pick<ApiCallGraphqlAction, 'title' | 'id' | 'type'>
      ) | (
        { __typename?: 'CalculationAction' }
        & Pick<CalculationAction, 'title' | 'id' | 'type'>
      ) | (
        { __typename?: 'ChecklistAction' }
        & Pick<ChecklistAction, 'title' | 'id' | 'type'>
      ) | (
        { __typename?: 'ClinicalNoteAction' }
        & Pick<ClinicalNoteAction, 'title' | 'id' | 'type'>
      ) | (
        { __typename?: 'ExtensionAction' }
        & Pick<ExtensionAction, 'title' | 'id' | 'type'>
      ) | (
        { __typename?: 'FormAction' }
        & Pick<FormAction, 'form_id' | 'title' | 'id' | 'type'>
      ) | (
        { __typename?: 'MessageAction' }
        & Pick<MessageAction, 'message_id' | 'title' | 'id' | 'type'>
      ) | (
        { __typename?: 'PushToEmrAction' }
        & Pick<PushToEmrAction, 'title' | 'id' | 'type'>
      )>>, label?: Maybe<(
        { __typename?: 'Label' }
        & Pick<Label, 'id' | 'color' | 'text'>
      )> }
    )> }
  ) }
);

export type GetPathwayInfoQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetPathwayInfoQuery = (
  { __typename?: 'Query' }
  & { pathway: (
    { __typename?: 'PathwayPayload' }
    & { pathway: (
      { __typename?: 'Pathway' }
      & Pick<Pathway, 'id' | 'title' | 'readonly'>
      & { created: (
        { __typename?: 'AuditTrail' }
        & Pick<AuditTrail, 'user_id' | 'user_email' | 'date'>
      ), last_updated?: Maybe<(
        { __typename?: 'AuditTrail' }
        & Pick<AuditTrail, 'user_id' | 'user_email' | 'date'>
      )>, last_published_version?: Maybe<(
        { __typename?: 'PathwayVersion' }
        & Pick<PathwayVersion, 'version' | 'version_status'>
      )> }
    ) }
  ) }
);

export type GetPathwayVersionQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetPathwayVersionQuery = (
  { __typename?: 'Query' }
  & { pathway_version: (
    { __typename?: 'PathwayVersionPayload' }
    & Pick<PathwayVersionPayload, 'success'>
    & { version: (
      { __typename?: 'PathwayVersion' }
      & PathwayVersionFragment
    ) }
  ) }
);

export type GetPathwayVersionsQueryVariables = Exact<{
  pathway_id: Scalars['String'];
}>;


export type GetPathwayVersionsQuery = (
  { __typename?: 'Query' }
  & { pathway_versions: (
    { __typename?: 'PathwayVersionsPayload' }
    & Pick<PathwayVersionsPayload, 'success'>
    & { versions: Array<(
      { __typename?: 'PathwayVersion' }
      & PathwayVersionFragment
    )> }
  ) }
);

export type PathwayVersionFragment = (
  { __typename?: 'PathwayVersion' }
  & Pick<PathwayVersion, 'id' | 'version' | 'created_at' | 'version_status' | 'commit_link'>
  & { created_by: (
    { __typename?: 'PathwayCollaborator' }
    & Pick<PathwayCollaborator, 'user_id' | 'user_email'>
  ), comments?: Maybe<(
    { __typename?: 'FormattedText' }
    & { content: (
      { __typename?: 'TranslatedText' }
      & Pick<TranslatedText, 'en'>
    ) }
  )> }
);

export type GetPathwayCaseTracksQueryVariables = Exact<{
  pathwayId: Scalars['String'];
}>;


export type GetPathwayCaseTracksQuery = (
  { __typename?: 'Query' }
  & { pathway: (
    { __typename?: 'PathwayPayload' }
    & { pathway: (
      { __typename?: 'Pathway' }
      & Pick<Pathway, 'id' | 'title'>
      & { tracks?: Maybe<Array<(
        { __typename?: 'Track' }
        & Pick<Track, 'id' | 'title'>
      )>> }
    ) }
  ) }
);

export type GetPathwayCaseTitleQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetPathwayCaseTitleQuery = (
  { __typename?: 'Query' }
  & { pathway_case: (
    { __typename?: 'PathwayCasePayload' }
    & { pathway_case: (
      { __typename?: 'PathwayCase' }
      & Pick<PathwayCase, 'title'>
    ) }
  ) }
);

export const CalculationActionFragmentFragmentDoc = gql`
    fragment CalculationActionFragment on CalculationAction {
  calculation_id
  calculation_inputs {
    calculation_input_id
    data_point_definition_id
  }
}
    `;
export const CheckListFragmentFragmentDoc = gql`
    fragment CheckListFragment on ChecklistAction {
  checklist
}
    `;
export const FormActionFragmentFragmentDoc = gql`
    fragment FormActionFragment on FormAction {
  form_id
  form_display_mode
}
    `;
export const MessageActionFragmentFragmentDoc = gql`
    fragment MessageActionFragment on MessageAction {
  message_id
}
    `;
export const TimingFragmentDoc = gql`
    fragment Timing on Timing {
  id
  reference
  delay {
    unit
    amount
  }
}
    `;
export const ConfigurationStatusFragmentDoc = gql`
    fragment ConfigurationStatus on ConfigurationStatus {
  status
  errors
}
    `;
export const TransitionPartsFragmentDoc = gql`
    fragment TransitionParts on Transition {
  id
  default
  origin {
    type
    node_id
    node_title
  }
  destination {
    type
    node_id
    node_title
  }
  status {
    ...ConfigurationStatus
  }
  transitionOrientation {
    origin_orientation
    destination_orientation
  }
  timing {
    id
    delay {
      amount
      unit
    }
    reference
  }
  rule {
    id
    boolean_operator
    conditions {
      id
      reference
      operand {
        type
        value
      }
      operator
      status {
        ...ConfigurationStatus
      }
    }
    status {
      ...ConfigurationStatus
    }
  }
}
    ${ConfigurationStatusFragmentDoc}`;
export const TransitionFragmentDoc = gql`
    fragment Transition on Transition {
  id
  origin {
    type
    node_id
  }
  destination {
    type
    node_id
  }
  transitionOrientation {
    origin_orientation
    destination_orientation
  }
  rule {
    id
  }
  timing {
    id
  }
  default
  status {
    ...ConfigurationStatus
  }
}
    ${ConfigurationStatusFragmentDoc}`;
export const DataPointDefinitionFragmentDoc = gql`
    fragment DataPointDefinition on DataPointDefinition {
  id
  key
  optional
  valueType
  possibleValues {
    label
    value
  }
  unit
  range {
    max
    min
  }
  title
  category
  data_point_metadata {
    key
    value
  }
  pii
  source_definition_id
  jsonSchemaSource {
    system
    qualifiedKey
  }
  jsonPath
}
    `;
export const ClinicalNoteFragmentDoc = gql`
    fragment ClinicalNote on ClinicalNote {
  id
  narratives {
    id
    key
    title
    body
    html_body
  }
  context {
    key
    value
  }
}
    `;
export const GeneratedClinicalNoteFragmentDoc = gql`
    fragment GeneratedClinicalNote on PreviewClinicalNote {
  id
  narratives {
    id
    key
    title
    body
  }
  context {
    key
    value
  }
}
    `;
export const ConstantFragmentDoc = gql`
    fragment Constant on Constant {
  id
  label
  value
  obfuscated
}
    `;
export const TransformationFragmentDoc = gql`
    fragment Transformation on Transformation {
  id
  type
  ... on AddPrefixTransformation {
    parameters {
      prefix
    }
  }
  ... on AddSuffixTransformation {
    parameters {
      suffix
    }
  }
  ... on FormatDateTransformation {
    parameters {
      format
    }
  }
  ... on FormatPhoneNumberTransformation {
    parameters {
      format
    }
  }
  ... on RoundToTransformation {
    parameters {
      nbr_decimals
    }
  }
  ... on AddDurationTransformation {
    parameters {
      duration {
        years
        months
        weeks
        days
        hours
        minutes
      }
    }
  }
  ... on SubtractDurationTransformation {
    parameters {
      duration {
        years
        months
        weeks
        days
        hours
        minutes
      }
    }
  }
  ... on AddWeekdaysTransformation {
    parameters {
      days
    }
  }
}
    `;
export const DynamicVariableFragmentDoc = gql`
    fragment DynamicVariable on DynamicVariable {
  id
  status {
    errors
    status
  }
  fallback
  data_point_definition {
    id
    category
    key
    title
    valueType
    possibleValues {
      label
      value
    }
  }
  data_point_property
  label
  transformations {
    ...Transformation
  }
}
    ${TransformationFragmentDoc}`;
export const ExtensionConfigurationFragmentDoc = gql`
    fragment ExtensionConfiguration on ExtensionConfiguration {
  extension_key
  properties {
    constant {
      id
      obfuscated
      value
      label
    }
    key
  }
}
    `;
export const QuestionFragmentDoc = gql`
    fragment Question on Question {
  id
  title
  form_id
  key
  dataPointValueType
  options {
    id
    value
    label
  }
  questionType
  userQuestionType
  rule_id
  questionConfig {
    recode_enabled
    use_select
    mandatory
    slider {
      min
      max
      step_value
      display_marks
      min_label
      max_label
      is_value_tooltip_on
      show_min_max_values
    }
    phone {
      default_country
      available_countries
    }
    number {
      range {
        min
        max
        enabled
      }
    }
    multiple_select {
      range {
        min
        max
        enabled
      }
      exclusive_option {
        option_id
        enabled
      }
    }
    date {
      allowed_dates
      include_date_of_response
    }
  }
  metadata {
    plaintext
    slate
  }
}
    `;
export const FormFragmentDoc = gql`
    fragment Form on Form {
  id
  title
  key
  trademark
  metadata {
    plaintext
    slate
  }
  questions {
    ...Question
  }
}
    ${QuestionFragmentDoc}`;
export const ApiCallFragmentDoc = gql`
    fragment ApiCall on ApiCall {
  id
  endpoint
  method
  headers {
    key
    value
  }
  body {
    slate
    plaintext
    html
  }
  mappings {
    data_point_definition_id
    response_key
    first_match_only
    id
  }
}
    `;
export const ActionFragmentDoc = gql`
    fragment Action on Action {
  id
  type
  title
  idle_time {
    unit
    amount
  }
  step {
    id
  }
  stakeholders {
    id
    label {
      en
    }
    clinical_app_role
  }
  locations {
    id
    label
  }
  ... on MessageAction {
    message_id
  }
  ... on ChecklistAction {
    checklist
  }
  ... on FormAction {
    form_id
    form_display_mode
    idle_time {
      amount
      unit
    }
  }
  ... on CalculationAction {
    calculation_id
    calculation_inputs {
      calculation_input_id
      data_point_definition_id
    }
  }
  ... on PushToEmrAction {
    emr_report_id
    emr_report {
      id
      body
    }
    custom_fields {
      id
      name
      type
      label
      description
      options {
        label
        value
      }
      value
      readonly
      mandatory
    }
  }
  ... on ApiCallAction {
    api_call {
      ...ApiCall
    }
  }
  ... on ApiCallGraphqlAction {
    api_call {
      ...ApiCall
    }
  }
  ... on ExtensionAction {
    extension_key
    extension_action_key
    stakeholders_select_settings {
      label
      mode
      description
    }
    extension_fields {
      id
      type
      label
      description
      required
      ... on HtmlField {
        value
        slate
      }
      ... on JsonField {
        value
        slate
        jsonSchemaSource {
          system
          qualifiedKey
        }
      }
      ... on DateField {
        value
        delay {
          amount
          unit
        }
      }
      ... on NumericField {
        value
      }
      ... on StringField {
        value
        stringType
      }
      ... on TextField {
        value
        slate
      }
      ... on BooleanField {
        value
      }
      ... on StringArrayField {
        value
      }
      ... on NumericArrayField {
        value
      }
    }
    extension_data_points {
      key
      valueType
      jsonSchemaSource {
        system
        qualifiedKey
      }
      jsonPath
    }
    extension_data_point_mappings {
      id
      extension_data_point_key
      data_point_definition_id
    }
  }
  status {
    ...ConfigurationStatus
  }
}
    ${ApiCallFragmentDoc}
${ConfigurationStatusFragmentDoc}`;
export const PathwayCaseWebhookCallFragmentDoc = gql`
    fragment PathwayCaseWebhookCall on PathwayCaseWebhookCall {
  id
  webhook_name
  webhook_id
  request {
    method
    endpoint
    headers {
      key
      value
    }
    body
  }
  status
  responses {
    status
    body
    date
  }
  pathway {
    id
    pathway_definition_id
    start_date
  }
  event_type
  created_at
}
    `;
export const HostedPagesLinkFragmentDoc = gql`
    fragment HostedPagesLink on AhpLink {
  id
  stakeholder_definition_id
  enabled
  language
  success_url
  cancel_url
  slug
  pathway_definition_id
  base_url
}
    `;
export const PathwayFragmentDoc = gql`
    fragment Pathway on Pathway {
  id
  title
  tracks {
    id
    title
  }
  created {
    user_id
    user_email
    date
  }
  last_updated {
    user_id
    user_email
    date
  }
  labels {
    id
    color
    text
  }
  last_published_version {
    version
    version_status
  }
  readonly
}
    `;
export const ElementFragmentDoc = gql`
    fragment Element on Element {
  id
  parent_id
  name
  status
  type
  activity_type
  start_date
  end_date
  label {
    id
    color
    text
  }
  stakeholders {
    id
    name
  }
  context {
    instance_id
    pathway_id
    track_id
    step_id
    action_id
  }
}
    `;
export const PathwayCaseListItemFragmentDoc = gql`
    fragment PathwayCaseListItem on PathwayCase {
  id
  title
  last_modification_date
  created_by {
    user_email
  }
  last_modified_by {
    user_email
  }
}
    `;
export const PathwaySettingsFragmentDoc = gql`
    fragment PathwaySettings on PathwaySettings {
  id
  reminders {
    amount
    delay {
      unit
      amount
    }
  }
  stakeholder_notification_language
  dashboard_ids
  stakeholders {
    definition_id
    email
  }
  allow_email_notifications
  branding {
    accent_color
    logo_url
    hosted_page_title
    hosted_page_auto_progress
    hosted_page_autosave
    custom_theme {
      plaintext
      slate
    }
  }
  api_call_retry_settings {
    enabled
    initial_delay_ms
    max_attempts
    max_delay_ms
  }
  source_control {
    repo_url
    access_token
    branch_name
  }
}
    `;
export const RuleFragmentDoc = gql`
    fragment Rule on Rule {
  id
  boolean_operator
  status {
    ...ConfigurationStatus
  }
  conditions {
    id
    reference
    reference_key
    operator
    operand {
      type
      value
    }
    status {
      ...ConfigurationStatus
    }
  }
}
    ${ConfigurationStatusFragmentDoc}`;
export const DesignatedTriggerTimerFragmentDoc = gql`
    fragment DesignatedTriggerTimer on DesignatedTriggerTimer {
  id
  delay {
    unit
    amount
  }
  reference {
    type
    ... on StepCompletedActivationReference {
      track_id
      step_id
    }
    ... on DataPointCollectedActivationReference {
      data_point_definition_id
    }
    ... on TrackCompletedActivationReference {
      track_id
    }
    ... on TrackStartedActivationReference {
      track_id
    }
  }
}
    `;
export const TriggerFragmentDoc = gql`
    fragment Trigger on Trigger {
  id
  target_type
  settings {
    type
    ... on StepCompletedTriggerSettings {
      track_id
      step_id
      rule_id
      rule {
        ...Rule
      }
    }
    ... on DataPointCollectedTriggerSettings {
      data_point_definition_id
      rule_id
      rule {
        ...Rule
      }
    }
    ... on TrackCompletedTriggerSettings {
      track_id
      rule_id
      rule {
        ...Rule
      }
    }
    ... on DataPointNotCollectedTriggerSettings {
      data_point_definition_id
      rule_id
      rule {
        ...Rule
      }
    }
    ... on ExtensionWebhookTriggerSettings {
      webhook_id
      rule_id
      rule {
        ...Rule
      }
    }
  }
  timers {
    ...DesignatedTriggerTimer
  }
}
    ${RuleFragmentDoc}
${DesignatedTriggerTimerFragmentDoc}`;
export const TrackFragmentDoc = gql`
    fragment Track on Track {
  id
  title
  triggers {
    ...Trigger
  }
  steps {
    id
    title
    transitions {
      id
    }
  }
}
    ${TriggerFragmentDoc}`;
export const ActivityFragmentDoc = gql`
    fragment Activity on Activity {
  id
  icon_url
  subject {
    id
    type
    name
  }
  action
  date
  object {
    id
    type
    name
  }
  indirect_object {
    id
    type
    name
  }
  status
  resolution
  reference_id
  track {
    id
    title
  }
  container_name
  label {
    id
    text
    color
  }
  sub_activities {
    id
    date
    action
    error
    error_category
    text {
      en
    }
    subject {
      id
      type
      name
    }
    object {
      id
      type
      name
    }
  }
  isUserActivity
  context {
    instance_id
    pathway_id
    track_id
    step_id
    action_id
  }
  stakeholders {
    id
    type
    name
    email
  }
}
    `;
export const PathwayCaseFragmentDoc = gql`
    fragment PathwayCase on PathwayCase {
  id
  pathway_id
  title
  status
  activities {
    ...Activity
  }
}
    ${ActivityFragmentDoc}`;
export const CanvasStepFragmentDoc = gql`
    fragment CanvasStep on Step {
  id
  title
  coordinates {
    x
    y
  }
  actions {
    type
    title
    id
    plugin_key
    ... on MessageAction {
      message_id
    }
    ... on ClinicalNoteAction {
      clinical_note_id
    }
    ... on PushToEmrAction {
      emr_report_id
    }
    locations {
      label
      id
    }
    stakeholders {
      id
      label {
        en
      }
    }
    status {
      ...ConfigurationStatus
    }
  }
  documentation {
    content {
      en
    }
  }
  label {
    id
    color
    text
  }
}
    ${ConfigurationStatusFragmentDoc}`;
export const CanvasStickyNoteFragmentDoc = gql`
    fragment CanvasStickyNote on StickyNote {
  id
  body {
    format
    text
  }
  coordinates {
    x
    y
  }
}
    `;
export const CanvasTransitionFragmentDoc = gql`
    fragment CanvasTransition on Transition {
  id
  default
  origin {
    type
    node_id
  }
  destination {
    type
    node_id
    node_title
  }
  transitionOrientation {
    origin_orientation
    destination_orientation
  }
  timing {
    id
    delay {
      amount
      unit
    }
    reference
    reference_key
  }
  rule {
    id
    boolean_operator
    conditions {
      id
      reference
      operand {
        type
        value
      }
      operator
    }
  }
}
    `;
export const PathwayVersionFragmentDoc = gql`
    fragment PathwayVersion on PathwayVersion {
  id
  version
  created_at
  created_by {
    user_id
    user_email
  }
  comments {
    content {
      en
    }
  }
  version_status
  commit_link
}
    `;
export const AddActionToStepDocument = gql`
    mutation AddActionToStep($input: AddActionToStepInput!) {
  addActionToStep(input: $input) {
    step {
      id
      actions {
        id
        title
        type
        ... on MessageAction {
          message_id
        }
        ... on PushToEmrAction {
          emr_report_id
        }
        ... on ClinicalNoteAction {
          clinical_note_id
        }
        ... on ExtensionAction {
          extension_key
        }
      }
    }
    action {
      id
      type
      ... on MessageAction {
        message_id
      }
      ... on PushToEmrAction {
        emr_report_id
      }
      ... on ClinicalNoteAction {
        clinical_note_id
      }
      ... on ExtensionAction {
        extension_key
      }
    }
  }
}
    `;
export type AddActionToStepMutationFn = Apollo.MutationFunction<AddActionToStepMutation, AddActionToStepMutationVariables>;

/**
 * __useAddActionToStepMutation__
 *
 * To run a mutation, you first call `useAddActionToStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddActionToStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addActionToStepMutation, { data, loading, error }] = useAddActionToStepMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddActionToStepMutation(baseOptions?: Apollo.MutationHookOptions<AddActionToStepMutation, AddActionToStepMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddActionToStepMutation, AddActionToStepMutationVariables>(AddActionToStepDocument, options);
      }
export type AddActionToStepMutationHookResult = ReturnType<typeof useAddActionToStepMutation>;
export type AddActionToStepMutationResult = Apollo.MutationResult<AddActionToStepMutation>;
export type AddActionToStepMutationOptions = Apollo.BaseMutationOptions<AddActionToStepMutation, AddActionToStepMutationVariables>;
export const RemoveActionFromStepDocument = gql`
    mutation RemoveActionFromStep($input: RemoveActionFromStepInput!) {
  removeActionFromStep(input: $input) {
    step {
      id
      actions {
        id
        title
        type
      }
    }
  }
}
    `;
export type RemoveActionFromStepMutationFn = Apollo.MutationFunction<RemoveActionFromStepMutation, RemoveActionFromStepMutationVariables>;

/**
 * __useRemoveActionFromStepMutation__
 *
 * To run a mutation, you first call `useRemoveActionFromStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveActionFromStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeActionFromStepMutation, { data, loading, error }] = useRemoveActionFromStepMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveActionFromStepMutation(baseOptions?: Apollo.MutationHookOptions<RemoveActionFromStepMutation, RemoveActionFromStepMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveActionFromStepMutation, RemoveActionFromStepMutationVariables>(RemoveActionFromStepDocument, options);
      }
export type RemoveActionFromStepMutationHookResult = ReturnType<typeof useRemoveActionFromStepMutation>;
export type RemoveActionFromStepMutationResult = Apollo.MutationResult<RemoveActionFromStepMutation>;
export type RemoveActionFromStepMutationOptions = Apollo.BaseMutationOptions<RemoveActionFromStepMutation, RemoveActionFromStepMutationVariables>;
export const ReorderActionsDocument = gql`
    mutation ReorderActions($input: ReorderActionsInput!) {
  reorderActions(input: $input) {
    code
    success
    step {
      id
      actions {
        id
        title
      }
    }
  }
}
    `;
export type ReorderActionsMutationFn = Apollo.MutationFunction<ReorderActionsMutation, ReorderActionsMutationVariables>;

/**
 * __useReorderActionsMutation__
 *
 * To run a mutation, you first call `useReorderActionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderActionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderActionsMutation, { data, loading, error }] = useReorderActionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReorderActionsMutation(baseOptions?: Apollo.MutationHookOptions<ReorderActionsMutation, ReorderActionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReorderActionsMutation, ReorderActionsMutationVariables>(ReorderActionsDocument, options);
      }
export type ReorderActionsMutationHookResult = ReturnType<typeof useReorderActionsMutation>;
export type ReorderActionsMutationResult = Apollo.MutationResult<ReorderActionsMutation>;
export type ReorderActionsMutationOptions = Apollo.BaseMutationOptions<ReorderActionsMutation, ReorderActionsMutationVariables>;
export const StepActionsDocument = gql`
    query StepActions($step_id: String!) {
  step(id: $step_id) {
    step {
      id
      actions {
        id
        title
        type
        ... on MessageAction {
          message_id
        }
        ... on PushToEmrAction {
          emr_report_id
        }
        ... on ClinicalNoteAction {
          clinical_note_id
        }
        ... on ApiCallAction {
          api_call_id
        }
        ... on ExtensionAction {
          extension_key
        }
      }
    }
  }
}
    `;

/**
 * __useStepActionsQuery__
 *
 * To run a query within a React component, call `useStepActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStepActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStepActionsQuery({
 *   variables: {
 *      step_id: // value for 'step_id'
 *   },
 * });
 */
export function useStepActionsQuery(baseOptions: Apollo.QueryHookOptions<StepActionsQuery, StepActionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StepActionsQuery, StepActionsQueryVariables>(StepActionsDocument, options);
      }
export function useStepActionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StepActionsQuery, StepActionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StepActionsQuery, StepActionsQueryVariables>(StepActionsDocument, options);
        }
export type StepActionsQueryHookResult = ReturnType<typeof useStepActionsQuery>;
export type StepActionsLazyQueryHookResult = ReturnType<typeof useStepActionsLazyQuery>;
export type StepActionsQueryResult = Apollo.QueryResult<StepActionsQuery, StepActionsQueryVariables>;
export const SetActionStakeholdersDocument = gql`
    mutation SetActionStakeholders($input: SetActionStakeholdersInput!) {
  setActionStakeholders(input: $input) {
    action {
      id
      title
      stakeholders {
        id
        label {
          en
        }
        clinical_app_role
      }
      status {
        ...ConfigurationStatus
      }
    }
  }
}
    ${ConfigurationStatusFragmentDoc}`;
export type SetActionStakeholdersMutationFn = Apollo.MutationFunction<SetActionStakeholdersMutation, SetActionStakeholdersMutationVariables>;

/**
 * __useSetActionStakeholdersMutation__
 *
 * To run a mutation, you first call `useSetActionStakeholdersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetActionStakeholdersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setActionStakeholdersMutation, { data, loading, error }] = useSetActionStakeholdersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetActionStakeholdersMutation(baseOptions?: Apollo.MutationHookOptions<SetActionStakeholdersMutation, SetActionStakeholdersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetActionStakeholdersMutation, SetActionStakeholdersMutationVariables>(SetActionStakeholdersDocument, options);
      }
export type SetActionStakeholdersMutationHookResult = ReturnType<typeof useSetActionStakeholdersMutation>;
export type SetActionStakeholdersMutationResult = Apollo.MutationResult<SetActionStakeholdersMutation>;
export type SetActionStakeholdersMutationOptions = Apollo.BaseMutationOptions<SetActionStakeholdersMutation, SetActionStakeholdersMutationVariables>;
export const GetActionTitleDocument = gql`
    query GetActionTitle($id: String!) {
  action(id: $id) {
    action {
      id
      title
    }
  }
}
    `;

/**
 * __useGetActionTitleQuery__
 *
 * To run a query within a React component, call `useGetActionTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActionTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActionTitleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetActionTitleQuery(baseOptions: Apollo.QueryHookOptions<GetActionTitleQuery, GetActionTitleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActionTitleQuery, GetActionTitleQueryVariables>(GetActionTitleDocument, options);
      }
export function useGetActionTitleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActionTitleQuery, GetActionTitleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActionTitleQuery, GetActionTitleQueryVariables>(GetActionTitleDocument, options);
        }
export type GetActionTitleQueryHookResult = ReturnType<typeof useGetActionTitleQuery>;
export type GetActionTitleLazyQueryHookResult = ReturnType<typeof useGetActionTitleLazyQuery>;
export type GetActionTitleQueryResult = Apollo.QueryResult<GetActionTitleQuery, GetActionTitleQueryVariables>;
export const SetActionTitleDocument = gql`
    mutation SetActionTitle($input: SetActionTitleInput!) {
  setActionTitle(input: $input) {
    action {
      id
      title
    }
  }
}
    `;
export type SetActionTitleMutationFn = Apollo.MutationFunction<SetActionTitleMutation, SetActionTitleMutationVariables>;

/**
 * __useSetActionTitleMutation__
 *
 * To run a mutation, you first call `useSetActionTitleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetActionTitleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setActionTitleMutation, { data, loading, error }] = useSetActionTitleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetActionTitleMutation(baseOptions?: Apollo.MutationHookOptions<SetActionTitleMutation, SetActionTitleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetActionTitleMutation, SetActionTitleMutationVariables>(SetActionTitleDocument, options);
      }
export type SetActionTitleMutationHookResult = ReturnType<typeof useSetActionTitleMutation>;
export type SetActionTitleMutationResult = Apollo.MutationResult<SetActionTitleMutation>;
export type SetActionTitleMutationOptions = Apollo.BaseMutationOptions<SetActionTitleMutation, SetActionTitleMutationVariables>;
export const GetCalculationsDocument = gql`
    query GetCalculations {
  calculations {
    calculations {
      id
      title
      description
    }
  }
}
    `;

/**
 * __useGetCalculationsQuery__
 *
 * To run a query within a React component, call `useGetCalculationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCalculationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCalculationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCalculationsQuery(baseOptions?: Apollo.QueryHookOptions<GetCalculationsQuery, GetCalculationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCalculationsQuery, GetCalculationsQueryVariables>(GetCalculationsDocument, options);
      }
export function useGetCalculationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCalculationsQuery, GetCalculationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCalculationsQuery, GetCalculationsQueryVariables>(GetCalculationsDocument, options);
        }
export type GetCalculationsQueryHookResult = ReturnType<typeof useGetCalculationsQuery>;
export type GetCalculationsLazyQueryHookResult = ReturnType<typeof useGetCalculationsLazyQuery>;
export type GetCalculationsQueryResult = Apollo.QueryResult<GetCalculationsQuery, GetCalculationsQueryVariables>;
export const SetActionCalculationIdDocument = gql`
    mutation SetActionCalculationId($input: SetActionCalculationIdInput!) {
  setActionCalculationId(input: $input) {
    action {
      id
      title
      ...CalculationActionFragment
      status {
        ...ConfigurationStatus
      }
    }
  }
}
    ${CalculationActionFragmentFragmentDoc}
${ConfigurationStatusFragmentDoc}`;
export type SetActionCalculationIdMutationFn = Apollo.MutationFunction<SetActionCalculationIdMutation, SetActionCalculationIdMutationVariables>;

/**
 * __useSetActionCalculationIdMutation__
 *
 * To run a mutation, you first call `useSetActionCalculationIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetActionCalculationIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setActionCalculationIdMutation, { data, loading, error }] = useSetActionCalculationIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetActionCalculationIdMutation(baseOptions?: Apollo.MutationHookOptions<SetActionCalculationIdMutation, SetActionCalculationIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetActionCalculationIdMutation, SetActionCalculationIdMutationVariables>(SetActionCalculationIdDocument, options);
      }
export type SetActionCalculationIdMutationHookResult = ReturnType<typeof useSetActionCalculationIdMutation>;
export type SetActionCalculationIdMutationResult = Apollo.MutationResult<SetActionCalculationIdMutation>;
export type SetActionCalculationIdMutationOptions = Apollo.BaseMutationOptions<SetActionCalculationIdMutation, SetActionCalculationIdMutationVariables>;
export const SetActionCalculationInputDocument = gql`
    mutation SetActionCalculationInput($input: SetCalculationDataPointInput!) {
  setActionCalculationInput(input: $input) {
    action {
      id
      title
      ...CalculationActionFragment
      status {
        ...ConfigurationStatus
      }
    }
  }
}
    ${CalculationActionFragmentFragmentDoc}
${ConfigurationStatusFragmentDoc}`;
export type SetActionCalculationInputMutationFn = Apollo.MutationFunction<SetActionCalculationInputMutation, SetActionCalculationInputMutationVariables>;

/**
 * __useSetActionCalculationInputMutation__
 *
 * To run a mutation, you first call `useSetActionCalculationInputMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetActionCalculationInputMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setActionCalculationInputMutation, { data, loading, error }] = useSetActionCalculationInputMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetActionCalculationInputMutation(baseOptions?: Apollo.MutationHookOptions<SetActionCalculationInputMutation, SetActionCalculationInputMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetActionCalculationInputMutation, SetActionCalculationInputMutationVariables>(SetActionCalculationInputDocument, options);
      }
export type SetActionCalculationInputMutationHookResult = ReturnType<typeof useSetActionCalculationInputMutation>;
export type SetActionCalculationInputMutationResult = Apollo.MutationResult<SetActionCalculationInputMutation>;
export type SetActionCalculationInputMutationOptions = Apollo.BaseMutationOptions<SetActionCalculationInputMutation, SetActionCalculationInputMutationVariables>;
export const SetActionChecklistItemsDocument = gql`
    mutation SetActionChecklistItems($input: SetActionChecklistItemsInput!) {
  setActionChecklistItems(input: $input) {
    action {
      id
      title
      ...CheckListFragment
    }
  }
}
    ${CheckListFragmentFragmentDoc}`;
export type SetActionChecklistItemsMutationFn = Apollo.MutationFunction<SetActionChecklistItemsMutation, SetActionChecklistItemsMutationVariables>;

/**
 * __useSetActionChecklistItemsMutation__
 *
 * To run a mutation, you first call `useSetActionChecklistItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetActionChecklistItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setActionChecklistItemsMutation, { data, loading, error }] = useSetActionChecklistItemsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetActionChecklistItemsMutation(baseOptions?: Apollo.MutationHookOptions<SetActionChecklistItemsMutation, SetActionChecklistItemsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetActionChecklistItemsMutation, SetActionChecklistItemsMutationVariables>(SetActionChecklistItemsDocument, options);
      }
export type SetActionChecklistItemsMutationHookResult = ReturnType<typeof useSetActionChecklistItemsMutation>;
export type SetActionChecklistItemsMutationResult = Apollo.MutationResult<SetActionChecklistItemsMutation>;
export type SetActionChecklistItemsMutationOptions = Apollo.BaseMutationOptions<SetActionChecklistItemsMutation, SetActionChecklistItemsMutationVariables>;
export const CreatePathwayDocument = gql`
    mutation CreatePathway($input: CreatePathwayInput!) {
  createPathway(input: $input) {
    pathway {
      ...Pathway
    }
  }
}
    ${PathwayFragmentDoc}`;
export type CreatePathwayMutationFn = Apollo.MutationFunction<CreatePathwayMutation, CreatePathwayMutationVariables>;

/**
 * __useCreatePathwayMutation__
 *
 * To run a mutation, you first call `useCreatePathwayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePathwayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPathwayMutation, { data, loading, error }] = useCreatePathwayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePathwayMutation(baseOptions?: Apollo.MutationHookOptions<CreatePathwayMutation, CreatePathwayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePathwayMutation, CreatePathwayMutationVariables>(CreatePathwayDocument, options);
      }
export type CreatePathwayMutationHookResult = ReturnType<typeof useCreatePathwayMutation>;
export type CreatePathwayMutationResult = Apollo.MutationResult<CreatePathwayMutation>;
export type CreatePathwayMutationOptions = Apollo.BaseMutationOptions<CreatePathwayMutation, CreatePathwayMutationVariables>;
export const AddDerivedDataPointDefinitionDocument = gql`
    mutation AddDerivedDataPointDefinition($input: AddDerivedDataPointDefinitionInput!) {
  addDerivedDataPointDefinition(input: $input) {
    code
    success
    data_point_definition {
      key
      source_definition_id
      id
      category
      valueType
      unit
      optional
      title
      pii
      jsonPath
    }
  }
}
    `;
export type AddDerivedDataPointDefinitionMutationFn = Apollo.MutationFunction<AddDerivedDataPointDefinitionMutation, AddDerivedDataPointDefinitionMutationVariables>;

/**
 * __useAddDerivedDataPointDefinitionMutation__
 *
 * To run a mutation, you first call `useAddDerivedDataPointDefinitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDerivedDataPointDefinitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDerivedDataPointDefinitionMutation, { data, loading, error }] = useAddDerivedDataPointDefinitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddDerivedDataPointDefinitionMutation(baseOptions?: Apollo.MutationHookOptions<AddDerivedDataPointDefinitionMutation, AddDerivedDataPointDefinitionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDerivedDataPointDefinitionMutation, AddDerivedDataPointDefinitionMutationVariables>(AddDerivedDataPointDefinitionDocument, options);
      }
export type AddDerivedDataPointDefinitionMutationHookResult = ReturnType<typeof useAddDerivedDataPointDefinitionMutation>;
export type AddDerivedDataPointDefinitionMutationResult = Apollo.MutationResult<AddDerivedDataPointDefinitionMutation>;
export type AddDerivedDataPointDefinitionMutationOptions = Apollo.BaseMutationOptions<AddDerivedDataPointDefinitionMutation, AddDerivedDataPointDefinitionMutationVariables>;
export const CreateFormDocument = gql`
    mutation CreateForm($input: CreateFormInput!) {
  createForm(input: $input) {
    success
    form {
      id
    }
  }
}
    `;
export type CreateFormMutationFn = Apollo.MutationFunction<CreateFormMutation, CreateFormMutationVariables>;

/**
 * __useCreateFormMutation__
 *
 * To run a mutation, you first call `useCreateFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFormMutation, { data, loading, error }] = useCreateFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFormMutation(baseOptions?: Apollo.MutationHookOptions<CreateFormMutation, CreateFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFormMutation, CreateFormMutationVariables>(CreateFormDocument, options);
      }
export type CreateFormMutationHookResult = ReturnType<typeof useCreateFormMutation>;
export type CreateFormMutationResult = Apollo.MutationResult<CreateFormMutation>;
export type CreateFormMutationOptions = Apollo.BaseMutationOptions<CreateFormMutation, CreateFormMutationVariables>;
export const GetFormsDocument = gql`
    query GetForms {
  forms {
    forms {
      id
      title
      description
      permissions {
        write
      }
    }
  }
}
    `;

/**
 * __useGetFormsQuery__
 *
 * To run a query within a React component, call `useGetFormsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFormsQuery(baseOptions?: Apollo.QueryHookOptions<GetFormsQuery, GetFormsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFormsQuery, GetFormsQueryVariables>(GetFormsDocument, options);
      }
export function useGetFormsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFormsQuery, GetFormsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFormsQuery, GetFormsQueryVariables>(GetFormsDocument, options);
        }
export type GetFormsQueryHookResult = ReturnType<typeof useGetFormsQuery>;
export type GetFormsLazyQueryHookResult = ReturnType<typeof useGetFormsLazyQuery>;
export type GetFormsQueryResult = Apollo.QueryResult<GetFormsQuery, GetFormsQueryVariables>;
export const SetActionFormDisplayModeDocument = gql`
    mutation SetActionFormDisplayMode($input: SetActionFormDisplayModeInput!) {
  setActionFormDisplayMode(input: $input) {
    action {
      id
      title
      ...FormActionFragment
    }
  }
}
    ${FormActionFragmentFragmentDoc}`;
export type SetActionFormDisplayModeMutationFn = Apollo.MutationFunction<SetActionFormDisplayModeMutation, SetActionFormDisplayModeMutationVariables>;

/**
 * __useSetActionFormDisplayModeMutation__
 *
 * To run a mutation, you first call `useSetActionFormDisplayModeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetActionFormDisplayModeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setActionFormDisplayModeMutation, { data, loading, error }] = useSetActionFormDisplayModeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetActionFormDisplayModeMutation(baseOptions?: Apollo.MutationHookOptions<SetActionFormDisplayModeMutation, SetActionFormDisplayModeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetActionFormDisplayModeMutation, SetActionFormDisplayModeMutationVariables>(SetActionFormDisplayModeDocument, options);
      }
export type SetActionFormDisplayModeMutationHookResult = ReturnType<typeof useSetActionFormDisplayModeMutation>;
export type SetActionFormDisplayModeMutationResult = Apollo.MutationResult<SetActionFormDisplayModeMutation>;
export type SetActionFormDisplayModeMutationOptions = Apollo.BaseMutationOptions<SetActionFormDisplayModeMutation, SetActionFormDisplayModeMutationVariables>;
export const SetActionFormIdDocument = gql`
    mutation SetActionFormId($input: SetActionFormIdInput!) {
  setActionFormId(input: $input) {
    action {
      id
      title
      status {
        ...ConfigurationStatus
      }
      ...FormActionFragment
    }
  }
}
    ${ConfigurationStatusFragmentDoc}
${FormActionFragmentFragmentDoc}`;
export type SetActionFormIdMutationFn = Apollo.MutationFunction<SetActionFormIdMutation, SetActionFormIdMutationVariables>;

/**
 * __useSetActionFormIdMutation__
 *
 * To run a mutation, you first call `useSetActionFormIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetActionFormIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setActionFormIdMutation, { data, loading, error }] = useSetActionFormIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetActionFormIdMutation(baseOptions?: Apollo.MutationHookOptions<SetActionFormIdMutation, SetActionFormIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetActionFormIdMutation, SetActionFormIdMutationVariables>(SetActionFormIdDocument, options);
      }
export type SetActionFormIdMutationHookResult = ReturnType<typeof useSetActionFormIdMutation>;
export type SetActionFormIdMutationResult = Apollo.MutationResult<SetActionFormIdMutation>;
export type SetActionFormIdMutationOptions = Apollo.BaseMutationOptions<SetActionFormIdMutation, SetActionFormIdMutationVariables>;
export const AddQuestionToFormDocument = gql`
    mutation AddQuestionToForm($input: AddQuestionToFormInput!) {
  addQuestionToForm(input: $input) {
    form {
      ...Form
    }
    question {
      ...Question
    }
  }
}
    ${FormFragmentDoc}
${QuestionFragmentDoc}`;
export type AddQuestionToFormMutationFn = Apollo.MutationFunction<AddQuestionToFormMutation, AddQuestionToFormMutationVariables>;

/**
 * __useAddQuestionToFormMutation__
 *
 * To run a mutation, you first call `useAddQuestionToFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddQuestionToFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addQuestionToFormMutation, { data, loading, error }] = useAddQuestionToFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddQuestionToFormMutation(baseOptions?: Apollo.MutationHookOptions<AddQuestionToFormMutation, AddQuestionToFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddQuestionToFormMutation, AddQuestionToFormMutationVariables>(AddQuestionToFormDocument, options);
      }
export type AddQuestionToFormMutationHookResult = ReturnType<typeof useAddQuestionToFormMutation>;
export type AddQuestionToFormMutationResult = Apollo.MutationResult<AddQuestionToFormMutation>;
export type AddQuestionToFormMutationOptions = Apollo.BaseMutationOptions<AddQuestionToFormMutation, AddQuestionToFormMutationVariables>;
export const AddQuestionsToFormDocument = gql`
    mutation AddQuestionsToForm($input: AddQuestionsToFormInput!) {
  addQuestionsToForm(input: $input) {
    form {
      ...Form
    }
    questions {
      ...Question
    }
  }
}
    ${FormFragmentDoc}
${QuestionFragmentDoc}`;
export type AddQuestionsToFormMutationFn = Apollo.MutationFunction<AddQuestionsToFormMutation, AddQuestionsToFormMutationVariables>;

/**
 * __useAddQuestionsToFormMutation__
 *
 * To run a mutation, you first call `useAddQuestionsToFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddQuestionsToFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addQuestionsToFormMutation, { data, loading, error }] = useAddQuestionsToFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddQuestionsToFormMutation(baseOptions?: Apollo.MutationHookOptions<AddQuestionsToFormMutation, AddQuestionsToFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddQuestionsToFormMutation, AddQuestionsToFormMutationVariables>(AddQuestionsToFormDocument, options);
      }
export type AddQuestionsToFormMutationHookResult = ReturnType<typeof useAddQuestionsToFormMutation>;
export type AddQuestionsToFormMutationResult = Apollo.MutationResult<AddQuestionsToFormMutation>;
export type AddQuestionsToFormMutationOptions = Apollo.BaseMutationOptions<AddQuestionsToFormMutation, AddQuestionsToFormMutationVariables>;
export const DuplicateQuestionDocument = gql`
    mutation DuplicateQuestion($input: DuplicateQuestionInput!) {
  duplicateQuestion(input: $input) {
    form {
      id
      questions {
        ...Question
      }
    }
    question {
      ...Question
    }
  }
}
    ${QuestionFragmentDoc}`;
export type DuplicateQuestionMutationFn = Apollo.MutationFunction<DuplicateQuestionMutation, DuplicateQuestionMutationVariables>;

/**
 * __useDuplicateQuestionMutation__
 *
 * To run a mutation, you first call `useDuplicateQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateQuestionMutation, { data, loading, error }] = useDuplicateQuestionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDuplicateQuestionMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateQuestionMutation, DuplicateQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateQuestionMutation, DuplicateQuestionMutationVariables>(DuplicateQuestionDocument, options);
      }
export type DuplicateQuestionMutationHookResult = ReturnType<typeof useDuplicateQuestionMutation>;
export type DuplicateQuestionMutationResult = Apollo.MutationResult<DuplicateQuestionMutation>;
export type DuplicateQuestionMutationOptions = Apollo.BaseMutationOptions<DuplicateQuestionMutation, DuplicateQuestionMutationVariables>;
export const EvaluateFormRulesDocument = gql`
    mutation EvaluateFormRules($input: EvaluateFormRulesInput!) {
  evaluateFormRules(input: $input) {
    results {
      question_id
      rule_id
      satisfied
    }
  }
}
    `;
export type EvaluateFormRulesMutationFn = Apollo.MutationFunction<EvaluateFormRulesMutation, EvaluateFormRulesMutationVariables>;

/**
 * __useEvaluateFormRulesMutation__
 *
 * To run a mutation, you first call `useEvaluateFormRulesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEvaluateFormRulesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [evaluateFormRulesMutation, { data, loading, error }] = useEvaluateFormRulesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEvaluateFormRulesMutation(baseOptions?: Apollo.MutationHookOptions<EvaluateFormRulesMutation, EvaluateFormRulesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EvaluateFormRulesMutation, EvaluateFormRulesMutationVariables>(EvaluateFormRulesDocument, options);
      }
export type EvaluateFormRulesMutationHookResult = ReturnType<typeof useEvaluateFormRulesMutation>;
export type EvaluateFormRulesMutationResult = Apollo.MutationResult<EvaluateFormRulesMutation>;
export type EvaluateFormRulesMutationOptions = Apollo.BaseMutationOptions<EvaluateFormRulesMutation, EvaluateFormRulesMutationVariables>;
export const RemoveQuestionFromFormDocument = gql`
    mutation RemoveQuestionFromForm($input: RemoveQuestionFromFormInput!) {
  removeQuestionFromForm(input: $input) {
    form {
      ...Form
    }
  }
}
    ${FormFragmentDoc}`;
export type RemoveQuestionFromFormMutationFn = Apollo.MutationFunction<RemoveQuestionFromFormMutation, RemoveQuestionFromFormMutationVariables>;

/**
 * __useRemoveQuestionFromFormMutation__
 *
 * To run a mutation, you first call `useRemoveQuestionFromFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveQuestionFromFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeQuestionFromFormMutation, { data, loading, error }] = useRemoveQuestionFromFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveQuestionFromFormMutation(baseOptions?: Apollo.MutationHookOptions<RemoveQuestionFromFormMutation, RemoveQuestionFromFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveQuestionFromFormMutation, RemoveQuestionFromFormMutationVariables>(RemoveQuestionFromFormDocument, options);
      }
export type RemoveQuestionFromFormMutationHookResult = ReturnType<typeof useRemoveQuestionFromFormMutation>;
export type RemoveQuestionFromFormMutationResult = Apollo.MutationResult<RemoveQuestionFromFormMutation>;
export type RemoveQuestionFromFormMutationOptions = Apollo.BaseMutationOptions<RemoveQuestionFromFormMutation, RemoveQuestionFromFormMutationVariables>;
export const ReorderQuestionsDocument = gql`
    mutation ReorderQuestions($input: ReorderQuestionsInput!) {
  reorderQuestions(input: $input) {
    form {
      ...Form
    }
  }
}
    ${FormFragmentDoc}`;
export type ReorderQuestionsMutationFn = Apollo.MutationFunction<ReorderQuestionsMutation, ReorderQuestionsMutationVariables>;

/**
 * __useReorderQuestionsMutation__
 *
 * To run a mutation, you first call `useReorderQuestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderQuestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderQuestionsMutation, { data, loading, error }] = useReorderQuestionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReorderQuestionsMutation(baseOptions?: Apollo.MutationHookOptions<ReorderQuestionsMutation, ReorderQuestionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReorderQuestionsMutation, ReorderQuestionsMutationVariables>(ReorderQuestionsDocument, options);
      }
export type ReorderQuestionsMutationHookResult = ReturnType<typeof useReorderQuestionsMutation>;
export type ReorderQuestionsMutationResult = Apollo.MutationResult<ReorderQuestionsMutation>;
export type ReorderQuestionsMutationOptions = Apollo.BaseMutationOptions<ReorderQuestionsMutation, ReorderQuestionsMutationVariables>;
export const SetFormKeyDocument = gql`
    mutation SetFormKey($input: SetFormKeyInput!) {
  setFormKey(input: $input) {
    form {
      ...Form
    }
  }
}
    ${FormFragmentDoc}`;
export type SetFormKeyMutationFn = Apollo.MutationFunction<SetFormKeyMutation, SetFormKeyMutationVariables>;

/**
 * __useSetFormKeyMutation__
 *
 * To run a mutation, you first call `useSetFormKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetFormKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setFormKeyMutation, { data, loading, error }] = useSetFormKeyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetFormKeyMutation(baseOptions?: Apollo.MutationHookOptions<SetFormKeyMutation, SetFormKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetFormKeyMutation, SetFormKeyMutationVariables>(SetFormKeyDocument, options);
      }
export type SetFormKeyMutationHookResult = ReturnType<typeof useSetFormKeyMutation>;
export type SetFormKeyMutationResult = Apollo.MutationResult<SetFormKeyMutation>;
export type SetFormKeyMutationOptions = Apollo.BaseMutationOptions<SetFormKeyMutation, SetFormKeyMutationVariables>;
export const SetFormMetadataDocument = gql`
    mutation SetFormMetadata($input: SetFormMetadataInput!) {
  setFormMetadata(input: $input) {
    form {
      ...Form
    }
  }
}
    ${FormFragmentDoc}`;
export type SetFormMetadataMutationFn = Apollo.MutationFunction<SetFormMetadataMutation, SetFormMetadataMutationVariables>;

/**
 * __useSetFormMetadataMutation__
 *
 * To run a mutation, you first call `useSetFormMetadataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetFormMetadataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setFormMetadataMutation, { data, loading, error }] = useSetFormMetadataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetFormMetadataMutation(baseOptions?: Apollo.MutationHookOptions<SetFormMetadataMutation, SetFormMetadataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetFormMetadataMutation, SetFormMetadataMutationVariables>(SetFormMetadataDocument, options);
      }
export type SetFormMetadataMutationHookResult = ReturnType<typeof useSetFormMetadataMutation>;
export type SetFormMetadataMutationResult = Apollo.MutationResult<SetFormMetadataMutation>;
export type SetFormMetadataMutationOptions = Apollo.BaseMutationOptions<SetFormMetadataMutation, SetFormMetadataMutationVariables>;
export const SetFormTitleDocument = gql`
    mutation SetFormTitle($input: SetFormTitleInput!) {
  setFormTitle(input: $input) {
    form {
      ...Form
    }
  }
}
    ${FormFragmentDoc}`;
export type SetFormTitleMutationFn = Apollo.MutationFunction<SetFormTitleMutation, SetFormTitleMutationVariables>;

/**
 * __useSetFormTitleMutation__
 *
 * To run a mutation, you first call `useSetFormTitleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetFormTitleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setFormTitleMutation, { data, loading, error }] = useSetFormTitleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetFormTitleMutation(baseOptions?: Apollo.MutationHookOptions<SetFormTitleMutation, SetFormTitleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetFormTitleMutation, SetFormTitleMutationVariables>(SetFormTitleDocument, options);
      }
export type SetFormTitleMutationHookResult = ReturnType<typeof useSetFormTitleMutation>;
export type SetFormTitleMutationResult = Apollo.MutationResult<SetFormTitleMutation>;
export type SetFormTitleMutationOptions = Apollo.BaseMutationOptions<SetFormTitleMutation, SetFormTitleMutationVariables>;
export const SetFormTrademarkDocument = gql`
    mutation SetFormTrademark($input: SetFormTrademarkInput!) {
  setFormTrademark(input: $input) {
    form {
      id
      trademark
    }
  }
}
    `;
export type SetFormTrademarkMutationFn = Apollo.MutationFunction<SetFormTrademarkMutation, SetFormTrademarkMutationVariables>;

/**
 * __useSetFormTrademarkMutation__
 *
 * To run a mutation, you first call `useSetFormTrademarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetFormTrademarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setFormTrademarkMutation, { data, loading, error }] = useSetFormTrademarkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetFormTrademarkMutation(baseOptions?: Apollo.MutationHookOptions<SetFormTrademarkMutation, SetFormTrademarkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetFormTrademarkMutation, SetFormTrademarkMutationVariables>(SetFormTrademarkDocument, options);
      }
export type SetFormTrademarkMutationHookResult = ReturnType<typeof useSetFormTrademarkMutation>;
export type SetFormTrademarkMutationResult = Apollo.MutationResult<SetFormTrademarkMutation>;
export type SetFormTrademarkMutationOptions = Apollo.BaseMutationOptions<SetFormTrademarkMutation, SetFormTrademarkMutationVariables>;
export const GetActionMessageDocument = gql`
    query GetActionMessage($id: String!) {
  action(id: $id) {
    action {
      id
      stakeholders {
        id
        label {
          en
        }
      }
      status {
        ...ConfigurationStatus
      }
      ... on MessageAction {
        message {
          id
          subject
          body
          attachments {
            id
            name
            url
            type
          }
        }
      }
    }
  }
}
    ${ConfigurationStatusFragmentDoc}`;

/**
 * __useGetActionMessageQuery__
 *
 * To run a query within a React component, call `useGetActionMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActionMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActionMessageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetActionMessageQuery(baseOptions: Apollo.QueryHookOptions<GetActionMessageQuery, GetActionMessageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActionMessageQuery, GetActionMessageQueryVariables>(GetActionMessageDocument, options);
      }
export function useGetActionMessageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActionMessageQuery, GetActionMessageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActionMessageQuery, GetActionMessageQueryVariables>(GetActionMessageDocument, options);
        }
export type GetActionMessageQueryHookResult = ReturnType<typeof useGetActionMessageQuery>;
export type GetActionMessageLazyQueryHookResult = ReturnType<typeof useGetActionMessageLazyQuery>;
export type GetActionMessageQueryResult = Apollo.QueryResult<GetActionMessageQuery, GetActionMessageQueryVariables>;
export const SetActionMessageIdDocument = gql`
    mutation SetActionMessageId($input: SetActionMessageIdInput!) {
  setActionMessageId(input: $input) {
    action {
      id
      title
      ...MessageActionFragment
    }
  }
}
    ${MessageActionFragmentFragmentDoc}`;
export type SetActionMessageIdMutationFn = Apollo.MutationFunction<SetActionMessageIdMutation, SetActionMessageIdMutationVariables>;

/**
 * __useSetActionMessageIdMutation__
 *
 * To run a mutation, you first call `useSetActionMessageIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetActionMessageIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setActionMessageIdMutation, { data, loading, error }] = useSetActionMessageIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetActionMessageIdMutation(baseOptions?: Apollo.MutationHookOptions<SetActionMessageIdMutation, SetActionMessageIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetActionMessageIdMutation, SetActionMessageIdMutationVariables>(SetActionMessageIdDocument, options);
      }
export type SetActionMessageIdMutationHookResult = ReturnType<typeof useSetActionMessageIdMutation>;
export type SetActionMessageIdMutationResult = Apollo.MutationResult<SetActionMessageIdMutation>;
export type SetActionMessageIdMutationOptions = Apollo.BaseMutationOptions<SetActionMessageIdMutation, SetActionMessageIdMutationVariables>;
export const DuplicateCareflowDocument = gql`
    mutation DuplicateCareflow($input: DuplicateCareflowInput!) {
  duplicateCareflow(input: $input) {
    success
    careflow_id
  }
}
    `;
export type DuplicateCareflowMutationFn = Apollo.MutationFunction<DuplicateCareflowMutation, DuplicateCareflowMutationVariables>;

/**
 * __useDuplicateCareflowMutation__
 *
 * To run a mutation, you first call `useDuplicateCareflowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateCareflowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateCareflowMutation, { data, loading, error }] = useDuplicateCareflowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDuplicateCareflowMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateCareflowMutation, DuplicateCareflowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateCareflowMutation, DuplicateCareflowMutationVariables>(DuplicateCareflowDocument, options);
      }
export type DuplicateCareflowMutationHookResult = ReturnType<typeof useDuplicateCareflowMutation>;
export type DuplicateCareflowMutationResult = Apollo.MutationResult<DuplicateCareflowMutation>;
export type DuplicateCareflowMutationOptions = Apollo.BaseMutationOptions<DuplicateCareflowMutation, DuplicateCareflowMutationVariables>;
export const PathwaysDocument = gql`
    query pathways {
  pathways {
    pathways {
      ...Pathway
    }
  }
}
    ${PathwayFragmentDoc}`;

/**
 * __usePathwaysQuery__
 *
 * To run a query within a React component, call `usePathwaysQuery` and pass it any options that fit your needs.
 * When your component renders, `usePathwaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePathwaysQuery({
 *   variables: {
 *   },
 * });
 */
export function usePathwaysQuery(baseOptions?: Apollo.QueryHookOptions<PathwaysQuery, PathwaysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PathwaysQuery, PathwaysQueryVariables>(PathwaysDocument, options);
      }
export function usePathwaysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PathwaysQuery, PathwaysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PathwaysQuery, PathwaysQueryVariables>(PathwaysDocument, options);
        }
export type PathwaysQueryHookResult = ReturnType<typeof usePathwaysQuery>;
export type PathwaysLazyQueryHookResult = ReturnType<typeof usePathwaysLazyQuery>;
export type PathwaysQueryResult = Apollo.QueryResult<PathwaysQuery, PathwaysQueryVariables>;
export const TrashPathwayDocument = gql`
    mutation TrashPathway($input: TrashPathwayInput!) {
  trashPathway(input: $input) {
    success
  }
}
    `;
export type TrashPathwayMutationFn = Apollo.MutationFunction<TrashPathwayMutation, TrashPathwayMutationVariables>;

/**
 * __useTrashPathwayMutation__
 *
 * To run a mutation, you first call `useTrashPathwayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrashPathwayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trashPathwayMutation, { data, loading, error }] = useTrashPathwayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTrashPathwayMutation(baseOptions?: Apollo.MutationHookOptions<TrashPathwayMutation, TrashPathwayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TrashPathwayMutation, TrashPathwayMutationVariables>(TrashPathwayDocument, options);
      }
export type TrashPathwayMutationHookResult = ReturnType<typeof useTrashPathwayMutation>;
export type TrashPathwayMutationResult = Apollo.MutationResult<TrashPathwayMutation>;
export type TrashPathwayMutationOptions = Apollo.BaseMutationOptions<TrashPathwayMutation, TrashPathwayMutationVariables>;
export const SetEmrReportBodyDocument = gql`
    mutation SetEmrReportBody($input: SetEmrReportBodyInput!) {
  setEmrReportBody(input: $input) {
    emr_report {
      id
      body
    }
  }
}
    `;
export type SetEmrReportBodyMutationFn = Apollo.MutationFunction<SetEmrReportBodyMutation, SetEmrReportBodyMutationVariables>;

/**
 * __useSetEmrReportBodyMutation__
 *
 * To run a mutation, you first call `useSetEmrReportBodyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetEmrReportBodyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setEmrReportBodyMutation, { data, loading, error }] = useSetEmrReportBodyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetEmrReportBodyMutation(baseOptions?: Apollo.MutationHookOptions<SetEmrReportBodyMutation, SetEmrReportBodyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetEmrReportBodyMutation, SetEmrReportBodyMutationVariables>(SetEmrReportBodyDocument, options);
      }
export type SetEmrReportBodyMutationHookResult = ReturnType<typeof useSetEmrReportBodyMutation>;
export type SetEmrReportBodyMutationResult = Apollo.MutationResult<SetEmrReportBodyMutation>;
export type SetEmrReportBodyMutationOptions = Apollo.BaseMutationOptions<SetEmrReportBodyMutation, SetEmrReportBodyMutationVariables>;
export const UpdateQuestionDocument = gql`
    mutation UpdateQuestion($input: UpdateQuestionInput!) {
  updateQuestion(input: $input) {
    question {
      id
      title
      key
      form_id
      options {
        id
        value
        label
      }
    }
  }
}
    `;
export type UpdateQuestionMutationFn = Apollo.MutationFunction<UpdateQuestionMutation, UpdateQuestionMutationVariables>;

/**
 * __useUpdateQuestionMutation__
 *
 * To run a mutation, you first call `useUpdateQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuestionMutation, { data, loading, error }] = useUpdateQuestionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateQuestionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateQuestionMutation, UpdateQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateQuestionMutation, UpdateQuestionMutationVariables>(UpdateQuestionDocument, options);
      }
export type UpdateQuestionMutationHookResult = ReturnType<typeof useUpdateQuestionMutation>;
export type UpdateQuestionMutationResult = Apollo.MutationResult<UpdateQuestionMutation>;
export type UpdateQuestionMutationOptions = Apollo.BaseMutationOptions<UpdateQuestionMutation, UpdateQuestionMutationVariables>;
export const AddRuleToQuestionDocument = gql`
    mutation AddRuleToQuestion($input: AddRuleToQuestionInput!) {
  addRuleToQuestion(input: $input) {
    question {
      ...Question
    }
    rule {
      ...Rule
    }
  }
}
    ${QuestionFragmentDoc}
${RuleFragmentDoc}`;
export type AddRuleToQuestionMutationFn = Apollo.MutationFunction<AddRuleToQuestionMutation, AddRuleToQuestionMutationVariables>;

/**
 * __useAddRuleToQuestionMutation__
 *
 * To run a mutation, you first call `useAddRuleToQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRuleToQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRuleToQuestionMutation, { data, loading, error }] = useAddRuleToQuestionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddRuleToQuestionMutation(baseOptions?: Apollo.MutationHookOptions<AddRuleToQuestionMutation, AddRuleToQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddRuleToQuestionMutation, AddRuleToQuestionMutationVariables>(AddRuleToQuestionDocument, options);
      }
export type AddRuleToQuestionMutationHookResult = ReturnType<typeof useAddRuleToQuestionMutation>;
export type AddRuleToQuestionMutationResult = Apollo.MutationResult<AddRuleToQuestionMutation>;
export type AddRuleToQuestionMutationOptions = Apollo.BaseMutationOptions<AddRuleToQuestionMutation, AddRuleToQuestionMutationVariables>;
export const GetFormDataPointDefinitionsDocument = gql`
    query GetFormDataPointDefinitions($input: FormDataPointDefinitionsInput!) {
  form_data_point_definitions(input: $input) {
    data_point_definitions {
      id
      title
      category
      key
      valueType
      possibleValues {
        label
        value
      }
      unit
      range {
        min
        max
      }
    }
  }
}
    `;

/**
 * __useGetFormDataPointDefinitionsQuery__
 *
 * To run a query within a React component, call `useGetFormDataPointDefinitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormDataPointDefinitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormDataPointDefinitionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetFormDataPointDefinitionsQuery(baseOptions: Apollo.QueryHookOptions<GetFormDataPointDefinitionsQuery, GetFormDataPointDefinitionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFormDataPointDefinitionsQuery, GetFormDataPointDefinitionsQueryVariables>(GetFormDataPointDefinitionsDocument, options);
      }
export function useGetFormDataPointDefinitionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFormDataPointDefinitionsQuery, GetFormDataPointDefinitionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFormDataPointDefinitionsQuery, GetFormDataPointDefinitionsQueryVariables>(GetFormDataPointDefinitionsDocument, options);
        }
export type GetFormDataPointDefinitionsQueryHookResult = ReturnType<typeof useGetFormDataPointDefinitionsQuery>;
export type GetFormDataPointDefinitionsLazyQueryHookResult = ReturnType<typeof useGetFormDataPointDefinitionsLazyQuery>;
export type GetFormDataPointDefinitionsQueryResult = Apollo.QueryResult<GetFormDataPointDefinitionsQuery, GetFormDataPointDefinitionsQueryVariables>;
export const RemoveRuleFromQuestionDocument = gql`
    mutation RemoveRuleFromQuestion($input: RemoveRuleFromQuestionInput!) {
  removeRuleFromQuestion(input: $input) {
    question {
      ...Question
    }
  }
}
    ${QuestionFragmentDoc}`;
export type RemoveRuleFromQuestionMutationFn = Apollo.MutationFunction<RemoveRuleFromQuestionMutation, RemoveRuleFromQuestionMutationVariables>;

/**
 * __useRemoveRuleFromQuestionMutation__
 *
 * To run a mutation, you first call `useRemoveRuleFromQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRuleFromQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRuleFromQuestionMutation, { data, loading, error }] = useRemoveRuleFromQuestionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveRuleFromQuestionMutation(baseOptions?: Apollo.MutationHookOptions<RemoveRuleFromQuestionMutation, RemoveRuleFromQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveRuleFromQuestionMutation, RemoveRuleFromQuestionMutationVariables>(RemoveRuleFromQuestionDocument, options);
      }
export type RemoveRuleFromQuestionMutationHookResult = ReturnType<typeof useRemoveRuleFromQuestionMutation>;
export type RemoveRuleFromQuestionMutationResult = Apollo.MutationResult<RemoveRuleFromQuestionMutation>;
export type RemoveRuleFromQuestionMutationOptions = Apollo.BaseMutationOptions<RemoveRuleFromQuestionMutation, RemoveRuleFromQuestionMutationVariables>;
export const AddConditionToRuleDocument = gql`
    mutation AddConditionToRule($input: AddConditionToRuleInput!) {
  addConditionToRule(input: $input) {
    rule {
      ...Rule
    }
  }
}
    ${RuleFragmentDoc}`;
export type AddConditionToRuleMutationFn = Apollo.MutationFunction<AddConditionToRuleMutation, AddConditionToRuleMutationVariables>;

/**
 * __useAddConditionToRuleMutation__
 *
 * To run a mutation, you first call `useAddConditionToRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddConditionToRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addConditionToRuleMutation, { data, loading, error }] = useAddConditionToRuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddConditionToRuleMutation(baseOptions?: Apollo.MutationHookOptions<AddConditionToRuleMutation, AddConditionToRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddConditionToRuleMutation, AddConditionToRuleMutationVariables>(AddConditionToRuleDocument, options);
      }
export type AddConditionToRuleMutationHookResult = ReturnType<typeof useAddConditionToRuleMutation>;
export type AddConditionToRuleMutationResult = Apollo.MutationResult<AddConditionToRuleMutation>;
export type AddConditionToRuleMutationOptions = Apollo.BaseMutationOptions<AddConditionToRuleMutation, AddConditionToRuleMutationVariables>;
export const GetRuleDocument = gql`
    query GetRule($id: String!) {
  rule(id: $id) {
    rule {
      ...Rule
    }
  }
}
    ${RuleFragmentDoc}`;

/**
 * __useGetRuleQuery__
 *
 * To run a query within a React component, call `useGetRuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRuleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRuleQuery(baseOptions: Apollo.QueryHookOptions<GetRuleQuery, GetRuleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRuleQuery, GetRuleQueryVariables>(GetRuleDocument, options);
      }
export function useGetRuleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRuleQuery, GetRuleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRuleQuery, GetRuleQueryVariables>(GetRuleDocument, options);
        }
export type GetRuleQueryHookResult = ReturnType<typeof useGetRuleQuery>;
export type GetRuleLazyQueryHookResult = ReturnType<typeof useGetRuleLazyQuery>;
export type GetRuleQueryResult = Apollo.QueryResult<GetRuleQuery, GetRuleQueryVariables>;
export const RemoveConditionFromRuleDocument = gql`
    mutation RemoveConditionFromRule($input: RemoveConditionFromRuleInput!) {
  removeConditionFromRule(input: $input) {
    code
    success
    rule {
      ...Rule
    }
  }
}
    ${RuleFragmentDoc}`;
export type RemoveConditionFromRuleMutationFn = Apollo.MutationFunction<RemoveConditionFromRuleMutation, RemoveConditionFromRuleMutationVariables>;

/**
 * __useRemoveConditionFromRuleMutation__
 *
 * To run a mutation, you first call `useRemoveConditionFromRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveConditionFromRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeConditionFromRuleMutation, { data, loading, error }] = useRemoveConditionFromRuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveConditionFromRuleMutation(baseOptions?: Apollo.MutationHookOptions<RemoveConditionFromRuleMutation, RemoveConditionFromRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveConditionFromRuleMutation, RemoveConditionFromRuleMutationVariables>(RemoveConditionFromRuleDocument, options);
      }
export type RemoveConditionFromRuleMutationHookResult = ReturnType<typeof useRemoveConditionFromRuleMutation>;
export type RemoveConditionFromRuleMutationResult = Apollo.MutationResult<RemoveConditionFromRuleMutation>;
export type RemoveConditionFromRuleMutationOptions = Apollo.BaseMutationOptions<RemoveConditionFromRuleMutation, RemoveConditionFromRuleMutationVariables>;
export const SetConditionOperandDocument = gql`
    mutation SetConditionOperand($input: SetConditionOperandInput!) {
  setConditionOperand(input: $input) {
    rule {
      ...Rule
    }
  }
}
    ${RuleFragmentDoc}`;
export type SetConditionOperandMutationFn = Apollo.MutationFunction<SetConditionOperandMutation, SetConditionOperandMutationVariables>;

/**
 * __useSetConditionOperandMutation__
 *
 * To run a mutation, you first call `useSetConditionOperandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetConditionOperandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setConditionOperandMutation, { data, loading, error }] = useSetConditionOperandMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetConditionOperandMutation(baseOptions?: Apollo.MutationHookOptions<SetConditionOperandMutation, SetConditionOperandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetConditionOperandMutation, SetConditionOperandMutationVariables>(SetConditionOperandDocument, options);
      }
export type SetConditionOperandMutationHookResult = ReturnType<typeof useSetConditionOperandMutation>;
export type SetConditionOperandMutationResult = Apollo.MutationResult<SetConditionOperandMutation>;
export type SetConditionOperandMutationOptions = Apollo.BaseMutationOptions<SetConditionOperandMutation, SetConditionOperandMutationVariables>;
export const SetConditionOperatorDocument = gql`
    mutation SetConditionOperator($input: SetConditionOperatorInput!) {
  setConditionOperator(input: $input) {
    rule {
      ...Rule
    }
  }
}
    ${RuleFragmentDoc}`;
export type SetConditionOperatorMutationFn = Apollo.MutationFunction<SetConditionOperatorMutation, SetConditionOperatorMutationVariables>;

/**
 * __useSetConditionOperatorMutation__
 *
 * To run a mutation, you first call `useSetConditionOperatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetConditionOperatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setConditionOperatorMutation, { data, loading, error }] = useSetConditionOperatorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetConditionOperatorMutation(baseOptions?: Apollo.MutationHookOptions<SetConditionOperatorMutation, SetConditionOperatorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetConditionOperatorMutation, SetConditionOperatorMutationVariables>(SetConditionOperatorDocument, options);
      }
export type SetConditionOperatorMutationHookResult = ReturnType<typeof useSetConditionOperatorMutation>;
export type SetConditionOperatorMutationResult = Apollo.MutationResult<SetConditionOperatorMutation>;
export type SetConditionOperatorMutationOptions = Apollo.BaseMutationOptions<SetConditionOperatorMutation, SetConditionOperatorMutationVariables>;
export const SetConditionReferenceDocument = gql`
    mutation SetConditionReference($input: SetConditionReferenceInput!) {
  setConditionReference(input: $input) {
    rule {
      ...Rule
    }
  }
}
    ${RuleFragmentDoc}`;
export type SetConditionReferenceMutationFn = Apollo.MutationFunction<SetConditionReferenceMutation, SetConditionReferenceMutationVariables>;

/**
 * __useSetConditionReferenceMutation__
 *
 * To run a mutation, you first call `useSetConditionReferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetConditionReferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setConditionReferenceMutation, { data, loading, error }] = useSetConditionReferenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetConditionReferenceMutation(baseOptions?: Apollo.MutationHookOptions<SetConditionReferenceMutation, SetConditionReferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetConditionReferenceMutation, SetConditionReferenceMutationVariables>(SetConditionReferenceDocument, options);
      }
export type SetConditionReferenceMutationHookResult = ReturnType<typeof useSetConditionReferenceMutation>;
export type SetConditionReferenceMutationResult = Apollo.MutationResult<SetConditionReferenceMutation>;
export type SetConditionReferenceMutationOptions = Apollo.BaseMutationOptions<SetConditionReferenceMutation, SetConditionReferenceMutationVariables>;
export const SetRuleBooleanOperatorDocument = gql`
    mutation SetRuleBooleanOperator($input: SetRuleBooleanOperatorInput!) {
  setRuleBooleanOperator(input: $input) {
    rule {
      ...Rule
    }
  }
}
    ${RuleFragmentDoc}`;
export type SetRuleBooleanOperatorMutationFn = Apollo.MutationFunction<SetRuleBooleanOperatorMutation, SetRuleBooleanOperatorMutationVariables>;

/**
 * __useSetRuleBooleanOperatorMutation__
 *
 * To run a mutation, you first call `useSetRuleBooleanOperatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetRuleBooleanOperatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setRuleBooleanOperatorMutation, { data, loading, error }] = useSetRuleBooleanOperatorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetRuleBooleanOperatorMutation(baseOptions?: Apollo.MutationHookOptions<SetRuleBooleanOperatorMutation, SetRuleBooleanOperatorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetRuleBooleanOperatorMutation, SetRuleBooleanOperatorMutationVariables>(SetRuleBooleanOperatorDocument, options);
      }
export type SetRuleBooleanOperatorMutationHookResult = ReturnType<typeof useSetRuleBooleanOperatorMutation>;
export type SetRuleBooleanOperatorMutationResult = Apollo.MutationResult<SetRuleBooleanOperatorMutation>;
export type SetRuleBooleanOperatorMutationOptions = Apollo.BaseMutationOptions<SetRuleBooleanOperatorMutation, SetRuleBooleanOperatorMutationVariables>;
export const StepDocument = gql`
    query step($id: String!) {
  step(id: $id) {
    success
    code
    step {
      id
      title
      actions {
        id
        type
      }
      documentation {
        content {
          en
        }
        format
      }
      transitions {
        id
        status {
          status
        }
      }
    }
  }
}
    `;

/**
 * __useStepQuery__
 *
 * To run a query within a React component, call `useStepQuery` and pass it any options that fit your needs.
 * When your component renders, `useStepQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStepQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStepQuery(baseOptions: Apollo.QueryHookOptions<StepQuery, StepQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StepQuery, StepQueryVariables>(StepDocument, options);
      }
export function useStepLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StepQuery, StepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StepQuery, StepQueryVariables>(StepDocument, options);
        }
export type StepQueryHookResult = ReturnType<typeof useStepQuery>;
export type StepLazyQueryHookResult = ReturnType<typeof useStepLazyQuery>;
export type StepQueryResult = Apollo.QueryResult<StepQuery, StepQueryVariables>;
export const SetStepDocumentationDocument = gql`
    mutation SetStepDocumentation($input: SetStepDocumentationInput!) {
  setStepDocumentation(input: $input) {
    success
    code
    step {
      id
      title
      description
      documentation {
        content {
          en
        }
        format
      }
    }
  }
}
    `;
export type SetStepDocumentationMutationFn = Apollo.MutationFunction<SetStepDocumentationMutation, SetStepDocumentationMutationVariables>;

/**
 * __useSetStepDocumentationMutation__
 *
 * To run a mutation, you first call `useSetStepDocumentationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetStepDocumentationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setStepDocumentationMutation, { data, loading, error }] = useSetStepDocumentationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetStepDocumentationMutation(baseOptions?: Apollo.MutationHookOptions<SetStepDocumentationMutation, SetStepDocumentationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetStepDocumentationMutation, SetStepDocumentationMutationVariables>(SetStepDocumentationDocument, options);
      }
export type SetStepDocumentationMutationHookResult = ReturnType<typeof useSetStepDocumentationMutation>;
export type SetStepDocumentationMutationResult = Apollo.MutationResult<SetStepDocumentationMutation>;
export type SetStepDocumentationMutationOptions = Apollo.BaseMutationOptions<SetStepDocumentationMutation, SetStepDocumentationMutationVariables>;
export const AddLabelToStepDocument = gql`
    mutation AddLabelToStep($input: AddLabelToStepInput!) {
  addLabelToStep(input: $input) {
    step {
      id
      label {
        id
        color
        text
      }
    }
  }
}
    `;
export type AddLabelToStepMutationFn = Apollo.MutationFunction<AddLabelToStepMutation, AddLabelToStepMutationVariables>;

/**
 * __useAddLabelToStepMutation__
 *
 * To run a mutation, you first call `useAddLabelToStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLabelToStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLabelToStepMutation, { data, loading, error }] = useAddLabelToStepMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddLabelToStepMutation(baseOptions?: Apollo.MutationHookOptions<AddLabelToStepMutation, AddLabelToStepMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddLabelToStepMutation, AddLabelToStepMutationVariables>(AddLabelToStepDocument, options);
      }
export type AddLabelToStepMutationHookResult = ReturnType<typeof useAddLabelToStepMutation>;
export type AddLabelToStepMutationResult = Apollo.MutationResult<AddLabelToStepMutation>;
export type AddLabelToStepMutationOptions = Apollo.BaseMutationOptions<AddLabelToStepMutation, AddLabelToStepMutationVariables>;
export const GetPathwayLabelsDocument = gql`
    query GetPathwayLabels($id: String!) {
  pathway(id: $id) {
    pathway {
      id
      labels {
        id
        color
        text
      }
    }
  }
}
    `;

/**
 * __useGetPathwayLabelsQuery__
 *
 * To run a query within a React component, call `useGetPathwayLabelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPathwayLabelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPathwayLabelsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPathwayLabelsQuery(baseOptions: Apollo.QueryHookOptions<GetPathwayLabelsQuery, GetPathwayLabelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPathwayLabelsQuery, GetPathwayLabelsQueryVariables>(GetPathwayLabelsDocument, options);
      }
export function useGetPathwayLabelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPathwayLabelsQuery, GetPathwayLabelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPathwayLabelsQuery, GetPathwayLabelsQueryVariables>(GetPathwayLabelsDocument, options);
        }
export type GetPathwayLabelsQueryHookResult = ReturnType<typeof useGetPathwayLabelsQuery>;
export type GetPathwayLabelsLazyQueryHookResult = ReturnType<typeof useGetPathwayLabelsLazyQuery>;
export type GetPathwayLabelsQueryResult = Apollo.QueryResult<GetPathwayLabelsQuery, GetPathwayLabelsQueryVariables>;
export const RemoveLabelFromStepDocument = gql`
    mutation RemoveLabelFromStep($input: RemoveLabelFromStepInput!) {
  removeLabelFromStep(input: $input) {
    step {
      id
      label {
        id
        color
        text
      }
    }
  }
}
    `;
export type RemoveLabelFromStepMutationFn = Apollo.MutationFunction<RemoveLabelFromStepMutation, RemoveLabelFromStepMutationVariables>;

/**
 * __useRemoveLabelFromStepMutation__
 *
 * To run a mutation, you first call `useRemoveLabelFromStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveLabelFromStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeLabelFromStepMutation, { data, loading, error }] = useRemoveLabelFromStepMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveLabelFromStepMutation(baseOptions?: Apollo.MutationHookOptions<RemoveLabelFromStepMutation, RemoveLabelFromStepMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveLabelFromStepMutation, RemoveLabelFromStepMutationVariables>(RemoveLabelFromStepDocument, options);
      }
export type RemoveLabelFromStepMutationHookResult = ReturnType<typeof useRemoveLabelFromStepMutation>;
export type RemoveLabelFromStepMutationResult = Apollo.MutationResult<RemoveLabelFromStepMutation>;
export type RemoveLabelFromStepMutationOptions = Apollo.BaseMutationOptions<RemoveLabelFromStepMutation, RemoveLabelFromStepMutationVariables>;
export const StepLabelDocument = gql`
    query StepLabel($id: String!) {
  step(id: $id) {
    success
    code
    step {
      id
      label {
        id
        color
        text
      }
    }
  }
}
    `;

/**
 * __useStepLabelQuery__
 *
 * To run a query within a React component, call `useStepLabelQuery` and pass it any options that fit your needs.
 * When your component renders, `useStepLabelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStepLabelQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStepLabelQuery(baseOptions: Apollo.QueryHookOptions<StepLabelQuery, StepLabelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StepLabelQuery, StepLabelQueryVariables>(StepLabelDocument, options);
      }
export function useStepLabelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StepLabelQuery, StepLabelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StepLabelQuery, StepLabelQueryVariables>(StepLabelDocument, options);
        }
export type StepLabelQueryHookResult = ReturnType<typeof useStepLabelQuery>;
export type StepLabelLazyQueryHookResult = ReturnType<typeof useStepLabelLazyQuery>;
export type StepLabelQueryResult = Apollo.QueryResult<StepLabelQuery, StepLabelQueryVariables>;
export const UpdateLabelTextDocument = gql`
    mutation UpdateLabelText($input: UpdateLabelTextInput!) {
  updateLabelText(input: $input) {
    success
    label {
      id
      text
    }
  }
}
    `;
export type UpdateLabelTextMutationFn = Apollo.MutationFunction<UpdateLabelTextMutation, UpdateLabelTextMutationVariables>;

/**
 * __useUpdateLabelTextMutation__
 *
 * To run a mutation, you first call `useUpdateLabelTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLabelTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLabelTextMutation, { data, loading, error }] = useUpdateLabelTextMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLabelTextMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLabelTextMutation, UpdateLabelTextMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLabelTextMutation, UpdateLabelTextMutationVariables>(UpdateLabelTextDocument, options);
      }
export type UpdateLabelTextMutationHookResult = ReturnType<typeof useUpdateLabelTextMutation>;
export type UpdateLabelTextMutationResult = Apollo.MutationResult<UpdateLabelTextMutation>;
export type UpdateLabelTextMutationOptions = Apollo.BaseMutationOptions<UpdateLabelTextMutation, UpdateLabelTextMutationVariables>;
export const StepTemplateDocument = gql`
    query StepTemplate($id: String!) {
  step(id: $id) {
    success
    code
    step {
      id
      title
      actions {
        id
      }
      documentation {
        content {
          en
        }
        format
      }
    }
  }
}
    `;

/**
 * __useStepTemplateQuery__
 *
 * To run a query within a React component, call `useStepTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useStepTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStepTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStepTemplateQuery(baseOptions: Apollo.QueryHookOptions<StepTemplateQuery, StepTemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StepTemplateQuery, StepTemplateQueryVariables>(StepTemplateDocument, options);
      }
export function useStepTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StepTemplateQuery, StepTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StepTemplateQuery, StepTemplateQueryVariables>(StepTemplateDocument, options);
        }
export type StepTemplateQueryHookResult = ReturnType<typeof useStepTemplateQuery>;
export type StepTemplateLazyQueryHookResult = ReturnType<typeof useStepTemplateLazyQuery>;
export type StepTemplateQueryResult = Apollo.QueryResult<StepTemplateQuery, StepTemplateQueryVariables>;
export const GetStepTitleDocument = gql`
    query GetStepTitle($id: String!) {
  step(id: $id) {
    step {
      id
      title
    }
  }
}
    `;

/**
 * __useGetStepTitleQuery__
 *
 * To run a query within a React component, call `useGetStepTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStepTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStepTitleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetStepTitleQuery(baseOptions: Apollo.QueryHookOptions<GetStepTitleQuery, GetStepTitleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStepTitleQuery, GetStepTitleQueryVariables>(GetStepTitleDocument, options);
      }
export function useGetStepTitleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStepTitleQuery, GetStepTitleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStepTitleQuery, GetStepTitleQueryVariables>(GetStepTitleDocument, options);
        }
export type GetStepTitleQueryHookResult = ReturnType<typeof useGetStepTitleQuery>;
export type GetStepTitleLazyQueryHookResult = ReturnType<typeof useGetStepTitleLazyQuery>;
export type GetStepTitleQueryResult = Apollo.QueryResult<GetStepTitleQuery, GetStepTitleQueryVariables>;
export const ClearTimingDelayDocument = gql`
    mutation ClearTimingDelay($input: ClearTimingDelayInput!) {
  clearTimingDelay(input: $input) {
    timing {
      ...Timing
    }
  }
}
    ${TimingFragmentDoc}`;
export type ClearTimingDelayMutationFn = Apollo.MutationFunction<ClearTimingDelayMutation, ClearTimingDelayMutationVariables>;

/**
 * __useClearTimingDelayMutation__
 *
 * To run a mutation, you first call `useClearTimingDelayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearTimingDelayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearTimingDelayMutation, { data, loading, error }] = useClearTimingDelayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClearTimingDelayMutation(baseOptions?: Apollo.MutationHookOptions<ClearTimingDelayMutation, ClearTimingDelayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClearTimingDelayMutation, ClearTimingDelayMutationVariables>(ClearTimingDelayDocument, options);
      }
export type ClearTimingDelayMutationHookResult = ReturnType<typeof useClearTimingDelayMutation>;
export type ClearTimingDelayMutationResult = Apollo.MutationResult<ClearTimingDelayMutation>;
export type ClearTimingDelayMutationOptions = Apollo.BaseMutationOptions<ClearTimingDelayMutation, ClearTimingDelayMutationVariables>;
export const ClearTimingReferenceDocument = gql`
    mutation ClearTimingReference($input: ClearTimingReferenceInput!) {
  clearTimingReference(input: $input) {
    timing {
      ...Timing
    }
  }
}
    ${TimingFragmentDoc}`;
export type ClearTimingReferenceMutationFn = Apollo.MutationFunction<ClearTimingReferenceMutation, ClearTimingReferenceMutationVariables>;

/**
 * __useClearTimingReferenceMutation__
 *
 * To run a mutation, you first call `useClearTimingReferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearTimingReferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearTimingReferenceMutation, { data, loading, error }] = useClearTimingReferenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClearTimingReferenceMutation(baseOptions?: Apollo.MutationHookOptions<ClearTimingReferenceMutation, ClearTimingReferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClearTimingReferenceMutation, ClearTimingReferenceMutationVariables>(ClearTimingReferenceDocument, options);
      }
export type ClearTimingReferenceMutationHookResult = ReturnType<typeof useClearTimingReferenceMutation>;
export type ClearTimingReferenceMutationResult = Apollo.MutationResult<ClearTimingReferenceMutation>;
export type ClearTimingReferenceMutationOptions = Apollo.BaseMutationOptions<ClearTimingReferenceMutation, ClearTimingReferenceMutationVariables>;
export const GetTimingDocument = gql`
    query GetTiming($id: String!) {
  timing(id: $id) {
    timing {
      ...Timing
    }
  }
}
    ${TimingFragmentDoc}`;

/**
 * __useGetTimingQuery__
 *
 * To run a query within a React component, call `useGetTimingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTimingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTimingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTimingQuery(baseOptions: Apollo.QueryHookOptions<GetTimingQuery, GetTimingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTimingQuery, GetTimingQueryVariables>(GetTimingDocument, options);
      }
export function useGetTimingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTimingQuery, GetTimingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTimingQuery, GetTimingQueryVariables>(GetTimingDocument, options);
        }
export type GetTimingQueryHookResult = ReturnType<typeof useGetTimingQuery>;
export type GetTimingLazyQueryHookResult = ReturnType<typeof useGetTimingLazyQuery>;
export type GetTimingQueryResult = Apollo.QueryResult<GetTimingQuery, GetTimingQueryVariables>;
export const SetTimingDelayDocument = gql`
    mutation SetTimingDelay($input: SetTimingDelayInput!) {
  setTimingDelay(input: $input) {
    timing {
      ...Timing
    }
  }
}
    ${TimingFragmentDoc}`;
export type SetTimingDelayMutationFn = Apollo.MutationFunction<SetTimingDelayMutation, SetTimingDelayMutationVariables>;

/**
 * __useSetTimingDelayMutation__
 *
 * To run a mutation, you first call `useSetTimingDelayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTimingDelayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTimingDelayMutation, { data, loading, error }] = useSetTimingDelayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetTimingDelayMutation(baseOptions?: Apollo.MutationHookOptions<SetTimingDelayMutation, SetTimingDelayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetTimingDelayMutation, SetTimingDelayMutationVariables>(SetTimingDelayDocument, options);
      }
export type SetTimingDelayMutationHookResult = ReturnType<typeof useSetTimingDelayMutation>;
export type SetTimingDelayMutationResult = Apollo.MutationResult<SetTimingDelayMutation>;
export type SetTimingDelayMutationOptions = Apollo.BaseMutationOptions<SetTimingDelayMutation, SetTimingDelayMutationVariables>;
export const SetTimingReferenceDocument = gql`
    mutation SetTimingReference($input: SetTimingReferenceInput!) {
  setTimingReference(input: $input) {
    timing {
      ...Timing
    }
  }
}
    ${TimingFragmentDoc}`;
export type SetTimingReferenceMutationFn = Apollo.MutationFunction<SetTimingReferenceMutation, SetTimingReferenceMutationVariables>;

/**
 * __useSetTimingReferenceMutation__
 *
 * To run a mutation, you first call `useSetTimingReferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTimingReferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTimingReferenceMutation, { data, loading, error }] = useSetTimingReferenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetTimingReferenceMutation(baseOptions?: Apollo.MutationHookOptions<SetTimingReferenceMutation, SetTimingReferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetTimingReferenceMutation, SetTimingReferenceMutationVariables>(SetTimingReferenceDocument, options);
      }
export type SetTimingReferenceMutationHookResult = ReturnType<typeof useSetTimingReferenceMutation>;
export type SetTimingReferenceMutationResult = Apollo.MutationResult<SetTimingReferenceMutation>;
export type SetTimingReferenceMutationOptions = Apollo.BaseMutationOptions<SetTimingReferenceMutation, SetTimingReferenceMutationVariables>;
export const StartTransitionFromStepDocument = gql`
    mutation StartTransitionFromStep($input: StartTransitionFromStepInput!) {
  startTransitionFromStep(input: $input) {
    step {
      id
      transitions {
        ...TransitionParts
      }
    }
    transition {
      ...TransitionParts
    }
  }
}
    ${TransitionPartsFragmentDoc}`;
export type StartTransitionFromStepMutationFn = Apollo.MutationFunction<StartTransitionFromStepMutation, StartTransitionFromStepMutationVariables>;

/**
 * __useStartTransitionFromStepMutation__
 *
 * To run a mutation, you first call `useStartTransitionFromStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartTransitionFromStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startTransitionFromStepMutation, { data, loading, error }] = useStartTransitionFromStepMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartTransitionFromStepMutation(baseOptions?: Apollo.MutationHookOptions<StartTransitionFromStepMutation, StartTransitionFromStepMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartTransitionFromStepMutation, StartTransitionFromStepMutationVariables>(StartTransitionFromStepDocument, options);
      }
export type StartTransitionFromStepMutationHookResult = ReturnType<typeof useStartTransitionFromStepMutation>;
export type StartTransitionFromStepMutationResult = Apollo.MutationResult<StartTransitionFromStepMutation>;
export type StartTransitionFromStepMutationOptions = Apollo.BaseMutationOptions<StartTransitionFromStepMutation, StartTransitionFromStepMutationVariables>;
export const StepTransitionsDocument = gql`
    query StepTransitions($id: String!) {
  step(id: $id) {
    success
    code
    step {
      id
      transitions {
        ...TransitionParts
      }
    }
  }
}
    ${TransitionPartsFragmentDoc}`;

/**
 * __useStepTransitionsQuery__
 *
 * To run a query within a React component, call `useStepTransitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStepTransitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStepTransitionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStepTransitionsQuery(baseOptions: Apollo.QueryHookOptions<StepTransitionsQuery, StepTransitionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StepTransitionsQuery, StepTransitionsQueryVariables>(StepTransitionsDocument, options);
      }
export function useStepTransitionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StepTransitionsQuery, StepTransitionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StepTransitionsQuery, StepTransitionsQueryVariables>(StepTransitionsDocument, options);
        }
export type StepTransitionsQueryHookResult = ReturnType<typeof useStepTransitionsQuery>;
export type StepTransitionsLazyQueryHookResult = ReturnType<typeof useStepTransitionsLazyQuery>;
export type StepTransitionsQueryResult = Apollo.QueryResult<StepTransitionsQuery, StepTransitionsQueryVariables>;
export const TransitionDocument = gql`
    query Transition($id: String!) {
  transition(id: $id) {
    transition {
      id
      origin {
        type
        node_id
        node_title
      }
      status {
        ...ConfigurationStatus
      }
      rule {
        id
        status {
          ...ConfigurationStatus
        }
        conditions {
          status {
            ...ConfigurationStatus
          }
        }
      }
    }
  }
}
    ${ConfigurationStatusFragmentDoc}`;

/**
 * __useTransitionQuery__
 *
 * To run a query within a React component, call `useTransitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransitionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTransitionQuery(baseOptions: Apollo.QueryHookOptions<TransitionQuery, TransitionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TransitionQuery, TransitionQueryVariables>(TransitionDocument, options);
      }
export function useTransitionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TransitionQuery, TransitionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TransitionQuery, TransitionQueryVariables>(TransitionDocument, options);
        }
export type TransitionQueryHookResult = ReturnType<typeof useTransitionQuery>;
export type TransitionLazyQueryHookResult = ReturnType<typeof useTransitionLazyQuery>;
export type TransitionQueryResult = Apollo.QueryResult<TransitionQuery, TransitionQueryVariables>;
export const GetTransitionDocument = gql`
    query GetTransition($id: String!) {
  transition(id: $id) {
    transition {
      ...Transition
    }
  }
}
    ${TransitionFragmentDoc}`;

/**
 * __useGetTransitionQuery__
 *
 * To run a query within a React component, call `useGetTransitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransitionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTransitionQuery(baseOptions: Apollo.QueryHookOptions<GetTransitionQuery, GetTransitionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTransitionQuery, GetTransitionQueryVariables>(GetTransitionDocument, options);
      }
export function useGetTransitionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTransitionQuery, GetTransitionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTransitionQuery, GetTransitionQueryVariables>(GetTransitionDocument, options);
        }
export type GetTransitionQueryHookResult = ReturnType<typeof useGetTransitionQuery>;
export type GetTransitionLazyQueryHookResult = ReturnType<typeof useGetTransitionLazyQuery>;
export type GetTransitionQueryResult = Apollo.QueryResult<GetTransitionQuery, GetTransitionQueryVariables>;
export const ClearActionIdleTimeDocument = gql`
    mutation ClearActionIdleTime($input: ClearActionIdleTimeInput!) {
  clearActionIdleTime(input: $input) {
    action {
      id
      idle_time {
        unit
        amount
      }
    }
  }
}
    `;
export type ClearActionIdleTimeMutationFn = Apollo.MutationFunction<ClearActionIdleTimeMutation, ClearActionIdleTimeMutationVariables>;

/**
 * __useClearActionIdleTimeMutation__
 *
 * To run a mutation, you first call `useClearActionIdleTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearActionIdleTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearActionIdleTimeMutation, { data, loading, error }] = useClearActionIdleTimeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClearActionIdleTimeMutation(baseOptions?: Apollo.MutationHookOptions<ClearActionIdleTimeMutation, ClearActionIdleTimeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClearActionIdleTimeMutation, ClearActionIdleTimeMutationVariables>(ClearActionIdleTimeDocument, options);
      }
export type ClearActionIdleTimeMutationHookResult = ReturnType<typeof useClearActionIdleTimeMutation>;
export type ClearActionIdleTimeMutationResult = Apollo.MutationResult<ClearActionIdleTimeMutation>;
export type ClearActionIdleTimeMutationOptions = Apollo.BaseMutationOptions<ClearActionIdleTimeMutation, ClearActionIdleTimeMutationVariables>;
export const SetActionIdleTimeDocument = gql`
    mutation SetActionIdleTime($input: SetActionIdleTimeInput!) {
  setActionIdleTime(input: $input) {
    action {
      id
      idle_time {
        unit
        amount
      }
    }
  }
}
    `;
export type SetActionIdleTimeMutationFn = Apollo.MutationFunction<SetActionIdleTimeMutation, SetActionIdleTimeMutationVariables>;

/**
 * __useSetActionIdleTimeMutation__
 *
 * To run a mutation, you first call `useSetActionIdleTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetActionIdleTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setActionIdleTimeMutation, { data, loading, error }] = useSetActionIdleTimeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetActionIdleTimeMutation(baseOptions?: Apollo.MutationHookOptions<SetActionIdleTimeMutation, SetActionIdleTimeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetActionIdleTimeMutation, SetActionIdleTimeMutationVariables>(SetActionIdleTimeDocument, options);
      }
export type SetActionIdleTimeMutationHookResult = ReturnType<typeof useSetActionIdleTimeMutation>;
export type SetActionIdleTimeMutationResult = Apollo.MutationResult<SetActionIdleTimeMutation>;
export type SetActionIdleTimeMutationOptions = Apollo.BaseMutationOptions<SetActionIdleTimeMutation, SetActionIdleTimeMutationVariables>;
export const ActivateTriggerDocument = gql`
    mutation ActivateTrigger($input: ActivateTriggerInput!) {
  activateTrigger(input: $input) {
    evaluated_rule_id
    activated
    success
    message
  }
}
    `;
export type ActivateTriggerMutationFn = Apollo.MutationFunction<ActivateTriggerMutation, ActivateTriggerMutationVariables>;

/**
 * __useActivateTriggerMutation__
 *
 * To run a mutation, you first call `useActivateTriggerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateTriggerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateTriggerMutation, { data, loading, error }] = useActivateTriggerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateTriggerMutation(baseOptions?: Apollo.MutationHookOptions<ActivateTriggerMutation, ActivateTriggerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateTriggerMutation, ActivateTriggerMutationVariables>(ActivateTriggerDocument, options);
      }
export type ActivateTriggerMutationHookResult = ReturnType<typeof useActivateTriggerMutation>;
export type ActivateTriggerMutationResult = Apollo.MutationResult<ActivateTriggerMutation>;
export type ActivateTriggerMutationOptions = Apollo.BaseMutationOptions<ActivateTriggerMutation, ActivateTriggerMutationVariables>;
export const AddDataPointDefinitionToPathwayDocument = gql`
    mutation AddDataPointDefinitionToPathway($input: AddDataPointDefinitionToPathwayInput!) {
  addDataPointDefinitionToPathway(input: $input) {
    success
    data_point_definition {
      ...DataPointDefinition
    }
  }
}
    ${DataPointDefinitionFragmentDoc}`;
export type AddDataPointDefinitionToPathwayMutationFn = Apollo.MutationFunction<AddDataPointDefinitionToPathwayMutation, AddDataPointDefinitionToPathwayMutationVariables>;

/**
 * __useAddDataPointDefinitionToPathwayMutation__
 *
 * To run a mutation, you first call `useAddDataPointDefinitionToPathwayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDataPointDefinitionToPathwayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDataPointDefinitionToPathwayMutation, { data, loading, error }] = useAddDataPointDefinitionToPathwayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddDataPointDefinitionToPathwayMutation(baseOptions?: Apollo.MutationHookOptions<AddDataPointDefinitionToPathwayMutation, AddDataPointDefinitionToPathwayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDataPointDefinitionToPathwayMutation, AddDataPointDefinitionToPathwayMutationVariables>(AddDataPointDefinitionToPathwayDocument, options);
      }
export type AddDataPointDefinitionToPathwayMutationHookResult = ReturnType<typeof useAddDataPointDefinitionToPathwayMutation>;
export type AddDataPointDefinitionToPathwayMutationResult = Apollo.MutationResult<AddDataPointDefinitionToPathwayMutation>;
export type AddDataPointDefinitionToPathwayMutationOptions = Apollo.BaseMutationOptions<AddDataPointDefinitionToPathwayMutation, AddDataPointDefinitionToPathwayMutationVariables>;
export const AddStepToLibraryDocument = gql`
    mutation AddStepToLibrary($input: AddStepToLibraryInput!) {
  addStepToLibrary(input: $input) {
    success
    step {
      id
      title
    }
  }
}
    `;
export type AddStepToLibraryMutationFn = Apollo.MutationFunction<AddStepToLibraryMutation, AddStepToLibraryMutationVariables>;

/**
 * __useAddStepToLibraryMutation__
 *
 * To run a mutation, you first call `useAddStepToLibraryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStepToLibraryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStepToLibraryMutation, { data, loading, error }] = useAddStepToLibraryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddStepToLibraryMutation(baseOptions?: Apollo.MutationHookOptions<AddStepToLibraryMutation, AddStepToLibraryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddStepToLibraryMutation, AddStepToLibraryMutationVariables>(AddStepToLibraryDocument, options);
      }
export type AddStepToLibraryMutationHookResult = ReturnType<typeof useAddStepToLibraryMutation>;
export type AddStepToLibraryMutationResult = Apollo.MutationResult<AddStepToLibraryMutation>;
export type AddStepToLibraryMutationOptions = Apollo.BaseMutationOptions<AddStepToLibraryMutation, AddStepToLibraryMutationVariables>;
export const AddWebhookDocument = gql`
    mutation AddWebhook($input: AddWebhookInput!) {
  addWebhook(input: $input) {
    webhook {
      id
    }
  }
}
    `;
export type AddWebhookMutationFn = Apollo.MutationFunction<AddWebhookMutation, AddWebhookMutationVariables>;

/**
 * __useAddWebhookMutation__
 *
 * To run a mutation, you first call `useAddWebhookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddWebhookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addWebhookMutation, { data, loading, error }] = useAddWebhookMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddWebhookMutation(baseOptions?: Apollo.MutationHookOptions<AddWebhookMutation, AddWebhookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddWebhookMutation, AddWebhookMutationVariables>(AddWebhookDocument, options);
      }
export type AddWebhookMutationHookResult = ReturnType<typeof useAddWebhookMutation>;
export type AddWebhookMutationResult = Apollo.MutationResult<AddWebhookMutation>;
export type AddWebhookMutationOptions = Apollo.BaseMutationOptions<AddWebhookMutation, AddWebhookMutationVariables>;
export const AddApiCallMappingDocument = gql`
    mutation AddApiCallMapping($input: AddMappingToApiCallInput!) {
  addMappingToApiCall(input: $input) {
    api_call {
      id
      mappings {
        data_point_definition_id
        response_key
        first_match_only
        id
      }
    }
  }
}
    `;
export type AddApiCallMappingMutationFn = Apollo.MutationFunction<AddApiCallMappingMutation, AddApiCallMappingMutationVariables>;

/**
 * __useAddApiCallMappingMutation__
 *
 * To run a mutation, you first call `useAddApiCallMappingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddApiCallMappingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addApiCallMappingMutation, { data, loading, error }] = useAddApiCallMappingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddApiCallMappingMutation(baseOptions?: Apollo.MutationHookOptions<AddApiCallMappingMutation, AddApiCallMappingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddApiCallMappingMutation, AddApiCallMappingMutationVariables>(AddApiCallMappingDocument, options);
      }
export type AddApiCallMappingMutationHookResult = ReturnType<typeof useAddApiCallMappingMutation>;
export type AddApiCallMappingMutationResult = Apollo.MutationResult<AddApiCallMappingMutation>;
export type AddApiCallMappingMutationOptions = Apollo.BaseMutationOptions<AddApiCallMappingMutation, AddApiCallMappingMutationVariables>;
export const RemoveMappingFromApiCallDocument = gql`
    mutation RemoveMappingFromApiCall($input: RemoveMappingFromApiCallInput!) {
  removeMappingFromApiCall(input: $input) {
    api_call {
      id
      mappings {
        data_point_definition_id
        response_key
        first_match_only
        id
      }
      method
    }
  }
}
    `;
export type RemoveMappingFromApiCallMutationFn = Apollo.MutationFunction<RemoveMappingFromApiCallMutation, RemoveMappingFromApiCallMutationVariables>;

/**
 * __useRemoveMappingFromApiCallMutation__
 *
 * To run a mutation, you first call `useRemoveMappingFromApiCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMappingFromApiCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMappingFromApiCallMutation, { data, loading, error }] = useRemoveMappingFromApiCallMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveMappingFromApiCallMutation(baseOptions?: Apollo.MutationHookOptions<RemoveMappingFromApiCallMutation, RemoveMappingFromApiCallMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveMappingFromApiCallMutation, RemoveMappingFromApiCallMutationVariables>(RemoveMappingFromApiCallDocument, options);
      }
export type RemoveMappingFromApiCallMutationHookResult = ReturnType<typeof useRemoveMappingFromApiCallMutation>;
export type RemoveMappingFromApiCallMutationResult = Apollo.MutationResult<RemoveMappingFromApiCallMutation>;
export type RemoveMappingFromApiCallMutationOptions = Apollo.BaseMutationOptions<RemoveMappingFromApiCallMutation, RemoveMappingFromApiCallMutationVariables>;
export const SetApiCallBodyDocument = gql`
    mutation SetApiCallBody($input: SetApiCallBodyInput!) {
  setApiCallBody(input: $input) {
    api_call {
      ...ApiCall
    }
  }
}
    ${ApiCallFragmentDoc}`;
export type SetApiCallBodyMutationFn = Apollo.MutationFunction<SetApiCallBodyMutation, SetApiCallBodyMutationVariables>;

/**
 * __useSetApiCallBodyMutation__
 *
 * To run a mutation, you first call `useSetApiCallBodyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetApiCallBodyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setApiCallBodyMutation, { data, loading, error }] = useSetApiCallBodyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetApiCallBodyMutation(baseOptions?: Apollo.MutationHookOptions<SetApiCallBodyMutation, SetApiCallBodyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetApiCallBodyMutation, SetApiCallBodyMutationVariables>(SetApiCallBodyDocument, options);
      }
export type SetApiCallBodyMutationHookResult = ReturnType<typeof useSetApiCallBodyMutation>;
export type SetApiCallBodyMutationResult = Apollo.MutationResult<SetApiCallBodyMutation>;
export type SetApiCallBodyMutationOptions = Apollo.BaseMutationOptions<SetApiCallBodyMutation, SetApiCallBodyMutationVariables>;
export const SetApiCallEndpointDocument = gql`
    mutation SetApiCallEndpoint($input: SetApiCallEndpointInput!) {
  setApiCallEndpoint(input: $input) {
    api_call {
      ...ApiCall
    }
  }
}
    ${ApiCallFragmentDoc}`;
export type SetApiCallEndpointMutationFn = Apollo.MutationFunction<SetApiCallEndpointMutation, SetApiCallEndpointMutationVariables>;

/**
 * __useSetApiCallEndpointMutation__
 *
 * To run a mutation, you first call `useSetApiCallEndpointMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetApiCallEndpointMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setApiCallEndpointMutation, { data, loading, error }] = useSetApiCallEndpointMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetApiCallEndpointMutation(baseOptions?: Apollo.MutationHookOptions<SetApiCallEndpointMutation, SetApiCallEndpointMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetApiCallEndpointMutation, SetApiCallEndpointMutationVariables>(SetApiCallEndpointDocument, options);
      }
export type SetApiCallEndpointMutationHookResult = ReturnType<typeof useSetApiCallEndpointMutation>;
export type SetApiCallEndpointMutationResult = Apollo.MutationResult<SetApiCallEndpointMutation>;
export type SetApiCallEndpointMutationOptions = Apollo.BaseMutationOptions<SetApiCallEndpointMutation, SetApiCallEndpointMutationVariables>;
export const SetApiCallHeadersDocument = gql`
    mutation SetApiCallHeaders($input: SetApiCallHeadersInput!) {
  setApiCallHeaders(input: $input) {
    api_call {
      ...ApiCall
    }
  }
}
    ${ApiCallFragmentDoc}`;
export type SetApiCallHeadersMutationFn = Apollo.MutationFunction<SetApiCallHeadersMutation, SetApiCallHeadersMutationVariables>;

/**
 * __useSetApiCallHeadersMutation__
 *
 * To run a mutation, you first call `useSetApiCallHeadersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetApiCallHeadersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setApiCallHeadersMutation, { data, loading, error }] = useSetApiCallHeadersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetApiCallHeadersMutation(baseOptions?: Apollo.MutationHookOptions<SetApiCallHeadersMutation, SetApiCallHeadersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetApiCallHeadersMutation, SetApiCallHeadersMutationVariables>(SetApiCallHeadersDocument, options);
      }
export type SetApiCallHeadersMutationHookResult = ReturnType<typeof useSetApiCallHeadersMutation>;
export type SetApiCallHeadersMutationResult = Apollo.MutationResult<SetApiCallHeadersMutation>;
export type SetApiCallHeadersMutationOptions = Apollo.BaseMutationOptions<SetApiCallHeadersMutation, SetApiCallHeadersMutationVariables>;
export const SetApiCallMethodDocument = gql`
    mutation SetApiCallMethod($input: SetApiCallMethodInput!) {
  setApiCallMethod(input: $input) {
    api_call {
      id
      method
    }
  }
}
    `;
export type SetApiCallMethodMutationFn = Apollo.MutationFunction<SetApiCallMethodMutation, SetApiCallMethodMutationVariables>;

/**
 * __useSetApiCallMethodMutation__
 *
 * To run a mutation, you first call `useSetApiCallMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetApiCallMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setApiCallMethodMutation, { data, loading, error }] = useSetApiCallMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetApiCallMethodMutation(baseOptions?: Apollo.MutationHookOptions<SetApiCallMethodMutation, SetApiCallMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetApiCallMethodMutation, SetApiCallMethodMutationVariables>(SetApiCallMethodDocument, options);
      }
export type SetApiCallMethodMutationHookResult = ReturnType<typeof useSetApiCallMethodMutation>;
export type SetApiCallMethodMutationResult = Apollo.MutationResult<SetApiCallMethodMutation>;
export type SetApiCallMethodMutationOptions = Apollo.BaseMutationOptions<SetApiCallMethodMutation, SetApiCallMethodMutationVariables>;
export const UpdateApiCallMappingDataPointDocument = gql`
    mutation UpdateApiCallMappingDataPoint($input: UpdateApiCallMappingDataPointInput!) {
  updateApiCallMappingDataPoint(input: $input) {
    api_call {
      id
      mappings {
        data_point_definition_id
        response_key
        first_match_only
        id
      }
      method
    }
  }
}
    `;
export type UpdateApiCallMappingDataPointMutationFn = Apollo.MutationFunction<UpdateApiCallMappingDataPointMutation, UpdateApiCallMappingDataPointMutationVariables>;

/**
 * __useUpdateApiCallMappingDataPointMutation__
 *
 * To run a mutation, you first call `useUpdateApiCallMappingDataPointMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApiCallMappingDataPointMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApiCallMappingDataPointMutation, { data, loading, error }] = useUpdateApiCallMappingDataPointMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApiCallMappingDataPointMutation(baseOptions?: Apollo.MutationHookOptions<UpdateApiCallMappingDataPointMutation, UpdateApiCallMappingDataPointMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateApiCallMappingDataPointMutation, UpdateApiCallMappingDataPointMutationVariables>(UpdateApiCallMappingDataPointDocument, options);
      }
export type UpdateApiCallMappingDataPointMutationHookResult = ReturnType<typeof useUpdateApiCallMappingDataPointMutation>;
export type UpdateApiCallMappingDataPointMutationResult = Apollo.MutationResult<UpdateApiCallMappingDataPointMutation>;
export type UpdateApiCallMappingDataPointMutationOptions = Apollo.BaseMutationOptions<UpdateApiCallMappingDataPointMutation, UpdateApiCallMappingDataPointMutationVariables>;
export const UpdateApiCallMappingFirstMatchOnlyDocument = gql`
    mutation UpdateApiCallMappingFirstMatchOnly($input: UpdateApiCallMappingFirstMatchOnlyInput!) {
  updateApiCallMappingFirstMatchOnly(input: $input) {
    api_call {
      id
      mappings {
        data_point_definition_id
        response_key
        first_match_only
        id
      }
      method
    }
  }
}
    `;
export type UpdateApiCallMappingFirstMatchOnlyMutationFn = Apollo.MutationFunction<UpdateApiCallMappingFirstMatchOnlyMutation, UpdateApiCallMappingFirstMatchOnlyMutationVariables>;

/**
 * __useUpdateApiCallMappingFirstMatchOnlyMutation__
 *
 * To run a mutation, you first call `useUpdateApiCallMappingFirstMatchOnlyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApiCallMappingFirstMatchOnlyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApiCallMappingFirstMatchOnlyMutation, { data, loading, error }] = useUpdateApiCallMappingFirstMatchOnlyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApiCallMappingFirstMatchOnlyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateApiCallMappingFirstMatchOnlyMutation, UpdateApiCallMappingFirstMatchOnlyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateApiCallMappingFirstMatchOnlyMutation, UpdateApiCallMappingFirstMatchOnlyMutationVariables>(UpdateApiCallMappingFirstMatchOnlyDocument, options);
      }
export type UpdateApiCallMappingFirstMatchOnlyMutationHookResult = ReturnType<typeof useUpdateApiCallMappingFirstMatchOnlyMutation>;
export type UpdateApiCallMappingFirstMatchOnlyMutationResult = Apollo.MutationResult<UpdateApiCallMappingFirstMatchOnlyMutation>;
export type UpdateApiCallMappingFirstMatchOnlyMutationOptions = Apollo.BaseMutationOptions<UpdateApiCallMappingFirstMatchOnlyMutation, UpdateApiCallMappingFirstMatchOnlyMutationVariables>;
export const UpdateApiCallMappingKeyDocument = gql`
    mutation UpdateApiCallMappingKey($input: UpdateApiCallMappingKeyInput!) {
  updateApiCallMappingKey(input: $input) {
    api_call {
      id
      mappings {
        data_point_definition_id
        response_key
        first_match_only
        id
      }
      method
    }
  }
}
    `;
export type UpdateApiCallMappingKeyMutationFn = Apollo.MutationFunction<UpdateApiCallMappingKeyMutation, UpdateApiCallMappingKeyMutationVariables>;

/**
 * __useUpdateApiCallMappingKeyMutation__
 *
 * To run a mutation, you first call `useUpdateApiCallMappingKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApiCallMappingKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApiCallMappingKeyMutation, { data, loading, error }] = useUpdateApiCallMappingKeyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApiCallMappingKeyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateApiCallMappingKeyMutation, UpdateApiCallMappingKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateApiCallMappingKeyMutation, UpdateApiCallMappingKeyMutationVariables>(UpdateApiCallMappingKeyDocument, options);
      }
export type UpdateApiCallMappingKeyMutationHookResult = ReturnType<typeof useUpdateApiCallMappingKeyMutation>;
export type UpdateApiCallMappingKeyMutationResult = Apollo.MutationResult<UpdateApiCallMappingKeyMutation>;
export type UpdateApiCallMappingKeyMutationOptions = Apollo.BaseMutationOptions<UpdateApiCallMappingKeyMutation, UpdateApiCallMappingKeyMutationVariables>;
export const GetDataPointDefinitionDocument = gql`
    query GetDataPointDefinition($dataPointDefinitionId: String!) {
  data_point_definition(id: $dataPointDefinitionId) {
    success
    data_point_definition {
      ...DataPointDefinition
    }
  }
}
    ${DataPointDefinitionFragmentDoc}`;

/**
 * __useGetDataPointDefinitionQuery__
 *
 * To run a query within a React component, call `useGetDataPointDefinitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataPointDefinitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataPointDefinitionQuery({
 *   variables: {
 *      dataPointDefinitionId: // value for 'dataPointDefinitionId'
 *   },
 * });
 */
export function useGetDataPointDefinitionQuery(baseOptions: Apollo.QueryHookOptions<GetDataPointDefinitionQuery, GetDataPointDefinitionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDataPointDefinitionQuery, GetDataPointDefinitionQueryVariables>(GetDataPointDefinitionDocument, options);
      }
export function useGetDataPointDefinitionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDataPointDefinitionQuery, GetDataPointDefinitionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDataPointDefinitionQuery, GetDataPointDefinitionQueryVariables>(GetDataPointDefinitionDocument, options);
        }
export type GetDataPointDefinitionQueryHookResult = ReturnType<typeof useGetDataPointDefinitionQuery>;
export type GetDataPointDefinitionLazyQueryHookResult = ReturnType<typeof useGetDataPointDefinitionLazyQuery>;
export type GetDataPointDefinitionQueryResult = Apollo.QueryResult<GetDataPointDefinitionQuery, GetDataPointDefinitionQueryVariables>;
export const BaselineInfoDocument = gql`
    query BaselineInfo($pathway_case_id: String!) {
  baseline_info(pathway_case_id: $pathway_case_id) {
    baselineDataPoints {
      value
      definition {
        id
        title
        category
        key
        valueType
        possibleValues {
          label
          value
        }
        unit
        jsonPath
        jsonSchemaSource {
          system
          qualifiedKey
        }
        range {
          min
          max
        }
      }
    }
  }
}
    `;

/**
 * __useBaselineInfoQuery__
 *
 * To run a query within a React component, call `useBaselineInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useBaselineInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBaselineInfoQuery({
 *   variables: {
 *      pathway_case_id: // value for 'pathway_case_id'
 *   },
 * });
 */
export function useBaselineInfoQuery(baseOptions: Apollo.QueryHookOptions<BaselineInfoQuery, BaselineInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BaselineInfoQuery, BaselineInfoQueryVariables>(BaselineInfoDocument, options);
      }
export function useBaselineInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BaselineInfoQuery, BaselineInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BaselineInfoQuery, BaselineInfoQueryVariables>(BaselineInfoDocument, options);
        }
export type BaselineInfoQueryHookResult = ReturnType<typeof useBaselineInfoQuery>;
export type BaselineInfoLazyQueryHookResult = ReturnType<typeof useBaselineInfoLazyQuery>;
export type BaselineInfoQueryResult = Apollo.QueryResult<BaselineInfoQuery, BaselineInfoQueryVariables>;
export const GetActionCalculationDocument = gql`
    query GetActionCalculation($id: String!) {
  action(id: $id) {
    action {
      ...Action
    }
  }
}
    ${ActionFragmentDoc}`;

/**
 * __useGetActionCalculationQuery__
 *
 * To run a query within a React component, call `useGetActionCalculationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActionCalculationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActionCalculationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetActionCalculationQuery(baseOptions: Apollo.QueryHookOptions<GetActionCalculationQuery, GetActionCalculationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActionCalculationQuery, GetActionCalculationQueryVariables>(GetActionCalculationDocument, options);
      }
export function useGetActionCalculationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActionCalculationQuery, GetActionCalculationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActionCalculationQuery, GetActionCalculationQueryVariables>(GetActionCalculationDocument, options);
        }
export type GetActionCalculationQueryHookResult = ReturnType<typeof useGetActionCalculationQuery>;
export type GetActionCalculationLazyQueryHookResult = ReturnType<typeof useGetActionCalculationLazyQuery>;
export type GetActionCalculationQueryResult = Apollo.QueryResult<GetActionCalculationQuery, GetActionCalculationQueryVariables>;
export const GetCalculationResultsDocument = gql`
    query GetCalculationResults($input: CalculationResultInput!) {
  calculation_results(input: $input) {
    result {
      subresult_id
      value
      value_type
      unit
      status
    }
  }
}
    `;

/**
 * __useGetCalculationResultsQuery__
 *
 * To run a query within a React component, call `useGetCalculationResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCalculationResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCalculationResultsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCalculationResultsQuery(baseOptions: Apollo.QueryHookOptions<GetCalculationResultsQuery, GetCalculationResultsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCalculationResultsQuery, GetCalculationResultsQueryVariables>(GetCalculationResultsDocument, options);
      }
export function useGetCalculationResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCalculationResultsQuery, GetCalculationResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCalculationResultsQuery, GetCalculationResultsQueryVariables>(GetCalculationResultsDocument, options);
        }
export type GetCalculationResultsQueryHookResult = ReturnType<typeof useGetCalculationResultsQuery>;
export type GetCalculationResultsLazyQueryHookResult = ReturnType<typeof useGetCalculationResultsLazyQuery>;
export type GetCalculationResultsQueryResult = Apollo.QueryResult<GetCalculationResultsQuery, GetCalculationResultsQueryVariables>;
export const GetCareflowComponentsDocument = gql`
    query GetCareflowComponents($id: String!) {
  careflow_components(id: $id) {
    json
  }
}
    `;

/**
 * __useGetCareflowComponentsQuery__
 *
 * To run a query within a React component, call `useGetCareflowComponentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCareflowComponentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCareflowComponentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCareflowComponentsQuery(baseOptions: Apollo.QueryHookOptions<GetCareflowComponentsQuery, GetCareflowComponentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCareflowComponentsQuery, GetCareflowComponentsQueryVariables>(GetCareflowComponentsDocument, options);
      }
export function useGetCareflowComponentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCareflowComponentsQuery, GetCareflowComponentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCareflowComponentsQuery, GetCareflowComponentsQueryVariables>(GetCareflowComponentsDocument, options);
        }
export type GetCareflowComponentsQueryHookResult = ReturnType<typeof useGetCareflowComponentsQuery>;
export type GetCareflowComponentsLazyQueryHookResult = ReturnType<typeof useGetCareflowComponentsLazyQuery>;
export type GetCareflowComponentsQueryResult = Apollo.QueryResult<GetCareflowComponentsQuery, GetCareflowComponentsQueryVariables>;
export const SubmitChecklistDocument = gql`
    mutation SubmitChecklist($input: SubmitChecklistInput!) {
  submitChecklist(input: $input) {
    activity {
      ...Activity
    }
  }
}
    ${ActivityFragmentDoc}`;
export type SubmitChecklistMutationFn = Apollo.MutationFunction<SubmitChecklistMutation, SubmitChecklistMutationVariables>;

/**
 * __useSubmitChecklistMutation__
 *
 * To run a mutation, you first call `useSubmitChecklistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitChecklistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitChecklistMutation, { data, loading, error }] = useSubmitChecklistMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitChecklistMutation(baseOptions?: Apollo.MutationHookOptions<SubmitChecklistMutation, SubmitChecklistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitChecklistMutation, SubmitChecklistMutationVariables>(SubmitChecklistDocument, options);
      }
export type SubmitChecklistMutationHookResult = ReturnType<typeof useSubmitChecklistMutation>;
export type SubmitChecklistMutationResult = Apollo.MutationResult<SubmitChecklistMutation>;
export type SubmitChecklistMutationOptions = Apollo.BaseMutationOptions<SubmitChecklistMutation, SubmitChecklistMutationVariables>;
export const AddNarrativeToClinicalNoteDocument = gql`
    mutation AddNarrativeToClinicalNote($input: AddNarrativeToClinicalNoteInput!) {
  addNarrativeToClinicalNote(input: $input) {
    clinical_note {
      ...ClinicalNote
    }
  }
}
    ${ClinicalNoteFragmentDoc}`;
export type AddNarrativeToClinicalNoteMutationFn = Apollo.MutationFunction<AddNarrativeToClinicalNoteMutation, AddNarrativeToClinicalNoteMutationVariables>;

/**
 * __useAddNarrativeToClinicalNoteMutation__
 *
 * To run a mutation, you first call `useAddNarrativeToClinicalNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNarrativeToClinicalNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNarrativeToClinicalNoteMutation, { data, loading, error }] = useAddNarrativeToClinicalNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddNarrativeToClinicalNoteMutation(baseOptions?: Apollo.MutationHookOptions<AddNarrativeToClinicalNoteMutation, AddNarrativeToClinicalNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddNarrativeToClinicalNoteMutation, AddNarrativeToClinicalNoteMutationVariables>(AddNarrativeToClinicalNoteDocument, options);
      }
export type AddNarrativeToClinicalNoteMutationHookResult = ReturnType<typeof useAddNarrativeToClinicalNoteMutation>;
export type AddNarrativeToClinicalNoteMutationResult = Apollo.MutationResult<AddNarrativeToClinicalNoteMutation>;
export type AddNarrativeToClinicalNoteMutationOptions = Apollo.BaseMutationOptions<AddNarrativeToClinicalNoteMutation, AddNarrativeToClinicalNoteMutationVariables>;
export const GetClinicalNoteDocument = gql`
    query GetClinicalNote($id: String!) {
  clinical_note(id: $id) {
    clinical_note {
      ...ClinicalNote
    }
  }
}
    ${ClinicalNoteFragmentDoc}`;

/**
 * __useGetClinicalNoteQuery__
 *
 * To run a query within a React component, call `useGetClinicalNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClinicalNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClinicalNoteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClinicalNoteQuery(baseOptions: Apollo.QueryHookOptions<GetClinicalNoteQuery, GetClinicalNoteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClinicalNoteQuery, GetClinicalNoteQueryVariables>(GetClinicalNoteDocument, options);
      }
export function useGetClinicalNoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClinicalNoteQuery, GetClinicalNoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClinicalNoteQuery, GetClinicalNoteQueryVariables>(GetClinicalNoteDocument, options);
        }
export type GetClinicalNoteQueryHookResult = ReturnType<typeof useGetClinicalNoteQuery>;
export type GetClinicalNoteLazyQueryHookResult = ReturnType<typeof useGetClinicalNoteLazyQuery>;
export type GetClinicalNoteQueryResult = Apollo.QueryResult<GetClinicalNoteQuery, GetClinicalNoteQueryVariables>;
export const GetGeneratedClinicalNoteDocument = gql`
    query GetGeneratedClinicalNote($id: String!) {
  generated_clinical_note(id: $id) {
    clinical_note {
      ...GeneratedClinicalNote
    }
  }
}
    ${GeneratedClinicalNoteFragmentDoc}`;

/**
 * __useGetGeneratedClinicalNoteQuery__
 *
 * To run a query within a React component, call `useGetGeneratedClinicalNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneratedClinicalNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneratedClinicalNoteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetGeneratedClinicalNoteQuery(baseOptions: Apollo.QueryHookOptions<GetGeneratedClinicalNoteQuery, GetGeneratedClinicalNoteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGeneratedClinicalNoteQuery, GetGeneratedClinicalNoteQueryVariables>(GetGeneratedClinicalNoteDocument, options);
      }
export function useGetGeneratedClinicalNoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGeneratedClinicalNoteQuery, GetGeneratedClinicalNoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGeneratedClinicalNoteQuery, GetGeneratedClinicalNoteQueryVariables>(GetGeneratedClinicalNoteDocument, options);
        }
export type GetGeneratedClinicalNoteQueryHookResult = ReturnType<typeof useGetGeneratedClinicalNoteQuery>;
export type GetGeneratedClinicalNoteLazyQueryHookResult = ReturnType<typeof useGetGeneratedClinicalNoteLazyQuery>;
export type GetGeneratedClinicalNoteQueryResult = Apollo.QueryResult<GetGeneratedClinicalNoteQuery, GetGeneratedClinicalNoteQueryVariables>;
export const RemoveClinicalNoteNarrativeDocument = gql`
    mutation RemoveClinicalNoteNarrative($input: RemoveClinicalNoteNarrativeInput!) {
  removeClinicalNoteNarrative(input: $input) {
    clinical_note {
      ...ClinicalNote
    }
  }
}
    ${ClinicalNoteFragmentDoc}`;
export type RemoveClinicalNoteNarrativeMutationFn = Apollo.MutationFunction<RemoveClinicalNoteNarrativeMutation, RemoveClinicalNoteNarrativeMutationVariables>;

/**
 * __useRemoveClinicalNoteNarrativeMutation__
 *
 * To run a mutation, you first call `useRemoveClinicalNoteNarrativeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveClinicalNoteNarrativeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeClinicalNoteNarrativeMutation, { data, loading, error }] = useRemoveClinicalNoteNarrativeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveClinicalNoteNarrativeMutation(baseOptions?: Apollo.MutationHookOptions<RemoveClinicalNoteNarrativeMutation, RemoveClinicalNoteNarrativeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveClinicalNoteNarrativeMutation, RemoveClinicalNoteNarrativeMutationVariables>(RemoveClinicalNoteNarrativeDocument, options);
      }
export type RemoveClinicalNoteNarrativeMutationHookResult = ReturnType<typeof useRemoveClinicalNoteNarrativeMutation>;
export type RemoveClinicalNoteNarrativeMutationResult = Apollo.MutationResult<RemoveClinicalNoteNarrativeMutation>;
export type RemoveClinicalNoteNarrativeMutationOptions = Apollo.BaseMutationOptions<RemoveClinicalNoteNarrativeMutation, RemoveClinicalNoteNarrativeMutationVariables>;
export const SetClinicalNoteNarrativeTitleDocument = gql`
    mutation SetClinicalNoteNarrativeTitle($input: SetClinicalNoteNarrativeTitleInput!) {
  setClinicalNoteNarrativeTitle(input: $input) {
    clinical_note {
      ...ClinicalNote
    }
  }
}
    ${ClinicalNoteFragmentDoc}`;
export type SetClinicalNoteNarrativeTitleMutationFn = Apollo.MutationFunction<SetClinicalNoteNarrativeTitleMutation, SetClinicalNoteNarrativeTitleMutationVariables>;

/**
 * __useSetClinicalNoteNarrativeTitleMutation__
 *
 * To run a mutation, you first call `useSetClinicalNoteNarrativeTitleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetClinicalNoteNarrativeTitleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setClinicalNoteNarrativeTitleMutation, { data, loading, error }] = useSetClinicalNoteNarrativeTitleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetClinicalNoteNarrativeTitleMutation(baseOptions?: Apollo.MutationHookOptions<SetClinicalNoteNarrativeTitleMutation, SetClinicalNoteNarrativeTitleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetClinicalNoteNarrativeTitleMutation, SetClinicalNoteNarrativeTitleMutationVariables>(SetClinicalNoteNarrativeTitleDocument, options);
      }
export type SetClinicalNoteNarrativeTitleMutationHookResult = ReturnType<typeof useSetClinicalNoteNarrativeTitleMutation>;
export type SetClinicalNoteNarrativeTitleMutationResult = Apollo.MutationResult<SetClinicalNoteNarrativeTitleMutation>;
export type SetClinicalNoteNarrativeTitleMutationOptions = Apollo.BaseMutationOptions<SetClinicalNoteNarrativeTitleMutation, SetClinicalNoteNarrativeTitleMutationVariables>;
export const UpdateClinicalNoteContextDocument = gql`
    mutation UpdateClinicalNoteContext($input: UpdateClinicalNoteContextInput!) {
  updateClinicalNoteContext(input: $input) {
    clinical_note {
      ...ClinicalNote
    }
  }
}
    ${ClinicalNoteFragmentDoc}`;
export type UpdateClinicalNoteContextMutationFn = Apollo.MutationFunction<UpdateClinicalNoteContextMutation, UpdateClinicalNoteContextMutationVariables>;

/**
 * __useUpdateClinicalNoteContextMutation__
 *
 * To run a mutation, you first call `useUpdateClinicalNoteContextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClinicalNoteContextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClinicalNoteContextMutation, { data, loading, error }] = useUpdateClinicalNoteContextMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClinicalNoteContextMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClinicalNoteContextMutation, UpdateClinicalNoteContextMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClinicalNoteContextMutation, UpdateClinicalNoteContextMutationVariables>(UpdateClinicalNoteContextDocument, options);
      }
export type UpdateClinicalNoteContextMutationHookResult = ReturnType<typeof useUpdateClinicalNoteContextMutation>;
export type UpdateClinicalNoteContextMutationResult = Apollo.MutationResult<UpdateClinicalNoteContextMutation>;
export type UpdateClinicalNoteContextMutationOptions = Apollo.BaseMutationOptions<UpdateClinicalNoteContextMutation, UpdateClinicalNoteContextMutationVariables>;
export const UpdateClinicalNoteNarrativeBodyDocument = gql`
    mutation UpdateClinicalNoteNarrativeBody($input: UpdateClinicalNoteNarrativeBodyInput!) {
  updateClinicalNoteNarrativeBody(input: $input) {
    clinical_note {
      ...ClinicalNote
    }
  }
}
    ${ClinicalNoteFragmentDoc}`;
export type UpdateClinicalNoteNarrativeBodyMutationFn = Apollo.MutationFunction<UpdateClinicalNoteNarrativeBodyMutation, UpdateClinicalNoteNarrativeBodyMutationVariables>;

/**
 * __useUpdateClinicalNoteNarrativeBodyMutation__
 *
 * To run a mutation, you first call `useUpdateClinicalNoteNarrativeBodyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClinicalNoteNarrativeBodyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClinicalNoteNarrativeBodyMutation, { data, loading, error }] = useUpdateClinicalNoteNarrativeBodyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClinicalNoteNarrativeBodyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClinicalNoteNarrativeBodyMutation, UpdateClinicalNoteNarrativeBodyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClinicalNoteNarrativeBodyMutation, UpdateClinicalNoteNarrativeBodyMutationVariables>(UpdateClinicalNoteNarrativeBodyDocument, options);
      }
export type UpdateClinicalNoteNarrativeBodyMutationHookResult = ReturnType<typeof useUpdateClinicalNoteNarrativeBodyMutation>;
export type UpdateClinicalNoteNarrativeBodyMutationResult = Apollo.MutationResult<UpdateClinicalNoteNarrativeBodyMutation>;
export type UpdateClinicalNoteNarrativeBodyMutationOptions = Apollo.BaseMutationOptions<UpdateClinicalNoteNarrativeBodyMutation, UpdateClinicalNoteNarrativeBodyMutationVariables>;
export const CodeComparisonDocument = gql`
    query CodeComparison($pathwayId: String!) {
  pathwayDiffs(pathway_id: $pathwayId) {
    diff {
      newPathway
      oldPathway
    }
  }
}
    `;

/**
 * __useCodeComparisonQuery__
 *
 * To run a query within a React component, call `useCodeComparisonQuery` and pass it any options that fit your needs.
 * When your component renders, `useCodeComparisonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCodeComparisonQuery({
 *   variables: {
 *      pathwayId: // value for 'pathwayId'
 *   },
 * });
 */
export function useCodeComparisonQuery(baseOptions: Apollo.QueryHookOptions<CodeComparisonQuery, CodeComparisonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CodeComparisonQuery, CodeComparisonQueryVariables>(CodeComparisonDocument, options);
      }
export function useCodeComparisonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CodeComparisonQuery, CodeComparisonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CodeComparisonQuery, CodeComparisonQueryVariables>(CodeComparisonDocument, options);
        }
export type CodeComparisonQueryHookResult = ReturnType<typeof useCodeComparisonQuery>;
export type CodeComparisonLazyQueryHookResult = ReturnType<typeof useCodeComparisonLazyQuery>;
export type CodeComparisonQueryResult = Apollo.QueryResult<CodeComparisonQuery, CodeComparisonQueryVariables>;
export const CompareVersionsDocument = gql`
    query CompareVersions($pathwayId: String!) {
  pathwayDiffTree(pathway_id: $pathwayId) {
    diff {
      newPathway
      oldPathway
    }
  }
}
    `;

/**
 * __useCompareVersionsQuery__
 *
 * To run a query within a React component, call `useCompareVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompareVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompareVersionsQuery({
 *   variables: {
 *      pathwayId: // value for 'pathwayId'
 *   },
 * });
 */
export function useCompareVersionsQuery(baseOptions: Apollo.QueryHookOptions<CompareVersionsQuery, CompareVersionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompareVersionsQuery, CompareVersionsQueryVariables>(CompareVersionsDocument, options);
      }
export function useCompareVersionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompareVersionsQuery, CompareVersionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompareVersionsQuery, CompareVersionsQueryVariables>(CompareVersionsDocument, options);
        }
export type CompareVersionsQueryHookResult = ReturnType<typeof useCompareVersionsQuery>;
export type CompareVersionsLazyQueryHookResult = ReturnType<typeof useCompareVersionsLazyQuery>;
export type CompareVersionsQueryResult = Apollo.QueryResult<CompareVersionsQuery, CompareVersionsQueryVariables>;
export const AddStepFromTemplateDocument = gql`
    mutation AddStepFromTemplate($input: AddStepFromTemplateInput!) {
  addStepFromTemplate(input: $input) {
    success
    code
    step {
      id
      title
      coordinates {
        x
        y
      }
      actions {
        id
        title
        type
      }
    }
  }
}
    `;
export type AddStepFromTemplateMutationFn = Apollo.MutationFunction<AddStepFromTemplateMutation, AddStepFromTemplateMutationVariables>;

/**
 * __useAddStepFromTemplateMutation__
 *
 * To run a mutation, you first call `useAddStepFromTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStepFromTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStepFromTemplateMutation, { data, loading, error }] = useAddStepFromTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddStepFromTemplateMutation(baseOptions?: Apollo.MutationHookOptions<AddStepFromTemplateMutation, AddStepFromTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddStepFromTemplateMutation, AddStepFromTemplateMutationVariables>(AddStepFromTemplateDocument, options);
      }
export type AddStepFromTemplateMutationHookResult = ReturnType<typeof useAddStepFromTemplateMutation>;
export type AddStepFromTemplateMutationResult = Apollo.MutationResult<AddStepFromTemplateMutation>;
export type AddStepFromTemplateMutationOptions = Apollo.BaseMutationOptions<AddStepFromTemplateMutation, AddStepFromTemplateMutationVariables>;
export const GetConstantDocument = gql`
    query GetConstant($input: ConstantInput!) {
  constant(input: $input) {
    constant {
      ...Constant
    }
  }
}
    ${ConstantFragmentDoc}`;

/**
 * __useGetConstantQuery__
 *
 * To run a query within a React component, call `useGetConstantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConstantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConstantQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetConstantQuery(baseOptions: Apollo.QueryHookOptions<GetConstantQuery, GetConstantQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConstantQuery, GetConstantQueryVariables>(GetConstantDocument, options);
      }
export function useGetConstantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConstantQuery, GetConstantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConstantQuery, GetConstantQueryVariables>(GetConstantDocument, options);
        }
export type GetConstantQueryHookResult = ReturnType<typeof useGetConstantQuery>;
export type GetConstantLazyQueryHookResult = ReturnType<typeof useGetConstantLazyQuery>;
export type GetConstantQueryResult = Apollo.QueryResult<GetConstantQuery, GetConstantQueryVariables>;
export const GetConstantsDocument = gql`
    query GetConstants($pathway_id: String!) {
  pathway(id: $pathway_id) {
    pathway {
      id
      settings {
        constants {
          ...Constant
        }
      }
    }
  }
}
    ${ConstantFragmentDoc}`;

/**
 * __useGetConstantsQuery__
 *
 * To run a query within a React component, call `useGetConstantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConstantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConstantsQuery({
 *   variables: {
 *      pathway_id: // value for 'pathway_id'
 *   },
 * });
 */
export function useGetConstantsQuery(baseOptions: Apollo.QueryHookOptions<GetConstantsQuery, GetConstantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConstantsQuery, GetConstantsQueryVariables>(GetConstantsDocument, options);
      }
export function useGetConstantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConstantsQuery, GetConstantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConstantsQuery, GetConstantsQueryVariables>(GetConstantsDocument, options);
        }
export type GetConstantsQueryHookResult = ReturnType<typeof useGetConstantsQuery>;
export type GetConstantsLazyQueryHookResult = ReturnType<typeof useGetConstantsLazyQuery>;
export type GetConstantsQueryResult = Apollo.QueryResult<GetConstantsQuery, GetConstantsQueryVariables>;
export const CreateConstantDocument = gql`
    mutation CreateConstant($input: AddConstantInput!) {
  addConstant(input: $input) {
    success
    pathway {
      id
      settings {
        constants {
          ...Constant
        }
      }
    }
  }
}
    ${ConstantFragmentDoc}`;
export type CreateConstantMutationFn = Apollo.MutationFunction<CreateConstantMutation, CreateConstantMutationVariables>;

/**
 * __useCreateConstantMutation__
 *
 * To run a mutation, you first call `useCreateConstantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConstantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConstantMutation, { data, loading, error }] = useCreateConstantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateConstantMutation(baseOptions?: Apollo.MutationHookOptions<CreateConstantMutation, CreateConstantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateConstantMutation, CreateConstantMutationVariables>(CreateConstantDocument, options);
      }
export type CreateConstantMutationHookResult = ReturnType<typeof useCreateConstantMutation>;
export type CreateConstantMutationResult = Apollo.MutationResult<CreateConstantMutation>;
export type CreateConstantMutationOptions = Apollo.BaseMutationOptions<CreateConstantMutation, CreateConstantMutationVariables>;
export const CreateDynamicVariableDocument = gql`
    mutation CreateDynamicVariable($input: CreateDynamicVariableInput!) {
  createDynamicVariable(input: $input) {
    success
    dynamic_variable {
      data_point_definition {
        valueType
      }
      data_point_property
      fallback
      id
      label
      status {
        status
        errors
      }
      transformations {
        type
      }
    }
  }
}
    `;
export type CreateDynamicVariableMutationFn = Apollo.MutationFunction<CreateDynamicVariableMutation, CreateDynamicVariableMutationVariables>;

/**
 * __useCreateDynamicVariableMutation__
 *
 * To run a mutation, you first call `useCreateDynamicVariableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDynamicVariableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDynamicVariableMutation, { data, loading, error }] = useCreateDynamicVariableMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDynamicVariableMutation(baseOptions?: Apollo.MutationHookOptions<CreateDynamicVariableMutation, CreateDynamicVariableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDynamicVariableMutation, CreateDynamicVariableMutationVariables>(CreateDynamicVariableDocument, options);
      }
export type CreateDynamicVariableMutationHookResult = ReturnType<typeof useCreateDynamicVariableMutation>;
export type CreateDynamicVariableMutationResult = Apollo.MutationResult<CreateDynamicVariableMutation>;
export type CreateDynamicVariableMutationOptions = Apollo.BaseMutationOptions<CreateDynamicVariableMutation, CreateDynamicVariableMutationVariables>;
export const GetCustomActionsDocument = gql`
    query GetCustomActions($pathwayId: String!) {
  pathway(id: $pathwayId) {
    pathway {
      id
      custom_actions {
        id
        name
        type
        category
        fields {
          id
          name
          type
          label
          description
          options {
            label
            value
          }
          value
          readonly
          mandatory
        }
      }
    }
  }
}
    `;

/**
 * __useGetCustomActionsQuery__
 *
 * To run a query within a React component, call `useGetCustomActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomActionsQuery({
 *   variables: {
 *      pathwayId: // value for 'pathwayId'
 *   },
 * });
 */
export function useGetCustomActionsQuery(baseOptions: Apollo.QueryHookOptions<GetCustomActionsQuery, GetCustomActionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomActionsQuery, GetCustomActionsQueryVariables>(GetCustomActionsDocument, options);
      }
export function useGetCustomActionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomActionsQuery, GetCustomActionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomActionsQuery, GetCustomActionsQueryVariables>(GetCustomActionsDocument, options);
        }
export type GetCustomActionsQueryHookResult = ReturnType<typeof useGetCustomActionsQuery>;
export type GetCustomActionsLazyQueryHookResult = ReturnType<typeof useGetCustomActionsLazyQuery>;
export type GetCustomActionsQueryResult = Apollo.QueryResult<GetCustomActionsQuery, GetCustomActionsQueryVariables>;
export const DataCatalogDocument = gql`
    query DataCatalog($input: DataCatalogInput!) {
  data_catalog(input: $input) {
    items {
      id
      data_point_metadata {
        key
        value
      }
      pii
      title
      category
      key
      valueType
      possibleValues {
        label
        value
      }
      unit
      range {
        min
        max
      }
      jsonSchemaSource {
        system
        qualifiedKey
      }
      jsonPath
      collection_contexts {
        track {
          id
          title
        }
        step {
          id
          title
        }
        action {
          id
          title
        }
        stakeholder {
          id
          label {
            en
          }
        }
      }
    }
  }
}
    `;

/**
 * __useDataCatalogQuery__
 *
 * To run a query within a React component, call `useDataCatalogQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataCatalogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataCatalogQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDataCatalogQuery(baseOptions: Apollo.QueryHookOptions<DataCatalogQuery, DataCatalogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DataCatalogQuery, DataCatalogQueryVariables>(DataCatalogDocument, options);
      }
export function useDataCatalogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DataCatalogQuery, DataCatalogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DataCatalogQuery, DataCatalogQueryVariables>(DataCatalogDocument, options);
        }
export type DataCatalogQueryHookResult = ReturnType<typeof useDataCatalogQuery>;
export type DataCatalogLazyQueryHookResult = ReturnType<typeof useDataCatalogLazyQuery>;
export type DataCatalogQueryResult = Apollo.QueryResult<DataCatalogQuery, DataCatalogQueryVariables>;
export const DataCatalogJsonDocument = gql`
    query DataCatalogJson($input: DataCatalogInput!) {
  data_catalog_json(input: $input) {
    json
  }
}
    `;

/**
 * __useDataCatalogJsonQuery__
 *
 * To run a query within a React component, call `useDataCatalogJsonQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataCatalogJsonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataCatalogJsonQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDataCatalogJsonQuery(baseOptions: Apollo.QueryHookOptions<DataCatalogJsonQuery, DataCatalogJsonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DataCatalogJsonQuery, DataCatalogJsonQueryVariables>(DataCatalogJsonDocument, options);
      }
export function useDataCatalogJsonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DataCatalogJsonQuery, DataCatalogJsonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DataCatalogJsonQuery, DataCatalogJsonQueryVariables>(DataCatalogJsonDocument, options);
        }
export type DataCatalogJsonQueryHookResult = ReturnType<typeof useDataCatalogJsonQuery>;
export type DataCatalogJsonLazyQueryHookResult = ReturnType<typeof useDataCatalogJsonLazyQuery>;
export type DataCatalogJsonQueryResult = Apollo.QueryResult<DataCatalogJsonQuery, DataCatalogJsonQueryVariables>;
export const GetPathwayTitlesDocument = gql`
    query GetPathwayTitles {
  pathways {
    pathways {
      id
      title
    }
  }
}
    `;

/**
 * __useGetPathwayTitlesQuery__
 *
 * To run a query within a React component, call `useGetPathwayTitlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPathwayTitlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPathwayTitlesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPathwayTitlesQuery(baseOptions?: Apollo.QueryHookOptions<GetPathwayTitlesQuery, GetPathwayTitlesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPathwayTitlesQuery, GetPathwayTitlesQueryVariables>(GetPathwayTitlesDocument, options);
      }
export function useGetPathwayTitlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPathwayTitlesQuery, GetPathwayTitlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPathwayTitlesQuery, GetPathwayTitlesQueryVariables>(GetPathwayTitlesDocument, options);
        }
export type GetPathwayTitlesQueryHookResult = ReturnType<typeof useGetPathwayTitlesQuery>;
export type GetPathwayTitlesLazyQueryHookResult = ReturnType<typeof useGetPathwayTitlesLazyQuery>;
export type GetPathwayTitlesQueryResult = Apollo.QueryResult<GetPathwayTitlesQuery, GetPathwayTitlesQueryVariables>;
export const SetDataPointMetadataDocument = gql`
    mutation SetDataPointMetadata($input: SetDataPointDefinitionMetaDataFieldInput!) {
  setDataPointDefinitionMetaDataField(input: $input) {
    success
    data_point_definition {
      id
      data_point_metadata {
        key
        value
      }
    }
  }
}
    `;
export type SetDataPointMetadataMutationFn = Apollo.MutationFunction<SetDataPointMetadataMutation, SetDataPointMetadataMutationVariables>;

/**
 * __useSetDataPointMetadataMutation__
 *
 * To run a mutation, you first call `useSetDataPointMetadataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDataPointMetadataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDataPointMetadataMutation, { data, loading, error }] = useSetDataPointMetadataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetDataPointMetadataMutation(baseOptions?: Apollo.MutationHookOptions<SetDataPointMetadataMutation, SetDataPointMetadataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetDataPointMetadataMutation, SetDataPointMetadataMutationVariables>(SetDataPointMetadataDocument, options);
      }
export type SetDataPointMetadataMutationHookResult = ReturnType<typeof useSetDataPointMetadataMutation>;
export type SetDataPointMetadataMutationResult = Apollo.MutationResult<SetDataPointMetadataMutation>;
export type SetDataPointMetadataMutationOptions = Apollo.BaseMutationOptions<SetDataPointMetadataMutation, SetDataPointMetadataMutationVariables>;
export const SetDataPointPiiDocument = gql`
    mutation SetDataPointPii($input: SetDataPointDefinitionPiiFieldInput!) {
  setDataPointDefinitionPiiField(input: $input) {
    success
    data_point_definition {
      ...DataPointDefinition
    }
  }
}
    ${DataPointDefinitionFragmentDoc}`;
export type SetDataPointPiiMutationFn = Apollo.MutationFunction<SetDataPointPiiMutation, SetDataPointPiiMutationVariables>;

/**
 * __useSetDataPointPiiMutation__
 *
 * To run a mutation, you first call `useSetDataPointPiiMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDataPointPiiMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDataPointPiiMutation, { data, loading, error }] = useSetDataPointPiiMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetDataPointPiiMutation(baseOptions?: Apollo.MutationHookOptions<SetDataPointPiiMutation, SetDataPointPiiMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetDataPointPiiMutation, SetDataPointPiiMutationVariables>(SetDataPointPiiDocument, options);
      }
export type SetDataPointPiiMutationHookResult = ReturnType<typeof useSetDataPointPiiMutation>;
export type SetDataPointPiiMutationResult = Apollo.MutationResult<SetDataPointPiiMutation>;
export type SetDataPointPiiMutationOptions = Apollo.BaseMutationOptions<SetDataPointPiiMutation, SetDataPointPiiMutationVariables>;
export const RemoveStepFromTrackDocument = gql`
    mutation RemoveStepFromTrack($input: RemoveStepFromTrackInput!) {
  removeStepFromTrack(input: $input) {
    success
    track {
      id
      steps {
        id
      }
    }
  }
}
    `;
export type RemoveStepFromTrackMutationFn = Apollo.MutationFunction<RemoveStepFromTrackMutation, RemoveStepFromTrackMutationVariables>;

/**
 * __useRemoveStepFromTrackMutation__
 *
 * To run a mutation, you first call `useRemoveStepFromTrackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveStepFromTrackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeStepFromTrackMutation, { data, loading, error }] = useRemoveStepFromTrackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveStepFromTrackMutation(baseOptions?: Apollo.MutationHookOptions<RemoveStepFromTrackMutation, RemoveStepFromTrackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveStepFromTrackMutation, RemoveStepFromTrackMutationVariables>(RemoveStepFromTrackDocument, options);
      }
export type RemoveStepFromTrackMutationHookResult = ReturnType<typeof useRemoveStepFromTrackMutation>;
export type RemoveStepFromTrackMutationResult = Apollo.MutationResult<RemoveStepFromTrackMutation>;
export type RemoveStepFromTrackMutationOptions = Apollo.BaseMutationOptions<RemoveStepFromTrackMutation, RemoveStepFromTrackMutationVariables>;
export const RemoveStickyNoteFromTrackDocument = gql`
    mutation RemoveStickyNoteFromTrack($input: RemoveStickyNoteFromTrackInput!) {
  removeStickyNoteFromTrack(input: $input) {
    success
  }
}
    `;
export type RemoveStickyNoteFromTrackMutationFn = Apollo.MutationFunction<RemoveStickyNoteFromTrackMutation, RemoveStickyNoteFromTrackMutationVariables>;

/**
 * __useRemoveStickyNoteFromTrackMutation__
 *
 * To run a mutation, you first call `useRemoveStickyNoteFromTrackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveStickyNoteFromTrackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeStickyNoteFromTrackMutation, { data, loading, error }] = useRemoveStickyNoteFromTrackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveStickyNoteFromTrackMutation(baseOptions?: Apollo.MutationHookOptions<RemoveStickyNoteFromTrackMutation, RemoveStickyNoteFromTrackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveStickyNoteFromTrackMutation, RemoveStickyNoteFromTrackMutationVariables>(RemoveStickyNoteFromTrackDocument, options);
      }
export type RemoveStickyNoteFromTrackMutationHookResult = ReturnType<typeof useRemoveStickyNoteFromTrackMutation>;
export type RemoveStickyNoteFromTrackMutationResult = Apollo.MutationResult<RemoveStickyNoteFromTrackMutation>;
export type RemoveStickyNoteFromTrackMutationOptions = Apollo.BaseMutationOptions<RemoveStickyNoteFromTrackMutation, RemoveStickyNoteFromTrackMutationVariables>;
export const RemoveTransitionDocument = gql`
    mutation RemoveTransition($input: RemoveTransitionInput!) {
  removeTransition(input: $input) {
    success
  }
}
    `;
export type RemoveTransitionMutationFn = Apollo.MutationFunction<RemoveTransitionMutation, RemoveTransitionMutationVariables>;

/**
 * __useRemoveTransitionMutation__
 *
 * To run a mutation, you first call `useRemoveTransitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTransitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTransitionMutation, { data, loading, error }] = useRemoveTransitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveTransitionMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTransitionMutation, RemoveTransitionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveTransitionMutation, RemoveTransitionMutationVariables>(RemoveTransitionDocument, options);
      }
export type RemoveTransitionMutationHookResult = ReturnType<typeof useRemoveTransitionMutation>;
export type RemoveTransitionMutationResult = Apollo.MutationResult<RemoveTransitionMutation>;
export type RemoveTransitionMutationOptions = Apollo.BaseMutationOptions<RemoveTransitionMutation, RemoveTransitionMutationVariables>;
export const DuplicateStepDocument = gql`
    mutation DuplicateStep($input: DuplicateStepInput!) {
  duplicateStep(input: $input) {
    step {
      ...CanvasStep
    }
  }
}
    ${CanvasStepFragmentDoc}`;
export type DuplicateStepMutationFn = Apollo.MutationFunction<DuplicateStepMutation, DuplicateStepMutationVariables>;

/**
 * __useDuplicateStepMutation__
 *
 * To run a mutation, you first call `useDuplicateStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateStepMutation, { data, loading, error }] = useDuplicateStepMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDuplicateStepMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateStepMutation, DuplicateStepMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateStepMutation, DuplicateStepMutationVariables>(DuplicateStepDocument, options);
      }
export type DuplicateStepMutationHookResult = ReturnType<typeof useDuplicateStepMutation>;
export type DuplicateStepMutationResult = Apollo.MutationResult<DuplicateStepMutation>;
export type DuplicateStepMutationOptions = Apollo.BaseMutationOptions<DuplicateStepMutation, DuplicateStepMutationVariables>;
export const AddTransformationToDynamicVariableDocument = gql`
    mutation AddTransformationToDynamicVariable($input: AddTransformationToDynamicVariableInput!) {
  addTransformationToDynamicVariable(input: $input) {
    dynamic_variable {
      id
      transformations {
        ...Transformation
      }
    }
  }
}
    ${TransformationFragmentDoc}`;
export type AddTransformationToDynamicVariableMutationFn = Apollo.MutationFunction<AddTransformationToDynamicVariableMutation, AddTransformationToDynamicVariableMutationVariables>;

/**
 * __useAddTransformationToDynamicVariableMutation__
 *
 * To run a mutation, you first call `useAddTransformationToDynamicVariableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTransformationToDynamicVariableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTransformationToDynamicVariableMutation, { data, loading, error }] = useAddTransformationToDynamicVariableMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTransformationToDynamicVariableMutation(baseOptions?: Apollo.MutationHookOptions<AddTransformationToDynamicVariableMutation, AddTransformationToDynamicVariableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTransformationToDynamicVariableMutation, AddTransformationToDynamicVariableMutationVariables>(AddTransformationToDynamicVariableDocument, options);
      }
export type AddTransformationToDynamicVariableMutationHookResult = ReturnType<typeof useAddTransformationToDynamicVariableMutation>;
export type AddTransformationToDynamicVariableMutationResult = Apollo.MutationResult<AddTransformationToDynamicVariableMutation>;
export type AddTransformationToDynamicVariableMutationOptions = Apollo.BaseMutationOptions<AddTransformationToDynamicVariableMutation, AddTransformationToDynamicVariableMutationVariables>;
export const GetDynamicVariableDocument = gql`
    query GetDynamicVariable($dynamicVariableId: String!) {
  dynamic_variable(id: $dynamicVariableId) {
    dynamic_variable {
      ...DynamicVariable
    }
  }
}
    ${DynamicVariableFragmentDoc}`;

/**
 * __useGetDynamicVariableQuery__
 *
 * To run a query within a React component, call `useGetDynamicVariableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDynamicVariableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDynamicVariableQuery({
 *   variables: {
 *      dynamicVariableId: // value for 'dynamicVariableId'
 *   },
 * });
 */
export function useGetDynamicVariableQuery(baseOptions: Apollo.QueryHookOptions<GetDynamicVariableQuery, GetDynamicVariableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDynamicVariableQuery, GetDynamicVariableQueryVariables>(GetDynamicVariableDocument, options);
      }
export function useGetDynamicVariableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDynamicVariableQuery, GetDynamicVariableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDynamicVariableQuery, GetDynamicVariableQueryVariables>(GetDynamicVariableDocument, options);
        }
export type GetDynamicVariableQueryHookResult = ReturnType<typeof useGetDynamicVariableQuery>;
export type GetDynamicVariableLazyQueryHookResult = ReturnType<typeof useGetDynamicVariableLazyQuery>;
export type GetDynamicVariableQueryResult = Apollo.QueryResult<GetDynamicVariableQuery, GetDynamicVariableQueryVariables>;
export const GetDynamicVariablesDocument = gql`
    query GetDynamicVariables($pathwayId: String!) {
  dynamic_variables(pathway_id: $pathwayId) {
    dynamic_variables {
      ...DynamicVariable
    }
  }
}
    ${DynamicVariableFragmentDoc}`;

/**
 * __useGetDynamicVariablesQuery__
 *
 * To run a query within a React component, call `useGetDynamicVariablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDynamicVariablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDynamicVariablesQuery({
 *   variables: {
 *      pathwayId: // value for 'pathwayId'
 *   },
 * });
 */
export function useGetDynamicVariablesQuery(baseOptions: Apollo.QueryHookOptions<GetDynamicVariablesQuery, GetDynamicVariablesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDynamicVariablesQuery, GetDynamicVariablesQueryVariables>(GetDynamicVariablesDocument, options);
      }
export function useGetDynamicVariablesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDynamicVariablesQuery, GetDynamicVariablesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDynamicVariablesQuery, GetDynamicVariablesQueryVariables>(GetDynamicVariablesDocument, options);
        }
export type GetDynamicVariablesQueryHookResult = ReturnType<typeof useGetDynamicVariablesQuery>;
export type GetDynamicVariablesLazyQueryHookResult = ReturnType<typeof useGetDynamicVariablesLazyQuery>;
export type GetDynamicVariablesQueryResult = Apollo.QueryResult<GetDynamicVariablesQuery, GetDynamicVariablesQueryVariables>;
export const RemoveTransformationsFromDynamicVariableDocument = gql`
    mutation RemoveTransformationsFromDynamicVariable($input: RemoveTransformationsFromDynamicVariableInput!) {
  removeTransformationsFromDynamicVariable(input: $input) {
    dynamic_variable {
      id
      transformations {
        ...Transformation
      }
    }
  }
}
    ${TransformationFragmentDoc}`;
export type RemoveTransformationsFromDynamicVariableMutationFn = Apollo.MutationFunction<RemoveTransformationsFromDynamicVariableMutation, RemoveTransformationsFromDynamicVariableMutationVariables>;

/**
 * __useRemoveTransformationsFromDynamicVariableMutation__
 *
 * To run a mutation, you first call `useRemoveTransformationsFromDynamicVariableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTransformationsFromDynamicVariableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTransformationsFromDynamicVariableMutation, { data, loading, error }] = useRemoveTransformationsFromDynamicVariableMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveTransformationsFromDynamicVariableMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTransformationsFromDynamicVariableMutation, RemoveTransformationsFromDynamicVariableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveTransformationsFromDynamicVariableMutation, RemoveTransformationsFromDynamicVariableMutationVariables>(RemoveTransformationsFromDynamicVariableDocument, options);
      }
export type RemoveTransformationsFromDynamicVariableMutationHookResult = ReturnType<typeof useRemoveTransformationsFromDynamicVariableMutation>;
export type RemoveTransformationsFromDynamicVariableMutationResult = Apollo.MutationResult<RemoveTransformationsFromDynamicVariableMutation>;
export type RemoveTransformationsFromDynamicVariableMutationOptions = Apollo.BaseMutationOptions<RemoveTransformationsFromDynamicVariableMutation, RemoveTransformationsFromDynamicVariableMutationVariables>;
export const SetDynamicVariableDataPointDefinitionDocument = gql`
    mutation SetDynamicVariableDataPointDefinition($input: SetDynamicVariableDataPointDefinitionInput!) {
  setDynamicVariableDataPointDefinition(input: $input) {
    dynamic_variable {
      ...DynamicVariable
    }
  }
}
    ${DynamicVariableFragmentDoc}`;
export type SetDynamicVariableDataPointDefinitionMutationFn = Apollo.MutationFunction<SetDynamicVariableDataPointDefinitionMutation, SetDynamicVariableDataPointDefinitionMutationVariables>;

/**
 * __useSetDynamicVariableDataPointDefinitionMutation__
 *
 * To run a mutation, you first call `useSetDynamicVariableDataPointDefinitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDynamicVariableDataPointDefinitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDynamicVariableDataPointDefinitionMutation, { data, loading, error }] = useSetDynamicVariableDataPointDefinitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetDynamicVariableDataPointDefinitionMutation(baseOptions?: Apollo.MutationHookOptions<SetDynamicVariableDataPointDefinitionMutation, SetDynamicVariableDataPointDefinitionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetDynamicVariableDataPointDefinitionMutation, SetDynamicVariableDataPointDefinitionMutationVariables>(SetDynamicVariableDataPointDefinitionDocument, options);
      }
export type SetDynamicVariableDataPointDefinitionMutationHookResult = ReturnType<typeof useSetDynamicVariableDataPointDefinitionMutation>;
export type SetDynamicVariableDataPointDefinitionMutationResult = Apollo.MutationResult<SetDynamicVariableDataPointDefinitionMutation>;
export type SetDynamicVariableDataPointDefinitionMutationOptions = Apollo.BaseMutationOptions<SetDynamicVariableDataPointDefinitionMutation, SetDynamicVariableDataPointDefinitionMutationVariables>;
export const SetDynamicVariableDataPointPropertyDocument = gql`
    mutation SetDynamicVariableDataPointProperty($input: SetDynamicVariableDataPointPropertyInput!) {
  setDynamicVariableDataPointProperty(input: $input) {
    dynamic_variable {
      ...DynamicVariable
    }
  }
}
    ${DynamicVariableFragmentDoc}`;
export type SetDynamicVariableDataPointPropertyMutationFn = Apollo.MutationFunction<SetDynamicVariableDataPointPropertyMutation, SetDynamicVariableDataPointPropertyMutationVariables>;

/**
 * __useSetDynamicVariableDataPointPropertyMutation__
 *
 * To run a mutation, you first call `useSetDynamicVariableDataPointPropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDynamicVariableDataPointPropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDynamicVariableDataPointPropertyMutation, { data, loading, error }] = useSetDynamicVariableDataPointPropertyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetDynamicVariableDataPointPropertyMutation(baseOptions?: Apollo.MutationHookOptions<SetDynamicVariableDataPointPropertyMutation, SetDynamicVariableDataPointPropertyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetDynamicVariableDataPointPropertyMutation, SetDynamicVariableDataPointPropertyMutationVariables>(SetDynamicVariableDataPointPropertyDocument, options);
      }
export type SetDynamicVariableDataPointPropertyMutationHookResult = ReturnType<typeof useSetDynamicVariableDataPointPropertyMutation>;
export type SetDynamicVariableDataPointPropertyMutationResult = Apollo.MutationResult<SetDynamicVariableDataPointPropertyMutation>;
export type SetDynamicVariableDataPointPropertyMutationOptions = Apollo.BaseMutationOptions<SetDynamicVariableDataPointPropertyMutation, SetDynamicVariableDataPointPropertyMutationVariables>;
export const SetDynamicVariableFallbackDocument = gql`
    mutation SetDynamicVariableFallback($input: SetDynamicVariableFallbackInput!) {
  setDynamicVariableFallback(input: $input) {
    dynamic_variable {
      ...DynamicVariable
    }
  }
}
    ${DynamicVariableFragmentDoc}`;
export type SetDynamicVariableFallbackMutationFn = Apollo.MutationFunction<SetDynamicVariableFallbackMutation, SetDynamicVariableFallbackMutationVariables>;

/**
 * __useSetDynamicVariableFallbackMutation__
 *
 * To run a mutation, you first call `useSetDynamicVariableFallbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDynamicVariableFallbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDynamicVariableFallbackMutation, { data, loading, error }] = useSetDynamicVariableFallbackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetDynamicVariableFallbackMutation(baseOptions?: Apollo.MutationHookOptions<SetDynamicVariableFallbackMutation, SetDynamicVariableFallbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetDynamicVariableFallbackMutation, SetDynamicVariableFallbackMutationVariables>(SetDynamicVariableFallbackDocument, options);
      }
export type SetDynamicVariableFallbackMutationHookResult = ReturnType<typeof useSetDynamicVariableFallbackMutation>;
export type SetDynamicVariableFallbackMutationResult = Apollo.MutationResult<SetDynamicVariableFallbackMutation>;
export type SetDynamicVariableFallbackMutationOptions = Apollo.BaseMutationOptions<SetDynamicVariableFallbackMutation, SetDynamicVariableFallbackMutationVariables>;
export const SetDynamicVariableLabelDocument = gql`
    mutation SetDynamicVariableLabel($input: SetDynamicVariableLabelInput!) {
  setDynamicVariableLabel(input: $input) {
    dynamic_variable {
      ...DynamicVariable
    }
  }
}
    ${DynamicVariableFragmentDoc}`;
export type SetDynamicVariableLabelMutationFn = Apollo.MutationFunction<SetDynamicVariableLabelMutation, SetDynamicVariableLabelMutationVariables>;

/**
 * __useSetDynamicVariableLabelMutation__
 *
 * To run a mutation, you first call `useSetDynamicVariableLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDynamicVariableLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDynamicVariableLabelMutation, { data, loading, error }] = useSetDynamicVariableLabelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetDynamicVariableLabelMutation(baseOptions?: Apollo.MutationHookOptions<SetDynamicVariableLabelMutation, SetDynamicVariableLabelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetDynamicVariableLabelMutation, SetDynamicVariableLabelMutationVariables>(SetDynamicVariableLabelDocument, options);
      }
export type SetDynamicVariableLabelMutationHookResult = ReturnType<typeof useSetDynamicVariableLabelMutation>;
export type SetDynamicVariableLabelMutationResult = Apollo.MutationResult<SetDynamicVariableLabelMutation>;
export type SetDynamicVariableLabelMutationOptions = Apollo.BaseMutationOptions<SetDynamicVariableLabelMutation, SetDynamicVariableLabelMutationVariables>;
export const SetTransformationParamsDocument = gql`
    mutation SetTransformationParams($input: SetTransformationParamsInput!) {
  setTransformationParams(input: $input) {
    dynamic_variable {
      id
      transformations {
        ...Transformation
      }
    }
  }
}
    ${TransformationFragmentDoc}`;
export type SetTransformationParamsMutationFn = Apollo.MutationFunction<SetTransformationParamsMutation, SetTransformationParamsMutationVariables>;

/**
 * __useSetTransformationParamsMutation__
 *
 * To run a mutation, you first call `useSetTransformationParamsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTransformationParamsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTransformationParamsMutation, { data, loading, error }] = useSetTransformationParamsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetTransformationParamsMutation(baseOptions?: Apollo.MutationHookOptions<SetTransformationParamsMutation, SetTransformationParamsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetTransformationParamsMutation, SetTransformationParamsMutationVariables>(SetTransformationParamsDocument, options);
      }
export type SetTransformationParamsMutationHookResult = ReturnType<typeof useSetTransformationParamsMutation>;
export type SetTransformationParamsMutationResult = Apollo.MutationResult<SetTransformationParamsMutation>;
export type SetTransformationParamsMutationOptions = Apollo.BaseMutationOptions<SetTransformationParamsMutation, SetTransformationParamsMutationVariables>;
export const SetTransformationTypeDocument = gql`
    mutation SetTransformationType($input: SetTransformationTypeInput!) {
  setTransformationType(input: $input) {
    dynamic_variable {
      id
      transformations {
        ...Transformation
      }
    }
  }
}
    ${TransformationFragmentDoc}`;
export type SetTransformationTypeMutationFn = Apollo.MutationFunction<SetTransformationTypeMutation, SetTransformationTypeMutationVariables>;

/**
 * __useSetTransformationTypeMutation__
 *
 * To run a mutation, you first call `useSetTransformationTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTransformationTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTransformationTypeMutation, { data, loading, error }] = useSetTransformationTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetTransformationTypeMutation(baseOptions?: Apollo.MutationHookOptions<SetTransformationTypeMutation, SetTransformationTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetTransformationTypeMutation, SetTransformationTypeMutationVariables>(SetTransformationTypeDocument, options);
      }
export type SetTransformationTypeMutationHookResult = ReturnType<typeof useSetTransformationTypeMutation>;
export type SetTransformationTypeMutationResult = Apollo.MutationResult<SetTransformationTypeMutation>;
export type SetTransformationTypeMutationOptions = Apollo.BaseMutationOptions<SetTransformationTypeMutation, SetTransformationTypeMutationVariables>;
export const GetGeneratedEmrReportDocument = gql`
    query GetGeneratedEmrReport($id: String!) {
  generated_emr_report(id: $id) {
    report {
      id
      message_html
      metadata {
        id
        label
        value
      }
    }
  }
}
    `;

/**
 * __useGetGeneratedEmrReportQuery__
 *
 * To run a query within a React component, call `useGetGeneratedEmrReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneratedEmrReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneratedEmrReportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetGeneratedEmrReportQuery(baseOptions: Apollo.QueryHookOptions<GetGeneratedEmrReportQuery, GetGeneratedEmrReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGeneratedEmrReportQuery, GetGeneratedEmrReportQueryVariables>(GetGeneratedEmrReportDocument, options);
      }
export function useGetGeneratedEmrReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGeneratedEmrReportQuery, GetGeneratedEmrReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGeneratedEmrReportQuery, GetGeneratedEmrReportQueryVariables>(GetGeneratedEmrReportDocument, options);
        }
export type GetGeneratedEmrReportQueryHookResult = ReturnType<typeof useGetGeneratedEmrReportQuery>;
export type GetGeneratedEmrReportLazyQueryHookResult = ReturnType<typeof useGetGeneratedEmrReportLazyQuery>;
export type GetGeneratedEmrReportQueryResult = Apollo.QueryResult<GetGeneratedEmrReportQuery, GetGeneratedEmrReportQueryVariables>;
export const GetEvaluatedRuleDocument = gql`
    query GetEvaluatedRule($id: String!) {
  evaluatedRule(id: $id) {
    evaluatedRule {
      satisfied
      conditions {
        id
        satisfied
        reference {
          key
          data_point_definition_id
          value
        }
        operator
        operand {
          type
          value
        }
      }
    }
  }
}
    `;

/**
 * __useGetEvaluatedRuleQuery__
 *
 * To run a query within a React component, call `useGetEvaluatedRuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEvaluatedRuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEvaluatedRuleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEvaluatedRuleQuery(baseOptions: Apollo.QueryHookOptions<GetEvaluatedRuleQuery, GetEvaluatedRuleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEvaluatedRuleQuery, GetEvaluatedRuleQueryVariables>(GetEvaluatedRuleDocument, options);
      }
export function useGetEvaluatedRuleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEvaluatedRuleQuery, GetEvaluatedRuleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEvaluatedRuleQuery, GetEvaluatedRuleQueryVariables>(GetEvaluatedRuleDocument, options);
        }
export type GetEvaluatedRuleQueryHookResult = ReturnType<typeof useGetEvaluatedRuleQuery>;
export type GetEvaluatedRuleLazyQueryHookResult = ReturnType<typeof useGetEvaluatedRuleLazyQuery>;
export type GetEvaluatedRuleQueryResult = Apollo.QueryResult<GetEvaluatedRuleQuery, GetEvaluatedRuleQueryVariables>;
export const GetExtensionActivityRecordDocument = gql`
    query GetExtensionActivityRecord($id: String!) {
  extensionActivityRecord(id: $id) {
    record {
      id
      activity_id
      pathway_id
      plugin_key
      plugin_action_key
      fields {
        id
        type
        label
        value
      }
      date
      settings {
        value
        label
        key
      }
      data_points {
        label
        value
      }
    }
  }
}
    `;

/**
 * __useGetExtensionActivityRecordQuery__
 *
 * To run a query within a React component, call `useGetExtensionActivityRecordQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExtensionActivityRecordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExtensionActivityRecordQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetExtensionActivityRecordQuery(baseOptions: Apollo.QueryHookOptions<GetExtensionActivityRecordQuery, GetExtensionActivityRecordQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExtensionActivityRecordQuery, GetExtensionActivityRecordQueryVariables>(GetExtensionActivityRecordDocument, options);
      }
export function useGetExtensionActivityRecordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExtensionActivityRecordQuery, GetExtensionActivityRecordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExtensionActivityRecordQuery, GetExtensionActivityRecordQueryVariables>(GetExtensionActivityRecordDocument, options);
        }
export type GetExtensionActivityRecordQueryHookResult = ReturnType<typeof useGetExtensionActivityRecordQuery>;
export type GetExtensionActivityRecordLazyQueryHookResult = ReturnType<typeof useGetExtensionActivityRecordLazyQuery>;
export type GetExtensionActivityRecordQueryResult = Apollo.QueryResult<GetExtensionActivityRecordQuery, GetExtensionActivityRecordQueryVariables>;
export const ConfigureExtensionDocument = gql`
    mutation ConfigureExtension($input: ConfigureExtensionInput!) {
  configureExtension(input: $input) {
    extension {
      ...ExtensionConfiguration
    }
  }
}
    ${ExtensionConfigurationFragmentDoc}`;
export type ConfigureExtensionMutationFn = Apollo.MutationFunction<ConfigureExtensionMutation, ConfigureExtensionMutationVariables>;

/**
 * __useConfigureExtensionMutation__
 *
 * To run a mutation, you first call `useConfigureExtensionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfigureExtensionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [configureExtensionMutation, { data, loading, error }] = useConfigureExtensionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfigureExtensionMutation(baseOptions?: Apollo.MutationHookOptions<ConfigureExtensionMutation, ConfigureExtensionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfigureExtensionMutation, ConfigureExtensionMutationVariables>(ConfigureExtensionDocument, options);
      }
export type ConfigureExtensionMutationHookResult = ReturnType<typeof useConfigureExtensionMutation>;
export type ConfigureExtensionMutationResult = Apollo.MutationResult<ConfigureExtensionMutation>;
export type ConfigureExtensionMutationOptions = Apollo.BaseMutationOptions<ConfigureExtensionMutation, ConfigureExtensionMutationVariables>;
export const GetExtensionConfigurationsDocument = gql`
    query GetExtensionConfigurations($pathway_id: String!) {
  extension_configurations(pathway_id: $pathway_id) {
    extensions {
      ...ExtensionConfiguration
    }
  }
}
    ${ExtensionConfigurationFragmentDoc}`;

/**
 * __useGetExtensionConfigurationsQuery__
 *
 * To run a query within a React component, call `useGetExtensionConfigurationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExtensionConfigurationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExtensionConfigurationsQuery({
 *   variables: {
 *      pathway_id: // value for 'pathway_id'
 *   },
 * });
 */
export function useGetExtensionConfigurationsQuery(baseOptions: Apollo.QueryHookOptions<GetExtensionConfigurationsQuery, GetExtensionConfigurationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExtensionConfigurationsQuery, GetExtensionConfigurationsQueryVariables>(GetExtensionConfigurationsDocument, options);
      }
export function useGetExtensionConfigurationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExtensionConfigurationsQuery, GetExtensionConfigurationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExtensionConfigurationsQuery, GetExtensionConfigurationsQueryVariables>(GetExtensionConfigurationsDocument, options);
        }
export type GetExtensionConfigurationsQueryHookResult = ReturnType<typeof useGetExtensionConfigurationsQuery>;
export type GetExtensionConfigurationsLazyQueryHookResult = ReturnType<typeof useGetExtensionConfigurationsLazyQuery>;
export type GetExtensionConfigurationsQueryResult = Apollo.QueryResult<GetExtensionConfigurationsQuery, GetExtensionConfigurationsQueryVariables>;
export const RemoveExtensionConfigurationDocument = gql`
    mutation RemoveExtensionConfiguration($input: RemoveExtensionConfigurationInput!) {
  removeExtensionConfiguration(input: $input) {
    success
  }
}
    `;
export type RemoveExtensionConfigurationMutationFn = Apollo.MutationFunction<RemoveExtensionConfigurationMutation, RemoveExtensionConfigurationMutationVariables>;

/**
 * __useRemoveExtensionConfigurationMutation__
 *
 * To run a mutation, you first call `useRemoveExtensionConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveExtensionConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeExtensionConfigurationMutation, { data, loading, error }] = useRemoveExtensionConfigurationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveExtensionConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<RemoveExtensionConfigurationMutation, RemoveExtensionConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveExtensionConfigurationMutation, RemoveExtensionConfigurationMutationVariables>(RemoveExtensionConfigurationDocument, options);
      }
export type RemoveExtensionConfigurationMutationHookResult = ReturnType<typeof useRemoveExtensionConfigurationMutation>;
export type RemoveExtensionConfigurationMutationResult = Apollo.MutationResult<RemoveExtensionConfigurationMutation>;
export type RemoveExtensionConfigurationMutationOptions = Apollo.BaseMutationOptions<RemoveExtensionConfigurationMutation, RemoveExtensionConfigurationMutationVariables>;
export const AddDataPointMappingToExtensionActionDocument = gql`
    mutation AddDataPointMappingToExtensionAction($input: AddDataPointMappingToExtensionActionInput!) {
  addDataPointMappingToExtensionAction(input: $input) {
    action {
      id
      extension_data_point_mappings {
        id
        data_point_definition_id
        extension_data_point_key
      }
    }
  }
}
    `;
export type AddDataPointMappingToExtensionActionMutationFn = Apollo.MutationFunction<AddDataPointMappingToExtensionActionMutation, AddDataPointMappingToExtensionActionMutationVariables>;

/**
 * __useAddDataPointMappingToExtensionActionMutation__
 *
 * To run a mutation, you first call `useAddDataPointMappingToExtensionActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDataPointMappingToExtensionActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDataPointMappingToExtensionActionMutation, { data, loading, error }] = useAddDataPointMappingToExtensionActionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddDataPointMappingToExtensionActionMutation(baseOptions?: Apollo.MutationHookOptions<AddDataPointMappingToExtensionActionMutation, AddDataPointMappingToExtensionActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDataPointMappingToExtensionActionMutation, AddDataPointMappingToExtensionActionMutationVariables>(AddDataPointMappingToExtensionActionDocument, options);
      }
export type AddDataPointMappingToExtensionActionMutationHookResult = ReturnType<typeof useAddDataPointMappingToExtensionActionMutation>;
export type AddDataPointMappingToExtensionActionMutationResult = Apollo.MutationResult<AddDataPointMappingToExtensionActionMutation>;
export type AddDataPointMappingToExtensionActionMutationOptions = Apollo.BaseMutationOptions<AddDataPointMappingToExtensionActionMutation, AddDataPointMappingToExtensionActionMutationVariables>;
export const RemoveDataPointMappingFromExtensionActionDocument = gql`
    mutation RemoveDataPointMappingFromExtensionAction($input: RemoveDataPointMappingFromExtensionActionInput!) {
  removeDataPointMappingFromExtensionAction(input: $input) {
    action {
      id
      extension_data_point_mappings {
        id
        data_point_definition_id
        extension_data_point_key
      }
    }
  }
}
    `;
export type RemoveDataPointMappingFromExtensionActionMutationFn = Apollo.MutationFunction<RemoveDataPointMappingFromExtensionActionMutation, RemoveDataPointMappingFromExtensionActionMutationVariables>;

/**
 * __useRemoveDataPointMappingFromExtensionActionMutation__
 *
 * To run a mutation, you first call `useRemoveDataPointMappingFromExtensionActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDataPointMappingFromExtensionActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDataPointMappingFromExtensionActionMutation, { data, loading, error }] = useRemoveDataPointMappingFromExtensionActionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveDataPointMappingFromExtensionActionMutation(baseOptions?: Apollo.MutationHookOptions<RemoveDataPointMappingFromExtensionActionMutation, RemoveDataPointMappingFromExtensionActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveDataPointMappingFromExtensionActionMutation, RemoveDataPointMappingFromExtensionActionMutationVariables>(RemoveDataPointMappingFromExtensionActionDocument, options);
      }
export type RemoveDataPointMappingFromExtensionActionMutationHookResult = ReturnType<typeof useRemoveDataPointMappingFromExtensionActionMutation>;
export type RemoveDataPointMappingFromExtensionActionMutationResult = Apollo.MutationResult<RemoveDataPointMappingFromExtensionActionMutation>;
export type RemoveDataPointMappingFromExtensionActionMutationOptions = Apollo.BaseMutationOptions<RemoveDataPointMappingFromExtensionActionMutation, RemoveDataPointMappingFromExtensionActionMutationVariables>;
export const SetExtensionActionDataPointMappingDataPointDocument = gql`
    mutation SetExtensionActionDataPointMappingDataPoint($input: SetExtensionActionDataPointMappingDataPointInput!) {
  setExtensionActionDataPointMappingDataPoint(input: $input) {
    action {
      id
      extension_data_point_mappings {
        id
        data_point_definition_id
        extension_data_point_key
      }
    }
  }
}
    `;
export type SetExtensionActionDataPointMappingDataPointMutationFn = Apollo.MutationFunction<SetExtensionActionDataPointMappingDataPointMutation, SetExtensionActionDataPointMappingDataPointMutationVariables>;

/**
 * __useSetExtensionActionDataPointMappingDataPointMutation__
 *
 * To run a mutation, you first call `useSetExtensionActionDataPointMappingDataPointMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetExtensionActionDataPointMappingDataPointMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setExtensionActionDataPointMappingDataPointMutation, { data, loading, error }] = useSetExtensionActionDataPointMappingDataPointMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetExtensionActionDataPointMappingDataPointMutation(baseOptions?: Apollo.MutationHookOptions<SetExtensionActionDataPointMappingDataPointMutation, SetExtensionActionDataPointMappingDataPointMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetExtensionActionDataPointMappingDataPointMutation, SetExtensionActionDataPointMappingDataPointMutationVariables>(SetExtensionActionDataPointMappingDataPointDocument, options);
      }
export type SetExtensionActionDataPointMappingDataPointMutationHookResult = ReturnType<typeof useSetExtensionActionDataPointMappingDataPointMutation>;
export type SetExtensionActionDataPointMappingDataPointMutationResult = Apollo.MutationResult<SetExtensionActionDataPointMappingDataPointMutation>;
export type SetExtensionActionDataPointMappingDataPointMutationOptions = Apollo.BaseMutationOptions<SetExtensionActionDataPointMappingDataPointMutation, SetExtensionActionDataPointMappingDataPointMutationVariables>;
export const SetExtensionActionDataPointMappingKeyDocument = gql`
    mutation SetExtensionActionDataPointMappingKey($input: SetExtensionActionDataPointMappingKeyInput!) {
  setExtensionActionDataPointMappingKey(input: $input) {
    action {
      id
      extension_data_point_mappings {
        id
        data_point_definition_id
        extension_data_point_key
      }
    }
  }
}
    `;
export type SetExtensionActionDataPointMappingKeyMutationFn = Apollo.MutationFunction<SetExtensionActionDataPointMappingKeyMutation, SetExtensionActionDataPointMappingKeyMutationVariables>;

/**
 * __useSetExtensionActionDataPointMappingKeyMutation__
 *
 * To run a mutation, you first call `useSetExtensionActionDataPointMappingKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetExtensionActionDataPointMappingKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setExtensionActionDataPointMappingKeyMutation, { data, loading, error }] = useSetExtensionActionDataPointMappingKeyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetExtensionActionDataPointMappingKeyMutation(baseOptions?: Apollo.MutationHookOptions<SetExtensionActionDataPointMappingKeyMutation, SetExtensionActionDataPointMappingKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetExtensionActionDataPointMappingKeyMutation, SetExtensionActionDataPointMappingKeyMutationVariables>(SetExtensionActionDataPointMappingKeyDocument, options);
      }
export type SetExtensionActionDataPointMappingKeyMutationHookResult = ReturnType<typeof useSetExtensionActionDataPointMappingKeyMutation>;
export type SetExtensionActionDataPointMappingKeyMutationResult = Apollo.MutationResult<SetExtensionActionDataPointMappingKeyMutation>;
export type SetExtensionActionDataPointMappingKeyMutationOptions = Apollo.BaseMutationOptions<SetExtensionActionDataPointMappingKeyMutation, SetExtensionActionDataPointMappingKeyMutationVariables>;
export const SetExtensionActionDataPointMappingsDocument = gql`
    mutation SetExtensionActionDataPointMappings($input: SetExtensionActionDataPointMappingsInput!) {
  setExtensionActionDataPointMappings(input: $input) {
    action {
      id
      extension_data_point_mappings {
        id
        data_point_definition_id
        extension_data_point_key
      }
    }
  }
}
    `;
export type SetExtensionActionDataPointMappingsMutationFn = Apollo.MutationFunction<SetExtensionActionDataPointMappingsMutation, SetExtensionActionDataPointMappingsMutationVariables>;

/**
 * __useSetExtensionActionDataPointMappingsMutation__
 *
 * To run a mutation, you first call `useSetExtensionActionDataPointMappingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetExtensionActionDataPointMappingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setExtensionActionDataPointMappingsMutation, { data, loading, error }] = useSetExtensionActionDataPointMappingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetExtensionActionDataPointMappingsMutation(baseOptions?: Apollo.MutationHookOptions<SetExtensionActionDataPointMappingsMutation, SetExtensionActionDataPointMappingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetExtensionActionDataPointMappingsMutation, SetExtensionActionDataPointMappingsMutationVariables>(SetExtensionActionDataPointMappingsDocument, options);
      }
export type SetExtensionActionDataPointMappingsMutationHookResult = ReturnType<typeof useSetExtensionActionDataPointMappingsMutation>;
export type SetExtensionActionDataPointMappingsMutationResult = Apollo.MutationResult<SetExtensionActionDataPointMappingsMutation>;
export type SetExtensionActionDataPointMappingsMutationOptions = Apollo.BaseMutationOptions<SetExtensionActionDataPointMappingsMutation, SetExtensionActionDataPointMappingsMutationVariables>;
export const GetExtensionsDocument = gql`
    query GetExtensions {
  extensions {
    extensions {
      key
      title
      icon_url
      settings {
        description
        fields {
          key
          label
          obfuscated
          required
          description
        }
      }
      actions {
        key
        title
        icon
        category
      }
      available_webhooks {
        key
        description
      }
    }
  }
}
    `;

/**
 * __useGetExtensionsQuery__
 *
 * To run a query within a React component, call `useGetExtensionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExtensionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExtensionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetExtensionsQuery(baseOptions?: Apollo.QueryHookOptions<GetExtensionsQuery, GetExtensionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExtensionsQuery, GetExtensionsQueryVariables>(GetExtensionsDocument, options);
      }
export function useGetExtensionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExtensionsQuery, GetExtensionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExtensionsQuery, GetExtensionsQueryVariables>(GetExtensionsDocument, options);
        }
export type GetExtensionsQueryHookResult = ReturnType<typeof useGetExtensionsQuery>;
export type GetExtensionsLazyQueryHookResult = ReturnType<typeof useGetExtensionsLazyQuery>;
export type GetExtensionsQueryResult = Apollo.QueryResult<GetExtensionsQuery, GetExtensionsQueryVariables>;
export const GetFormResponseDocument = gql`
    query GetFormResponse($input: FormResponseInput!) {
  form_response(input: $input) {
    response {
      answers {
        question_id
        value
        value_type
      }
    }
  }
}
    `;

/**
 * __useGetFormResponseQuery__
 *
 * To run a query within a React component, call `useGetFormResponseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormResponseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormResponseQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetFormResponseQuery(baseOptions: Apollo.QueryHookOptions<GetFormResponseQuery, GetFormResponseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFormResponseQuery, GetFormResponseQueryVariables>(GetFormResponseDocument, options);
      }
export function useGetFormResponseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFormResponseQuery, GetFormResponseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFormResponseQuery, GetFormResponseQueryVariables>(GetFormResponseDocument, options);
        }
export type GetFormResponseQueryHookResult = ReturnType<typeof useGetFormResponseQuery>;
export type GetFormResponseLazyQueryHookResult = ReturnType<typeof useGetFormResponseLazyQuery>;
export type GetFormResponseQueryResult = Apollo.QueryResult<GetFormResponseQuery, GetFormResponseQueryVariables>;
export const SubmitFormResponseDocument = gql`
    mutation SubmitFormResponse($input: SubmitFormResponseInput!) {
  submitFormResponse(input: $input) {
    activity {
      ...Activity
    }
  }
}
    ${ActivityFragmentDoc}`;
export type SubmitFormResponseMutationFn = Apollo.MutationFunction<SubmitFormResponseMutation, SubmitFormResponseMutationVariables>;

/**
 * __useSubmitFormResponseMutation__
 *
 * To run a mutation, you first call `useSubmitFormResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitFormResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitFormResponseMutation, { data, loading, error }] = useSubmitFormResponseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitFormResponseMutation(baseOptions?: Apollo.MutationHookOptions<SubmitFormResponseMutation, SubmitFormResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitFormResponseMutation, SubmitFormResponseMutationVariables>(SubmitFormResponseDocument, options);
      }
export type SubmitFormResponseMutationHookResult = ReturnType<typeof useSubmitFormResponseMutation>;
export type SubmitFormResponseMutationResult = Apollo.MutationResult<SubmitFormResponseMutation>;
export type SubmitFormResponseMutationOptions = Apollo.BaseMutationOptions<SubmitFormResponseMutation, SubmitFormResponseMutationVariables>;
export const GetFormDocument = gql`
    query GetForm($id: String!) {
  form(id: $id) {
    form {
      ...Form
    }
  }
}
    ${FormFragmentDoc}`;

/**
 * __useGetFormQuery__
 *
 * To run a query within a React component, call `useGetFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFormQuery(baseOptions: Apollo.QueryHookOptions<GetFormQuery, GetFormQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFormQuery, GetFormQueryVariables>(GetFormDocument, options);
      }
export function useGetFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFormQuery, GetFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFormQuery, GetFormQueryVariables>(GetFormDocument, options);
        }
export type GetFormQueryHookResult = ReturnType<typeof useGetFormQuery>;
export type GetFormLazyQueryHookResult = ReturnType<typeof useGetFormLazyQuery>;
export type GetFormQueryResult = Apollo.QueryResult<GetFormQuery, GetFormQueryVariables>;
export const GetFormLibraryDocument = gql`
    query GetFormLibrary {
  forms {
    forms {
      id
      title
      key
      created {
        user_email
        user_id
        date
      }
      last_updated {
        user_email
        user_id
        date
      }
      question_ids
      pathways_used_in {
        id
        title
      }
    }
  }
}
    `;

/**
 * __useGetFormLibraryQuery__
 *
 * To run a query within a React component, call `useGetFormLibraryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormLibraryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormLibraryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFormLibraryQuery(baseOptions?: Apollo.QueryHookOptions<GetFormLibraryQuery, GetFormLibraryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFormLibraryQuery, GetFormLibraryQueryVariables>(GetFormLibraryDocument, options);
      }
export function useGetFormLibraryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFormLibraryQuery, GetFormLibraryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFormLibraryQuery, GetFormLibraryQueryVariables>(GetFormLibraryDocument, options);
        }
export type GetFormLibraryQueryHookResult = ReturnType<typeof useGetFormLibraryQuery>;
export type GetFormLibraryLazyQueryHookResult = ReturnType<typeof useGetFormLibraryLazyQuery>;
export type GetFormLibraryQueryResult = Apollo.QueryResult<GetFormLibraryQuery, GetFormLibraryQueryVariables>;
export const GetActionDocument = gql`
    query GetAction($id: String!) {
  action(id: $id) {
    action {
      ...Action
    }
  }
}
    ${ActionFragmentDoc}`;

/**
 * __useGetActionQuery__
 *
 * To run a query within a React component, call `useGetActionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetActionQuery(baseOptions: Apollo.QueryHookOptions<GetActionQuery, GetActionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActionQuery, GetActionQueryVariables>(GetActionDocument, options);
      }
export function useGetActionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActionQuery, GetActionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActionQuery, GetActionQueryVariables>(GetActionDocument, options);
        }
export type GetActionQueryHookResult = ReturnType<typeof useGetActionQuery>;
export type GetActionLazyQueryHookResult = ReturnType<typeof useGetActionLazyQuery>;
export type GetActionQueryResult = Apollo.QueryResult<GetActionQuery, GetActionQueryVariables>;
export const GetPathwayCaseApiCallDocument = gql`
    query GetPathwayCaseApiCall($id: String!) {
  pathwayCaseApiCall(id: $id) {
    code
    success
    api_call {
      created_at
      title
      status
      id
      request {
        body
        method
        endpoint
        headers {
          value
          key
        }
      }
      responses {
        status
        date
        body
      }
    }
  }
}
    `;

/**
 * __useGetPathwayCaseApiCallQuery__
 *
 * To run a query within a React component, call `useGetPathwayCaseApiCallQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPathwayCaseApiCallQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPathwayCaseApiCallQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPathwayCaseApiCallQuery(baseOptions: Apollo.QueryHookOptions<GetPathwayCaseApiCallQuery, GetPathwayCaseApiCallQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPathwayCaseApiCallQuery, GetPathwayCaseApiCallQueryVariables>(GetPathwayCaseApiCallDocument, options);
      }
export function useGetPathwayCaseApiCallLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPathwayCaseApiCallQuery, GetPathwayCaseApiCallQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPathwayCaseApiCallQuery, GetPathwayCaseApiCallQueryVariables>(GetPathwayCaseApiCallDocument, options);
        }
export type GetPathwayCaseApiCallQueryHookResult = ReturnType<typeof useGetPathwayCaseApiCallQuery>;
export type GetPathwayCaseApiCallLazyQueryHookResult = ReturnType<typeof useGetPathwayCaseApiCallLazyQuery>;
export type GetPathwayCaseApiCallQueryResult = Apollo.QueryResult<GetPathwayCaseApiCallQuery, GetPathwayCaseApiCallQueryVariables>;
export const GetPathwayCaseApiCallsDocument = gql`
    query GetPathwayCaseApiCalls($pathway_case_id: String!) {
  pathwayCaseApiCalls(pathway_case_id: $pathway_case_id) {
    code
    success
    api_calls {
      title
      created_at
      status
      id
      request {
        body
        method
        endpoint
        headers {
          value
          key
        }
      }
      responses {
        status
        date
        body
      }
    }
  }
}
    `;

/**
 * __useGetPathwayCaseApiCallsQuery__
 *
 * To run a query within a React component, call `useGetPathwayCaseApiCallsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPathwayCaseApiCallsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPathwayCaseApiCallsQuery({
 *   variables: {
 *      pathway_case_id: // value for 'pathway_case_id'
 *   },
 * });
 */
export function useGetPathwayCaseApiCallsQuery(baseOptions: Apollo.QueryHookOptions<GetPathwayCaseApiCallsQuery, GetPathwayCaseApiCallsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPathwayCaseApiCallsQuery, GetPathwayCaseApiCallsQueryVariables>(GetPathwayCaseApiCallsDocument, options);
      }
export function useGetPathwayCaseApiCallsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPathwayCaseApiCallsQuery, GetPathwayCaseApiCallsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPathwayCaseApiCallsQuery, GetPathwayCaseApiCallsQueryVariables>(GetPathwayCaseApiCallsDocument, options);
        }
export type GetPathwayCaseApiCallsQueryHookResult = ReturnType<typeof useGetPathwayCaseApiCallsQuery>;
export type GetPathwayCaseApiCallsLazyQueryHookResult = ReturnType<typeof useGetPathwayCaseApiCallsLazyQuery>;
export type GetPathwayCaseApiCallsQueryResult = Apollo.QueryResult<GetPathwayCaseApiCallsQuery, GetPathwayCaseApiCallsQueryVariables>;
export const OnApiCallCreatedDocument = gql`
    subscription OnApiCallCreated($pathway_case_id: String!) {
  apiCallCreated(pathway_case_id: $pathway_case_id) {
    status
    created_at
    status
    id
    title
    request {
      body
      method
      endpoint
      headers {
        value
        key
      }
    }
    responses {
      status
      date
      body
    }
  }
}
    `;

/**
 * __useOnApiCallCreatedSubscription__
 *
 * To run a query within a React component, call `useOnApiCallCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnApiCallCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnApiCallCreatedSubscription({
 *   variables: {
 *      pathway_case_id: // value for 'pathway_case_id'
 *   },
 * });
 */
export function useOnApiCallCreatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnApiCallCreatedSubscription, OnApiCallCreatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnApiCallCreatedSubscription, OnApiCallCreatedSubscriptionVariables>(OnApiCallCreatedDocument, options);
      }
export type OnApiCallCreatedSubscriptionHookResult = ReturnType<typeof useOnApiCallCreatedSubscription>;
export type OnApiCallCreatedSubscriptionResult = Apollo.SubscriptionResult<OnApiCallCreatedSubscription>;
export const OnApiCallUpdatedDocument = gql`
    subscription OnApiCallUpdated($pathway_case_id: String!) {
  apiCallUpdated(pathway_case_id: $pathway_case_id) {
    status
    created_at
    status
    id
    title
    request {
      body
      method
      endpoint
      headers {
        value
        key
      }
    }
    responses {
      status
      date
      body
    }
  }
}
    `;

/**
 * __useOnApiCallUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnApiCallUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnApiCallUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnApiCallUpdatedSubscription({
 *   variables: {
 *      pathway_case_id: // value for 'pathway_case_id'
 *   },
 * });
 */
export function useOnApiCallUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnApiCallUpdatedSubscription, OnApiCallUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnApiCallUpdatedSubscription, OnApiCallUpdatedSubscriptionVariables>(OnApiCallUpdatedDocument, options);
      }
export type OnApiCallUpdatedSubscriptionHookResult = ReturnType<typeof useOnApiCallUpdatedSubscription>;
export type OnApiCallUpdatedSubscriptionResult = Apollo.SubscriptionResult<OnApiCallUpdatedSubscription>;
export const GetPathwayCaseWebhookCallDocument = gql`
    query GetPathwayCaseWebhookCall($id: String!) {
  pathwayCaseWebhookCall(webhook_call_id: $id) {
    webhook_call {
      ...PathwayCaseWebhookCall
    }
  }
}
    ${PathwayCaseWebhookCallFragmentDoc}`;

/**
 * __useGetPathwayCaseWebhookCallQuery__
 *
 * To run a query within a React component, call `useGetPathwayCaseWebhookCallQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPathwayCaseWebhookCallQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPathwayCaseWebhookCallQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPathwayCaseWebhookCallQuery(baseOptions: Apollo.QueryHookOptions<GetPathwayCaseWebhookCallQuery, GetPathwayCaseWebhookCallQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPathwayCaseWebhookCallQuery, GetPathwayCaseWebhookCallQueryVariables>(GetPathwayCaseWebhookCallDocument, options);
      }
export function useGetPathwayCaseWebhookCallLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPathwayCaseWebhookCallQuery, GetPathwayCaseWebhookCallQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPathwayCaseWebhookCallQuery, GetPathwayCaseWebhookCallQueryVariables>(GetPathwayCaseWebhookCallDocument, options);
        }
export type GetPathwayCaseWebhookCallQueryHookResult = ReturnType<typeof useGetPathwayCaseWebhookCallQuery>;
export type GetPathwayCaseWebhookCallLazyQueryHookResult = ReturnType<typeof useGetPathwayCaseWebhookCallLazyQuery>;
export type GetPathwayCaseWebhookCallQueryResult = Apollo.QueryResult<GetPathwayCaseWebhookCallQuery, GetPathwayCaseWebhookCallQueryVariables>;
export const GetPathwayCaseWebhookCallsDocument = gql`
    query GetPathwayCaseWebhookCalls($pathway_case_id: String!) {
  pathwayCaseWebhookCalls(pathway_case_id: $pathway_case_id) {
    webhook_calls {
      ...PathwayCaseWebhookCall
    }
  }
}
    ${PathwayCaseWebhookCallFragmentDoc}`;

/**
 * __useGetPathwayCaseWebhookCallsQuery__
 *
 * To run a query within a React component, call `useGetPathwayCaseWebhookCallsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPathwayCaseWebhookCallsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPathwayCaseWebhookCallsQuery({
 *   variables: {
 *      pathway_case_id: // value for 'pathway_case_id'
 *   },
 * });
 */
export function useGetPathwayCaseWebhookCallsQuery(baseOptions: Apollo.QueryHookOptions<GetPathwayCaseWebhookCallsQuery, GetPathwayCaseWebhookCallsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPathwayCaseWebhookCallsQuery, GetPathwayCaseWebhookCallsQueryVariables>(GetPathwayCaseWebhookCallsDocument, options);
      }
export function useGetPathwayCaseWebhookCallsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPathwayCaseWebhookCallsQuery, GetPathwayCaseWebhookCallsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPathwayCaseWebhookCallsQuery, GetPathwayCaseWebhookCallsQueryVariables>(GetPathwayCaseWebhookCallsDocument, options);
        }
export type GetPathwayCaseWebhookCallsQueryHookResult = ReturnType<typeof useGetPathwayCaseWebhookCallsQuery>;
export type GetPathwayCaseWebhookCallsLazyQueryHookResult = ReturnType<typeof useGetPathwayCaseWebhookCallsLazyQuery>;
export type GetPathwayCaseWebhookCallsQueryResult = Apollo.QueryResult<GetPathwayCaseWebhookCallsQuery, GetPathwayCaseWebhookCallsQueryVariables>;
export const OnWebhookCallCreatedDocument = gql`
    subscription OnWebhookCallCreated($pathway_case_id: String!) {
  webhookCallCreated(pathway_case_id: $pathway_case_id) {
    ...PathwayCaseWebhookCall
  }
}
    ${PathwayCaseWebhookCallFragmentDoc}`;

/**
 * __useOnWebhookCallCreatedSubscription__
 *
 * To run a query within a React component, call `useOnWebhookCallCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnWebhookCallCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnWebhookCallCreatedSubscription({
 *   variables: {
 *      pathway_case_id: // value for 'pathway_case_id'
 *   },
 * });
 */
export function useOnWebhookCallCreatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnWebhookCallCreatedSubscription, OnWebhookCallCreatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnWebhookCallCreatedSubscription, OnWebhookCallCreatedSubscriptionVariables>(OnWebhookCallCreatedDocument, options);
      }
export type OnWebhookCallCreatedSubscriptionHookResult = ReturnType<typeof useOnWebhookCallCreatedSubscription>;
export type OnWebhookCallCreatedSubscriptionResult = Apollo.SubscriptionResult<OnWebhookCallCreatedSubscription>;
export const OnWebhookCallUpdatedDocument = gql`
    subscription OnWebhookCallUpdated($pathway_case_id: String!) {
  webhookCallUpdated(pathway_case_id: $pathway_case_id) {
    ...PathwayCaseWebhookCall
  }
}
    ${PathwayCaseWebhookCallFragmentDoc}`;

/**
 * __useOnWebhookCallUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnWebhookCallUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnWebhookCallUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnWebhookCallUpdatedSubscription({
 *   variables: {
 *      pathway_case_id: // value for 'pathway_case_id'
 *   },
 * });
 */
export function useOnWebhookCallUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnWebhookCallUpdatedSubscription, OnWebhookCallUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnWebhookCallUpdatedSubscription, OnWebhookCallUpdatedSubscriptionVariables>(OnWebhookCallUpdatedDocument, options);
      }
export type OnWebhookCallUpdatedSubscriptionHookResult = ReturnType<typeof useOnWebhookCallUpdatedSubscription>;
export type OnWebhookCallUpdatedSubscriptionResult = Apollo.SubscriptionResult<OnWebhookCallUpdatedSubscription>;
export const OnWebhookCallsDeletedDocument = gql`
    subscription OnWebhookCallsDeleted($pathway_case_id: String!) {
  webhookCallsDeleted(pathway_case_id: $pathway_case_id) {
    webhook_call_ids
  }
}
    `;

/**
 * __useOnWebhookCallsDeletedSubscription__
 *
 * To run a query within a React component, call `useOnWebhookCallsDeletedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnWebhookCallsDeletedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnWebhookCallsDeletedSubscription({
 *   variables: {
 *      pathway_case_id: // value for 'pathway_case_id'
 *   },
 * });
 */
export function useOnWebhookCallsDeletedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnWebhookCallsDeletedSubscription, OnWebhookCallsDeletedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnWebhookCallsDeletedSubscription, OnWebhookCallsDeletedSubscriptionVariables>(OnWebhookCallsDeletedDocument, options);
      }
export type OnWebhookCallsDeletedSubscriptionHookResult = ReturnType<typeof useOnWebhookCallsDeletedSubscription>;
export type OnWebhookCallsDeletedSubscriptionResult = Apollo.SubscriptionResult<OnWebhookCallsDeletedSubscription>;
export const StickyNoteDocument = gql`
    query StickyNote($id: String!) {
  stickyNote(id: $id) {
    success
    code
    stickyNote {
      id
      body {
        text
        format
      }
    }
  }
}
    `;

/**
 * __useStickyNoteQuery__
 *
 * To run a query within a React component, call `useStickyNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useStickyNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStickyNoteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStickyNoteQuery(baseOptions: Apollo.QueryHookOptions<StickyNoteQuery, StickyNoteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StickyNoteQuery, StickyNoteQueryVariables>(StickyNoteDocument, options);
      }
export function useStickyNoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StickyNoteQuery, StickyNoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StickyNoteQuery, StickyNoteQueryVariables>(StickyNoteDocument, options);
        }
export type StickyNoteQueryHookResult = ReturnType<typeof useStickyNoteQuery>;
export type StickyNoteLazyQueryHookResult = ReturnType<typeof useStickyNoteLazyQuery>;
export type StickyNoteQueryResult = Apollo.QueryResult<StickyNoteQuery, StickyNoteQueryVariables>;
export const GetWebhookDocument = gql`
    query GetWebhook($input: WebhookInput!) {
  webhook(input: $input) {
    success
    webhook {
      endpoint
      endpoint_test
      headers {
        key
        value
      }
      enabled
      name
      subscribed_events
      signing_key
    }
  }
}
    `;

/**
 * __useGetWebhookQuery__
 *
 * To run a query within a React component, call `useGetWebhookQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebhookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebhookQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetWebhookQuery(baseOptions: Apollo.QueryHookOptions<GetWebhookQuery, GetWebhookQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWebhookQuery, GetWebhookQueryVariables>(GetWebhookDocument, options);
      }
export function useGetWebhookLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWebhookQuery, GetWebhookQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWebhookQuery, GetWebhookQueryVariables>(GetWebhookDocument, options);
        }
export type GetWebhookQueryHookResult = ReturnType<typeof useGetWebhookQuery>;
export type GetWebhookLazyQueryHookResult = ReturnType<typeof useGetWebhookLazyQuery>;
export type GetWebhookQueryResult = Apollo.QueryResult<GetWebhookQuery, GetWebhookQueryVariables>;
export const GetWebhooksDocument = gql`
    query GetWebhooks($pathway_id: String!) {
  pathway(id: $pathway_id) {
    pathway {
      settings {
        webhooks {
          id
          endpoint
          endpoint_test
          headers {
            key
            value
          }
          enabled
          name
          created_at
          subscribed_events
          signing_key
        }
      }
    }
  }
}
    `;

/**
 * __useGetWebhooksQuery__
 *
 * To run a query within a React component, call `useGetWebhooksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebhooksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebhooksQuery({
 *   variables: {
 *      pathway_id: // value for 'pathway_id'
 *   },
 * });
 */
export function useGetWebhooksQuery(baseOptions: Apollo.QueryHookOptions<GetWebhooksQuery, GetWebhooksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWebhooksQuery, GetWebhooksQueryVariables>(GetWebhooksDocument, options);
      }
export function useGetWebhooksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWebhooksQuery, GetWebhooksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWebhooksQuery, GetWebhooksQueryVariables>(GetWebhooksDocument, options);
        }
export type GetWebhooksQueryHookResult = ReturnType<typeof useGetWebhooksQuery>;
export type GetWebhooksLazyQueryHookResult = ReturnType<typeof useGetWebhooksLazyQuery>;
export type GetWebhooksQueryResult = Apollo.QueryResult<GetWebhooksQuery, GetWebhooksQueryVariables>;
export const ConfigureHostedPagesLinkDocument = gql`
    mutation ConfigureHostedPagesLink($input: ConfigureAhpLinkInput!) {
  configureAhpLink(input: $input) {
    ahp_link {
      ...HostedPagesLink
    }
  }
}
    ${HostedPagesLinkFragmentDoc}`;
export type ConfigureHostedPagesLinkMutationFn = Apollo.MutationFunction<ConfigureHostedPagesLinkMutation, ConfigureHostedPagesLinkMutationVariables>;

/**
 * __useConfigureHostedPagesLinkMutation__
 *
 * To run a mutation, you first call `useConfigureHostedPagesLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfigureHostedPagesLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [configureHostedPagesLinkMutation, { data, loading, error }] = useConfigureHostedPagesLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfigureHostedPagesLinkMutation(baseOptions?: Apollo.MutationHookOptions<ConfigureHostedPagesLinkMutation, ConfigureHostedPagesLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfigureHostedPagesLinkMutation, ConfigureHostedPagesLinkMutationVariables>(ConfigureHostedPagesLinkDocument, options);
      }
export type ConfigureHostedPagesLinkMutationHookResult = ReturnType<typeof useConfigureHostedPagesLinkMutation>;
export type ConfigureHostedPagesLinkMutationResult = Apollo.MutationResult<ConfigureHostedPagesLinkMutation>;
export type ConfigureHostedPagesLinkMutationOptions = Apollo.BaseMutationOptions<ConfigureHostedPagesLinkMutation, ConfigureHostedPagesLinkMutationVariables>;
export const GetHostedPagesLinksDocument = gql`
    query GetHostedPagesLinks($careflow_id: String!) {
  ahp_links(careflow_id: $careflow_id) {
    ahp_links {
      ...HostedPagesLink
    }
  }
}
    ${HostedPagesLinkFragmentDoc}`;

/**
 * __useGetHostedPagesLinksQuery__
 *
 * To run a query within a React component, call `useGetHostedPagesLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHostedPagesLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHostedPagesLinksQuery({
 *   variables: {
 *      careflow_id: // value for 'careflow_id'
 *   },
 * });
 */
export function useGetHostedPagesLinksQuery(baseOptions: Apollo.QueryHookOptions<GetHostedPagesLinksQuery, GetHostedPagesLinksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHostedPagesLinksQuery, GetHostedPagesLinksQueryVariables>(GetHostedPagesLinksDocument, options);
      }
export function useGetHostedPagesLinksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHostedPagesLinksQuery, GetHostedPagesLinksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHostedPagesLinksQuery, GetHostedPagesLinksQueryVariables>(GetHostedPagesLinksDocument, options);
        }
export type GetHostedPagesLinksQueryHookResult = ReturnType<typeof useGetHostedPagesLinksQuery>;
export type GetHostedPagesLinksLazyQueryHookResult = ReturnType<typeof useGetHostedPagesLinksLazyQuery>;
export type GetHostedPagesLinksQueryResult = Apollo.QueryResult<GetHostedPagesLinksQuery, GetHostedPagesLinksQueryVariables>;
export const IsSlugUniqueDocument = gql`
    query IsSlugUnique($slug: String!, $id: String) {
  is_slug_unique(slug: $slug, id: $id) {
    is_slug_unique
  }
}
    `;

/**
 * __useIsSlugUniqueQuery__
 *
 * To run a query within a React component, call `useIsSlugUniqueQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsSlugUniqueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsSlugUniqueQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIsSlugUniqueQuery(baseOptions: Apollo.QueryHookOptions<IsSlugUniqueQuery, IsSlugUniqueQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsSlugUniqueQuery, IsSlugUniqueQueryVariables>(IsSlugUniqueDocument, options);
      }
export function useIsSlugUniqueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsSlugUniqueQuery, IsSlugUniqueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsSlugUniqueQuery, IsSlugUniqueQueryVariables>(IsSlugUniqueDocument, options);
        }
export type IsSlugUniqueQueryHookResult = ReturnType<typeof useIsSlugUniqueQuery>;
export type IsSlugUniqueLazyQueryHookResult = ReturnType<typeof useIsSlugUniqueLazyQuery>;
export type IsSlugUniqueQueryResult = Apollo.QueryResult<IsSlugUniqueQuery, IsSlugUniqueQueryVariables>;
export const MarkReleaseAsLiveDocument = gql`
    mutation MarkReleaseAsLive($input: MarkReleaseAsLiveInput!) {
  markReleaseAsLive(input: $input) {
    success
  }
}
    `;
export type MarkReleaseAsLiveMutationFn = Apollo.MutationFunction<MarkReleaseAsLiveMutation, MarkReleaseAsLiveMutationVariables>;

/**
 * __useMarkReleaseAsLiveMutation__
 *
 * To run a mutation, you first call `useMarkReleaseAsLiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkReleaseAsLiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markReleaseAsLiveMutation, { data, loading, error }] = useMarkReleaseAsLiveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkReleaseAsLiveMutation(baseOptions?: Apollo.MutationHookOptions<MarkReleaseAsLiveMutation, MarkReleaseAsLiveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkReleaseAsLiveMutation, MarkReleaseAsLiveMutationVariables>(MarkReleaseAsLiveDocument, options);
      }
export type MarkReleaseAsLiveMutationHookResult = ReturnType<typeof useMarkReleaseAsLiveMutation>;
export type MarkReleaseAsLiveMutationResult = Apollo.MutationResult<MarkReleaseAsLiveMutation>;
export type MarkReleaseAsLiveMutationOptions = Apollo.BaseMutationOptions<MarkReleaseAsLiveMutation, MarkReleaseAsLiveMutationVariables>;
export const AddMessageAttachmentDocument = gql`
    mutation AddMessageAttachment($input: AddMessageAttachmentInput!) {
  addMessageAttachment(input: $input) {
    success
    message {
      id
      subject
      body
      attachments {
        id
        name
        url
        type
      }
    }
  }
}
    `;
export type AddMessageAttachmentMutationFn = Apollo.MutationFunction<AddMessageAttachmentMutation, AddMessageAttachmentMutationVariables>;

/**
 * __useAddMessageAttachmentMutation__
 *
 * To run a mutation, you first call `useAddMessageAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMessageAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMessageAttachmentMutation, { data, loading, error }] = useAddMessageAttachmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddMessageAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<AddMessageAttachmentMutation, AddMessageAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddMessageAttachmentMutation, AddMessageAttachmentMutationVariables>(AddMessageAttachmentDocument, options);
      }
export type AddMessageAttachmentMutationHookResult = ReturnType<typeof useAddMessageAttachmentMutation>;
export type AddMessageAttachmentMutationResult = Apollo.MutationResult<AddMessageAttachmentMutation>;
export type AddMessageAttachmentMutationOptions = Apollo.BaseMutationOptions<AddMessageAttachmentMutation, AddMessageAttachmentMutationVariables>;
export const GetMessageDocument = gql`
    query GetMessage($id: String!) {
  message(id: $id) {
    message {
      id
      body
      subject
      attachments {
        id
        name
        type
        url
      }
    }
  }
}
    `;

/**
 * __useGetMessageQuery__
 *
 * To run a query within a React component, call `useGetMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMessageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMessageQuery(baseOptions: Apollo.QueryHookOptions<GetMessageQuery, GetMessageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMessageQuery, GetMessageQueryVariables>(GetMessageDocument, options);
      }
export function useGetMessageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMessageQuery, GetMessageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMessageQuery, GetMessageQueryVariables>(GetMessageDocument, options);
        }
export type GetMessageQueryHookResult = ReturnType<typeof useGetMessageQuery>;
export type GetMessageLazyQueryHookResult = ReturnType<typeof useGetMessageLazyQuery>;
export type GetMessageQueryResult = Apollo.QueryResult<GetMessageQuery, GetMessageQueryVariables>;
export const RemoveMessageAttachmentDocument = gql`
    mutation RemoveMessageAttachment($input: RemoveMessageAttachmentInput!) {
  removeMessageAttachment(input: $input) {
    success
    message {
      id
      subject
      body
      attachments {
        id
        name
        url
        type
      }
    }
  }
}
    `;
export type RemoveMessageAttachmentMutationFn = Apollo.MutationFunction<RemoveMessageAttachmentMutation, RemoveMessageAttachmentMutationVariables>;

/**
 * __useRemoveMessageAttachmentMutation__
 *
 * To run a mutation, you first call `useRemoveMessageAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMessageAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMessageAttachmentMutation, { data, loading, error }] = useRemoveMessageAttachmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveMessageAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<RemoveMessageAttachmentMutation, RemoveMessageAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveMessageAttachmentMutation, RemoveMessageAttachmentMutationVariables>(RemoveMessageAttachmentDocument, options);
      }
export type RemoveMessageAttachmentMutationHookResult = ReturnType<typeof useRemoveMessageAttachmentMutation>;
export type RemoveMessageAttachmentMutationResult = Apollo.MutationResult<RemoveMessageAttachmentMutation>;
export type RemoveMessageAttachmentMutationOptions = Apollo.BaseMutationOptions<RemoveMessageAttachmentMutation, RemoveMessageAttachmentMutationVariables>;
export const ReorderMessageAttachmentsDocument = gql`
    mutation ReorderMessageAttachments($input: ReorderMessageAttachmentsInput!) {
  reorderMessageAttachments(input: $input) {
    success
    message {
      id
      subject
      body
      attachments {
        id
        name
        url
        type
      }
    }
  }
}
    `;
export type ReorderMessageAttachmentsMutationFn = Apollo.MutationFunction<ReorderMessageAttachmentsMutation, ReorderMessageAttachmentsMutationVariables>;

/**
 * __useReorderMessageAttachmentsMutation__
 *
 * To run a mutation, you first call `useReorderMessageAttachmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderMessageAttachmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderMessageAttachmentsMutation, { data, loading, error }] = useReorderMessageAttachmentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReorderMessageAttachmentsMutation(baseOptions?: Apollo.MutationHookOptions<ReorderMessageAttachmentsMutation, ReorderMessageAttachmentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReorderMessageAttachmentsMutation, ReorderMessageAttachmentsMutationVariables>(ReorderMessageAttachmentsDocument, options);
      }
export type ReorderMessageAttachmentsMutationHookResult = ReturnType<typeof useReorderMessageAttachmentsMutation>;
export type ReorderMessageAttachmentsMutationResult = Apollo.MutationResult<ReorderMessageAttachmentsMutation>;
export type ReorderMessageAttachmentsMutationOptions = Apollo.BaseMutationOptions<ReorderMessageAttachmentsMutation, ReorderMessageAttachmentsMutationVariables>;
export const SetMessageAttachmentNameDocument = gql`
    mutation SetMessageAttachmentName($input: SetMessageAttachmentNameInput!) {
  setMessageAttachmentName(input: $input) {
    code
    success
    message {
      id
      attachments {
        id
        name
        url
      }
    }
  }
}
    `;
export type SetMessageAttachmentNameMutationFn = Apollo.MutationFunction<SetMessageAttachmentNameMutation, SetMessageAttachmentNameMutationVariables>;

/**
 * __useSetMessageAttachmentNameMutation__
 *
 * To run a mutation, you first call `useSetMessageAttachmentNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMessageAttachmentNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMessageAttachmentNameMutation, { data, loading, error }] = useSetMessageAttachmentNameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetMessageAttachmentNameMutation(baseOptions?: Apollo.MutationHookOptions<SetMessageAttachmentNameMutation, SetMessageAttachmentNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetMessageAttachmentNameMutation, SetMessageAttachmentNameMutationVariables>(SetMessageAttachmentNameDocument, options);
      }
export type SetMessageAttachmentNameMutationHookResult = ReturnType<typeof useSetMessageAttachmentNameMutation>;
export type SetMessageAttachmentNameMutationResult = Apollo.MutationResult<SetMessageAttachmentNameMutation>;
export type SetMessageAttachmentNameMutationOptions = Apollo.BaseMutationOptions<SetMessageAttachmentNameMutation, SetMessageAttachmentNameMutationVariables>;
export const SetMessageAttachmentUrlDocument = gql`
    mutation SetMessageAttachmentUrl($input: SetMessageAttachmentUrlInput!) {
  setMessageAttachmentUrl(input: $input) {
    code
    success
    message {
      id
      attachments {
        id
        name
        url
      }
    }
  }
}
    `;
export type SetMessageAttachmentUrlMutationFn = Apollo.MutationFunction<SetMessageAttachmentUrlMutation, SetMessageAttachmentUrlMutationVariables>;

/**
 * __useSetMessageAttachmentUrlMutation__
 *
 * To run a mutation, you first call `useSetMessageAttachmentUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMessageAttachmentUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMessageAttachmentUrlMutation, { data, loading, error }] = useSetMessageAttachmentUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetMessageAttachmentUrlMutation(baseOptions?: Apollo.MutationHookOptions<SetMessageAttachmentUrlMutation, SetMessageAttachmentUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetMessageAttachmentUrlMutation, SetMessageAttachmentUrlMutationVariables>(SetMessageAttachmentUrlDocument, options);
      }
export type SetMessageAttachmentUrlMutationHookResult = ReturnType<typeof useSetMessageAttachmentUrlMutation>;
export type SetMessageAttachmentUrlMutationResult = Apollo.MutationResult<SetMessageAttachmentUrlMutation>;
export type SetMessageAttachmentUrlMutationOptions = Apollo.BaseMutationOptions<SetMessageAttachmentUrlMutation, SetMessageAttachmentUrlMutationVariables>;
export const SetMessageBodyDocument = gql`
    mutation SetMessageBody($input: SetMessageBodyInput!) {
  setMessageBody(input: $input) {
    success
    message {
      id
      body
    }
  }
}
    `;
export type SetMessageBodyMutationFn = Apollo.MutationFunction<SetMessageBodyMutation, SetMessageBodyMutationVariables>;

/**
 * __useSetMessageBodyMutation__
 *
 * To run a mutation, you first call `useSetMessageBodyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMessageBodyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMessageBodyMutation, { data, loading, error }] = useSetMessageBodyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetMessageBodyMutation(baseOptions?: Apollo.MutationHookOptions<SetMessageBodyMutation, SetMessageBodyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetMessageBodyMutation, SetMessageBodyMutationVariables>(SetMessageBodyDocument, options);
      }
export type SetMessageBodyMutationHookResult = ReturnType<typeof useSetMessageBodyMutation>;
export type SetMessageBodyMutationResult = Apollo.MutationResult<SetMessageBodyMutation>;
export type SetMessageBodyMutationOptions = Apollo.BaseMutationOptions<SetMessageBodyMutation, SetMessageBodyMutationVariables>;
export const SetMessageSubjectDocument = gql`
    mutation SetMessageSubject($input: SetMessageSubjectInput!) {
  setMessageSubject(input: $input) {
    success
    message {
      id
      subject
    }
  }
}
    `;
export type SetMessageSubjectMutationFn = Apollo.MutationFunction<SetMessageSubjectMutation, SetMessageSubjectMutationVariables>;

/**
 * __useSetMessageSubjectMutation__
 *
 * To run a mutation, you first call `useSetMessageSubjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMessageSubjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMessageSubjectMutation, { data, loading, error }] = useSetMessageSubjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetMessageSubjectMutation(baseOptions?: Apollo.MutationHookOptions<SetMessageSubjectMutation, SetMessageSubjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetMessageSubjectMutation, SetMessageSubjectMutationVariables>(SetMessageSubjectDocument, options);
      }
export type SetMessageSubjectMutationHookResult = ReturnType<typeof useSetMessageSubjectMutation>;
export type SetMessageSubjectMutationResult = Apollo.MutationResult<SetMessageSubjectMutation>;
export type SetMessageSubjectMutationOptions = Apollo.BaseMutationOptions<SetMessageSubjectMutation, SetMessageSubjectMutationVariables>;
export const GetGeneratedMessageDocument = gql`
    query GetGeneratedMessage($id: String!) {
  generated_message(id: $id) {
    message {
      id
      body
      format
      subject
      attachments {
        id
        name
        type
        url
      }
    }
  }
}
    `;

/**
 * __useGetGeneratedMessageQuery__
 *
 * To run a query within a React component, call `useGetGeneratedMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneratedMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneratedMessageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetGeneratedMessageQuery(baseOptions: Apollo.QueryHookOptions<GetGeneratedMessageQuery, GetGeneratedMessageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGeneratedMessageQuery, GetGeneratedMessageQueryVariables>(GetGeneratedMessageDocument, options);
      }
export function useGetGeneratedMessageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGeneratedMessageQuery, GetGeneratedMessageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGeneratedMessageQuery, GetGeneratedMessageQueryVariables>(GetGeneratedMessageDocument, options);
        }
export type GetGeneratedMessageQueryHookResult = ReturnType<typeof useGetGeneratedMessageQuery>;
export type GetGeneratedMessageLazyQueryHookResult = ReturnType<typeof useGetGeneratedMessageLazyQuery>;
export type GetGeneratedMessageQueryResult = Apollo.QueryResult<GetGeneratedMessageQuery, GetGeneratedMessageQueryVariables>;
export const ReadMessageDocument = gql`
    mutation ReadMessage($input: ReadMessageInput!) {
  readMessage(input: $input) {
    activity {
      ...Activity
    }
  }
}
    ${ActivityFragmentDoc}`;
export type ReadMessageMutationFn = Apollo.MutationFunction<ReadMessageMutation, ReadMessageMutationVariables>;

/**
 * __useReadMessageMutation__
 *
 * To run a mutation, you first call `useReadMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readMessageMutation, { data, loading, error }] = useReadMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReadMessageMutation(baseOptions?: Apollo.MutationHookOptions<ReadMessageMutation, ReadMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReadMessageMutation, ReadMessageMutationVariables>(ReadMessageDocument, options);
      }
export type ReadMessageMutationHookResult = ReturnType<typeof useReadMessageMutation>;
export type ReadMessageMutationResult = Apollo.MutationResult<ReadMessageMutation>;
export type ReadMessageMutationOptions = Apollo.BaseMutationOptions<ReadMessageMutation, ReadMessageMutationVariables>;
export const GetPathwayDocument = gql`
    query GetPathway($id: String!) {
  pathway(id: $id) {
    pathway {
      ...Pathway
    }
  }
}
    ${PathwayFragmentDoc}`;

/**
 * __useGetPathwayQuery__
 *
 * To run a query within a React component, call `useGetPathwayQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPathwayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPathwayQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPathwayQuery(baseOptions: Apollo.QueryHookOptions<GetPathwayQuery, GetPathwayQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPathwayQuery, GetPathwayQueryVariables>(GetPathwayDocument, options);
      }
export function useGetPathwayLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPathwayQuery, GetPathwayQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPathwayQuery, GetPathwayQueryVariables>(GetPathwayDocument, options);
        }
export type GetPathwayQueryHookResult = ReturnType<typeof useGetPathwayQuery>;
export type GetPathwayLazyQueryHookResult = ReturnType<typeof useGetPathwayLazyQuery>;
export type GetPathwayQueryResult = Apollo.QueryResult<GetPathwayQuery, GetPathwayQueryVariables>;
export const SetBrandingAccentColorDocument = gql`
    mutation SetBrandingAccentColor($input: SetBrandingAccentColorInput!) {
  setBrandingAccentColor(input: $input) {
    settings {
      ...PathwaySettings
    }
  }
}
    ${PathwaySettingsFragmentDoc}`;
export type SetBrandingAccentColorMutationFn = Apollo.MutationFunction<SetBrandingAccentColorMutation, SetBrandingAccentColorMutationVariables>;

/**
 * __useSetBrandingAccentColorMutation__
 *
 * To run a mutation, you first call `useSetBrandingAccentColorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetBrandingAccentColorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setBrandingAccentColorMutation, { data, loading, error }] = useSetBrandingAccentColorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetBrandingAccentColorMutation(baseOptions?: Apollo.MutationHookOptions<SetBrandingAccentColorMutation, SetBrandingAccentColorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetBrandingAccentColorMutation, SetBrandingAccentColorMutationVariables>(SetBrandingAccentColorDocument, options);
      }
export type SetBrandingAccentColorMutationHookResult = ReturnType<typeof useSetBrandingAccentColorMutation>;
export type SetBrandingAccentColorMutationResult = Apollo.MutationResult<SetBrandingAccentColorMutation>;
export type SetBrandingAccentColorMutationOptions = Apollo.BaseMutationOptions<SetBrandingAccentColorMutation, SetBrandingAccentColorMutationVariables>;
export const SetBrandingCustomThemeDocument = gql`
    mutation SetBrandingCustomTheme($input: SetBrandingCustomThemeInput!) {
  setBrandingCustomTheme(input: $input) {
    settings {
      ...PathwaySettings
    }
  }
}
    ${PathwaySettingsFragmentDoc}`;
export type SetBrandingCustomThemeMutationFn = Apollo.MutationFunction<SetBrandingCustomThemeMutation, SetBrandingCustomThemeMutationVariables>;

/**
 * __useSetBrandingCustomThemeMutation__
 *
 * To run a mutation, you first call `useSetBrandingCustomThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetBrandingCustomThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setBrandingCustomThemeMutation, { data, loading, error }] = useSetBrandingCustomThemeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetBrandingCustomThemeMutation(baseOptions?: Apollo.MutationHookOptions<SetBrandingCustomThemeMutation, SetBrandingCustomThemeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetBrandingCustomThemeMutation, SetBrandingCustomThemeMutationVariables>(SetBrandingCustomThemeDocument, options);
      }
export type SetBrandingCustomThemeMutationHookResult = ReturnType<typeof useSetBrandingCustomThemeMutation>;
export type SetBrandingCustomThemeMutationResult = Apollo.MutationResult<SetBrandingCustomThemeMutation>;
export type SetBrandingCustomThemeMutationOptions = Apollo.BaseMutationOptions<SetBrandingCustomThemeMutation, SetBrandingCustomThemeMutationVariables>;
export const SetBrandingHostedPageAutoProgressDocument = gql`
    mutation SetBrandingHostedPageAutoProgress($input: SetBrandingHostedPageAutoProgressInput!) {
  setBrandingHostedPageAutoProgress(input: $input) {
    settings {
      ...PathwaySettings
    }
  }
}
    ${PathwaySettingsFragmentDoc}`;
export type SetBrandingHostedPageAutoProgressMutationFn = Apollo.MutationFunction<SetBrandingHostedPageAutoProgressMutation, SetBrandingHostedPageAutoProgressMutationVariables>;

/**
 * __useSetBrandingHostedPageAutoProgressMutation__
 *
 * To run a mutation, you first call `useSetBrandingHostedPageAutoProgressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetBrandingHostedPageAutoProgressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setBrandingHostedPageAutoProgressMutation, { data, loading, error }] = useSetBrandingHostedPageAutoProgressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetBrandingHostedPageAutoProgressMutation(baseOptions?: Apollo.MutationHookOptions<SetBrandingHostedPageAutoProgressMutation, SetBrandingHostedPageAutoProgressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetBrandingHostedPageAutoProgressMutation, SetBrandingHostedPageAutoProgressMutationVariables>(SetBrandingHostedPageAutoProgressDocument, options);
      }
export type SetBrandingHostedPageAutoProgressMutationHookResult = ReturnType<typeof useSetBrandingHostedPageAutoProgressMutation>;
export type SetBrandingHostedPageAutoProgressMutationResult = Apollo.MutationResult<SetBrandingHostedPageAutoProgressMutation>;
export type SetBrandingHostedPageAutoProgressMutationOptions = Apollo.BaseMutationOptions<SetBrandingHostedPageAutoProgressMutation, SetBrandingHostedPageAutoProgressMutationVariables>;
export const SetBrandingHostedPageAutosaveDocument = gql`
    mutation SetBrandingHostedPageAutosave($input: SetBrandingHostedPageAutosaveInput!) {
  setBrandingHostedPageAutosave(input: $input) {
    settings {
      ...PathwaySettings
    }
  }
}
    ${PathwaySettingsFragmentDoc}`;
export type SetBrandingHostedPageAutosaveMutationFn = Apollo.MutationFunction<SetBrandingHostedPageAutosaveMutation, SetBrandingHostedPageAutosaveMutationVariables>;

/**
 * __useSetBrandingHostedPageAutosaveMutation__
 *
 * To run a mutation, you first call `useSetBrandingHostedPageAutosaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetBrandingHostedPageAutosaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setBrandingHostedPageAutosaveMutation, { data, loading, error }] = useSetBrandingHostedPageAutosaveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetBrandingHostedPageAutosaveMutation(baseOptions?: Apollo.MutationHookOptions<SetBrandingHostedPageAutosaveMutation, SetBrandingHostedPageAutosaveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetBrandingHostedPageAutosaveMutation, SetBrandingHostedPageAutosaveMutationVariables>(SetBrandingHostedPageAutosaveDocument, options);
      }
export type SetBrandingHostedPageAutosaveMutationHookResult = ReturnType<typeof useSetBrandingHostedPageAutosaveMutation>;
export type SetBrandingHostedPageAutosaveMutationResult = Apollo.MutationResult<SetBrandingHostedPageAutosaveMutation>;
export type SetBrandingHostedPageAutosaveMutationOptions = Apollo.BaseMutationOptions<SetBrandingHostedPageAutosaveMutation, SetBrandingHostedPageAutosaveMutationVariables>;
export const SetBrandingHostedPageTitleDocument = gql`
    mutation SetBrandingHostedPageTitle($input: SetBrandingHostedPageTitleInput!) {
  setBrandingHostedPageTitle(input: $input) {
    settings {
      ...PathwaySettings
    }
  }
}
    ${PathwaySettingsFragmentDoc}`;
export type SetBrandingHostedPageTitleMutationFn = Apollo.MutationFunction<SetBrandingHostedPageTitleMutation, SetBrandingHostedPageTitleMutationVariables>;

/**
 * __useSetBrandingHostedPageTitleMutation__
 *
 * To run a mutation, you first call `useSetBrandingHostedPageTitleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetBrandingHostedPageTitleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setBrandingHostedPageTitleMutation, { data, loading, error }] = useSetBrandingHostedPageTitleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetBrandingHostedPageTitleMutation(baseOptions?: Apollo.MutationHookOptions<SetBrandingHostedPageTitleMutation, SetBrandingHostedPageTitleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetBrandingHostedPageTitleMutation, SetBrandingHostedPageTitleMutationVariables>(SetBrandingHostedPageTitleDocument, options);
      }
export type SetBrandingHostedPageTitleMutationHookResult = ReturnType<typeof useSetBrandingHostedPageTitleMutation>;
export type SetBrandingHostedPageTitleMutationResult = Apollo.MutationResult<SetBrandingHostedPageTitleMutation>;
export type SetBrandingHostedPageTitleMutationOptions = Apollo.BaseMutationOptions<SetBrandingHostedPageTitleMutation, SetBrandingHostedPageTitleMutationVariables>;
export const SetBrandingLogoUrlDocument = gql`
    mutation SetBrandingLogoUrl($input: SetBrandingLogoUrlInput!) {
  setBrandingLogoUrl(input: $input) {
    settings {
      ...PathwaySettings
    }
  }
}
    ${PathwaySettingsFragmentDoc}`;
export type SetBrandingLogoUrlMutationFn = Apollo.MutationFunction<SetBrandingLogoUrlMutation, SetBrandingLogoUrlMutationVariables>;

/**
 * __useSetBrandingLogoUrlMutation__
 *
 * To run a mutation, you first call `useSetBrandingLogoUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetBrandingLogoUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setBrandingLogoUrlMutation, { data, loading, error }] = useSetBrandingLogoUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetBrandingLogoUrlMutation(baseOptions?: Apollo.MutationHookOptions<SetBrandingLogoUrlMutation, SetBrandingLogoUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetBrandingLogoUrlMutation, SetBrandingLogoUrlMutationVariables>(SetBrandingLogoUrlDocument, options);
      }
export type SetBrandingLogoUrlMutationHookResult = ReturnType<typeof useSetBrandingLogoUrlMutation>;
export type SetBrandingLogoUrlMutationResult = Apollo.MutationResult<SetBrandingLogoUrlMutation>;
export type SetBrandingLogoUrlMutationOptions = Apollo.BaseMutationOptions<SetBrandingLogoUrlMutation, SetBrandingLogoUrlMutationVariables>;
export const PathwayCaseDocument = gql`
    query PathwayCase($pathwayCaseId: String!) {
  pathway_case(id: $pathwayCaseId) {
    pathway_case {
      id
      title
      status
    }
  }
}
    `;

/**
 * __usePathwayCaseQuery__
 *
 * To run a query within a React component, call `usePathwayCaseQuery` and pass it any options that fit your needs.
 * When your component renders, `usePathwayCaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePathwayCaseQuery({
 *   variables: {
 *      pathwayCaseId: // value for 'pathwayCaseId'
 *   },
 * });
 */
export function usePathwayCaseQuery(baseOptions: Apollo.QueryHookOptions<PathwayCaseQuery, PathwayCaseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PathwayCaseQuery, PathwayCaseQueryVariables>(PathwayCaseDocument, options);
      }
export function usePathwayCaseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PathwayCaseQuery, PathwayCaseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PathwayCaseQuery, PathwayCaseQueryVariables>(PathwayCaseDocument, options);
        }
export type PathwayCaseQueryHookResult = ReturnType<typeof usePathwayCaseQuery>;
export type PathwayCaseLazyQueryHookResult = ReturnType<typeof usePathwayCaseLazyQuery>;
export type PathwayCaseQueryResult = Apollo.QueryResult<PathwayCaseQuery, PathwayCaseQueryVariables>;
export const SetPathwayCaseTitleDocument = gql`
    mutation SetPathwayCaseTitle($input: SetPathwayCaseTitleInput!) {
  setPathwayCaseTitle(input: $input) {
    success
    pathway_case {
      id
      title
    }
  }
}
    `;
export type SetPathwayCaseTitleMutationFn = Apollo.MutationFunction<SetPathwayCaseTitleMutation, SetPathwayCaseTitleMutationVariables>;

/**
 * __useSetPathwayCaseTitleMutation__
 *
 * To run a mutation, you first call `useSetPathwayCaseTitleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPathwayCaseTitleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPathwayCaseTitleMutation, { data, loading, error }] = useSetPathwayCaseTitleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetPathwayCaseTitleMutation(baseOptions?: Apollo.MutationHookOptions<SetPathwayCaseTitleMutation, SetPathwayCaseTitleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetPathwayCaseTitleMutation, SetPathwayCaseTitleMutationVariables>(SetPathwayCaseTitleDocument, options);
      }
export type SetPathwayCaseTitleMutationHookResult = ReturnType<typeof useSetPathwayCaseTitleMutation>;
export type SetPathwayCaseTitleMutationResult = Apollo.MutationResult<SetPathwayCaseTitleMutation>;
export type SetPathwayCaseTitleMutationOptions = Apollo.BaseMutationOptions<SetPathwayCaseTitleMutation, SetPathwayCaseTitleMutationVariables>;
export const OnActivitiesDeletedDocument = gql`
    subscription OnActivitiesDeleted($pathway_case_id: String!) {
  activitiesDeleted(pathway_case_id: $pathway_case_id) {
    activity_ids
  }
}
    `;

/**
 * __useOnActivitiesDeletedSubscription__
 *
 * To run a query within a React component, call `useOnActivitiesDeletedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnActivitiesDeletedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnActivitiesDeletedSubscription({
 *   variables: {
 *      pathway_case_id: // value for 'pathway_case_id'
 *   },
 * });
 */
export function useOnActivitiesDeletedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnActivitiesDeletedSubscription, OnActivitiesDeletedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnActivitiesDeletedSubscription, OnActivitiesDeletedSubscriptionVariables>(OnActivitiesDeletedDocument, options);
      }
export type OnActivitiesDeletedSubscriptionHookResult = ReturnType<typeof useOnActivitiesDeletedSubscription>;
export type OnActivitiesDeletedSubscriptionResult = Apollo.SubscriptionResult<OnActivitiesDeletedSubscription>;
export const OnActivityCompletedDocument = gql`
    subscription OnActivityCompleted($pathway_case_id: String!) {
  activityCompleted(pathway_case_id: $pathway_case_id) {
    ...Activity
  }
}
    ${ActivityFragmentDoc}`;

/**
 * __useOnActivityCompletedSubscription__
 *
 * To run a query within a React component, call `useOnActivityCompletedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnActivityCompletedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnActivityCompletedSubscription({
 *   variables: {
 *      pathway_case_id: // value for 'pathway_case_id'
 *   },
 * });
 */
export function useOnActivityCompletedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnActivityCompletedSubscription, OnActivityCompletedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnActivityCompletedSubscription, OnActivityCompletedSubscriptionVariables>(OnActivityCompletedDocument, options);
      }
export type OnActivityCompletedSubscriptionHookResult = ReturnType<typeof useOnActivityCompletedSubscription>;
export type OnActivityCompletedSubscriptionResult = Apollo.SubscriptionResult<OnActivityCompletedSubscription>;
export const OnActivityCreatedDocument = gql`
    subscription OnActivityCreated($pathway_case_id: String!) {
  activityCreated(pathway_case_id: $pathway_case_id) {
    ...Activity
  }
}
    ${ActivityFragmentDoc}`;

/**
 * __useOnActivityCreatedSubscription__
 *
 * To run a query within a React component, call `useOnActivityCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnActivityCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnActivityCreatedSubscription({
 *   variables: {
 *      pathway_case_id: // value for 'pathway_case_id'
 *   },
 * });
 */
export function useOnActivityCreatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnActivityCreatedSubscription, OnActivityCreatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnActivityCreatedSubscription, OnActivityCreatedSubscriptionVariables>(OnActivityCreatedDocument, options);
      }
export type OnActivityCreatedSubscriptionHookResult = ReturnType<typeof useOnActivityCreatedSubscription>;
export type OnActivityCreatedSubscriptionResult = Apollo.SubscriptionResult<OnActivityCreatedSubscription>;
export const OnActivityUpdatedDocument = gql`
    subscription OnActivityUpdated($pathway_case_id: String!) {
  activityUpdated(pathway_case_id: $pathway_case_id) {
    ...Activity
  }
}
    ${ActivityFragmentDoc}`;

/**
 * __useOnActivityUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnActivityUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnActivityUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnActivityUpdatedSubscription({
 *   variables: {
 *      pathway_case_id: // value for 'pathway_case_id'
 *   },
 * });
 */
export function useOnActivityUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnActivityUpdatedSubscription, OnActivityUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnActivityUpdatedSubscription, OnActivityUpdatedSubscriptionVariables>(OnActivityUpdatedDocument, options);
      }
export type OnActivityUpdatedSubscriptionHookResult = ReturnType<typeof useOnActivityUpdatedSubscription>;
export type OnActivityUpdatedSubscriptionResult = Apollo.SubscriptionResult<OnActivityUpdatedSubscription>;
export const PathwayCaseActivitiesDocument = gql`
    query PathwayCaseActivities($pathway_case_id: String!) {
  pathwayCaseActivities(pathway_case_id: $pathway_case_id) {
    success
    activities {
      ...Activity
    }
  }
}
    ${ActivityFragmentDoc}`;

/**
 * __usePathwayCaseActivitiesQuery__
 *
 * To run a query within a React component, call `usePathwayCaseActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePathwayCaseActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePathwayCaseActivitiesQuery({
 *   variables: {
 *      pathway_case_id: // value for 'pathway_case_id'
 *   },
 * });
 */
export function usePathwayCaseActivitiesQuery(baseOptions: Apollo.QueryHookOptions<PathwayCaseActivitiesQuery, PathwayCaseActivitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PathwayCaseActivitiesQuery, PathwayCaseActivitiesQueryVariables>(PathwayCaseActivitiesDocument, options);
      }
export function usePathwayCaseActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PathwayCaseActivitiesQuery, PathwayCaseActivitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PathwayCaseActivitiesQuery, PathwayCaseActivitiesQueryVariables>(PathwayCaseActivitiesDocument, options);
        }
export type PathwayCaseActivitiesQueryHookResult = ReturnType<typeof usePathwayCaseActivitiesQuery>;
export type PathwayCaseActivitiesLazyQueryHookResult = ReturnType<typeof usePathwayCaseActivitiesLazyQuery>;
export type PathwayCaseActivitiesQueryResult = Apollo.QueryResult<PathwayCaseActivitiesQuery, PathwayCaseActivitiesQueryVariables>;
export const PathwayCaseApiCallDocument = gql`
    query PathwayCaseApiCall($id: String!) {
  pathwayCaseApiCall(id: $id) {
    api_call {
      id
      request {
        endpoint
        headers {
          key
          value
        }
        body
        method
      }
      responses {
        status
        body
        date
      }
      title
    }
  }
}
    `;

/**
 * __usePathwayCaseApiCallQuery__
 *
 * To run a query within a React component, call `usePathwayCaseApiCallQuery` and pass it any options that fit your needs.
 * When your component renders, `usePathwayCaseApiCallQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePathwayCaseApiCallQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePathwayCaseApiCallQuery(baseOptions: Apollo.QueryHookOptions<PathwayCaseApiCallQuery, PathwayCaseApiCallQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PathwayCaseApiCallQuery, PathwayCaseApiCallQueryVariables>(PathwayCaseApiCallDocument, options);
      }
export function usePathwayCaseApiCallLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PathwayCaseApiCallQuery, PathwayCaseApiCallQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PathwayCaseApiCallQuery, PathwayCaseApiCallQueryVariables>(PathwayCaseApiCallDocument, options);
        }
export type PathwayCaseApiCallQueryHookResult = ReturnType<typeof usePathwayCaseApiCallQuery>;
export type PathwayCaseApiCallLazyQueryHookResult = ReturnType<typeof usePathwayCaseApiCallLazyQuery>;
export type PathwayCaseApiCallQueryResult = Apollo.QueryResult<PathwayCaseApiCallQuery, PathwayCaseApiCallQueryVariables>;
export const TriggerPathwayCaseApiCallDocument = gql`
    mutation TriggerPathwayCaseApiCall($input: TriggerApiCallInput!) {
  triggerApiCall(input: $input) {
    api_call {
      id
      request {
        endpoint
        headers {
          key
          value
        }
        body
      }
      responses {
        status
        body
        date
      }
    }
  }
}
    `;
export type TriggerPathwayCaseApiCallMutationFn = Apollo.MutationFunction<TriggerPathwayCaseApiCallMutation, TriggerPathwayCaseApiCallMutationVariables>;

/**
 * __useTriggerPathwayCaseApiCallMutation__
 *
 * To run a mutation, you first call `useTriggerPathwayCaseApiCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerPathwayCaseApiCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerPathwayCaseApiCallMutation, { data, loading, error }] = useTriggerPathwayCaseApiCallMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTriggerPathwayCaseApiCallMutation(baseOptions?: Apollo.MutationHookOptions<TriggerPathwayCaseApiCallMutation, TriggerPathwayCaseApiCallMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TriggerPathwayCaseApiCallMutation, TriggerPathwayCaseApiCallMutationVariables>(TriggerPathwayCaseApiCallDocument, options);
      }
export type TriggerPathwayCaseApiCallMutationHookResult = ReturnType<typeof useTriggerPathwayCaseApiCallMutation>;
export type TriggerPathwayCaseApiCallMutationResult = Apollo.MutationResult<TriggerPathwayCaseApiCallMutation>;
export type TriggerPathwayCaseApiCallMutationOptions = Apollo.BaseMutationOptions<TriggerPathwayCaseApiCallMutation, TriggerPathwayCaseApiCallMutationVariables>;
export const OnElementCompletedDocument = gql`
    subscription OnElementCompleted($pathway_case_id: String!) {
  elementCompleted(pathway_case_id: $pathway_case_id) {
    ...Element
  }
}
    ${ElementFragmentDoc}`;

/**
 * __useOnElementCompletedSubscription__
 *
 * To run a query within a React component, call `useOnElementCompletedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnElementCompletedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnElementCompletedSubscription({
 *   variables: {
 *      pathway_case_id: // value for 'pathway_case_id'
 *   },
 * });
 */
export function useOnElementCompletedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnElementCompletedSubscription, OnElementCompletedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnElementCompletedSubscription, OnElementCompletedSubscriptionVariables>(OnElementCompletedDocument, options);
      }
export type OnElementCompletedSubscriptionHookResult = ReturnType<typeof useOnElementCompletedSubscription>;
export type OnElementCompletedSubscriptionResult = Apollo.SubscriptionResult<OnElementCompletedSubscription>;
export const OnElementCreatedDocument = gql`
    subscription OnElementCreated($pathway_case_id: String!) {
  elementCreated(pathway_case_id: $pathway_case_id) {
    ...Element
  }
}
    ${ElementFragmentDoc}`;

/**
 * __useOnElementCreatedSubscription__
 *
 * To run a query within a React component, call `useOnElementCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnElementCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnElementCreatedSubscription({
 *   variables: {
 *      pathway_case_id: // value for 'pathway_case_id'
 *   },
 * });
 */
export function useOnElementCreatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnElementCreatedSubscription, OnElementCreatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnElementCreatedSubscription, OnElementCreatedSubscriptionVariables>(OnElementCreatedDocument, options);
      }
export type OnElementCreatedSubscriptionHookResult = ReturnType<typeof useOnElementCreatedSubscription>;
export type OnElementCreatedSubscriptionResult = Apollo.SubscriptionResult<OnElementCreatedSubscription>;
export const OnElementDeletedDocument = gql`
    subscription OnElementDeleted($pathway_case_id: String!) {
  elementDeleted(pathway_case_id: $pathway_case_id) {
    ...Element
  }
}
    ${ElementFragmentDoc}`;

/**
 * __useOnElementDeletedSubscription__
 *
 * To run a query within a React component, call `useOnElementDeletedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnElementDeletedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnElementDeletedSubscription({
 *   variables: {
 *      pathway_case_id: // value for 'pathway_case_id'
 *   },
 * });
 */
export function useOnElementDeletedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnElementDeletedSubscription, OnElementDeletedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnElementDeletedSubscription, OnElementDeletedSubscriptionVariables>(OnElementDeletedDocument, options);
      }
export type OnElementDeletedSubscriptionHookResult = ReturnType<typeof useOnElementDeletedSubscription>;
export type OnElementDeletedSubscriptionResult = Apollo.SubscriptionResult<OnElementDeletedSubscription>;
export const OnElementUpdatedDocument = gql`
    subscription OnElementUpdated($pathway_case_id: String!) {
  elementUpdated(pathway_case_id: $pathway_case_id) {
    ...Element
  }
}
    ${ElementFragmentDoc}`;

/**
 * __useOnElementUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnElementUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnElementUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnElementUpdatedSubscription({
 *   variables: {
 *      pathway_case_id: // value for 'pathway_case_id'
 *   },
 * });
 */
export function useOnElementUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnElementUpdatedSubscription, OnElementUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnElementUpdatedSubscription, OnElementUpdatedSubscriptionVariables>(OnElementUpdatedDocument, options);
      }
export type OnElementUpdatedSubscriptionHookResult = ReturnType<typeof useOnElementUpdatedSubscription>;
export type OnElementUpdatedSubscriptionResult = Apollo.SubscriptionResult<OnElementUpdatedSubscription>;
export const PathwayCaseElementsDocument = gql`
    query PathwayCaseElements($pathway_case_id: String!) {
  pathwayCaseElements(pathway_case_id: $pathway_case_id) {
    elements {
      ...Element
    }
  }
}
    ${ElementFragmentDoc}`;

/**
 * __usePathwayCaseElementsQuery__
 *
 * To run a query within a React component, call `usePathwayCaseElementsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePathwayCaseElementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePathwayCaseElementsQuery({
 *   variables: {
 *      pathway_case_id: // value for 'pathway_case_id'
 *   },
 * });
 */
export function usePathwayCaseElementsQuery(baseOptions: Apollo.QueryHookOptions<PathwayCaseElementsQuery, PathwayCaseElementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PathwayCaseElementsQuery, PathwayCaseElementsQueryVariables>(PathwayCaseElementsDocument, options);
      }
export function usePathwayCaseElementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PathwayCaseElementsQuery, PathwayCaseElementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PathwayCaseElementsQuery, PathwayCaseElementsQueryVariables>(PathwayCaseElementsDocument, options);
        }
export type PathwayCaseElementsQueryHookResult = ReturnType<typeof usePathwayCaseElementsQuery>;
export type PathwayCaseElementsLazyQueryHookResult = ReturnType<typeof usePathwayCaseElementsLazyQuery>;
export type PathwayCaseElementsQueryResult = Apollo.QueryResult<PathwayCaseElementsQuery, PathwayCaseElementsQueryVariables>;
export const PathwayCaseStepActivitiesDocument = gql`
    query PathwayCaseStepActivities($input: PathwayCaseStepActivitiesInput!) {
  pathwayCaseStepActivities(input: $input) {
    success
    activities {
      ...Activity
    }
  }
}
    ${ActivityFragmentDoc}`;

/**
 * __usePathwayCaseStepActivitiesQuery__
 *
 * To run a query within a React component, call `usePathwayCaseStepActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePathwayCaseStepActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePathwayCaseStepActivitiesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePathwayCaseStepActivitiesQuery(baseOptions: Apollo.QueryHookOptions<PathwayCaseStepActivitiesQuery, PathwayCaseStepActivitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PathwayCaseStepActivitiesQuery, PathwayCaseStepActivitiesQueryVariables>(PathwayCaseStepActivitiesDocument, options);
      }
export function usePathwayCaseStepActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PathwayCaseStepActivitiesQuery, PathwayCaseStepActivitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PathwayCaseStepActivitiesQuery, PathwayCaseStepActivitiesQueryVariables>(PathwayCaseStepActivitiesDocument, options);
        }
export type PathwayCaseStepActivitiesQueryHookResult = ReturnType<typeof usePathwayCaseStepActivitiesQuery>;
export type PathwayCaseStepActivitiesLazyQueryHookResult = ReturnType<typeof usePathwayCaseStepActivitiesLazyQuery>;
export type PathwayCaseStepActivitiesQueryResult = Apollo.QueryResult<PathwayCaseStepActivitiesQuery, PathwayCaseStepActivitiesQueryVariables>;
export const OnTriggerActivationCreatedDocument = gql`
    subscription OnTriggerActivationCreated($pathway_case_id: String!) {
  triggerActivationCreated(pathway_case_id: $pathway_case_id) {
    status
    track {
      id
      title
    }
    type
    trigger {
      ...Trigger
    }
    id
  }
}
    ${TriggerFragmentDoc}`;

/**
 * __useOnTriggerActivationCreatedSubscription__
 *
 * To run a query within a React component, call `useOnTriggerActivationCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnTriggerActivationCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnTriggerActivationCreatedSubscription({
 *   variables: {
 *      pathway_case_id: // value for 'pathway_case_id'
 *   },
 * });
 */
export function useOnTriggerActivationCreatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnTriggerActivationCreatedSubscription, OnTriggerActivationCreatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnTriggerActivationCreatedSubscription, OnTriggerActivationCreatedSubscriptionVariables>(OnTriggerActivationCreatedDocument, options);
      }
export type OnTriggerActivationCreatedSubscriptionHookResult = ReturnType<typeof useOnTriggerActivationCreatedSubscription>;
export type OnTriggerActivationCreatedSubscriptionResult = Apollo.SubscriptionResult<OnTriggerActivationCreatedSubscription>;
export const OnTriggerActivationUpdatedDocument = gql`
    subscription OnTriggerActivationUpdated($pathway_case_id: String!) {
  triggerActivationUpdated(pathway_case_id: $pathway_case_id) {
    status
    track {
      id
      title
    }
    type
    trigger {
      ...Trigger
    }
    id
  }
}
    ${TriggerFragmentDoc}`;

/**
 * __useOnTriggerActivationUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnTriggerActivationUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnTriggerActivationUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnTriggerActivationUpdatedSubscription({
 *   variables: {
 *      pathway_case_id: // value for 'pathway_case_id'
 *   },
 * });
 */
export function useOnTriggerActivationUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnTriggerActivationUpdatedSubscription, OnTriggerActivationUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnTriggerActivationUpdatedSubscription, OnTriggerActivationUpdatedSubscriptionVariables>(OnTriggerActivationUpdatedDocument, options);
      }
export type OnTriggerActivationUpdatedSubscriptionHookResult = ReturnType<typeof useOnTriggerActivationUpdatedSubscription>;
export type OnTriggerActivationUpdatedSubscriptionResult = Apollo.SubscriptionResult<OnTriggerActivationUpdatedSubscription>;
export const PathwayCaseTriggerActivationsDocument = gql`
    query PathwayCaseTriggerActivations($pathway_case_id: String!) {
  pathwayCaseTriggerActivations(pathway_case_id: $pathway_case_id) {
    success
    trigger_activations {
      id
      trigger {
        ...Trigger
      }
      track {
        id
        title
      }
      status
      type
    }
  }
}
    ${TriggerFragmentDoc}`;

/**
 * __usePathwayCaseTriggerActivationsQuery__
 *
 * To run a query within a React component, call `usePathwayCaseTriggerActivationsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePathwayCaseTriggerActivationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePathwayCaseTriggerActivationsQuery({
 *   variables: {
 *      pathway_case_id: // value for 'pathway_case_id'
 *   },
 * });
 */
export function usePathwayCaseTriggerActivationsQuery(baseOptions: Apollo.QueryHookOptions<PathwayCaseTriggerActivationsQuery, PathwayCaseTriggerActivationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PathwayCaseTriggerActivationsQuery, PathwayCaseTriggerActivationsQueryVariables>(PathwayCaseTriggerActivationsDocument, options);
      }
export function usePathwayCaseTriggerActivationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PathwayCaseTriggerActivationsQuery, PathwayCaseTriggerActivationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PathwayCaseTriggerActivationsQuery, PathwayCaseTriggerActivationsQueryVariables>(PathwayCaseTriggerActivationsDocument, options);
        }
export type PathwayCaseTriggerActivationsQueryHookResult = ReturnType<typeof usePathwayCaseTriggerActivationsQuery>;
export type PathwayCaseTriggerActivationsLazyQueryHookResult = ReturnType<typeof usePathwayCaseTriggerActivationsLazyQuery>;
export type PathwayCaseTriggerActivationsQueryResult = Apollo.QueryResult<PathwayCaseTriggerActivationsQuery, PathwayCaseTriggerActivationsQueryVariables>;
export const CreatePathwayCaseDocument = gql`
    mutation CreatePathwayCase($input: CreatePathwayCaseInput!) {
  createPathwayCase(input: $input) {
    success
    pathway_case {
      ...PathwayCaseListItem
    }
  }
}
    ${PathwayCaseListItemFragmentDoc}`;
export type CreatePathwayCaseMutationFn = Apollo.MutationFunction<CreatePathwayCaseMutation, CreatePathwayCaseMutationVariables>;

/**
 * __useCreatePathwayCaseMutation__
 *
 * To run a mutation, you first call `useCreatePathwayCaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePathwayCaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPathwayCaseMutation, { data, loading, error }] = useCreatePathwayCaseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePathwayCaseMutation(baseOptions?: Apollo.MutationHookOptions<CreatePathwayCaseMutation, CreatePathwayCaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePathwayCaseMutation, CreatePathwayCaseMutationVariables>(CreatePathwayCaseDocument, options);
      }
export type CreatePathwayCaseMutationHookResult = ReturnType<typeof useCreatePathwayCaseMutation>;
export type CreatePathwayCaseMutationResult = Apollo.MutationResult<CreatePathwayCaseMutation>;
export type CreatePathwayCaseMutationOptions = Apollo.BaseMutationOptions<CreatePathwayCaseMutation, CreatePathwayCaseMutationVariables>;
export const DeletePathwayCaseDocument = gql`
    mutation DeletePathwayCase($input: DeletePathwayCaseInput!) {
  deletePathwayCase(input: $input) {
    success
  }
}
    `;
export type DeletePathwayCaseMutationFn = Apollo.MutationFunction<DeletePathwayCaseMutation, DeletePathwayCaseMutationVariables>;

/**
 * __useDeletePathwayCaseMutation__
 *
 * To run a mutation, you first call `useDeletePathwayCaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePathwayCaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePathwayCaseMutation, { data, loading, error }] = useDeletePathwayCaseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePathwayCaseMutation(baseOptions?: Apollo.MutationHookOptions<DeletePathwayCaseMutation, DeletePathwayCaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePathwayCaseMutation, DeletePathwayCaseMutationVariables>(DeletePathwayCaseDocument, options);
      }
export type DeletePathwayCaseMutationHookResult = ReturnType<typeof useDeletePathwayCaseMutation>;
export type DeletePathwayCaseMutationResult = Apollo.MutationResult<DeletePathwayCaseMutation>;
export type DeletePathwayCaseMutationOptions = Apollo.BaseMutationOptions<DeletePathwayCaseMutation, DeletePathwayCaseMutationVariables>;
export const GetPathwayCasesDocument = gql`
    query GetPathwayCases($input: PathwayCasesInput!) {
  pathway_cases(input: $input) {
    pathway_cases {
      ...PathwayCaseListItem
    }
  }
}
    ${PathwayCaseListItemFragmentDoc}`;

/**
 * __useGetPathwayCasesQuery__
 *
 * To run a query within a React component, call `useGetPathwayCasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPathwayCasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPathwayCasesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPathwayCasesQuery(baseOptions: Apollo.QueryHookOptions<GetPathwayCasesQuery, GetPathwayCasesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPathwayCasesQuery, GetPathwayCasesQueryVariables>(GetPathwayCasesDocument, options);
      }
export function useGetPathwayCasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPathwayCasesQuery, GetPathwayCasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPathwayCasesQuery, GetPathwayCasesQueryVariables>(GetPathwayCasesDocument, options);
        }
export type GetPathwayCasesQueryHookResult = ReturnType<typeof useGetPathwayCasesQuery>;
export type GetPathwayCasesLazyQueryHookResult = ReturnType<typeof useGetPathwayCasesLazyQuery>;
export type GetPathwayCasesQueryResult = Apollo.QueryResult<GetPathwayCasesQuery, GetPathwayCasesQueryVariables>;
export const PathwayCollaboratorDocument = gql`
    query PathwayCollaborator($id: String) {
  user(id: $id) {
    user {
      id
      email
      name
    }
  }
}
    `;

/**
 * __usePathwayCollaboratorQuery__
 *
 * To run a query within a React component, call `usePathwayCollaboratorQuery` and pass it any options that fit your needs.
 * When your component renders, `usePathwayCollaboratorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePathwayCollaboratorQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePathwayCollaboratorQuery(baseOptions?: Apollo.QueryHookOptions<PathwayCollaboratorQuery, PathwayCollaboratorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PathwayCollaboratorQuery, PathwayCollaboratorQueryVariables>(PathwayCollaboratorDocument, options);
      }
export function usePathwayCollaboratorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PathwayCollaboratorQuery, PathwayCollaboratorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PathwayCollaboratorQuery, PathwayCollaboratorQueryVariables>(PathwayCollaboratorDocument, options);
        }
export type PathwayCollaboratorQueryHookResult = ReturnType<typeof usePathwayCollaboratorQuery>;
export type PathwayCollaboratorLazyQueryHookResult = ReturnType<typeof usePathwayCollaboratorLazyQuery>;
export type PathwayCollaboratorQueryResult = Apollo.QueryResult<PathwayCollaboratorQuery, PathwayCollaboratorQueryVariables>;
export const UpdatePathwayDashboardIdsDocument = gql`
    mutation UpdatePathwayDashboardIds($input: SetDashboardIdsInput!) {
  setDashboardIds(input: $input) {
    settings {
      ...PathwaySettings
    }
  }
}
    ${PathwaySettingsFragmentDoc}`;
export type UpdatePathwayDashboardIdsMutationFn = Apollo.MutationFunction<UpdatePathwayDashboardIdsMutation, UpdatePathwayDashboardIdsMutationVariables>;

/**
 * __useUpdatePathwayDashboardIdsMutation__
 *
 * To run a mutation, you first call `useUpdatePathwayDashboardIdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePathwayDashboardIdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePathwayDashboardIdsMutation, { data, loading, error }] = useUpdatePathwayDashboardIdsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePathwayDashboardIdsMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePathwayDashboardIdsMutation, UpdatePathwayDashboardIdsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePathwayDashboardIdsMutation, UpdatePathwayDashboardIdsMutationVariables>(UpdatePathwayDashboardIdsDocument, options);
      }
export type UpdatePathwayDashboardIdsMutationHookResult = ReturnType<typeof useUpdatePathwayDashboardIdsMutation>;
export type UpdatePathwayDashboardIdsMutationResult = Apollo.MutationResult<UpdatePathwayDashboardIdsMutation>;
export type UpdatePathwayDashboardIdsMutationOptions = Apollo.BaseMutationOptions<UpdatePathwayDashboardIdsMutation, UpdatePathwayDashboardIdsMutationVariables>;
export const PathwayDataPointDocument = gql`
    query PathwayDataPoint($input: DataPointDefinitionsInput!) {
  data_point_definitions(input: $input) {
    data_point_definitions {
      ...DataPointDefinition
    }
  }
}
    ${DataPointDefinitionFragmentDoc}`;

/**
 * __usePathwayDataPointQuery__
 *
 * To run a query within a React component, call `usePathwayDataPointQuery` and pass it any options that fit your needs.
 * When your component renders, `usePathwayDataPointQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePathwayDataPointQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePathwayDataPointQuery(baseOptions: Apollo.QueryHookOptions<PathwayDataPointQuery, PathwayDataPointQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PathwayDataPointQuery, PathwayDataPointQueryVariables>(PathwayDataPointDocument, options);
      }
export function usePathwayDataPointLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PathwayDataPointQuery, PathwayDataPointQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PathwayDataPointQuery, PathwayDataPointQueryVariables>(PathwayDataPointDocument, options);
        }
export type PathwayDataPointQueryHookResult = ReturnType<typeof usePathwayDataPointQuery>;
export type PathwayDataPointLazyQueryHookResult = ReturnType<typeof usePathwayDataPointLazyQuery>;
export type PathwayDataPointQueryResult = Apollo.QueryResult<PathwayDataPointQuery, PathwayDataPointQueryVariables>;
export const AddTriggerToPathwayDocument = gql`
    mutation AddTriggerToPathway($input: AddTriggerToPathwayInput!) {
  addTriggerToPathway(input: $input) {
    success
    trigger {
      ...Trigger
    }
  }
}
    ${TriggerFragmentDoc}`;
export type AddTriggerToPathwayMutationFn = Apollo.MutationFunction<AddTriggerToPathwayMutation, AddTriggerToPathwayMutationVariables>;

/**
 * __useAddTriggerToPathwayMutation__
 *
 * To run a mutation, you first call `useAddTriggerToPathwayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTriggerToPathwayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTriggerToPathwayMutation, { data, loading, error }] = useAddTriggerToPathwayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTriggerToPathwayMutation(baseOptions?: Apollo.MutationHookOptions<AddTriggerToPathwayMutation, AddTriggerToPathwayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTriggerToPathwayMutation, AddTriggerToPathwayMutationVariables>(AddTriggerToPathwayDocument, options);
      }
export type AddTriggerToPathwayMutationHookResult = ReturnType<typeof useAddTriggerToPathwayMutation>;
export type AddTriggerToPathwayMutationResult = Apollo.MutationResult<AddTriggerToPathwayMutation>;
export type AddTriggerToPathwayMutationOptions = Apollo.BaseMutationOptions<AddTriggerToPathwayMutation, AddTriggerToPathwayMutationVariables>;
export const GetPathwayExtensionsDocument = gql`
    query GetPathwayExtensions($pathwayId: String!) {
  pathwayExtensions(pathway_id: $pathwayId) {
    extensions {
      key
      title
      description
      icon_url
      actions {
        key
        title
        icon
        category
      }
      webhooks(pathway_id: $pathwayId) {
        id
        endpoint_url
        webhook_key
        data_point_definitions {
          ...DataPointDefinition
        }
      }
    }
  }
  extensions {
    extensions {
      key
      title
      icon_url
      category
      settings {
        description
        fields {
          key
          label
          obfuscated
          required
          description
        }
      }
      actions {
        key
        title
        icon
        category
      }
      available_webhooks {
        key
        description
      }
    }
  }
  pathway(id: $pathwayId) {
    pathway {
      triggers {
        ...Trigger
      }
    }
  }
}
    ${DataPointDefinitionFragmentDoc}
${TriggerFragmentDoc}`;

/**
 * __useGetPathwayExtensionsQuery__
 *
 * To run a query within a React component, call `useGetPathwayExtensionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPathwayExtensionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPathwayExtensionsQuery({
 *   variables: {
 *      pathwayId: // value for 'pathwayId'
 *   },
 * });
 */
export function useGetPathwayExtensionsQuery(baseOptions: Apollo.QueryHookOptions<GetPathwayExtensionsQuery, GetPathwayExtensionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPathwayExtensionsQuery, GetPathwayExtensionsQueryVariables>(GetPathwayExtensionsDocument, options);
      }
export function useGetPathwayExtensionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPathwayExtensionsQuery, GetPathwayExtensionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPathwayExtensionsQuery, GetPathwayExtensionsQueryVariables>(GetPathwayExtensionsDocument, options);
        }
export type GetPathwayExtensionsQueryHookResult = ReturnType<typeof useGetPathwayExtensionsQuery>;
export type GetPathwayExtensionsLazyQueryHookResult = ReturnType<typeof useGetPathwayExtensionsLazyQuery>;
export type GetPathwayExtensionsQueryResult = Apollo.QueryResult<GetPathwayExtensionsQuery, GetPathwayExtensionsQueryVariables>;
export const RemoveTriggerFromPathwayDocument = gql`
    mutation RemoveTriggerFromPathway($input: RemoveTriggerFromPathwayInput!) {
  removeTriggerFromPathway(input: $input) {
    success
  }
}
    `;
export type RemoveTriggerFromPathwayMutationFn = Apollo.MutationFunction<RemoveTriggerFromPathwayMutation, RemoveTriggerFromPathwayMutationVariables>;

/**
 * __useRemoveTriggerFromPathwayMutation__
 *
 * To run a mutation, you first call `useRemoveTriggerFromPathwayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTriggerFromPathwayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTriggerFromPathwayMutation, { data, loading, error }] = useRemoveTriggerFromPathwayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveTriggerFromPathwayMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTriggerFromPathwayMutation, RemoveTriggerFromPathwayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveTriggerFromPathwayMutation, RemoveTriggerFromPathwayMutationVariables>(RemoveTriggerFromPathwayDocument, options);
      }
export type RemoveTriggerFromPathwayMutationHookResult = ReturnType<typeof useRemoveTriggerFromPathwayMutation>;
export type RemoveTriggerFromPathwayMutationResult = Apollo.MutationResult<RemoveTriggerFromPathwayMutation>;
export type RemoveTriggerFromPathwayMutationOptions = Apollo.BaseMutationOptions<RemoveTriggerFromPathwayMutation, RemoveTriggerFromPathwayMutationVariables>;
export const SetPathwayTitleDocument = gql`
    mutation SetPathwayTitle($input: SetPathwayTitleInput!) {
  setPathwayTitle(input: $input) {
    pathway {
      id
      title
    }
  }
}
    `;
export type SetPathwayTitleMutationFn = Apollo.MutationFunction<SetPathwayTitleMutation, SetPathwayTitleMutationVariables>;

/**
 * __useSetPathwayTitleMutation__
 *
 * To run a mutation, you first call `useSetPathwayTitleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPathwayTitleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPathwayTitleMutation, { data, loading, error }] = useSetPathwayTitleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetPathwayTitleMutation(baseOptions?: Apollo.MutationHookOptions<SetPathwayTitleMutation, SetPathwayTitleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetPathwayTitleMutation, SetPathwayTitleMutationVariables>(SetPathwayTitleDocument, options);
      }
export type SetPathwayTitleMutationHookResult = ReturnType<typeof useSetPathwayTitleMutation>;
export type SetPathwayTitleMutationResult = Apollo.MutationResult<SetPathwayTitleMutation>;
export type SetPathwayTitleMutationOptions = Apollo.BaseMutationOptions<SetPathwayTitleMutation, SetPathwayTitleMutationVariables>;
export const GetPathwayNavigationInfoDocument = gql`
    query GetPathwayNavigationInfo($id: String!) {
  pathway(id: $id) {
    pathway {
      id
      title
      readonly
    }
  }
}
    `;

/**
 * __useGetPathwayNavigationInfoQuery__
 *
 * To run a query within a React component, call `useGetPathwayNavigationInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPathwayNavigationInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPathwayNavigationInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPathwayNavigationInfoQuery(baseOptions: Apollo.QueryHookOptions<GetPathwayNavigationInfoQuery, GetPathwayNavigationInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPathwayNavigationInfoQuery, GetPathwayNavigationInfoQueryVariables>(GetPathwayNavigationInfoDocument, options);
      }
export function useGetPathwayNavigationInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPathwayNavigationInfoQuery, GetPathwayNavigationInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPathwayNavigationInfoQuery, GetPathwayNavigationInfoQueryVariables>(GetPathwayNavigationInfoDocument, options);
        }
export type GetPathwayNavigationInfoQueryHookResult = ReturnType<typeof useGetPathwayNavigationInfoQuery>;
export type GetPathwayNavigationInfoLazyQueryHookResult = ReturnType<typeof useGetPathwayNavigationInfoLazyQuery>;
export type GetPathwayNavigationInfoQueryResult = Apollo.QueryResult<GetPathwayNavigationInfoQuery, GetPathwayNavigationInfoQueryVariables>;
export const UpdateEmailNotificationStatusDocument = gql`
    mutation UpdateEmailNotificationStatus($input: UpdateEmailNotificationStatusInput!) {
  updateEmailNotificationStatus(input: $input) {
    settings {
      ...PathwaySettings
    }
  }
}
    ${PathwaySettingsFragmentDoc}`;
export type UpdateEmailNotificationStatusMutationFn = Apollo.MutationFunction<UpdateEmailNotificationStatusMutation, UpdateEmailNotificationStatusMutationVariables>;

/**
 * __useUpdateEmailNotificationStatusMutation__
 *
 * To run a mutation, you first call `useUpdateEmailNotificationStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmailNotificationStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmailNotificationStatusMutation, { data, loading, error }] = useUpdateEmailNotificationStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmailNotificationStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmailNotificationStatusMutation, UpdateEmailNotificationStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmailNotificationStatusMutation, UpdateEmailNotificationStatusMutationVariables>(UpdateEmailNotificationStatusDocument, options);
      }
export type UpdateEmailNotificationStatusMutationHookResult = ReturnType<typeof useUpdateEmailNotificationStatusMutation>;
export type UpdateEmailNotificationStatusMutationResult = Apollo.MutationResult<UpdateEmailNotificationStatusMutation>;
export type UpdateEmailNotificationStatusMutationOptions = Apollo.BaseMutationOptions<UpdateEmailNotificationStatusMutation, UpdateEmailNotificationStatusMutationVariables>;
export const UpdatePathwayReminderAmountDocument = gql`
    mutation UpdatePathwayReminderAmount($input: SetRemindersAmountInput!) {
  setRemindersAmount(input: $input) {
    settings {
      ...PathwaySettings
    }
  }
}
    ${PathwaySettingsFragmentDoc}`;
export type UpdatePathwayReminderAmountMutationFn = Apollo.MutationFunction<UpdatePathwayReminderAmountMutation, UpdatePathwayReminderAmountMutationVariables>;

/**
 * __useUpdatePathwayReminderAmountMutation__
 *
 * To run a mutation, you first call `useUpdatePathwayReminderAmountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePathwayReminderAmountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePathwayReminderAmountMutation, { data, loading, error }] = useUpdatePathwayReminderAmountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePathwayReminderAmountMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePathwayReminderAmountMutation, UpdatePathwayReminderAmountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePathwayReminderAmountMutation, UpdatePathwayReminderAmountMutationVariables>(UpdatePathwayReminderAmountDocument, options);
      }
export type UpdatePathwayReminderAmountMutationHookResult = ReturnType<typeof useUpdatePathwayReminderAmountMutation>;
export type UpdatePathwayReminderAmountMutationResult = Apollo.MutationResult<UpdatePathwayReminderAmountMutation>;
export type UpdatePathwayReminderAmountMutationOptions = Apollo.BaseMutationOptions<UpdatePathwayReminderAmountMutation, UpdatePathwayReminderAmountMutationVariables>;
export const UpdatePathwayReminderDelayDocument = gql`
    mutation UpdatePathwayReminderDelay($input: SetRemindersDelayInput!) {
  setRemindersDelay(input: $input) {
    settings {
      ...PathwaySettings
    }
  }
}
    ${PathwaySettingsFragmentDoc}`;
export type UpdatePathwayReminderDelayMutationFn = Apollo.MutationFunction<UpdatePathwayReminderDelayMutation, UpdatePathwayReminderDelayMutationVariables>;

/**
 * __useUpdatePathwayReminderDelayMutation__
 *
 * To run a mutation, you first call `useUpdatePathwayReminderDelayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePathwayReminderDelayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePathwayReminderDelayMutation, { data, loading, error }] = useUpdatePathwayReminderDelayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePathwayReminderDelayMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePathwayReminderDelayMutation, UpdatePathwayReminderDelayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePathwayReminderDelayMutation, UpdatePathwayReminderDelayMutationVariables>(UpdatePathwayReminderDelayDocument, options);
      }
export type UpdatePathwayReminderDelayMutationHookResult = ReturnType<typeof useUpdatePathwayReminderDelayMutation>;
export type UpdatePathwayReminderDelayMutationResult = Apollo.MutationResult<UpdatePathwayReminderDelayMutation>;
export type UpdatePathwayReminderDelayMutationOptions = Apollo.BaseMutationOptions<UpdatePathwayReminderDelayMutation, UpdatePathwayReminderDelayMutationVariables>;
export const PathwaySettingsDocument = gql`
    query PathwaySettings($definition_id: String!) {
  pathwaySettings(input: $definition_id) {
    settings {
      ...PathwaySettings
    }
  }
}
    ${PathwaySettingsFragmentDoc}`;

/**
 * __usePathwaySettingsQuery__
 *
 * To run a query within a React component, call `usePathwaySettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePathwaySettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePathwaySettingsQuery({
 *   variables: {
 *      definition_id: // value for 'definition_id'
 *   },
 * });
 */
export function usePathwaySettingsQuery(baseOptions: Apollo.QueryHookOptions<PathwaySettingsQuery, PathwaySettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PathwaySettingsQuery, PathwaySettingsQueryVariables>(PathwaySettingsDocument, options);
      }
export function usePathwaySettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PathwaySettingsQuery, PathwaySettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PathwaySettingsQuery, PathwaySettingsQueryVariables>(PathwaySettingsDocument, options);
        }
export type PathwaySettingsQueryHookResult = ReturnType<typeof usePathwaySettingsQuery>;
export type PathwaySettingsLazyQueryHookResult = ReturnType<typeof usePathwaySettingsLazyQuery>;
export type PathwaySettingsQueryResult = Apollo.QueryResult<PathwaySettingsQuery, PathwaySettingsQueryVariables>;
export const PathwayStakeholdersDocument = gql`
    query PathwayStakeholders($pathway_id: String!) {
  pathway(id: $pathway_id) {
    pathway {
      id
      stakeholders {
        id
        clinical_app_role
        label {
          en
        }
      }
    }
  }
}
    `;

/**
 * __usePathwayStakeholdersQuery__
 *
 * To run a query within a React component, call `usePathwayStakeholdersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePathwayStakeholdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePathwayStakeholdersQuery({
 *   variables: {
 *      pathway_id: // value for 'pathway_id'
 *   },
 * });
 */
export function usePathwayStakeholdersQuery(baseOptions: Apollo.QueryHookOptions<PathwayStakeholdersQuery, PathwayStakeholdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PathwayStakeholdersQuery, PathwayStakeholdersQueryVariables>(PathwayStakeholdersDocument, options);
      }
export function usePathwayStakeholdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PathwayStakeholdersQuery, PathwayStakeholdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PathwayStakeholdersQuery, PathwayStakeholdersQueryVariables>(PathwayStakeholdersDocument, options);
        }
export type PathwayStakeholdersQueryHookResult = ReturnType<typeof usePathwayStakeholdersQuery>;
export type PathwayStakeholdersLazyQueryHookResult = ReturnType<typeof usePathwayStakeholdersLazyQuery>;
export type PathwayStakeholdersQueryResult = Apollo.QueryResult<PathwayStakeholdersQuery, PathwayStakeholdersQueryVariables>;
export const SetStakeholderEmailsDocument = gql`
    mutation SetStakeholderEmails($input: SetStakeholderEmailsInput!) {
  setStakeholderEmails(input: $input) {
    settings {
      id
      reminders {
        amount
        delay {
          amount
          unit
        }
      }
      stakeholder_notification_language
      dashboard_ids
      stakeholders {
        definition_id
        email
      }
    }
  }
}
    `;
export type SetStakeholderEmailsMutationFn = Apollo.MutationFunction<SetStakeholderEmailsMutation, SetStakeholderEmailsMutationVariables>;

/**
 * __useSetStakeholderEmailsMutation__
 *
 * To run a mutation, you first call `useSetStakeholderEmailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetStakeholderEmailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setStakeholderEmailsMutation, { data, loading, error }] = useSetStakeholderEmailsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetStakeholderEmailsMutation(baseOptions?: Apollo.MutationHookOptions<SetStakeholderEmailsMutation, SetStakeholderEmailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetStakeholderEmailsMutation, SetStakeholderEmailsMutationVariables>(SetStakeholderEmailsDocument, options);
      }
export type SetStakeholderEmailsMutationHookResult = ReturnType<typeof useSetStakeholderEmailsMutation>;
export type SetStakeholderEmailsMutationResult = Apollo.MutationResult<SetStakeholderEmailsMutation>;
export type SetStakeholderEmailsMutationOptions = Apollo.BaseMutationOptions<SetStakeholderEmailsMutation, SetStakeholderEmailsMutationVariables>;
export const AddTrackToPathwayDocument = gql`
    mutation AddTrackToPathway($input: AddTrackToPathwayInput!) {
  addTrackToPathway(input: $input) {
    success
    track {
      ...Track
    }
  }
}
    ${TrackFragmentDoc}`;
export type AddTrackToPathwayMutationFn = Apollo.MutationFunction<AddTrackToPathwayMutation, AddTrackToPathwayMutationVariables>;

/**
 * __useAddTrackToPathwayMutation__
 *
 * To run a mutation, you first call `useAddTrackToPathwayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTrackToPathwayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTrackToPathwayMutation, { data, loading, error }] = useAddTrackToPathwayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTrackToPathwayMutation(baseOptions?: Apollo.MutationHookOptions<AddTrackToPathwayMutation, AddTrackToPathwayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTrackToPathwayMutation, AddTrackToPathwayMutationVariables>(AddTrackToPathwayDocument, options);
      }
export type AddTrackToPathwayMutationHookResult = ReturnType<typeof useAddTrackToPathwayMutation>;
export type AddTrackToPathwayMutationResult = Apollo.MutationResult<AddTrackToPathwayMutation>;
export type AddTrackToPathwayMutationOptions = Apollo.BaseMutationOptions<AddTrackToPathwayMutation, AddTrackToPathwayMutationVariables>;
export const AddTriggerToTrackDocument = gql`
    mutation AddTriggerToTrack($input: AddTriggerToTrackInput!) {
  addTriggerToTrack(input: $input) {
    success
    trigger {
      ...Trigger
    }
  }
}
    ${TriggerFragmentDoc}`;
export type AddTriggerToTrackMutationFn = Apollo.MutationFunction<AddTriggerToTrackMutation, AddTriggerToTrackMutationVariables>;

/**
 * __useAddTriggerToTrackMutation__
 *
 * To run a mutation, you first call `useAddTriggerToTrackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTriggerToTrackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTriggerToTrackMutation, { data, loading, error }] = useAddTriggerToTrackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTriggerToTrackMutation(baseOptions?: Apollo.MutationHookOptions<AddTriggerToTrackMutation, AddTriggerToTrackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTriggerToTrackMutation, AddTriggerToTrackMutationVariables>(AddTriggerToTrackDocument, options);
      }
export type AddTriggerToTrackMutationHookResult = ReturnType<typeof useAddTriggerToTrackMutation>;
export type AddTriggerToTrackMutationResult = Apollo.MutationResult<AddTriggerToTrackMutation>;
export type AddTriggerToTrackMutationOptions = Apollo.BaseMutationOptions<AddTriggerToTrackMutation, AddTriggerToTrackMutationVariables>;
export const GetTracksDocument = gql`
    query GetTracks($pathwayId: String!) {
  pathway(id: $pathwayId) {
    pathway {
      id
      tracks {
        ...Track
      }
    }
  }
}
    ${TrackFragmentDoc}`;

/**
 * __useGetTracksQuery__
 *
 * To run a query within a React component, call `useGetTracksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTracksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTracksQuery({
 *   variables: {
 *      pathwayId: // value for 'pathwayId'
 *   },
 * });
 */
export function useGetTracksQuery(baseOptions: Apollo.QueryHookOptions<GetTracksQuery, GetTracksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTracksQuery, GetTracksQueryVariables>(GetTracksDocument, options);
      }
export function useGetTracksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTracksQuery, GetTracksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTracksQuery, GetTracksQueryVariables>(GetTracksDocument, options);
        }
export type GetTracksQueryHookResult = ReturnType<typeof useGetTracksQuery>;
export type GetTracksLazyQueryHookResult = ReturnType<typeof useGetTracksLazyQuery>;
export type GetTracksQueryResult = Apollo.QueryResult<GetTracksQuery, GetTracksQueryVariables>;
export const RemoveTrackFromPathwayDocument = gql`
    mutation RemoveTrackFromPathway($input: RemoveTrackFromPathwayInput!) {
  removeTrackFromPathway(input: $input) {
    success
    pathway {
      id
      tracks {
        ...Track
      }
    }
  }
}
    ${TrackFragmentDoc}`;
export type RemoveTrackFromPathwayMutationFn = Apollo.MutationFunction<RemoveTrackFromPathwayMutation, RemoveTrackFromPathwayMutationVariables>;

/**
 * __useRemoveTrackFromPathwayMutation__
 *
 * To run a mutation, you first call `useRemoveTrackFromPathwayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTrackFromPathwayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTrackFromPathwayMutation, { data, loading, error }] = useRemoveTrackFromPathwayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveTrackFromPathwayMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTrackFromPathwayMutation, RemoveTrackFromPathwayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveTrackFromPathwayMutation, RemoveTrackFromPathwayMutationVariables>(RemoveTrackFromPathwayDocument, options);
      }
export type RemoveTrackFromPathwayMutationHookResult = ReturnType<typeof useRemoveTrackFromPathwayMutation>;
export type RemoveTrackFromPathwayMutationResult = Apollo.MutationResult<RemoveTrackFromPathwayMutation>;
export type RemoveTrackFromPathwayMutationOptions = Apollo.BaseMutationOptions<RemoveTrackFromPathwayMutation, RemoveTrackFromPathwayMutationVariables>;
export const RemoveTriggerFromTrackDocument = gql`
    mutation RemoveTriggerFromTrack($input: RemoveTriggerFromTrackInput!) {
  removeTriggerFromTrack(input: $input) {
    success
  }
}
    `;
export type RemoveTriggerFromTrackMutationFn = Apollo.MutationFunction<RemoveTriggerFromTrackMutation, RemoveTriggerFromTrackMutationVariables>;

/**
 * __useRemoveTriggerFromTrackMutation__
 *
 * To run a mutation, you first call `useRemoveTriggerFromTrackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTriggerFromTrackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTriggerFromTrackMutation, { data, loading, error }] = useRemoveTriggerFromTrackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveTriggerFromTrackMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTriggerFromTrackMutation, RemoveTriggerFromTrackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveTriggerFromTrackMutation, RemoveTriggerFromTrackMutationVariables>(RemoveTriggerFromTrackDocument, options);
      }
export type RemoveTriggerFromTrackMutationHookResult = ReturnType<typeof useRemoveTriggerFromTrackMutation>;
export type RemoveTriggerFromTrackMutationResult = Apollo.MutationResult<RemoveTriggerFromTrackMutation>;
export type RemoveTriggerFromTrackMutationOptions = Apollo.BaseMutationOptions<RemoveTriggerFromTrackMutation, RemoveTriggerFromTrackMutationVariables>;
export const SetTrackTitleDocument = gql`
    mutation SetTrackTitle($input: SetTrackTitleInput!) {
  setTrackTitle(input: $input) {
    success
    track {
      ...Track
    }
  }
}
    ${TrackFragmentDoc}`;
export type SetTrackTitleMutationFn = Apollo.MutationFunction<SetTrackTitleMutation, SetTrackTitleMutationVariables>;

/**
 * __useSetTrackTitleMutation__
 *
 * To run a mutation, you first call `useSetTrackTitleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTrackTitleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTrackTitleMutation, { data, loading, error }] = useSetTrackTitleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetTrackTitleMutation(baseOptions?: Apollo.MutationHookOptions<SetTrackTitleMutation, SetTrackTitleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetTrackTitleMutation, SetTrackTitleMutationVariables>(SetTrackTitleDocument, options);
      }
export type SetTrackTitleMutationHookResult = ReturnType<typeof useSetTrackTitleMutation>;
export type SetTrackTitleMutationResult = Apollo.MutationResult<SetTrackTitleMutation>;
export type SetTrackTitleMutationOptions = Apollo.BaseMutationOptions<SetTrackTitleMutation, SetTrackTitleMutationVariables>;
export const IsPathwayReadyForPreviewDocument = gql`
    query IsPathwayReadyForPreview($pathway_definition_id: String!) {
  isPathwayReadyForPreview(pathway_definition_id: $pathway_definition_id) {
    status {
      status
      components {
        id
        type
        status
        reason {
          type
          error
        }
        link {
          url
          text
        }
      }
    }
  }
}
    `;

/**
 * __useIsPathwayReadyForPreviewQuery__
 *
 * To run a query within a React component, call `useIsPathwayReadyForPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsPathwayReadyForPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsPathwayReadyForPreviewQuery({
 *   variables: {
 *      pathway_definition_id: // value for 'pathway_definition_id'
 *   },
 * });
 */
export function useIsPathwayReadyForPreviewQuery(baseOptions: Apollo.QueryHookOptions<IsPathwayReadyForPreviewQuery, IsPathwayReadyForPreviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsPathwayReadyForPreviewQuery, IsPathwayReadyForPreviewQueryVariables>(IsPathwayReadyForPreviewDocument, options);
      }
export function useIsPathwayReadyForPreviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsPathwayReadyForPreviewQuery, IsPathwayReadyForPreviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsPathwayReadyForPreviewQuery, IsPathwayReadyForPreviewQueryVariables>(IsPathwayReadyForPreviewDocument, options);
        }
export type IsPathwayReadyForPreviewQueryHookResult = ReturnType<typeof useIsPathwayReadyForPreviewQuery>;
export type IsPathwayReadyForPreviewLazyQueryHookResult = ReturnType<typeof useIsPathwayReadyForPreviewLazyQuery>;
export type IsPathwayReadyForPreviewQueryResult = Apollo.QueryResult<IsPathwayReadyForPreviewQuery, IsPathwayReadyForPreviewQueryVariables>;
export const OnRebuildGraphStatusUpdatedDocument = gql`
    subscription OnRebuildGraphStatusUpdated($pathway_definition_id: String!) {
  rebuildGraphStatusUpdated(pathway_definition_id: $pathway_definition_id) {
    event_count
    replayed_count
  }
}
    `;

/**
 * __useOnRebuildGraphStatusUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnRebuildGraphStatusUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnRebuildGraphStatusUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnRebuildGraphStatusUpdatedSubscription({
 *   variables: {
 *      pathway_definition_id: // value for 'pathway_definition_id'
 *   },
 * });
 */
export function useOnRebuildGraphStatusUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnRebuildGraphStatusUpdatedSubscription, OnRebuildGraphStatusUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnRebuildGraphStatusUpdatedSubscription, OnRebuildGraphStatusUpdatedSubscriptionVariables>(OnRebuildGraphStatusUpdatedDocument, options);
      }
export type OnRebuildGraphStatusUpdatedSubscriptionHookResult = ReturnType<typeof useOnRebuildGraphStatusUpdatedSubscription>;
export type OnRebuildGraphStatusUpdatedSubscriptionResult = Apollo.SubscriptionResult<OnRebuildGraphStatusUpdatedSubscription>;
export const RebuildGraphDocument = gql`
    mutation RebuildGraph($pathway_definition_id: String!) {
  rebuildGraph(pathway_definition_id: $pathway_definition_id) {
    success
  }
}
    `;
export type RebuildGraphMutationFn = Apollo.MutationFunction<RebuildGraphMutation, RebuildGraphMutationVariables>;

/**
 * __useRebuildGraphMutation__
 *
 * To run a mutation, you first call `useRebuildGraphMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRebuildGraphMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rebuildGraphMutation, { data, loading, error }] = useRebuildGraphMutation({
 *   variables: {
 *      pathway_definition_id: // value for 'pathway_definition_id'
 *   },
 * });
 */
export function useRebuildGraphMutation(baseOptions?: Apollo.MutationHookOptions<RebuildGraphMutation, RebuildGraphMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RebuildGraphMutation, RebuildGraphMutationVariables>(RebuildGraphDocument, options);
      }
export type RebuildGraphMutationHookResult = ReturnType<typeof useRebuildGraphMutation>;
export type RebuildGraphMutationResult = Apollo.MutationResult<RebuildGraphMutation>;
export type RebuildGraphMutationOptions = Apollo.BaseMutationOptions<RebuildGraphMutation, RebuildGraphMutationVariables>;
export const GetPreviewDocument = gql`
    query GetPreview($pathwayCaseId: String!) {
  pathway_case(id: $pathwayCaseId) {
    pathway_case {
      ...PathwayCase
    }
  }
}
    ${PathwayCaseFragmentDoc}`;

/**
 * __useGetPreviewQuery__
 *
 * To run a query within a React component, call `useGetPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPreviewQuery({
 *   variables: {
 *      pathwayCaseId: // value for 'pathwayCaseId'
 *   },
 * });
 */
export function useGetPreviewQuery(baseOptions: Apollo.QueryHookOptions<GetPreviewQuery, GetPreviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPreviewQuery, GetPreviewQueryVariables>(GetPreviewDocument, options);
      }
export function useGetPreviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPreviewQuery, GetPreviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPreviewQuery, GetPreviewQueryVariables>(GetPreviewDocument, options);
        }
export type GetPreviewQueryHookResult = ReturnType<typeof useGetPreviewQuery>;
export type GetPreviewLazyQueryHookResult = ReturnType<typeof useGetPreviewLazyQuery>;
export type GetPreviewQueryResult = Apollo.QueryResult<GetPreviewQuery, GetPreviewQueryVariables>;
export const OnPreviewUpdatedDocument = gql`
    subscription OnPreviewUpdated($pathwayCaseId: ID!) {
  previewUpdated(pathway_case_id: $pathwayCaseId) {
    ...PathwayCase
  }
}
    ${PathwayCaseFragmentDoc}`;

/**
 * __useOnPreviewUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnPreviewUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnPreviewUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnPreviewUpdatedSubscription({
 *   variables: {
 *      pathwayCaseId: // value for 'pathwayCaseId'
 *   },
 * });
 */
export function useOnPreviewUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnPreviewUpdatedSubscription, OnPreviewUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnPreviewUpdatedSubscription, OnPreviewUpdatedSubscriptionVariables>(OnPreviewUpdatedDocument, options);
      }
export type OnPreviewUpdatedSubscriptionHookResult = ReturnType<typeof useOnPreviewUpdatedSubscription>;
export type OnPreviewUpdatedSubscriptionResult = Apollo.SubscriptionResult<OnPreviewUpdatedSubscription>;
export const ResetPreviewDocument = gql`
    mutation ResetPreview($input: ResetPreviewInput!) {
  resetPreview(input: $input) {
    success
  }
}
    `;
export type ResetPreviewMutationFn = Apollo.MutationFunction<ResetPreviewMutation, ResetPreviewMutationVariables>;

/**
 * __useResetPreviewMutation__
 *
 * To run a mutation, you first call `useResetPreviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPreviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPreviewMutation, { data, loading, error }] = useResetPreviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPreviewMutation(baseOptions?: Apollo.MutationHookOptions<ResetPreviewMutation, ResetPreviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPreviewMutation, ResetPreviewMutationVariables>(ResetPreviewDocument, options);
      }
export type ResetPreviewMutationHookResult = ReturnType<typeof useResetPreviewMutation>;
export type ResetPreviewMutationResult = Apollo.MutationResult<ResetPreviewMutation>;
export type ResetPreviewMutationOptions = Apollo.BaseMutationOptions<ResetPreviewMutation, ResetPreviewMutationVariables>;
export const StartPreviewDocument = gql`
    mutation StartPreview($input: StartPreviewInput!) {
  startPreview(input: $input) {
    success
  }
}
    `;
export type StartPreviewMutationFn = Apollo.MutationFunction<StartPreviewMutation, StartPreviewMutationVariables>;

/**
 * __useStartPreviewMutation__
 *
 * To run a mutation, you first call `useStartPreviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartPreviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startPreviewMutation, { data, loading, error }] = useStartPreviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartPreviewMutation(baseOptions?: Apollo.MutationHookOptions<StartPreviewMutation, StartPreviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartPreviewMutation, StartPreviewMutationVariables>(StartPreviewDocument, options);
      }
export type StartPreviewMutationHookResult = ReturnType<typeof useStartPreviewMutation>;
export type StartPreviewMutationResult = Apollo.MutationResult<StartPreviewMutation>;
export type StartPreviewMutationOptions = Apollo.BaseMutationOptions<StartPreviewMutation, StartPreviewMutationVariables>;
export const PublishPathwayDocument = gql`
    mutation PublishPathway($input: PublishPathwayInput!) {
  publishPathway(input: $input) {
    published_version {
      ...PathwayVersion
    }
  }
}
    ${PathwayVersionFragmentDoc}`;
export type PublishPathwayMutationFn = Apollo.MutationFunction<PublishPathwayMutation, PublishPathwayMutationVariables>;

/**
 * __usePublishPathwayMutation__
 *
 * To run a mutation, you first call `usePublishPathwayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishPathwayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishPathwayMutation, { data, loading, error }] = usePublishPathwayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishPathwayMutation(baseOptions?: Apollo.MutationHookOptions<PublishPathwayMutation, PublishPathwayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishPathwayMutation, PublishPathwayMutationVariables>(PublishPathwayDocument, options);
      }
export type PublishPathwayMutationHookResult = ReturnType<typeof usePublishPathwayMutation>;
export type PublishPathwayMutationResult = Apollo.MutationResult<PublishPathwayMutation>;
export type PublishPathwayMutationOptions = Apollo.BaseMutationOptions<PublishPathwayMutation, PublishPathwayMutationVariables>;
export const RefreshExtensionDocument = gql`
    mutation RefreshExtension($input: RefreshExtensionInput!) {
  refreshExtension(input: $input) {
    not_supported_actions {
      action {
        id
        title
      }
      step {
        id
        title
      }
      track {
        id
        title
      }
    }
  }
}
    `;
export type RefreshExtensionMutationFn = Apollo.MutationFunction<RefreshExtensionMutation, RefreshExtensionMutationVariables>;

/**
 * __useRefreshExtensionMutation__
 *
 * To run a mutation, you first call `useRefreshExtensionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshExtensionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshExtensionMutation, { data, loading, error }] = useRefreshExtensionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRefreshExtensionMutation(baseOptions?: Apollo.MutationHookOptions<RefreshExtensionMutation, RefreshExtensionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshExtensionMutation, RefreshExtensionMutationVariables>(RefreshExtensionDocument, options);
      }
export type RefreshExtensionMutationHookResult = ReturnType<typeof useRefreshExtensionMutation>;
export type RefreshExtensionMutationResult = Apollo.MutationResult<RefreshExtensionMutation>;
export type RefreshExtensionMutationOptions = Apollo.BaseMutationOptions<RefreshExtensionMutation, RefreshExtensionMutationVariables>;
export const RemoveDataPointDefinitionFromPathwayDocument = gql`
    mutation RemoveDataPointDefinitionFromPathway($input: RemoveDataPointDefinitionFromPathwayInput!) {
  removeDataPointDefinitionFromPathway(input: $input) {
    success
  }
}
    `;
export type RemoveDataPointDefinitionFromPathwayMutationFn = Apollo.MutationFunction<RemoveDataPointDefinitionFromPathwayMutation, RemoveDataPointDefinitionFromPathwayMutationVariables>;

/**
 * __useRemoveDataPointDefinitionFromPathwayMutation__
 *
 * To run a mutation, you first call `useRemoveDataPointDefinitionFromPathwayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDataPointDefinitionFromPathwayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDataPointDefinitionFromPathwayMutation, { data, loading, error }] = useRemoveDataPointDefinitionFromPathwayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveDataPointDefinitionFromPathwayMutation(baseOptions?: Apollo.MutationHookOptions<RemoveDataPointDefinitionFromPathwayMutation, RemoveDataPointDefinitionFromPathwayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveDataPointDefinitionFromPathwayMutation, RemoveDataPointDefinitionFromPathwayMutationVariables>(RemoveDataPointDefinitionFromPathwayDocument, options);
      }
export type RemoveDataPointDefinitionFromPathwayMutationHookResult = ReturnType<typeof useRemoveDataPointDefinitionFromPathwayMutation>;
export type RemoveDataPointDefinitionFromPathwayMutationResult = Apollo.MutationResult<RemoveDataPointDefinitionFromPathwayMutation>;
export type RemoveDataPointDefinitionFromPathwayMutationOptions = Apollo.BaseMutationOptions<RemoveDataPointDefinitionFromPathwayMutation, RemoveDataPointDefinitionFromPathwayMutationVariables>;
export const RemoveConstantDocument = gql`
    mutation RemoveConstant($input: RemoveConstantInput!) {
  removeConstant(input: $input) {
    success
    pathway {
      id
      settings {
        constants {
          id
          label
          value
          obfuscated
        }
      }
    }
  }
}
    `;
export type RemoveConstantMutationFn = Apollo.MutationFunction<RemoveConstantMutation, RemoveConstantMutationVariables>;

/**
 * __useRemoveConstantMutation__
 *
 * To run a mutation, you first call `useRemoveConstantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveConstantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeConstantMutation, { data, loading, error }] = useRemoveConstantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveConstantMutation(baseOptions?: Apollo.MutationHookOptions<RemoveConstantMutation, RemoveConstantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveConstantMutation, RemoveConstantMutationVariables>(RemoveConstantDocument, options);
      }
export type RemoveConstantMutationHookResult = ReturnType<typeof useRemoveConstantMutation>;
export type RemoveConstantMutationResult = Apollo.MutationResult<RemoveConstantMutation>;
export type RemoveConstantMutationOptions = Apollo.BaseMutationOptions<RemoveConstantMutation, RemoveConstantMutationVariables>;
export const RemoveDynamicVariableDocument = gql`
    mutation RemoveDynamicVariable($input: RemoveDynamicVariableInput!) {
  removeDynamicVariable(input: $input) {
    success
  }
}
    `;
export type RemoveDynamicVariableMutationFn = Apollo.MutationFunction<RemoveDynamicVariableMutation, RemoveDynamicVariableMutationVariables>;

/**
 * __useRemoveDynamicVariableMutation__
 *
 * To run a mutation, you first call `useRemoveDynamicVariableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDynamicVariableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDynamicVariableMutation, { data, loading, error }] = useRemoveDynamicVariableMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveDynamicVariableMutation(baseOptions?: Apollo.MutationHookOptions<RemoveDynamicVariableMutation, RemoveDynamicVariableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveDynamicVariableMutation, RemoveDynamicVariableMutationVariables>(RemoveDynamicVariableDocument, options);
      }
export type RemoveDynamicVariableMutationHookResult = ReturnType<typeof useRemoveDynamicVariableMutation>;
export type RemoveDynamicVariableMutationResult = Apollo.MutationResult<RemoveDynamicVariableMutation>;
export type RemoveDynamicVariableMutationOptions = Apollo.BaseMutationOptions<RemoveDynamicVariableMutation, RemoveDynamicVariableMutationVariables>;
export const RemoveStepFromLibraryDocument = gql`
    mutation RemoveStepFromLibrary($input: RemoveStepFromLibraryInput!) {
  removeStepFromLibrary(input: $input) {
    success
  }
}
    `;
export type RemoveStepFromLibraryMutationFn = Apollo.MutationFunction<RemoveStepFromLibraryMutation, RemoveStepFromLibraryMutationVariables>;

/**
 * __useRemoveStepFromLibraryMutation__
 *
 * To run a mutation, you first call `useRemoveStepFromLibraryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveStepFromLibraryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeStepFromLibraryMutation, { data, loading, error }] = useRemoveStepFromLibraryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveStepFromLibraryMutation(baseOptions?: Apollo.MutationHookOptions<RemoveStepFromLibraryMutation, RemoveStepFromLibraryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveStepFromLibraryMutation, RemoveStepFromLibraryMutationVariables>(RemoveStepFromLibraryDocument, options);
      }
export type RemoveStepFromLibraryMutationHookResult = ReturnType<typeof useRemoveStepFromLibraryMutation>;
export type RemoveStepFromLibraryMutationResult = Apollo.MutationResult<RemoveStepFromLibraryMutation>;
export type RemoveStepFromLibraryMutationOptions = Apollo.BaseMutationOptions<RemoveStepFromLibraryMutation, RemoveStepFromLibraryMutationVariables>;
export const RemoveWebhookDocument = gql`
    mutation RemoveWebhook($input: RemoveWebhookInput!) {
  removeWebhook(input: $input) {
    success
    pathway {
      settings {
        webhooks {
          id
          enabled
          name
        }
      }
    }
  }
}
    `;
export type RemoveWebhookMutationFn = Apollo.MutationFunction<RemoveWebhookMutation, RemoveWebhookMutationVariables>;

/**
 * __useRemoveWebhookMutation__
 *
 * To run a mutation, you first call `useRemoveWebhookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveWebhookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeWebhookMutation, { data, loading, error }] = useRemoveWebhookMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveWebhookMutation(baseOptions?: Apollo.MutationHookOptions<RemoveWebhookMutation, RemoveWebhookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveWebhookMutation, RemoveWebhookMutationVariables>(RemoveWebhookDocument, options);
      }
export type RemoveWebhookMutationHookResult = ReturnType<typeof useRemoveWebhookMutation>;
export type RemoveWebhookMutationResult = Apollo.MutationResult<RemoveWebhookMutation>;
export type RemoveWebhookMutationOptions = Apollo.BaseMutationOptions<RemoveWebhookMutation, RemoveWebhookMutationVariables>;
export const RetryAllFailedWebhooksDocument = gql`
    mutation RetryAllFailedWebhooks($input: RetryAllFailedWebhookCallsInput!) {
  retryAllFailedWebhookCalls(input: $input) {
    success
  }
}
    `;
export type RetryAllFailedWebhooksMutationFn = Apollo.MutationFunction<RetryAllFailedWebhooksMutation, RetryAllFailedWebhooksMutationVariables>;

/**
 * __useRetryAllFailedWebhooksMutation__
 *
 * To run a mutation, you first call `useRetryAllFailedWebhooksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetryAllFailedWebhooksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retryAllFailedWebhooksMutation, { data, loading, error }] = useRetryAllFailedWebhooksMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRetryAllFailedWebhooksMutation(baseOptions?: Apollo.MutationHookOptions<RetryAllFailedWebhooksMutation, RetryAllFailedWebhooksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RetryAllFailedWebhooksMutation, RetryAllFailedWebhooksMutationVariables>(RetryAllFailedWebhooksDocument, options);
      }
export type RetryAllFailedWebhooksMutationHookResult = ReturnType<typeof useRetryAllFailedWebhooksMutation>;
export type RetryAllFailedWebhooksMutationResult = Apollo.MutationResult<RetryAllFailedWebhooksMutation>;
export type RetryAllFailedWebhooksMutationOptions = Apollo.BaseMutationOptions<RetryAllFailedWebhooksMutation, RetryAllFailedWebhooksMutationVariables>;
export const RetryAllWebhookCallsDocument = gql`
    mutation RetryAllWebhookCalls($input: RetryAllWebhookCallsInput!) {
  retryAllWebhookCalls(input: $input) {
    success
  }
}
    `;
export type RetryAllWebhookCallsMutationFn = Apollo.MutationFunction<RetryAllWebhookCallsMutation, RetryAllWebhookCallsMutationVariables>;

/**
 * __useRetryAllWebhookCallsMutation__
 *
 * To run a mutation, you first call `useRetryAllWebhookCallsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetryAllWebhookCallsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retryAllWebhookCallsMutation, { data, loading, error }] = useRetryAllWebhookCallsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRetryAllWebhookCallsMutation(baseOptions?: Apollo.MutationHookOptions<RetryAllWebhookCallsMutation, RetryAllWebhookCallsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RetryAllWebhookCallsMutation, RetryAllWebhookCallsMutationVariables>(RetryAllWebhookCallsDocument, options);
      }
export type RetryAllWebhookCallsMutationHookResult = ReturnType<typeof useRetryAllWebhookCallsMutation>;
export type RetryAllWebhookCallsMutationResult = Apollo.MutationResult<RetryAllWebhookCallsMutation>;
export type RetryAllWebhookCallsMutationOptions = Apollo.BaseMutationOptions<RetryAllWebhookCallsMutation, RetryAllWebhookCallsMutationVariables>;
export const RetryApiCallDocument = gql`
    mutation RetryApiCall($input: RetryApiCallInput!) {
  retryApiCall(input: $input) {
    success
    code
  }
}
    `;
export type RetryApiCallMutationFn = Apollo.MutationFunction<RetryApiCallMutation, RetryApiCallMutationVariables>;

/**
 * __useRetryApiCallMutation__
 *
 * To run a mutation, you first call `useRetryApiCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetryApiCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retryApiCallMutation, { data, loading, error }] = useRetryApiCallMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRetryApiCallMutation(baseOptions?: Apollo.MutationHookOptions<RetryApiCallMutation, RetryApiCallMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RetryApiCallMutation, RetryApiCallMutationVariables>(RetryApiCallDocument, options);
      }
export type RetryApiCallMutationHookResult = ReturnType<typeof useRetryApiCallMutation>;
export type RetryApiCallMutationResult = Apollo.MutationResult<RetryApiCallMutation>;
export type RetryApiCallMutationOptions = Apollo.BaseMutationOptions<RetryApiCallMutation, RetryApiCallMutationVariables>;
export const RetryWebhookCallDocument = gql`
    mutation RetryWebhookCall($input: RetryWebhookCallInput!) {
  retryWebhookCall(input: $input) {
    success
    code
  }
}
    `;
export type RetryWebhookCallMutationFn = Apollo.MutationFunction<RetryWebhookCallMutation, RetryWebhookCallMutationVariables>;

/**
 * __useRetryWebhookCallMutation__
 *
 * To run a mutation, you first call `useRetryWebhookCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetryWebhookCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retryWebhookCallMutation, { data, loading, error }] = useRetryWebhookCallMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRetryWebhookCallMutation(baseOptions?: Apollo.MutationHookOptions<RetryWebhookCallMutation, RetryWebhookCallMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RetryWebhookCallMutation, RetryWebhookCallMutationVariables>(RetryWebhookCallDocument, options);
      }
export type RetryWebhookCallMutationHookResult = ReturnType<typeof useRetryWebhookCallMutation>;
export type RetryWebhookCallMutationResult = Apollo.MutationResult<RetryWebhookCallMutation>;
export type RetryWebhookCallMutationOptions = Apollo.BaseMutationOptions<RetryWebhookCallMutation, RetryWebhookCallMutationVariables>;
export const SetCustomActionFieldDocument = gql`
    mutation SetCustomActionField($input: SetCustomActionFieldInput!) {
  setCustomActionField(input: $input) {
    action {
      id
      ... on PushToEmrAction {
        custom_fields {
          id
          value
        }
      }
    }
  }
}
    `;
export type SetCustomActionFieldMutationFn = Apollo.MutationFunction<SetCustomActionFieldMutation, SetCustomActionFieldMutationVariables>;

/**
 * __useSetCustomActionFieldMutation__
 *
 * To run a mutation, you first call `useSetCustomActionFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCustomActionFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCustomActionFieldMutation, { data, loading, error }] = useSetCustomActionFieldMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetCustomActionFieldMutation(baseOptions?: Apollo.MutationHookOptions<SetCustomActionFieldMutation, SetCustomActionFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetCustomActionFieldMutation, SetCustomActionFieldMutationVariables>(SetCustomActionFieldDocument, options);
      }
export type SetCustomActionFieldMutationHookResult = ReturnType<typeof useSetCustomActionFieldMutation>;
export type SetCustomActionFieldMutationResult = Apollo.MutationResult<SetCustomActionFieldMutation>;
export type SetCustomActionFieldMutationOptions = Apollo.BaseMutationOptions<SetCustomActionFieldMutation, SetCustomActionFieldMutationVariables>;
export const SetDataPointDefinitionOptionalDocument = gql`
    mutation SetDataPointDefinitionOptional($input: SetDataPointDefinitionOptionalFieldInput!) {
  setDataPointDefinitionOptionalField(input: $input) {
    success
    data_point_definition {
      id
      optional
    }
  }
}
    `;
export type SetDataPointDefinitionOptionalMutationFn = Apollo.MutationFunction<SetDataPointDefinitionOptionalMutation, SetDataPointDefinitionOptionalMutationVariables>;

/**
 * __useSetDataPointDefinitionOptionalMutation__
 *
 * To run a mutation, you first call `useSetDataPointDefinitionOptionalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDataPointDefinitionOptionalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDataPointDefinitionOptionalMutation, { data, loading, error }] = useSetDataPointDefinitionOptionalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetDataPointDefinitionOptionalMutation(baseOptions?: Apollo.MutationHookOptions<SetDataPointDefinitionOptionalMutation, SetDataPointDefinitionOptionalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetDataPointDefinitionOptionalMutation, SetDataPointDefinitionOptionalMutationVariables>(SetDataPointDefinitionOptionalDocument, options);
      }
export type SetDataPointDefinitionOptionalMutationHookResult = ReturnType<typeof useSetDataPointDefinitionOptionalMutation>;
export type SetDataPointDefinitionOptionalMutationResult = Apollo.MutationResult<SetDataPointDefinitionOptionalMutation>;
export type SetDataPointDefinitionOptionalMutationOptions = Apollo.BaseMutationOptions<SetDataPointDefinitionOptionalMutation, SetDataPointDefinitionOptionalMutationVariables>;
export const SetTransitionDestinationDocument = gql`
    mutation SetTransitionDestination($input: SetTransitionDestinationInput!) {
  setTransitionDestination(input: $input) {
    transition {
      ...CanvasTransition
    }
  }
}
    ${CanvasTransitionFragmentDoc}`;
export type SetTransitionDestinationMutationFn = Apollo.MutationFunction<SetTransitionDestinationMutation, SetTransitionDestinationMutationVariables>;

/**
 * __useSetTransitionDestinationMutation__
 *
 * To run a mutation, you first call `useSetTransitionDestinationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTransitionDestinationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTransitionDestinationMutation, { data, loading, error }] = useSetTransitionDestinationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetTransitionDestinationMutation(baseOptions?: Apollo.MutationHookOptions<SetTransitionDestinationMutation, SetTransitionDestinationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetTransitionDestinationMutation, SetTransitionDestinationMutationVariables>(SetTransitionDestinationDocument, options);
      }
export type SetTransitionDestinationMutationHookResult = ReturnType<typeof useSetTransitionDestinationMutation>;
export type SetTransitionDestinationMutationResult = Apollo.MutationResult<SetTransitionDestinationMutation>;
export type SetTransitionDestinationMutationOptions = Apollo.BaseMutationOptions<SetTransitionDestinationMutation, SetTransitionDestinationMutationVariables>;
export const SetExtensionActionFieldDocument = gql`
    mutation SetExtensionActionField($input: SetExtensionActionFieldInput!) {
  setExtensionActionField(input: $input) {
    action {
      id
      status {
        ...ConfigurationStatus
      }
      ... on ExtensionAction {
        extension_fields {
          id
          ... on HtmlField {
            value
            slate
          }
          ... on DateField {
            value
            delay {
              amount
              unit
            }
          }
          ... on JsonField {
            value
            slate
          }
          ... on NumericField {
            value
          }
          ... on StringField {
            value
          }
          ... on TextField {
            value
            slate
          }
          ... on BooleanField {
            value
          }
          ... on StringArrayField {
            value
          }
          ... on NumericArrayField {
            value
          }
        }
      }
    }
  }
}
    ${ConfigurationStatusFragmentDoc}`;
export type SetExtensionActionFieldMutationFn = Apollo.MutationFunction<SetExtensionActionFieldMutation, SetExtensionActionFieldMutationVariables>;

/**
 * __useSetExtensionActionFieldMutation__
 *
 * To run a mutation, you first call `useSetExtensionActionFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetExtensionActionFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setExtensionActionFieldMutation, { data, loading, error }] = useSetExtensionActionFieldMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetExtensionActionFieldMutation(baseOptions?: Apollo.MutationHookOptions<SetExtensionActionFieldMutation, SetExtensionActionFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetExtensionActionFieldMutation, SetExtensionActionFieldMutationVariables>(SetExtensionActionFieldDocument, options);
      }
export type SetExtensionActionFieldMutationHookResult = ReturnType<typeof useSetExtensionActionFieldMutation>;
export type SetExtensionActionFieldMutationResult = Apollo.MutationResult<SetExtensionActionFieldMutation>;
export type SetExtensionActionFieldMutationOptions = Apollo.BaseMutationOptions<SetExtensionActionFieldMutation, SetExtensionActionFieldMutationVariables>;
export const SetStepTitleDocument = gql`
    mutation SetStepTitle($input: SetStepTitleInput!) {
  setStepTitle(input: $input) {
    success
    code
    step {
      id
      title
    }
  }
}
    `;
export type SetStepTitleMutationFn = Apollo.MutationFunction<SetStepTitleMutation, SetStepTitleMutationVariables>;

/**
 * __useSetStepTitleMutation__
 *
 * To run a mutation, you first call `useSetStepTitleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetStepTitleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setStepTitleMutation, { data, loading, error }] = useSetStepTitleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetStepTitleMutation(baseOptions?: Apollo.MutationHookOptions<SetStepTitleMutation, SetStepTitleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetStepTitleMutation, SetStepTitleMutationVariables>(SetStepTitleDocument, options);
      }
export type SetStepTitleMutationHookResult = ReturnType<typeof useSetStepTitleMutation>;
export type SetStepTitleMutationResult = Apollo.MutationResult<SetStepTitleMutation>;
export type SetStepTitleMutationOptions = Apollo.BaseMutationOptions<SetStepTitleMutation, SetStepTitleMutationVariables>;
export const StakeholdersDocument = gql`
    query Stakeholders {
  stakeholders {
    stakeholders {
      id
      label {
        en
      }
      clinical_app_role
    }
  }
}
    `;

/**
 * __useStakeholdersQuery__
 *
 * To run a query within a React component, call `useStakeholdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useStakeholdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStakeholdersQuery({
 *   variables: {
 *   },
 * });
 */
export function useStakeholdersQuery(baseOptions?: Apollo.QueryHookOptions<StakeholdersQuery, StakeholdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StakeholdersQuery, StakeholdersQueryVariables>(StakeholdersDocument, options);
      }
export function useStakeholdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StakeholdersQuery, StakeholdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StakeholdersQuery, StakeholdersQueryVariables>(StakeholdersDocument, options);
        }
export type StakeholdersQueryHookResult = ReturnType<typeof useStakeholdersQuery>;
export type StakeholdersLazyQueryHookResult = ReturnType<typeof useStakeholdersLazyQuery>;
export type StakeholdersQueryResult = Apollo.QueryResult<StakeholdersQuery, StakeholdersQueryVariables>;
export const AddTrackDocument = gql`
    mutation AddTrack($input: AddTrackInput!) {
  addTrack(input: $input) {
    success
  }
}
    `;
export type AddTrackMutationFn = Apollo.MutationFunction<AddTrackMutation, AddTrackMutationVariables>;

/**
 * __useAddTrackMutation__
 *
 * To run a mutation, you first call `useAddTrackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTrackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTrackMutation, { data, loading, error }] = useAddTrackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTrackMutation(baseOptions?: Apollo.MutationHookOptions<AddTrackMutation, AddTrackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTrackMutation, AddTrackMutationVariables>(AddTrackDocument, options);
      }
export type AddTrackMutationHookResult = ReturnType<typeof useAddTrackMutation>;
export type AddTrackMutationResult = Apollo.MutationResult<AddTrackMutation>;
export type AddTrackMutationOptions = Apollo.BaseMutationOptions<AddTrackMutation, AddTrackMutationVariables>;
export const GetAdHocTracksDocument = gql`
    query GetAdHocTracks($pathway_definition_id: String!) {
  adHocTracks(pathway_definition_id: $pathway_definition_id) {
    tracks {
      id
      title
    }
  }
}
    `;

/**
 * __useGetAdHocTracksQuery__
 *
 * To run a query within a React component, call `useGetAdHocTracksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdHocTracksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdHocTracksQuery({
 *   variables: {
 *      pathway_definition_id: // value for 'pathway_definition_id'
 *   },
 * });
 */
export function useGetAdHocTracksQuery(baseOptions: Apollo.QueryHookOptions<GetAdHocTracksQuery, GetAdHocTracksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdHocTracksQuery, GetAdHocTracksQueryVariables>(GetAdHocTracksDocument, options);
      }
export function useGetAdHocTracksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdHocTracksQuery, GetAdHocTracksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdHocTracksQuery, GetAdHocTracksQueryVariables>(GetAdHocTracksDocument, options);
        }
export type GetAdHocTracksQueryHookResult = ReturnType<typeof useGetAdHocTracksQuery>;
export type GetAdHocTracksLazyQueryHookResult = ReturnType<typeof useGetAdHocTracksLazyQuery>;
export type GetAdHocTracksQueryResult = Apollo.QueryResult<GetAdHocTracksQuery, GetAdHocTracksQueryVariables>;
export const GetStepDocumentationDocument = gql`
    query GetStepDocumentation($id: String!) {
  step(id: $id) {
    success
    code
    step {
      id
      documentation {
        content {
          en
        }
      }
    }
  }
}
    `;

/**
 * __useGetStepDocumentationQuery__
 *
 * To run a query within a React component, call `useGetStepDocumentationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStepDocumentationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStepDocumentationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetStepDocumentationQuery(baseOptions: Apollo.QueryHookOptions<GetStepDocumentationQuery, GetStepDocumentationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStepDocumentationQuery, GetStepDocumentationQueryVariables>(GetStepDocumentationDocument, options);
      }
export function useGetStepDocumentationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStepDocumentationQuery, GetStepDocumentationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStepDocumentationQuery, GetStepDocumentationQueryVariables>(GetStepDocumentationDocument, options);
        }
export type GetStepDocumentationQueryHookResult = ReturnType<typeof useGetStepDocumentationQuery>;
export type GetStepDocumentationLazyQueryHookResult = ReturnType<typeof useGetStepDocumentationLazyQuery>;
export type GetStepDocumentationQueryResult = Apollo.QueryResult<GetStepDocumentationQuery, GetStepDocumentationQueryVariables>;
export const StepLibraryDocument = gql`
    query StepLibrary {
  stepLibrary {
    steps {
      id
      title
      actions {
        id
        locations {
          label
          id
        }
        stakeholders {
          id
          label {
            en
          }
        }
      }
    }
  }
}
    `;

/**
 * __useStepLibraryQuery__
 *
 * To run a query within a React component, call `useStepLibraryQuery` and pass it any options that fit your needs.
 * When your component renders, `useStepLibraryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStepLibraryQuery({
 *   variables: {
 *   },
 * });
 */
export function useStepLibraryQuery(baseOptions?: Apollo.QueryHookOptions<StepLibraryQuery, StepLibraryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StepLibraryQuery, StepLibraryQueryVariables>(StepLibraryDocument, options);
      }
export function useStepLibraryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StepLibraryQuery, StepLibraryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StepLibraryQuery, StepLibraryQueryVariables>(StepLibraryDocument, options);
        }
export type StepLibraryQueryHookResult = ReturnType<typeof useStepLibraryQuery>;
export type StepLibraryLazyQueryHookResult = ReturnType<typeof useStepLibraryLazyQuery>;
export type StepLibraryQueryResult = Apollo.QueryResult<StepLibraryQuery, StepLibraryQueryVariables>;
export const StepLibraryForPathwayDocument = gql`
    query StepLibraryForPathway($pathway_id: String!) {
  stepLibraryForPathway(pathway_id: $pathway_id) {
    steps {
      id
      title
      actions {
        id
        locations {
          label
          id
        }
        stakeholders {
          id
          label {
            en
          }
        }
      }
    }
  }
}
    `;

/**
 * __useStepLibraryForPathwayQuery__
 *
 * To run a query within a React component, call `useStepLibraryForPathwayQuery` and pass it any options that fit your needs.
 * When your component renders, `useStepLibraryForPathwayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStepLibraryForPathwayQuery({
 *   variables: {
 *      pathway_id: // value for 'pathway_id'
 *   },
 * });
 */
export function useStepLibraryForPathwayQuery(baseOptions: Apollo.QueryHookOptions<StepLibraryForPathwayQuery, StepLibraryForPathwayQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StepLibraryForPathwayQuery, StepLibraryForPathwayQueryVariables>(StepLibraryForPathwayDocument, options);
      }
export function useStepLibraryForPathwayLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StepLibraryForPathwayQuery, StepLibraryForPathwayQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StepLibraryForPathwayQuery, StepLibraryForPathwayQueryVariables>(StepLibraryForPathwayDocument, options);
        }
export type StepLibraryForPathwayQueryHookResult = ReturnType<typeof useStepLibraryForPathwayQuery>;
export type StepLibraryForPathwayLazyQueryHookResult = ReturnType<typeof useStepLibraryForPathwayLazyQuery>;
export type StepLibraryForPathwayQueryResult = Apollo.QueryResult<StepLibraryForPathwayQuery, StepLibraryForPathwayQueryVariables>;
export const GetStepWithLabelDocument = gql`
    query GetStepWithLabel($id: String!) {
  step(id: $id) {
    step {
      id
      title
      label {
        id
        text
        color
      }
    }
  }
}
    `;

/**
 * __useGetStepWithLabelQuery__
 *
 * To run a query within a React component, call `useGetStepWithLabelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStepWithLabelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStepWithLabelQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetStepWithLabelQuery(baseOptions: Apollo.QueryHookOptions<GetStepWithLabelQuery, GetStepWithLabelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStepWithLabelQuery, GetStepWithLabelQueryVariables>(GetStepWithLabelDocument, options);
      }
export function useGetStepWithLabelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStepWithLabelQuery, GetStepWithLabelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStepWithLabelQuery, GetStepWithLabelQueryVariables>(GetStepWithLabelDocument, options);
        }
export type GetStepWithLabelQueryHookResult = ReturnType<typeof useGetStepWithLabelQuery>;
export type GetStepWithLabelLazyQueryHookResult = ReturnType<typeof useGetStepWithLabelLazyQuery>;
export type GetStepWithLabelQueryResult = Apollo.QueryResult<GetStepWithLabelQuery, GetStepWithLabelQueryVariables>;
export const GetStepsWithLabelDocument = gql`
    query GetStepsWithLabel($track_id: String!) {
  steps(track_id: $track_id) {
    success
    code
    steps {
      id
      title
      label {
        id
        text
        color
      }
    }
  }
}
    `;

/**
 * __useGetStepsWithLabelQuery__
 *
 * To run a query within a React component, call `useGetStepsWithLabelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStepsWithLabelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStepsWithLabelQuery({
 *   variables: {
 *      track_id: // value for 'track_id'
 *   },
 * });
 */
export function useGetStepsWithLabelQuery(baseOptions: Apollo.QueryHookOptions<GetStepsWithLabelQuery, GetStepsWithLabelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStepsWithLabelQuery, GetStepsWithLabelQueryVariables>(GetStepsWithLabelDocument, options);
      }
export function useGetStepsWithLabelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStepsWithLabelQuery, GetStepsWithLabelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStepsWithLabelQuery, GetStepsWithLabelQueryVariables>(GetStepsWithLabelDocument, options);
        }
export type GetStepsWithLabelQueryHookResult = ReturnType<typeof useGetStepsWithLabelQuery>;
export type GetStepsWithLabelLazyQueryHookResult = ReturnType<typeof useGetStepsWithLabelLazyQuery>;
export type GetStepsWithLabelQueryResult = Apollo.QueryResult<GetStepsWithLabelQuery, GetStepsWithLabelQueryVariables>;
export const TenantDocument = gql`
    query tenant {
  tenant {
    tenant {
      id
      name
      public
      is_default
      logo_path
      accent_color
      hosted_page_title
      api_call_retry_settings {
        enabled
        initial_delay_ms
        max_delay_ms
        max_attempts
      }
    }
    code
    success
  }
}
    `;

/**
 * __useTenantQuery__
 *
 * To run a query within a React component, call `useTenantQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantQuery({
 *   variables: {
 *   },
 * });
 */
export function useTenantQuery(baseOptions?: Apollo.QueryHookOptions<TenantQuery, TenantQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TenantQuery, TenantQueryVariables>(TenantDocument, options);
      }
export function useTenantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TenantQuery, TenantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TenantQuery, TenantQueryVariables>(TenantDocument, options);
        }
export type TenantQueryHookResult = ReturnType<typeof useTenantQuery>;
export type TenantLazyQueryHookResult = ReturnType<typeof useTenantLazyQuery>;
export type TenantQueryResult = Apollo.QueryResult<TenantQuery, TenantQueryVariables>;
export const ExecuteTestApiCallDocument = gql`
    mutation ExecuteTestApiCall($input: ExecuteTestApiCallInput!) {
  executeTestApiCall(input: $input) {
    success
    request {
      endpoint
      body
      headers {
        key
        value
      }
      method
    }
    response {
      status
      body
      date
      headers
      error
    }
    mappings {
      data_point_definition_id
      response_key
      value
      value_type
    }
    metadata {
      response_time
      response_size
    }
  }
}
    `;
export type ExecuteTestApiCallMutationFn = Apollo.MutationFunction<ExecuteTestApiCallMutation, ExecuteTestApiCallMutationVariables>;

/**
 * __useExecuteTestApiCallMutation__
 *
 * To run a mutation, you first call `useExecuteTestApiCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExecuteTestApiCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [executeTestApiCallMutation, { data, loading, error }] = useExecuteTestApiCallMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExecuteTestApiCallMutation(baseOptions?: Apollo.MutationHookOptions<ExecuteTestApiCallMutation, ExecuteTestApiCallMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExecuteTestApiCallMutation, ExecuteTestApiCallMutationVariables>(ExecuteTestApiCallDocument, options);
      }
export type ExecuteTestApiCallMutationHookResult = ReturnType<typeof useExecuteTestApiCallMutation>;
export type ExecuteTestApiCallMutationResult = Apollo.MutationResult<ExecuteTestApiCallMutation>;
export type ExecuteTestApiCallMutationOptions = Apollo.BaseMutationOptions<ExecuteTestApiCallMutation, ExecuteTestApiCallMutationVariables>;
export const TestCareFlowSourceControlSettingsDocument = gql`
    mutation TestCareFlowSourceControlSettings($input: TestSourceControlInput!) {
  testCareFlowSourceControlSettings(input: $input) {
    success
    __typename
  }
}
    `;
export type TestCareFlowSourceControlSettingsMutationFn = Apollo.MutationFunction<TestCareFlowSourceControlSettingsMutation, TestCareFlowSourceControlSettingsMutationVariables>;

/**
 * __useTestCareFlowSourceControlSettingsMutation__
 *
 * To run a mutation, you first call `useTestCareFlowSourceControlSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestCareFlowSourceControlSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testCareFlowSourceControlSettingsMutation, { data, loading, error }] = useTestCareFlowSourceControlSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTestCareFlowSourceControlSettingsMutation(baseOptions?: Apollo.MutationHookOptions<TestCareFlowSourceControlSettingsMutation, TestCareFlowSourceControlSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TestCareFlowSourceControlSettingsMutation, TestCareFlowSourceControlSettingsMutationVariables>(TestCareFlowSourceControlSettingsDocument, options);
      }
export type TestCareFlowSourceControlSettingsMutationHookResult = ReturnType<typeof useTestCareFlowSourceControlSettingsMutation>;
export type TestCareFlowSourceControlSettingsMutationResult = Apollo.MutationResult<TestCareFlowSourceControlSettingsMutation>;
export type TestCareFlowSourceControlSettingsMutationOptions = Apollo.BaseMutationOptions<TestCareFlowSourceControlSettingsMutation, TestCareFlowSourceControlSettingsMutationVariables>;
export const AddStepFromLibraryToTrackDocument = gql`
    mutation AddStepFromLibraryToTrack($input: AddStepFromLibraryToTrackInput!) {
  addStepFromLibraryToTrack(input: $input) {
    success
    step {
      ...CanvasStep
    }
  }
}
    ${CanvasStepFragmentDoc}`;
export type AddStepFromLibraryToTrackMutationFn = Apollo.MutationFunction<AddStepFromLibraryToTrackMutation, AddStepFromLibraryToTrackMutationVariables>;

/**
 * __useAddStepFromLibraryToTrackMutation__
 *
 * To run a mutation, you first call `useAddStepFromLibraryToTrackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStepFromLibraryToTrackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStepFromLibraryToTrackMutation, { data, loading, error }] = useAddStepFromLibraryToTrackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddStepFromLibraryToTrackMutation(baseOptions?: Apollo.MutationHookOptions<AddStepFromLibraryToTrackMutation, AddStepFromLibraryToTrackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddStepFromLibraryToTrackMutation, AddStepFromLibraryToTrackMutationVariables>(AddStepFromLibraryToTrackDocument, options);
      }
export type AddStepFromLibraryToTrackMutationHookResult = ReturnType<typeof useAddStepFromLibraryToTrackMutation>;
export type AddStepFromLibraryToTrackMutationResult = Apollo.MutationResult<AddStepFromLibraryToTrackMutation>;
export type AddStepFromLibraryToTrackMutationOptions = Apollo.BaseMutationOptions<AddStepFromLibraryToTrackMutation, AddStepFromLibraryToTrackMutationVariables>;
export const AddStepToTrackDocument = gql`
    mutation AddStepToTrack($input: AddStepToTrackInput!) {
  addStepToTrack(input: $input) {
    success
    step {
      ...CanvasStep
    }
  }
}
    ${CanvasStepFragmentDoc}`;
export type AddStepToTrackMutationFn = Apollo.MutationFunction<AddStepToTrackMutation, AddStepToTrackMutationVariables>;

/**
 * __useAddStepToTrackMutation__
 *
 * To run a mutation, you first call `useAddStepToTrackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStepToTrackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStepToTrackMutation, { data, loading, error }] = useAddStepToTrackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddStepToTrackMutation(baseOptions?: Apollo.MutationHookOptions<AddStepToTrackMutation, AddStepToTrackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddStepToTrackMutation, AddStepToTrackMutationVariables>(AddStepToTrackDocument, options);
      }
export type AddStepToTrackMutationHookResult = ReturnType<typeof useAddStepToTrackMutation>;
export type AddStepToTrackMutationResult = Apollo.MutationResult<AddStepToTrackMutation>;
export type AddStepToTrackMutationOptions = Apollo.BaseMutationOptions<AddStepToTrackMutation, AddStepToTrackMutationVariables>;
export const AddStickyNoteToTrackDocument = gql`
    mutation AddStickyNoteToTrack($input: AddStickyNoteToTrackInput!) {
  addStickyNoteToTrack(input: $input) {
    success
    stickyNote {
      ...CanvasStickyNote
    }
  }
}
    ${CanvasStickyNoteFragmentDoc}`;
export type AddStickyNoteToTrackMutationFn = Apollo.MutationFunction<AddStickyNoteToTrackMutation, AddStickyNoteToTrackMutationVariables>;

/**
 * __useAddStickyNoteToTrackMutation__
 *
 * To run a mutation, you first call `useAddStickyNoteToTrackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStickyNoteToTrackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStickyNoteToTrackMutation, { data, loading, error }] = useAddStickyNoteToTrackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddStickyNoteToTrackMutation(baseOptions?: Apollo.MutationHookOptions<AddStickyNoteToTrackMutation, AddStickyNoteToTrackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddStickyNoteToTrackMutation, AddStickyNoteToTrackMutationVariables>(AddStickyNoteToTrackDocument, options);
      }
export type AddStickyNoteToTrackMutationHookResult = ReturnType<typeof useAddStickyNoteToTrackMutation>;
export type AddStickyNoteToTrackMutationResult = Apollo.MutationResult<AddStickyNoteToTrackMutation>;
export type AddStickyNoteToTrackMutationOptions = Apollo.BaseMutationOptions<AddStickyNoteToTrackMutation, AddStickyNoteToTrackMutationVariables>;
export const ConnectStepsDocument = gql`
    mutation ConnectSteps($input: ConnectStepsInput!) {
  connectSteps(input: $input) {
    success
    code
    origin_step {
      id
      transitions {
        id
      }
    }
    transition {
      ...CanvasTransition
    }
  }
}
    ${CanvasTransitionFragmentDoc}`;
export type ConnectStepsMutationFn = Apollo.MutationFunction<ConnectStepsMutation, ConnectStepsMutationVariables>;

/**
 * __useConnectStepsMutation__
 *
 * To run a mutation, you first call `useConnectStepsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectStepsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectStepsMutation, { data, loading, error }] = useConnectStepsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConnectStepsMutation(baseOptions?: Apollo.MutationHookOptions<ConnectStepsMutation, ConnectStepsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConnectStepsMutation, ConnectStepsMutationVariables>(ConnectStepsDocument, options);
      }
export type ConnectStepsMutationHookResult = ReturnType<typeof useConnectStepsMutation>;
export type ConnectStepsMutationResult = Apollo.MutationResult<ConnectStepsMutation>;
export type ConnectStepsMutationOptions = Apollo.BaseMutationOptions<ConnectStepsMutation, ConnectStepsMutationVariables>;
export const EndTrackAfterStepDocument = gql`
    mutation EndTrackAfterStep($input: EndTrackAfterStepInput!) {
  endTrackAfterStep(input: $input) {
    success
    code
    origin_step {
      id
      transitions {
        id
      }
    }
    transition {
      ...CanvasTransition
    }
  }
}
    ${CanvasTransitionFragmentDoc}`;
export type EndTrackAfterStepMutationFn = Apollo.MutationFunction<EndTrackAfterStepMutation, EndTrackAfterStepMutationVariables>;

/**
 * __useEndTrackAfterStepMutation__
 *
 * To run a mutation, you first call `useEndTrackAfterStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndTrackAfterStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endTrackAfterStepMutation, { data, loading, error }] = useEndTrackAfterStepMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEndTrackAfterStepMutation(baseOptions?: Apollo.MutationHookOptions<EndTrackAfterStepMutation, EndTrackAfterStepMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EndTrackAfterStepMutation, EndTrackAfterStepMutationVariables>(EndTrackAfterStepDocument, options);
      }
export type EndTrackAfterStepMutationHookResult = ReturnType<typeof useEndTrackAfterStepMutation>;
export type EndTrackAfterStepMutationResult = Apollo.MutationResult<EndTrackAfterStepMutation>;
export type EndTrackAfterStepMutationOptions = Apollo.BaseMutationOptions<EndTrackAfterStepMutation, EndTrackAfterStepMutationVariables>;
export const SetStepCoordinatesDocument = gql`
    mutation SetStepCoordinates($input: SetStepCoordinatesInput!) {
  setStepCoordinates(input: $input) {
    success
    code
    step {
      ...CanvasStep
    }
  }
}
    ${CanvasStepFragmentDoc}`;
export type SetStepCoordinatesMutationFn = Apollo.MutationFunction<SetStepCoordinatesMutation, SetStepCoordinatesMutationVariables>;

/**
 * __useSetStepCoordinatesMutation__
 *
 * To run a mutation, you first call `useSetStepCoordinatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetStepCoordinatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setStepCoordinatesMutation, { data, loading, error }] = useSetStepCoordinatesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetStepCoordinatesMutation(baseOptions?: Apollo.MutationHookOptions<SetStepCoordinatesMutation, SetStepCoordinatesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetStepCoordinatesMutation, SetStepCoordinatesMutationVariables>(SetStepCoordinatesDocument, options);
      }
export type SetStepCoordinatesMutationHookResult = ReturnType<typeof useSetStepCoordinatesMutation>;
export type SetStepCoordinatesMutationResult = Apollo.MutationResult<SetStepCoordinatesMutation>;
export type SetStepCoordinatesMutationOptions = Apollo.BaseMutationOptions<SetStepCoordinatesMutation, SetStepCoordinatesMutationVariables>;
export const SetStickyNoteCoordinatesDocument = gql`
    mutation SetStickyNoteCoordinates($input: SetStickyNoteCoordinatesInput!) {
  setStickyNoteCoordinates(input: $input) {
    success
    code
    stickyNote {
      ...CanvasStickyNote
    }
  }
}
    ${CanvasStickyNoteFragmentDoc}`;
export type SetStickyNoteCoordinatesMutationFn = Apollo.MutationFunction<SetStickyNoteCoordinatesMutation, SetStickyNoteCoordinatesMutationVariables>;

/**
 * __useSetStickyNoteCoordinatesMutation__
 *
 * To run a mutation, you first call `useSetStickyNoteCoordinatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetStickyNoteCoordinatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setStickyNoteCoordinatesMutation, { data, loading, error }] = useSetStickyNoteCoordinatesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetStickyNoteCoordinatesMutation(baseOptions?: Apollo.MutationHookOptions<SetStickyNoteCoordinatesMutation, SetStickyNoteCoordinatesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetStickyNoteCoordinatesMutation, SetStickyNoteCoordinatesMutationVariables>(SetStickyNoteCoordinatesDocument, options);
      }
export type SetStickyNoteCoordinatesMutationHookResult = ReturnType<typeof useSetStickyNoteCoordinatesMutation>;
export type SetStickyNoteCoordinatesMutationResult = Apollo.MutationResult<SetStickyNoteCoordinatesMutation>;
export type SetStickyNoteCoordinatesMutationOptions = Apollo.BaseMutationOptions<SetStickyNoteCoordinatesMutation, SetStickyNoteCoordinatesMutationVariables>;
export const SetTrackEndCoordinatesDocument = gql`
    mutation SetTrackEndCoordinates($input: SetTrackEndCoordinatesInput!) {
  setTrackEndCoordinates(input: $input) {
    success
    code
    track {
      id
      end_coordinates {
        x
        y
      }
    }
  }
}
    `;
export type SetTrackEndCoordinatesMutationFn = Apollo.MutationFunction<SetTrackEndCoordinatesMutation, SetTrackEndCoordinatesMutationVariables>;

/**
 * __useSetTrackEndCoordinatesMutation__
 *
 * To run a mutation, you first call `useSetTrackEndCoordinatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTrackEndCoordinatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTrackEndCoordinatesMutation, { data, loading, error }] = useSetTrackEndCoordinatesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetTrackEndCoordinatesMutation(baseOptions?: Apollo.MutationHookOptions<SetTrackEndCoordinatesMutation, SetTrackEndCoordinatesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetTrackEndCoordinatesMutation, SetTrackEndCoordinatesMutationVariables>(SetTrackEndCoordinatesDocument, options);
      }
export type SetTrackEndCoordinatesMutationHookResult = ReturnType<typeof useSetTrackEndCoordinatesMutation>;
export type SetTrackEndCoordinatesMutationResult = Apollo.MutationResult<SetTrackEndCoordinatesMutation>;
export type SetTrackEndCoordinatesMutationOptions = Apollo.BaseMutationOptions<SetTrackEndCoordinatesMutation, SetTrackEndCoordinatesMutationVariables>;
export const SetTrackStartCoordinatesDocument = gql`
    mutation SetTrackStartCoordinates($input: SetTrackStartCoordinatesInput!) {
  setTrackStartCoordinates(input: $input) {
    success
    code
    track {
      id
      start_coordinates {
        x
        y
      }
    }
  }
}
    `;
export type SetTrackStartCoordinatesMutationFn = Apollo.MutationFunction<SetTrackStartCoordinatesMutation, SetTrackStartCoordinatesMutationVariables>;

/**
 * __useSetTrackStartCoordinatesMutation__
 *
 * To run a mutation, you first call `useSetTrackStartCoordinatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTrackStartCoordinatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTrackStartCoordinatesMutation, { data, loading, error }] = useSetTrackStartCoordinatesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetTrackStartCoordinatesMutation(baseOptions?: Apollo.MutationHookOptions<SetTrackStartCoordinatesMutation, SetTrackStartCoordinatesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetTrackStartCoordinatesMutation, SetTrackStartCoordinatesMutationVariables>(SetTrackStartCoordinatesDocument, options);
      }
export type SetTrackStartCoordinatesMutationHookResult = ReturnType<typeof useSetTrackStartCoordinatesMutation>;
export type SetTrackStartCoordinatesMutationResult = Apollo.MutationResult<SetTrackStartCoordinatesMutation>;
export type SetTrackStartCoordinatesMutationOptions = Apollo.BaseMutationOptions<SetTrackStartCoordinatesMutation, SetTrackStartCoordinatesMutationVariables>;
export const StartTrackFromStepDocument = gql`
    mutation StartTrackFromStep($input: StartTrackFromStepInput!) {
  startTrackFromStep(input: $input) {
    success
    code
    transition {
      ...CanvasTransition
    }
  }
}
    ${CanvasTransitionFragmentDoc}`;
export type StartTrackFromStepMutationFn = Apollo.MutationFunction<StartTrackFromStepMutation, StartTrackFromStepMutationVariables>;

/**
 * __useStartTrackFromStepMutation__
 *
 * To run a mutation, you first call `useStartTrackFromStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartTrackFromStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startTrackFromStepMutation, { data, loading, error }] = useStartTrackFromStepMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartTrackFromStepMutation(baseOptions?: Apollo.MutationHookOptions<StartTrackFromStepMutation, StartTrackFromStepMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartTrackFromStepMutation, StartTrackFromStepMutationVariables>(StartTrackFromStepDocument, options);
      }
export type StartTrackFromStepMutationHookResult = ReturnType<typeof useStartTrackFromStepMutation>;
export type StartTrackFromStepMutationResult = Apollo.MutationResult<StartTrackFromStepMutation>;
export type StartTrackFromStepMutationOptions = Apollo.BaseMutationOptions<StartTrackFromStepMutation, StartTrackFromStepMutationVariables>;
export const TrackCanvasDocument = gql`
    query TrackCanvas($track_id: String!) {
  track(id: $track_id) {
    track {
      id
      title
      start_coordinates {
        x
        y
      }
      end_coordinates {
        x
        y
      }
      steps {
        ...CanvasStep
      }
      sticky_notes {
        ...CanvasStickyNote
      }
      transitions {
        ...CanvasTransition
      }
    }
  }
}
    ${CanvasStepFragmentDoc}
${CanvasStickyNoteFragmentDoc}
${CanvasTransitionFragmentDoc}`;

/**
 * __useTrackCanvasQuery__
 *
 * To run a query within a React component, call `useTrackCanvasQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrackCanvasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrackCanvasQuery({
 *   variables: {
 *      track_id: // value for 'track_id'
 *   },
 * });
 */
export function useTrackCanvasQuery(baseOptions: Apollo.QueryHookOptions<TrackCanvasQuery, TrackCanvasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TrackCanvasQuery, TrackCanvasQueryVariables>(TrackCanvasDocument, options);
      }
export function useTrackCanvasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TrackCanvasQuery, TrackCanvasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TrackCanvasQuery, TrackCanvasQueryVariables>(TrackCanvasDocument, options);
        }
export type TrackCanvasQueryHookResult = ReturnType<typeof useTrackCanvasQuery>;
export type TrackCanvasLazyQueryHookResult = ReturnType<typeof useTrackCanvasLazyQuery>;
export type TrackCanvasQueryResult = Apollo.QueryResult<TrackCanvasQuery, TrackCanvasQueryVariables>;
export const SetTriggerDataPointDefinitionDocument = gql`
    mutation SetTriggerDataPointDefinition($input: SetTriggerDataPointDefinitionInput!) {
  setTriggerDataPointDefinition(input: $input) {
    trigger {
      ...Trigger
    }
  }
}
    ${TriggerFragmentDoc}`;
export type SetTriggerDataPointDefinitionMutationFn = Apollo.MutationFunction<SetTriggerDataPointDefinitionMutation, SetTriggerDataPointDefinitionMutationVariables>;

/**
 * __useSetTriggerDataPointDefinitionMutation__
 *
 * To run a mutation, you first call `useSetTriggerDataPointDefinitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTriggerDataPointDefinitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTriggerDataPointDefinitionMutation, { data, loading, error }] = useSetTriggerDataPointDefinitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetTriggerDataPointDefinitionMutation(baseOptions?: Apollo.MutationHookOptions<SetTriggerDataPointDefinitionMutation, SetTriggerDataPointDefinitionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetTriggerDataPointDefinitionMutation, SetTriggerDataPointDefinitionMutationVariables>(SetTriggerDataPointDefinitionDocument, options);
      }
export type SetTriggerDataPointDefinitionMutationHookResult = ReturnType<typeof useSetTriggerDataPointDefinitionMutation>;
export type SetTriggerDataPointDefinitionMutationResult = Apollo.MutationResult<SetTriggerDataPointDefinitionMutation>;
export type SetTriggerDataPointDefinitionMutationOptions = Apollo.BaseMutationOptions<SetTriggerDataPointDefinitionMutation, SetTriggerDataPointDefinitionMutationVariables>;
export const SetTriggerStepDocument = gql`
    mutation SetTriggerStep($input: SetTriggerStepInput!) {
  setTriggerStep(input: $input) {
    trigger {
      ...Trigger
    }
  }
}
    ${TriggerFragmentDoc}`;
export type SetTriggerStepMutationFn = Apollo.MutationFunction<SetTriggerStepMutation, SetTriggerStepMutationVariables>;

/**
 * __useSetTriggerStepMutation__
 *
 * To run a mutation, you first call `useSetTriggerStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTriggerStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTriggerStepMutation, { data, loading, error }] = useSetTriggerStepMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetTriggerStepMutation(baseOptions?: Apollo.MutationHookOptions<SetTriggerStepMutation, SetTriggerStepMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetTriggerStepMutation, SetTriggerStepMutationVariables>(SetTriggerStepDocument, options);
      }
export type SetTriggerStepMutationHookResult = ReturnType<typeof useSetTriggerStepMutation>;
export type SetTriggerStepMutationResult = Apollo.MutationResult<SetTriggerStepMutation>;
export type SetTriggerStepMutationOptions = Apollo.BaseMutationOptions<SetTriggerStepMutation, SetTriggerStepMutationVariables>;
export const SetTriggerTrackDocument = gql`
    mutation SetTriggerTrack($input: SetTriggerTrackInput!) {
  setTriggerTrack(input: $input) {
    trigger {
      ...Trigger
    }
  }
}
    ${TriggerFragmentDoc}`;
export type SetTriggerTrackMutationFn = Apollo.MutationFunction<SetTriggerTrackMutation, SetTriggerTrackMutationVariables>;

/**
 * __useSetTriggerTrackMutation__
 *
 * To run a mutation, you first call `useSetTriggerTrackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTriggerTrackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTriggerTrackMutation, { data, loading, error }] = useSetTriggerTrackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetTriggerTrackMutation(baseOptions?: Apollo.MutationHookOptions<SetTriggerTrackMutation, SetTriggerTrackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetTriggerTrackMutation, SetTriggerTrackMutationVariables>(SetTriggerTrackDocument, options);
      }
export type SetTriggerTrackMutationHookResult = ReturnType<typeof useSetTriggerTrackMutation>;
export type SetTriggerTrackMutationResult = Apollo.MutationResult<SetTriggerTrackMutation>;
export type SetTriggerTrackMutationOptions = Apollo.BaseMutationOptions<SetTriggerTrackMutation, SetTriggerTrackMutationVariables>;
export const SetTriggerTypeDocument = gql`
    mutation SetTriggerType($input: SetTriggerTypeInput!) {
  setTriggerType(input: $input) {
    trigger {
      ...Trigger
    }
  }
}
    ${TriggerFragmentDoc}`;
export type SetTriggerTypeMutationFn = Apollo.MutationFunction<SetTriggerTypeMutation, SetTriggerTypeMutationVariables>;

/**
 * __useSetTriggerTypeMutation__
 *
 * To run a mutation, you first call `useSetTriggerTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTriggerTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTriggerTypeMutation, { data, loading, error }] = useSetTriggerTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetTriggerTypeMutation(baseOptions?: Apollo.MutationHookOptions<SetTriggerTypeMutation, SetTriggerTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetTriggerTypeMutation, SetTriggerTypeMutationVariables>(SetTriggerTypeDocument, options);
      }
export type SetTriggerTypeMutationHookResult = ReturnType<typeof useSetTriggerTypeMutation>;
export type SetTriggerTypeMutationResult = Apollo.MutationResult<SetTriggerTypeMutation>;
export type SetTriggerTypeMutationOptions = Apollo.BaseMutationOptions<SetTriggerTypeMutation, SetTriggerTypeMutationVariables>;
export const AddTriggerTimerDocument = gql`
    mutation AddTriggerTimer($input: AddTriggerTimerInput!) {
  addTriggerTimer(input: $input) {
    trigger {
      id
      timers {
        ...DesignatedTriggerTimer
      }
    }
  }
}
    ${DesignatedTriggerTimerFragmentDoc}`;
export type AddTriggerTimerMutationFn = Apollo.MutationFunction<AddTriggerTimerMutation, AddTriggerTimerMutationVariables>;

/**
 * __useAddTriggerTimerMutation__
 *
 * To run a mutation, you first call `useAddTriggerTimerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTriggerTimerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTriggerTimerMutation, { data, loading, error }] = useAddTriggerTimerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTriggerTimerMutation(baseOptions?: Apollo.MutationHookOptions<AddTriggerTimerMutation, AddTriggerTimerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTriggerTimerMutation, AddTriggerTimerMutationVariables>(AddTriggerTimerDocument, options);
      }
export type AddTriggerTimerMutationHookResult = ReturnType<typeof useAddTriggerTimerMutation>;
export type AddTriggerTimerMutationResult = Apollo.MutationResult<AddTriggerTimerMutation>;
export type AddTriggerTimerMutationOptions = Apollo.BaseMutationOptions<AddTriggerTimerMutation, AddTriggerTimerMutationVariables>;
export const RemoveTriggerTimerDocument = gql`
    mutation RemoveTriggerTimer($input: RemoveTriggerTimerInput!) {
  removeTriggerTimer(input: $input) {
    success
    trigger {
      id
      timers {
        ...DesignatedTriggerTimer
      }
    }
  }
}
    ${DesignatedTriggerTimerFragmentDoc}`;
export type RemoveTriggerTimerMutationFn = Apollo.MutationFunction<RemoveTriggerTimerMutation, RemoveTriggerTimerMutationVariables>;

/**
 * __useRemoveTriggerTimerMutation__
 *
 * To run a mutation, you first call `useRemoveTriggerTimerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTriggerTimerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTriggerTimerMutation, { data, loading, error }] = useRemoveTriggerTimerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveTriggerTimerMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTriggerTimerMutation, RemoveTriggerTimerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveTriggerTimerMutation, RemoveTriggerTimerMutationVariables>(RemoveTriggerTimerDocument, options);
      }
export type RemoveTriggerTimerMutationHookResult = ReturnType<typeof useRemoveTriggerTimerMutation>;
export type RemoveTriggerTimerMutationResult = Apollo.MutationResult<RemoveTriggerTimerMutation>;
export type RemoveTriggerTimerMutationOptions = Apollo.BaseMutationOptions<RemoveTriggerTimerMutation, RemoveTriggerTimerMutationVariables>;
export const SetTriggerTimerDataPointDefinitionDocument = gql`
    mutation SetTriggerTimerDataPointDefinition($input: SetTriggerTimerDataPointDefinitionInput!) {
  setTriggerTimerDataPointDefinition(input: $input) {
    success
    trigger {
      id
      timers {
        ...DesignatedTriggerTimer
      }
    }
  }
}
    ${DesignatedTriggerTimerFragmentDoc}`;
export type SetTriggerTimerDataPointDefinitionMutationFn = Apollo.MutationFunction<SetTriggerTimerDataPointDefinitionMutation, SetTriggerTimerDataPointDefinitionMutationVariables>;

/**
 * __useSetTriggerTimerDataPointDefinitionMutation__
 *
 * To run a mutation, you first call `useSetTriggerTimerDataPointDefinitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTriggerTimerDataPointDefinitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTriggerTimerDataPointDefinitionMutation, { data, loading, error }] = useSetTriggerTimerDataPointDefinitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetTriggerTimerDataPointDefinitionMutation(baseOptions?: Apollo.MutationHookOptions<SetTriggerTimerDataPointDefinitionMutation, SetTriggerTimerDataPointDefinitionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetTriggerTimerDataPointDefinitionMutation, SetTriggerTimerDataPointDefinitionMutationVariables>(SetTriggerTimerDataPointDefinitionDocument, options);
      }
export type SetTriggerTimerDataPointDefinitionMutationHookResult = ReturnType<typeof useSetTriggerTimerDataPointDefinitionMutation>;
export type SetTriggerTimerDataPointDefinitionMutationResult = Apollo.MutationResult<SetTriggerTimerDataPointDefinitionMutation>;
export type SetTriggerTimerDataPointDefinitionMutationOptions = Apollo.BaseMutationOptions<SetTriggerTimerDataPointDefinitionMutation, SetTriggerTimerDataPointDefinitionMutationVariables>;
export const SetTriggerTimerDelayDocument = gql`
    mutation SetTriggerTimerDelay($input: SetTriggerDelayInput!) {
  setTriggerDelay(input: $input) {
    success
    trigger {
      id
      timers {
        ...DesignatedTriggerTimer
      }
    }
  }
}
    ${DesignatedTriggerTimerFragmentDoc}`;
export type SetTriggerTimerDelayMutationFn = Apollo.MutationFunction<SetTriggerTimerDelayMutation, SetTriggerTimerDelayMutationVariables>;

/**
 * __useSetTriggerTimerDelayMutation__
 *
 * To run a mutation, you first call `useSetTriggerTimerDelayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTriggerTimerDelayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTriggerTimerDelayMutation, { data, loading, error }] = useSetTriggerTimerDelayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetTriggerTimerDelayMutation(baseOptions?: Apollo.MutationHookOptions<SetTriggerTimerDelayMutation, SetTriggerTimerDelayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetTriggerTimerDelayMutation, SetTriggerTimerDelayMutationVariables>(SetTriggerTimerDelayDocument, options);
      }
export type SetTriggerTimerDelayMutationHookResult = ReturnType<typeof useSetTriggerTimerDelayMutation>;
export type SetTriggerTimerDelayMutationResult = Apollo.MutationResult<SetTriggerTimerDelayMutation>;
export type SetTriggerTimerDelayMutationOptions = Apollo.BaseMutationOptions<SetTriggerTimerDelayMutation, SetTriggerTimerDelayMutationVariables>;
export const SetTriggerTimerStepDocument = gql`
    mutation SetTriggerTimerStep($input: SetTriggerTimerStepInput!) {
  setTriggerTimerStep(input: $input) {
    success
    trigger {
      id
      timers {
        ...DesignatedTriggerTimer
      }
    }
  }
}
    ${DesignatedTriggerTimerFragmentDoc}`;
export type SetTriggerTimerStepMutationFn = Apollo.MutationFunction<SetTriggerTimerStepMutation, SetTriggerTimerStepMutationVariables>;

/**
 * __useSetTriggerTimerStepMutation__
 *
 * To run a mutation, you first call `useSetTriggerTimerStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTriggerTimerStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTriggerTimerStepMutation, { data, loading, error }] = useSetTriggerTimerStepMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetTriggerTimerStepMutation(baseOptions?: Apollo.MutationHookOptions<SetTriggerTimerStepMutation, SetTriggerTimerStepMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetTriggerTimerStepMutation, SetTriggerTimerStepMutationVariables>(SetTriggerTimerStepDocument, options);
      }
export type SetTriggerTimerStepMutationHookResult = ReturnType<typeof useSetTriggerTimerStepMutation>;
export type SetTriggerTimerStepMutationResult = Apollo.MutationResult<SetTriggerTimerStepMutation>;
export type SetTriggerTimerStepMutationOptions = Apollo.BaseMutationOptions<SetTriggerTimerStepMutation, SetTriggerTimerStepMutationVariables>;
export const SetTriggerTimerTrackDocument = gql`
    mutation SetTriggerTimerTrack($input: SetTriggerTimerTrackInput!) {
  setTriggerTimerTrack(input: $input) {
    success
    trigger {
      id
      timers {
        ...DesignatedTriggerTimer
      }
    }
  }
}
    ${DesignatedTriggerTimerFragmentDoc}`;
export type SetTriggerTimerTrackMutationFn = Apollo.MutationFunction<SetTriggerTimerTrackMutation, SetTriggerTimerTrackMutationVariables>;

/**
 * __useSetTriggerTimerTrackMutation__
 *
 * To run a mutation, you first call `useSetTriggerTimerTrackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTriggerTimerTrackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTriggerTimerTrackMutation, { data, loading, error }] = useSetTriggerTimerTrackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetTriggerTimerTrackMutation(baseOptions?: Apollo.MutationHookOptions<SetTriggerTimerTrackMutation, SetTriggerTimerTrackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetTriggerTimerTrackMutation, SetTriggerTimerTrackMutationVariables>(SetTriggerTimerTrackDocument, options);
      }
export type SetTriggerTimerTrackMutationHookResult = ReturnType<typeof useSetTriggerTimerTrackMutation>;
export type SetTriggerTimerTrackMutationResult = Apollo.MutationResult<SetTriggerTimerTrackMutation>;
export type SetTriggerTimerTrackMutationOptions = Apollo.BaseMutationOptions<SetTriggerTimerTrackMutation, SetTriggerTimerTrackMutationVariables>;
export const SetTriggerTimerTypeDocument = gql`
    mutation SetTriggerTimerType($input: SetTriggerTimerTypeInput!) {
  setTriggerTimerType(input: $input) {
    success
    trigger {
      id
      timers {
        ...DesignatedTriggerTimer
      }
    }
  }
}
    ${DesignatedTriggerTimerFragmentDoc}`;
export type SetTriggerTimerTypeMutationFn = Apollo.MutationFunction<SetTriggerTimerTypeMutation, SetTriggerTimerTypeMutationVariables>;

/**
 * __useSetTriggerTimerTypeMutation__
 *
 * To run a mutation, you first call `useSetTriggerTimerTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTriggerTimerTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTriggerTimerTypeMutation, { data, loading, error }] = useSetTriggerTimerTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetTriggerTimerTypeMutation(baseOptions?: Apollo.MutationHookOptions<SetTriggerTimerTypeMutation, SetTriggerTimerTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetTriggerTimerTypeMutation, SetTriggerTimerTypeMutationVariables>(SetTriggerTimerTypeDocument, options);
      }
export type SetTriggerTimerTypeMutationHookResult = ReturnType<typeof useSetTriggerTimerTypeMutation>;
export type SetTriggerTimerTypeMutationResult = Apollo.MutationResult<SetTriggerTimerTypeMutation>;
export type SetTriggerTimerTypeMutationOptions = Apollo.BaseMutationOptions<SetTriggerTimerTypeMutation, SetTriggerTimerTypeMutationVariables>;
export const UpdateDataPointDefinitionDocument = gql`
    mutation UpdateDataPointDefinition($input: UpdateDataPointDefinitionInput!) {
  updateDataPointDefinition(input: $input) {
    success
    data_point_definition {
      ...DataPointDefinition
    }
  }
}
    ${DataPointDefinitionFragmentDoc}`;
export type UpdateDataPointDefinitionMutationFn = Apollo.MutationFunction<UpdateDataPointDefinitionMutation, UpdateDataPointDefinitionMutationVariables>;

/**
 * __useUpdateDataPointDefinitionMutation__
 *
 * To run a mutation, you first call `useUpdateDataPointDefinitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDataPointDefinitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDataPointDefinitionMutation, { data, loading, error }] = useUpdateDataPointDefinitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDataPointDefinitionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDataPointDefinitionMutation, UpdateDataPointDefinitionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDataPointDefinitionMutation, UpdateDataPointDefinitionMutationVariables>(UpdateDataPointDefinitionDocument, options);
      }
export type UpdateDataPointDefinitionMutationHookResult = ReturnType<typeof useUpdateDataPointDefinitionMutation>;
export type UpdateDataPointDefinitionMutationResult = Apollo.MutationResult<UpdateDataPointDefinitionMutation>;
export type UpdateDataPointDefinitionMutationOptions = Apollo.BaseMutationOptions<UpdateDataPointDefinitionMutation, UpdateDataPointDefinitionMutationVariables>;
export const UpdateCareFlowApiCallRetrySettingsDocument = gql`
    mutation UpdateCareFlowApiCallRetrySettings($input: UpdateCareFlowApiCallRetrySettingsInput!) {
  updateCareFlowApiCallRetrySettings(input: $input) {
    settings {
      ...PathwaySettings
    }
  }
}
    ${PathwaySettingsFragmentDoc}`;
export type UpdateCareFlowApiCallRetrySettingsMutationFn = Apollo.MutationFunction<UpdateCareFlowApiCallRetrySettingsMutation, UpdateCareFlowApiCallRetrySettingsMutationVariables>;

/**
 * __useUpdateCareFlowApiCallRetrySettingsMutation__
 *
 * To run a mutation, you first call `useUpdateCareFlowApiCallRetrySettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCareFlowApiCallRetrySettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCareFlowApiCallRetrySettingsMutation, { data, loading, error }] = useUpdateCareFlowApiCallRetrySettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCareFlowApiCallRetrySettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCareFlowApiCallRetrySettingsMutation, UpdateCareFlowApiCallRetrySettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCareFlowApiCallRetrySettingsMutation, UpdateCareFlowApiCallRetrySettingsMutationVariables>(UpdateCareFlowApiCallRetrySettingsDocument, options);
      }
export type UpdateCareFlowApiCallRetrySettingsMutationHookResult = ReturnType<typeof useUpdateCareFlowApiCallRetrySettingsMutation>;
export type UpdateCareFlowApiCallRetrySettingsMutationResult = Apollo.MutationResult<UpdateCareFlowApiCallRetrySettingsMutation>;
export type UpdateCareFlowApiCallRetrySettingsMutationOptions = Apollo.BaseMutationOptions<UpdateCareFlowApiCallRetrySettingsMutation, UpdateCareFlowApiCallRetrySettingsMutationVariables>;
export const UpdateCareFlowSourceControlSettingsDocument = gql`
    mutation UpdateCareFlowSourceControlSettings($input: UpdateCareFlowSourceControlSettingsInput!) {
  updateCareFlowSourceControlSettings(input: $input) {
    settings {
      source_control {
        repo_url
        access_token
        branch_name
      }
    }
  }
}
    `;
export type UpdateCareFlowSourceControlSettingsMutationFn = Apollo.MutationFunction<UpdateCareFlowSourceControlSettingsMutation, UpdateCareFlowSourceControlSettingsMutationVariables>;

/**
 * __useUpdateCareFlowSourceControlSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateCareFlowSourceControlSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCareFlowSourceControlSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCareFlowSourceControlSettingsMutation, { data, loading, error }] = useUpdateCareFlowSourceControlSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCareFlowSourceControlSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCareFlowSourceControlSettingsMutation, UpdateCareFlowSourceControlSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCareFlowSourceControlSettingsMutation, UpdateCareFlowSourceControlSettingsMutationVariables>(UpdateCareFlowSourceControlSettingsDocument, options);
      }
export type UpdateCareFlowSourceControlSettingsMutationHookResult = ReturnType<typeof useUpdateCareFlowSourceControlSettingsMutation>;
export type UpdateCareFlowSourceControlSettingsMutationResult = Apollo.MutationResult<UpdateCareFlowSourceControlSettingsMutation>;
export type UpdateCareFlowSourceControlSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateCareFlowSourceControlSettingsMutation, UpdateCareFlowSourceControlSettingsMutationVariables>;
export const UpdateConstantDocument = gql`
    mutation UpdateConstant($input: UpdateConstantInput!) {
  updateConstant(input: $input) {
    success
    pathway {
      id
      settings {
        constants {
          ...Constant
        }
      }
    }
  }
}
    ${ConstantFragmentDoc}`;
export type UpdateConstantMutationFn = Apollo.MutationFunction<UpdateConstantMutation, UpdateConstantMutationVariables>;

/**
 * __useUpdateConstantMutation__
 *
 * To run a mutation, you first call `useUpdateConstantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConstantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConstantMutation, { data, loading, error }] = useUpdateConstantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateConstantMutation(baseOptions?: Apollo.MutationHookOptions<UpdateConstantMutation, UpdateConstantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateConstantMutation, UpdateConstantMutationVariables>(UpdateConstantDocument, options);
      }
export type UpdateConstantMutationHookResult = ReturnType<typeof useUpdateConstantMutation>;
export type UpdateConstantMutationResult = Apollo.MutationResult<UpdateConstantMutation>;
export type UpdateConstantMutationOptions = Apollo.BaseMutationOptions<UpdateConstantMutation, UpdateConstantMutationVariables>;
export const UpdateDynamicVariableDocument = gql`
    mutation UpdateDynamicVariable($input: UpdateDynamicVariableInput!) {
  updateDynamicVariable(input: $input) {
    success
    dynamic_variable {
      ...DynamicVariable
    }
  }
}
    ${DynamicVariableFragmentDoc}`;
export type UpdateDynamicVariableMutationFn = Apollo.MutationFunction<UpdateDynamicVariableMutation, UpdateDynamicVariableMutationVariables>;

/**
 * __useUpdateDynamicVariableMutation__
 *
 * To run a mutation, you first call `useUpdateDynamicVariableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDynamicVariableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDynamicVariableMutation, { data, loading, error }] = useUpdateDynamicVariableMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDynamicVariableMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDynamicVariableMutation, UpdateDynamicVariableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDynamicVariableMutation, UpdateDynamicVariableMutationVariables>(UpdateDynamicVariableDocument, options);
      }
export type UpdateDynamicVariableMutationHookResult = ReturnType<typeof useUpdateDynamicVariableMutation>;
export type UpdateDynamicVariableMutationResult = Apollo.MutationResult<UpdateDynamicVariableMutation>;
export type UpdateDynamicVariableMutationOptions = Apollo.BaseMutationOptions<UpdateDynamicVariableMutation, UpdateDynamicVariableMutationVariables>;
export const SetDateQuestionConfigDocument = gql`
    mutation SetDateQuestionConfig($input: SetDateQuestionConfigInput!) {
  setDateQuestionConfig(input: $input) {
    question {
      id
      questionConfig {
        date {
          allowed_dates
          include_date_of_response
        }
      }
    }
  }
}
    `;
export type SetDateQuestionConfigMutationFn = Apollo.MutationFunction<SetDateQuestionConfigMutation, SetDateQuestionConfigMutationVariables>;

/**
 * __useSetDateQuestionConfigMutation__
 *
 * To run a mutation, you first call `useSetDateQuestionConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDateQuestionConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDateQuestionConfigMutation, { data, loading, error }] = useSetDateQuestionConfigMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetDateQuestionConfigMutation(baseOptions?: Apollo.MutationHookOptions<SetDateQuestionConfigMutation, SetDateQuestionConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetDateQuestionConfigMutation, SetDateQuestionConfigMutationVariables>(SetDateQuestionConfigDocument, options);
      }
export type SetDateQuestionConfigMutationHookResult = ReturnType<typeof useSetDateQuestionConfigMutation>;
export type SetDateQuestionConfigMutationResult = Apollo.MutationResult<SetDateQuestionConfigMutation>;
export type SetDateQuestionConfigMutationOptions = Apollo.BaseMutationOptions<SetDateQuestionConfigMutation, SetDateQuestionConfigMutationVariables>;
export const SetMultipleSelectQuestionConfigDocument = gql`
    mutation SetMultipleSelectQuestionConfig($input: SetMultipleSelectQuestionConfigInput!) {
  setMultipleSelectQuestionConfig(input: $input) {
    question {
      id
      questionConfig {
        multiple_select {
          range {
            min
            max
            enabled
          }
          exclusive_option {
            option_id
            enabled
          }
        }
      }
    }
  }
}
    `;
export type SetMultipleSelectQuestionConfigMutationFn = Apollo.MutationFunction<SetMultipleSelectQuestionConfigMutation, SetMultipleSelectQuestionConfigMutationVariables>;

/**
 * __useSetMultipleSelectQuestionConfigMutation__
 *
 * To run a mutation, you first call `useSetMultipleSelectQuestionConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMultipleSelectQuestionConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMultipleSelectQuestionConfigMutation, { data, loading, error }] = useSetMultipleSelectQuestionConfigMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetMultipleSelectQuestionConfigMutation(baseOptions?: Apollo.MutationHookOptions<SetMultipleSelectQuestionConfigMutation, SetMultipleSelectQuestionConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetMultipleSelectQuestionConfigMutation, SetMultipleSelectQuestionConfigMutationVariables>(SetMultipleSelectQuestionConfigDocument, options);
      }
export type SetMultipleSelectQuestionConfigMutationHookResult = ReturnType<typeof useSetMultipleSelectQuestionConfigMutation>;
export type SetMultipleSelectQuestionConfigMutationResult = Apollo.MutationResult<SetMultipleSelectQuestionConfigMutation>;
export type SetMultipleSelectQuestionConfigMutationOptions = Apollo.BaseMutationOptions<SetMultipleSelectQuestionConfigMutation, SetMultipleSelectQuestionConfigMutationVariables>;
export const SetNumberQuestionConfigDocument = gql`
    mutation SetNumberQuestionConfig($input: SetNumberQuestionConfigInput!) {
  setNumberQuestionConfig(input: $input) {
    question {
      id
      questionConfig {
        number {
          range {
            min
            max
            enabled
          }
        }
      }
    }
  }
}
    `;
export type SetNumberQuestionConfigMutationFn = Apollo.MutationFunction<SetNumberQuestionConfigMutation, SetNumberQuestionConfigMutationVariables>;

/**
 * __useSetNumberQuestionConfigMutation__
 *
 * To run a mutation, you first call `useSetNumberQuestionConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetNumberQuestionConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setNumberQuestionConfigMutation, { data, loading, error }] = useSetNumberQuestionConfigMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetNumberQuestionConfigMutation(baseOptions?: Apollo.MutationHookOptions<SetNumberQuestionConfigMutation, SetNumberQuestionConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetNumberQuestionConfigMutation, SetNumberQuestionConfigMutationVariables>(SetNumberQuestionConfigDocument, options);
      }
export type SetNumberQuestionConfigMutationHookResult = ReturnType<typeof useSetNumberQuestionConfigMutation>;
export type SetNumberQuestionConfigMutationResult = Apollo.MutationResult<SetNumberQuestionConfigMutation>;
export type SetNumberQuestionConfigMutationOptions = Apollo.BaseMutationOptions<SetNumberQuestionConfigMutation, SetNumberQuestionConfigMutationVariables>;
export const SetPhoneQuestionConfigDocument = gql`
    mutation SetPhoneQuestionConfig($input: SetPhoneQuestionConfigInput!) {
  setPhoneQuestionConfig(input: $input) {
    question {
      id
      questionConfig {
        phone {
          default_country
          available_countries
        }
      }
    }
  }
}
    `;
export type SetPhoneQuestionConfigMutationFn = Apollo.MutationFunction<SetPhoneQuestionConfigMutation, SetPhoneQuestionConfigMutationVariables>;

/**
 * __useSetPhoneQuestionConfigMutation__
 *
 * To run a mutation, you first call `useSetPhoneQuestionConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPhoneQuestionConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPhoneQuestionConfigMutation, { data, loading, error }] = useSetPhoneQuestionConfigMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetPhoneQuestionConfigMutation(baseOptions?: Apollo.MutationHookOptions<SetPhoneQuestionConfigMutation, SetPhoneQuestionConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetPhoneQuestionConfigMutation, SetPhoneQuestionConfigMutationVariables>(SetPhoneQuestionConfigDocument, options);
      }
export type SetPhoneQuestionConfigMutationHookResult = ReturnType<typeof useSetPhoneQuestionConfigMutation>;
export type SetPhoneQuestionConfigMutationResult = Apollo.MutationResult<SetPhoneQuestionConfigMutation>;
export type SetPhoneQuestionConfigMutationOptions = Apollo.BaseMutationOptions<SetPhoneQuestionConfigMutation, SetPhoneQuestionConfigMutationVariables>;
export const SetQuestionConfigDocument = gql`
    mutation SetQuestionConfig($input: SetQuestionConfigInput!) {
  setQuestionConfig(input: $input) {
    question {
      id
      questionConfig {
        mandatory
        use_select
      }
    }
  }
}
    `;
export type SetQuestionConfigMutationFn = Apollo.MutationFunction<SetQuestionConfigMutation, SetQuestionConfigMutationVariables>;

/**
 * __useSetQuestionConfigMutation__
 *
 * To run a mutation, you first call `useSetQuestionConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetQuestionConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setQuestionConfigMutation, { data, loading, error }] = useSetQuestionConfigMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetQuestionConfigMutation(baseOptions?: Apollo.MutationHookOptions<SetQuestionConfigMutation, SetQuestionConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetQuestionConfigMutation, SetQuestionConfigMutationVariables>(SetQuestionConfigDocument, options);
      }
export type SetQuestionConfigMutationHookResult = ReturnType<typeof useSetQuestionConfigMutation>;
export type SetQuestionConfigMutationResult = Apollo.MutationResult<SetQuestionConfigMutation>;
export type SetQuestionConfigMutationOptions = Apollo.BaseMutationOptions<SetQuestionConfigMutation, SetQuestionConfigMutationVariables>;
export const SetQuestionKeyDocument = gql`
    mutation SetQuestionKey($input: SetQuestionKeyInput!) {
  setQuestionKey(input: $input) {
    question {
      id
      key
    }
  }
}
    `;
export type SetQuestionKeyMutationFn = Apollo.MutationFunction<SetQuestionKeyMutation, SetQuestionKeyMutationVariables>;

/**
 * __useSetQuestionKeyMutation__
 *
 * To run a mutation, you first call `useSetQuestionKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetQuestionKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setQuestionKeyMutation, { data, loading, error }] = useSetQuestionKeyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetQuestionKeyMutation(baseOptions?: Apollo.MutationHookOptions<SetQuestionKeyMutation, SetQuestionKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetQuestionKeyMutation, SetQuestionKeyMutationVariables>(SetQuestionKeyDocument, options);
      }
export type SetQuestionKeyMutationHookResult = ReturnType<typeof useSetQuestionKeyMutation>;
export type SetQuestionKeyMutationResult = Apollo.MutationResult<SetQuestionKeyMutation>;
export type SetQuestionKeyMutationOptions = Apollo.BaseMutationOptions<SetQuestionKeyMutation, SetQuestionKeyMutationVariables>;
export const SetQuestionMetadataDocument = gql`
    mutation SetQuestionMetadata($input: SetQuestionMetadataInput!) {
  setQuestionMetadata(input: $input) {
    question {
      id
      metadata {
        plaintext
        slate
      }
    }
  }
}
    `;
export type SetQuestionMetadataMutationFn = Apollo.MutationFunction<SetQuestionMetadataMutation, SetQuestionMetadataMutationVariables>;

/**
 * __useSetQuestionMetadataMutation__
 *
 * To run a mutation, you first call `useSetQuestionMetadataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetQuestionMetadataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setQuestionMetadataMutation, { data, loading, error }] = useSetQuestionMetadataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetQuestionMetadataMutation(baseOptions?: Apollo.MutationHookOptions<SetQuestionMetadataMutation, SetQuestionMetadataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetQuestionMetadataMutation, SetQuestionMetadataMutationVariables>(SetQuestionMetadataDocument, options);
      }
export type SetQuestionMetadataMutationHookResult = ReturnType<typeof useSetQuestionMetadataMutation>;
export type SetQuestionMetadataMutationResult = Apollo.MutationResult<SetQuestionMetadataMutation>;
export type SetQuestionMetadataMutationOptions = Apollo.BaseMutationOptions<SetQuestionMetadataMutation, SetQuestionMetadataMutationVariables>;
export const SetQuestionOptionValueTypeDocument = gql`
    mutation SetQuestionOptionValueType($input: SetQuestionOptionValueTypeInput!) {
  setQuestionOptionValueType(input: $input) {
    question {
      id
      dataPointValueType
      options {
        id
        value
        label
      }
    }
  }
}
    `;
export type SetQuestionOptionValueTypeMutationFn = Apollo.MutationFunction<SetQuestionOptionValueTypeMutation, SetQuestionOptionValueTypeMutationVariables>;

/**
 * __useSetQuestionOptionValueTypeMutation__
 *
 * To run a mutation, you first call `useSetQuestionOptionValueTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetQuestionOptionValueTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setQuestionOptionValueTypeMutation, { data, loading, error }] = useSetQuestionOptionValueTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetQuestionOptionValueTypeMutation(baseOptions?: Apollo.MutationHookOptions<SetQuestionOptionValueTypeMutation, SetQuestionOptionValueTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetQuestionOptionValueTypeMutation, SetQuestionOptionValueTypeMutationVariables>(SetQuestionOptionValueTypeDocument, options);
      }
export type SetQuestionOptionValueTypeMutationHookResult = ReturnType<typeof useSetQuestionOptionValueTypeMutation>;
export type SetQuestionOptionValueTypeMutationResult = Apollo.MutationResult<SetQuestionOptionValueTypeMutation>;
export type SetQuestionOptionValueTypeMutationOptions = Apollo.BaseMutationOptions<SetQuestionOptionValueTypeMutation, SetQuestionOptionValueTypeMutationVariables>;
export const SetQuestionsConfigDocument = gql`
    mutation SetQuestionsConfig($input: SetQuestionsConfigInput!) {
  setQuestionsConfig(input: $input) {
    questions {
      id
      questionConfig {
        mandatory
        use_select
      }
    }
  }
}
    `;
export type SetQuestionsConfigMutationFn = Apollo.MutationFunction<SetQuestionsConfigMutation, SetQuestionsConfigMutationVariables>;

/**
 * __useSetQuestionsConfigMutation__
 *
 * To run a mutation, you first call `useSetQuestionsConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetQuestionsConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setQuestionsConfigMutation, { data, loading, error }] = useSetQuestionsConfigMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetQuestionsConfigMutation(baseOptions?: Apollo.MutationHookOptions<SetQuestionsConfigMutation, SetQuestionsConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetQuestionsConfigMutation, SetQuestionsConfigMutationVariables>(SetQuestionsConfigDocument, options);
      }
export type SetQuestionsConfigMutationHookResult = ReturnType<typeof useSetQuestionsConfigMutation>;
export type SetQuestionsConfigMutationResult = Apollo.MutationResult<SetQuestionsConfigMutation>;
export type SetQuestionsConfigMutationOptions = Apollo.BaseMutationOptions<SetQuestionsConfigMutation, SetQuestionsConfigMutationVariables>;
export const SetSliderQuestionConfigDocument = gql`
    mutation SetSliderQuestionConfig($input: SetSliderQuestionConfigInput!) {
  setSliderQuestionConfig(input: $input) {
    question {
      id
      questionConfig {
        slider {
          min
          max
          step_value
          display_marks
          min_label
          max_label
          is_value_tooltip_on
          show_min_max_values
        }
      }
    }
  }
}
    `;
export type SetSliderQuestionConfigMutationFn = Apollo.MutationFunction<SetSliderQuestionConfigMutation, SetSliderQuestionConfigMutationVariables>;

/**
 * __useSetSliderQuestionConfigMutation__
 *
 * To run a mutation, you first call `useSetSliderQuestionConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSliderQuestionConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSliderQuestionConfigMutation, { data, loading, error }] = useSetSliderQuestionConfigMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetSliderQuestionConfigMutation(baseOptions?: Apollo.MutationHookOptions<SetSliderQuestionConfigMutation, SetSliderQuestionConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetSliderQuestionConfigMutation, SetSliderQuestionConfigMutationVariables>(SetSliderQuestionConfigDocument, options);
      }
export type SetSliderQuestionConfigMutationHookResult = ReturnType<typeof useSetSliderQuestionConfigMutation>;
export type SetSliderQuestionConfigMutationResult = Apollo.MutationResult<SetSliderQuestionConfigMutation>;
export type SetSliderQuestionConfigMutationOptions = Apollo.BaseMutationOptions<SetSliderQuestionConfigMutation, SetSliderQuestionConfigMutationVariables>;
export const UpdateStickyNoteBodyDocument = gql`
    mutation UpdateStickyNoteBody($input: UpdateStickyNoteBodyInput!) {
  updateStickyNoteBody(input: $input) {
    success
    code
    stickyNote {
      id
      body {
        format
        text
      }
    }
  }
}
    `;
export type UpdateStickyNoteBodyMutationFn = Apollo.MutationFunction<UpdateStickyNoteBodyMutation, UpdateStickyNoteBodyMutationVariables>;

/**
 * __useUpdateStickyNoteBodyMutation__
 *
 * To run a mutation, you first call `useUpdateStickyNoteBodyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStickyNoteBodyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStickyNoteBodyMutation, { data, loading, error }] = useUpdateStickyNoteBodyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStickyNoteBodyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStickyNoteBodyMutation, UpdateStickyNoteBodyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStickyNoteBodyMutation, UpdateStickyNoteBodyMutationVariables>(UpdateStickyNoteBodyDocument, options);
      }
export type UpdateStickyNoteBodyMutationHookResult = ReturnType<typeof useUpdateStickyNoteBodyMutation>;
export type UpdateStickyNoteBodyMutationResult = Apollo.MutationResult<UpdateStickyNoteBodyMutation>;
export type UpdateStickyNoteBodyMutationOptions = Apollo.BaseMutationOptions<UpdateStickyNoteBodyMutation, UpdateStickyNoteBodyMutationVariables>;
export const UpdateWebhookDocument = gql`
    mutation UpdateWebhook($input: UpdateWebhookInput!) {
  updateWebhook(input: $input) {
    pathway {
      settings {
        webhooks {
          id
          enabled
          name
          subscribed_events
        }
      }
    }
  }
}
    `;
export type UpdateWebhookMutationFn = Apollo.MutationFunction<UpdateWebhookMutation, UpdateWebhookMutationVariables>;

/**
 * __useUpdateWebhookMutation__
 *
 * To run a mutation, you first call `useUpdateWebhookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWebhookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWebhookMutation, { data, loading, error }] = useUpdateWebhookMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWebhookMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWebhookMutation, UpdateWebhookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWebhookMutation, UpdateWebhookMutationVariables>(UpdateWebhookDocument, options);
      }
export type UpdateWebhookMutationHookResult = ReturnType<typeof useUpdateWebhookMutation>;
export type UpdateWebhookMutationResult = Apollo.MutationResult<UpdateWebhookMutation>;
export type UpdateWebhookMutationOptions = Apollo.BaseMutationOptions<UpdateWebhookMutation, UpdateWebhookMutationVariables>;
export const UpdateWebhookEndpointDocument = gql`
    mutation UpdateWebhookEndpoint($input: UpdateWebhookEndpointInput!) {
  updateWebhookEndpoint(input: $input) {
    pathway {
      settings {
        webhooks {
          id
          endpoint
          endpoint_test
          headers {
            key
            value
          }
          enabled
          name
          subscribed_events
        }
      }
    }
  }
}
    `;
export type UpdateWebhookEndpointMutationFn = Apollo.MutationFunction<UpdateWebhookEndpointMutation, UpdateWebhookEndpointMutationVariables>;

/**
 * __useUpdateWebhookEndpointMutation__
 *
 * To run a mutation, you first call `useUpdateWebhookEndpointMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWebhookEndpointMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWebhookEndpointMutation, { data, loading, error }] = useUpdateWebhookEndpointMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWebhookEndpointMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWebhookEndpointMutation, UpdateWebhookEndpointMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWebhookEndpointMutation, UpdateWebhookEndpointMutationVariables>(UpdateWebhookEndpointDocument, options);
      }
export type UpdateWebhookEndpointMutationHookResult = ReturnType<typeof useUpdateWebhookEndpointMutation>;
export type UpdateWebhookEndpointMutationResult = Apollo.MutationResult<UpdateWebhookEndpointMutation>;
export type UpdateWebhookEndpointMutationOptions = Apollo.BaseMutationOptions<UpdateWebhookEndpointMutation, UpdateWebhookEndpointMutationVariables>;
export const UpdateWebhookHeadersDocument = gql`
    mutation UpdateWebhookHeaders($input: UpdateWebhookHeadersInput!) {
  updateWebhookHeaders(input: $input) {
    pathway {
      settings {
        webhooks {
          id
          endpoint
          endpoint_test
          headers {
            key
            value
          }
          enabled
          name
          subscribed_events
        }
      }
    }
  }
}
    `;
export type UpdateWebhookHeadersMutationFn = Apollo.MutationFunction<UpdateWebhookHeadersMutation, UpdateWebhookHeadersMutationVariables>;

/**
 * __useUpdateWebhookHeadersMutation__
 *
 * To run a mutation, you first call `useUpdateWebhookHeadersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWebhookHeadersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWebhookHeadersMutation, { data, loading, error }] = useUpdateWebhookHeadersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWebhookHeadersMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWebhookHeadersMutation, UpdateWebhookHeadersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWebhookHeadersMutation, UpdateWebhookHeadersMutationVariables>(UpdateWebhookHeadersDocument, options);
      }
export type UpdateWebhookHeadersMutationHookResult = ReturnType<typeof useUpdateWebhookHeadersMutation>;
export type UpdateWebhookHeadersMutationResult = Apollo.MutationResult<UpdateWebhookHeadersMutation>;
export type UpdateWebhookHeadersMutationOptions = Apollo.BaseMutationOptions<UpdateWebhookHeadersMutation, UpdateWebhookHeadersMutationVariables>;
export const UpdateWebhookNameDocument = gql`
    mutation UpdateWebhookName($input: UpdateWebhookNameInput!) {
  updateWebhookName(input: $input) {
    pathway {
      settings {
        webhooks {
          id
          endpoint
          endpoint_test
          headers {
            key
            value
          }
          enabled
          name
          subscribed_events
        }
      }
    }
  }
}
    `;
export type UpdateWebhookNameMutationFn = Apollo.MutationFunction<UpdateWebhookNameMutation, UpdateWebhookNameMutationVariables>;

/**
 * __useUpdateWebhookNameMutation__
 *
 * To run a mutation, you first call `useUpdateWebhookNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWebhookNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWebhookNameMutation, { data, loading, error }] = useUpdateWebhookNameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWebhookNameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWebhookNameMutation, UpdateWebhookNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWebhookNameMutation, UpdateWebhookNameMutationVariables>(UpdateWebhookNameDocument, options);
      }
export type UpdateWebhookNameMutationHookResult = ReturnType<typeof useUpdateWebhookNameMutation>;
export type UpdateWebhookNameMutationResult = Apollo.MutationResult<UpdateWebhookNameMutation>;
export type UpdateWebhookNameMutationOptions = Apollo.BaseMutationOptions<UpdateWebhookNameMutation, UpdateWebhookNameMutationVariables>;
export const UpdateWebhookSubscribedEventsDocument = gql`
    mutation UpdateWebhookSubscribedEvents($input: UpdateWebhookSubscribedEventsInput!) {
  updateWebhookSubscribedEvents(input: $input) {
    pathway {
      settings {
        webhooks {
          id
          endpoint
          endpoint_test
          headers {
            key
            value
          }
          enabled
          name
          subscribed_events
        }
      }
    }
  }
}
    `;
export type UpdateWebhookSubscribedEventsMutationFn = Apollo.MutationFunction<UpdateWebhookSubscribedEventsMutation, UpdateWebhookSubscribedEventsMutationVariables>;

/**
 * __useUpdateWebhookSubscribedEventsMutation__
 *
 * To run a mutation, you first call `useUpdateWebhookSubscribedEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWebhookSubscribedEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWebhookSubscribedEventsMutation, { data, loading, error }] = useUpdateWebhookSubscribedEventsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWebhookSubscribedEventsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWebhookSubscribedEventsMutation, UpdateWebhookSubscribedEventsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWebhookSubscribedEventsMutation, UpdateWebhookSubscribedEventsMutationVariables>(UpdateWebhookSubscribedEventsDocument, options);
      }
export type UpdateWebhookSubscribedEventsMutationHookResult = ReturnType<typeof useUpdateWebhookSubscribedEventsMutation>;
export type UpdateWebhookSubscribedEventsMutationResult = Apollo.MutationResult<UpdateWebhookSubscribedEventsMutation>;
export type UpdateWebhookSubscribedEventsMutationOptions = Apollo.BaseMutationOptions<UpdateWebhookSubscribedEventsMutation, UpdateWebhookSubscribedEventsMutationVariables>;
export const UpdateWebhookTestEndpointDocument = gql`
    mutation UpdateWebhookTestEndpoint($input: UpdateWebhookTestEndpointInput!) {
  updateWebhookTestEndpoint(input: $input) {
    pathway {
      settings {
        webhooks {
          id
          endpoint
          endpoint_test
          headers {
            key
            value
          }
          enabled
          name
          subscribed_events
        }
      }
    }
  }
}
    `;
export type UpdateWebhookTestEndpointMutationFn = Apollo.MutationFunction<UpdateWebhookTestEndpointMutation, UpdateWebhookTestEndpointMutationVariables>;

/**
 * __useUpdateWebhookTestEndpointMutation__
 *
 * To run a mutation, you first call `useUpdateWebhookTestEndpointMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWebhookTestEndpointMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWebhookTestEndpointMutation, { data, loading, error }] = useUpdateWebhookTestEndpointMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWebhookTestEndpointMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWebhookTestEndpointMutation, UpdateWebhookTestEndpointMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWebhookTestEndpointMutation, UpdateWebhookTestEndpointMutationVariables>(UpdateWebhookTestEndpointDocument, options);
      }
export type UpdateWebhookTestEndpointMutationHookResult = ReturnType<typeof useUpdateWebhookTestEndpointMutation>;
export type UpdateWebhookTestEndpointMutationResult = Apollo.MutationResult<UpdateWebhookTestEndpointMutation>;
export type UpdateWebhookTestEndpointMutationOptions = Apollo.BaseMutationOptions<UpdateWebhookTestEndpointMutation, UpdateWebhookTestEndpointMutationVariables>;
export const UpdateWebhookStatusDocument = gql`
    mutation UpdateWebhookStatus($input: UpdateWebhookStatusInput!) {
  updateWebhookStatus(input: $input) {
    pathway {
      settings {
        webhooks {
          id
          endpoint
          endpoint_test
          headers {
            key
            value
          }
          enabled
          name
          subscribed_events
        }
      }
    }
  }
}
    `;
export type UpdateWebhookStatusMutationFn = Apollo.MutationFunction<UpdateWebhookStatusMutation, UpdateWebhookStatusMutationVariables>;

/**
 * __useUpdateWebhookStatusMutation__
 *
 * To run a mutation, you first call `useUpdateWebhookStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWebhookStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWebhookStatusMutation, { data, loading, error }] = useUpdateWebhookStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWebhookStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWebhookStatusMutation, UpdateWebhookStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWebhookStatusMutation, UpdateWebhookStatusMutationVariables>(UpdateWebhookStatusDocument, options);
      }
export type UpdateWebhookStatusMutationHookResult = ReturnType<typeof useUpdateWebhookStatusMutation>;
export type UpdateWebhookStatusMutationResult = Apollo.MutationResult<UpdateWebhookStatusMutation>;
export type UpdateWebhookStatusMutationOptions = Apollo.BaseMutationOptions<UpdateWebhookStatusMutation, UpdateWebhookStatusMutationVariables>;
export const UserDocument = gql`
    query user {
  user {
    code
    success
    user {
      id
      email
      tenant {
        id
        name
        public
        is_default
        logo_path
        accent_color
        hosted_page_title
        api_call_retry_settings {
          enabled
          initial_delay_ms
          max_delay_ms
          max_attempts
        }
      }
      team {
        id
        name
      }
      intercom_hash
      pending_invitation_id
    }
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserQuery(baseOptions?: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const SimulatorLayoutDocument = gql`
    query SimulatorLayout {
  simulatorLayout @client {
    activityFeedWidth
  }
}
    `;

/**
 * __useSimulatorLayoutQuery__
 *
 * To run a query within a React component, call `useSimulatorLayoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimulatorLayoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimulatorLayoutQuery({
 *   variables: {
 *   },
 * });
 */
export function useSimulatorLayoutQuery(baseOptions?: Apollo.QueryHookOptions<SimulatorLayoutQuery, SimulatorLayoutQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SimulatorLayoutQuery, SimulatorLayoutQueryVariables>(SimulatorLayoutDocument, options);
      }
export function useSimulatorLayoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SimulatorLayoutQuery, SimulatorLayoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SimulatorLayoutQuery, SimulatorLayoutQueryVariables>(SimulatorLayoutDocument, options);
        }
export type SimulatorLayoutQueryHookResult = ReturnType<typeof useSimulatorLayoutQuery>;
export type SimulatorLayoutLazyQueryHookResult = ReturnType<typeof useSimulatorLayoutLazyQuery>;
export type SimulatorLayoutQueryResult = Apollo.QueryResult<SimulatorLayoutQuery, SimulatorLayoutQueryVariables>;
export const LayoutDocument = gql`
    query Layout {
  layout @client {
    startPanelWidth
    startPanelCollapsed
    endPanelWidth
  }
}
    `;

/**
 * __useLayoutQuery__
 *
 * To run a query within a React component, call `useLayoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useLayoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLayoutQuery({
 *   variables: {
 *   },
 * });
 */
export function useLayoutQuery(baseOptions?: Apollo.QueryHookOptions<LayoutQuery, LayoutQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LayoutQuery, LayoutQueryVariables>(LayoutDocument, options);
      }
export function useLayoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LayoutQuery, LayoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LayoutQuery, LayoutQueryVariables>(LayoutDocument, options);
        }
export type LayoutQueryHookResult = ReturnType<typeof useLayoutQuery>;
export type LayoutLazyQueryHookResult = ReturnType<typeof useLayoutLazyQuery>;
export type LayoutQueryResult = Apollo.QueryResult<LayoutQuery, LayoutQueryVariables>;
export const TrackCanvasSettingsDocument = gql`
    query TrackCanvasSettings {
  trackCanvasSettings @client {
    trackId
    zoomLevel
    offset {
      x
      y
    }
  }
}
    `;

/**
 * __useTrackCanvasSettingsQuery__
 *
 * To run a query within a React component, call `useTrackCanvasSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrackCanvasSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrackCanvasSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTrackCanvasSettingsQuery(baseOptions?: Apollo.QueryHookOptions<TrackCanvasSettingsQuery, TrackCanvasSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TrackCanvasSettingsQuery, TrackCanvasSettingsQueryVariables>(TrackCanvasSettingsDocument, options);
      }
export function useTrackCanvasSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TrackCanvasSettingsQuery, TrackCanvasSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TrackCanvasSettingsQuery, TrackCanvasSettingsQueryVariables>(TrackCanvasSettingsDocument, options);
        }
export type TrackCanvasSettingsQueryHookResult = ReturnType<typeof useTrackCanvasSettingsQuery>;
export type TrackCanvasSettingsLazyQueryHookResult = ReturnType<typeof useTrackCanvasSettingsLazyQuery>;
export type TrackCanvasSettingsQueryResult = Apollo.QueryResult<TrackCanvasSettingsQuery, TrackCanvasSettingsQueryVariables>;
export const SessionUrlsDocument = gql`
    query SessionUrls {
  sessionUrls @client {
    builder
    preview
  }
}
    `;

/**
 * __useSessionUrlsQuery__
 *
 * To run a query within a React component, call `useSessionUrlsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionUrlsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionUrlsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSessionUrlsQuery(baseOptions?: Apollo.QueryHookOptions<SessionUrlsQuery, SessionUrlsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SessionUrlsQuery, SessionUrlsQueryVariables>(SessionUrlsDocument, options);
      }
export function useSessionUrlsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SessionUrlsQuery, SessionUrlsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SessionUrlsQuery, SessionUrlsQueryVariables>(SessionUrlsDocument, options);
        }
export type SessionUrlsQueryHookResult = ReturnType<typeof useSessionUrlsQuery>;
export type SessionUrlsLazyQueryHookResult = ReturnType<typeof useSessionUrlsLazyQuery>;
export type SessionUrlsQueryResult = Apollo.QueryResult<SessionUrlsQuery, SessionUrlsQueryVariables>;
export const PageTitleDocument = gql`
    query PageTitle {
  pageTitle @client
}
    `;

/**
 * __usePageTitleQuery__
 *
 * To run a query within a React component, call `usePageTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageTitleQuery({
 *   variables: {
 *   },
 * });
 */
export function usePageTitleQuery(baseOptions?: Apollo.QueryHookOptions<PageTitleQuery, PageTitleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PageTitleQuery, PageTitleQueryVariables>(PageTitleDocument, options);
      }
export function usePageTitleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageTitleQuery, PageTitleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PageTitleQuery, PageTitleQueryVariables>(PageTitleDocument, options);
        }
export type PageTitleQueryHookResult = ReturnType<typeof usePageTitleQuery>;
export type PageTitleLazyQueryHookResult = ReturnType<typeof usePageTitleLazyQuery>;
export type PageTitleQueryResult = Apollo.QueryResult<PageTitleQuery, PageTitleQueryVariables>;
export const CalculationsLibraryDocument = gql`
    query CalculationsLibrary {
  calculationsLibrary @client {
    calculation_id
    calculation_name {
      en
    }
    calculation_description {
      en
    }
    calculation_blueprint {
      input_definition {
        id
        input_type {
          type
        }
      }
    }
  }
}
    `;

/**
 * __useCalculationsLibraryQuery__
 *
 * To run a query within a React component, call `useCalculationsLibraryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculationsLibraryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculationsLibraryQuery({
 *   variables: {
 *   },
 * });
 */
export function useCalculationsLibraryQuery(baseOptions?: Apollo.QueryHookOptions<CalculationsLibraryQuery, CalculationsLibraryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CalculationsLibraryQuery, CalculationsLibraryQueryVariables>(CalculationsLibraryDocument, options);
      }
export function useCalculationsLibraryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalculationsLibraryQuery, CalculationsLibraryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CalculationsLibraryQuery, CalculationsLibraryQueryVariables>(CalculationsLibraryDocument, options);
        }
export type CalculationsLibraryQueryHookResult = ReturnType<typeof useCalculationsLibraryQuery>;
export type CalculationsLibraryLazyQueryHookResult = ReturnType<typeof useCalculationsLibraryLazyQuery>;
export type CalculationsLibraryQueryResult = Apollo.QueryResult<CalculationsLibraryQuery, CalculationsLibraryQueryVariables>;
export const GetFormsWithElementsInfoDocument = gql`
    query GetFormsWithElementsInfo {
  forms {
    forms {
      id
      title
      key
    }
  }
}
    `;

/**
 * __useGetFormsWithElementsInfoQuery__
 *
 * To run a query within a React component, call `useGetFormsWithElementsInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormsWithElementsInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormsWithElementsInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFormsWithElementsInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetFormsWithElementsInfoQuery, GetFormsWithElementsInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFormsWithElementsInfoQuery, GetFormsWithElementsInfoQueryVariables>(GetFormsWithElementsInfoDocument, options);
      }
export function useGetFormsWithElementsInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFormsWithElementsInfoQuery, GetFormsWithElementsInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFormsWithElementsInfoQuery, GetFormsWithElementsInfoQueryVariables>(GetFormsWithElementsInfoDocument, options);
        }
export type GetFormsWithElementsInfoQueryHookResult = ReturnType<typeof useGetFormsWithElementsInfoQuery>;
export type GetFormsWithElementsInfoLazyQueryHookResult = ReturnType<typeof useGetFormsWithElementsInfoLazyQuery>;
export type GetFormsWithElementsInfoQueryResult = Apollo.QueryResult<GetFormsWithElementsInfoQuery, GetFormsWithElementsInfoQueryVariables>;
export const StepsWithActionTypesDocument = gql`
    query StepsWithActionTypes($pathway_id: String!) {
  pathwaySteps(pathway_id: $pathway_id) {
    success
    code
    steps {
      id
      title
      track_id
      actions {
        title
        id
        type
        ... on FormAction {
          form_id
        }
        ... on MessageAction {
          message_id
        }
      }
      label {
        id
        color
        text
      }
    }
  }
}
    `;

/**
 * __useStepsWithActionTypesQuery__
 *
 * To run a query within a React component, call `useStepsWithActionTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStepsWithActionTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStepsWithActionTypesQuery({
 *   variables: {
 *      pathway_id: // value for 'pathway_id'
 *   },
 * });
 */
export function useStepsWithActionTypesQuery(baseOptions: Apollo.QueryHookOptions<StepsWithActionTypesQuery, StepsWithActionTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StepsWithActionTypesQuery, StepsWithActionTypesQueryVariables>(StepsWithActionTypesDocument, options);
      }
export function useStepsWithActionTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StepsWithActionTypesQuery, StepsWithActionTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StepsWithActionTypesQuery, StepsWithActionTypesQueryVariables>(StepsWithActionTypesDocument, options);
        }
export type StepsWithActionTypesQueryHookResult = ReturnType<typeof useStepsWithActionTypesQuery>;
export type StepsWithActionTypesLazyQueryHookResult = ReturnType<typeof useStepsWithActionTypesLazyQuery>;
export type StepsWithActionTypesQueryResult = Apollo.QueryResult<StepsWithActionTypesQuery, StepsWithActionTypesQueryVariables>;
export const GetPathwayInfoDocument = gql`
    query GetPathwayInfo($id: String!) {
  pathway(id: $id) {
    pathway {
      id
      title
      created {
        user_id
        user_email
        date
      }
      last_updated {
        user_id
        user_email
        date
      }
      last_published_version {
        version
        version_status
      }
      readonly
    }
  }
}
    `;

/**
 * __useGetPathwayInfoQuery__
 *
 * To run a query within a React component, call `useGetPathwayInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPathwayInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPathwayInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPathwayInfoQuery(baseOptions: Apollo.QueryHookOptions<GetPathwayInfoQuery, GetPathwayInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPathwayInfoQuery, GetPathwayInfoQueryVariables>(GetPathwayInfoDocument, options);
      }
export function useGetPathwayInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPathwayInfoQuery, GetPathwayInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPathwayInfoQuery, GetPathwayInfoQueryVariables>(GetPathwayInfoDocument, options);
        }
export type GetPathwayInfoQueryHookResult = ReturnType<typeof useGetPathwayInfoQuery>;
export type GetPathwayInfoLazyQueryHookResult = ReturnType<typeof useGetPathwayInfoLazyQuery>;
export type GetPathwayInfoQueryResult = Apollo.QueryResult<GetPathwayInfoQuery, GetPathwayInfoQueryVariables>;
export const GetPathwayVersionDocument = gql`
    query GetPathwayVersion($id: String!) {
  pathway_version(id: $id) {
    success
    version {
      ...PathwayVersion
    }
  }
}
    ${PathwayVersionFragmentDoc}`;

/**
 * __useGetPathwayVersionQuery__
 *
 * To run a query within a React component, call `useGetPathwayVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPathwayVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPathwayVersionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPathwayVersionQuery(baseOptions: Apollo.QueryHookOptions<GetPathwayVersionQuery, GetPathwayVersionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPathwayVersionQuery, GetPathwayVersionQueryVariables>(GetPathwayVersionDocument, options);
      }
export function useGetPathwayVersionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPathwayVersionQuery, GetPathwayVersionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPathwayVersionQuery, GetPathwayVersionQueryVariables>(GetPathwayVersionDocument, options);
        }
export type GetPathwayVersionQueryHookResult = ReturnType<typeof useGetPathwayVersionQuery>;
export type GetPathwayVersionLazyQueryHookResult = ReturnType<typeof useGetPathwayVersionLazyQuery>;
export type GetPathwayVersionQueryResult = Apollo.QueryResult<GetPathwayVersionQuery, GetPathwayVersionQueryVariables>;
export const GetPathwayVersionsDocument = gql`
    query GetPathwayVersions($pathway_id: String!) {
  pathway_versions(pathway_id: $pathway_id) {
    success
    versions {
      ...PathwayVersion
    }
  }
}
    ${PathwayVersionFragmentDoc}`;

/**
 * __useGetPathwayVersionsQuery__
 *
 * To run a query within a React component, call `useGetPathwayVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPathwayVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPathwayVersionsQuery({
 *   variables: {
 *      pathway_id: // value for 'pathway_id'
 *   },
 * });
 */
export function useGetPathwayVersionsQuery(baseOptions: Apollo.QueryHookOptions<GetPathwayVersionsQuery, GetPathwayVersionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPathwayVersionsQuery, GetPathwayVersionsQueryVariables>(GetPathwayVersionsDocument, options);
      }
export function useGetPathwayVersionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPathwayVersionsQuery, GetPathwayVersionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPathwayVersionsQuery, GetPathwayVersionsQueryVariables>(GetPathwayVersionsDocument, options);
        }
export type GetPathwayVersionsQueryHookResult = ReturnType<typeof useGetPathwayVersionsQuery>;
export type GetPathwayVersionsLazyQueryHookResult = ReturnType<typeof useGetPathwayVersionsLazyQuery>;
export type GetPathwayVersionsQueryResult = Apollo.QueryResult<GetPathwayVersionsQuery, GetPathwayVersionsQueryVariables>;
export const GetPathwayCaseTracksDocument = gql`
    query GetPathwayCaseTracks($pathwayId: String!) {
  pathway(id: $pathwayId) {
    pathway {
      id
      title
      tracks {
        id
        title
      }
    }
  }
}
    `;

/**
 * __useGetPathwayCaseTracksQuery__
 *
 * To run a query within a React component, call `useGetPathwayCaseTracksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPathwayCaseTracksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPathwayCaseTracksQuery({
 *   variables: {
 *      pathwayId: // value for 'pathwayId'
 *   },
 * });
 */
export function useGetPathwayCaseTracksQuery(baseOptions: Apollo.QueryHookOptions<GetPathwayCaseTracksQuery, GetPathwayCaseTracksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPathwayCaseTracksQuery, GetPathwayCaseTracksQueryVariables>(GetPathwayCaseTracksDocument, options);
      }
export function useGetPathwayCaseTracksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPathwayCaseTracksQuery, GetPathwayCaseTracksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPathwayCaseTracksQuery, GetPathwayCaseTracksQueryVariables>(GetPathwayCaseTracksDocument, options);
        }
export type GetPathwayCaseTracksQueryHookResult = ReturnType<typeof useGetPathwayCaseTracksQuery>;
export type GetPathwayCaseTracksLazyQueryHookResult = ReturnType<typeof useGetPathwayCaseTracksLazyQuery>;
export type GetPathwayCaseTracksQueryResult = Apollo.QueryResult<GetPathwayCaseTracksQuery, GetPathwayCaseTracksQueryVariables>;
export const GetPathwayCaseTitleDocument = gql`
    query GetPathwayCaseTitle($id: String!) {
  pathway_case(id: $id) {
    pathway_case {
      title
    }
  }
}
    `;

/**
 * __useGetPathwayCaseTitleQuery__
 *
 * To run a query within a React component, call `useGetPathwayCaseTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPathwayCaseTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPathwayCaseTitleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPathwayCaseTitleQuery(baseOptions: Apollo.QueryHookOptions<GetPathwayCaseTitleQuery, GetPathwayCaseTitleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPathwayCaseTitleQuery, GetPathwayCaseTitleQueryVariables>(GetPathwayCaseTitleDocument, options);
      }
export function useGetPathwayCaseTitleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPathwayCaseTitleQuery, GetPathwayCaseTitleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPathwayCaseTitleQuery, GetPathwayCaseTitleQueryVariables>(GetPathwayCaseTitleDocument, options);
        }
export type GetPathwayCaseTitleQueryHookResult = ReturnType<typeof useGetPathwayCaseTitleQuery>;
export type GetPathwayCaseTitleLazyQueryHookResult = ReturnType<typeof useGetPathwayCaseTitleLazyQuery>;
export type GetPathwayCaseTitleQueryResult = Apollo.QueryResult<GetPathwayCaseTitleQuery, GetPathwayCaseTitleQueryVariables>;