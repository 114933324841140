import Analytics, { type AnalyticsInstance } from 'analytics'
// @ts-expect-error it's ok no types for this package
import custifyPlugin from '@analytics/custify'
import { useAnalytics } from 'use-analytics'
import { environment } from './environment'

// Initialize analytics & load plugins
export const setupAnalytics = (app: string = 'Awell'): AnalyticsInstance => {
  const analytics = Analytics({
    app,
    plugins: [
      custifyPlugin({
        accountId: '6582dd6330bbcc84840f3d8b',
      }),
    ],
  })
  return analytics
}

export const useTrackCustify = (): ((
  eventName: string,
  metadata?: Record<string, any>,
) => Promise<void>) => {
  const { track } = useAnalytics()

  const trackCustify = async (
    eventName: string,
    metadata: Record<string, any> = {},
  ): Promise<void> => {
    const defaultMetadata = {
      environment: environment.environment,
      ...metadata,
    }
    void track(eventName, defaultMetadata)
  }

  return trackCustify
}
