import { Menu as MenuIcon } from '@awell/ui-kit/components/Icons'
import { Menu, MenuItem } from '@awell/ui-kit/components/Menu'
import React, { type FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AppVersion } from '../AppVersion'
import { useStyles } from './useStyles'
import { useUser } from '../../hooks/useUser'

interface MobileMenuProps {
  handleLogout: () => void
}

export const MobileMenu: FC<MobileMenuProps> = ({ handleLogout }) => {
  const { t } = useTranslation()
  const { user } = useUser()
  const classes = useStyles()

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

  return (
    <Menu
      open={isMobileMenuOpen}
      onClose={() => {
        setIsMobileMenuOpen(false)
      }}
      onOpen={() => {
        setIsMobileMenuOpen(true)
      }}
      toggleButtonContent={<MenuIcon />}
      dropdownLeft
    >
      <MenuItem disabled>
        {t('logged_in_user_email', { email: user?.email })}
      </MenuItem>
      <MenuItem>
        <a
          href={t('link_awell_help')}
          // eslint-disable-next-line react/jsx-no-target-blank
          target='_blank'
          rel='noopener noreferrer'
          className={classes.link}
        >
          {t('menu_awell_help')}
        </a>
      </MenuItem>
      <MenuItem onClick={handleLogout}>{t('logout')}</MenuItem>
      <MenuItem disabled>
        <AppVersion />
      </MenuItem>
    </Menu>
  )
}
MobileMenu.displayName = 'MobileMenu'
