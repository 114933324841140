import { navigate } from '@reach/router'
import { useStytchMemberSession, useStytchB2BClient } from '@stytch/react/b2b'
import { isNil } from 'lodash'
import React, { useEffect, type FC, useState, type ReactNode } from 'react'
import { environment } from '../environment'
import { useCustify } from './useCustify'
import { useHeap } from './useHeap'
import { useCookies } from 'react-cookie'

interface AuthenticationGuardProps {
  children: ReactNode
  loadingComponent: ReactNode
}

export const AuthenticationGuard: FC<AuthenticationGuardProps> = ({
  children,
  loadingComponent,
}) => {
  const { session } = useStytchMemberSession()
  const [cookies] = useCookies(['stytch_session'])
  const stytch = useStytchB2BClient()
  const [isLoading, setLoading] = useState(true)

  // @TODO Check why we use hooks for tools inside the scope of Auth hook
  useHeap()
  useCustify()

  const redirectToLogin = (): void => {
    void navigate(`${environment.urls.careops}/login`)
  }

  const authenticate = async (): Promise<boolean> => {
    try {
      const authResponse = await stytch.session.authenticate()
      return !isNil(authResponse.session_token)
    } catch (err) {
      return false
    }
  }

  useEffect(() => {
    // we know that if we dont have a stytch session the user is not logged in
    if (isNil(cookies?.stytch_session)) {
      redirectToLogin()
    }
    // Stytch session takes some time to be loaded so we should explicitly check if users session is still valid
    // this will only happen if the cookie exists and the session was revoked
    // this is not going to block customer but will ask for a new authentication if the session invalid
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    authenticate().then(isAuth => {
      if (!isAuth) {
        redirectToLogin()
      }
      setLoading(false)
    })
  }, [session])

  if (isLoading) {
    return <>{loadingComponent}</>
  }

  return <>{children}</>
}

AuthenticationGuard.displayName = 'AuthenticationGuard'
