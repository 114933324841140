import { setupAnalytics } from '@awell/libs-web/analytics'
import { AuthenticationGuard } from '@awell/libs-web/authentication'
import { OrganizationSwitcher } from '@awell/libs-web/OrganizationSwitcher'
import { environment } from '@awell/libs-web/environment'
import { AppTheme } from '@awell/ui-kit/components/AppTheme'
import { Spinner } from '@awell/ui-kit/components/Spinner'
import { BreadcrumbsProvider } from '@awell/ui-kit/hooks/useBreadcrumbs'
import { AppLayout } from '@awell/ui-kit/layouts/AppLayout'
import { type RouteComponentProps, navigate } from '@reach/router'
import { StytchB2BProvider } from '@stytch/react/b2b'
import { StytchB2BUIClient } from '@stytch/vanilla-js/b2b'
import React, { type FC } from 'react'
import { I18nextProvider } from 'react-i18next'
import { AnalyticsProvider } from 'use-analytics'
import { GraphqlWrapper } from './GraphqlWrapper'
import { NotificationProvider } from './NotificationProvider'
import { AppHeader } from './components/AppHeader'
import { FeatureGateProvider } from './features'
import { BackNavigationContextProvider } from './hooks/useBackNavigationContext'
import i18n from './i18n'
import { Routes } from './routes'
import { Router } from './routes/Router'

import './styles/tailwind.css'
// eslint-disable-next-line import/no-unresolved, import/no-extraneous-dependencies

const stytch = new StytchB2BUIClient(environment.auth.stytch_public_token, {
  cookieOptions: {
    opaqueTokenCookieName: `stytch_session`,
    domain: environment.auth.auth_cookies_allowed_domain,
    availableToSubdomains: true,
  },
})

const App: FC<RouteComponentProps> = () => {
  const analytics = setupAnalytics('Studio')
  return (
    <StytchB2BProvider stytch={stytch}>
      <AnalyticsProvider instance={analytics}>
        <AuthenticationGuard loadingComponent={<Spinner />}>
          <GraphqlWrapper>
            <I18nextProvider i18n={i18n}>
              <NotificationProvider>
                <AppTheme seed='studio'>
                  <FeatureGateProvider>
                    <OrganizationSwitcher
                      onSwitch={() => {
                        void navigate('/')
                      }}
                      environment={environment.environment}
                    >
                      <BreadcrumbsProvider>
                        <BackNavigationContextProvider>
                          <AppLayout>
                            <AppLayout.Header>
                              <Router>
                                <AppHeader default />
                              </Router>
                            </AppLayout.Header>
                            <AppLayout.Body>
                              <Routes />
                            </AppLayout.Body>
                          </AppLayout>
                        </BackNavigationContextProvider>
                      </BreadcrumbsProvider>
                    </OrganizationSwitcher>
                  </FeatureGateProvider>
                </AppTheme>
              </NotificationProvider>
            </I18nextProvider>
          </GraphqlWrapper>
        </AuthenticationGuard>
      </AnalyticsProvider>
    </StytchB2BProvider>
  )
}

export default App
