import { useTranslation } from 'react-i18next'
import { createApolloCacheUpdate, updateQuery } from '@awell/libs-web/graphql'
import { useNotifications } from '../useNotifications'
import {
  DeletePathwayCaseMutation,
  GetPathwayCasesDocument,
  GetPathwayCasesQuery,
  GetPathwayCasesQueryVariables,
  PathwayCase,
  useDeletePathwayCaseMutation,
} from './types'

interface DeletePathwayCaseParams {
  pathwayCaseId: string
}

interface UseDeletePathwayCaseHook {
  deletePathwayCase: ({
    pathwayCaseId,
  }: DeletePathwayCaseParams) => Promise<void>
}

export const useDeletePathwayCase = ({
  pathwayId,
}: {
  pathwayId: string
}): UseDeletePathwayCaseHook => {
  const { notifyError } = useNotifications()
  const { t } = useTranslation()
  const variables: GetPathwayCasesQueryVariables = {
    input: { pathway_id: pathwayId },
  }

  const [deletePathwayCaseMutation] = useDeletePathwayCaseMutation()
  const deletePathwayCase = async ({
    pathwayCaseId,
  }: DeletePathwayCaseParams) => {
    try {
      const update = createApolloCacheUpdate<
        DeletePathwayCaseMutation,
        GetPathwayCasesQuery,
        GetPathwayCasesQueryVariables
      >({
        query: GetPathwayCasesDocument,
        variables,
        updateFunction: ({ query, result }) => {
          if (result.data?.deletePathwayCase.success) {
            const pathway_cases = query.pathway_cases.pathway_cases.filter(
              pathway_case => pathway_case.id !== pathwayCaseId,
            )
            return updateQuery<GetPathwayCasesQuery, Array<PathwayCase>>(
              query,
              ['pathway_cases', 'pathway_cases'],
              pathway_cases,
            )
          }
          return query
        },
      })
      await deletePathwayCaseMutation({
        variables: {
          input: {
            pathway_case_id: pathwayCaseId,
          },
        },
        update,
      })
    } catch (error) {
      notifyError({
        message: t('pathway_case_create_error'),
        error,
      })
    }
  }

  return { deletePathwayCase }
}
